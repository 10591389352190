import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import { useSetRecoilState } from "recoil";

import { currentBoardIdState } from "AppState";
import { TQueryResult, ViewQueryLoader } from "components/loading/QueryLoader";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { ProductTourElementClasses } from "lib/Constants";
import { useDocumentTitle } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { useRouteParams } from "lib/Routing";
import { Storage } from "lib/Storage";
import { useRedirectToFullPathname, useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { DetailViewQuery } from "lib/graphql/__generated__/graphql";
import { TicketNotFoundView } from "views/error/NotFoundView";
import { useTicketDetailDropzone } from "views/ticketDetail/content/useTicketDetailDropzone";
import { DetailViewProvider, useDetailView } from "views/ticketDetail/context/DetailViewContext";
import {
    DetailViewDiscussionProvider,
    ResolvedThreadsLocation,
    useDetailViewDiscussion,
} from "views/ticketDetail/context/DetailViewDiscussionContext";

import styles from "./DetailView.module.scss";
import { DetailViewHeader } from "./DetailViewHeader";
import { DetailViewMain } from "./DetailViewMain";
import { ResolvedThreadsSideDrawer } from "./ResolvedThreadsSideDrawer";
import { TicketHistorySideDrawer } from "./TicketHistorySideDrawer";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment DetailView_org on orgs {
            id

            ...BoardAndStagesMenuContextProvider_org
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment DetailView_ticket on tickets {
            id
            ref
            slug
            title

            board {
                id
                display_name
            }

            ...DetailViewDiscussionProvider_ticket
            ...DetailViewHeader_ticket
            ...DetailViewMain_ticket
            ...DetailViewProvider_ticket
            ...ResolvedThreadsSideDrawer_ticket
        }
    `),
};

function DetailViewLayout() {
    const { ticket } = useDetailView();
    const { isDiscussionPanelPopulated } = useDetailViewDiscussion();
    const viewRef = useRef<HTMLDivElement>(null);
    const { onDragOver, onDrop } = useTicketDetailDropzone({
        dropzoneRef: viewRef,
        ticketId: ticket.id,
    });

    return (
        <div className={styles.layout} ref={viewRef} onDragOver={onDragOver} onDrop={onDrop}>
            <div
                className={classNames(
                    styles.highlightTarget,
                    ProductTourElementClasses.TICKET_HEADER_AND_DOCUMENT,
                    isDiscussionPanelPopulated && styles.isDiscussionPanelPopulated
                )}
            />
            <DetailViewHeader />
            <DetailViewMain />
            <ResolvedThreadsSideDrawer />
            <TicketHistorySideDrawer />
        </div>
    );
}

function DetailViewImpl({ queryResult }: { queryResult: TQueryResult<DetailViewQuery> }) {
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const { setDocumentTitle } = useDocumentTitle();
    const { buildTicketUrl } = useUrlBuilders();
    const setCurrentBoardId = useSetRecoilState(currentBoardIdState);

    const ticket = getFragmentData(fragments.ticket, queryResult.data?.org?.tickets[0]);

    useRedirectToFullPathname({
        fullPathname: ticket
            ? buildTicketUrl({
                  ticketSlug: ticket.slug,
                  vanity: {
                      boardDisplayName: ticket.board.display_name,
                      ticketRef: ticket.ref,
                      ticketTitle: ticket.title,
                  },
              }).pathname
            : null,
    });

    useEffect(() => {
        if (ticket) {
            setDocumentTitle(
                shouldShowTicketRefs ? `#${ticket.ref} ${ticket.title}` : `${ticket.title}`
            );
        }
    }, [ticket, setDocumentTitle, shouldShowTicketRefs]);

    useEffect(() => {
        if (ticket) {
            setCurrentBoardId(ticket.board.id);
            Storage.All.setItem("mostRecentlyViewedBoardId", ticket.board.id);
        }
    }, [setCurrentBoardId, ticket]);

    if (queryResult.loading && !queryResult.data) {
        return null;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    if (!ticket) {
        return <TicketNotFoundView />;
    }

    return (
        <React.Fragment key={ticket.id}>
            <DetailViewProvider ticket={ticket}>
                <DetailViewDiscussionProvider
                    resolvedThreadsLocation={ResolvedThreadsLocation.DRAWER}
                    ticket={ticket}
                >
                    <DetailViewLayout />
                </DetailViewDiscussionProvider>
            </DetailViewProvider>
        </React.Fragment>
    );
}

export function DetailView() {
    const currentUser = useCurrentUser();
    const { ticketSlug } = useRouteParams<{ ticketSlug?: string }>();

    if (!ticketSlug) {
        return <TicketNotFoundView />;
    }

    return (
        <ViewQueryLoader
            query={DetailView.queries.component}
            variables={{ orgId: currentUser.org_id, slug: ticketSlug }}
        >
            {({ queryResult }) => <DetailViewImpl queryResult={queryResult} />}
        </ViewQueryLoader>
    );
}

DetailView.queries = {
    component: gql(/* GraphQL */ `
        query DetailView($orgId: Int!, $slug: String!) {
            org: orgs_by_pk(id: $orgId) {
                id
                ...DetailView_org

                tickets(where: { slug: { _eq: $slug } }) {
                    ...DetailView_ticket
                }
            }
        }
    `),
};

Queries.register({ component: "DetailView", gqlMapByName: DetailView.queries });
