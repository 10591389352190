import React, { useEffect, useRef } from "react";

import {
    isKeyboardTextAreaCancel,
    isKeyboardTextAreaSubmit,
    isKeyboardTextInputCancel,
    isKeyboardTextInputSubmit,
} from "lib/Keyboard";

export type AbstractTextAreaProps = {
    /** autoSize can only be used in controlled mode by also providing a value prop. */
    autoSize?: boolean;
    className?: string;
    onKeyboardCancel?: () => void;
    onKeyboardSubmit?: () => void;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
    treatAsTextInputForKeyboard?: boolean;
    value: string;
} & React.ComponentPropsWithoutRef<"textarea">;

export function AbstractTextArea({
    autoSize = false,
    className,
    onKeyboardCancel,
    onKeyboardSubmit,
    textAreaRef,
    treatAsTextInputForKeyboard = false,
    value,

    ...htmlTextAreaProps
}: AbstractTextAreaProps) {
    const localRef = useRef<HTMLTextAreaElement>(null);
    const ref = textAreaRef || localRef;
    const valueRef = useRef(value);

    const [isCancel, isSubmit] = treatAsTextInputForKeyboard
        ? [isKeyboardTextInputCancel, isKeyboardTextInputSubmit]
        : [isKeyboardTextAreaCancel, isKeyboardTextAreaSubmit];

    useEffect(() => {
        if (!autoSize || !ref.current) {
            return;
        }

        if (value.length < valueRef.current.length) {
            // If content is now smaller, temporarily set to "auto" so that scrollHeight
            // "resets" to its natural value.
            ref.current.style.height = "auto";
        }

        const newHeight = `${ref.current.scrollHeight}px`;

        if (ref.current.style.height !== newHeight) {
            ref.current.style.height = newHeight;
        }

        valueRef.current = value;
    }, [autoSize, ref, value]);

    return (
        <textarea
            {...htmlTextAreaProps}
            className={className}
            onKeyDown={event => {
                if (onKeyboardCancel && isCancel(event)) {
                    event.preventDefault();
                    onKeyboardCancel();
                }

                if (onKeyboardSubmit && isSubmit(event)) {
                    event.preventDefault();
                    onKeyboardSubmit();
                }

                htmlTextAreaProps.onKeyDown?.(event);
            }}
            ref={ref}
            rows={autoSize ? 1 : undefined}
            value={value}
        />
    );
}
