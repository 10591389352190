import React, { useCallback, useEffect, useRef } from "react";

import { Classes } from "@blueprintjs/core";
import { Popover2InteractionKind } from "@blueprintjs/popover2";
import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuPopover, MenuPopoverProps } from "components/ui/core/MenuPopover";
import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";
import { isKeyboardClick } from "lib/Keyboard";
import { Link, LinkProps } from "lib/Routing";

import styles from "./MenuItem.module.scss";

export type MenuItemProps = {
    active?: boolean;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    disableHoverEffect?: boolean;
    icon?: React.ReactNode;
    instrumentation: Omit<InstrumentationEvent, "eventType"> | null;
    labelElement?: React.ReactNode;
    popoverProps?: Omit<MenuPopoverProps, "children">;
    scrollIntoView?: boolean;
    shouldDismissPopover?: boolean;
    showSubmenuChevron?: boolean;
    text: React.ReactNode;

    /** If present, render the menu item as a react router Link instead of a native <a> tag. */
    to?: LinkProps["to"];
} & React.ComponentPropsWithoutRef<"a">;

/**
 * A MenuItem is an <a> tag wrapped in a <li> tag.. This is a reimplementation of blueprint MenuItem
 * component with our own styling and behavior changes.
 *
 * Supports all standard HTML anchor attributes.
 */
export function MenuItem({
    active,
    children,
    className,
    disabled,
    disableHoverEffect,
    icon,
    instrumentation,
    labelElement,
    popoverProps,
    scrollIntoView,
    shouldDismissPopover = true,
    showSubmenuChevron,
    text,
    to,

    ...htmlAnchorProps
}: MenuItemProps) {
    const ref = useRef<HTMLLIElement>(null);
    const { onClick } = htmlAnchorProps;
    const safeOnClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            onClick?.(e);
        },
        [onClick]
    );

    const { recordCallback } = useInstrumentation();
    const wrappedOnClick = recordCallback(
        {
            eventType: instrumentation?.elementName ? Enums.InstrumentationEvent.CLICK : null,
            elementName: instrumentation?.elementName,
            eventData: instrumentation?.eventData,
            dedupeKey: Date.now(),
        },
        safeOnClick
    );

    useEffect(() => {
        if (scrollIntoView) {
            // See https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
            // @ts-ignore-error
            ref.current?.scrollIntoView({ behavior: "instant", block: "nearest" });
        }
    }, [scrollIntoView]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLAnchorElement>) => {
            if (isKeyboardClick(e)) {
                e.preventDefault();
                wrappedOnClick();
            }

            htmlAnchorProps.onKeyDown?.(e);
        },
        [htmlAnchorProps, wrappedOnClick]
    );

    const hasSubmenu = !!children;

    const maybeWrapInPopover = (target: React.ReactElement) => {
        if (!hasSubmenu) {
            return target;
        }

        return (
            <MenuPopover
                autoFocus={false}
                captureDismiss={false}
                disabled={disabled}
                // Without enforceFocus, if you _click_ a parent menu item instead of hovering it,
                // clicking a submenu item doesn't work. It looks like that's because clicking
                // the parent menu item causes it to grab the focus, at which point clicking
                // anything outside it (including the submenu) causes the submenu to dismiss.
                enforceFocus
                fill
                hoverCloseDelay={0}
                interactionKind={Popover2InteractionKind.HOVER}
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [-8, 0],
                        },
                    },
                    preventOverflow: {
                        enabled: true,
                        options: { padding: 20 },
                    },
                }}
                placement="right-start"
                usePortal
                {...popoverProps}
                content={<Menu className={undefined}>{children}</Menu>}
                minimal
                popoverClassName={classNames(popoverProps?.popoverClassName)}
            >
                {target}
            </MenuPopover>
        );
    };

    const target = React.createElement(
        to ? Link : "a",
        {
            tabIndex: 0,
            role: "menuitem",
            // If "to" was provided, we're rendering a Link component above, otherwise an <a> tag.
            // The TypeScript compiler is not able to make that inference.
            // @ts-expect-error
            to,
            ...htmlAnchorProps,
            onClick: wrappedOnClick,
            onKeyDown: handleKeyDown,
            ...(disabled
                ? {
                      href: undefined,
                      onClick: undefined,
                      onMouseDown: undefined,
                      onMouseEnter: undefined,
                      onMouseLeave: undefined,
                      tabIndex: -1,
                  }
                : {}),
            className: classNames(
                className,
                styles.menuItem,
                Classes.MENU_ITEM,
                disableHoverEffect && styles.disableHoverEffect,
                {
                    [Classes.ACTIVE]: active,
                    [Classes.DISABLED]: disabled,
                    [Classes.POPOVER_DISMISS]: shouldDismissPopover && !disabled && !hasSubmenu,
                }
            ),
        },
        icon,
        <div className={classNames(Classes.FILL)}>{text}</div>,
        labelElement ? (
            <span className={classNames(styles.menuItemLabel, Classes.MENU_ITEM_LABEL)}>
                {labelElement}
            </span>
        ) : null,
        hasSubmenu || showSubmenuChevron ? (
            <Icon className={styles.submenuItemChevron} icon="chevron-right" iconSet="lucide" />
        ) : undefined
    );

    return <li ref={ref}>{maybeWrapInPopover(target)}</li>;
}
