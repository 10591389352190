import React from "react";

import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";

import { DropdownButton } from "./DropdownButton";

export type DropdownMenuProps = {
    currentValue: string;
    id?: string;
    items: { value: string; displayText: string }[];
    onChange: (value: string) => void;
};

export function DropdownMenu({ currentValue, id, items, onChange }: DropdownMenuProps) {
    return (
        <MenuPopover
            content={
                <Menu>
                    {items.map(({ value, displayText }, i) => {
                        if (value === "DIVIDER") {
                            // eslint-disable-next-line react/no-array-index-key
                            return <MenuDivider key={`divider${i}`} />;
                        }

                        if (displayText) {
                            return (
                                <MenuItem
                                    key={value}
                                    text={displayText}
                                    onClick={() => onChange(value)}
                                    instrumentation={null}
                                />
                            );
                        }

                        return null;
                    })}
                </Menu>
            }
            placement="bottom-end"
            // As of December 2021, this is necessary to fix some scroll jank. Without this, at
            // the time, if a user scrolled down on the page and then opened a dropdown menu, the
            // page would snap scroll back up. Not using a portal fixes this.
            // See https://go.flat.app/tickets/1376.
            // Why doesn't this happen elsewhere in the app? It appears scroll jank only occurs
            // when there is _no_ ancestor node with overflow-y. So, if it becomes necessary
            // here to usePortal again, consider solving the scroll jank issue by including
            // overflow-y somewhere high up on the page.
            usePortal={false}
        >
            <DropdownButton id={id} text={items.find(i => i.value === currentValue)!.displayText} />
        </MenuPopover>
    );
}
