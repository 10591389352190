import { useCallback, useMemo } from "react";

import { CommonEnums, TicketDueDates, sortStages } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    TaskDueDateInfo_taskFragment,
    TaskDueDateInfo_taskFragmentDoc,
    TaskOwnershipInfo_taskFragment,
    TaskOwnershipInfo_taskFragmentDoc,
    TaskStatusInfo_taskFragment,
    TaskStatusInfo_taskFragmentDoc,
    TicketActivityDatesInfo_ticketFragment,
    TicketActivityDatesInfo_ticketFragmentDoc,
    TicketDueDateInfo_ticketFragment,
    TicketDueDateInfo_ticketFragmentDoc,
    TicketOwnershipInfo_ticketFragment,
    TicketOwnershipInfo_ticketFragmentDoc,
    TicketParentsInfo_ticketFragment,
    TicketParentsInfo_ticketFragmentDoc,
    TicketProgressInfo_ticketFragment,
    TicketProgressInfo_ticketFragmentDoc,
    TicketWatcherInfo_ticketFragment,
    TicketWatcherInfo_ticketFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { CurrentUser } from "lib/types/common/currentUser";

export function useGetTaskStatusInfo() {
    const getTaskStatusInfo = useCallback(
        ({ task: _taskFragment }: { task: FragmentType<typeof TaskStatusInfo_taskFragmentDoc> }) =>
            useGetTaskStatusInfo._compute({
                task: getFragmentData(TaskStatusInfo_taskFragmentDoc, _taskFragment),
            }),
        []
    );

    return { getTaskStatusInfo };
}

useGetTaskStatusInfo._compute = ({ task }: { task: TaskStatusInfo_taskFragment }) => {
    const isComplete = !!(
        task.is_complete ||
        (task.task_type === CommonEnums.TaskType.CHILD_TICKET &&
            (task.child_ticket?.stage.role === CommonEnums.StageRole.COMPLETE ||
                task.child_ticket?.archived_at))
    );
    const isHidden = !!(
        task.deleted_at ||
        (task.task_type === CommonEnums.TaskType.CHILD_TICKET && task.child_ticket?.trashed_at)
    );

    return { isComplete, isHidden };
};

useGetTaskStatusInfo.fragments = {
    task: gql(/* GraphQL */ `
        fragment TaskStatusInfo_task on tasks {
            id
            deleted_at
            is_complete
            task_type

            child_ticket {
                id
                archived_at
                trashed_at

                stage {
                    id
                    role
                }
            }
        }
    `),
};

export function useTaskDueDateInfo({
    task: _taskFragment,
}: {
    task: FragmentType<typeof TaskDueDateInfo_taskFragmentDoc>;
}) {
    const { formattedDueDate, isUpcomingSoon, isOverdue } = useTaskDueDateInfo._compute({
        task: getFragmentData(TaskDueDateInfo_taskFragmentDoc, _taskFragment),
    });

    const result = useMemo(
        () => ({
            formattedDueDate,
            isUpcomingSoon,
            isOverdue,
        }),
        [formattedDueDate, isUpcomingSoon, isOverdue]
    );

    return result;
}

useTaskDueDateInfo._compute = ({ task }: { task: TaskDueDateInfo_taskFragment }) => {
    if (!task.due_date) {
        return {
            formattedDueDate: null,
            isUpcomingSoon: false,
            isOverdue: false,
        };
    }

    const formattedDueDate = TicketDueDates.format({ dueDate: task.due_date });
    const { isUpcomingSoon, isToday, isPast } = TicketDueDates.getInfo({ dueDate: task.due_date });
    const isAlertable = !task.is_complete;

    return {
        formattedDueDate,
        isUpcomingSoon: !!(isAlertable && isUpcomingSoon),
        isOverdue: !!(isAlertable && (isToday || isPast)),
    };
};

useTaskDueDateInfo.fragments = {
    task: gql(/* GraphQL */ `
        fragment TaskDueDateInfo_task on tasks {
            id
            due_date
            is_complete
        }
    `),
};

export function useTaskOwnershipInfo({
    task: _taskFragment,
}: {
    task: FragmentType<typeof TaskOwnershipInfo_taskFragmentDoc>;
}) {
    const currentUser = useCurrentUser();
    const { isCurrentUserOwner, ownershipSummaryText } = useTaskOwnershipInfo._compute({
        task: getFragmentData(TaskOwnershipInfo_taskFragmentDoc, _taskFragment),
        currentUser,
    });

    const result = useMemo(
        () => ({
            isCurrentUserOwner,
            ownershipSummaryText,
        }),
        [isCurrentUserOwner, ownershipSummaryText]
    );

    return result;
}

useTaskOwnershipInfo._compute = ({
    task,
    currentUser,
}: {
    task: TaskOwnershipInfo_taskFragment;
    currentUser: CurrentUser;
}) => {
    const isCurrentUserOwner = !!(task.assignee && task.assignee.id === currentUser.id);
    let ownershipSummaryText = "";

    if (task.assignee) {
        ownershipSummaryText = isCurrentUserOwner ? "me" : task.assignee.name;
    }

    return {
        isCurrentUserOwner,
        ownershipSummaryText,
    };
};

useTaskOwnershipInfo.fragments = {
    task: gql(/* GraphQL */ `
        fragment TaskOwnershipInfo_task on tasks {
            id

            assignee {
                id
                name
            }
        }
    `),
};

export function useTicketActivityDatesInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketActivityDatesInfo_ticketFragmentDoc>;
}) {
    const { createdAt, importedAt, updatedAt, archivedAt } = useTicketActivityDatesInfo._compute({
        ticket: getFragmentData(TicketActivityDatesInfo_ticketFragmentDoc, _ticketFragment),
    });

    const result = useMemo(
        () => ({
            createdAt,
            importedAt,
            updatedAt,
            archivedAt,
        }),
        [createdAt, importedAt, updatedAt, archivedAt]
    );

    return result;
}

useTicketActivityDatesInfo._compute = ({
    ticket,
}: {
    ticket: TicketActivityDatesInfo_ticketFragment;
}) => {
    const {
        create_or_import_event: [createOrImportEvent],
        most_recent_update_event: [mostRecentUpdateEvent],
        archived_at: archivedAt,
    } = ticket;
    const createdAt =
        createOrImportEvent?.event_type === "CREATE_TICKET" ? createOrImportEvent.event_at : null;
    const importedAt =
        createOrImportEvent?.event_type === "IMPORT_TICKET" ? createOrImportEvent.event_at : null;
    const updatedAt = mostRecentUpdateEvent?.event_at ?? null;

    return {
        createdAt,
        importedAt,
        updatedAt,
        archivedAt,
    };
};

useTicketActivityDatesInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketActivityDatesInfo_ticket on tickets {
            id
            archived_at

            create_or_import_event: ticket_history_events(
                where: { event_type: { _in: ["CREATE_TICKET", "IMPORT_TICKET"] } }
                order_by: { event_at: asc }
                limit: 1
            ) {
                id
                event_type
                event_at
            }

            most_recent_update_event: ticket_history_events(
                order_by: { event_at: desc }
                limit: 1
            ) {
                id
                event_type
                event_at
            }
        }
    `),
};

export function useTicketDueDateInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketDueDateInfo_ticketFragmentDoc>;
}) {
    const { formattedDueDate, isUpcomingSoon, isOverdue } = useTicketDueDateInfo._compute({
        ticket: getFragmentData(TicketDueDateInfo_ticketFragmentDoc, _ticketFragment),
    });

    const result = useMemo(
        () => ({
            formattedDueDate,
            isUpcomingSoon,
            isOverdue,
        }),
        [formattedDueDate, isUpcomingSoon, isOverdue]
    );

    return result;
}

useTicketDueDateInfo._compute = ({ ticket }: { ticket: TicketDueDateInfo_ticketFragment }) => {
    if (!ticket.due_date) {
        return {
            formattedDueDate: null,
            isUpcomingSoon: false,
            isOverdue: false,
        };
    }

    const formattedDueDate = TicketDueDates.format({ dueDate: ticket.due_date });
    const { isUpcomingSoon, isToday, isPast } = TicketDueDates.getInfo({
        dueDate: ticket.due_date,
    });
    const isAlertable =
        !ticket.archived_at &&
        !ticket.trashed_at &&
        !(ticket.stage.role === CommonEnums.StageRole.COMPLETE);

    return {
        formattedDueDate,
        isUpcomingSoon: !!(isAlertable && isUpcomingSoon),
        isOverdue: !!(isAlertable && (isToday || isPast)),
    };
};

useTicketDueDateInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketDueDateInfo_ticket on tickets {
            id
            due_date
            archived_at
            trashed_at

            stage {
                id
                role
            }
        }
    `),
};

export function useTicketOwnershipInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketOwnershipInfo_ticketFragmentDoc> | null;
}) {
    const currentUser = useCurrentUser();
    const {
        ownerUser,
        memberUsers,
        isCurrentUserOwner,
        isCurrentUserMember,
        ownershipSummaryText,
    } = useTicketOwnershipInfo._compute({
        ticket: _ticketFragment
            ? getFragmentData(TicketOwnershipInfo_ticketFragmentDoc, _ticketFragment)
            : null,
        currentUser,
    });

    const result = useMemo(
        () => ({
            ownerUser,
            memberUsers,
            isCurrentUserOwner,
            isCurrentUserMember,
            ownershipSummaryText,
        }),
        [ownerUser, memberUsers, isCurrentUserOwner, isCurrentUserMember, ownershipSummaryText]
    );

    return result;
}

useTicketOwnershipInfo._compute = ({
    ticket,
    currentUser,
}: {
    ticket: TicketOwnershipInfo_ticketFragment | null;
    currentUser: CurrentUser;
}) => {
    if (!ticket) {
        return {
            ownerUser: null,
            memberUsers: [],
            isCurrentUserOwner: false,
            isCurrentUserMember: false,
            ownershipSummaryText: "",
        };
    }

    const ownerUser =
        ticket.owners.find(to => to.type === CommonEnums.TicketOwnerType.OWNER)?.owner || null;
    const memberUsers = ticket.owners
        .filter(to => to.type === CommonEnums.TicketOwnerType.MEMBER)
        .map(to => to.owner);
    const isCurrentUserOwner = !!(ownerUser && ownerUser.id === currentUser.id);
    const isCurrentUserMember = memberUsers.some(memberUser => memberUser.id === currentUser.id);

    let ownershipSummaryText: string;
    const noCollaborators = memberUsers.length === 0;
    const oneCollaborator = memberUsers.length === 1;

    if (isCurrentUserOwner) {
        if (noCollaborators) {
            ownershipSummaryText = "me";
        } else if (oneCollaborator) {
            ownershipSummaryText = `me + ${memberUsers[0].name}`;
        } else {
            ownershipSummaryText = `me + ${memberUsers.length} others`;
        }
    } else if (ownerUser) {
        if (noCollaborators) {
            ownershipSummaryText = ownerUser.name;
        } else if (oneCollaborator) {
            if (isCurrentUserMember) {
                ownershipSummaryText = `${ownerUser.name} + me`;
            } else {
                ownershipSummaryText = `${ownerUser.name} + ${memberUsers[0].name}`;
            }
        } else {
            ownershipSummaryText = `${ownerUser.name} + ${memberUsers.length} others`;
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (noCollaborators) {
            ownershipSummaryText = "";
        } else if (oneCollaborator) {
            if (isCurrentUserMember) {
                ownershipSummaryText = "me";
            } else {
                ownershipSummaryText = memberUsers[0].name;
            }
        } else {
            const otherCollaboratorNames = memberUsers
                .filter(({ id }) => id !== currentUser.id)
                .map(({ name }) => name)
                .sort((a, b) => a.localeCompare(b));

            if (isCurrentUserMember) {
                ownershipSummaryText = ["me", ...otherCollaboratorNames].join(", ");
            } else {
                ownershipSummaryText = otherCollaboratorNames.join(", ");
            }
        }
    }

    return {
        ownerUser,
        memberUsers,
        isCurrentUserOwner,
        isCurrentUserMember,
        ownershipSummaryText,
    };
};

useTicketOwnershipInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketOwnershipInfo_ticket on tickets {
            id

            owners {
                ticket_id
                user_id
                type

                owner {
                    id
                    name
                }
            }
        }
    `),
};

export function useTicketParentsInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketParentsInfo_ticketFragmentDoc>;
}) {
    const { parentTicketsText } = useTicketParentsInfo._compute({
        ticket: getFragmentData(TicketParentsInfo_ticketFragmentDoc, _ticketFragment),
    });

    const result = useMemo(
        () => ({
            parentTicketsText,
        }),
        [parentTicketsText]
    );

    return result;
}

useTicketParentsInfo._compute = ({ ticket }: { ticket: TicketParentsInfo_ticketFragment }) => {
    const parentTickets = ticket.child_of_tasks
        .map(({ ticket: t }) => t)
        .sort((a, b) => a.title.localeCompare(b.title));
    const firstParentTicketTitle = parentTickets[0]?.title ?? "";
    const remainingParentTicketsCount =
        parentTickets.length > 1 ? ` +${parentTickets.length - 1}` : "";
    const parentTicketsText = `${firstParentTicketTitle}${remainingParentTicketsCount}`;

    return {
        parentTicketsText,
    };
};

useTicketParentsInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketParentsInfo_ticket on tickets {
            id

            child_of_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    ticket: { trashed_at: { _is_null: true } }
                }
            ) {
                id

                ticket {
                    id
                    title
                }
            }
        }
    `),
};

export function useTicketProgressInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketProgressInfo_ticketFragmentDoc> | null;
}) {
    const {
        isComplete,
        isBlocked,
        progressFraction,
        progressPercent,
    } = useTicketProgressInfo._compute({
        ticket: _ticketFragment
            ? getFragmentData(TicketProgressInfo_ticketFragmentDoc, _ticketFragment)
            : null,
    });

    const result = useMemo(
        () => ({
            isComplete,
            isBlocked,
            progressFraction,
            progressPercent,
        }),
        [isComplete, isBlocked, progressFraction, progressPercent]
    );

    return result;
}

useTicketProgressInfo._compute = ({
    ticket,
}: {
    ticket: TicketProgressInfo_ticketFragment | null;
}) => {
    if (!ticket) {
        return {
            isComplete: false,
            isBlocked: false,
            progressFraction: 0,
            progressPercent: 0,
        };
    }

    if (ticket.archived_at) {
        return {
            isComplete: true,
            isBlocked: false,
            progressFraction: 1,
            progressPercent: 100,
        };
    }

    const stages = ticket.board.stages.concat().sort(sortStages());
    const currentStageIndex = stages.findIndex(s => s.id === ticket.stage_id);
    const progressFraction = currentStageIndex / (stages.length - 1);
    const progressPercent =
        typeof progressFraction === "number"
            ? Math.min(1, Math.max(0, progressFraction)) * 100
            : null;
    const isComplete = progressPercent === 100;
    const isBlocked = !!ticket.blockers.length;

    return {
        isComplete,
        isBlocked,
        progressFraction,
        progressPercent,
    };
};

useTicketProgressInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketProgressInfo_ticket on tickets {
            id
            archived_at
            stage_id

            blockers: threads(
                where: { blocker_type: { _is_null: false }, resolved_at: { _is_null: true } }
            ) {
                id
            }

            board {
                id

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    board_pos
                }
            }
        }
    `),
};

export function useTicketWatcherInfo({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof TicketWatcherInfo_ticketFragmentDoc>;
}) {
    const currentUser = useCurrentUser();
    const { watcherUsers, isCurrentUserWatcher } = useTicketWatcherInfo._compute({
        ticket: getFragmentData(TicketWatcherInfo_ticketFragmentDoc, _ticketFragment),
        currentUser,
    });

    const result = useMemo(
        () => ({
            watcherUsers,
            isCurrentUserWatcher,
        }),
        [watcherUsers, isCurrentUserWatcher]
    );

    return result;
}

useTicketWatcherInfo._compute = ({
    ticket,
    currentUser,
}: {
    ticket: TicketWatcherInfo_ticketFragment;
    currentUser: CurrentUser;
}) => {
    const watcherUsers = ticket.watchers.map(to => to.watcher);
    const isCurrentUserWatcher = watcherUsers.some(
        watcherUser => watcherUser.id === currentUser.id
    );

    return {
        watcherUsers,
        isCurrentUserWatcher,
    };
};

useTicketWatcherInfo.fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketWatcherInfo_ticket on tickets {
            id

            watchers {
                watcher {
                    id
                    name
                }
            }
        }
    `),
};
