import React, { useCallback, useEffect, useRef, useState } from "react";

import { PopoverInteractionKind } from "@blueprintjs/core";
import {
    Select as BlueprintSelect,
    SelectProps as BlueprintSelectProps,
    ICreateNewItem,
    IItemRendererProps,
    getCreateNewItem,
} from "@blueprintjs/select";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { Icon } from "components/ui/core/Icon";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { EnumValue, Enums } from "lib/Enums";
import { useIsMounted } from "lib/Hooks";
import { isDefined } from "lib/types/guards";

import styles from "./Select.module.scss";

type SelectItemProps<T> = {
    disabled?: boolean;
    hideMenuItemIcon?: boolean;
    instrumentation: MenuItemProps["instrumentation"];
    isSelected: boolean;
    item: T;
    listItemProps: IItemRendererProps;
    menuItemClassName?: string;
    menuItemTextRenderer: (item: T, active: boolean) => React.ReactNode;
    onMouseMove: React.MouseEventHandler<HTMLAnchorElement>;
    shouldDismissPopover?: boolean;
};

function SelectItem<T>({
    disabled,
    hideMenuItemIcon,
    instrumentation,
    isSelected,
    item,
    listItemProps,
    menuItemClassName,
    menuItemTextRenderer,
    onMouseMove,
    shouldDismissPopover,
}: SelectItemProps<T>) {
    const { active } = listItemProps.modifiers;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        listItemProps.handleClick(e);
    };

    return (
        <MenuItem
            icon={
                hideMenuItemIcon ? null : isSelected ? (
                    <Icon icon="check" iconSet="lucide" iconSize={16} />
                ) : (
                    <Icon icon="blank" iconSet="c9r" />
                )
            }
            instrumentation={instrumentation}
            className={classNames(
                styles.menuItem,
                active && styles.menuItemActive,
                menuItemClassName
            )}
            text={menuItemTextRenderer(item, active)}
            onClick={handleClick}
            active={active}
            disabled={disabled}
            shouldDismissPopover={shouldDismissPopover}
            disableHoverEffect
            onMouseMove={onMouseMove}
        />
    );
}

// https://github.com/facebook/react/issues/16905
const emptyArray = [] as unknown[];
const defaultId = "id" as const;

export type SelectProps<T> = {
    children: React.ReactNode | (({ selectedItems }: { selectedItems: T[] }) => React.ReactNode);

    allowMultipleSelected?: boolean;
    className?: string;
    closePopover?: boolean;
    createNewItemRenderer?:
        | (({
              query,
              active,
              handleClick,
              handleMouseMove,
          }: {
              query: string;
              active: boolean;
              handleClick: React.MouseEventHandler<HTMLElement>;
              handleMouseMove: React.MouseEventHandler<HTMLAnchorElement>;
          }) => JSX.Element | undefined)
        | undefined;
    disabledPredicate?: (item: T) => boolean;
    getInstrumentation?: (item: T) => SelectItemProps<T>["instrumentation"];
    hideMenuItemIconMode?: EnumValue<"HideMenuItemIconMode">;
    idField?: keyof T;
    initiallySelectedItemsIDs?: (number | string)[];
    isOpen?: boolean;
    items: T[];
    menuItemClassName?: string;
    menuItemTextRenderer: (item: T, active: boolean) => React.ReactNode;
    onSelect: (item: T | null, didCreate?: boolean) => void;
    placeholder?: string;
    targetClassName?: string;
} & Pick<
    BlueprintSelectProps<T>,
    | "createNewItemFromQuery"
    | "filterable"
    | "itemListPredicate"
    | "itemListRenderer"
    | "itemPredicate"
> &
    Pick<
        Exclude<BlueprintSelectProps<T>["popoverProps"], undefined>,
        | "fill"
        | "onOpened"
        | "onOpening"
        | "onClosing"
        | "onInteraction"
        | "hoverCloseDelay"
        | "interactionKind"
        | "modifiers"
        | "placement"
        | "popoverClassName"
    >;

export function Select<T>({
    // Prop types shared with Suggest component.
    children,
    popoverClassName,
    filterable,
    items = emptyArray as T[],
    onSelect,
    isOpen: isControlledOpen,
    onOpening,
    onClosing,
    closePopover,
    menuItemClassName,
    menuItemTextRenderer,
    itemListRenderer,
    placement = "bottom-end",
    fill,
    interactionKind,
    hoverCloseDelay,
    modifiers,

    // Select specific props.
    className,
    targetClassName,
    // @ts-ignore
    idField = defaultId,
    initiallySelectedItemsIDs = emptyArray as (number | string)[],
    allowMultipleSelected,
    hideMenuItemIconMode = Enums.HideMenuItemIconMode.NEVER,
    placeholder = "Search items",
    createNewItemFromQuery,
    createNewItemRenderer,
    itemPredicate,
    itemListPredicate,
    disabledPredicate,
    onOpened,
    onInteraction,
    getInstrumentation,
}: SelectProps<T>) {
    const [allItems, setAllItems] = useState(items);
    const [selectedItems, setSelectedItems] = useState(
        initiallySelectedItemsIDs
            .map(id => items.find(itm => itm[idField] === id))
            .filter(isDefined)
    );
    const [activeItem, setActiveItem] = useState<T | ICreateNewItem | null>(null);
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef(null);
    const [hideMenuItemIcon, setHideMenuItemIcon] = useState<boolean | undefined>(
        {
            [Enums.HideMenuItemIconMode.ALWAYS]: true,
            [Enums.HideMenuItemIconMode.NEVER]: false,
            [Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED]: undefined,
        }[hideMenuItemIconMode]
    );

    useEffect(() => {
        setAllItems(items);
    }, [items]);

    useEffect(() => {
        setSelectedItems(
            initiallySelectedItemsIDs
                .map(id => items.find(itm => itm[idField] === id))
                .filter(isDefined)
        );
    }, [items, initiallySelectedItemsIDs, idField]);

    useEffect(() => {
        setIsOpen(prev => isControlledOpen ?? prev);
    }, [isControlledOpen]);

    useEffect(() => {
        if (closePopover) {
            setIsOpen(false);
        }
    }, [closePopover]);

    const handleActiveItemChange = useCallback(({ newActiveItem }: { newActiveItem: T | null }) => {
        setActiveItem(newActiveItem || getCreateNewItem());
    }, []);

    const renderItem = useCallback(
        (item: T, listItemProps: IItemRendererProps) => {
            return item ? (
                <SelectItem
                    menuItemClassName={menuItemClassName}
                    // @ts-ignore
                    key={item[idField] ?? uuidv4()}
                    item={item}
                    listItemProps={listItemProps}
                    isSelected={selectedItems.some(
                        selectedItem => selectedItem[idField] === item[idField]
                    )}
                    menuItemTextRenderer={menuItemTextRenderer}
                    instrumentation={getInstrumentation?.(item) ?? null}
                    disabled={disabledPredicate?.(item)}
                    shouldDismissPopover={!allowMultipleSelected}
                    hideMenuItemIcon={hideMenuItemIcon}
                    onMouseMove={() => {
                        if (activeItem !== item) {
                            handleActiveItemChange({ newActiveItem: item });
                        }
                    }}
                />
            ) : null;
        },
        [
            activeItem,
            allowMultipleSelected,
            disabledPredicate,
            getInstrumentation,
            handleActiveItemChange,
            hideMenuItemIcon,
            idField,
            menuItemClassName,
            menuItemTextRenderer,
            selectedItems,
        ]
    );

    const isMounted = useIsMounted();

    const handleItemAddAndRemove = (item: T) => {
        if (!allowMultipleSelected) {
            setIsOpen(false);

            const emptyItem = items.find(itm => itm[idField] === null);
            let newSelectedItems: T[];

            if (item === selectedItems[0] && emptyItem) {
                // For single select picker with an item representing "none", allow
                // clicking the already selected item to toggle it off.
                onSelect?.(null);
                newSelectedItems = [emptyItem];
            } else {
                onSelect?.(item[idField] ? item : null);
                newSelectedItems = [item];
            }

            // Hack to only set selectedItems after popover has closed to prevent
            // popover flashing with the item selected while closing.
            setTimeout(() => {
                if (isMounted()) {
                    setSelectedItems(newSelectedItems);
                }
            }, 40);
            return;
        }

        setQuery("");

        // As of October 2023, in Chrome, in prod, the input box was losing its cursor (but,
        // mysteriously, not its focus) on select. Blurring and refocusing the input box on select
        // as follows resolved the issue. (See `AbstractTextInput` for a similar issue.)
        setImmediate(() => {
            inputRef.current?.blur();
            inputRef.current?.focus();
        });

        const i = selectedItems.findIndex(itm => itm[idField] === item[idField]);

        if (i >= 0) {
            const newSelectedItems = selectedItems.slice(0, i).concat(selectedItems.slice(i + 1));

            setSelectedItems(newSelectedItems);
            onSelect?.(item, true);

            return;
        }

        if (!allItems.some(itm => itm[idField] === item[idField])) {
            setAllItems([...items, item]);
        }

        setSelectedItems([...selectedItems, item]);
        onSelect?.(item);
    };

    return (
        <BlueprintSelect
            // Stop Esc from closing outer/ other portals.
            // As of January 2023, BlueprintSelect's typing doesn't recognize that it does accept
            // an onKeyDown prop.
            // @ts-expect-error
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Escape") {
                    setIsOpen(false);
                    e.stopPropagation();
                    return false;
                }

                return true;
            }}
            query={query}
            onQueryChange={q => setQuery(q)}
            itemsEqual={idField}
            items={allItems}
            filterable={filterable}
            itemPredicate={itemPredicate}
            itemListPredicate={itemListPredicate}
            resetOnSelect
            resetOnClose
            createNewItemFromQuery={createNewItemFromQuery}
            createNewItemRenderer={(q, active, handleClick) =>
                createNewItemRenderer?.({
                    query: q,
                    active,
                    handleClick,
                    handleMouseMove: () => {
                        if (activeItem !== getCreateNewItem()) {
                            handleActiveItemChange({ newActiveItem: null });
                        }
                    },
                })
            }
            itemRenderer={renderItem}
            itemListRenderer={itemListRenderer}
            onItemSelect={handleItemAddAndRemove}
            popoverProps={{
                fill,
                transitionDuration: 30,
                popoverRef,
                isOpen,
                onInteraction: state => {
                    setIsOpen(state);
                    onInteraction?.(state);
                },
                onOpening: (...args) => {
                    const newSelectedItems = initiallySelectedItemsIDs
                        .map(id => items.find(itm => itm[idField] === id))
                        .filter(isDefined);

                    setSelectedItems(newSelectedItems);

                    if (hideMenuItemIconMode === Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED) {
                        setHideMenuItemIcon(!newSelectedItems.length);
                    }

                    onOpening?.(...args);
                },
                onOpened,
                onClosing,
                minimal: true,
                popoverClassName: classNames(popoverClassName, styles.itemsPopover),
                targetClassName: classNames(targetClassName, styles.itemsList),

                placement,
                // Necessary to suppress noisy warning about both position and placement
                // being specified. This won't be necessary in blueprint 4.
                position: undefined,
                modifiers,
                hasBackdrop: !interactionKind || interactionKind === PopoverInteractionKind.CLICK,
                interactionKind,
                hoverCloseDelay,
            }}
            className={classNames(className, styles.itemsPicker)}
            inputProps={{
                placeholder,
                className: styles.searchInput,
                leftIcon: null,
                inputRef,
            }}
            activeItem={activeItem}
            onActiveItemChange={newActiveItem => handleActiveItemChange({ newActiveItem })}
        >
            {typeof children === "function" ? children({ selectedItems }) : children}
        </BlueprintSelect>
    );
}
