import { useCallback, useEffect } from "react";

import { P } from "c9r-common";

import { Config } from "Config";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { setIdentityCookie } from "lib/mutations/user/setIdentityCookie";

export function useIdentityCookieManager() {
    const { isFeatureEnabled } = useFeatureFlags();

    const isIdentityCookieFeatureEnabled = isFeatureEnabled({
        feature: Enums.Feature.IDENTITY_COOKIE,
    });

    const trySetIdentityCookie = useCallback(() => {
        (async () => {
            if (isIdentityCookieFeatureEnabled) {
                try {
                    await P.retry(setIdentityCookie, Config.identityCookie.retryConfig);
                } catch (error) {
                    Log.error("Failed to set identity cookie", { error });
                }
            }
        })();
    }, [isIdentityCookieFeatureEnabled]);

    useEffect(trySetIdentityCookie, [trySetIdentityCookie]);
    useInterval(trySetIdentityCookie, Config.identityCookie.resetIntervalMs);
}
