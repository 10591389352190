import React from "react";

import { ElementProps, Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";

import { Config } from "Config";
import { useThemeContext } from "contexts/ThemeContext";
import { Log } from "lib/Log";

export const stripePromise = loadStripe(Config.stripePublishableKey);

stripePromise.catch(error => {
    Log.warn("Failed to load Stripe", { error });
});

export function StripeElementsWithAppearance({
    children,
    clientSecret,
    options,
    ...props
}: {
    children: React.ReactNode;
    clientSecret: string;
    options?: StripeElementsOptions;
    props?: ElementProps;
}) {
    const { isDarkMode } = useThemeContext();

    return (
        <Elements
            {...props}
            stripe={stripePromise}
            options={{
                loader: "always",
                ...options,
                clientSecret,

                // As of July 2022, these fonts and colors are set to match the defaults we've set
                // in index.scss, _fonts.scss, palette.scss, etc.
                // For simplicity, we didn't invest the time in creating a single source value for
                // these constants in both CSS and JS.
                appearance: isDarkMode
                    ? {
                          theme: "night",
                          variables: {
                              colorBackground: "#21272A", // --color-gray-cool-90
                              colorDanger: "#DA544F", // --color-status-red
                              colorPrimary: "#6FBEF7", // --color-blue-40
                              colorTextPlaceholder: "#878D96", // --color-placeholder
                              fontFamily: "SF Pro Text, system-ui, sans-serif", // --content-font-family
                          },
                          rules: {
                              ".Input": {
                                  boxShadow: "unset",
                              },
                              ".Input:focus": {
                                  border: "1px solid transparent",
                                  boxShadow: "unset",
                                  outline: "rgba(111, 190, 247, 0.75) solid 2px", // --color-focus-highlight-rgba
                              },
                          },
                      }
                    : {
                          theme: "stripe",
                          variables: {
                              colorDanger: "#DA544F", // --color-status-red
                              colorPrimary: "#167FCA", // --color-blue-40
                              colorTextPlaceholder: "#B6C1C9", // --color-placeholder
                              fontFamily: "SF Pro Text, system-ui, sans-serif", // --content-font-family
                          },
                          rules: {
                              ".Input": {
                                  boxShadow: "unset",
                              },
                              ".Input:focus": {
                                  border: "1px solid transparent",
                                  boxShadow: "unset",
                                  outline: "rgba(26, 145, 230, 0.75) solid 2px", // --color-focus-highlight-rgba
                              },
                          },
                      },
            }}
        >
            {children}
        </Elements>
    );
}
