import React, { useCallback, useEffect, useState } from "react";

import { Classes } from "@blueprintjs/core";
import { SelectProps as BlueprintSelectProps } from "@blueprintjs/select";
import { CommonEnumValue, LabelColors, TLabel, sortLabels } from "c9r-common";
import classNames from "classnames";
import stringify from "fast-json-stable-stringify";

import { QueryLoader, TQueryResult } from "components/loading/QueryLoader";
import { Label, LabelDisplay } from "components/shared/Label";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { Select } from "components/ui/core/Select";
import { useCurrentUser } from "contexts/UserContext";
import { getUniqueLabels, pickLabelColor } from "lib/Helpers";
import { useDialog, useResettingState } from "lib/Hooks";
import { gql } from "lib/graphql/__generated__";
import { LabelsPickerQuery } from "lib/graphql/__generated__/graphql";
import { useDeleteLabel, useUndeleteLabel, useUpdateLabel } from "lib/mutations";

import styles from "./LabelsPicker.module.scss";

type ColorPickerProps = {
    onClick: (color: string) => void;
    activeColor: CommonEnumValue<"LabelColor">;
    inUseColors: CommonEnumValue<"LabelColor">[];
    elementNamePrefix: string;
};

function ColorPicker({ onClick, activeColor, inUseColors, elementNamePrefix }: ColorPickerProps) {
    return (
        <div className={classNames(Classes.MENU, styles.colorPicker)}>
            {(Object.keys(LabelColors) as Array<keyof typeof LabelColors>).map(key => {
                const colorInUse = inUseColors.find(color => color === key);

                return (
                    <BorderButton
                        minimal
                        content={
                            <>
                                {activeColor === key ? (
                                    <Icon icon="check" iconSet="lucide" strokeWeight={2} />
                                ) : (
                                    <Icon icon="blank" iconSet="c9r" />
                                )}
                                <svg
                                    style={{
                                        visibility: colorInUse ? "visible" : "hidden",
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "0px",
                                    }}
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 4L8 0V7C8 7.55228 7.55228 8 7 8H0L4 4Z"
                                        fill={LabelColors[key].textColor}
                                    />
                                </svg>
                            </>
                        }
                        key={key}
                        className={classNames(Classes.MENU_ITEM, styles.colorPickerItem)}
                        contentClassName={styles.colorPickerItemContent}
                        instrumentation={{
                            elementName: `${elementNamePrefix}label_picker.edit_modal.color_swatch_btn`,
                            eventData: { color: key },
                        }}
                        onClick={() => onClick(key)}
                        style={{
                            background: LabelColors[key].bgColor,
                            color: LabelColors[key].textColor,
                            border: `1px solid ${LabelColors[key].bgColor}`,
                        }}
                    />
                );
            })}
        </div>
    );
}

type EditLabelDialogProps = {
    boardId: string;
    onClose: () => void;
    onSave: ({ oldLabel, newLabel }: { oldLabel: TLabel; newLabel: TLabel }) => void;
    onDelete: () => void;
    isOpen: boolean;
    originalLabel: TLabel;
    inUseColors: CommonEnumValue<"LabelColor">[];
    elementNamePrefix: string;
};

function EditLabelDialog({
    boardId,
    onClose,
    onSave,
    onDelete,
    isOpen,
    originalLabel,
    inUseColors,
    elementNamePrefix,
}: EditLabelDialogProps) {
    const [previewLabel, setPreviewLabel] = useState(originalLabel);
    const [disableSave, setDisableSave] = useState(true);

    useEffect(() => {
        if (isOpen) {
            setPreviewLabel(originalLabel);
        }
    }, [isOpen, originalLabel]);

    useEffect(() => {
        if (
            (previewLabel.text.trim() === originalLabel.text || !previewLabel.text.trim()) &&
            previewLabel.color === originalLabel.color
        ) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [previewLabel, originalLabel]);

    const previewLabelWithEmptyTextHandling = () => {
        const trimmedText = previewLabel.text.trim();
        return trimmedText
            ? {
                  ...previewLabel,
                  text: trimmedText,
              }
            : {
                  ...previewLabel,
                  text: originalLabel.text,
              };
    };

    return (
        <Dialog title="Edit label" isOpen={isOpen} onClose={onClose} className={styles.editDialog}>
            <Dialog.Body className={styles.editDialogBody}>
                <Menu className={styles.editDialogMenu}>
                    <input
                        autoFocus
                        className={classNames(Classes.INPUT, styles.previewLabelInput)}
                        value={previewLabel.text}
                        onChange={e => setPreviewLabel({ ...previewLabel, text: e.target.value })}
                        type="text"
                        placeholder="Label name"
                    />
                    <ColorPicker
                        activeColor={previewLabel.color as CommonEnumValue<"LabelColor">}
                        onClick={previewColorKey => {
                            setPreviewLabel({
                                ...previewLabel,
                                color: previewColorKey,
                            });
                        }}
                        inUseColors={inUseColors}
                        elementNamePrefix={elementNamePrefix}
                    />
                    <div className={styles.editPreview}>
                        <div>Preview</div>
                        <div>
                            <Label
                                className={styles.previewLabel}
                                color={previewLabelWithEmptyTextHandling().color}
                                text={previewLabelWithEmptyTextHandling().text}
                            />
                        </div>
                    </div>
                </Menu>
            </Dialog.Body>
            <Dialog.Footer className={classNames(Classes.DIALOG_FOOTER, styles.editDialogFooter)}>
                <BorderButton
                    content="Delete label"
                    minimal
                    instrumentation={{
                        elementName: `${elementNamePrefix}label_picker.edit_modal.delete_btn`,
                        eventData: {
                            boardId,
                            color: originalLabel.color,
                            text: originalLabel.text,
                        },
                    }}
                    onClick={onDelete}
                />
                <BorderButton
                    content="Save"
                    disabled={!!disableSave}
                    instrumentation={{
                        elementName: `${elementNamePrefix}label_picker.edit_modal.save_btn`,
                        eventData: {
                            boardId,
                            oldLabel: originalLabel,
                            newLabel: previewLabelWithEmptyTextHandling(),
                        },
                    }}
                    onClick={() => {
                        onSave({
                            oldLabel: originalLabel,
                            newLabel: previewLabelWithEmptyTextHandling(),
                        });
                    }}
                    primary
                />
            </Dialog.Footer>
        </Dialog>
    );
}

type EditableLabelItemProps = {
    active?: boolean;
    boardId: string;
    label: TLabel;
    onEdit: (label: TLabel) => void;
    elementNamePrefix: string;
};

const EditableLabelItem = React.memo(
    ({ active, boardId, label, onEdit, elementNamePrefix }: EditableLabelItemProps) => {
        return (
            <span
                className={classNames(
                    styles.labelItemWrapper,
                    active && styles.labelItemWrapperActive
                )}
            >
                <Label color={label.color} text={label.text} />
                <BorderButton
                    content={<Icon icon="edit-2" iconSet="lucide" iconSize={16} />}
                    instrumentation={{
                        elementName: `${elementNamePrefix}label_picker.label_edit_btn`,
                        eventData: { boardId, color: label.color, text: label.text },
                    }}
                    onClick={e => {
                        // Stop parent's handleLabelAddAndRemove onClick from firing.
                        e.stopPropagation();
                        onEdit(label);
                    }}
                    className={styles.editLabelBtn}
                    small
                    minimal
                />
            </span>
        );
    }
);

EditableLabelItem.displayName = "EditableLabelItem";

export type LabelsPickerProps = {
    className?: string;
    elementName?: string;
    boardId: string;
    initiallySelectedLabels: TLabel[];
    onAdd: (label: TLabel, labels: TLabel[]) => void;
    onRemove: (label: TLabel, labels: TLabel[]) => void;
    placeholderText?: string;
    popoverModifiers?: Exclude<
        BlueprintSelectProps<TLabel>["popoverProps"],
        undefined
    >["modifiers"];
};

export function LabelsPickerImpl({
    className,
    elementName,
    boardId,
    initiallySelectedLabels = [],
    onAdd,
    onRemove,
    placeholderText,
    popoverModifiers,
    queryResult,
}: LabelsPickerProps & {
    queryResult: TQueryResult<LabelsPickerQuery>;
}) {
    const elementNamePrefix = elementName ? `${elementName}.` : "";

    // Even if we don't know all labels yet or which ones are selectable, set the initial state
    // to the list of currently selected labels so that those selected labels appear immediately,
    // before the query below has completed.
    const [existingLabels, setExistingLabels] = useState(initiallySelectedLabels);
    const [pendingLabels, setPendingLabels] = useState<TLabel[]>([]);
    const [allLabels, setAllLabels] = useState(existingLabels.concat(pendingLabels));

    const [deletedLabels, setDeletedLabels] = useState<TLabel[]>([]);
    const [selectableLabels, setSelectableLabels] = useState(initiallySelectedLabels);
    const [selectedLabels, setSelectedLabels] = useState(
        initiallySelectedLabels.concat().sort(sortLabels()) || []
    );
    const [labelBeingEdited, setLabelBeingEdited] = useState<TLabel | null>(null);
    const [color, setColor] = useState<CommonEnumValue<"LabelColor">>(
        pickLabelColor({ labels: allLabels })
    );
    const [closePopover, setClosePopover] = useResettingState(false, 50);
    const editLabelDialog = useDialog();
    const { updateLabel } = useUpdateLabel();
    const { deleteLabel } = useDeleteLabel();
    const { undeleteLabel } = useUndeleteLabel();

    useEffect(() => setSelectedLabels(initiallySelectedLabels.concat().sort(sortLabels())), [
        initiallySelectedLabels,
    ]);

    useEffect(() => {
        if (!queryResult.loading && queryResult.data?.board) {
            setExistingLabels(
                getUniqueLabels({
                    labels: queryResult.data.board.tickets
                        .map(ticket => ticket.label_attachments)
                        .flat(),
                }).sort(sortLabels())
            );
            setDeletedLabels(queryResult.data.board.labels_config.deleted);
        }
    }, [queryResult.loading, queryResult.data]);

    useEffect(() => {
        const newPendingLabels = pendingLabels.filter(
            pendingLabel =>
                !existingLabels.find(
                    existingLabel =>
                        existingLabel.color === pendingLabel.color &&
                        existingLabel.text === pendingLabel.text
                )
        );

        if (!(stringify(newPendingLabels) === stringify(pendingLabels))) {
            setPendingLabels(newPendingLabels);
        }
    }, [existingLabels, pendingLabels]);

    useEffect(() => {
        setAllLabels(existingLabels.concat(pendingLabels));
    }, [existingLabels, pendingLabels]);

    useEffect(() => {
        setSelectableLabels(
            allLabels.filter(
                label =>
                    !deletedLabels.find(l => l.color === label.color && l.text === label.text) ||
                    initiallySelectedLabels.find(
                        l => l.color === label.color && l.text === label.text
                    )
            )
        );
    }, [allLabels, deletedLabels, initiallySelectedLabels]);

    const filterLabels = useCallback(
        (query: string, labels: TLabel[]) => {
            const normalize = (s: string) => s.toLowerCase();

            const normalizedQuery = normalize(query);

            const isSelected = (label: TLabel) =>
                selectedLabels.find(l => l.color === label.color && l.text === label.text);

            return labels
                .filter(label => label.text.toLowerCase().includes(normalizedQuery))
                .sort((a, b) => {
                    if (isSelected(a) && !isSelected(b)) {
                        return -1;
                    }

                    if (!isSelected(a) && isSelected(b)) {
                        return 1;
                    }

                    const normalizedTextA = normalize(a.text);
                    const normalizedTextB = normalize(b.text);

                    if (
                        normalizedTextA.startsWith(normalizedQuery) &&
                        !normalizedTextB.startsWith(normalizedQuery)
                    ) {
                        return -1;
                    }

                    if (
                        !normalizedTextA.startsWith(normalizedQuery) &&
                        normalizedTextB.startsWith(normalizedQuery)
                    ) {
                        return 1;
                    }

                    return sortLabels()(a, b);
                });
        },
        [selectedLabels]
    );

    const handleEditLabel = useCallback(
        (label: TLabel) => {
            setLabelBeingEdited(label);
            setClosePopover(true);
            editLabelDialog.open();
        },
        [editLabelDialog, setClosePopover]
    );

    const handleLabelAddAndRemove = async (label: TLabel | null, removeLabel = false) => {
        if (!label) {
            return;
        }

        if (removeLabel) {
            // In case the removed label is a pending label, update `pendingLabels`. (If it isn't,
            // then this is a no-op.)
            setPendingLabels(
                pendingLabels.filter(l => !(l.color === label.color && l.text === label.text))
            );

            onRemove(
                label,
                selectedLabels.filter(l => !(l.color === label.color && l.text === label.text))
            );
            return;
        }

        if (!existingLabels.find(l => l.color === label.color && l.text === label.text)) {
            setPendingLabels([...pendingLabels, label]);
        }

        // In case the added label is deleted, undelete it. (If it isn't, then this is a no-op.)
        await undeleteLabel({ boardId, label });

        setSelectedLabels([...selectedLabels, label]);
        onAdd(label, [...selectedLabels, label]);
    };

    const handleLabelUpdate = async ({
        oldLabel,
        newLabel,
    }: {
        oldLabel: TLabel;
        newLabel: TLabel;
    }) => {
        await updateLabel({
            boardId,
            oldLabel,
            newLabel,
        });

        // In case the updated label is deleted, undelete it. (If it isn't, then this is a no-op.)
        await undeleteLabel({ boardId, label: newLabel });

        if (pendingLabels.find(l => l.color === oldLabel.color && l.text === oldLabel.text)) {
            setPendingLabels(
                pendingLabels
                    .filter(l => !(l.color === oldLabel.color && l.text === oldLabel.text))
                    .concat({ color: newLabel.color, text: newLabel.text })
            );
        }

        if (selectedLabels.find(l => l.color === oldLabel.color && l.text === oldLabel.text)) {
            const selectedLabelsWithoutOldLabel = selectedLabels.filter(
                l => !(l.color === oldLabel.color && l.text === oldLabel.text)
            );

            onRemove(oldLabel, selectedLabelsWithoutOldLabel);

            const newSelectedLabels = selectedLabelsWithoutOldLabel.concat({
                color: newLabel.color,
                text: newLabel.text,
            });

            onAdd({ color: newLabel.color, text: newLabel.text }, newSelectedLabels);

            setSelectedLabels(newSelectedLabels);
        }
    };

    const handleLabelDelete = async (label: TLabel) => {
        await deleteLabel({ boardId, label });

        const index = selectedLabels.findIndex(
            l => l.color === label.color && l.text === label.text
        );

        if (index >= 0) {
            const newSelectedLabels = selectedLabels
                .slice(0, index)
                .concat(selectedLabels.slice(index + 1));

            setSelectedLabels(newSelectedLabels);
            onRemove(label, newSelectedLabels);
        }
    };

    return (
        <>
            <Select
                className={className}
                targetClassName={styles.labelsList}
                popoverClassName={styles.labelsPopover}
                menuItemClassName={styles.labelMenuItem}
                allowMultipleSelected
                initiallySelectedItemsIDs={initiallySelectedLabels.map(
                    label => `${label.color}|${label.text}`
                )}
                items={selectableLabels.map(label => ({
                    ...label,
                    id: `${label.color}|${label.text}`,
                }))}
                itemListPredicate={filterLabels}
                menuItemTextRenderer={(item, active) => (
                    <EditableLabelItem
                        active={active}
                        boardId={boardId}
                        label={item}
                        onEdit={handleEditLabel}
                        elementNamePrefix={elementNamePrefix}
                    />
                )}
                closePopover={closePopover}
                placeholder={
                    selectableLabels.length ? "Filter labels or create new" : "Create new label"
                }
                placement="bottom-start"
                modifiers={popoverModifiers}
                createNewItemFromQuery={q => {
                    const trimmedQuery = q.trim();

                    if (!trimmedQuery) {
                        return (undefined as unknown) as TLabel;
                    }

                    setColor(pickLabelColor({ labels: allLabels }));

                    return {
                        color,
                        text: trimmedQuery,
                    };
                }}
                createNewItemRenderer={({ query, active, handleClick, handleMouseMove }) => {
                    const trimmedQuery = query.trim();

                    // Don't show if query == an existing label.
                    if (
                        !trimmedQuery ||
                        selectableLabels.some(l => l.text === trimmedQuery && l.color === color)
                    ) {
                        return undefined;
                    }

                    return (
                        <MenuItem
                            className={styles.labelMenuItem}
                            text={
                                <span className={styles.labelItemWrapper}>
                                    <span className={styles.createLabel}>
                                        Create <LabelDisplay color={color} text={query} />
                                    </span>
                                </span>
                            }
                            active={active}
                            onClick={handleClick}
                            shouldDismissPopover={false}
                            instrumentation={{
                                elementName: "labels_picker.menu.create_label",
                            }}
                            disableHoverEffect
                            onMouseMove={handleMouseMove}
                        />
                    );
                }}
                onSelect={handleLabelAddAndRemove}
            >
                {({ selectedItems }) => (
                    <button type="button" className={styles.tagWrapper}>
                        {selectedItems.filter(Boolean).length ? (
                            selectedItems
                                .sort(sortLabels())
                                .map(label => (
                                    <Label
                                        className={styles.labelTag}
                                        key={`${label.color}|${label.text}`}
                                        color={label.color}
                                        text={label.text}
                                    />
                                ))
                        ) : (
                            <span className={styles.tagPlaceholder}>
                                <Icon icon="plus" iconSet="lucide" iconSize={16} />
                                <span>{placeholderText ?? "Labels"}</span>
                            </span>
                        )}
                    </button>
                )}
            </Select>
            {labelBeingEdited ? (
                <EditLabelDialog
                    boardId={boardId}
                    isOpen={editLabelDialog.isOpen}
                    originalLabel={labelBeingEdited}
                    onClose={editLabelDialog.close}
                    onSave={({ oldLabel, newLabel }) => {
                        void handleLabelUpdate({ oldLabel, newLabel });
                        editLabelDialog.close();
                    }}
                    onDelete={() => {
                        void handleLabelDelete(labelBeingEdited);
                        editLabelDialog.close();
                    }}
                    inUseColors={allLabels.map(
                        label => label.color as CommonEnumValue<"LabelColor">
                    )}
                    elementNamePrefix={elementNamePrefix}
                />
            ) : null}
        </>
    );
}

export function LabelsPicker(props: LabelsPickerProps) {
    const currentUser = useCurrentUser();

    return (
        <QueryLoader
            query={LabelsPicker.queries.component}
            variables={{ orgId: currentUser.org_id, boardId: props.boardId }}
        >
            {({ queryResult }) => <LabelsPickerImpl {...props} queryResult={queryResult} />}
        </QueryLoader>
    );
}

LabelsPicker.queries = {
    component: gql(/* GraphQL */ `
        query LabelsPicker($boardId: uuid!) {
            board: boards_by_pk(id: $boardId) {
                id
                labels_config

                tickets {
                    id

                    label_attachments {
                        color
                        text
                    }
                }
            }
        }
    `),
};
