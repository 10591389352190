import { useCallback } from "react";

import { v4 as uuidv4 } from "uuid";

import { useReplicache } from "lib/replicache/Context";

export function useCreateTicketAttachment() {
    const { replicache } = useReplicache();

    const createTicketAttachment = useCallback(
        async ({
            ticketId,
            title,
            key,
        }: {
            ticketId: string;
            title: string;
            key: string | null;
        }) => {
            const ticketAttachmentId = uuidv4();

            await replicache.mutate.createTicketAttachment({
                key,
                ticketAttachmentId,
                ticketId,
                title,
            });
        },
        [replicache]
    );

    return { createTicketAttachment };
}

export function useDeleteTicketAttachment() {
    const { replicache } = useReplicache();

    const deleteTicketAttachment = useCallback(
        async ({ attachmentId }: { attachmentId: string }) => {
            await replicache.mutate.deleteTicketAttachment({
                ticketAttachmentId: attachmentId,
            });
        },
        [replicache]
    );

    return { deleteTicketAttachment };
}
