import { useCallback, useEffect } from "react";

import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import { Config } from "Config";

export const useTrackingUid = ({ setOrTouchOnRender = false } = {}) => {
    const getTrackingUid = useCallback(() => Cookies.get(Config.tracking.cookie.key), []);

    const setOrTouchTrackingUid = useCallback(() => {
        const value = getTrackingUid();

        Cookies.set(Config.tracking.cookie.key, value ?? uuidv4(), {
            domain: Config.tracking.cookie.domain,
            expires: Config.tracking.cookie.expirationDays,
        });
    }, [getTrackingUid]);

    useEffect(() => {
        if (setOrTouchOnRender) {
            setOrTouchTrackingUid();
        }
    }, [setOrTouchOnRender, setOrTouchTrackingUid]);

    return { getTrackingUid, setOrTouchTrackingUid };
};
