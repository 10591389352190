import { useCallback } from "react";

import { CommonEnumValue, CommonEnums, sortStages } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { moveToFirstPosition, moveToLastPosition } from "lib/EntityPositioning";
import { gql } from "lib/graphql/__generated__";
import { useReplicache } from "lib/replicache/Context";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

type TStagePositions = {
    boardPos: number | null;
    minTicketStagePos: number | null;
    maxTicketStagePos: number | null;
};

function useGetStagePositions() {
    const replicacheGraphQLClient = useReplicacheGraphQLClient();

    const getStagePositions = useCallback(
        async ({ stageId }: { stageId: string }): Promise<TStagePositions | null> => {
            const fragmentArgs = {
                id: stageId,
                fragment: gql(/* GraphQL */ `
                    fragment GetCachedStagePositions_stage on stages {
                        id
                        board_pos
                        min_ticket_stage_pos
                        max_ticket_stage_pos
                    }
                `),
            };

            const cachedStage = await replicacheGraphQLClient.readFragment(fragmentArgs);

            if (cachedStage) {
                return {
                    boardPos: cachedStage.board_pos,
                    minTicketStagePos: cachedStage.min_ticket_stage_pos,
                    maxTicketStagePos: cachedStage.max_ticket_stage_pos,
                };
            }

            const queryArgs = {
                query: gql(/* GraphQL */ `
                    query GetStagePositions($stageId: uuid!) {
                        stages_by_pk(id: $stageId) {
                            id
                            board_pos
                            min_ticket_stage_pos
                            max_ticket_stage_pos
                        }
                    }
                `),
                variables: {
                    stageId,
                },
            };

            const stage = (await replicacheGraphQLClient.readQuery(queryArgs))?.stages_by_pk;

            if (!stage) {
                return null;
            }

            return {
                boardPos: stage.board_pos,
                minTicketStagePos: stage.min_ticket_stage_pos,
                maxTicketStagePos: stage.max_ticket_stage_pos,
            };
        },
        [replicacheGraphQLClient]
    );

    return { getStagePositions };
}

/**
 * Move a ticket to a stage and position within its current board or a different board.
 */
export function useMoveTicketToStagePosition() {
    const { replicache } = useReplicache();

    const moveTicketToStagePosition = useCallback(
        async ({
            ticketId,
            toStageId,
            toStagePos,
            toBoardId,
        }: {
            ticketId: string;
            toStageId: string;
            toStagePos: number;
            toBoardId: string;
        }) => {
            await replicache.mutate.updateTicketStage({
                ticketId,
                toStageId,
                toStagePos,
            });
        },
        [replicache]
    );

    return { moveTicketToStagePosition };
}

/**
 * Moves tickets to ticket-specific positions in a stage.
 */
export function useMoveTicketsToStagePositions() {
    const currentUser = useCurrentUser();
    const { replicache } = useReplicache();

    const moveTicketsToStagePositions = useCallback(
        async ({
            ticketIds,
            toStageId,
            toStagePoses,
            toBoardId,
        }: {
            ticketIds: string[];
            toStageId: string;
            toStagePoses: number[];
            toBoardId: string;
        }) => {
            await replicache.mutate.bulkMutation({
                orgId: currentUser.org_id,
                mutations: ticketIds.map((ticketId, i) => ({
                    name: "updateTicketStage",
                    args: {
                        ticketId,
                        toStageId,
                        toStagePos: toStagePoses[i],
                    },
                })),
            });
        },
        [currentUser.org_id, replicache]
    );

    return { moveTicketsToStagePositions };
}

/**
 * Move a ticket either forward or backward in its board's workflow. If moving forward,
 * move to the bottom of the next stage. If moving backward, move to the top of the
 * previous stage.
 *
 * Note: This mutation is not suitable when the ticket is being displayed on a board,
 * since it doesn't handle all cache updates for the stage the ticket is coming from.
 */
export function useMoveTicketByDirectionInWorkflow() {
    const currentUser = useCurrentUser();
    const { getStagePositions } = useGetStagePositions();
    const { moveTicketToStagePosition } = useMoveTicketToStagePosition();

    const moveTicketByDirectionInWorkflow = useCallback(
        async ({
            ticketId,
            fromStageId,
            toStageDirection,
        }: {
            ticketId: string;
            fromStageId: string;
            toStageDirection: CommonEnumValue<"Direction">;
        }) => {
            const fromStage = currentUser.org.all_boards
                .flatMap(b => b.all_stages)
                .find(s => s.id === fromStageId);

            if (!fromStage) {
                return undefined;
            }

            const boardId = fromStage.board_id;
            const board = currentUser.org.all_boards.find(b => b.id === boardId);

            if (!board) {
                return undefined;
            }

            const stages = board?.all_stages.filter(s => !s.deleted_at).sort(sortStages());

            const toStage =
                stages[
                    stages.findIndex(s => s === fromStage) +
                        (toStageDirection === CommonEnums.Direction.FORWARD ? 1 : -1)
                ];

            if (!toStage) {
                return undefined;
            }

            const toBoardId = toStage.board_id;
            const toStageId = toStage.id;
            const stagePositions = await getStagePositions({ stageId: toStageId });

            if (!stagePositions) {
                return undefined;
            }

            const toStagePos =
                toStageDirection === CommonEnums.Direction.FORWARD
                    ? moveToLastPosition({ maxPos: stagePositions.maxTicketStagePos })
                    : moveToFirstPosition({ minPos: stagePositions.minTicketStagePos });

            return moveTicketToStagePosition({ ticketId, toBoardId, toStageId, toStagePos });
        },
        [currentUser, getStagePositions, moveTicketToStagePosition]
    );

    return { moveTicketByDirectionInWorkflow };
}

/**
 * Move a ticket to the start of its current stage.
 */
export function useMoveTicketToStartOfCurrentStage() {
    const currentUser = useCurrentUser();
    const { getStagePositions } = useGetStagePositions();
    const { moveTicketToStagePosition } = useMoveTicketToStagePosition();

    const moveTicketToStartOfCurrentStage = useCallback(
        async ({ ticketId, onStageId }: { ticketId: string; onStageId: string }) => {
            const onStage = currentUser.org.all_boards
                .flatMap(b => b.all_stages)
                .find(s => s.id === onStageId);

            if (!onStage) {
                return undefined;
            }

            const stagePositions = await getStagePositions({ stageId: onStage.id });

            if (!stagePositions) {
                return undefined;
            }

            const toBoardId = onStage.board_id;
            const toStagePos = moveToFirstPosition({
                minPos: stagePositions.minTicketStagePos,
            });

            return moveTicketToStagePosition({
                ticketId,
                toBoardId,
                toStageId: onStageId,
                toStagePos,
            });
        },
        [currentUser, getStagePositions, moveTicketToStagePosition]
    );

    return { moveTicketToStartOfCurrentStage };
}

/**
 * Move a ticket to the end of its current stage.
 */
export function useMoveTicketToEndOfCurrentStage() {
    const currentUser = useCurrentUser();
    const { getStagePositions } = useGetStagePositions();
    const { moveTicketToStagePosition } = useMoveTicketToStagePosition();

    const moveTicketToEndOfCurrentStage = useCallback(
        async ({ ticketId, onStageId }: { ticketId: string; onStageId: string }) => {
            const onStage = currentUser.org.all_boards
                .flatMap(b => b.all_stages)
                .find(s => s.id === onStageId);

            if (!onStage) {
                return undefined;
            }

            const stagePositions = await getStagePositions({ stageId: onStage.id });

            if (!stagePositions) {
                return undefined;
            }

            const toBoardId = onStage.board_id;
            const toStagePos = moveToLastPosition({
                maxPos: stagePositions.maxTicketStagePos,
            });

            return moveTicketToStagePosition({
                ticketId,
                toBoardId,
                toStageId: onStageId,
                toStagePos,
            });
        },
        [currentUser, getStagePositions, moveTicketToStagePosition]
    );

    return { moveTicketToEndOfCurrentStage };
}
