import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useUpdateTasklistStage() {
    const { replicache } = useReplicache();

    const updateTasklistStage = useCallback(
        async ({ tasklistId, stageId }: { tasklistId: string; stageId: string }) => {
            await replicache.mutate.updateTasklist({ tasklistId, stageId });
        },
        [replicache]
    );

    return { updateTasklistStage };
}

export function useUpdateTasklistTitle() {
    const { replicache } = useReplicache();

    const updateTasklistTitle = useCallback(
        async ({ tasklistId, title }: { tasklistId: string; title: string }) => {
            await replicache.mutate.updateTasklist({ tasklistId, title });
        },
        [replicache]
    );

    return { updateTasklistTitle };
}
