import { useCallback } from "react";

import { TLabel } from "c9r-common";

import { useReplicache } from "lib/replicache/Context";

export function useAttachLabel() {
    const { replicache } = useReplicache();

    const attachLabel = useCallback(
        async ({ ticketId, label }: { ticketId: string; label: TLabel }) => {
            await replicache.mutate.updateTicketLabels({
                ticketId,
                labelsToAdd: [label],
            });
        },
        [replicache]
    );

    return { attachLabel };
}

export function useDetachLabel() {
    const { replicache } = useReplicache();

    const detachLabel = useCallback(
        async ({ ticketId, label }: { ticketId: string; label: TLabel }) => {
            await replicache.mutate.updateTicketLabels({
                ticketId,
                labelsToRemove: [label],
            });
        },
        [replicache]
    );

    return { detachLabel };
}
