import React, { useEffect } from "react";

import { CommonEnums } from "c9r-common";
import { Redirect } from "react-router-dom";

import { useSetIsViewReady } from "components/loading/Loading";
import { BorderButton } from "components/ui/core/BorderButton";
import { useCurrentUser } from "contexts/UserContext";
import { useCreateBoardDialog } from "dialogs/CreateBoardDialog";
import { pickDefaultBoard } from "lib/Helpers";
import { useUrlBuilders } from "lib/Urls";

import styles from "./NoBoardsZeroStateView.module.scss";

export function NoBoardsZeroStateView() {
    const currentUser = useCurrentUser();
    const { buildBoardUrl } = useUrlBuilders();
    const dialog = useCreateBoardDialog();
    const setIsViewReady = useSetIsViewReady();

    const board = pickDefaultBoard({ org: currentUser.org });

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    if (board) {
        return (
            <Redirect
                to={
                    buildBoardUrl({
                        boardSlug: board.slug,
                        vanity: { boardDisplayName: board.display_name },
                    }).pathname
                }
            />
        );
    }

    const createBoardButton = (
        <BorderButton
            brandCta
            content="Create a workspace"
            instrumentation={null}
            onClick={dialog.open}
        />
    );

    return (
        <div className={styles.view}>
            <p className={styles.text}>
                <strong>
                    There are no workspaces in {currentUser.org.display_name} (that you can access).
                </strong>

                {(() => {
                    switch (currentUser.role) {
                        case CommonEnums.UserRole.USER: {
                            return (
                                <>
                                    <br />
                                    Ask your team admin to add you to a workspace. Or, you can
                                    create one.
                                    <br />
                                    <br />
                                    {createBoardButton}
                                </>
                            );
                        }

                        case CommonEnums.UserRole.USER_ORG_ADMIN: {
                            return (
                                <>
                                    <br />
                                    <br />
                                    {createBoardButton}
                                </>
                            );
                        }

                        case CommonEnums.UserRole.USER_ORG_GUEST: {
                            return (
                                <>
                                    <br />
                                    Ask your team admin to add you to a workspace.
                                </>
                            );
                        }

                        default:
                            return null;
                    }
                })()}
            </p>
        </div>
    );
}
