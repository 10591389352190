import React, { useCallback } from "react";

import { TLabel } from "c9r-common";

import { LabelsPicker } from "components/shared/LabelsPicker";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useAttachLabel, useDetachLabel } from "lib/mutations";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketLabelsPicker_ticket on tickets {
            id
            board_id

            label_attachments {
                color
                text
            }
        }
    `),
};

export type TicketLabelsPickerProps = {
    className?: string;
    placeholderText?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TicketLabelsPicker({
    className,
    placeholderText,
    ticket: _ticketFragment,
}: TicketLabelsPickerProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { attachLabel } = useAttachLabel();
    const { detachLabel } = useDetachLabel();

    const handleLabelAdd = useCallback(
        async (label: TLabel) => {
            await attachLabel({
                ticketId: ticket.id,
                label,
            });
        },
        [attachLabel, ticket.id]
    );

    const handleLabelRemove = useCallback(
        async (label: TLabel) => {
            await detachLabel({
                ticketId: ticket.id,
                label,
            });
        },
        [detachLabel, ticket.id]
    );

    return (
        <LabelsPicker
            className={className}
            boardId={ticket.board_id}
            initiallySelectedLabels={ticket.label_attachments}
            placeholderText={placeholderText}
            onAdd={handleLabelAdd}
            onRemove={handleLabelRemove}
        />
    );
}
