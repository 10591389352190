import React, { useCallback, useMemo } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog } from "components/ui/core/Dialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog } from "lib/Hooks";

import styles from "./GitHubImport.module.scss";

type GitHubImportDialogProps = { isOpen: boolean; onClose?: () => void };

function GitHubImportDialog({ isOpen, onClose }: GitHubImportDialogProps) {
    return (
        <Dialog
            className={styles.dialog}
            title="Import from GitHub"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <p>
                    If you're interested in importing from GitHub issues,{" "}
                    <SupportMailto text="contact us" />. We'd be happy to work with you – for free –
                    to get your data into Flat.
                </p>
            </Dialog.Body>
            <Dialog.Footer />
        </Dialog>
    );
}

const useGitHubImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();

    const githubImportDialog = useMemo(
        () => <GitHubImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateGitHubImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        dialog.open();
    }, [dialog, gateFeature]);

    return { initiateGitHubImport, githubImportDialog };
};

export { useGitHubImport };
