import React from "react";

import { CommonEnumValue } from "c9r-common";
import classNames from "classnames";

import { BorderButton, BorderButtonProps } from "components/ui/core/BorderButton";
import { Tooltip } from "components/ui/core/Tooltip";
import { useGetTaskStatusInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TasksProgress_taskFragment } from "lib/graphql/__generated__/graphql";

import styles from "./TasksProgress.module.scss";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment TasksProgress_task on tasks {
            id
            task_type

            ...TaskStatusInfo_task
        }
    `),
};

function useTaskBreakdown({
    tasks,
    taskTypes,
}: {
    tasks: TasksProgress_taskFragment[];
    taskTypes?: CommonEnumValue<"TaskType">[];
}) {
    const { getTaskStatusInfo } = useGetTaskStatusInfo();
    const eligibleTasks = tasks.filter(
        task =>
            !getTaskStatusInfo({ task }).isHidden &&
            (!taskTypes || taskTypes.includes(task.task_type))
    );

    return {
        completedTasks: eligibleTasks.filter(task => getTaskStatusInfo({ task }).isComplete),
        incompleteTasks: eligibleTasks.filter(task => !getTaskStatusInfo({ task }).isComplete),
    };
}

type TasksProgressBarSegmentProps = {
    className?: string;
    isComplete?: boolean;
    small?: boolean;
};

function TasksProgressBarSegment({ className, isComplete, small }: TasksProgressBarSegmentProps) {
    return (
        <span
            className={classNames(
                className,
                styles.segment,
                isComplete && styles.completed,
                small && styles.small
            )}
        />
    );
}

export type TasksProgressBarProps = {
    className?: string;
    small?: boolean;
    tasks: FragmentType<typeof fragments.task>[];
    taskTypes?: CommonEnumValue<"TaskType">[];
};

export function TasksProgressBar({
    className,
    small,
    tasks: _taskFragments,
    taskTypes,
}: TasksProgressBarProps) {
    const tasks = _taskFragments.map(_taskFragment =>
        getFragmentData(fragments.task, _taskFragment)
    );
    const { completedTasks, incompleteTasks } = useTaskBreakdown({ tasks, taskTypes });

    return (
        <span className={classNames(className, styles.progressBar)}>
            {completedTasks.map(task => (
                <TasksProgressBarSegment key={task.id} isComplete small={small} />
            ))}
            {incompleteTasks.map(task => (
                <TasksProgressBarSegment key={task.id} small={small} />
            ))}
        </span>
    );
}

export type TasksProgressReportProps = {
    className?: string;
    tasks: FragmentType<typeof fragments.task>[];
    taskTypes?: CommonEnumValue<"TaskType">[];
};

export function TasksProgressReport({
    className,
    tasks: _taskFragments,
    taskTypes,
}: TasksProgressReportProps) {
    const tasks = _taskFragments.map(_taskFragment =>
        getFragmentData(fragments.task, _taskFragment)
    );
    const { completedTasks, incompleteTasks } = useTaskBreakdown({ tasks, taskTypes });

    return (
        <span className={classNames(className, styles.progressReport)}>
            {completedTasks.length}/{completedTasks.length + incompleteTasks.length}
        </span>
    );
}

export type TasksProgressProps = {
    className?: string;
    children: React.ReactNode;
};

export function TasksProgress({ className, children }: TasksProgressProps) {
    return <div className={classNames(className, styles.tasksProgress)}>{children}</div>;
}

export type TasksProgressButtonProps = {
    className?: string;
    children: React.ReactNode;
    handleClick: BorderButtonProps["onClick"];
    isTasklistCollapsed?: boolean;
    instrumentation: BorderButtonProps["instrumentation"];
};

export function TasksProgressButton({
    className,
    children,
    handleClick,
    isTasklistCollapsed,
    instrumentation,
}: TasksProgressButtonProps) {
    return (
        <Tooltip
            className={styles.tasksProgressTooltip}
            content="Hide completed"
            disabled={isTasklistCollapsed}
            openOnTargetFocus={false}
            placement="top"
            small
        >
            <BorderButton
                className={styles.tasksProgressBtn}
                contentClassName={classNames(className, styles.tasksProgress)}
                onClick={handleClick}
                minimal
                textHover
                content={
                    <>
                        {isTasklistCollapsed && <span className={styles.showAll}>Show all</span>}
                        {children}
                    </>
                }
                instrumentation={instrumentation}
            />
        </Tooltip>
    );
}
