import { useCallback } from "react";

import { JSONContent } from "@tiptap/core";
import { CommonEnums, TLabel, TSizeSpec, TTasklist } from "c9r-common";
import { format as formatDate, parse as parseDate } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { TAttachment } from "components/shared/Attachments";
import { generatePositionsBetween } from "lib/EntityPositioning";
import { generateTicketSlug } from "lib/Slugs";
import { gql } from "lib/graphql/__generated__";
import { useReplicache } from "lib/replicache/Context";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";
import { isDefined } from "lib/types/guards";

export function useCreateTicket() {
    const { replicache } = useReplicache();

    const createTicket = useCallback(
        async ({
            boardId,
            stageId,
            stagePos,
            title,
            descriptionJSON,
            labels,
            ownerUserId,
            memberUserIds,
            attachments,
            tasklists,
            sizeSpec,
            dueDate,
            makeCurrentUserWatcher,
        }: {
            boardId: string;
            stageId: string;
            stagePos: number;
            title: string;
            descriptionJSON?: JSONContent;
            labels?: TLabel[];
            ownerUserId?: number;
            memberUserIds?: number[];
            attachments?: TAttachment[];
            tasklists?: TTasklist[];
            sizeSpec?: TSizeSpec;
            dueDate?: Date;
            makeCurrentUserWatcher?: boolean;
        }) => {
            const ticketId = uuidv4();
            const slug = generateTicketSlug();

            const formattedDueDate = dueDate ? formatDate(dueDate, "yyyy-MM-dd") : null;

            const ticketRef = await replicache.mutate.createTicket(
                JSON.parse(
                    JSON.stringify({
                        ticketId,
                        stageId,
                        stagePos,
                        title,
                        slug,
                        description: descriptionJSON,
                        labels,
                        ownerUserId,
                        memberUserIds,
                        attachments: attachments?.map(a => ({ ...a, id: uuidv4() })),
                        sizeSpec,
                        dueDate: formattedDueDate,
                        makeCurrentUserWatcher,
                        tasklists: tasklists?.map(tl => ({
                            ...tl,
                            id: uuidv4(),
                            uuid: uuidv4(),
                            tasks: tl.tasks.map(t => ({ ...t, id: uuidv4() })),
                        })),
                    })
                )
            );

            return ticketRef !== undefined ? { id: ticketId, ref: ticketRef, slug } : undefined;
        },
        [replicache]
    );

    return { createTicket };
}

/**
 * Clone a ticket, copying most of its details to a new ticket.
 */
export function useCloneTicket() {
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const { createTicket } = useCreateTicket();

    const cloneTicket = useCallback(
        async ({ sourceTicketId }: { sourceTicketId: string }) => {
            const args = {
                query: gql(/* GraphQL */ `
                    query GetSourceTicketForClone($ticketId: uuid!) {
                        tickets_by_pk(id: $ticketId) {
                            id
                            board_id
                            stage_id
                            stage_pos
                            title
                            description_json
                            description_html
                            description_text
                            size_spec
                            due_date

                            label_attachments {
                                color
                                text
                            }

                            owners {
                                ticket_id
                                user_id
                                type
                            }

                            stage {
                                id

                                tickets {
                                    id
                                    stage_pos
                                }
                            }

                            tasklists(where: { deleted_at: { _is_null: true } }) {
                                id
                                stage_id
                                title

                                tasks(where: { deleted_at: { _is_null: true } }) {
                                    id
                                    assigned_to_user_id
                                    child_ticket_id
                                    due_date
                                    is_complete
                                    tasklist_pos
                                    task_type
                                    title
                                }
                            }
                        }
                    }
                `),
                variables: {
                    ticketId: sourceTicketId,
                },
            };

            const sourceTicket = (await replicacheGraphQLClient.readQuery(args))?.tickets_by_pk;

            if (!sourceTicket || !sourceTicket.stage_id || !sourceTicket.stage) {
                return null;
            }

            const tasklists = sourceTicket.tasklists.map(tl => ({
                stageId: tl.stage_id,
                title: tl.title,
                tasks: tl.tasks.map(t => ({
                    assignedToUserId: t.assigned_to_user_id,
                    childTicketId: t.child_ticket_id,
                    dueDate: t.due_date,
                    isComplete: t.is_complete,
                    tasklistPos: t.tasklist_pos,
                    title: t.title,
                })),
            }));

            const newMinimalTicket = await createTicket({
                boardId: sourceTicket.board_id,
                stageId: sourceTicket.stage_id,
                stagePos: generatePositionsBetween({
                    fromPos: sourceTicket.stage_pos,
                    toPos: sourceTicket.stage.tickets
                        .map(t => t.stage_pos)
                        .filter(isDefined)
                        .filter(stagePos => stagePos > (sourceTicket.stage_pos ?? 0))
                        .reduce((a, b) => Math.min(a, b), Infinity),
                    count: 1,
                })[0],
                title: `[Duplicated]: ${sourceTicket.title}`,
                descriptionJSON: sourceTicket.description_json ?? undefined,
                labels: sourceTicket.label_attachments,
                ownerUserId: sourceTicket.owners.find(
                    to => to.type === CommonEnums.TicketOwnerType.OWNER
                )?.user_id,
                memberUserIds: sourceTicket.owners
                    .filter(to => to.type === CommonEnums.TicketOwnerType.MEMBER)
                    .map(to => to.user_id),
                sizeSpec: sourceTicket.size_spec ?? undefined,
                dueDate: sourceTicket.due_date
                    ? parseDate(sourceTicket.due_date, "yyyy-MM-dd", new Date())
                    : undefined,
                makeCurrentUserWatcher: true,
                tasklists,
            });

            const ticketId = newMinimalTicket?.id;

            if (!ticketId) {
                return null;
            }

            return { ticketId };
        },
        [createTicket, replicacheGraphQLClient]
    );

    return { cloneTicket };
}
