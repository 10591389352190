import React, { useEffect } from "react";

import { useSetIsViewReady } from "components/loading/Loading";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { useDocumentTitle } from "lib/Hooks";
import { Link, useHistory } from "lib/Routing";

import styles from "./DelayedRedirectView.module.scss";

export type DelayedRedirectViewProps = {
    to: string;
    message?: string;
    delayMs?: number;
};

export function DelayedRedirectView({ to, message, delayMs = 5000 }: DelayedRedirectViewProps) {
    const { history } = useHistory();
    const setIsViewReady = useSetIsViewReady();

    useDocumentTitle();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            history.push(to);
        }, delayMs);

        return () => clearTimeout(timeout);
    }, [delayMs, history, to]);

    return (
        <div className={styles.delayedRedirectView}>
            <BrandLogomark className={styles.logomark} height={30} />
            {message ? <p className={styles.header}>{message}</p> : null}
            <p className={styles.text}>You will be redirected in a few seconds.</p>
            <p className={styles.link}>
                <Link to={to}>Take me there</Link>
            </p>
        </div>
    );
}
