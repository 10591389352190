import React from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import { Icon, IconProps } from "components/ui/core/Icon";
import { AbstractButton, AbstractButtonProps } from "components/ui/core/abstract/AbstractButton";

import styles from "./BorderButton.module.scss";

export type BorderButtonProps = {
    content: React.ReactNode;
    contentClassName?: string;
    fill?: boolean;
    loading?: boolean;

    alignContentLeft?: boolean;
    alignContentRight?: boolean;

    leftIconProps?: IconProps;
    rightIconProps?: IconProps;
    iconGap?: number;
    iconSeparate?: boolean;
    minimal?: boolean;
    sharper?: boolean;
    sharperer?: boolean;
    sharpest?: boolean;
    useHoverEffect?: boolean;

    themeFlip?: boolean;
    themeLight?: boolean;
    themeDark?: boolean;
    themeDarkHighContrast?: boolean;

    brandCta?: boolean;
    brandFlatCta?: boolean;
    brandFlatFancyCta?: boolean;
    cta?: boolean;
    primary?: boolean;
    danger?: boolean;
    textHover?: boolean;

    small?: boolean;
    large?: boolean;

    square?: boolean;
    tight?: boolean;
    tighter?: boolean;
    tighterer?: boolean;
    tightest?: boolean;
    zeroPadding?: boolean;

    flush?: boolean;
    flushVertical?: boolean;
    flushSide?: boolean;
    flushTop?: boolean;
    flushRight?: boolean;
    flushBottom?: boolean;
    flushLeft?: boolean;
} & Omit<AbstractButtonProps, "children">;

export function BorderButton({
    content,
    contentClassName,
    fill = false,
    loading = false,

    alignContentLeft,
    alignContentRight,

    leftIconProps,
    rightIconProps,
    iconGap,
    iconSeparate,
    minimal,
    sharper,
    sharperer,
    sharpest,
    useHoverEffect = true,

    // Color themes. Mutually exclusive.
    themeFlip = false,
    themeLight = false,
    themeDark = false,
    themeDarkHighContrast = false,

    // Styles. Mutually exclusive.
    brandCta = false,
    brandFlatCta = false,
    brandFlatFancyCta = false,
    cta = false,
    primary = false,
    danger = false,
    textHover = false,

    // Sizes. Mutually exlusive.
    small = false,
    large = false,

    // Padding
    square = false,
    tight = false,
    tighter = false,
    tighterer = false,
    tightest = false,
    zeroPadding = false,

    // Add margin to counter padding and facilitate alignment of content (not hover background)
    flush,
    flushVertical,
    flushSide,
    flushTop,
    flushRight,
    flushBottom,
    flushLeft,

    ...abstractButtonProps
}: BorderButtonProps) {
    const getSize = () => (small ? 16 : large ? 22 : 18);
    const getIconSize = ({ iconSize }: IconProps) => iconSize ?? getSize();

    const defaultStyle =
        !brandCta &&
        !brandFlatCta &&
        !brandFlatFancyCta &&
        !cta &&
        !primary &&
        !danger &&
        !textHover;
    const defaultTheme = !themeFlip && !themeLight && !themeDark && !themeDarkHighContrast;

    return (
        <AbstractButton
            {...abstractButtonProps}
            className={classNames(
                abstractButtonProps.className,
                styles.button,
                defaultStyle && styles.defaultStyle,
                defaultTheme && styles.defaultTheme,
                !useHoverEffect && styles.noHoverEffect,
                brandCta && styles.brandCta,
                brandFlatCta && styles.brandFlatCta,
                brandFlatFancyCta && styles.brandFlatFancyCta,
                cta && styles.cta,
                (abstractButtonProps.disabled || loading) && styles.disabled,
                fill && styles.fill,
                danger && styles.danger,
                primary && styles.primary,
                flush && styles.flush,
                flushVertical && styles.flushVertical,
                flushSide && styles.flushSide,
                flushTop && styles.flushTop,
                flushRight && styles.flushRight,
                flushBottom && styles.flushBottom,
                flushLeft && styles.flushLeft,
                large && styles.large,
                loading && styles.loading,
                minimal && styles.minimal,
                small && styles.small,
                sharper && styles.sharper,
                sharperer && styles.sharperer,
                sharpest && styles.sharpest,
                square && styles.square,
                textHover && styles.textHover,
                themeFlip && styles.themeFlip,
                themeLight && styles.themeLight,
                themeDark && styles.themeDark,
                themeDarkHighContrast && styles.themeDarkHighContrast,
                tight && styles.tight,
                tighter && styles.tighter,
                tighterer && styles.tighterer,
                tightest && styles.tightest,
                zeroPadding && styles.zeroPadding
            )}
            disabled={abstractButtonProps.disabled || loading}
        >
            {loading && <Spinner className={styles.spinner} size={getSize()} />}
            <div
                className={classNames([
                    contentClassName,
                    styles.content,
                    alignContentLeft && styles.alignContentLeft,
                    alignContentRight && styles.alignContentRight,
                    (leftIconProps || rightIconProps) && styles.icon,
                    (leftIconProps || rightIconProps) && iconSeparate && styles.iconSeparate,
                ])}
                style={{
                    gap:
                        (leftIconProps || rightIconProps) && iconGap !== undefined
                            ? `${iconGap}px`
                            : undefined,
                }}
            >
                {leftIconProps && <Icon {...leftIconProps} iconSize={getIconSize(leftIconProps)} />}
                {content}
                {rightIconProps && (
                    <Icon {...rightIconProps} iconSize={getIconSize(rightIconProps)} />
                )}
            </div>
        </AbstractButton>
    );
}
