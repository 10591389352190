import React from "react";

import { CommonEnums } from "c9r-common";

import { Avatar } from "components/ui/common/Avatar";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { useCurrentUser } from "contexts/UserContext";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./UserMenuItem.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserMenuItem_user on users {
            id
            name
            role

            ...Avatar_user
        }
    `),
};

export type UserMenuItemProps = {
    className?: string;
    showAvatarAsIcon?: boolean;
    user: FragmentType<typeof fragments.user>;
} & Omit<MenuItemProps, "text">;

export function UserMenuItem({
    className,
    showAvatarAsIcon,
    user: _userFragment,
    ...menuItemProps
}: UserMenuItemProps) {
    return (
        <MenuItem
            className={className}
            icon={showAvatarAsIcon ? <UserMenuItemIcon user={_userFragment} /> : null}
            {...menuItemProps}
            text={<UserMenuItemText showAvatar={!showAvatarAsIcon} user={_userFragment} />}
        />
    );
}

export type UserMenuItemIconProps = {
    className?: string;
    user: FragmentType<typeof fragments.user>;
};

export function UserMenuItemIcon({ className, user: _userFragment }: UserMenuItemIconProps) {
    const user = getFragmentData(fragments.user, _userFragment);

    return <Avatar className={className} user={user} size={26} />;
}

export type UserMenuItemTextProps = {
    className?: string;
    showAvatar?: boolean;
    showGuestIndicator?: boolean;
    user: FragmentType<typeof fragments.user>;
};

export function UserMenuItemText({
    className,
    showAvatar,
    showGuestIndicator,
    user: _userFragment,
}: UserMenuItemTextProps) {
    const user = getFragmentData(fragments.user, _userFragment);
    const currentUser = useCurrentUser();

    return (
        <span className={className}>
            {showAvatar ? (
                <span className={styles.avatarInline}>
                    <Avatar user={user} size={26} />
                </span>
            ) : null}
            {user.name}
            {user.id === currentUser.id ? " (me)" : null}
            {showGuestIndicator && user.role === CommonEnums.UserRole.USER_ORG_GUEST ? (
                <span className={styles.guestIndicator}>&nbsp;&nbsp;&nbsp;Guest</span>
            ) : null}
        </span>
    );
}
