import { v4 as uuidv4 } from "uuid";

import { Config } from "Config";
import { Enums } from "lib/Enums";
import { useInterval } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";

export function useHeartbeatRecorder() {
    const { recordEvent } = useInstrumentation();

    useInterval(() => {
        void recordEvent({ eventType: Enums.InstrumentationEvent.HEARTBEAT, dedupeKey: uuidv4() });
    }, Config.heartbeatIntervalMs);
}
