export const Regexes = {
    DEMO_FAKE_EMAIL_ADDRESS: /[a-z]+\.[0-9]{13}\.[a-z0-9]{2}@example\.com/gi,
    MENTION: /@([a-zA-z0-9-_]{2,40})/gi,
    MENTIONS_IN_TEXT: /(?:^|[^a-zA-z0-9-_@])@([a-zA-z0-9-_]{2,40})(?=$|[^a-zA-z0-9-_@])/gi,
    NON_MENTION_CHAR: /[a-zA-z0-9-_@]/,
    // See https://www.figma.com/developers/embed
    LINK_UNFURL_FIGMA: /^https:\/\/(?:[\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/i,
    LINK_UNFURL_LOOM: /^https:\/\/(?:[\w.-]+\.)?loom.com\/share\/(?<key>[0-9a-z]{32})\/?$/i,
    LINK_UNFURL_GOOGLE_DRIVE: /^https:\/\/(?:[\w.-]+\.)?drive.google.com\/file\/d\/(?<key>[\w-]{22,128})\/view\/?/i,
    VALID_USERNAME: /^[a-zA-Z0-9-_]{2,40}$/,
    VALID_USERNAME_CHAR: /[a-zA-Z0-9-_]/,
};
