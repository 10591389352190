import React from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./TicketLink.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketLink_ticket on tickets {
            id
            ref
            slug
            title

            board {
                id
                display_name
            }
        }
    `),
};

export type TicketLinkProps = {
    className?: string;
    iconSize?: number;
    loading?: boolean;
    selected?: boolean;
    ticket?: FragmentType<typeof fragments.ticket> | null;
    underline?: boolean;
};

export function TicketLink({
    className,
    iconSize,
    loading,
    selected,
    ticket: _ticketFragment,
    underline = true,
}: TicketLinkProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const { buildTicketUrl } = useUrlBuilders();
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    const ticketIcon = (
        <Icon
            className={styles.icon}
            icon="file-text"
            iconSet="lucide"
            iconSize={iconSize ?? 16}
            strokeWidth={1.5}
            textInline
        />
    );

    if (!ticket) {
        return (
            <span
                className={classNames(
                    className,
                    styles.ticketLink,
                    !loading && styles.unauthorized
                )}
            >
                <span className={styles.leader}>
                    {ticketIcon}
                    {loading ? (
                        <Spinner className={styles.spinner} tagName="span" size={14} />
                    ) : (
                        buildUnauthorizedDisplayName({ abstractName: "workItem" })
                    )}
                </span>
            </span>
        );
    }

    const [titleFirstWord, titleRest] = ticket.title.split(/(\s.*)/);

    return (
        <Link
            className={classNames(className, styles.ticketLink, selected && styles.selected)}
            data-drag-handle
            to={
                buildTicketUrl({
                    ticketSlug: ticket.slug,
                    vanity: {
                        boardDisplayName: ticket.board.display_name,
                        ticketRef: ticket.ref,
                        ticketTitle: ticket.title,
                    },
                }).pathname
            }
        >
            <span className={styles.leader}>
                {ticketIcon}
                {titleFirstWord ? (
                    <span className={classNames(styles.title, underline && styles.underline)}>
                        {titleFirstWord}
                    </span>
                ) : null}
            </span>
            {titleRest ? (
                <span className={classNames(styles.title, underline && styles.underline)}>
                    {titleRest}
                </span>
            ) : null}
            {shouldShowTicketRefs ? (
                <span
                    className={classNames(styles.ref, styles.title, underline && styles.underline)}
                >
                    {" "}
                    (#{ticket.ref})
                </span>
            ) : null}
        </Link>
    );
}
