import { useEffect } from "react";

import { useApolloClient } from "@apollo/client";

import { AppToaster } from "components/ui/core/AppToaster";
import { Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { useHistory } from "lib/Routing";
import { useDefaultRedirectUrl } from "lib/Urls";
import { gql } from "lib/graphql/__generated__";

export type GitHubInstallationRedirectProps = {
    installationId?: string;
};

export function GitHubInstallationRedirect({ installationId }: GitHubInstallationRedirectProps) {
    const client = useApolloClient();
    const { history } = useHistory();
    const defaultRedirectUrl = useDefaultRedirectUrl();

    const toastError = (msg?: string) => {
        AppToaster.error({
            message: `Something went wrong setting up GitHub${
                msg ? ` (${msg})` : ""
            }. Please try again.`,
        });
    };

    useEffect(() => {
        (async () => {
            if (!installationId) {
                Log.error("GitHub setup failed", { reason: "Missing installationId" });
                toastError();
                history.push(defaultRedirectUrl.pathname);
                return;
            }

            const maxTimeoutMs = 20000;
            const setupToastTimeout = 7500;
            const slowSetupToastTimeout = maxTimeoutMs - setupToastTimeout;

            const tryConnectGitHub = async () => {
                const result = await client.mutate({
                    mutation: gql(/* GraphQL */ `
                        mutation ConnectGitHubInstallation($installationId: String!) {
                            connect_github(installation_id: $installationId) {
                                ok
                            }
                        }
                    `),
                    variables: { installationId },
                    context: {
                        apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                    },
                });

                return !!result.data?.connect_github.ok;
            };

            let didToastSlowSetup = false;
            const startTime = Date.now();
            let connected = false;

            AppToaster.info({
                message: "One moment while we finish connecting to GitHub...",
                timeout: setupToastTimeout,
            });

            while (Date.now() - startTime < maxTimeoutMs && !connected) {
                if (!didToastSlowSetup && Date.now() - startTime > setupToastTimeout) {
                    didToastSlowSetup = true;

                    Log.warn("Slow GitHub app installation");
                    AppToaster.info({
                        message: "This is taking a bit longer than expected, hang in there...",
                        timeout: slowSetupToastTimeout,
                    });
                }

                connected = await tryConnectGitHub();

                if (!connected) {
                    await new Promise<void>(resolve => {
                        setTimeout(() => resolve(), 1300);
                    });
                }
            }

            if (connected) {
                AppToaster.clear();
                AppToaster.success({
                    message: "Your Flat account is now linked to your GitHub account.",
                });
            } else {
                Log.error("GitHub setup failed", { reason: "Backend error" });
                AppToaster.clear();
                toastError();
            }

            history.push(defaultRedirectUrl.pathname);
        })();
    }, [defaultRedirectUrl.pathname, installationId, history, client]);

    return null;
}
