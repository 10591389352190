import React from "react";

import classNames from "classnames";

import { BrandLogomark } from "components/shared/BrandLogomark";
import { CssClasses } from "lib/Constants";
import { useIsTitleBarHidden } from "lib/Hooks";
import { Link } from "lib/Routing";

import styles from "./LogoPageLayout.module.scss";

export type LogoPageLayoutProps = {
    children: React.ReactNode;
    className?: string;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
};

export function LogoPageLayout({ children, className, title, subtitle }: LogoPageLayoutProps) {
    const isTitleBarHidden = useIsTitleBarHidden();

    return (
        <>
            <Link
                className={classNames(
                    styles.brandLogomark,
                    isTitleBarHidden && styles.titleBarHidden
                )}
                role="link"
                to="/"
            >
                <BrandLogomark height={30} />
            </Link>
            <div
                id={styles.layout}
                className={classNames(
                    className,
                    isTitleBarHidden && styles.titleBarHidden,
                    CssClasses.LAYOUT_ROOT
                )}
            >
                {title ? <h1>{title}</h1> : null}
                {subtitle ? <h2>{subtitle}</h2> : null}
                {children}
            </div>
        </>
    );
}
