import React, { useEffect, useLayoutEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { CommonEnumValue, CommonEnums, authorizeTicketReference } from "c9r-common";
import { ExtensionTicketReference } from "c9r-rich-text";
import { Plugin, PluginKey } from "prosemirror-state";

import { TSearchIndexTicket, TicketSearchIndex } from "components/search/TicketSearchIndex";
import {
    TicketMenuItemClassName,
    TicketMenuItemLayout,
    TicketMenuWrapper,
} from "components/shared/TicketMenu";
import { TicketLink } from "components/ui/common/TicketLink";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { Tooltip } from "components/ui/core/Tooltip";
import { buildSuggestionParams } from "components/ui/editor/extensions/helpers/Suggestions";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { parseTicketUrl } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TicketReference_ticketFragment } from "lib/graphql/__generated__/graphql";

import styles from "./TicketReference.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment TicketReference_board on boards {
            id
            access_type
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment TicketReference_ticket on tickets {
            id

            board {
                id

                ...TicketReference_board
            }

            ...TicketLink_ticket
            ...TicketMenuItemLayout_ticket
        }
    `),
};

type TicketReferenceNodeViewProps = {
    node: any;
    selected: boolean;
};

function TicketReferenceNodeView({ node, selected }: TicketReferenceNodeViewProps) {
    const ticketIdentifier =
        // When we switched ticketIds from auto-increment integers to UUIDs in late 2022,
        // we didn't go back and migrate ticket references in rich documents. So, some old
        // rich documents still have integer-based ticketIds, which are no longer usable.
        node.attrs.ticketId && typeof node.attrs.ticketId === "string"
            ? { type: CommonEnums.TicketIdentifierType.ID, value: node.attrs.ticketId }
            : { type: CommonEnums.TicketIdentifierType.REF, value: node.attrs.ticketRef };

    return (
        <NodeViewWrapper as="span">
            <TicketReference selected={selected} ticketIdentifier={ticketIdentifier} />
        </NodeViewWrapper>
    );
}

type TicketReferenceProps = {
    selected: boolean;
    ticketIdentifier: { type: CommonEnumValue<"TicketIdentifierType">; value: string };
};

function TicketReference({ selected, ticketIdentifier }: TicketReferenceProps) {
    const [ticket, setTicket] = useState<
        TSearchIndexTicket | TicketReference_ticketFragment | null
    >(null);
    const currentUser = useCurrentUser();
    const [runQuery, queryResult] = {
        [CommonEnums.TicketIdentifierType.ID]: useLazyQuery(
            TicketReference.queries.componentByTicketId,
            {
                fetchPolicy: "cache-and-network",
                variables: {
                    ticketId: ticketIdentifier.value,
                },
            }
        ),
        [CommonEnums.TicketIdentifierType.REF]: useLazyQuery(
            TicketReference.queries.componentByTicketRef,
            {
                fetchPolicy: "cache-and-network",
                variables: {
                    orgId: currentUser.org_id,
                    ticketRef: ticketIdentifier.value,
                },
            }
        ),
    }[ticketIdentifier.type];

    // We try to get the referenced ticket from the local search index before anything renders.
    // If the ticket isn't found in the index (because it's trashed or the index hasn't loaded
    // yet), then we run a lazy query as a fallback.
    useLayoutEffect(() => {
        const results = TicketSearchIndex.findTickets({
            searchQuery: ticketIdentifier.value,
            ...{
                [CommonEnums.TicketIdentifierType.ID]: { ticketIdOnly: true },
                [CommonEnums.TicketIdentifierType.REF]: { ticketRefOnly: true },
            }[ticketIdentifier.type],
        });

        if (!results.length) {
            void runQuery();
        } else {
            // We searched by ticketIdOnly or ticketRefOnly, but it may not actually be
            // the ticket we're looking for, because ticketRefOnly also allows prefix
            // matches. So we need to check the result *actually* matches.
            const { ticket: maybeMatchingTicket } = results[0];

            if (
                (ticketIdentifier.type === CommonEnums.TicketIdentifierType.ID &&
                    maybeMatchingTicket.id === ticketIdentifier.value) ||
                (ticketIdentifier.type === CommonEnums.TicketIdentifierType.REF &&
                    maybeMatchingTicket.ref === ticketIdentifier.value)
            ) {
                setTicket(maybeMatchingTicket);
            }
        }
    }, [runQuery, ticketIdentifier.type, ticketIdentifier.value]);

    useEffect(() => {
        if (queryResult.data?.tickets[0]) {
            setTicket(getFragmentData(fragments.ticket, queryResult.data.tickets[0]));
        }
    }, [queryResult.data]);

    return <TicketLink loading={queryResult.loading} selected={selected} ticket={ticket} />;
}

TicketReference.queries = {
    componentByTicketId: gql(/* GraphQL */ `
        query TicketReferenceByTicketId($ticketId: uuid!) {
            tickets(where: { id: { _eq: $ticketId } }) {
                ...TicketReference_ticket
            }
        }
    `),

    componentByTicketRef: gql(/* GraphQL */ `
        query TicketReferenceByTicketRef($orgId: Int!, $ticketRef: String!) {
            tickets(where: { org_id: { _eq: $orgId }, ref: { _eq: $ticketRef } }) {
                ...TicketReference_ticket
            }
        }
    `),
};

type TicketsSuggestionsMenuProps = {
    board: FragmentType<typeof fragments.board>;
    items: (TicketReference_ticketFragment | TSearchIndexTicket)[];
    selectedIndex: number;
    onSelect: (selectedIndex: number) => void;
    query?: string;
};

function TicketsSuggestionsMenu({
    board: _boardFragment,
    items,
    selectedIndex,
    onSelect,
    query,
}: TicketsSuggestionsMenuProps) {
    const board = getFragmentData(fragments.board, _boardFragment);

    const shouldShowTicketRefs = useShouldShowTicketRefs();

    // As fof July 2023, it was necessary to add an extra didRender dependency to
    // TicketMenuWrapper to ensure it computes the right column widths on the
    // initial render.
    const [didRender, setDidRender] = useState(false);

    useEffect(() => {
        setDidRender(true);
    }, []);

    return (
        <TicketMenuWrapper dependencies={[items, query, didRender]}>
            <Menu className={styles.menu}>
                <div className={styles.menuCaption}>
                    {query
                        ? `Topics matching "${query}"`
                        : shouldShowTicketRefs
                        ? "Search by topic ID, title, labels..."
                        : "Search by title, labels..."}
                </div>
                {items.map((ticket, index) => {
                    const { isAuthorized, reason } = authorizeTicketReference({
                        ticketReference: {
                            context: CommonEnums.TicketReferenceContext.RICH_TEXT,
                            originBoard: board,
                            targetBoard: getFragmentData(fragments.board, ticket.board),
                        },
                    });

                    return (
                        <Tooltip
                            key={ticket.id}
                            className={styles.menuItemWrapper}
                            content={reason ?? ""}
                            disabled={isAuthorized}
                            modifiers={{ offset: { enabled: false } }}
                            placement="left"
                            small
                        >
                            <MenuItem
                                className={TicketMenuItemClassName}
                                active={index === selectedIndex}
                                disabled={!isAuthorized}
                                text={<TicketMenuItemLayout ticket={ticket} />}
                                onClick={() => onSelect(index)}
                                instrumentation={{
                                    elementName: "ticket_suggestions_menu",
                                    eventData: { query, ticketId: ticket.id },
                                }}
                            />
                        </Tooltip>
                    );
                })}
            </Menu>
        </TicketMenuWrapper>
    );
}

export default ExtensionTicketReference.extend({
    addNodeView() {
        return ReactNodeViewRenderer(TicketReferenceNodeView);
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                pluginKey: new PluginKey("ticketReference"),
                editor: this.editor,
                ...buildSuggestionParams({
                    char: "#",
                    type: this.name,
                    search: query => {
                        const maxResults = 5;
                        const matchingTickets = TicketSearchIndex.findTickets({
                            searchQuery: query,
                            maxResults: maxResults + 1,
                        })
                            .map(({ ticket }) => ticket)
                            .slice(0, maxResults);

                        return matchingTickets.map(ticket => ({
                            item: ticket,
                            isExactMatch: ticket.ref === query,
                        }));
                    },
                    SuggestionsMenuComponent: props => (
                        <TicketsSuggestionsMenu {...props} board={this.options.board} />
                    ),
                    itemToCommandProps: ticket => ({
                        attrs: { ticketId: ticket.id, ticketRef: ticket.ref },
                    }),
                    previousCharAllowList: [" ", "(", "["],
                    autoReplaceExactMatch: query => query.length >= 2,
                    itemCharsRegex: /^[0-9]+$/,
                }),
            }),

            new Plugin({
                key: new PluginKey("handlePasteTicketReferenceUrl"),
                props: {
                    handlePaste: (view, event) => {
                        const pastedText = event.clipboardData?.getData("text");

                        if (!pastedText) {
                            return false;
                        }

                        const ticketSlug = parseTicketUrl({ url: pastedText })?.ticketSlug;

                        if (!ticketSlug) {
                            return false;
                        }

                        const ticket = TicketSearchIndex.findTicketBySlug({ ticketSlug });

                        if (!ticket) {
                            return false;
                        }

                        this.editor.commands.insertContent({
                            type: this.name,
                            attrs: { ticketId: ticket.id, ticketRef: ticket.ref },
                        });

                        return true;
                    },
                },
            }),
        ];
    },
});
