import React from "react";

import { CommonEnums, sortTasks } from "c9r-common";
import classNames from "classnames";

import { ChildTicketProgressBar } from "components/shared/ChildTicketProgressBar";
import { Hotspot } from "components/ui/core/Hotspot";
import { Enums } from "lib/Enums";
import { useGetTaskStatusInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./CardChildTickets.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment CardChildTickets_ticket on tickets {
            id
            title

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id
                added_at

                stage {
                    id
                    board_pos
                }

                tasks(where: { deleted_at: { _is_null: true } }) {
                    id
                    tasklist_pos
                    task_type

                    child_ticket {
                        id
                        trashed_at

                        ...ChildTicketProgressBar_ticket
                    }

                    ...TaskStatusInfo_task
                }
            }
        }
    `),
};

export type CardChildTicketsProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function CardChildTickets({ className, ticket: _ticketFragment }: CardChildTicketsProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { getTaskStatusInfo } = useGetTaskStatusInfo();

    const tasks = ticket.tasklists
        .concat()
        .sort((a, b) =>
            // A tasklist always has a stage, but if it's a guest user, it may not be visible
            // to then, so we have to check it exists.
            a.stage && b.stage && a.stage.id !== b.stage.id
                ? (a.stage.board_pos ?? 0) - (b.stage.board_pos ?? 0)
                : new Date(a.added_at).getTime() - new Date(b.added_at).getTime()
        )
        .flatMap(tl =>
            tl.tasks
                .filter(
                    task =>
                        task.task_type === CommonEnums.TaskType.CHILD_TICKET &&
                        !task.child_ticket?.trashed_at &&
                        !getTaskStatusInfo({ task }).isComplete
                )
                .sort(sortTasks())
        );

    if (!tasks.length) {
        return null;
    }

    return (
        <Hotspot
            allowListValue={ticket.title}
            hotspotKey={Enums.HotspotKey.BOARD_CARD_CHILD_TICKETS}
            offset={[0, 11]}
            placement="left"
            usePortal={false}
            targetTagName="div"
        >
            <div className={classNames(className, styles.cardChildTickets)}>
                {tasks.map(task => (
                    <ChildTicketProgressBar
                        key={task.id}
                        className={styles.cardChildTicket}
                        ticket={task.child_ticket}
                        decreaseOpacity
                    />
                ))}
            </div>
        </Hotspot>
    );
}
