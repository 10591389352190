import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { TextButton } from "components/ui/core/TextButton";
import { GlobalCssClasses } from "lib/Constants";
import { isHTMLElement } from "lib/types/guards";

import styles from "./EditorAccordion.module.scss";

export type EditorAccordionProps = {
    children: React.ReactNode;
    className?: string;
    forceExpand?: boolean;
    heightCollapsed?: number;
    heightCollapseThreshold?: number;
    isStuck?: boolean;
};

export function EditorAccordion({
    children,
    className,
    forceExpand,
    heightCollapsed = 64,
    heightCollapseThreshold = 144,
    isStuck,
}: EditorAccordionProps) {
    const [isCollapsed, setIsCollapsed] = useState(!forceExpand);
    const [showCollapseExpandButton, setShowCollapseExpandButton] = useState(false);
    const [isContentCollapsable, setIsContentCollapsable] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (isContentCollapsable) {
            setShowCollapseExpandButton(true);
        }
    }, [isContentCollapsable]);

    useEffect(() => {
        if (isContentCollapsable && !forceExpand) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    }, [isContentCollapsable, forceExpand]);

    const refCallback = (node: HTMLDivElement) => {
        if (!node) {
            return null;
        }

        textRef.current = node;

        const editorElement = node.querySelector(`.${GlobalCssClasses.RICH_EDITOR}`);

        if (!editorElement) {
            return null;
        }

        const editorChildren = Array.from(editorElement.children).filter(isHTMLElement);
        const editorHeight = parseInt(window.getComputedStyle(editorElement).height);

        // When the accordion is collapsed, we ideally want only whole lines to be visible, not
        // partially cut off lines. The basic approach is to iterate through the elements and
        // find the first element that exceeds the collapse threshold, then for that element
        // (which will be the last element to display and will be cut off), compute a cutoff
        // point that's *between* its lines using its line height as a guide.
        //
        // The common case will be plain paragraphs or single-level lists, so that's all we handle
        // here.
        if (editorHeight > heightCollapseThreshold) {
            let lastElementToDisplay = editorChildren[0];
            let heightSoFar = 0;

            while (heightSoFar <= heightCollapsed && editorChildren.length) {
                const nextTopLevelChild = editorChildren.shift()!;

                // If it's a list, consider cutting off between each item in the list rather than
                // the list as a whole.
                const truncateableChildren = ["ol", "ul"].includes(
                    nextTopLevelChild.tagName.toLowerCase()
                )
                    ? Array.from(nextTopLevelChild.children).filter(isHTMLElement)
                    : [nextTopLevelChild];

                for (const child of truncateableChildren) {
                    const childStyle = window.getComputedStyle(child);

                    heightSoFar =
                        child.offsetTop + child.offsetHeight + parseInt(childStyle.marginBottom);

                    // Special case: If the node doesn't have any text, don't use it as the last
                    // item to display. That way, we avoid the situation where the last item
                    // displayed is just a blank line.
                    if (child.textContent) {
                        lastElementToDisplay = child;
                    }

                    if (heightSoFar > heightCollapsed) {
                        break;
                    }
                }
            }

            // Cut off the last child using its bottom margin and line height.
            const lastElementToDisplayStyle = window.getComputedStyle(lastElementToDisplay);
            const lastElementToDisplayLineHeight = parseInt(lastElementToDisplayStyle.lineHeight);
            const lastElementToDisplayMarginBottom = parseInt(
                lastElementToDisplayStyle.marginBottom
            );
            const lastElementToDisplayBottom =
                lastElementToDisplay.offsetTop +
                lastElementToDisplay.offsetHeight +
                lastElementToDisplayMarginBottom;

            const heightToDisplay =
                lastElementToDisplayBottom -
                (lastElementToDisplayMarginBottom +
                    Math.max(
                        Math.floor(
                            (lastElementToDisplayBottom -
                                lastElementToDisplayMarginBottom -
                                heightCollapsed) /
                                lastElementToDisplayLineHeight
                        ) * lastElementToDisplayLineHeight,
                        0
                    ));

            if (heightToDisplay < editorHeight) {
                setIsContentCollapsable(true);
            } else {
                setIsContentCollapsable(false);
            }

            if (textRef.current) {
                if (isCollapsed) {
                    textRef.current.style.maxHeight = `${heightToDisplay}px`;
                } else {
                    textRef.current.style.removeProperty("max-height");
                }
            }
        } else {
            setIsContentCollapsable(false);
        }

        return textRef;
    };

    return (
        <div>
            <div
                ref={refCallback}
                className={classNames(className, isCollapsed && styles.accordionCollapse)}
            >
                {children}
            </div>
            {!isStuck && showCollapseExpandButton ? (
                <TextButton
                    className={styles.expandBtn}
                    link
                    text={isCollapsed ? "Show more" : "Show less"}
                    onClick={() => {
                        setIsCollapsed(prev => !prev);
                    }}
                    instrumentation={null}
                />
            ) : null}
        </div>
    );
}
