import { useCallback, useEffect, useMemo } from "react";

import { AppUrls } from "c9r-common";

import { AppData } from "AppData";
import { useMaybeCurrentOrg } from "AppState";
import { useCurrentUser } from "contexts/UserContext";
import { useFeatureFlags } from "lib/Features";
import { useHistory } from "lib/Routing";

export const RoutePathType = {
    ORG: "ORG",
    SEARCH: "SEARCH",
    SETTINGS: "SETTINGS",
    SETUP: "SETUP",
    TOPIC: "TOPIC",
    USER: "USER",
    WORKSPACE: "WORKSPACE",
} as const;

export const RoutePatternsByPathType = {
    [RoutePathType.ORG]: ["/o/:currentOrgSlug"],
    [RoutePathType.SEARCH]: ["/search", "/o/:currentOrgSlug/search"],
    [RoutePathType.SETTINGS]: ["/settings", "/o/:currentOrgSlug/settings"],
    [RoutePathType.SETUP]: ["/o/:currentOrgSlug/setup"],
    [RoutePathType.TOPIC]: ["/o/:currentOrgSlug/t/:ticketSlug"],
    [RoutePathType.USER]: ["/o/:currentOrgSlug/p/:userSlug"],
    [RoutePathType.WORKSPACE]: ["/o/:currentOrgSlug/ws/:boardSlug"],
};

/**
 * Parse a url string into a URL object or return null.
 */
const safeCreateUrlObject = (
    /** Absolute or relative URL. */
    url: string
) => {
    try {
        return new URL(url, window.location.origin);
    } catch {
        return null;
    }
};

export function useBaseUrlBuilders() {
    const buildOrgBaseUrl = useCallback(
        ({ orgSlug }: { orgSlug: string }) => AppUrls.buildOrgBaseUrl({ orgSlug }),
        []
    );

    return useMemo(() => ({ buildOrgBaseUrl }), [buildOrgBaseUrl]);
}

export function useDefaultRedirectUrl() {
    const currentOrg = useMaybeCurrentOrg();
    const { buildOrgBaseUrl } = useBaseUrlBuilders();

    return currentOrg ? buildOrgBaseUrl({ orgSlug: currentOrg.slug }) : { pathname: "/" };
}

export function useUrlBuilders() {
    const currentUser = useCurrentUser();
    const currentOrgSlug = currentUser.org.slug;
    const { buildOrgBaseUrl } = useBaseUrlBuilders();
    const buildCurrentOrgUrl = useCallback(() => buildOrgBaseUrl({ orgSlug: currentOrgSlug }), [
        buildOrgBaseUrl,
        currentOrgSlug,
    ]);

    const buildBoardUrl = useCallback(
        ({ boardSlug, vanity }: { boardSlug: string; vanity?: { boardDisplayName: string } }) =>
            AppUrls.buildBoardUrl({ orgSlug: currentOrgSlug, boardSlug, vanity }),
        [currentOrgSlug]
    );

    const buildImageUrl = useCallback(
        ({ key, filename }: { key: string; filename: string }) =>
            AppUrls.buildImageUrl({ orgSlug: currentOrgSlug, key, filename }),
        [currentOrgSlug]
    );

    const buildSearchUrl = useCallback(() => AppUrls.buildSearchUrl({ orgSlug: currentOrgSlug }), [
        currentOrgSlug,
    ]);

    const buildSettingsUrl = useCallback(
        () => AppUrls.buildSettingsUrl({ orgSlug: currentOrgSlug }),
        [currentOrgSlug]
    );

    const buildTicketAttachmentUrl = useCallback(
        ({ ticketSlug, key, filename }: { ticketSlug: string; key: string; filename: string }) =>
            AppUrls.buildTicketAttachmentUrl({
                orgSlug: currentOrgSlug,
                ticketSlug,
                key,
                filename,
            }),
        [currentOrgSlug]
    );

    const buildTicketUrl = useCallback(
        ({
            ticketSlug,
            vanity,
        }: {
            ticketSlug: string;
            vanity?: { boardDisplayName: string; ticketRef: string; ticketTitle: string };
        }) =>
            AppUrls.buildTicketUrl({
                orgSlug: currentOrgSlug,
                ticketSlug,
                vanity: vanity
                    ? {
                          ...vanity,
                          showTicketRef: AppData.currentUser?.settings.SHOW_TICKET_REFS?.enabled,
                      }
                    : undefined,
            }),
        [currentOrgSlug]
    );

    const buildUserUrl = useCallback(
        ({ userSlug, vanity }: { userSlug: string; vanity?: { username: string } }) =>
            AppUrls.buildUserUrl({ orgSlug: currentOrgSlug, userSlug, vanity }),
        [currentOrgSlug]
    );

    return useMemo(
        () => ({
            buildBoardUrl,
            buildCurrentOrgUrl,
            buildImageUrl,
            buildSearchUrl,
            buildSettingsUrl,
            buildTicketAttachmentUrl,
            buildTicketUrl,
            buildUserUrl,
        }),
        [
            buildBoardUrl,
            buildCurrentOrgUrl,
            buildImageUrl,
            buildSearchUrl,
            buildSettingsUrl,
            buildTicketAttachmentUrl,
            buildTicketUrl,
            buildUserUrl,
        ]
    );
}

/**
 * Parse details about a ticket from its URL.
 *
 * Returns Details about the ticket, or null if the given url is not a ticket url.
 */
export function parseTicketUrl({
    url,
}: {
    /** Absolute or relative URL as a string. If omitted, uses the current URL. */
    url?: string;
} = {}) {
    const urlObject = url ? safeCreateUrlObject(url) : window.location;

    if (!urlObject) {
        return null;
    }

    const match = urlObject.pathname.match(/^\/o\/\w+\/t\/(\w+)\/?.*$/);

    if (!match) {
        return null;
    }

    return {
        ticketSlug: match[1],
    };
}

export function useRedirectToFullPathname({ fullPathname }: { fullPathname: string | null }) {
    const { isFeatureEnabled } = useFeatureFlags();
    const { getCurrentLocation, history } = useHistory();

    useEffect(() => {
        if (!fullPathname) {
            return;
        }

        const location = getCurrentLocation();

        if (fullPathname.includes(location.pathname) && fullPathname !== location.pathname) {
            history.replace({
                ...location,
                pathname: fullPathname,
            });
        }
    }, [fullPathname, getCurrentLocation, history, isFeatureEnabled]);
}

export function useRedirectToBoard() {
    const { history } = useHistory();
    const { buildBoardUrl } = useUrlBuilders();

    const redirectToBoard = useCallback(
        async ({ slug, displayName }: { slug: string; displayName: string }) => {
            history.push(
                buildBoardUrl({
                    boardSlug: slug,
                    vanity: {
                        boardDisplayName: displayName,
                    },
                }).pathname
            );
        },
        [buildBoardUrl, history]
    );

    return { redirectToBoard };
}
