import React from "react";

import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";

import styles from "./TicketRowLayout.module.scss";

export type TicketRowTaskProps = {
    className?: string;
    taskContent: React.ReactNode;
};

export function TicketListTaskSubrow({ className, taskContent }: TicketRowTaskProps) {
    return <div className={classNames(className, styles.task)}>{taskContent}</div>;
}

export type TicketRowLayoutProps = {
    className?: string;
    isCollapsed?: boolean;
    taskSubrows?: React.ReactNode;
    ticketContent: React.ReactNode;
    toggleIsCollapsed?: () => void;
};

export function TicketRowLayout({
    className,
    isCollapsed,
    taskSubrows,
    ticketContent,
    toggleIsCollapsed,
}: TicketRowLayoutProps) {
    return (
        <div className={classNames(className, styles.layout)}>
            <div className={styles.primaryRow}>
                {taskSubrows ? (
                    <BorderButton
                        className={styles.chevron}
                        content={
                            <Icon
                                icon={isCollapsed ? "chevron-right" : "chevron-down"}
                                iconSet="lucide"
                            />
                        }
                        onClick={toggleIsCollapsed}
                        flushVertical
                        instrumentation={{
                            elementName: "ticket_row.expand_collapse_btn",
                        }}
                        minimal
                        small
                        square
                        preventDefault
                        useHoverEffect={false}
                    />
                ) : null}
                <div className={styles.ticket}>{ticketContent}</div>
            </div>
            {taskSubrows && !isCollapsed ? <div className={styles.tasks}>{taskSubrows}</div> : null}
        </div>
    );
}
