import { useCallback } from "react";

import { Enums } from "lib/Enums";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useChangeOrgDomainSignupEnabled() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ChangeOrgDomainSignupEnabled($orgId: Int!, $isEnabled: Boolean!) {
                update_orgs_by_pk(
                    pk_columns: { id: $orgId }
                    _set: { is_domain_signup_enabled: $isEnabled }
                ) {
                    id
                    is_domain_signup_enabled
                }
            }
        `)
    );

    const changeOrgDomainSignupEnabled = useCallback(
        async (
            { isEnabled, orgId }: { isEnabled: boolean; orgId: number },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    orgId,
                    isEnabled,
                },
                optimisticResponse: {
                    update_orgs_by_pk: {
                        __typename: "orgs",
                        id: orgId,
                        is_domain_signup_enabled: isEnabled,
                    },
                },
            });
        },
        [mutation]
    );

    return { changeOrgDomainSignupEnabled };
}
