import React from "react";

import { CommonEnums, TicketSizes, sortStages } from "c9r-common";
import { parse as parseDate } from "date-fns";

import {
    DueDateButtonDisplay,
    MembersListDisplay,
    OwnerButtonDisplay,
    SizeButtonDisplay,
    StageButtonDisplay,
    TSizeButtonSizeScheme,
} from "components/shared/MetadataPickers";
import { Hotspot } from "components/ui/core/Hotspot";
import { moveToFirstPosition, moveToLastPosition } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useTicketDueDateInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    MembersListDisplay_userFragment,
    UserSelect_userFragment,
} from "lib/graphql/__generated__/graphql";
import {
    useChangeTicketMembers,
    useMoveTicketToStagePosition,
    useRemoveTicketOwner,
    useSetTicketOwner,
    useUpdateTicketDueDate,
    useUpdateTicketSize,
} from "lib/mutations";
import { isDefined } from "lib/types/guards";

import styles from "./TicketMetadataPickers.module.scss";

const fragments = {
    DueDateButton: {
        ticket: gql(/* GraphQL */ `
            fragment DueDateButton_ticket on tickets {
                id
                due_date

                board {
                    id
                    settings
                }

                ...TicketDueDateInfo_ticket
            }
        `),
    },
    MembersList: {
        ticket: gql(/* GraphQL */ `
            fragment MembersList_ticket on tickets {
                id

                board {
                    id

                    authorized_users {
                        user {
                            id

                            ...MembersListDisplay_user
                        }
                    }
                }

                owners {
                    ticket_id
                    user_id
                    type

                    owner {
                        id
                        name

                        ...Avatar_user
                        ...MembersListDisplay_user
                    }
                }
            }
        `),
    },
    OwnerButton: {
        ticket: gql(/* GraphQL */ `
            fragment OwnerButton_ticket on tickets {
                id

                board {
                    id

                    authorized_users {
                        user {
                            id
                            name

                            ...OwnerButtonDisplay_user
                        }
                    }
                }

                owners {
                    ticket_id
                    user_id
                    type

                    owner {
                        id
                        name

                        ...OwnerButtonDisplay_user
                    }
                }
            }
        `),
    },
    SizeButton: {
        ticket: gql(/* GraphQL */ `
            fragment SizeButton_ticket on tickets {
                id
                size_spec

                board {
                    id
                    settings
                }
            }
        `),
    },
    StageButton: {
        ticket: gql(/* GraphQL */ `
            fragment StageButton_ticket on tickets {
                id
                stage_id
                archived_at
                trashed_at

                board {
                    id

                    stages(where: { deleted_at: { _is_null: true } }) {
                        id
                        board_id
                        board_pos
                        display_name
                        role
                        min_ticket_stage_pos
                        max_ticket_stage_pos
                    }
                }
            }
        `),
    },
};

export type DueDateButtonProps = {
    ticket: FragmentType<typeof fragments.DueDateButton.ticket>;
};

export function DueDateButton({ ticket: _ticketFragment }: DueDateButtonProps) {
    const ticket = getFragmentData(fragments.DueDateButton.ticket, _ticketFragment);
    const { updateTicketDueDate } = useUpdateTicketDueDate();
    const { isUpcomingSoon, isOverdue } = useTicketDueDateInfo({ ticket });

    const handleDueDateChange = async (dueDate: Date | null | undefined) => {
        await updateTicketDueDate({
            ticketId: ticket.id,
            dueDate: dueDate ?? null,
        });
    };

    if (
        !ticket.due_date &&
        !ticket.board.settings[CommonEnums.BoardSettingType.DUE_DATES]?.enabled
    ) {
        return null;
    }

    return (
        <DueDateButtonDisplay
            dueDate={
                ticket.due_date ? parseDate(ticket.due_date, "yyyy-MM-dd", new Date()) : undefined
            }
            isUpcomingSoon={isUpcomingSoon}
            isOverdue={isOverdue}
            getInstrumentation={() => ({
                elementName: "ticket_detail.due_date_picker",
                eventData: {
                    ticketId: ticket.id,
                },
            })}
            onSelect={handleDueDateChange}
        />
    );
}

export type OwnerButtonProps = {
    ticket: FragmentType<typeof fragments.OwnerButton.ticket>;
};

export function OwnerButton({ ticket: _ticketFragment }: OwnerButtonProps) {
    const ticket = getFragmentData(fragments.OwnerButton.ticket, _ticketFragment);
    const { setTicketOwner } = useSetTicketOwner();
    const { removeTicketOwner } = useRemoveTicketOwner();
    const owner = ticket.owners
        .filter(to => to.type === CommonEnums.TicketOwnerType.OWNER)
        .map(to => to.owner)[0];
    const authorizedUsers = ticket.board.authorized_users.map(au => au.user).filter(isDefined);
    const authorizedUserIds = new Set(authorizedUsers.map(u => u.id));
    const selectableUsers = authorizedUsers
        .concat([owner].filter(isDefined).filter(o => !authorizedUserIds.has(o.id)))
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

    if (owner) {
        // @ts-ignore
        selectableUsers.push({ id: null, name: "Nobody" });
    }

    const handleChangeOwner = async (newOwner: UserSelect_userFragment | null) => {
        if (newOwner) {
            await setTicketOwner({ ticketId: ticket.id, userId: newOwner.id });
        } else {
            await removeTicketOwner({ ticketId: ticket.id });
        }
    };

    return (
        <div>
            <Hotspot
                fill={false}
                hotspotKey={Enums.HotspotKey.OWNER_AND_TEAM}
                contentOffset={[0, 8]}
                placement="bottom"
                usePortal={false}
            >
                <OwnerButtonDisplay
                    targetClassName={styles.pickerTarget}
                    ownerId={owner?.id}
                    onSelect={handleChangeOwner}
                    selectableUsers={selectableUsers}
                    getInstrumentation={user => ({
                        elementName: "ticket_detail.owner_menu",
                        eventData: {
                            ticketId: ticket.id,
                            userId: user.id,
                        },
                    })}
                />
            </Hotspot>
        </div>
    );
}

export type SizeButtonProps = {
    ticket: FragmentType<typeof fragments.SizeButton.ticket>;
};

export function SizeButton({ ticket: _ticketFragment }: SizeButtonProps) {
    const ticket = getFragmentData(fragments.SizeButton.ticket, _ticketFragment);
    const { updateTicketSize } = useUpdateTicketSize();

    const ticketSizeSpec = ticket.size_spec;
    const boardTicketSizes = ticket.board.settings[CommonEnums.BoardSettingType.SIZES];

    const handleSizeChange = async (sizeSpecItem: TSizeButtonSizeScheme | null) => {
        const { value } = sizeSpecItem || {};

        if (
            !boardTicketSizes?.enabled ||
            (!value && !ticketSizeSpec) ||
            (value && ticketSizeSpec && value === ticketSizeSpec.value)
        ) {
            return;
        }

        const sizeSpec = value
            ? {
                  unit: boardTicketSizes.scheme.unit,
                  value,
              }
            : null;

        await updateTicketSize({
            ticketId: ticket.id,
            sizeSpec,
        });
    };

    if (!boardTicketSizes?.enabled) {
        return null;
    }

    const sizeScheme = ([] as {
        value: string | number | null;
        text: string;
    }[]).concat(
        boardTicketSizes.scheme.values.map((value: string | number) => ({
            value,
            text: TicketSizes.format({
                value,
                unit: boardTicketSizes.scheme.unit,
            }),
        }))
    );

    if (ticketSizeSpec) {
        sizeScheme.push({
            value: null,
            text: "¯\\_(ツ)_/¯",
        });
    }

    if (
        ticketSizeSpec &&
        (ticketSizeSpec.unit !== boardTicketSizes.scheme.unit ||
            !boardTicketSizes.scheme.values.includes(ticketSizeSpec.value))
    ) {
        sizeScheme.unshift({
            value: ticketSizeSpec.value,
            text: TicketSizes.format({
                value: ticketSizeSpec.value,
                unit: ticketSizeSpec.unit,
            }),
        });
    }

    return (
        <SizeButtonDisplay
            onSelect={handleSizeChange}
            sizeScheme={sizeScheme}
            sizeSpec={ticketSizeSpec ?? null}
            getInstrumentation={({ value }) => ({
                elementName: "ticket_detail.size_menu",
                eventData: {
                    ticketId: ticket.id,
                    size: value,
                },
            })}
        />
    );
}

export type StageButtonProps = {
    ticket: FragmentType<typeof fragments.StageButton.ticket>;
};

export function StageButton({ ticket: _ticketFragment }: StageButtonProps) {
    const ticket = getFragmentData(fragments.StageButton.ticket, _ticketFragment);
    const { moveTicketToStagePosition } = useMoveTicketToStagePosition();
    const stages = ticket.board.stages.concat().sort(sortStages());

    const handleStageChange = async (selectedStage: { id: string } | null) => {
        if (!selectedStage || selectedStage.id === ticket.stage_id) {
            return;
        }

        const fromStageId = ticket.stage_id;
        const toStageId = selectedStage.id;
        const fromStage = stages.find(stage => stage.id === fromStageId)!;
        const toStage = stages.find(stage => stage.id === toStageId)!;
        const toBoardId = toStage.board_id;

        // When moving to a *later* stage, ticket moves to the *last* position in the stage.
        // When moving to an *earlier* stage, ticket moves to the *first* position in the stage.
        const toStagePos =
            fromStage && (toStage.board_pos ?? 0) > (fromStage.board_pos ?? 0)
                ? moveToLastPosition({ maxPos: toStage.max_ticket_stage_pos })
                : moveToFirstPosition({ minPos: toStage.min_ticket_stage_pos });

        await moveTicketToStagePosition({
            ticketId: ticket.id,
            toBoardId,
            toStageId,
            toStagePos,
        });
    };

    return (
        <StageButtonDisplay
            currentStageId={ticket.stage_id ?? undefined}
            onSelect={handleStageChange}
            replacementText={ticket.archived_at ? "Archived" : ticket.trashed_at ? "Trashed" : null}
            stages={stages}
            getInstrumentation={stage => ({
                elementName: "ticket_detail.stage_menu",
                eventData: {
                    ticketId: ticket.id,
                    stageId: stage.id,
                },
            })}
        />
    );
}

export type MembersListProps = {
    ticket: FragmentType<typeof fragments.MembersList.ticket>;
};

export function MembersList({ ticket: _ticketFragment }: MembersListProps) {
    const ticket = getFragmentData(fragments.MembersList.ticket, _ticketFragment);
    const { changeTicketMembers } = useChangeTicketMembers();
    const ticketOwnerId = ticket.owners.find(to => to.type === CommonEnums.TicketOwnerType.OWNER)
        ?.user_id;
    const selectedMembersIds = ticket.owners
        .filter(to => to.type === CommonEnums.TicketOwnerType.MEMBER)
        .map(to => to.user_id);
    const authorizedUsers = ticket.board.authorized_users.map(au => au.user).filter(isDefined);

    const onAddMember = async (user: UserSelect_userFragment | null) => {
        if (user) {
            await changeTicketMembers({
                ticketId: ticket.id,
                userIdsToAdd: [user.id],
                userIdsToRemove: [],
            });
        }
    };

    const onRemoveMember = async (user: MembersListDisplay_userFragment | null) => {
        if (user) {
            await changeTicketMembers({
                ticketId: ticket.id,
                userIdsToAdd: [],
                userIdsToRemove: [user.id],
            });
        }
    };

    // The ordinary UI flow is this:
    //   - Ticket has no owners or members
    //   - User adds an owner
    //   - Member picker appears
    //   - User optionally adds additional members
    // So, ordinarily it's not possible to have members without an owner. But it may be
    // possible to remove the owner after members have been added (either here or in some other
    // part of the app). In that case, we still need to show the members list and picker.
    //
    // Thus, we show the member picker long as there is any owner or member, i.e., the array
    // is not empty.
    if (!ticket.owners.length) {
        return null;
    }

    return (
        <MembersListDisplay
            selectedMembersIds={selectedMembersIds}
            ticketOwnerId={ticketOwnerId}
            onAddMember={onAddMember}
            onRemoveMember={onRemoveMember}
            users={authorizedUsers}
            elementName="ticket_detail"
        />
    );
}
