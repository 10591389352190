import { Errors, P } from "c9r-common";
import { orderBy } from "lodash";

import { Log } from "lib/Log";
import { isDefined } from "lib/types/guards";

import { ResolvedFieldSpecifier } from "./ResolvedFieldValueCache";
import { TContextValue } from "../../ContextValue";

/**
 * Helper function to apply a Hasura-style "order" clause. As of May 2023, this is intended to
 * facilitate transitioning the app away from querying the Hasura API directly and to querying
 * Replicache. Consequently, this class only bothers to implement the Hasura-style order
 * clauses that we use in practice, namely asc/desc on fields (not associations).
 *
 * For simplicity, this class does not have static type checking to enforce that the order clause
 * references fields that actually exist.
 */
export function sortEntries<T>({
    entries,
    orderByClause,
}: {
    entries: T[];
    orderByClause?: {}[] | null;
}): T[] {
    const orders = (orderByClause ?? []).flatMap(orderClause => Object.entries(orderClause));

    if (orders.some(order => !["asc", "desc"].includes(order[1] as string))) {
        Log.debug("Unsupported order clause", { orderByClause });
        throw new Errors.UnexpectedCaseError({
            reason: "Unsupported order clause",
            orderByClause,
        });
    }

    return orderBy(
        entries,
        orders.map(order => order[0]),
        orders.map(order => order[1] as "asc" | "desc")
    );
}

/**
 * Helper function to apply a Hasura-style array association args. As of May 2023, this is intended
 * to facilitate transitioning the app away from querying the Hasura API directly and to querying
 * Replicache. Consequently, this class only bothers to implement the Hasura-style args that we use
 * in practice, namely where, order_by, and limit.
 *
 * For simplicity, this function does not have static type checking to enforce that the args
 * reference fields and associations that actually exist.
 */
export function buildArrayAssociationResolver<
    P,
    A extends { where?: {} | null; order_by?: {}[] | null; limit?: number | null },
    T extends { __typename: string }
>(findEntries: (parent: P, args: A, context: TContextValue) => Promise<T[]>) {
    return async (parent: P, args: A, context: TContextValue) => {
        const entries = await findEntries(parent, args, context);

        const filteredEntries = (
            await Promise.all(
                entries.map(entry => context.whereClause.check({ entry, where: args.where }))
            )
        ).filter(isDefined);

        const sortedEntries = sortEntries({
            entries: filteredEntries,
            orderByClause: args.order_by,
        });

        return sortedEntries.slice(0, typeof args.limit === "number" ? args.limit : undefined);
    };
}

export function buildCachedResolver<P extends ResolvedFieldSpecifier["entry"], A, T>(
    fieldName: string,
    resolver: (parent: P, args: A, context: TContextValue) => Promise<T>
) {
    return async (parent: P, args: A, context: TContextValue) => {
        const { resolvedFieldValueCache } = context;
        const fieldSpecifier = { entry: parent, fieldName };
        const cachedValue = resolvedFieldValueCache.get<Promise<T>>(fieldSpecifier);

        if (cachedValue !== undefined) {
            return cachedValue;
        }

        const deferred = P.defer();

        void resolvedFieldValueCache.set(fieldSpecifier, deferred.promise);

        const value = await resolver(parent, args, context);

        deferred.resolve(value);

        return value;
    };
}

export function buildResolver<P, A, C, T>(
    resolver: (parent: P, args: A, context: C) => Promise<T>
) {
    return async (parent: P, args: A, context: C) => {
        try {
            return await resolver(parent, args, context);
        } catch (error) {
            // Log the error so we can get a full stack trace before the stack trace is swallowed
            // by the graphql lib.
            Log.error("Failed to execute Replicache GraphQL resolver", { error });
            throw error;
        }
    };
}
