import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { AbstractButton, AbstractButtonProps } from "components/ui/core/abstract/AbstractButton";

import styles from "./EllipsisButton.module.scss";

export type EllipsisButtonProps = {
    active?: boolean;
    className?: string;
    iconSize?: number;
    vertical?: boolean;
} & AbstractButtonProps;

/**
 * Button representing "more", e.g., for a menu. Supports all standard HTML button attributes.
 */
export function EllipsisButton({
    active,
    className,
    iconSize,
    vertical,
    ...abstractButtonProps
}: EllipsisButtonProps) {
    return (
        <AbstractButton
            {...abstractButtonProps}
            className={classNames(
                className,
                styles.button,
                active && styles.active,
                vertical && styles.vertical
            )}
            preventDefault
        >
            <Icon
                icon={vertical ? "ellipsisVertical" : "ellipsis"}
                iconSet="c9r"
                iconSize={iconSize ?? 16}
            />
        </AbstractButton>
    );
}
