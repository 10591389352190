import React, { useCallback, useRef } from "react";

import AttrAccept from "attr-accept";
import * as FileSelector from "file-selector";
import { useRecoilValue } from "recoil";

import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AppToaster } from "components/ui/core/AppToaster";
import { Icon } from "components/ui/core/Icon";
import { useDragDetection } from "lib/DragAndDrop";

import { useTicketDetailAttachments } from "./useTicketDetailAttachments";

// At one point we used react-dropzone for this. However, we learned that while
// react-dropzone is good for isolated drop areas with no complicated content, it's
// not great for wrapping an entire page. In particular, when using the useDropzone
// hook, clicks on links would not work. It's not 100% clear why, but it appears to
// have been because the entire component tree would rerender on mouse down, causing
// the click event on the link never to fire.
export const useTicketDetailDropzone = ({
    dropzoneRef,
    ticketId,
}: {
    dropzoneRef: React.RefObject<HTMLElement>;
    ticketId: string;
}) => {
    const isOnline = useRecoilValue(networkStatusState);
    const { handleAttachmentUpload } = useTicketDetailAttachments({ ticketId });
    const attachmentToastKey = useRef<string>();

    useDragDetection(dropzoneRef, (event: DragEvent) => {
        if (event.type === "dragenter" && !attachmentToastKey.current) {
            (async () => {
                const files = await FileSelector.fromEvent(event);

                // Only show the toast if online and the drag contains files and not, say, a snippet of text.
                if (isOnline && files.length) {
                    attachmentToastKey.current = AppToaster.info({
                        message: "Drop files anywhere to add as an attachment.",
                        icon: <Icon icon="upload" iconSet="c9r" iconSize={24} />,
                        timeout: 0,
                    });
                }
            })();
        } else if (["dragleave", "drop"].includes(event.type) && attachmentToastKey.current) {
            AppToaster.dismiss(attachmentToastKey.current);
            attachmentToastKey.current = undefined;
        }
    });

    // Per the DOM API, you can't specify a drop event handler (below) without preventing the
    // default behavior of the dragOver event.
    const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLElement>) => {
            event.preventDefault();
            event.persist();

            (async () => {
                // The idea here is to only upload as attachments files on the event that weren't
                // accepted by descendent elements.
                const files = (await FileSelector.fromEvent(event.nativeEvent)) as File[]; // TODO: This cast may be unsafe
                const alreadyAcceptedTypes = [] as string[];
                let acceptingElem = (event.target as Element).closest("[data-drop-accept]");

                do {
                    if (
                        acceptingElem &&
                        acceptingElem instanceof HTMLElement &&
                        acceptingElem.dataset?.dropAccept
                    ) {
                        alreadyAcceptedTypes.push(
                            ...acceptingElem.dataset.dropAccept.split(",").filter(Boolean)
                        );
                        acceptingElem = acceptingElem.parentElement
                            ? acceptingElem.parentElement.closest("[data-drop-accept]")
                            : null;
                    }
                } while (
                    acceptingElem &&
                    acceptingElem instanceof HTMLElement &&
                    acceptingElem.dataset?.dropAccept
                );

                const filesToUpload = files.filter(file => !AttrAccept(file, alreadyAcceptedTypes));

                if (filesToUpload.length) {
                    await handleAttachmentUpload(filesToUpload);
                }
            })();
        },
        [handleAttachmentUpload]
    );

    return { onDragOver, onDrop };
};
