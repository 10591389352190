import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";

import * as FileSelector from "file-selector";
import { useRecoilValue } from "recoil";

import { AppData } from "AppData";
import { AppToaster } from "components/ui/core/AppToaster";
import { CssClasses } from "lib/Constants";
import { electronWindowState } from "lib/ElectronWindowState";
import { Log } from "lib/Log";
import { useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";

export function useAsyncWatcher() {
    const [isInFlight, setIsInFlight] = useState(false);

    const watch: <A extends unknown[], C extends (...args: A) => Promise<unknown>>(
        cb: C,
        options?: { delayMs?: number }
    ) => (...args: A) => Promise<void> = (cb, { delayMs } = {}) => async (...args) => {
        if (isInFlight) {
            return;
        }

        setIsInFlight(true);

        try {
            await cb(...args);
        } finally {
            if (delayMs) {
                await new Promise<void>(resolve => {
                    setTimeout(() => resolve(), delayMs);
                });
            }

            setIsInFlight(false);
        }
    };

    return { isInFlight, watch };
}

export function useClipboard() {
    const copyTextToClipboard = useCallback(
        async ({ text, successToast }: { text: string; successToast?: React.ReactNode }) => {
            try {
                await navigator.clipboard.writeText(text);

                if (successToast) {
                    AppToaster.success({
                        message: successToast,
                    });
                }
            } catch (error) {
                Log.error("Failed to copy to clipboard", { error });
                AppToaster.error({
                    message: "Something went wrong copying that to the clipboard.",
                });
            }
        },
        []
    );

    return { copyTextToClipboard };
}

/**
 * LEGACY helper for managing state of a dialog. Pass the returned "isOpen" as the isOpen property
 * of a dialog, and use the returned "open" and "close" functions to open or close the dialog,
 * e.g., as a button click handler or as a handler for the dialog's onClose callback.
 *
 * The returned object is memoized and so can be safely used as is, without destructuring, as
 * a dependency to useEffect, useCallback, etc.
 */
export function useDialog<TProps>() {
    const [isOpen, setIsOpen] = useState(false);
    const [props, setProps] = useState<TProps | null>(null);

    const open = useCallback((value?: TProps | null) => {
        setProps(value ?? null);
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    // This is a bit subtle!
    //
    // When the hook first runs, isOpen is false. Therefore, openDialogCount doesn't immediately
    // get incremented, which is correct.
    //
    // Afterward, the cleanup function runs when isOpen changes or on unmount. If isOpen changes
    // to true, openDialogCount gets incremented. If it changes back to false, the cleanup function
    // runs (and isOpen is true, because the cleanup has a closure over the prior variable) and
    // openDialogCount gets decremented. Likewise if the component unmounts, the cleanup function
    // runs and decrements openDialogCount only if the dialog was open at the time.
    useEffect(() => {
        if (isOpen) {
            AppData.openDialogCount += 1;
        }

        return () => {
            if (isOpen) {
                AppData.openDialogCount -= 1;
            }
        };
    }, [isOpen]);

    const result = { isOpen, open, close, props };
    const dialog = useMemo(() => ({} as typeof result), []);

    Object.assign(dialog, result);

    return dialog;
}

/**
 * Hook to track whether some value variable changed, and temporarily indicate whether it did.
 *
 * Returns a boolean indicating whether the value recently changed from "from" to "to"
 * (within the timeout).
 */
export function useDidChange<TValue>({
    value,
    from,
    to,
    resetTimeoutMs,
}: {
    /** The value to track. */
    value: TValue;

    /** Will trigger a change if the value change from "from" to "to". */
    from: TValue;

    /** Will trigger a change if the value change from "from" to "to". */
    to: TValue;

    /** Timeout for indicating a change occurred. */
    resetTimeoutMs: number;
}) {
    const [didChange, setDidChange] = useResettingState(false, resetTimeoutMs);
    const [lastValue, setLastValue] = useState(value);

    useEffect(() => {
        if (lastValue === from && value === to) {
            setDidChange(true);
        }

        setLastValue(value);
    }, [value, from, to, lastValue, setDidChange]);

    return didChange;
}

export function useDocumentTitle(initialTitle?: string | null) {
    const [documentTitle, setDocumentTitle] = useState(initialTitle);

    useEffect(() => {
        setDocumentTitle(initialTitle);
    }, [initialTitle]);

    useEffect(() => {
        document.title = window.electron
            ? ["Flat", documentTitle].filter(Boolean).join(" | ")
            : [documentTitle, "Flat"].filter(Boolean).join(" | ");
    }, [documentTitle]);

    return { setDocumentTitle };
}

/**
 * Helper to open a native file picker dialog. The returned openFilePicker function can only
 * be used in response to a user-initiative event, e.g., a user click on an button or menu item.
 */
export function useFilePicker() {
    const openFilePicker = useCallback(
        (
            { accept, multiple }: { accept?: string; multiple?: boolean } = {},
            cb: (files: File[]) => void
        ) => {
            const inputElement = document.createElement("input");

            inputElement.className = CssClasses.INVISIBLE;
            inputElement.type = "file";
            inputElement.accept = accept ?? "";
            inputElement.multiple = multiple ?? false;

            // On iOS Safari, the input element must actually be on the DOM, or the
            // onChange event listener will not fire. See discussion at
            // https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
            document.body.appendChild(inputElement);

            const onChange = async (event: Event) => {
                const files = (await FileSelector.fromEvent(event)) as File[]; // TODO: This cast may be unsafe;

                inputElement.removeEventListener("change", onChange);
                inputElement.remove();

                if (cb) {
                    cb(files);
                }
            };

            inputElement.addEventListener("change", onChange);
            inputElement.dispatchEvent(new MouseEvent("click"));
        },
        []
    );

    return { openFilePicker };
}

export function useForceRerender() {
    const [, forceRerender] = useReducer(() => ({}), {});

    return forceRerender;
}

export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const runCurrentCallback = () => {
            savedCallback.current?.();
        };

        if (delay !== null) {
            const interval = setInterval(runCurrentCallback, delay);

            return () => clearInterval(interval);
        }

        return () => undefined;
    }, [delay]);
}

export function useIsTitleBarHidden() {
    const { isFullScreen: isElectronWindowFullScreen } = useRecoilValue(electronWindowState);

    return !!(window.electron?.config?.ui?.titleBar?.hide && !isElectronWindowFullScreen);
}

export function useIsMounted() {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
}

export function useMaybeControlledValue<T>({
    value: valueControlled,
    onValueChange: onValueChangeControlled,
    defaultValue,
}: {
    value?: T;
    onValueChange?: (newValue: T) => void;
    defaultValue: T;
}) {
    const isControlled = valueControlled !== undefined;
    const [valueInternal, setValueInternal] = useState(
        isControlled ? valueControlled : defaultValue
    );
    const value = isControlled ? valueControlled : valueInternal;

    const onValueChange = useCallback(
        (newValue: T) => {
            onValueChangeControlled?.(newValue);

            if (!isControlled) {
                setValueInternal(newValue);
            }
        },
        [isControlled, onValueChangeControlled]
    );

    return [value, onValueChange] as const;
}

export function useMediaQuery(query: string) {
    const checkIsMatch = useCallback((q: string) => {
        return window?.matchMedia?.(q).matches || false;
    }, []);

    const [isMatch, setIsMatch] = useState(checkIsMatch(query));

    const refresh = useCallback(() => {
        setIsMatch(checkIsMatch(query));
    }, [checkIsMatch, query]);

    useEffect(() => {
        const matchMedia = window?.matchMedia?.(query);

        if (!matchMedia) {
            return () => undefined;
        }

        if (matchMedia.addEventListener) {
            matchMedia.addEventListener("change", refresh);
        } else if (matchMedia.addListener) {
            matchMedia.addListener(refresh);
        }

        return () => {
            if (matchMedia.removeEventListener) {
                matchMedia.removeEventListener("change", refresh);
            } else if (matchMedia.removeListener) {
                matchMedia.removeListener(refresh);
            }
        };
    }, [query, refresh]);

    return isMatch;
}

export type useNavigableItemListParams<TItem> = {
    /**
     * By default, there will always be an active item. On open, it's the first item.
     * However, some UIs don't show an active item until the user interacts (e.g., moves
     * the mouse over an item or hits the up/down key).
     */
    allowNoActiveItem?: boolean;

    /**
     * The list of items.
     */
    items: TItem[];

    /**
     * Comparison function whether two items are equal. If omitted, uses strict JS equality, but
     * if items is a list of plain objects, you will probabl want to use deep equality.
     */
    itemsEqual?: (a: TItem, b: TItem) => boolean;

    /**
     * Number of items per page, used to control PageUp and PageDown velocity.
     */
    itemsPerPage?: number;

    /**
     * Callback invoked when the active item changes.
     */
    onActiveItemChange?: (item?: TItem) => void;
};

export function useNavigableItemList<TItem>({
    allowNoActiveItem,
    items,
    itemsEqual,
    itemsPerPage,
    onActiveItemChange,
}: useNavigableItemListParams<TItem>) {
    const NO_ACTIVE_ITEM_SENTINEL_INDEX = -1;
    const defaultActiveItemIndex = allowNoActiveItem ? NO_ACTIVE_ITEM_SENTINEL_INDEX : 0;

    const [activeItemIndex, _setActiveItemIndex] = useState(defaultActiveItemIndex);

    const itemsCountRef = useRef(items.length);

    const activeItem =
        activeItemIndex === NO_ACTIVE_ITEM_SENTINEL_INDEX ? undefined : items[activeItemIndex];
    const lastActiveItemRef = useRef<TItem | undefined>(activeItem);

    useEffect(() => {
        itemsCountRef.current = items.length;
    }, [items.length]);

    // Suppose the list of available items changes, but the previously active item is still in
    // the list. That item should still be the active one, even if it's in a different position.
    // This effect must run *before* the effect below.
    useEffect(() => {
        const lastActiveItem = lastActiveItemRef.current;
        const newIndexOfLastActiveItem = lastActiveItem
            ? items.findIndex(item =>
                  itemsEqual ? itemsEqual(item, lastActiveItem) : item === lastActiveItem
              )
            : defaultActiveItemIndex;

        if (newIndexOfLastActiveItem >= 0) {
            _setActiveItemIndex(newIndexOfLastActiveItem);
        }
    }, [defaultActiveItemIndex, items, itemsEqual]);

    // Keep the reference to the last active item up-to-date.
    // This effect must run *after* the effect above, so as not to overwrite lastActiveItemRef
    // prematurely, before we've used it.
    useEffect(() => {
        lastActiveItemRef.current = activeItem;
    }, [activeItem]);

    useEffect(() => {
        onActiveItemChange?.(activeItem);
    }, [activeItem, onActiveItemChange]);

    useEffect(() => {
        const handleKeyEvent = (e: KeyboardEvent) => {
            if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                e.preventDefault();

                if (activeItemIndex === NO_ACTIVE_ITEM_SENTINEL_INDEX) {
                    // There was no active item. So, make either the first or last
                    // item active.
                    _setActiveItemIndex(e.key === "ArrowUp" ? items.length - 1 : 0);
                } else {
                    _setActiveItemIndex(
                        (activeItemIndex + items.length + (e.key === "ArrowUp" ? -1 : 1)) %
                            items.length
                    );
                }

                return true;
            }

            if (itemsPerPage && ["PageUp", "PageDown"].includes(e.key)) {
                e.preventDefault();

                if (activeItemIndex === NO_ACTIVE_ITEM_SENTINEL_INDEX) {
                    // There was no active item. So, make either the first or last
                    // item active.
                    _setActiveItemIndex(e.key === "PageUp" ? items.length - 1 : 0);
                } else {
                    _setActiveItemIndex(
                        Math.max(
                            0,
                            Math.min(
                                items.length - 1,
                                activeItemIndex + itemsPerPage * (e.key === "PageDown" ? 1 : -1)
                            )
                        )
                    );
                }

                return true;
            }

            return false;
        };

        document.addEventListener("keydown", handleKeyEvent);

        return () => {
            document.removeEventListener("keydown", handleKeyEvent);
        };
    }, [activeItemIndex, items, itemsPerPage, NO_ACTIVE_ITEM_SENTINEL_INDEX]);

    const resetActiveItem = useCallback(() => {
        _setActiveItemIndex(defaultActiveItemIndex);
    }, [defaultActiveItemIndex]);

    const setActiveItemIndex = useCallback(
        (index: number) => {
            _setActiveItemIndex(Math.max(0, Math.min(index, itemsCountRef.current)));
        },
        [_setActiveItemIndex]
    );

    return useMemo(
        () => ({
            activeItem,
            resetActiveItem,
            setActiveItemIndex,
        }),
        [activeItem, resetActiveItem, setActiveItemIndex]
    );
}

export function useOnPathChange(callback: () => void) {
    const location = useLocation();
    const lastPathname = useRef<string | null>(null);

    useEffect(() => {
        if (lastPathname.current !== location.pathname) {
            lastPathname.current = location.pathname;
            callback();
        }
    }, [location.pathname, callback]);
}

// See https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
export function useOutsideClick(
    ref: React.RefObject<HTMLElement>,
    callback: (event: MouseEvent) => void,
    {
        eventType = "click",
        useCapture = false,
    }: { eventType?: "click" | "mousedown" | "mouseup"; useCapture?: boolean } = {}
) {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                ref.current &&
                event.target instanceof Element &&
                !ref.current.contains(event.target)
            ) {
                callback(event);
            }
        };

        document.addEventListener(eventType, handleClick, useCapture);

        return () => {
            document.removeEventListener(eventType, handleClick, useCapture);
        };
    }, [ref, callback, eventType, useCapture]);
}

export function useResetScrollPosition({
    scrollElementRef,
    id,
}: {
    scrollElementRef: React.RefObject<HTMLElement>;
    id: unknown;
}) {
    useLayoutEffect(() => {
        scrollElementRef.current?.scroll(0, 0);
    }, [id, scrollElementRef]);
}

export function useResettingState<TState>(initialState: TState, resetTimeoutMs: number) {
    const [state, setInternalState] = useState(initialState);
    const timeout = useRef<number>();

    useEffect(() => {
        return () => window.clearTimeout(timeout.current);
    }, []);

    const setState = useCallback(
        (newState: TState) => {
            window.clearTimeout(timeout.current);

            if (newState !== initialState) {
                timeout.current = window.setTimeout(() => setState(initialState), resetTimeoutMs);
            }

            setInternalState(newState);
        },
        [initialState, resetTimeoutMs]
    );

    return [state, setState] as const;
}

export function useResettingValueMap<TValue>({ timeoutMs }: { timeoutMs: number }) {
    type TrackingId = number | string;

    const timeoutsById = useRef<Record<TrackingId, number>>({});
    const [valuesById, setValuesById] = useState<
        Record<TrackingId, { setAt: Date; value: TValue } | null>
    >({});

    const getValueById = useCallback(
        ({ id }: { id: TrackingId }) => {
            const { setAt, value } = valuesById[id] ?? {};

            if (!setAt) {
                return undefined;
            }

            return Date.now() - setAt.getTime() < timeoutMs ? value : undefined;
        },
        [timeoutMs, valuesById]
    );

    useEffect(() => {
        const timeouts = Object.values(timeoutsById.current);

        return () => {
            timeouts.forEach(timeout => window.clearTimeout(timeout));
        };
    }, []);

    const clearEntryById = useCallback(({ id }: { id: TrackingId }) => {
        setValuesById(prev => ({ ...prev, [id]: null }));
    }, []);

    const setValueById = useCallback(
        ({ id, value }: { id: TrackingId; value: TValue }) => {
            if (value !== undefined) {
                timeoutsById.current[id] = window.setTimeout(
                    () => clearEntryById({ id }),
                    timeoutMs
                );

                setValuesById(prev => ({
                    ...prev,
                    [id]: { setAt: new Date(Date.now()), value },
                }));
            } else {
                window.clearTimeout(timeoutsById.current[id]);
                clearEntryById({ id });
            }
        },
        [clearEntryById, timeoutMs]
    );

    return useMemo(
        () => ({
            getValueById,
            setValueById,
        }),
        [getValueById, setValueById]
    );
}

export function useSaveScrollPosition({
    scrollElementRef,
    component,
    id,
}: {
    scrollElementRef: React.RefObject<HTMLElement>;
    component: string;
    id: number | string;
}) {
    const key = `${component}.${id}.scrollPos`;
    const lastKey = useRef(key);

    const saveScrollPos = useCallback(
        ({ element, key: storageKey }: { element: Element | null; key: string }) => {
            if (!element) {
                return;
            }

            const x = element.scrollLeft;
            const y = element.scrollTop;

            if (!x && !y) {
                Storage.Session.removeItem(storageKey);
            } else {
                Storage.Session.setItem(storageKey, { x, y });
            }
        },
        []
    );

    useLayoutEffect(() => {
        const scrollPos = Storage.Session.getItem(key);

        if (scrollPos && scrollElementRef.current) {
            scrollElementRef.current.scroll(scrollPos.x, scrollPos.y);
        }
    }, [key, scrollElementRef]);

    useEffect(() => {
        const cachedElement = scrollElementRef.current;

        // Save scroll position when component is unmounted.
        return () => {
            saveScrollPos({
                element: cachedElement,
                key: lastKey.current,
            });
        };
    }, [saveScrollPos, scrollElementRef]);

    useEffect(() => {
        const listener = () =>
            saveScrollPos({
                element: scrollElementRef.current,
                key,
            });

        // Save scroll position if the page gets reloaded.
        window.addEventListener("beforeunload", listener);

        return () => {
            window.removeEventListener("beforeunload", listener);
        };
    }, [key, saveScrollPos, scrollElementRef]);

    // Tricky: If we're rerendering using a different key, grab the scroll position
    // *now*. Don't wait for an effect, because by then the rendered content may
    // already have changed.
    if (key !== lastKey.current) {
        saveScrollPos({ element: scrollElementRef.current, key: lastKey.current });
        lastKey.current = key;
    }
}

/**
 * Helper for managing state of a togglable component. Pass the returned "isOpen" as the isOpen
 * property of the component, and use the returned "open" and "close" functions to open or close
 * the component, e.g., as a button click handler or as a handler for the component's onClose
 * callback.
 *
 * The returned object is memoized and so can be safely used as is, without destructuring, as a
 * dependency to useEffect, useCallback, etc.
 */
export function useToggle() {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    const result = { isOpen, open, close };
    const toggle = useMemo(() => ({} as typeof result), []);

    Object.assign(toggle, result);

    return toggle;
}

export function useWindowSize() {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useLayoutEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return size;
}
