import React from "react";

import {
    MenuDivider as BlueprintMenuDivider,
    MenuDividerProps as BlueprintMenuDividerProps,
} from "@blueprintjs/core";
import classNames from "classnames";

import styles from "./MenuDivider.module.scss";

export type MenuDividerProps = BlueprintMenuDividerProps;

export function MenuDivider({ ...blueprintMenuDividerProps }: MenuDividerProps) {
    return (
        <BlueprintMenuDivider
            {...blueprintMenuDividerProps}
            className={classNames(blueprintMenuDividerProps.className, styles.menuDivider)}
        />
    );
}
