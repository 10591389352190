import React, { useCallback, useState } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { useLiveViewQuery } from "components/loading/Loading";
import { useFullPageModalContext } from "components/shared/FullPageModal";
import { ImportNotes } from "components/shared/ImportNotes";
import { Avatar } from "components/ui/common/Avatar";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { Dialog } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentUser } from "contexts/UserContext";
import { useCreateBoardDialog } from "dialogs/CreateBoardDialog";
import { useEditBoardDialog } from "dialogs/EditBoardDialog";
import { ExternalTicketSources } from "lib/Constants";
import { useDialog } from "lib/Hooks";
import { useNomenclature } from "lib/Nomenclature";
import { Queries } from "lib/Queries";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import {
    BoardSettings_orgFragment,
    EditBoardDialog_boardFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import styles from "./BoardSettings.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment BoardSettings_org on orgs {
            id

            all_boards: boards {
                id
                access_type
                archived_at
                display_name
                slug

                active_attached_users: attached_users(
                    where: { user: { disabled_at: { _is_null: true } } }
                ) {
                    user {
                        id
                    }
                }

                import {
                    id
                    source
                    notes
                    processed_at
                }

                ...EditBoardDialog_board
            }

            users(where: { disabled_at: { _is_null: true } }) {
                id
                name
                role

                identity {
                    id
                    email_address
                }

                ...Avatar_user
            }
        }
    `),
};

type TOrg = BoardSettings_orgFragment;
type TBoard = BoardSettings_orgFragment["all_boards"][number];

const useGuests = ({ org, board }: { org: TOrg; board: TBoard }) => {
    const allGuestUsers = org.users
        .filter(user => user.role === CommonEnums.UserRole.USER_ORG_GUEST)
        .sort((a, b) =>
            (a.identity?.email_address ?? a.name) < (b.identity?.email_address ?? b.name) ? -1 : 1
        );
    const attachedGuestUsers = board.active_attached_users
        .map(boardUser => allGuestUsers.find(user => user.id === boardUser.user.id))
        .filter(isDefined)
        .sort((a, b) =>
            (a.identity?.email_address ?? a.name) < (b.identity?.email_address ?? b.name) ? -1 : 1
        );
    const attachableGuestUsers = allGuestUsers.filter(user => !attachedGuestUsers.includes(user));

    return { allGuestUsers, attachedGuestUsers, attachableGuestUsers };
};

type ImportNotesDialogProps = {
    board: TBoard;
    importNotes: { items: string[] };
    isOpen: boolean;
    onClose: () => void;
};

function ImportNotesDialog({ board, importNotes, isOpen, onClose }: ImportNotesDialogProps) {
    return (
        <Dialog
            title={`Import notes for "${board.display_name}"`}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <ImportNotes importNotes={importNotes} />
            </Dialog.Body>
        </Dialog>
    );
}

type BoardCardProps = {
    org: TOrg;
    board: TBoard;
};

function BoardCard({ org, board }: BoardCardProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const editBoardDialog = useEditBoardDialog();
    const importNotesDialog = useDialog();
    const { attachedGuestUsers } = useGuests({ org, board });
    const guestCountText = `${
        attachedGuestUsers.length === 0 ? "No" : attachedGuestUsers.length.toLocaleString()
    } ${attachedGuestUsers.length === 1 ? "guest" : "guests"}`;
    const isProcessedExternalImport = !!(
        board.import?.processed_at && ExternalTicketSources[board.import.source]
    );
    const isArchived = !!board.archived_at;

    const openEditBoardDialog = useCallback(() => {
        editBoardDialog.openWithProps({
            board: getFragmentData(EditBoardDialog_boardFragmentDoc, board),
            redirectOnNameChange: false,
        });
    }, [board, editBoardDialog]);

    return (
        <div className={classNames(styles.boardCard, isArchived && styles.archived)}>
            <div className={styles.boardCardTitle}>
                {board.display_name}
                {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                    <Icon icon="lock" iconSet="c9r" iconSize={12} strokeWidthAbsolute={1.5} />
                ) : null}
            </div>

            <div className={styles.boardCardInfo}>
                {isArchived ? (
                    <div>
                        Archived <TimeAgo date={board.archived_at!} />
                    </div>
                ) : null}
                {attachedGuestUsers.length > 0 ? (
                    <>
                        <div className={classNames(styles.boardCardGuestCount)}>
                            {guestCountText}
                        </div>
                        <div className={styles.boardCardGuestAvatars}>
                            {attachedGuestUsers.map(user => (
                                <Avatar key={user.id} user={user} size={24} showTooltip />
                            ))}
                        </div>
                    </>
                ) : null}
                {isProcessedExternalImport ? (
                    <div className={styles.boardCardImportDetails}>
                        <span>
                            Imported from {ExternalTicketSources[board.import!.source]!.displayName}{" "}
                            on{" "}
                            {new Date(board.import!.processed_at!).toLocaleDateString([], {
                                dateStyle: "short",
                            })}
                        </span>
                        {board.import?.notes ? (
                            <>
                                <br />
                                <span>
                                    <TextButton
                                        className={styles.boardCardImportNotesBtn}
                                        link
                                        text="View import notes"
                                        onClick={importNotesDialog.open}
                                        instrumentation={{
                                            elementName: "board_settings.show_import_notes_btn",
                                            eventData: {
                                                boardId: board.id,
                                            },
                                        }}
                                    />
                                </span>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>

            <div className={styles.boardCardMenuWrapper}>
                <MenuPopover
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [8, -4],
                            },
                        },
                    }}
                    content={
                        <Menu className={styles.boardCardMenu}>
                            <MenuItem
                                icon={<Icon icon="settings" iconSet="lucide" iconSize={18} />}
                                text="Workspace setup"
                                onClick={openEditBoardDialog}
                                instrumentation={{
                                    elementName: "board_settings.menu.setup",
                                    eventData: {
                                        boardId: board.id,
                                    },
                                }}
                            />
                        </Menu>
                    }
                    onOpening={() => setIsMenuOpen(true)}
                    onClosing={() => setIsMenuOpen(false)}
                    placement="bottom-end"
                    popoverClassName="bp3-dark"
                >
                    <EllipsisButton
                        className={classNames(
                            styles.ellipsisMenuBtn,
                            isMenuOpen && styles.ellipsisMenuBtnOpen
                        )}
                        iconSize={18}
                        vertical
                        instrumentation={null}
                    />
                </MenuPopover>
            </div>

            {isProcessedExternalImport && board.import?.notes ? (
                <ImportNotesDialog
                    board={board}
                    importNotes={board.import.notes}
                    isOpen={importNotesDialog.isOpen}
                    onClose={importNotesDialog.close}
                />
            ) : null}
        </div>
    );
}

function NewBoardPlaceholderCard() {
    const { setReturnTo } = useFullPageModalContext();
    const { nomenclature } = useNomenclature();
    const { buildBoardUrl } = useUrlBuilders();
    const createBoardDialog = useCreateBoardDialog();

    return (
        <button
            type="button"
            className={classNames(styles.boardCard, styles.newBoardPlaceholderCard)}
            onClick={() => {
                createBoardDialog.openWithProps({
                    onCreate: ({ slug, displayName }) => {
                        setReturnTo({
                            pathname: buildBoardUrl({
                                boardSlug: slug,
                                vanity: {
                                    boardDisplayName: displayName,
                                },
                            }).pathname,
                        });
                    },
                });
            }}
        >
            <span>+ New {nomenclature.space.singular.toLowerCase()}</span>
        </button>
    );
}

type BoardSectionProps = {
    org: TOrg;
    boards: TBoard[];
};

function BoardSection({ org, boards }: BoardSectionProps) {
    const { nomenclature } = useNomenclature();

    return (
        <div className={classNames(styles.section, styles.boardsSection)}>
            <h1>{nomenclature.space.plural}</h1>

            <div className={styles.boardGrid}>
                {boards
                    .concat()
                    .sort((a, b) => (a.display_name < b.display_name ? -1 : 1))
                    .map(board => (
                        <BoardCard key={board.id} org={org} board={board} />
                    ))}
                <NewBoardPlaceholderCard />
            </div>
        </div>
    );
}

export function BoardSettings() {
    const currentUser = useCurrentUser();

    const componentQuery = useLiveViewQuery({
        query: BoardSettings.queries.component,
        variables: {
            orgId: currentUser.org_id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const org = getFragmentData(fragments.org, componentQuery.data?.org);

    if (!org) {
        return null;
    }

    const { all_boards: boards } = org;

    return (
        <div className={styles.container}>
            <BoardSection org={org} boards={boards} />
        </div>
    );
}

BoardSettings.queries = {
    component: gql(/* GraphQL */ `
        query BoardSettings($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                ...BoardSettings_org
            }
        }
    `),
};

Queries.register({ component: "BoardSettings", gqlMapByName: BoardSettings.queries });
