import { useAutoRestartSubscription } from "lib/apollo/useAutoRestartSubscription";
import { gql } from "lib/graphql/__generated__";

import { TReplicache } from "./Factory";

const subscription = gql(/* GraphQL */ `
    subscription ReplicachePoke {
        replicache_spaces {
            id
            version
        }
    }
`);

export function usePokeReplicache({ replicache }: { replicache: TReplicache }) {
    useAutoRestartSubscription(subscription, {
        onSubscriptionData: () => replicache.pull(),
    });
}
