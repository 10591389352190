import { useCallback, useEffect, useRef } from "react";

/**
 * Helper to fire dragenter and dragleave/drop events on an element without them firing on
 * children of that element.
 */
export const useDragDetection = (
    ref: React.RefObject<HTMLElement>,
    callback: (event: DragEvent) => void
) => {
    const count = useRef(0);

    const handleDragEnter = useCallback(
        (event: DragEvent) => {
            if (
                ref?.current &&
                event.target instanceof Element &&
                ref.current.contains(event.target)
            ) {
                count.current += 1;

                if (count.current === 1) {
                    callback(event);
                }
            }
        },
        [ref, callback]
    );

    const handleDragLeave = useCallback(
        (event: DragEvent) => {
            if (
                ref?.current &&
                event.target instanceof Element &&
                ref.current.contains(event.target)
            ) {
                count.current -= 1;

                if (count.current === 0) {
                    callback(event);
                }
            }
        },
        [ref, callback]
    );

    useEffect(() => {
        const elem = ref?.current;

        elem?.addEventListener("dragenter", handleDragEnter);
        elem?.addEventListener("dragleave", handleDragLeave);
        elem?.addEventListener("drop", handleDragLeave);

        return () => {
            elem?.removeEventListener("dragenter", handleDragEnter);
            elem?.removeEventListener("dragleave", handleDragLeave);
            elem?.removeEventListener("drop", handleDragLeave);
        };
    }, [ref, callback, handleDragEnter, handleDragLeave]);
};

/**
 * Simple helper to standardize creating and retrieving unique Draggable and Droppable component IDs.
 */
export const dragAndDropEntity = {
    divider: "|||",
    getDndId: (entityType: string, ...ids: (number | string)[]) =>
        `${entityType.toUpperCase()}${dragAndDropEntity.divider}${ids.join(
            dragAndDropEntity.divider
        )}`,
    getEntityType: (dndId: string) => dndId.split(dragAndDropEntity.divider)[0],
    // Entity type might include multiple dividers, therefore reverse the split array to ensure that we get the number
    // appended at the end.
    getRootId: (dndId: string) => dndId.split(dragAndDropEntity.divider).reverse()[0],
};
