import React, { useEffect, useState } from "react";

import { CommonEnums } from "c9r-common";
import { useRecoilValue } from "recoil";

import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { TextInput } from "components/ui/core/TextInput";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useAsyncWatcher } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import { Log } from "lib/Log";
// As of June 2023, we should move the billing mutations and state to a shared lib.
// eslint-disable-next-line import/no-restricted-paths
import { useChangeSubscriptionPlan, useDeleteOrg } from "views/settings/billing/BillingMutations";
// eslint-disable-next-line import/no-restricted-paths
import { isEligibleForFreePlanDowngradeState } from "views/settings/billing/BillingState";

import styles from "./DeleteAccountDialog.module.scss";

export type DeleteAccountDialogProps = {
    onChange?: () => void;
};

const dialogState = dialogStateFamily<DeleteAccountDialogProps>("DeleteAccountDialog");

export function useDeleteAccountDialog() {
    return useDialogSingleton(dialogState);
}

export function DeleteAccountDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const dialog = useDeleteAccountDialog();
    const currentUser = useCurrentUser();
    const [confirmationInputText, setConfirmationInputText] = useState("");
    const isEligibleForFreePlanDowngrade = useRecoilValue(isEligibleForFreePlanDowngradeState);
    const { deleteOrg } = useDeleteOrg();
    const { changeSubscriptionPlan } = useChangeSubscriptionPlan();
    const downgradeToFreePlanSubmission = useAsyncWatcher();
    const deleteOrgSubmission = useAsyncWatcher();
    const { recordEvent } = useInstrumentation();
    const orgDisplayName = currentUser.org.display_name;

    useEffect(() => {
        if (isOpen) {
            setConfirmationInputText("");
        }
    }, [isOpen]);

    const handleDowngradeToFreePlan = async () => {
        const planKey = CommonEnums.SubscriptionPlanKey.FREE_PLAN;

        const [result] = await Promise.all([
            changeSubscriptionPlan({
                planKey,
                billingInterval: CommonEnums.SubscriptionBillingInterval.MONTH,
            }),
            recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                eventData: { planKey },
                elementName: "settings.delete_account_dialog",
                dedupeKey: Date.now(),
            }),
        ]);

        if (!result.data?.change_subscription_plan.ok) {
            Log.error("Failed to change subscription plan");
            AppToaster.error({
                message: "Something went wrong updating your subscription.",
            });
        }

        await props?.onChange?.();
        dialog.close();
    };

    const handleDeleteOrg = async () => {
        await recordEvent({
            eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
            elementName: "settings.delete_account_dialog",
            eventData: { deleteOrg: true, confirmationInputText },
            dedupeKey: Date.now(),
        });

        // Artificial delay to make it feel more substantial.
        await new Promise<void>(resolve => {
            setTimeout(() => resolve(), 3000);
        });

        try {
            await deleteOrg();
            dialog.close();
        } catch (error) {
            Log.error("Failed to delete org", { error });
            AppToaster.error({
                message: "Something went wrong deleting your organization.",
            });
        }
    };

    const isSubmissionInFlight =
        downgradeToFreePlanSubmission.isInFlight || deleteOrgSubmission.isInFlight;

    return (
        <Dialog
            className={styles.deleteAccountDialog}
            title="Delete organization"
            isOpen={isOpen}
            onClose={dialog.close}
        >
            <Dialog.Body>
                {isEligibleForFreePlanDowngrade ? (
                    <p>
                        Your organization is <strong>eligible to downgrade to the Free Plan</strong>
                        , or you can delete it.
                    </p>
                ) : null}
                <p>
                    Deleting your organization will cancel your subscription and take effect
                    immediately. Your organization will no longer be accessible to you or your team.
                </p>

                <p>
                    Please type <strong>{orgDisplayName}</strong> to confirm.
                </p>
                <TextInput
                    className={styles.input}
                    value={confirmationInputText}
                    placeholder={`Type ${orgDisplayName} to confirm`}
                    onChange={e => {
                        setConfirmationInputText(e.target.value);
                    }}
                />
                <p className={styles.expunge}>
                    Your organization's data will be permanently expunged after 90 days.
                </p>
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    {isEligibleForFreePlanDowngrade ? (
                        <BorderButton
                            content="Downgrade to Free Plan"
                            cta
                            disabled={isSubmissionInFlight}
                            instrumentation={{
                                elementName: "settings.delete_account_dialog.downgrade_btn",
                            }}
                            loading={downgradeToFreePlanSubmission.isInFlight}
                            onClick={downgradeToFreePlanSubmission.watch(handleDowngradeToFreePlan)}
                        />
                    ) : (
                        <BorderButton
                            content="Cancel"
                            onClick={dialog.close}
                            instrumentation={null}
                        />
                    )}
                    <BorderButton
                        content={<>Delete {orgDisplayName}</>}
                        danger
                        disabled={isSubmissionInFlight || confirmationInputText !== orgDisplayName}
                        instrumentation={{
                            elementName: "settings.delete_account_dialog.delete_org_btn",
                        }}
                        loading={deleteOrgSubmission.isInFlight}
                        onClick={deleteOrgSubmission.watch(handleDeleteOrg)}
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
