import React, { useEffect, useState } from "react";

import { FormGroup } from "@blueprintjs/core";
import { CommonEnums } from "c9r-common";
import { useRecoilValue } from "recoil";
import isEmail from "validator/lib/isEmail";

import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { TextInput } from "components/ui/core/TextInput";
import { useCurrentIdentity } from "contexts/IdentityContext";
import { useCurrentUser } from "contexts/UserContext";
import { useAsyncWatcher } from "lib/Hooks";
import { Log } from "lib/Log";
import { useChangeUserEmailAddress } from "lib/mutations";

import styles from "./ChangeEmailAddressDialog.module.scss";

const dialogState = dialogStateFamily<null>("ChangeEmailAddressDialog");

export function useChangeEmailAddressDialog() {
    return useDialogSingleton(dialogState);
}

export function ChangeEmailAddressDialog() {
    const currentIdentity = useCurrentIdentity();
    const currentUser = useCurrentUser();
    const { isOpen } = useRecoilValue(dialogState);
    const dialog = useChangeEmailAddressDialog();
    const [newEmailAddress, setNewEmailAddress] = useState(currentUser.identity.email_address);
    const [isInviteeEmailAddressValid, setIsInviteeEmailAddressValid] = useState(true);
    const submission = useAsyncWatcher();
    const { changeUserEmailAddress } = useChangeUserEmailAddress();

    const orgCount = currentIdentity.users.length;
    const otherOrgCount = orgCount - 1;

    useEffect(() => {
        if (isOpen) {
            setNewEmailAddress(currentUser.identity.email_address);
        }
    }, [isOpen, currentUser.identity.email_address]);

    useEffect(() => {
        setIsInviteeEmailAddressValid(!!(newEmailAddress && isEmail(newEmailAddress)));
    }, [newEmailAddress]);

    const handleChangeUserEmailAddress = submission.watch(async () => {
        const emailAddress = newEmailAddress.trim();

        if (!isEmail(emailAddress)) {
            return;
        }

        try {
            const result = await changeUserEmailAddress({ emailAddress });

            if (result.data?.change_user_email_address.ok) {
                AppToaster.success({
                    message:
                        "Check your email and click the verification link to confirm your new address.",
                });
            } else {
                const errorCode = (result.data?.change_user_email_address.error as any).code;

                switch (errorCode) {
                    case CommonEnums.ActionErrorCode.ACTION_DUPLICATE_EMAIL_ADDRESS:
                        AppToaster.info({
                            message: "There's already a user with that email address.",
                        });
                        break;

                    default:
                        throw new Error(errorCode);
                }
            }

            dialog.close();
            setNewEmailAddress("");
        } catch (error) {
            Log.error("Failed to update email address", { error });
            AppToaster.error({
                message: "Sorry, something went wrong updating your email address.",
            });
        }
    });

    return (
        <Dialog
            className={styles.dialog}
            title="Change email"
            isOpen={isOpen}
            onClose={dialog.close}
        >
            <Dialog.Body>
                <FormGroup label="Email address" labelFor="new-email-address">
                    <TextInput
                        id="new-email-address"
                        autoFocus
                        fill
                        value={newEmailAddress}
                        onChange={e => setNewEmailAddress(e.target.value)}
                        onKeyboardSubmit={handleChangeUserEmailAddress}
                    />
                </FormGroup>
                {orgCount > 1 ? (
                    <p className={styles.caption}>
                        Your email address will be changed both for{" "}
                        <strong>{currentUser.org.display_name}</strong> and for the{" "}
                        {otherOrgCount.toLocaleString()} other{" "}
                        {otherOrgCount === 1 ? "organization" : "organizations"} you're a member of.
                    </p>
                ) : null}
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton
                        disabled={submission.isInFlight}
                        content="Cancel"
                        onClick={dialog.close}
                        instrumentation={null}
                    />
                    <BorderButton
                        disabled={
                            newEmailAddress.trim() === currentUser.identity.email_address ||
                            !isInviteeEmailAddressValid
                        }
                        loading={submission.isInFlight}
                        content="Send verification email"
                        instrumentation={{
                            elementName: "change_email_address_dialog.send_verification_email_btn",
                        }}
                        onClick={handleChangeUserEmailAddress}
                        primary
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
