import { useCallback } from "react";

import { BillingSchema, CommonEnums } from "c9r-common";

export const useBillingFormatters = () => {
    const formatPrice = useCallback(({ price }: { price: BillingSchema.Price }) => {
        const pricePerUser =
            {
                usd: `$${price.unit_amount / 100}`,
            }[price.currency] || `${price.unit_amount} ${price.currency}`;

        const intervalAbbr = {
            [CommonEnums.SubscriptionBillingInterval.MONTH]: "mo",
            [CommonEnums.SubscriptionBillingInterval.YEAR]: "yr",
        }[price.billing_interval];

        return `${pricePerUser}/user/${intervalAbbr}`;
    }, []);

    return { formatPrice };
};
