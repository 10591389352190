import { useCallback } from "react";

import { CommonEnumValue } from "c9r-common";

import { Enums } from "lib/Enums";
import { tryUpdateCache } from "lib/apollo/tryUpdateCache";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useChangeUserEnabled() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ChangeUserEnabled($userId: Int!, $isEnabled: Boolean!) {
                change_user_enabled(user_id: $userId, is_enabled: $isEnabled) {
                    ok
                    error
                }
            }
        `)
    );

    const changeUserEnabled = useCallback(
        async (
            { userId, isEnabled }: { userId: number; isEnabled: boolean },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { userId, isEnabled },
                optimisticResponse: {
                    change_user_enabled: {
                        ok: true,
                        error: null,
                    },
                },
                update: tryUpdateCache((cache, result) => {
                    if (!result.data?.change_user_enabled.ok) {
                        return;
                    }

                    const fragment = gql(/* GraphQL */ `
                        fragment ChangeUserEnabledCacheUpdate on users {
                            id
                            disabled_at
                        }
                    `);

                    const cachedUser = cache.readFragment({
                        id: `users:${userId}`,
                        fragment,
                    });

                    if (!cachedUser) {
                        return;
                    }

                    cache.writeFragment({
                        id: `users:${userId}`,
                        fragment,
                        data: {
                            ...cachedUser,
                            disabled_at: isEnabled ? null : new Date().toISOString(),
                        },
                    });
                }),
            });
        },
        [mutation]
    );

    return { changeUserEnabled };
}

export function useChangeUserRole() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ChangeUserRole($userId: Int!, $role: String!) {
                change_user_role(user_id: $userId, role: $role) {
                    ok
                    error
                }
            }
        `)
    );

    const changeUserRole = useCallback(
        async (
            { userId, role }: { userId: number; role: CommonEnumValue<"UserRole"> },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { userId, role },
                optimisticResponse: {
                    change_user_role: {
                        ok: true,
                        error: null,
                    },
                },
                update: tryUpdateCache((cache, result) => {
                    if (!result.data?.change_user_role.ok) {
                        return;
                    }

                    const fragment = gql(/* GraphQL */ `
                        fragment ChangeUserRoleCacheUpdate on users {
                            id
                            role
                        }
                    `);

                    const cachedUser = cache.readFragment({
                        id: `users:${userId}`,
                        fragment,
                    });

                    if (!cachedUser) {
                        return;
                    }

                    cache.writeFragment({
                        id: `users:${userId}`,
                        fragment,
                        data: {
                            ...cachedUser,
                            role,
                        },
                    });
                }),
            });
        },
        [mutation]
    );

    return { changeUserRole };
}

export function useDisposeUser() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation DisposeUser(
                $userId: Int!
                $dispositionType: String!
                $dispositionParams: jsonb!
            ) {
                dispose_user(
                    user_id: $userId
                    disposition_type: $dispositionType
                    disposition_params: $dispositionParams
                ) {
                    ok
                    error
                }
            }
        `)
    );

    const disposeUser = useCallback(
        async (
            {
                userId,
                dispositionType,
                dispositionParams,
            }: {
                userId: number;
                dispositionType: CommonEnumValue<"UserDispositionType">;
                dispositionParams: unknown;
            },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    userId,
                    dispositionType,
                    dispositionParams,
                },
            });
        },
        [mutation]
    );

    return { disposeUser };
}

export function useInviteUser() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation InviteUser($emailAddress: String!) {
                invite_user(email_address: $emailAddress) {
                    ok
                    error
                }
            }
        `)
    );

    const inviteUser = useCallback(
        async (
            { emailAddress }: { emailAddress: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { emailAddress },
            });
        },
        [mutation]
    );

    return { inviteUser };
}

export function useInviteGuestUser() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation InviteGuestUser($emailAddress: String!) {
                invite_user(email_address: $emailAddress, role: "user_org_guest") {
                    ok
                    error
                    user_id
                }
            }
        `)
    );

    const inviteGuestUser = useCallback(
        async (
            { emailAddress }: { emailAddress: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { emailAddress },
            });
        },
        [mutation]
    );

    return { inviteGuestUser };
}

export function useReinviteUser() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ReinviteUser($userId: Int!) {
                reinvite_user(user_id: $userId) {
                    ok
                    error
                }
            }
        `)
    );

    const reinviteUser = useCallback(
        async ({ userId }: { userId: number }, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { userId },
            });
        },
        [mutation]
    );

    return { reinviteUser };
}
