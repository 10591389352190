import { CommonEnums, TLabel, sortLabels } from "c9r-common";
import stringify from "fast-json-stable-stringify";
import isEmail from "validator/lib/isEmail";

import { Config } from "Config";
import { Regexes } from "lib/Regexes";
import { Storage } from "lib/Storage";
import { CurrentOrg, CurrentUser } from "lib/types/common/currentUser";
import { isDefined } from "lib/types/guards";

export function canCurrentUserViewUserPage({
    currentUser,
    userIdToView,
}: {
    currentUser: CurrentUser;
    userIdToView: number;
}) {
    return (
        currentUser.role !== CommonEnums.UserRole.USER_ORG_GUEST || userIdToView === currentUser.id
    );
}

/**
 * Helper to group lists of items, possibly empty or falsey, to a flat list of items with
 * exactly one divider between each non-empty group (but not at the beginning or end).
 *
 */
export function divideAndFlattenGroups<TItem, TDivider>({
    itemGroups,
    divider,
}: {
    /** The groups, and the items within each group. Groups may be falsey or empty. */
    itemGroups: (TItem[] | undefined | null)[];

    /**
     * The divider to put between the non-empty groups, or a factory function that returns
     * a divider. Pass a divider directly if the same divider can be reused (e.g., a string)
     * or a factory function otherwise (e.g., a React component).
     */
    divider: TDivider | ((i: number) => TDivider);
}) {
    return itemGroups
        .filter(isDefined)
        .filter(g => g.length)
        .flatMap((g, i) =>
            i > 0 ? [divider instanceof Function ? divider(i) : divider, ...g] : g
        );
}

/**
 * Format a user's email address for display.
 */
export function formatUserEmailAddress({ emailAddress }: { emailAddress: string }) {
    let formattedEmailAddress = emailAddress;

    // In the demo environment, we generate email addresses for the fake demo users.
    // In the DB these email addresses must be globally unique (since the DB is shared
    // across all demos), so they incorporate a timestamp and some random characters.
    // But when we *display* them client-side, we can remove those extra details so
    // they look nicer.
    if (
        Config.reformatDemoFakeEmailAddresses &&
        emailAddress.match(Regexes.DEMO_FAKE_EMAIL_ADDRESS)
    ) {
        const [username, domain] = emailAddress.split("@");
        const [name] = username.split(".");
        formattedEmailAddress = `${name}@${domain}`;
    }

    return formattedEmailAddress;
}

/**
 * Generate a unique string key for a label based on its color and text.
 */
export function generateLabelKey({ label }: { label: TLabel }) {
    return [label.color, label.text].join("|_|_|");
}

/**
 * Return the max ticket ref char count in an array of tickets.
 */
export function getMaxRefCharCount(tickets: { ref: string }[]) {
    return Array.isArray(tickets)
        ? tickets.reduce(
              (currentMaxChar, ticket) =>
                  currentMaxChar >= ticket.ref.length ? currentMaxChar : ticket.ref.length,
              0
          )
        : 0;
}

/**
 * Given an array of labels, return an array of the unique labels that appeared. Order is
 * not preserved.
 */
export function getUniqueLabels({ labels }: { labels: TLabel[] }) {
    return Array.from(new Set(labels.map(label => stringify(label))))
        .map(labelStringified => JSON.parse(labelStringified) as TLabel)
        .sort(sortLabels());
}

export function isIdentityOnboarding(identity: {
    onboarding_completed_at: string | null;
    users: { id: number }[];
}) {
    return !identity.users.length || !identity.onboarding_completed_at;
}

/**
 * Helper to check if element is in viewport.
 * (See https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433.)
 */

export function isElementInViewport({ element }: { element: HTMLElement }) {
    const rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isUserOnboarding(user: {
    onboarding_type: string;
    onboarding_completed_at: string | null;
}) {
    return (
        user.onboarding_type !== CommonEnums.UserOnboardingType.NONE &&
        !user.onboarding_completed_at
    );
}

/**
 * Check whether two dates are effectively simultaneous.
 */
export function isSimultaneous(
    d1: string | number | Date,
    d2: string | number | Date,
    thresholdMs?: number
) {
    return (
        Math.abs(new Date(d1).getTime() - new Date(d2).getTime()) <=
        (thresholdMs || Config.simultaneousThresholdMs)
    );
}

/**
 * Helper to check if a user matches a search query.
 */
export function isUserQueryMatch({
    query,
    name,
    fullName,
    email,
}: {
    query: string;
    name: string;
    fullName?: string | null;
    email?: string;
}) {
    return [name]
        .concat((fullName ?? "").split(" "))
        .concat(email ?? "")
        .filter(Boolean)
        .some(word => word.toLowerCase().startsWith(query.toLowerCase()));
}

export function parseEmailAddresses(text: string) {
    return text
        .split(/[,\n]/gm)
        .filter(Boolean)
        .map(v => v.trim())
        .filter(v => isEmail(v))
        .map(v => v.toLowerCase());
}

export function pickDefaultBoard({ org }: { org: CurrentOrg }) {
    const mostRecentlyViewedBoardId = Storage.All.getItem("mostRecentlyViewedBoardId") as string;
    const activeBoards = org.all_boards
        .filter(board => !board.archived_at)
        .sort((a, b) => (a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1));

    return (
        (mostRecentlyViewedBoardId &&
            org.all_boards.find(b => b.id === mostRecentlyViewedBoardId)) ||
        org.all_boards.find(b =>
            Config.preferredDefaultBoardDisplayNames.includes(b.display_name)
        ) ||
        (activeBoards.length && activeBoards[0]) ||
        null
    );
}

/**
 * Pick a color for a new label.
 */
export function pickLabelColor({ labels }: { labels: { color: string }[] }) {
    const preferredOrder = [
        CommonEnums.LabelColor.BLUE,
        CommonEnums.LabelColor.RED,
        CommonEnums.LabelColor.GREEN,
        CommonEnums.LabelColor.ORANGE,
        CommonEnums.LabelColor.PURPLE,
        CommonEnums.LabelColor.CYAN,
        CommonEnums.LabelColor.PINK,
        CommonEnums.LabelColor.GREY,
        CommonEnums.LabelColor.BLUE_LIGHT,
        CommonEnums.LabelColor.RED_LIGHT,
        CommonEnums.LabelColor.ORANGE_DARK,
        CommonEnums.LabelColor.PURPLE_LIGHT,
        CommonEnums.LabelColor.CYAN_LIGHT,
        CommonEnums.LabelColor.GREEN_DARK,
        CommonEnums.LabelColor.PEACH,
        CommonEnums.LabelColor.GREEN_LIGHT,
        CommonEnums.LabelColor.YELLOW,
        CommonEnums.LabelColor.GREY_LIGHT,
    ];

    const countsByColor = labels.reduce((acc, { color }) => {
        if (acc[color] !== undefined) {
            acc[color] += 1;
        }

        return acc;
    }, Object.fromEntries(preferredOrder.map(color => [color, 0])));
    const minCount = Math.min(...Object.values(countsByColor));

    return preferredOrder.filter(color => countsByColor[color] === minCount)[0];
}

export async function resizeImageBlob({
    blob,
    width,
    height,
    mimeType = "image/png",
    quality = 0.95,
}: {
    blob: Blob;
    width: number;
    height: number;
    mimeType?: string;
    quality?: number;
}) {
    let resolve: (value: Blob | null | PromiseLike<Blob | null>) => void;
    const promise = new Promise<Blob | null>(_resolve => {
        resolve = _resolve;
    });

    const image = new Image();
    const url = window.URL.createObjectURL(blob);

    image.src = url;
    image.onload = () => {
        window.URL.revokeObjectURL(url);

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        canvas.toBlob(b => resolve(b), mimeType, quality);
    };

    return promise;
}

/**
 * Helper to scroll an element into view if needed (i.e. if the element is not in view).
 */

export function scrollElementIntoViewIfNeeded({
    element,
    scrollIntoViewArg,
}: {
    element: HTMLElement;
    scrollIntoViewArg?: boolean | ScrollIntoViewOptions;
}) {
    if (!isElementInViewport({ element })) {
        element.scrollIntoView(scrollIntoViewArg);
    }
}

export function selectAll(element: Element) {
    const range = document.createRange();
    const selection = window.getSelection();

    if (!selection) {
        return;
    }

    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
}

function maybeGetFirstNameAndLastNames({ fullName }: { fullName: string | null }) {
    if (!fullName) {
        return { firstName: null, lastNames: [] };
    }

    const [tentativeFirstName, ...tentativeLastNames] = fullName.trim().split(/\s/);

    return {
        firstName: tentativeFirstName || null,
        lastNames: tentativeLastNames.filter(Boolean),
    };
}

function capitalizeName({ name }: { name: string }) {
    if (name === "") {
        return "";
    }

    return `${name[0].toUpperCase()}${name.substring(1)}`;
}

function isDisplayNameValid({ candidateDisplayName }: { candidateDisplayName: string }) {
    return candidateDisplayName.toLowerCase().match(Regexes.VALID_USERNAME);
}

function isDisplayNameAvailable({
    candidateDisplayName,
    existingDisplayNames,
}: {
    candidateDisplayName: string;
    existingDisplayNames: string[];
}) {
    return !existingDisplayNames.some(
        edn => edn.toLowerCase() === candidateDisplayName.toLowerCase()
    );
}

/**
 * Wrap a string in quotes if it is multiple words. The string is assumed not to already
 * contain quotes.
 */
export function maybeQuote(str: string) {
    if (str && str.split(" ").length > 1) {
        return `"${str}"`;
    }

    return str;
}

/**
 * Helper to try to suggest a display name for a user who is onboarding. Bails out if it
 * can't generated one -- in that case the user can just enter one themselves.
 */
export function maybeSuggestDisplayName({
    fullName,
    existingDisplayNames,
}: {
    fullName: string | null;
    existingDisplayNames: string[];
}) {
    let candidateDisplayName: string;

    const { firstName, lastNames } = maybeGetFirstNameAndLastNames({ fullName });

    // all candidate display names require a first name
    if (!firstName) {
        return null;
    }

    // try first name
    candidateDisplayName = capitalizeName({ name: firstName });

    if (
        isDisplayNameValid({ candidateDisplayName }) &&
        isDisplayNameAvailable({ candidateDisplayName, existingDisplayNames })
    ) {
        return candidateDisplayName;
    }

    if (lastNames.length) {
        // try first initial plus last name
        candidateDisplayName = `${firstName[0].toUpperCase()}${lastNames
            .map(ln => capitalizeName({ name: ln }))
            .join("")}`;

        if (isDisplayNameValid({ candidateDisplayName })) {
            if (isDisplayNameAvailable({ candidateDisplayName, existingDisplayNames })) {
                return candidateDisplayName;
            }

            // try first initial plus last name plus number
            const candidateDisplayNameBase = candidateDisplayName;

            for (let i = 2; i < 10; i += 1) {
                candidateDisplayName = `${candidateDisplayNameBase}${i}`;

                if (isDisplayNameAvailable({ candidateDisplayName, existingDisplayNames })) {
                    return candidateDisplayName;
                }
            }
        }
    }

    // try first name plus number
    const candidateDisplayNameBase = capitalizeName({ name: firstName });

    if (!isDisplayNameValid({ candidateDisplayName: candidateDisplayNameBase })) {
        return null;
    }

    for (let i = 2; i < 10; i += 1) {
        candidateDisplayName = `${candidateDisplayNameBase}${i}`;

        if (isDisplayNameAvailable({ candidateDisplayName, existingDisplayNames })) {
            return candidateDisplayName;
        }
    }

    return null;
}

export function visitObject(obj: any, visitorFn: (key: string | null, val: any) => boolean) {
    visitorFn(null, obj);

    for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            if (visitorFn(key, obj[key])) {
                visitObject(obj[key], visitorFn);
            }
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
            visitorFn(key, obj[key]);
        }
    }
}
