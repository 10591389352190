import React, { createContext, useContext, useEffect, useRef, useState } from "react";

import { IdToken } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import jsonwebtoken from "jsonwebtoken";
import LogRocket from "logrocket";

import { useMaybeCurrentUser, useSetCurrentOrg, useSetCurrentUser } from "AppState";
import { Config } from "Config";
import VersioningInfo from "VersioningInfo";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { useTrackingUid } from "lib/Tracking";
import { useLiveQuery } from "lib/apollo/useLiveQuery";
import { getCurrentAccessToken } from "lib/auth/AccessToken";
import { useAuth0 } from "lib/auth/Auth0";
import { useRefreshAuth0AccessToken } from "lib/auth/Auth0AccessTokenManager";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { UserContext_userFragment } from "lib/graphql/__generated__/graphql";
import { useRecordLogin } from "lib/mutations/user/recordLogin";
import { CurrentMinimalUser, CurrentUserLegacy } from "lib/types/common/currentUser";

export const UserContext = createContext<CurrentMinimalUser | undefined>(undefined);

// Because UserContext is used throughout the app, this fragment should include only
// fields that change infrequently. If a field that changed frequently was included
// it would trigger lots of spurious re-renders throughout the app.
const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserContext_user on users {
            id
            app_config
            app_state
            appearance_preferences
            demographic_preferences
            full_name
            github_login
            last_viewed_work_at
            name
            onboarding_completed_at
            onboarding_type
            org_id
            pending_invite_sent_at
            role
            settings
            slack_user_id
            slack_dm_channel_id

            hotspot_interactions {
                id
                hotspot_key
                views
                last_viewed_at
                acks
                last_acked_at
            }

            identity {
                id
                auth0_id
                email_address
                is_email_address_verified
            }

            org {
                id
                app_config
                demographic_info
                disabled_at
                display_name
                is_domain_signup_enabled
                is_internal
                is_multi_board
                is_multi_user
                next_ticket_ref
                provisioned_by_identity_id
                settings
                slug

                all_boards: boards {
                    id
                    archived_at
                    display_name
                    slug

                    all_stages: stages {
                        id
                        board_id
                        board_pos
                        deleted_at
                        display_name

                        ...CardMenu_stage
                    }

                    attached_users {
                        user {
                            id
                        }
                    }

                    authorized_users {
                        user {
                            id
                        }
                    }
                }

                domains {
                    id
                    domain
                }

                users(where: { disabled_at: { _is_null: true } }) {
                    id
                    avatar_url
                    disabled_at
                    full_name
                    github_login
                    is_pending_disposition
                    name
                    slug

                    identity {
                        id
                        email_address
                    }

                    ...Avatar_user
                    ...EditBoardDialog_user
                    ...UserSelect_user
                    ...UserSelector_user
                    ...UserView_user
                }

                all_users: users {
                    id
                    avatar_url
                    disabled_at
                    full_name
                    github_login
                    is_pending_disposition
                    name
                    slug

                    identity {
                        id
                        email_address
                    }

                    ...Avatar_user
                    ...UserSelect_user
                    ...UserSelector_user
                    ...UserView_user
                }

                ...BoardAndStagesMenuContextProvider_org
            }

            ...Avatar_user
            ...EditBoardDialog_user
        }
    `),
};

const queries = {
    component: gql(/* GraphQL */ `
        query UserContext($userId: Int!) {
            users(where: { id: { _eq: $userId }, disabled_at: { _is_null: true } }) {
                ...UserContext_user
            }
        }
    `),
};

const useRefreshAuth0AccessTokenOnRoleChange = ({
    user,
}: {
    user?: UserContext_userFragment | null;
}) => {
    const { refreshAccessToken } = useRefreshAuth0AccessToken();
    const lastRole = useRef<string>();

    useEffect(() => {
        (async () => {
            if (!user) {
                return;
            }

            if (lastRole.current && lastRole.current !== user.role) {
                Log.info("Refreshing access token for new role", {
                    userId: user.id,
                    lastRole,
                    role: user.role,
                });

                Log.info("Access token info immediately before refresh due to role change", {
                    accessTokenInfo: jsonwebtoken.decode(getCurrentAccessToken()!),
                });

                await refreshAccessToken({ ignoreCache: true });

                Log.info("Access token info immediately after refresh due to role change", {
                    accessTokenInfo: jsonwebtoken.decode(getCurrentAccessToken()!),
                });
            }

            lastRole.current = user.role;
        })();
    }, [refreshAccessToken, user]);
};

export function Auth0UserProvider({
    children,
    userId,
}: {
    children: React.ReactNode;
    userId: number;
}) {
    const { getIdTokenClaims } = useAuth0();
    const [claims, setClaims] = useState<IdToken | null>(null);

    const componentQuery = useLiveQuery({
        query: queries.component,
        variables: { userId },
    });

    const user = getFragmentData(fragments.user, componentQuery.data?.users[0]);

    useRefreshAuth0AccessTokenOnRoleChange({ user });

    useEffect(() => {
        (async () => {
            setClaims((await getIdTokenClaims()) ?? null);
        })();
    }, [getIdTokenClaims]);

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error) {
        // eslint-disable-next-line no-console
        console.log(componentQuery.error);
        return null;
    }

    if (!user || !claims) {
        return null;
    }

    const currentMinimalUser: CurrentMinimalUser = {
        ...user,
        ...(claims["https://constructor.dev/id/claims/patches"] || {}),
    };

    return <UserProvider user={currentMinimalUser}>{children}</UserProvider>;
}

export function DemoUserProvider({
    userId,
    children,
}: {
    userId: number;
    children: React.ReactNode;
}) {
    const componentQuery = useLiveQuery({
        query: queries.component,
        variables: { userId },
    });

    const user = getFragmentData(fragments.user, componentQuery.data?.users[0]);

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error) {
        // eslint-disable-next-line no-console
        console.log(componentQuery.error);
        return null;
    }

    if (!user) {
        return null;
    }

    const currentMinimalUser: CurrentMinimalUser = { ...user, isReadOnly: false };

    return <UserProvider user={currentMinimalUser}>{children}</UserProvider>;
}

type UserProviderProps = {
    children?: React.ReactNode;
    user: CurrentMinimalUser;
};

function UserProvider({ children, user }: UserProviderProps) {
    const currentUser = useMaybeCurrentUser();
    const setCurrentOrg = useSetCurrentOrg();
    const setCurrentUser = useSetCurrentUser();

    const { getTrackingUid } = useTrackingUid();
    const { recordLogin } = useRecordLogin();
    const trackingUid = getTrackingUid();

    useEffect(() => {
        setCurrentOrg(isFullCurrentUser(user) ? user.org ?? null : null);
    }, [user, setCurrentOrg]);

    useEffect(() => {
        setCurrentUser(user ?? null);
    }, [user, setCurrentUser]);

    useEffect(() => {
        if (!user.isReadOnly) {
            void recordLogin({
                currentUserId: user.id,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
                uids: trackingUid ? { [trackingUid]: true } : {},
            });
        }
    }, [trackingUid, recordLogin, user.id, user.isReadOnly]);

    useEffect(() => {
        if (!user.isReadOnly) {
            const isDesktopApp = !!window.electron;
            const isInternal = !!user.org.is_internal;

            Log.addContext("userId", user.id);
            Log.addContext("orgId", user.org.id);
            Log.addContext("orgDisplayName", user.org.display_name);
            Log.addContext("clientVersion", VersioningInfo.current);
            Log.addContext("trackingUid", trackingUid);
            Log.addContext("isDesktopApp", isDesktopApp);
            Log.addContext("isInternal", isInternal);

            Sentry.setUser({
                id: String(user.id),
                name: user.name,
                org_id: user.org.id,
                org_display_name: user.org.display_name,
                client_version: VersioningInfo.current,
                tracking_uid: trackingUid,
            });

            window.electron?.setUserInfo?.({
                userId: user.id,
                userName: user.name,
                orgId: user.org.id,
                orgDisplayName: user.org.display_name,
            });

            if (Config.logrocket.enabled) {
                LogRocket.identify(String(user.id), {
                    name: user.name,
                    orgId: user.org.id,
                    ...(user.org.display_name && { orgDisplayName: user.org.display_name }),
                    clientVersion: VersioningInfo.current,
                    ...(trackingUid && { trackingUid }),
                });

                LogRocket.track("OrgUserSessionStarted", {
                    isDesktopApp,
                    isInternal,
                    orgId: user.org.id,
                    userId: user.id,
                });
            }
        }
    }, [
        trackingUid,
        user.id,
        user.name,
        user.org.display_name,
        user.org.id,
        user.org.is_internal,
        user.isReadOnly,
    ]);

    if (!currentUser) {
        return null;
    }

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export const useCurrentMinimalUser = () => {
    const currentMinimalUser = useContext(UserContext);

    if (!currentMinimalUser) {
        throw new Error("No UserContext.Provider found when using UserContext");
    }

    return currentMinimalUser;
};

export const isFullCurrentUser = (
    currentMinimalUser: CurrentMinimalUser
): currentMinimalUser is CurrentUserLegacy => {
    return !!(typeof currentMinimalUser.org_id === "number" && currentMinimalUser.org);
};

export const useCurrentUser = () => {
    const currentMinimalUser = useCurrentMinimalUser();

    if (isFullCurrentUser(currentMinimalUser)) {
        return currentMinimalUser;
    }

    throw new Error("Cannot call useCurrentUser for a user without an org");
};

export const useShouldShowTicketRefs = () => {
    const currentUser = useCurrentUser();

    return !!currentUser.settings.SHOW_TICKET_REFS?.enabled;
};

Queries.register({ component: "UserContext", gqlMapByName: queries });
