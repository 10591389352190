import React, { useCallback, useEffect, useRef, useState } from "react";

import { CommonEnumValue, CommonEnums, sortStages, sortTickets } from "c9r-common";
import { useRecoilState } from "recoil";

import { currentBoardIdState } from "AppState";
import { TQueryResult, ViewQueryLoader } from "components/loading/QueryLoader";
import { AppToaster } from "components/ui/core/AppToaster";
import { useMutations } from "contexts/MutationsContext";
import {
    TicketSelectionContextProvider,
    useTicketSelectionContext,
} from "contexts/TicketSelectionContext";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { NewTicketEntryId } from "lib/Constants";
import {
    generatePositionsBetween,
    moveToFirstPosition,
    moveToLastPosition,
    moveToPositionByIndex,
} from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { useArchiveManyTicketsUX } from "lib/MutationUX";
import { Queries } from "lib/Queries";
import { useRouteParams } from "lib/Routing";
import { Storage } from "lib/Storage";
import { useRedirectToFullPathname, useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { BoardPageQuery, BoardPage_boardFragment } from "lib/graphql/__generated__/graphql";
import { CurrentOrg } from "lib/types/common/currentUser";
import { isDefined } from "lib/types/guards";
import { BoardNotFoundView } from "views/error/NotFoundView";

import { Board } from "./Board";
import { BoardViewFilterProvider, useBoardViewFilter } from "./BoardFilterContext";
import { BoardViewProvider } from "./BoardViewContext";
import { useNewTicketEntry, useNewTicketEntryPlacement } from "./NewTicketEntry";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment BoardPage_board on boards {
            id
            display_name
            slug

            stages(where: { deleted_at: { _is_null: true } }) {
                id
                board_id
                board_pos
                deleted_at
                min_ticket_stage_pos
                max_ticket_stage_pos
            }

            tickets(where: { archived_at: { _is_null: true }, trashed_at: { _is_null: true } }) {
                id
                archived_at
                ref
                stage_id
                stage_pos
                trashed_at

                ...BoardViewProvider_ticket
                ...TicketFilter_ticket
            }

            ...BoardViewProvider_board
        }
    `),
};

type TBoard = BoardPage_boardFragment;
type TStage = TBoard["stages"][number];
type TTicket = TBoard["tickets"][number];

const findBoardBySlug = ({ org, boardSlug }: { org: CurrentOrg; boardSlug: string }) => {
    return org.all_boards.find(b => b.slug === boardSlug);
};

type BoardWrapperProps = {
    board: BoardPage_boardFragment;
};

function BoardWrapper({ board }: BoardWrapperProps) {
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const { isTicketMatchingFilterExpression } = useBoardViewFilter();
    const { recordEvent } = useInstrumentation();
    const { archiveManyTicketsUX } = useArchiveManyTicketsUX();

    const {
        archiveTicket,
        moveTicketToStagePosition,
        moveTicketsToStagePositions,
        trashTicket,
        unarchiveTicket,
        untrashTicket,
    } = useMutations();

    const { selection } = useTicketSelectionContext();
    const stagesById = useRef<Record<string, TStage>>({});
    const ticketsById = useRef<Record<string, TTicket>>({});
    const newTicketEntry = useNewTicketEntry();
    const newTicketEntryPlacement = useNewTicketEntryPlacement();
    const clearNewTicketEntry = newTicketEntry.clear;
    const [tickets, setTickets] = useState(board.tickets);

    useEffect(() => {
        setTickets(board.tickets);
    }, [board.tickets]);

    useEffect(() => {
        return () => clearNewTicketEntry();
    }, [clearNewTicketEntry]);

    for (const stage of board.stages) {
        stagesById.current[stage.id] = stage;
    }

    for (const ticket of tickets) {
        ticketsById.current[ticket.id] = ticket;
    }

    const doMoveCard = useCallback(
        async ({
            ticket,
            toStageId,
            toStagePos,
            isArchive = false,
            isTrash = false,
        }: {
            ticket: TTicket;
            toStageId?: string;
            toStagePos?: number;
            isArchive?: boolean;
            isTrash?: boolean;
        }) => {
            const fromStagePos = ticket.stage_pos!;

            if (isArchive) {
                await archiveTicket({ ticketId: ticket.id });

                AppToaster.success({
                    icon: null,
                    message: shouldShowTicketRefs
                        ? `Topic #${ticket.ref} archived.`
                        : "Topic archived.",
                    action: {
                        onClick: async () => {
                            await unarchiveTicket({ ticketId: ticket.id, stagePos: fromStagePos });
                        },
                        icon: "undo",
                        text: "Undo",
                    },
                });
            } else if (isTrash) {
                await trashTicket({ ticketId: ticket.id });

                AppToaster.success({
                    icon: null,
                    message: shouldShowTicketRefs
                        ? `Topic #${ticket.ref} moved to trash.`
                        : "Topic moved to trash.",
                    action: {
                        onClick: async () => {
                            await untrashTicket({ ticketId: ticket.id, stagePos: fromStagePos });
                        },
                        icon: "undo",
                        text: "Undo",
                    },
                });
            } else if (toStageId && toStagePos) {
                const toStage = stagesById.current[toStageId];
                const toBoardId = toStage.board_id;

                await moveTicketToStagePosition({
                    ticketId: ticket.id,
                    toBoardId,
                    toStageId,
                    toStagePos,
                });
            }
        },
        [
            archiveTicket,
            shouldShowTicketRefs,
            unarchiveTicket,
            trashTicket,
            untrashTicket,
            moveTicketToStagePosition,
        ]
    );

    const doMoveCards = useCallback(
        async ({
            ticketIds,
            toStageId,
            toStagePoses,
        }: {
            ticketIds: string[];
            toStageId: string;
            toStagePoses: number[];
        }) => {
            const toStage = stagesById.current[toStageId];
            const toBoardId = toStage.board_id;

            await moveTicketsToStagePositions({
                ticketIds,
                toStageId,
                toStagePoses,
                toBoardId,
            });
        },
        [moveTicketsToStagePositions]
    );

    const handleArchiveStageTickets = useCallback(
        async ({ stageId }: { stageId: string }) => {
            await archiveManyTicketsUX({
                tickets: Object.values(ticketsById.current)
                    .filter(t => t.stage_id === stageId && !t.archived_at && !t.trashed_at)
                    .filter(t => isTicketMatchingFilterExpression({ ticket: t })),
            });
        },
        [archiveManyTicketsUX, isTicketMatchingFilterExpression]
    );

    /**
     * Capture and persist details at the end of a card drag.
     */
    const handleDragCard = useCallback(
        async ({
            ticketId,
            toStageId,
            toIndexDisplayed,
        }: {
            ticketId: string;
            toStageId: string;

            /**
             * Displayed index that the ticket is being moved to (note, this might differ
             * from the tickets' actual indices due to the board filter). Pass "0" to move
             * the ticket to the first position and "Number.POSITIVE_INFINITY" to move to
             * the last position.
             */
            toIndexDisplayed: number;
        }) => {
            const ticket = ticketsById.current[ticketId];
            const toStage = stagesById.current[toStageId];
            const isMultiselectDrag =
                selection.mode === Enums.MultiselectMode.ACTIVE &&
                selection.ticketIds.has(ticket.id);

            const ticketsInStage = ([] as (
                | TTicket
                | { id: typeof NewTicketEntryId; stage_pos: number }
            )[])
                .concat(
                    Object.values(ticketsById.current).filter(
                        t => t.stage_id === toStageId && !t.archived_at && !t.trashed_at
                    )
                )
                .concat(
                    [
                        newTicketEntryPlacement.isPlaced &&
                        newTicketEntryPlacement.stageId === toStageId
                            ? ({
                                  stage_pos: newTicketEntryPlacement.stagePos,
                                  id: NewTicketEntryId,
                              } as const)
                            : null,
                    ].filter(isDefined)
                )
                .sort(sortTickets());
            const displayedTicketsInStage = ticketsInStage.filter(
                t =>
                    // Check if it's the NewTicketCard first, because `isTicketMatchingFilterExpression`
                    // requires the object to match the shape defined in its fragment.
                    String(t.id) === NewTicketEntryId ||
                    isTicketMatchingFilterExpression({ ticket: t as TTicket })
            );

            // If the new ticket card was dragged, just update its position.
            if (String(ticketId) === NewTicketEntryId) {
                newTicketEntry.moveTo({
                    boardId: toStage.board_id,
                    stageId: toStage.id,
                    stagePos: moveToPositionByIndex({
                        sortedEntities: displayedTicketsInStage,
                        posFieldName: "stage_pos",
                        toIndex: toIndexDisplayed,
                        entityId: ticketId,
                    }),
                });

                return;
            }

            void recordEvent({
                eventType: Enums.InstrumentationEvent.DRAG,
                elementName: "card",
                eventData: { ticketId: ticket.id, toStageId, isMultiselectDrag },
            });

            if (isMultiselectDrag) {
                // This feels a bit convoluted, and perhaps there's a more elegant way to express
                // it. react-beautiful-dnd gives us the index position where the card was dropped.
                // We need to convert that into position boundaries before and after. We need
                // to account for the possibility that there are selected tickets in the same
                // stage as the ticket being dragged, and the ticket being dragged may be moving
                // above, between, or below the other ones.
                const ticketDisplayedIndex = displayedTicketsInStage.findIndex(
                    t => t.id === ticket.id
                );
                const previousDisplayedUnselectedTicket = displayedTicketsInStage
                    .filter(
                        (t, i) =>
                            i <
                                toIndexDisplayed +
                                    (toStageId === ticket.stage_id &&
                                    toIndexDisplayed > ticketDisplayedIndex
                                        ? 1
                                        : 0) && !selection.ticketIds.has(t.id)
                    )
                    .pop();
                const nextDisplayedUnselectedTicket = displayedTicketsInStage
                    .filter(
                        (t, i) =>
                            i >=
                                toIndexDisplayed +
                                    (toStageId === ticket.stage_id &&
                                    toIndexDisplayed > ticketDisplayedIndex
                                        ? 1
                                        : 0) && !selection.ticketIds.has(t.id)
                    )
                    .shift();

                const fromPos = previousDisplayedUnselectedTicket?.stage_pos ?? null;
                const toPos = nextDisplayedUnselectedTicket?.stage_pos ?? null;
                const toStagePoses = generatePositionsBetween({
                    fromPos,
                    toPos,
                    count: selection.ticketIds.size,
                });

                const ticketIdsToMove = [
                    // Put the card that was actually dragged first, so that it appears
                    // in the dropped position, and all the additional cards appear
                    // below it.
                    ticket.id,
                    ...Array.from(selection.ticketIds).filter(id => id !== ticket.id),
                ];

                // As of June 2023, react-beautiful-dnd expects that when a drag ends, the app state
                // is updated *synchronously* so that the dropped item appears immediately in the dropped
                // position. If the state is updated asynchronously, the dropped item will temporarily
                // appear back in its old position until the state update is processed.
                //
                // Replicache processes state updates asynchronously. It's usually within the same frame,
                // but not always.
                //
                // Thus, we synchronously update the state ourselves to ensure the drop looks seamless.
                setTickets(prev => {
                    const prevTicketsById = Object.fromEntries(prev.map(t => [t.id, t]));
                    const ticketsToMove = ticketIdsToMove.map(
                        ticketIdToMove => prevTicketsById[ticketIdToMove]
                    );

                    return [
                        ...prev.filter(t => !ticketIdsToMove.includes(t.id)),
                        ...ticketsToMove.map((ticketToMove, i) => ({
                            ...ticketToMove,
                            stage_id: toStageId,
                            stage_pos: toStagePoses[i],
                        })),
                    ];
                });

                await doMoveCards({ ticketIds: ticketIdsToMove, toStageId, toStagePoses });
            } else {
                // The index in the _actual_ list for the drop is just after the index in the actual
                // list of the ticket that is _displayed_ above the drop index.
                // For example, if there are 10 actual tickets, but only 3 are displayed, and the drop
                // is after the first displayed ticket, then if that first *displayed* ticket is index
                // 6 in the full list, the actual drop index is 7.
                const toIndex =
                    toIndexDisplayed === 0
                        ? 0
                        : toIndexDisplayed === Number.POSITIVE_INFINITY
                        ? ticketsInStage.length
                        : ticketsInStage.findIndex(
                              t => t === displayedTicketsInStage[toIndexDisplayed - 1]
                          ) + 1;

                const toStagePos = moveToPositionByIndex({
                    sortedEntities: ticketsInStage,
                    posFieldName: "stage_pos",
                    toIndex,
                    entityId: ticketId,
                });

                // As of June 2023, react-beautiful-dnd expects that when a drag ends, the app state
                // is updated *synchronously* so that the dropped item appears immediately in the dropped
                // position. If the state is updated asynchronously, the dropped item will temporarily
                // appear back in its old position until the state update is processed.
                //
                // Replicache processes state updates asynchronously. It's usually within the same frame,
                // but not always.
                //
                // Thus, we synchronously update the state ourselves to ensure the drop looks seamless.
                setTickets(prev => [
                    ...prev.filter(t => t.id !== ticket.id),
                    {
                        ...ticket,
                        stage_id: toStageId,
                        stage_pos: toStagePos,
                    },
                ]);

                await doMoveCard({ ticket, toStageId, toStagePos });
            }
        },
        [
            doMoveCard,
            doMoveCards,
            isTicketMatchingFilterExpression,
            newTicketEntry,
            newTicketEntryPlacement.isPlaced,
            newTicketEntryPlacement.stageId,
            newTicketEntryPlacement.stagePos,
            recordEvent,
            selection,
        ]
    );

    const handleMoveCard = useCallback(
        /**
         * Move a ticket within/between stages using standard movements.
         */
        async ({
            ticketId,
            toStageDirection,
            withinStageDirection,
        }:
            | {
                  ticketId: string;

                  /** If moving ticket between stages, whether to move it to the next stage (FORWARD) or the previous stage (BACK). */
                  toStageDirection: CommonEnumValue<"Direction">;
                  withinStageDirection?: undefined;
              }
            | {
                  ticketId: string;
                  toStageDirection?: undefined;

                  /** When the ticket is moved, should it be at the top of the destination stage (BACK) or the bottom (FORWARD). */
                  withinStageDirection: CommonEnumValue<"Direction">;
              }) => {
            const ticket = ticketsById.current[ticketId];

            const displayedStages = Object.values(stagesById.current)
                .filter(s => s.board_id === board.id)
                .filter(s => !s.deleted_at)
                .sort(sortStages());

            const toStage = toStageDirection
                ? displayedStages[
                      displayedStages.findIndex(stage => stage.id === ticket.stage_id) +
                          (toStageDirection === CommonEnums.Direction.FORWARD ? 1 : -1)
                  ]
                : stagesById.current[ticket.stage_id];
            const toStageId = toStage.id;

            // By default:
            // - when moving to a *later* stage, ticket moves to the *last* position in the stage.
            // - when moving to an *earlier* stage, ticket moves to the *first* position in the stage.
            const shouldMoveTicketToLastPosition =
                (withinStageDirection ||
                    (toStageDirection === CommonEnums.Direction.FORWARD
                        ? CommonEnums.Direction.FORWARD
                        : CommonEnums.Direction.BACK)) === CommonEnums.Direction.FORWARD;

            // Note: We don't use the stages's min_ticket_stage_pos and max_ticket_stage_pos here,
            //       since we already have all of the tickets (as seen by the user). If we did use
            //       those fields, we'd have to update them in the cache any time we move a ticket.
            //       With this approach, we don't have to.
            const allPositions = Object.values(ticketsById.current)
                .filter(t => t.stage_id === toStage.id)
                .map(t => t.stage_pos)
                .filter(isDefined);
            const toStagePos = shouldMoveTicketToLastPosition
                ? moveToLastPosition({
                      maxPos: allPositions.length ? Math.max(...allPositions) : null,
                  })
                : moveToFirstPosition({
                      minPos: allPositions.length ? Math.min(...allPositions) : null,
                  });

            await doMoveCard({ ticket, toStageId, toStagePos });
        },
        [doMoveCard, board.id]
    );

    const handleArchiveCard = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            const ticket = ticketsById.current[ticketId];

            await doMoveCard({ ticket, isArchive: true });
        },
        [doMoveCard]
    );

    const handleTrashCard = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            const ticket = ticketsById.current[ticketId];

            await doMoveCard({ ticket, isTrash: true });
        },
        [doMoveCard]
    );

    return (
        <BoardViewProvider
            board={board}
            tickets={tickets.filter(ticket => isTicketMatchingFilterExpression({ ticket }))}
            onDragCard={handleDragCard}
            onMoveCard={handleMoveCard}
            onArchiveCard={handleArchiveCard}
            onTrashCard={handleTrashCard}
            onArchiveStageTickets={handleArchiveStageTickets}
        >
            <Board />
        </BoardViewProvider>
    );
}

type BoardViewProps = {
    boardId: string;
};

function BoardViewImpl({ queryResult }: { queryResult: TQueryResult<BoardPageQuery> }) {
    const board = getFragmentData(fragments.board, queryResult.data?.board);
    const { buildBoardUrl } = useUrlBuilders();

    useRedirectToFullPathname({
        fullPathname: board
            ? buildBoardUrl({
                  boardSlug: board.slug,
                  vanity: {
                      boardDisplayName: board.display_name,
                  },
              }).pathname
            : null,
    });

    if (queryResult.loading && !queryResult.data) {
        return null;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    if (!board) {
        return <BoardNotFoundView />;
    }

    return (
        <BoardViewFilterProvider boardId={board.id}>
            <TicketSelectionContextProvider boardId={board.id}>
                <BoardWrapper board={board} />
            </TicketSelectionContextProvider>
        </BoardViewFilterProvider>
    );
}

export function BoardView(props: BoardViewProps) {
    return (
        <ViewQueryLoader query={BoardPage.queries.component} variables={{ boardId: props.boardId }}>
            {({ queryResult }) => <BoardViewImpl queryResult={queryResult} />}
        </ViewQueryLoader>
    );
}

export function BoardPage() {
    const currentUser = useCurrentUser();
    const { boardSlug } = useRouteParams<{ boardSlug?: string }>();
    const [currentBoardId, setCurrentBoardId] = useRecoilState(currentBoardIdState);

    useEffect(() => {
        const board = boardSlug ? findBoardBySlug({ org: currentUser.org, boardSlug }) : null;

        if (board) {
            setCurrentBoardId(board.id);
            Storage.All.setItem("mostRecentlyViewedBoardId", board.id);
        }
    }, [boardSlug, currentUser.org, setCurrentBoardId]);

    const board = currentUser.org.all_boards.find(b => b.id === currentBoardId);

    if (boardSlug && !board && !findBoardBySlug({ org: currentUser.org, boardSlug })) {
        return <BoardNotFoundView />;
    }

    if (!currentBoardId) {
        return null;
    }

    if (!board) {
        return null;
    }

    return <BoardView key={board.id} boardId={board.id} />;
}

BoardPage.queries = {
    component: gql(/* GraphQL */ `
        query BoardPage($boardId: uuid!) {
            board: boards_by_pk(id: $boardId) {
                id

                ...BoardPage_board
            }
        }
    `),
};

Queries.register({ component: "BoardPage", gqlMapByName: BoardPage.queries });
