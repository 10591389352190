import { useCallback } from "react";

import { useCurrentUser } from "contexts/UserContext";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useChangeUserEmailAddress() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ChangeUserEmailAddress($emailAddress: String!) {
                change_user_email_address(email_address: $emailAddress) {
                    ok
                    error
                }
            }
        `)
    );

    const changeUserEmailAddress = useCallback(
        async (
            { emailAddress }: { emailAddress: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    emailAddress,
                },
            });
        },
        [mutation]
    );

    return { changeUserEmailAddress };
}

export function useSetOrRemoveUserAvatar() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation SetOrRemoveUserAvatar($userId: Int!, $avatarUrl: String) {
                update_users_by_pk(pk_columns: { id: $userId }, _set: { avatar_url: $avatarUrl }) {
                    id
                    avatar_url
                }
            }
        `)
    );

    const setOrRemoveUserAvatar = useCallback(
        async (
            { avatarUrl }: { avatarUrl: string | null },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    userId: currentUser.id,
                    avatarUrl,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        avatar_url: avatarUrl,
                    },
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { setOrRemoveUserAvatar };
}

export function useUpdateUserFullName() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation UpdateUserFullName($userId: Int!, $fullName: String!) {
                update_users_by_pk(pk_columns: { id: $userId }, _set: { full_name: $fullName }) {
                    id
                    full_name
                }
            }
        `)
    );

    const updateUserFullName = useCallback(
        async ({ fullName }: { fullName: string }, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    userId: currentUser.id,
                    fullName,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        full_name: fullName,
                    },
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { updateUserFullName };
}

export function useUpdateUsername() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation UpdateUsername($userId: Int!, $username: citext!) {
                update_users_by_pk(pk_columns: { id: $userId }, _set: { name: $username }) {
                    id
                    name
                }
            }
        `)
    );

    const updateUsername = useCallback(
        async ({ username }: { username: string }, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    userId: currentUser.id,
                    username,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        name: username,
                    },
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { updateUsername };
}
