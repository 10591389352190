import { useMemo, useRef } from "react";

import {
    DocumentNode,
    OperationVariables,
    QueryHookOptions,
    QueryResult,
    SubscriptionHookOptions,
    TypedDocumentNode,
    useQuery,
} from "@apollo/client";
import { detailedDiff } from "deep-object-diff";
import { OperationDefinitionNode } from "graphql";
import isEqual from "react-fast-compare";

import { Config } from "Config";
import { EnumValue } from "lib/Enums";
import { Log } from "lib/Log";
import { useAutoRestartSubscription } from "lib/apollo/useAutoRestartSubscription";

export interface UseLiveQueryType {
    <TData = any, TVariables = OperationVariables>({
        query,
        variables,
        queryParams,
        subscriptionParams,
    }: {
        query: DocumentNode | TypedDocumentNode<TData, TVariables>;
        variables?: TVariables;
        apiRoleType?: EnumValue<"ApiRoleType">;
        queryParams?: Omit<
            QueryHookOptions<TData, TVariables>,
            "variables" | "fetchPolicy" | "nextFetchPolicy"
        >;
        subscriptionParams?: Omit<
            SubscriptionHookOptions<TData, TVariables>,
            "variables" | "fetchPolicy" | "onSubscriptionData"
        >;
    }): QueryResult<TData, TVariables>;
}

export const useLiveQuery: UseLiveQueryType = ({
    query,
    variables,
    apiRoleType,
    queryParams,
    subscriptionParams,
}) => {
    const subscriptionDocumentCache = useRef(new Map());
    const queryResult = useQuery(query, {
        ...{
            ...queryParams,
            context: {
                ...queryParams?.context,
                apiRoleType,
            },
        },
        variables,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const subscription = useMemo(() => {
        const cachedSubscription = subscriptionDocumentCache.current.get(query);

        if (cachedSubscription) {
            return cachedSubscription;
        }

        const document = JSON.parse(JSON.stringify(query)) as typeof query;
        const queryDefinitions = document.definitions.filter(
            definition =>
                definition.kind === "OperationDefinition" && definition.operation === "query"
        ) as OperationDefinitionNode[];

        if (queryDefinitions.length !== 1) {
            throw new Error("Cannot use useLiveQuery on query with multiple query definitions");
        }

        (queryDefinitions[0] as any).operation = "subscription";

        subscriptionDocumentCache.current.set(query, document);

        return document;
    }, [query]);

    useAutoRestartSubscription(subscription, {
        ...{
            ...subscriptionParams,
            context: {
                ...subscriptionParams?.context,
                apiRoleType,
            },
        },
        variables,

        // It's important that the subscription data does not itself update the cache!
        // Our subscriptions are Hasura live queries which poll the DB. By the time the data
        // comes down, it may already be stale, e.g., if the user has made an additional mutation.
        // So, we simply use the subscription as an indication that something has changed, then
        // refetch the entire query to ensure we get the latest.
        fetchPolicy: "no-cache",
        onSubscriptionData: ({ client, subscriptionData }) => {
            const cachedData = client.readQuery({ query, variables });
            const isCacheStale = !isEqual(subscriptionData.data, cachedData);

            // Optimization to avoid refetches that would be no-ops. Say a user performs a mutation
            // and we do an optimistic cache update, and our optimistic update turns out to be
            // completely correct. In that case, when the subscription fires, incorporating the
            // user's mutation, it will match the cache, and there's no need to refetch the query.
            //
            // There are two benefits:
            //   (1) Avoids wasting network bandwidth for no reason
            //   (2) Avoids fetching a query, and having ApolloClient traverse the result to figure
            //       out what cache updates are needed. It turns out that traversal can be expensive
            //       on large result sets, so it's best to avoid it if possible. By contrast,
            //       reading from the cache and running react-fast-compare isEqual is very cheap.
            if (isCacheStale) {
                Log.debug("Triggering refetch due to stale cache", {
                    queryParams,
                    debug:
                        Config.graphql.logLiveViewQueryDiff && subscriptionData.data && cachedData
                            ? {
                                  diff: detailedDiff(
                                      (subscriptionData.data as unknown) as object,
                                      (cachedData as unknown) as object
                                  ),
                              }
                            : null,
                });
                void queryResult.refetch();
            } else {
                Log.debug("Skipping refetch because cache is up-to-date", { queryParams });
            }
        },
    });

    return queryResult;
};
