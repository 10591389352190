import React, { useCallback, useEffect, useRef } from "react";

import { Classes, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import {
    Draggable,
    DraggableProvidedDraggableProps,
    DraggableStateSnapshot,
    DraggingStyle,
} from "react-beautiful-dnd";

import { AppData } from "AppData";
import { Label } from "components/shared/Label";
import { LabelsPicker } from "components/shared/LabelsPicker";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { TextArea } from "components/ui/core/TextArea";
import { CssClasses, NewTicketEntryId } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useAsyncWatcher, useOutsideClick } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";

import styles from "./NewTicketCard.module.scss";
import { useBoardView } from "../BoardViewContext";
import {
    useNewTicketEntry,
    useNewTicketEntryContent,
    useNewTicketEntryMutations,
} from "../NewTicketEntry";

type FooterActionsProps = {
    canSubmit?: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;
};

function FooterActions({ canSubmit, onCancel, onSubmit }: FooterActionsProps) {
    return (
        <span className={styles.footerActions}>
            <BorderButton
                onClick={onCancel}
                content="Cancel"
                instrumentation={{
                    elementName: "new_ticket_card.cancel_btn",
                }}
                minimal
                small
            />
            <BorderButton
                disabled={!canSubmit}
                onClick={onSubmit}
                brandCta
                content="Create"
                instrumentation={{
                    elementName: "new_ticket_card.submit_btn",
                }}
                minimal
                small
            />
        </span>
    );
}

export type NewTicketCardProps = {
    className?: string;
    boardId: string;
    index: number;
    isDragDisabled?: boolean;
    onCancel?: () => void;
    onCreate?: (params: { another: boolean }) => void;
};

export function NewTicketCard({
    className,
    boardId,
    index,
    isDragDisabled,
    onCancel,
    onCreate,
}: NewTicketCardProps) {
    const { recordEvent } = useInstrumentation();
    const { draggingId } = useBoardView();
    const newTicketEntry = useNewTicketEntry();
    const { title, labels } = useNewTicketEntryContent();
    const { createTicket } = useNewTicketEntryMutations();
    const newTicketCardRef = useRef<HTMLLIElement | null>(null);
    const boundariesElement = document.querySelector("#appRoot");
    const submission = useAsyncWatcher();

    useEffect(() => {
        newTicketCardRef.current?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "nearest",
        });
    }, []);

    const getStyle = (
        style: DraggableProvidedDraggableProps["style"],
        snapshot: DraggableStateSnapshot
    ): React.CSSProperties => {
        // NB: This should match the margin specified in the columnCard class in the Column styles
        // to ensure that the cards do not shift when a card is being dragged.
        const marginBottom = draggingId === NewTicketEntryId ? "12px" : undefined;

        if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
            return { ...style, marginBottom };
        }

        const { duration } = snapshot.dropAnimation;

        return {
            ...style,
            marginBottom,
            width:
                (snapshot.draggingOver && AppData.stageWidthsById[Number(snapshot.draggingOver)]) ||
                (style as DraggingStyle)?.width ||
                undefined,
            transition: `all cubic-bezier(0.2,1.0,0.9,1.0) ${duration / 1.5}s`,
        };
    };

    const handleCancel = useCallback(() => {
        void recordEvent({
            eventType: Enums.InstrumentationEvent.ELEMENT_CANCEL,
            elementName: "new_ticket_card",
            dedupeKey: Date.now(),
        });

        onCancel?.();
    }, [onCancel, recordEvent]);

    const handleSubmit = useCallback(
        async ({ another }: { another: boolean }) => {
            if (!title) {
                return;
            }

            void recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                elementName: "new_ticket_card",
                dedupeKey: Date.now(),
            });

            const newTicket = await createTicket();

            if (!newTicket) {
                AppToaster.error({
                    message: "Something went wrong creating that topic. Please try again.",
                });

                return;
            }

            await onCreate?.({ another });
        },
        [createTicket, onCreate, recordEvent, title]
    );

    useOutsideClick(
        newTicketCardRef,
        useCallback(
            event => {
                if (!title) {
                    onCancel?.();
                } else if (
                    event.target instanceof Element &&
                    !event.target.closest(`.${Classes.OVERLAY}`)
                ) {
                    void handleSubmit({ another: false });
                }
            },
            [handleSubmit, onCancel, title]
        ),
        {
            eventType: "mousedown",
        }
    );

    return (
        <Draggable draggableId={NewTicketEntryId} index={index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => (
                <li
                    className={classNames(
                        className,
                        styles.newTicketCard,
                        submission.isInFlight && styles.isSubmitting,
                        snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging
                    )}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                    onTransitionEnd={e => {
                        provided.draggableProps.onTransitionEnd?.(e);
                    }}
                    ref={e => {
                        newTicketCardRef.current = e;
                        provided.innerRef(e);
                    }}
                >
                    {submission.isInFlight && (
                        <Spinner
                            className={styles.spinner}
                            intent={Enums.BlueprintIntent.PRIMARY}
                            size={20}
                        />
                    )}
                    <TextArea
                        autoSize
                        autoFocus
                        className={classNames(styles.title, CssClasses.ALWAYS_SHOW_FOCUS_INDICATOR)}
                        disabled={submission.isInFlight}
                        fill
                        onChange={e => newTicketEntry.setContent({ title: e.target.value })}
                        onKeyboardCancel={() => {
                            if (title) {
                                newTicketEntry.setContent({ title: "" });
                            } else {
                                handleCancel();
                            }
                        }}
                        onKeyboardSubmit={submission.watch(() => handleSubmit({ another: true }))}
                        treatAsTextInputForKeyboard
                        value={title}
                        placeholder="Enter title..."
                    />
                    {!submission.isInFlight && title ? (
                        <div className={styles.labelsPickerAndFooterActionsWrapper}>
                            <span className={styles.labelsPickerWrapper}>
                                <LabelsPicker
                                    boardId={boardId}
                                    initiallySelectedLabels={labels}
                                    onAdd={(_, selectedLabels) =>
                                        newTicketEntry.setContent({ labels: selectedLabels })
                                    }
                                    onRemove={(_, selectedLabels) =>
                                        newTicketEntry.setContent({ labels: selectedLabels })
                                    }
                                    {...(boundariesElement && {
                                        popoverModifiers: {
                                            flip: {
                                                boundariesElement,
                                            },
                                            preventOverflow: {
                                                boundariesElement,
                                            },
                                        },
                                    })}
                                />
                            </span>
                            <FooterActions
                                onCancel={onCancel}
                                onSubmit={submission.watch(() => handleSubmit({ another: false }))}
                                canSubmit={!!title}
                            />
                        </div>
                    ) : (
                        !!labels.length && (
                            <div className={styles.labels}>
                                {labels
                                    .concat()
                                    .sort(
                                        (a, b) =>
                                            a.text.localeCompare(b.text) ||
                                            a.color.localeCompare(b.color)
                                    )
                                    .map(label => (
                                        <React.Fragment key={`${label.color}|${label.text}`}>
                                            <Label color={label.color} text={label.text} />
                                        </React.Fragment>
                                    ))}
                            </div>
                        )
                    )}
                </li>
            )}
        </Draggable>
    );
}
