import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ValueOf } from "c9r-common";

import { DefaultDraftId, DraftEnums, createDraftStorageKey, hasExistingDraft } from "lib/Drafts";
import { useResettingState } from "lib/Hooks";
import { useLocation } from "lib/Routing";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { DetailViewDiscussionProvider_ticketFragment } from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

export type DetailViewDiscussionContextValue = {
    didDiscussionJustMount: boolean;
    isAddingNewBlocker: boolean;
    isAddingNewComment: boolean;
    isDiscussionPanelPopulated: boolean;
    lastReopenedThreadId: string | null;
    newBlockerDraftStorageKey: string;
    newCommentDraftStorageKey: string;
    onThreadReopen: ({ threadId }: { threadId: string }) => void;
    resolvedCount: number;
    setIsAddingNewBlocker: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAddingNewComment: React.Dispatch<React.SetStateAction<boolean>>;
    setShowResolved: React.Dispatch<React.SetStateAction<boolean>>;
    showResolved: boolean;
    urlHashId: { type: "COMMENT" | "THREAD" | undefined; value: string };
};

const [useDetailViewDiscussion, ContextProvider] = createCtx<DetailViewDiscussionContextValue>();

export { useDetailViewDiscussion };

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DetailViewDiscussionProvider_ticket on tickets {
            id

            blocker_of_threads(where: { resolved_at: { _is_null: true } }) {
                id
            }

            threads {
                id
                opened_at
                resolved_at

                comments {
                    id
                }
            }
        }
    `),
};

const getNewBlockerDraftStorageKey = ({ ticketId }: { ticketId: string }) =>
    createDraftStorageKey({
        entityType: DraftEnums.EntityTypes.TICKET,
        entityId: ticketId,
        draftEntityType: DraftEnums.DraftEntityTypes.BLOCKER,
        draftEntityTypeId: DefaultDraftId,
    });

const getNewCommentDraftStorageKey = ({ ticketId }: { ticketId: string }) =>
    createDraftStorageKey({
        entityType: DraftEnums.EntityTypes.TICKET,
        entityId: ticketId,
        draftEntityType: DraftEnums.DraftEntityTypes.THREAD,
        draftEntityTypeId: DefaultDraftId,
    });

export const ResolvedThreadsLocation = {
    DRAWER: "DRAWER",
    PANEL: "PANEL",
} as const;

const isDisplayingBlockingSection = ({
    ticket,
}: {
    ticket: DetailViewDiscussionProvider_ticketFragment;
}) => {
    return !!ticket.blocker_of_threads.length;
};

const isDisplayingThreads = ({
    resolvedThreadsLocation,
    showResolved,
    ticket,
}: {
    resolvedThreadsLocation: ValueOf<typeof ResolvedThreadsLocation>;
    showResolved?: boolean;
    ticket: DetailViewDiscussionProvider_ticketFragment;
}) => {
    return !!ticket.threads.some(
        thread =>
            !thread.resolved_at ||
            (showResolved && resolvedThreadsLocation === ResolvedThreadsLocation.PANEL)
    );
};

const getUrlHashId = (urlHash: string) => {
    // `urlHash` is of the form /#(?<anchor>C|T)(?<id>.*)

    const ANCHOR_INDEX = 1;

    return {
        type: ({ C: "COMMENT", T: "THREAD" } as const)[urlHash[ANCHOR_INDEX]],
        value: urlHash.slice(ANCHOR_INDEX + 1),
    };
};

export type DetailViewDiscussionProviderInternalProps = {
    children: React.ReactNode;
    resolvedThreadsLocation?: ValueOf<typeof ResolvedThreadsLocation>;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function DetailViewDiscussionProviderInternal({
    children,
    resolvedThreadsLocation = ResolvedThreadsLocation.PANEL,
    ticket: _ticketFragment,
}: DetailViewDiscussionProviderInternalProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const ticketId = ticket.id;
    const location = useLocation();
    const [didDiscussionJustMount, setDidDiscussionJustMount] = useResettingState(false, 250);
    const [lastReopenedThreadId, setLastReopenedThreadId] = useState<string | null>(null);
    const [showResolved, setShowResolved] = useState(false);
    const [urlHashId, setUrlHashId] = useState(getUrlHashId(location.hash));
    const newBlockerDraftStorageKey = getNewBlockerDraftStorageKey({ ticketId });
    const newCommentDraftStorageKey = getNewCommentDraftStorageKey({ ticketId });
    const [isAddingNewBlocker, setIsAddingNewBlocker] = useState(
        hasExistingDraft(newBlockerDraftStorageKey)
    );
    const [isAddingNewComment, setIsAddingNewComment] = useState(
        hasExistingDraft(newCommentDraftStorageKey)
    );
    const [isDiscussionPanelPopulated, setIsDiscussionPanelPopulated] = useState(
        isDisplayingBlockingSection({ ticket }) ||
            isDisplayingThreads({ resolvedThreadsLocation, showResolved, ticket }) ||
            isAddingNewBlocker ||
            isAddingNewComment
    );

    useEffect(() => {
        setDidDiscussionJustMount(true);
    }, [setDidDiscussionJustMount]);

    useEffect(() => {
        const newUrlHashId = getUrlHashId(location.hash);

        setUrlHashId(newUrlHashId);

        // If the hash ID is associated with a thread and that thread is resolved, then show resolved threads.
        if (newUrlHashId.type === undefined) {
            return;
        }

        const thread = {
            COMMENT: ticket.threads.find(t => t.comments.some(c => c.id === newUrlHashId.value)),
            THREAD: ticket.threads.find(t => t.id === newUrlHashId.value),
        }[newUrlHashId.type];

        if (thread?.resolved_at) {
            setShowResolved(true);
        }

        // We don't wish to add ticket.threads as a dependency as we don't want
        // the effect to run again if this changes, e.g. if a comment is added.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash]);

    useEffect(() => {
        setIsAddingNewBlocker(hasExistingDraft(newBlockerDraftStorageKey));
        setIsAddingNewComment(hasExistingDraft(newCommentDraftStorageKey));
    }, [newBlockerDraftStorageKey, newCommentDraftStorageKey]);

    useEffect(() => {
        setIsDiscussionPanelPopulated(
            isDisplayingBlockingSection({ ticket }) ||
                isDisplayingThreads({ resolvedThreadsLocation, showResolved, ticket }) ||
                isAddingNewBlocker ||
                isAddingNewComment
        );
    }, [isAddingNewBlocker, isAddingNewComment, resolvedThreadsLocation, showResolved, ticket]);

    const onThreadReopen = useCallback(
        ({ threadId }: { threadId: string }) => {
            if (resolvedThreadsLocation === ResolvedThreadsLocation.DRAWER) {
                setShowResolved(false);
                setLastReopenedThreadId(threadId);
            }
        },
        [resolvedThreadsLocation]
    );

    const resolvedCount = ticket.threads.filter(thread => thread.resolved_at).length;

    const value = useMemo(
        () => ({
            didDiscussionJustMount,
            isAddingNewBlocker,
            isAddingNewComment,
            isDiscussionPanelPopulated,
            lastReopenedThreadId,
            newBlockerDraftStorageKey,
            newCommentDraftStorageKey,
            onThreadReopen,
            resolvedCount,
            setIsAddingNewBlocker,
            setIsAddingNewComment,
            setShowResolved,
            showResolved,
            urlHashId,
        }),
        [
            didDiscussionJustMount,
            isAddingNewBlocker,
            isAddingNewComment,
            isDiscussionPanelPopulated,
            lastReopenedThreadId,
            newBlockerDraftStorageKey,
            newCommentDraftStorageKey,
            onThreadReopen,
            resolvedCount,
            setIsAddingNewBlocker,
            setIsAddingNewComment,
            setShowResolved,
            showResolved,
            urlHashId,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

export type DetailViewDiscussionProviderProps = DetailViewDiscussionProviderInternalProps;

export function DetailViewDiscussionProvider({
    children,
    resolvedThreadsLocation,
    ticket: _ticketFragment,
}: DetailViewDiscussionProviderProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <DetailViewDiscussionProviderInternal
            ticket={_ticketFragment}
            resolvedThreadsLocation={resolvedThreadsLocation}
            key={ticket.id}
        >
            {children}
        </DetailViewDiscussionProviderInternal>
    );
}
