import React from "react";

import classNames from "classnames";

import styles from "./Banner.module.scss";

export type BannerProps = {
    className?: string;
    contentClassName?: string;
    content: React.ReactNode;
    warning?: boolean;
};

export function Banner({ className, contentClassName, content, warning }: BannerProps) {
    if (!content) {
        return null;
    }

    return (
        <span className={classNames(className, styles.banner, warning && styles.warning)}>
            <span className={classNames(contentClassName, styles.bannerContent)}>{content}</span>
        </span>
    );
}
