import React, { useEffect, useRef, useState } from "react";

import { sortLabels } from "c9r-common";
import classNames from "classnames";
import { InView } from "react-intersection-observer";

import { AppData } from "AppData";
import { DraftReminder } from "components/shared/DraftReminder";
import { Label } from "components/shared/Label";
import { MultiselectBadge } from "components/shared/MultiselectBadge";
import { CardMenu, CardMenuProps } from "components/shared/card/CardMenu";
import { Tooltip } from "components/ui/core/Tooltip";
import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { maybeQuote } from "lib/Helpers";
import { useDidChange } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { BoardCard_ticketFragment } from "lib/graphql/__generated__/graphql";

import { BlockersSection } from "./BlockersSection";
import styles from "./BoardCard.module.scss";
import { BulkActionCardMenu } from "./BulkActionCardMenu";
import { CardChildTickets } from "./CardChildTickets";
import { CardInfoBoxes } from "./CardInfoBoxes";
import { CardInfoSummary } from "./CardInfoSummary";
import { DueDate } from "./DueDate";
import { ParentTickets } from "./ParentTickets";
import { Ref } from "./Ref";
import { Title } from "./Title";
import { useBoardView } from "../../BoardViewContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment BoardCard_ticket on tickets {
            id
            due_date
            title

            child_of_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    ticket: { trashed_at: { _is_null: true } }
                }
            ) {
                id
            }

            label_attachments {
                color
                text
            }

            board {
                id
                display_name
            }

            ...BlockersSection_ticket
            ...BulkActionCardMenu_ticket
            ...CardChildTickets_ticket
            ...CardInfoBoxes_ticket
            ...CardInfoSummary_ticket
            ...CardMenu_ticket
            ...DueDate_ticket
            ...ParentTickets_ticket
            ...Ref_ticket
            ...Title_ticket
        }
    `),
};

type BoardCardContentProps = {
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
    ticket: BoardCard_ticketFragment;
};

const BoardCardContent = React.memo(({ onClickFilterTrigger, ticket }: BoardCardContentProps) => {
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    const getLabels = () => {
        return (
            <div className={styles.labels}>
                {ticket.label_attachments.length
                    ? ticket.label_attachments
                          .concat()
                          .sort(sortLabels())
                          .map(label => {
                              return (
                                  <React.Fragment key={`${label.color}|${label.text}`}>
                                      <Tooltip
                                          content={<span>Filter workspace by label</span>}
                                          openOnTargetFocus={false}
                                          placement="bottom"
                                          small
                                      >
                                          <Label
                                              color={label.color}
                                              text={label.text}
                                              onClick={
                                                  onClickFilterTrigger
                                                      ? e =>
                                                            onClickFilterTrigger(
                                                                e,
                                                                `label:${maybeQuote(label.text)}`
                                                            )
                                                      : undefined
                                              }
                                          />
                                      </Tooltip>
                                  </React.Fragment>
                              );
                          })
                    : null}
            </div>
        );
    };

    return (
        <div className={styles.cardContent}>
            <DraftReminder className={styles.draftReminder} ticket={ticket} />
            {shouldShowTicketRefs ? (
                <>
                    <div className={styles.cardHeader}>
                        <Ref ticket={ticket} />
                        <div className={styles.spacer} />
                        <DueDate ticket={ticket} />
                    </div>
                    <ParentTickets className={styles.parentTickets} ticket={ticket} />
                </>
            ) : ticket.child_of_tasks.length !== 0 || ticket.due_date ? (
                <div className={styles.cardHeader}>
                    <ParentTickets className={styles.parentTickets} ticket={ticket} />
                    <div className={styles.spacer} />
                    <DueDate className={styles.dueDate} ticket={ticket} />
                </div>
            ) : null}
            <Title className={styles.title} ticket={ticket} />
            {getLabels()}
            <BlockersSection className={styles.blockersSection} ticket={ticket} />
            <CardInfoBoxes className={styles.cardInfoBoxes} ticket={ticket} />
            <CardInfoSummary
                className={styles.cardInfoSummary}
                ticket={ticket}
                onClickFilterTrigger={onClickFilterTrigger}
            />
            <CardChildTickets className={styles.cardChildTickets} ticket={ticket} />
        </div>
    );
});

BoardCardContent.displayName = "BoardCardContent";

export type BoardCardProps = {
    isDragging?: boolean;
    isDropping?: boolean;
    isHighlighted?: boolean;
    isMuted?: boolean;
    isSelected?: boolean;
    isFirstTicketInStage?: boolean;
    isLastTicketInStage?: boolean;
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
    ticket: FragmentType<typeof fragments.ticket>;
} & Pick<
    CardMenuProps,
    | "handleEdit"
    | "handleMoveToStartOfStage"
    | "handleMoveToEndOfStage"
    | "handleMoveToPreviousStage"
    | "handleMoveToNextStage"
    | "handleArchive"
    | "handleMoveToTrash"
>;

export function BoardCard({
    isDragging,
    isDropping,
    isMuted,
    isSelected,
    isFirstTicketInStage,
    isLastTicketInStage,
    handleEdit,
    handleMoveToStartOfStage,
    handleMoveToEndOfStage,
    handleMoveToPreviousStage,
    handleMoveToNextStage,
    handleArchive,
    handleMoveToTrash,
    onClickFilterTrigger,
    ticket: _ticketFragment,
}: BoardCardProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const [isVisible, setIsVisible] = useState(true);
    const { isDragInProgress } = useBoardView();
    const {
        getSelectableElementProps,
        maybeHandleTicketSelectionClick,
        selection,
    } = useTicketSelectionContext();
    const ref = useRef<HTMLDivElement>(null);
    const wasJustDropped = useDidChange({
        value: isDropping,
        from: true,
        to: false,
        resetTimeoutMs: 200,
    });

    useEffect(() => {
        if (
            ticket.id === AppData.scrollToTicketId &&
            AppData.scrollToTicketSetAt &&
            Date.now() - AppData.scrollToTicketSetAt < 3000
        ) {
            // As of April 2022 when this was implemented, if this setTimeout is removed or
            // replaced with setImmediate, the autoscroll to the new ticket would sometimes fail
            // to work (even though scrollIntoView was called). As a quick mitigation, we do it
            // after a short delay.
            setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 250);
        }
    }, [ticket.id]);

    return (
        <div
            {...getSelectableElementProps(ticket.id)}
            data-cy="board-card"
            className={classNames(
                styles.boardCard,
                isDragging && styles.dragging,
                isDropping && styles.dropping,
                wasJustDropped && styles.wasJustDropped,
                isMuted && styles.isMuted,
                isVisible && styles.isVisible,
                selection.mode === Enums.MultiselectMode.STARTING && styles.multiselectStarting,
                selection.mode === Enums.MultiselectMode.ACTIVE && styles.multiselectActive,
                isSelected ? styles.selected : styles.notSelected
            )}
            ref={ref}
            onClick={e => maybeHandleTicketSelectionClick({ e, ticketId: ticket.id })}
        >
            <InView as="div" className={styles.visibilityDetector} onChange={setIsVisible} />
            {!isDragInProgress || (isDragging && isSelected) ? (
                <MultiselectBadge
                    className={styles.multiselectIcon}
                    count={isDragging && isSelected ? selection.ticketIds.size : undefined}
                />
            ) : null}
            {isSelected ? (
                <BulkActionCardMenu className={styles.menuWrapper} ticket={ticket} />
            ) : (
                <CardMenu
                    className={styles.menuWrapper}
                    ticket={ticket}
                    isFirstTicketInStage={isFirstTicketInStage}
                    isLastTicketInStage={isLastTicketInStage}
                    handleEdit={handleEdit}
                    handleMoveToStartOfStage={handleMoveToStartOfStage}
                    handleMoveToEndOfStage={handleMoveToEndOfStage}
                    handleMoveToPreviousStage={handleMoveToPreviousStage}
                    handleMoveToNextStage={handleMoveToNextStage}
                    handleArchive={handleArchive}
                    handleMoveToTrash={handleMoveToTrash}
                />
            )}
            <BoardCardContent onClickFilterTrigger={onClickFilterTrigger} ticket={ticket} />
        </div>
    );
}
