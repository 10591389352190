import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { TQueryResult, ViewQueryLoader } from "components/loading/QueryLoader";
import { CardMenu } from "components/shared/card/CardMenu";
import {
    TicketList,
    TicketListHeader,
    TicketListRow,
} from "components/shared/ticketList/TicketList";
import {
    TaskRowContentCheckbox,
    TaskRowContentDueDate,
    TaskRowContentMainInfo,
    TicketRowContentActivity,
    TicketRowContentAncestry,
    TicketRowContentBoard,
    TicketRowContentDueDate,
    TicketRowContentDynamicWidthTableColumnDefinitions,
    TicketRowContentIcon,
    TicketRowContentMainInfo,
    TicketRowContentOwnership,
    TicketRowContentPlaceholder,
    TicketRowContentProgress,
    TicketRowContentStage,
} from "components/shared/ticketList/TicketRowContent";
import {
    TicketListTaskSubrow,
    TicketRowLayout,
} from "components/shared/ticketList/TicketRowLayout";
import { DynamicWidthTable, DynamicWidthTableCell } from "components/ui/common/DynamicWidthTable";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { interpolateMissingPositions, moveToPositionByIndex } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useDocumentTitle, useResettingValueMap, useSaveScrollPosition } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    PlanViewQuery,
    PlanView_taskFragment,
    PlanView_ticketFragment,
    PlanView_userFragment,
} from "lib/graphql/__generated__/graphql";
import { useChangeTicketPlans } from "lib/mutations";
import { createCtx } from "lib/react/Context";

import styles from "./PlanView.module.scss";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment PlanView_task on tasks {
            id
            assigned_to_user_id
            deleted_at
            due_date
            is_complete
            task_type
            tasklist_pos

            tasklist {
                id
                added_at

                stage {
                    id
                    board_pos
                }
            }

            ...TaskRowContentCheckbox_task
            ...TaskRowContentDueDate_task
            ...TaskRowContentMainInfo_task
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment PlanView_ticket on tickets {
            id
            archived_at
            trashed_at

            tasks(where: { deleted_at: { _is_null: true } }) {
                id
                ...PlanView_task
            }

            ...CardMenu_ticket
            ...TicketListRow_ticket
            ...TicketRowContentActivity_ticket
            ...TicketRowContentAncestry_ticket
            ...TicketRowContentBoard_ticket
            ...TicketRowContentDueDate_ticket
            ...TicketRowContentMainInfo_ticket
            ...TicketRowContentOwnership_ticket
            ...TicketRowContentProgress_ticket
            ...TicketRowContentReference_ticket
            ...TicketRowContentStage_ticket
            ...TicketWatcherInfo_ticket
        }
    `),

    user: gql(/* GraphQL */ `
        fragment PlanView_user on users {
            id
            name

            owned_tickets(
                where: {
                    ticket: {
                        archived_at: { _is_null: true }
                        trashed_at: { _is_null: true }
                        board: { archived_at: { _is_null: true } }
                        stage: { deleted_at: { _is_null: true }, role: { _nin: ["COMPLETE"] } }
                    }
                }
            ) {
                ticket_id
                user_id
                type
                added_at

                ticket {
                    id
                    ...PlanView_ticket
                }
            }

            ticket_plans {
                user_id
                ticket_id
                plan_type
                plan_pos
            }
        }
    `),
};

const DynamicWidthTableColumnDefinitions = [
    ...TicketRowContentDynamicWidthTableColumnDefinitions,
    {
        columnClassName: styles.menu,
        displayIfEmpty: true,
    },
];

export type PlanViewContextValue = {
    handleTaskCompletionChange: ({
        taskId,
        isComplete,
    }: {
        taskId: string;
        isComplete: boolean;
    }) => void;
    isRecentlyCompletedTask: ({ taskId }: { taskId: string }) => boolean;
    isSectionCollapsed: ({
        planType,
    }: {
        planType: CommonEnumValue<"UserTicketPlanType">;
    }) => boolean;
    isTicketCollapsed: ({ ticketId }: { ticketId: string }) => boolean;
    tickets: PlanView_ticketFragment[];
    toggleSectionCollapsed: ({
        planType,
    }: {
        planType: CommonEnumValue<"UserTicketPlanType">;
    }) => void;
    toggleTicketCollapsed: ({ ticketId }: { ticketId: string }) => void;
    user: PlanView_userFragment;
};

const [usePlanView, ContextProvider] = createCtx<PlanViewContextValue>();

type PlanViewProviderProps = {
    children: React.ReactNode;
    user: FragmentType<typeof fragments.user>;
};

type ViewState = Partial<{
    sections: Partial<
        Record<
            CommonEnumValue<"UserTicketPlanType">,
            {
                isCollapsed?: boolean;
            }
        >
    >;
    tickets: Record<
        number | string,
        {
            isCollapsed?: boolean;
        }
    >;
}>;

// If updating, ensure it matches the CSS transition duration.
const COMPLETED_TASK_UNMOUNT_DELAY_MS = 300;

function PlanViewProvider({ children, user: _userFragment }: PlanViewProviderProps) {
    const user = getFragmentData(fragments.user, _userFragment);

    // Track when a task is marked completed in this view, so that we can still display it briefly
    // before it's unmounted.
    const resettingValueMap = useResettingValueMap({ timeoutMs: COMPLETED_TASK_UNMOUNT_DELAY_MS });

    const isRecentlyCompletedTask = useCallback(
        ({ taskId }: { taskId: string }) => {
            return !!resettingValueMap.getValueById({ id: taskId });
        },
        [resettingValueMap]
    );

    const handleTaskCompletionChange = useCallback(
        ({ taskId, isComplete }: { taskId: string; isComplete: boolean }) => {
            resettingValueMap.setValueById({ id: taskId, value: isComplete });
        },
        [resettingValueMap]
    );

    const tickets = useMemo(
        () =>
            user.owned_tickets
                .map(ot => getFragmentData(fragments.ticket, ot.ticket))
                .filter(t => !t.archived_at && !t.trashed_at),
        [user.owned_tickets]
    );
    const storageKey = `user.${user.id}.plan`;
    const [viewState, setViewState] = useState(
        (Storage.All.getItem(storageKey) ?? {}) as ViewState
    );

    useEffect(() => {
        Storage.All.setItem(storageKey, viewState);
    }, [viewState, storageKey]);

    const isSectionCollapsed = useCallback(
        ({ planType }: { planType: CommonEnumValue<"UserTicketPlanType"> }) => {
            return !!viewState?.sections?.[planType]?.isCollapsed;
        },
        [viewState]
    );

    const toggleSectionCollapsed = useCallback(
        ({ planType }: { planType: CommonEnumValue<"UserTicketPlanType"> }) => {
            setViewState(prev => ({
                ...prev,
                sections: {
                    ...prev.sections,
                    [planType]: {
                        ...prev.sections?.[planType],
                        isCollapsed: !prev?.sections?.[planType]?.isCollapsed,
                    },
                },
            }));
        },
        []
    );

    const isTicketCollapsed = useCallback(
        ({ ticketId }: { ticketId: string }) => {
            const isCollapsed = viewState?.tickets?.[ticketId]?.isCollapsed;

            // Default: tickets are  collapsed
            return typeof isCollapsed === "boolean" ? isCollapsed : true;
        },
        [viewState]
    );

    const toggleTicketCollapsed = useCallback(({ ticketId }: { ticketId: string }) => {
        setViewState(prev => {
            const prevIsCollapsed = prev?.tickets?.[ticketId]?.isCollapsed;

            return {
                ...prev,
                tickets: {
                    ...prev.tickets,
                    [ticketId]: {
                        ...prev.tickets?.[ticketId],
                        // Default: tickets are collapsed, so if the value isn't set, toggling
                        // should make it not collapsed
                        isCollapsed:
                            typeof prevIsCollapsed === "boolean" ? !prevIsCollapsed : false,
                    },
                },
            };
        });
    }, []);

    const value = useMemo(
        () => ({
            handleTaskCompletionChange,
            isRecentlyCompletedTask,
            isSectionCollapsed,
            isTicketCollapsed,
            tickets,
            toggleSectionCollapsed,
            toggleTicketCollapsed,
            user,
        }),
        [
            handleTaskCompletionChange,
            isRecentlyCompletedTask,
            isSectionCollapsed,
            isTicketCollapsed,
            tickets,
            toggleSectionCollapsed,
            toggleTicketCollapsed,
            user,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

type SortableSectionRecord = {
    id: string;
    becameOwnerAt: string;
    posPersisted?: number;
    tasks?: PlanView_taskFragment[];
    ticket: PlanView_ticketFragment;
};

type SectionRecord = {
    id: string;
    posDisplayed: number;
    posPersisted?: number;
    tasks?: PlanView_taskFragment[];
    ticket: PlanView_ticketFragment;
};

export function sortSection(a: SortableSectionRecord, b: SortableSectionRecord) {
    if (a.posPersisted && b.posPersisted) {
        return a.posPersisted - b.posPersisted;
    }

    if (a.posPersisted && !b.posPersisted) {
        return 1;
    }

    if (!a.posPersisted && b.posPersisted) {
        return -1;
    }

    return new Date(b.becameOwnerAt).getTime() - new Date(a.becameOwnerAt).getTime();
}

function useSections() {
    const { isRecentlyCompletedTask, tickets, user } = usePlanView();

    const { sortableSectionRecords, ticketInfoMap } = useMemo(() => {
        const _ticketsByTicketId = Object.fromEntries(tickets.map(ticket => [ticket.id, ticket]));
        const _plansByTicketId: Partial<Record<
            typeof user["ticket_plans"][number]["ticket_id"],
            typeof user.ticket_plans[number]
        >> = Object.fromEntries(user.ticket_plans.map(tp => [tp.ticket_id, tp]));
        const _sortableSectionRecords: SortableSectionRecord[] = user.owned_tickets
            .filter(ot => _ticketsByTicketId[ot.ticket.id])
            .map(ot => ({
                id: String(ot.ticket.id),
                becameOwnerAt: ot.added_at,
                posPersisted: _plansByTicketId[ot.ticket.id]?.plan_pos,
                tasks: _ticketsByTicketId[ot.ticket.id].tasks
                    .map(t => getFragmentData(fragments.task, t))
                    .filter(
                        task =>
                            task.task_type === CommonEnums.TaskType.TASK &&
                            (!task.is_complete || isRecentlyCompletedTask({ taskId: task.id })) &&
                            !task.deleted_at &&
                            // Explicitly owned tasks: assingned to the user
                            // Implicitly owned tasks: unassigned tasks go to the ticket owner
                            (task.assigned_to_user_id === user.id ||
                                (ot.type === CommonEnums.TicketOwnerType.OWNER &&
                                    !task.assigned_to_user_id))
                    )
                    .sort((a, b) =>
                        a.tasklist.id === b.tasklist.id
                            ? (a.tasklist_pos ?? 0) - (b.tasklist_pos ?? 0)
                            : a.tasklist.stage.id === b.tasklist.stage.id
                            ? new Date(a.tasklist.added_at).getTime() -
                              new Date(b.tasklist.added_at).getTime()
                            : (a.tasklist.stage.board_pos ?? 0) - (b.tasklist.stage.board_pos ?? 0)
                    ),
                ticket: _ticketsByTicketId[ot.ticket.id],
            }));
        const _ticketInfoMap = Object.fromEntries(
            _sortableSectionRecords.map(ot => [
                ot.ticket.id,
                {
                    ticket: ot.ticket,
                    plan: _plansByTicketId[ot.ticket.id] ?? null,
                },
            ])
        );

        return {
            sortableSectionRecords: _sortableSectionRecords,
            plansByTicketId: _plansByTicketId,
            ticketInfoMap: _ticketInfoMap,
        };
    }, [isRecentlyCompletedTask, tickets, user]);

    // When a user views the page, if there are new tickets in the "NONE" section, they
    // may not have a position yet. We sort those based on when the user was added as an owner.
    // Meantime, we calculate a position for those records and persist that new position. That
    // way, the user can drag/drop normally.
    const getRecordsForSection = useCallback(
        ({ planType }: { planType: string }) => {
            const sortedSectionRecords = sortableSectionRecords
                .filter(
                    ({ ticket }) =>
                        ticketInfoMap[ticket.id].plan?.plan_type === planType ||
                        (planType === CommonEnums.UserTicketPlanType.NONE &&
                            !ticketInfoMap[ticket.id].plan?.plan_type)
                )
                .sort(sortSection);

            const positions = interpolateMissingPositions({
                sortedPositions: sortedSectionRecords.map(ssr => ssr.posPersisted),
            });
            const sectionRecords: SectionRecord[] = sortedSectionRecords.map((ssr, i) => ({
                id: ssr.id,
                posPersisted: ssr.posPersisted,
                posDisplayed: positions[i],
                tasks: ssr.tasks,
                ticket: ssr.ticket,
            }));

            return sectionRecords;
        },
        [sortableSectionRecords, ticketInfoMap]
    );

    return useMemo(() => ({ getRecordsForSection }), [getRecordsForSection]);
}

function usePersistPlanPositiions({
    planType,
}: {
    planType: CommonEnumValue<"UserTicketPlanType">;
}) {
    const [isInFlight, setIsInFlight] = useState(false);
    const currentUser = useCurrentUser();
    const { user } = usePlanView();
    const { changeTicketPlans } = useChangeTicketPlans();
    const { getRecordsForSection } = useSections();

    useEffect(() => {
        if (currentUser.id !== user.id || currentUser.isReadOnly || isInFlight) {
            return;
        }

        const sectionRecords = getRecordsForSection({ planType });

        if (sectionRecords.every(pr => pr.posPersisted)) {
            return;
        }

        (async () => {
            setIsInFlight(true);

            try {
                await changeTicketPlans(
                    sectionRecords
                        .filter(pr => !pr.posPersisted)
                        .map(pr => ({
                            ticketId: pr.ticket.id,
                            planType,
                            planPos: pr.posDisplayed,
                        }))
                );
            } finally {
                setIsInFlight(false);
            }
        })();
    }, [
        changeTicketPlans,
        currentUser.id,
        currentUser.isReadOnly,
        getRecordsForSection,
        isInFlight,
        user.id,
        planType,
    ]);
}

type SectionTitleProps = {
    isEmpty?: boolean;
    planType: CommonEnumValue<"UserTicketPlanType">;
};

function SectionTitle({ isEmpty, planType }: SectionTitleProps) {
    return (
        <span className={classNames(styles.sectionTitle, isEmpty && styles.sectionIsEmpty)}>
            {
                {
                    [CommonEnums.UserTicketPlanType.LATER]: "Later",
                    [CommonEnums.UserTicketPlanType.NEXT]: "Next",
                    [CommonEnums.UserTicketPlanType.NONE]: (
                        <>
                            <span>Inbox</span>
                            <Icon
                                className={styles.sectionTitleIcon}
                                icon="inbox"
                                iconSet="lucide"
                                iconSize={16}
                                strokeWidth={1}
                            />
                        </>
                    ),
                    [CommonEnums.UserTicketPlanType.NOW]: "Now",
                }[planType]
            }
        </span>
    );
}

type SectionRowProps = {
    index: number;
    sectionRecord: SectionRecord;
};

function SectionRow({ index, sectionRecord }: SectionRowProps) {
    const currentUser = useCurrentUser();
    const location = useLocation();
    const {
        handleTaskCompletionChange,
        isTicketCollapsed,
        toggleTicketCollapsed,
        user,
    } = usePlanView();
    const isReadOnly = currentUser.id !== user.id;

    const { tasks, ticket } = sectionRecord;
    const ticketId = ticket.id;

    const isCollapsed = isTicketCollapsed({ ticketId });

    const toggleIsCollapsed = useCallback(() => {
        toggleTicketCollapsed({ ticketId });
    }, [ticketId, toggleTicketCollapsed]);

    return (
        <TicketListRow
            className={styles.ticketListRow}
            key={ticket.id}
            index={index}
            isDraggable={!isReadOnly}
            locationState={{
                from: {
                    location,
                    pageDetails: {
                        appPage: Enums.AppPage.USER,
                        userId: user.id,
                    },
                },
            }}
            ticketRowLayout={
                <TicketRowLayout
                    isCollapsed={isCollapsed}
                    ticketContent={
                        <>
                            <TicketRowContentIcon className={styles.ticketRowIcon} />
                            <TicketRowContentMainInfo ticket={ticket} />
                            <TicketRowContentAncestry ticket={ticket} />
                            <TicketRowContentActivity ticket={ticket} />
                            <TicketRowContentBoard ticket={ticket} />
                            <TicketRowContentStage ticket={ticket} />
                            <TicketRowContentProgress ticket={ticket} />
                            <TicketRowContentOwnership ticket={ticket} />
                            <TicketRowContentDueDate ticket={ticket} />
                            <DynamicWidthTableCell className={styles.menu}>
                                <CardMenu
                                    className={styles.menuButton}
                                    ticket={ticket}
                                    handleMoveToStartOfStage={null}
                                    handleMoveToEndOfStage={null}
                                />
                            </DynamicWidthTableCell>
                        </>
                    }
                    taskSubrows={
                        tasks?.length
                            ? tasks.map(task => {
                                  return (
                                      <TicketListTaskSubrow
                                          className={classNames(
                                              styles.taskSubrow,
                                              task.is_complete && styles.isCompleteTask
                                          )}
                                          key={task.id}
                                          taskContent={
                                              <>
                                                  <TaskRowContentCheckbox
                                                      className={styles.ticketRowIcon}
                                                      onChangeTaskCompletion={
                                                          handleTaskCompletionChange
                                                      }
                                                      task={task}
                                                  />
                                                  <TaskRowContentMainInfo task={task} />
                                                  <TicketRowContentPlaceholder classNameKey="ancestry" />
                                                  <TicketRowContentPlaceholder classNameKey="activity" />
                                                  <TicketRowContentPlaceholder classNameKey="board" />
                                                  <TicketRowContentPlaceholder classNameKey="stage" />
                                                  <TicketRowContentPlaceholder classNameKey="progress" />
                                                  <TicketRowContentPlaceholder classNameKey="ownershipSummary" />
                                                  <TaskRowContentDueDate task={task} />
                                                  <DynamicWidthTableCell className={styles.menu} />
                                              </>
                                          }
                                      />
                                  );
                              })
                            : undefined
                    }
                    toggleIsCollapsed={toggleIsCollapsed}
                />
            }
            ticket={ticket}
        />
    );
}

type SectionProps = {
    planType: CommonEnumValue<"UserTicketPlanType">;
};

function Section({ planType }: SectionProps) {
    usePersistPlanPositiions({ planType });

    const { getRecordsForSection } = useSections();
    const { isSectionCollapsed, toggleSectionCollapsed } = usePlanView();
    const sectionRecords = getRecordsForSection({ planType });
    const isCollapsed = isSectionCollapsed({ planType });
    const isEmpty = !sectionRecords.length;

    const toggleIsCollapsed = useCallback(() => {
        toggleSectionCollapsed({ planType });
    }, [planType, toggleSectionCollapsed]);

    return (
        <TicketList
            isCollapsed={isCollapsed}
            listId={planType}
            sectionHeader={
                <TicketListHeader
                    instrumentation={{
                        elementName: "user_view.plan.collapse_btn",
                        eventData: { planType, isCollapsed },
                    }}
                    isCollapsed={isCollapsed}
                    title={<SectionTitle planType={planType} isEmpty={isEmpty} />}
                    toggleIsCollapsed={toggleIsCollapsed}
                />
            }
        >
            {sectionRecords.map((sectionRecord, index) => (
                <SectionRow key={sectionRecord.id} index={index} sectionRecord={sectionRecord} />
            ))}
        </TicketList>
    );
}

function useDragEndHandler() {
    const { changeTicketPlans } = useChangeTicketPlans();
    const { getRecordsForSection } = useSections();

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            const { draggableId, destination, source } = result;

            if (
                !destination ||
                (destination.droppableId === source.droppableId &&
                    destination.index === source.index)
            ) {
                return;
            }

            const ticketId = dragAndDropEntity.getRootId(draggableId);
            const toPlanType = dragAndDropEntity.getRootId(destination.droppableId);
            const toIndex = destination.index;

            // If the destination section is collapsed, destination.index will be zero, which puts the
            // dropped ticket at the top of the section, which is what we want.

            const sectionRecords = getRecordsForSection({ planType: toPlanType });

            const toPlanPos = moveToPositionByIndex({
                sortedEntities: sectionRecords,
                posFieldName: "posDisplayed",
                toIndex,
                entityId: ticketId,
            });

            if (!toPlanType) {
                return;
            }

            await changeTicketPlans([
                {
                    ticketId,
                    planType: toPlanType as CommonEnumValue<"UserTicketPlanType">,
                    planPos: toPlanPos,
                },
            ]);
        },
        [changeTicketPlans, getRecordsForSection]
    );

    return { handleDragEnd };
}

function Content() {
    const { isSectionCollapsed, tickets, user } = usePlanView();
    const { handleDragEnd } = useDragEndHandler();

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <DynamicWidthTable
                columnDefinitions={DynamicWidthTableColumnDefinitions}
                dependencies={[
                    user,
                    tickets,
                    Object.values(CommonEnums.UserTicketPlanType)
                        .map(planType => isSectionCollapsed({ planType }))
                        .join("|"),
                ]}
            >
                <Section planType={CommonEnums.UserTicketPlanType.NONE} />
                <Section planType={CommonEnums.UserTicketPlanType.NOW} />
                <Section planType={CommonEnums.UserTicketPlanType.NEXT} />
                <Section planType={CommonEnums.UserTicketPlanType.LATER} />
            </DynamicWidthTable>
        </DragDropContext>
    );
}

export type PlanViewProps = {
    className?: string;
    userId: number;
};

export function PlanViewImpl({
    className,
    userId,
    queryResult,
}: PlanViewProps & { queryResult: TQueryResult<PlanViewQuery> }) {
    const containerRef = useRef<HTMLDivElement>(null);
    const { setDocumentTitle } = useDocumentTitle();

    useSaveScrollPosition({
        scrollElementRef: containerRef,
        component: "user_view_plan",
        id: userId,
    });

    const userFragment = queryResult.data?.user;
    const user = getFragmentData(fragments.user, userFragment);

    useEffect(() => {
        if (user) {
            setDocumentTitle(user.name);
        }
    }, [user, setDocumentTitle]);

    if (queryResult.loading && !queryResult.data) {
        return null;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    if (!userFragment || !user) {
        return null;
    }

    return (
        <PlanViewProvider user={userFragment}>
            <div className={classNames(className, styles.main)} key={userId} ref={containerRef}>
                <Content />
            </div>
        </PlanViewProvider>
    );
}

export function PlanView(props: PlanViewProps) {
    return (
        <ViewQueryLoader query={PlanView.queries.component} variables={{ userId: props.userId }}>
            {({ queryResult }) => <PlanViewImpl {...props} queryResult={queryResult} />}
        </ViewQueryLoader>
    );
}

PlanView.queries = {
    component: gql(/* GraphQL */ `
        query PlanView($userId: Int!) {
            user: users_by_pk(id: $userId) {
                id
                ...PlanView_user
            }
        }
    `),
};

Queries.register({ component: "PlanView", gqlMapByName: PlanView.queries });
