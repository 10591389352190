import React from "react";

import classNames from "classnames";

import styles from "./CommentExplanatoryFooter.module.scss";

export type CommentExplanatoryFooterProps = {
    className?: string;
    children: React.ReactNode;
};

export function CommentExplanatoryFooter({ className, children }: CommentExplanatoryFooterProps) {
    return <div className={classNames(className, styles.commentExplanatoryFooter)}>{children}</div>;
}
