import { BillingSchema, CommonEnums, ValueOf } from "c9r-common";
import { atom, selector } from "recoil";

export const BillingSection = {
    CONTACT_INFO: "CONTACT_INFO",
    PAYMENT_METHOD: "PAYMENT_METHOD",
    SUBSCRIPTION_PLAN: "SUBSCRIPTION_PLAN",
} as const;

export const billingInfoState = atom<(BillingSchema.BillingInfo & { as_of?: Date | null }) | null>({
    key: "BillingInfo",
    default: null,
});

export const billingInfoSectionLastModifiedState = atom({
    key: "BillingInfoSectionLastModified",
    default: {
        [BillingSection.CONTACT_INFO]: null,
        [BillingSection.PAYMENT_METHOD]: null,
        [BillingSection.SUBSCRIPTION_PLAN]: null,
    } as Record<ValueOf<typeof BillingSection>, Date | null>,
});

export const isEligibleForFreePlanDowngradeState = selector({
    key: "IsEligibleForFreePlanDowngrade",
    get: ({ get }) => {
        const billingInfo = get(billingInfoState);

        return (
            billingInfo?.current_subscription_plan?.plan_key !==
                CommonEnums.SubscriptionPlanKey.FREE_PLAN &&
            billingInfo?.available_subscription_plans?.find(
                plan => plan.plan_key === CommonEnums.SubscriptionPlanKey.FREE_PLAN
            )?.eligibility.is_eligible
        );
    },
});
