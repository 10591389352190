import Graph from "graphology";
import { hasCycle } from "graphology-dag";

import { WriteApi } from "./replicache/Api";
import { TaskEntry } from "./replicache/entries/EntryTypes";

function buildGraph({ tasks }: { tasks: TaskEntry[] }) {
    const graph = new Graph({ type: "directed" });

    tasks.forEach(t => {
        if (t.deleted_at || !t.child_ticket_id) {
            return;
        }

        graph.updateEdge(t.ticket_id, t.child_ticket_id);
    });

    return graph;
}

export async function wouldCreateTicketCycle({
    api,
    parentTicketId,
    childTicketId,
}: {
    api: WriteApi;
    parentTicketId: string;
    childTicketId: string;
}) {
    const tasks = await api.tasks.findAll();
    const graph = buildGraph({ tasks });

    graph.updateEdge(parentTicketId, childTicketId);

    return hasCycle(graph);
}
