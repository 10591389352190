import React from "react";

import Suggestion from "@tiptap/suggestion";
import { ExtensionMention } from "c9r-rich-text";
import { PluginKey } from "prosemirror-state";

import { UserMenuItem } from "components/ui/common/UserMenuItem";
import { Menu } from "components/ui/core/Menu";
import { buildSuggestionParams } from "components/ui/editor/extensions/helpers/Suggestions";
import { isUserQueryMatch } from "lib/Helpers";
import { Regexes } from "lib/Regexes";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { ExtensionMention_userFragment } from "lib/graphql/__generated__/graphql";

import "./Mention.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment ExtensionMention_user on users {
            id
            full_name
            name

            ...UserMenuItem_user
        }
    `),
};

type MentionsSuggestionsMenuProps = {
    items: ExtensionMention_userFragment[];
    selectedIndex: number;
    onSelect: (selectedIndex: number) => void;
};

function MentionsSuggestionsMenu({ items, selectedIndex, onSelect }: MentionsSuggestionsMenuProps) {
    return (
        <Menu>
            {items.map((user, index) => (
                <UserMenuItem
                    key={user.id}
                    active={index === selectedIndex}
                    onClick={() => onSelect(index)}
                    showAvatarAsIcon
                    user={user}
                    instrumentation={{
                        elementName: "mentions_suggestions_menu",
                        eventData: { userId: user.id },
                    }}
                />
            ))}
        </Menu>
    );
}

interface MentionOptions {
    users: FragmentType<typeof fragments.user>[];
}

export default ExtensionMention.extend<MentionOptions>({
    addProseMirrorPlugins() {
        return [
            Suggestion({
                pluginKey: new PluginKey("mention"),
                editor: this.editor,
                ...buildSuggestionParams({
                    char: "@",
                    type: this.name,
                    search: query => {
                        const mentionableUsers = this.options.users.map(user =>
                            getFragmentData(fragments.user, user)
                        );

                        const matchingUsers = (() => {
                            if (!query) {
                                return mentionableUsers.sort((a, b) =>
                                    a.name.localeCompare(b.name)
                                );
                            }

                            const usersWithMatchingUsername = mentionableUsers
                                .filter(user => {
                                    return isUserQueryMatch({
                                        query,
                                        name: user.name,
                                    });
                                })
                                .sort((a, b) => a.name.localeCompare(b.name));

                            const usersWithMatchingFullName = mentionableUsers
                                .filter(user => {
                                    return (
                                        !usersWithMatchingUsername.includes(user) &&
                                        isUserQueryMatch({
                                            query,
                                            name: "",
                                            fullName: user.full_name,
                                        })
                                    );
                                })
                                .sort((a, b) =>
                                    (a.full_name ?? "").localeCompare(b.full_name ?? "")
                                );

                            return [...usersWithMatchingUsername, ...usersWithMatchingFullName];
                        })();

                        return matchingUsers.slice(0, 5).map(user => ({
                            item: user,
                            isExactMatch: user.name.toLowerCase() === query.toLowerCase(),
                        }));
                    },
                    SuggestionsMenuComponent: MentionsSuggestionsMenu,
                    itemToCommandProps: user => ({
                        attrs: { userId: user.id, userName: user.name },
                    }),
                    autoReplaceExactMatch: true,
                    itemCharsRegex: Regexes.VALID_USERNAME_CHAR,
                    previousCharAllowList: [" ", "(", "["],
                }),
            }),
        ];
    },
});
