import React, { Fragment, useCallback, useEffect, useMemo } from "react";

import { CommonEnumValue } from "c9r-common";
import classNames from "classnames";
import { DragDropContext } from "react-beautiful-dnd";

import { BoardFilter } from "components/shared/BoardFilter";
import { BoardHeaderName } from "components/ui/common/BoardHeaderName";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { SideDrawer } from "components/ui/common/SideDrawer";
import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Toggle } from "components/ui/core/Toggle";
import { Tooltip } from "components/ui/core/Tooltip";
import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useCurrentUser } from "contexts/UserContext";
import { useNewTicketDialog } from "dialogs/NewTicketDialog";
import { useBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDocumentTitle } from "lib/Hooks";
import { useHotkey } from "lib/Hotkeys";
import { isKeyboardShortcutEligible } from "lib/Keyboard";
import { useNomenclature } from "lib/Nomenclature";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { isHTMLElement } from "lib/types/guards";

import styles from "./Board.module.scss";
import { useBoardViewFilter } from "./BoardFilterContext";
import { BoardStatusToast } from "./BoardStatusToast";
import { useBoardView } from "./BoardViewContext";
import { EditTicketsToast } from "./EditTicketsToast";
import { UserDispositionBanner } from "./UserDispositionBanner";
import { Columns } from "./columns/Columns";
import { ListView } from "./list/ListView";
import { Archive } from "./sideDrawer/Archive";
import { Trash } from "./sideDrawer/Trash";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment BoardView_board on boards {
            id
            access_type
            archived_at
            display_name

            ...BoardFilter_board
            ...EditBoardDialog_board
            ...NewTicketDialog_board
            ...UserDispositionBanner_board
        }
    `),
};

function useBoardViewDocumentTitle() {
    const currentUser = useCurrentUser();
    const { board: _boardFragment, isArchiveVisible, isTrashVisible } = useBoardView();
    const board = getFragmentData(fragments.board, _boardFragment);

    // As of May 2023, assuming archive and trash never visible at same time.
    const documentTitle = [
        currentUser.org.is_multi_board && board.display_name,
        isArchiveVisible && "Archive",
        isTrashVisible && "Trash",
    ]
        .filter(Boolean)
        .join(" ");

    const { setDocumentTitle } = useDocumentTitle(documentTitle);

    useEffect(() => {
        setDocumentTitle(documentTitle);
    }, [documentTitle, setDocumentTitle]);
}

function BoardMenu() {
    const {
        openEditBoardDialog,
        highlightMyTickets,
        onHighlightMyTicketChange,
        showTicketCounts,
        onShowTicketCountsChange,
        toggleIsArchiveVisible,
        toggleIsTrashVisible,
    } = useBoardView();
    const { nomenclature } = useNomenclature();

    return (
        <div className={styles.boardMenu}>
            <MenuPopover
                className={styles.boardMenuPopover}
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [4, -4],
                        },
                    },
                    preventOverflow: { enabled: false },
                }}
                content={
                    <Menu>
                        <MenuItem
                            text={`${nomenclature.space.singular} setup`}
                            icon={<Icon icon="settings" iconSet="lucide" iconSize={20} />}
                            instrumentation={{
                                elementName: "board.board_menu.board_setup_button",
                            }}
                            onClick={openEditBoardDialog}
                        />
                        <li className={styles.toggle}>
                            <Toggle
                                alignment="right"
                                checked={highlightMyTickets}
                                onChange={onHighlightMyTicketChange}
                                instrumentation={{
                                    elementName: "board.board_menu.highlight_button",
                                }}
                                label="Highlight my topics"
                                fill
                            />
                        </li>
                        <li className={styles.toggle}>
                            <Toggle
                                alignment="right"
                                checked={showTicketCounts}
                                onChange={onShowTicketCountsChange}
                                instrumentation={{
                                    elementName: "board.board_menu.show_ticket_counts_button",
                                }}
                                label="Show topic counts"
                                fill
                            />
                        </li>
                        <MenuDivider />
                        <MenuItem
                            text="View archive"
                            icon={<Icon icon="archive" iconSet="c9r" iconSize={20} />}
                            instrumentation={{
                                elementName: "board.board_menu.archive_button",
                            }}
                            onClick={toggleIsArchiveVisible}
                        />
                        <MenuItem
                            text="View trash"
                            icon={<Icon icon="trash" iconSet="lucide" iconSize={20} />}
                            instrumentation={{ elementName: "board.board_menu.trash_button" }}
                            onClick={toggleIsTrashVisible}
                        />
                    </Menu>
                }
                placement="bottom-end"
            >
                <EllipsisButton
                    className={styles.boardMenuButton}
                    vertical
                    iconSize={20}
                    instrumentation={null}
                />
            </MenuPopover>
        </div>
    );
}

function ViewAsListButton() {
    const {
        setFlipListViewStageOrder,
        setShowListView,
        flipListViewStageOrder,
        showListView,
    } = useBoardView();
    const label = showListView ? "Change list order" : "View as list";

    return (
        <Tooltip
            className={styles.boardStyleControlTooltipWrapper}
            content={<span>{label}</span>}
            openOnTargetFocus={false}
            placement="bottom"
            small
        >
            <BorderButton
                className={classNames(
                    styles.iconButton,
                    styles.viewAsListButton,
                    showListView && styles.active
                )}
                square
                tighterer
                minimal
                instrumentation={{ elementName: "board.board_header.view_as_list" }}
                content={
                    <>
                        <Icon icon="list" iconSet="lucide" iconSize={20} strokeWidth={1} />
                        {!!showListView && (
                            <Icon
                                icon={flipListViewStageOrder ? "arrow-up" : "arrow-down"}
                                iconSet="lucide"
                                iconSize={20}
                                strokeWidth={1}
                            />
                        )}
                    </>
                }
                contentClassName={styles.iconButtonContent}
                onClick={() => {
                    if (showListView) {
                        setFlipListViewStageOrder(prev => !prev);
                    } else {
                        setShowListView(true);
                    }
                }}
                data-cy="view-as-list-btn"
            />
        </Tooltip>
    );
}

function ViewAsColumnsButton() {
    const { setShowListView, showListView } = useBoardView();

    return (
        <Tooltip
            className={styles.boardStyleControlTooltipWrapper}
            content={<span>View as board</span>}
            openOnTargetFocus={false}
            placement="bottom"
            small
        >
            <BorderButton
                className={classNames(styles.iconButton, !showListView && styles.active)}
                square
                tighterer
                minimal
                instrumentation={{ elementName: "board.board_header.view_as_columns" }}
                content={<Icon icon="columnsThree" iconSet="c9r" iconSize={20} strokeWidth={1} />}
                onClick={() => setShowListView(false)}
                data-cy="view-as-columns-btn"
            />
        </Tooltip>
    );
}

type CreateNewTicketButtonProps = {
    onClick: () => void;
};

function CreateNewTicketButton({ onClick }: CreateNewTicketButtonProps) {
    useHotkey("+", () => onClick(), { ignoreModifiers: true, combinationKey: "" }, [onClick]);

    return (
        <Tooltip
            className={styles.boardStyleControlTooltipWrapper}
            content={
                <span className={styles.createNewTicketButtonTooltipContent}>
                    Create new topic <Hotkey text="+" />
                </span>
            }
            openOnTargetFocus={false}
            placement="bottom"
            small
        >
            <BorderButton
                data-cy="create-new-ticket-btn"
                brandCta
                square
                tightest
                instrumentation={{ elementName: "board.board_header.new_ticket" }}
                content={<Icon icon="plus" iconSet="lucide" iconSize={18} strokeWeight={1.5} />}
                onClick={onClick}
            />
        </Tooltip>
    );
}

function MultiselectToast() {
    const { cancelSelection, selection } = useTicketSelectionContext();
    const { board, collapsedStageIds, showListView, tickets } = useBoardView();
    const { isFeatureEnabled } = useFeatureFlags();
    const isQuickEditsEnabled = isFeatureEnabled({ feature: Enums.Feature.QUICK_EDITS });

    const displayedTicketIds = useMemo(
        () =>
            new Set(
                showListView
                    ? tickets
                          .filter(({ stage_id }) => !collapsedStageIds.includes(stage_id))
                          .map(({ id }) => id)
                    : tickets.map(({ id }) => id)
            ),
        [collapsedStageIds, showListView, tickets]
    );

    const hiddenSelectedTicketsCount = useMemo(
        () =>
            Array.from(selection.ticketIds).reduce(
                (count, ticketId) => (displayedTicketIds.has(ticketId) ? count : count + 1),
                0
            ),
        [displayedTicketIds, selection.ticketIds]
    );

    return (
        <>
            <BoardStatusToast
                className={styles.multiselectStatus}
                isOpen={
                    isQuickEditsEnabled
                        ? selection.mode === Enums.MultiselectMode.STARTING
                        : selection.mode !== Enums.MultiselectMode.OFF
                }
            >
                {selection.mode === Enums.MultiselectMode.STARTING ? (
                    <span className={styles.multiselectStatusMainText}>Click to select topics</span>
                ) : (
                    <>
                        <span className={styles.multiselectStatusMainText}>
                            {selection.ticketIds.size.toLocaleString()}{" "}
                            {selection.ticketIds.size === 1 ? "topic" : "topics"} selected
                            {hiddenSelectedTicketsCount ? (
                                <span className={styles.multiselectStatusHiddenTicketCount}>
                                    ({hiddenSelectedTicketsCount.toLocaleString()} hidden)
                                </span>
                            ) : null}
                        </span>
                        <BorderButton
                            className={styles.multiselectStatusClearBtn}
                            content="Clear"
                            flush
                            instrumentation={{
                                elementName: "board.board_header.clear_selection",
                            }}
                            minimal
                            onClick={cancelSelection}
                            small
                            tight
                            useHoverEffect={false}
                        />
                    </>
                )}
            </BoardStatusToast>

            {isQuickEditsEnabled ? (
                <EditTicketsToast
                    boardId={board.id}
                    onClose={cancelSelection}
                    isOpen={selection.mode === Enums.MultiselectMode.ACTIVE}
                    ticketIds={Array.from(selection.ticketIds)}
                />
            ) : null}
        </>
    );
}

function BoardHeader() {
    const breakpoints = useBreakpoints();
    const {
        board: _boardFragment,
        isArchiveVisible,
        isTrashVisible,
        openEditBoardDialog,
        tickets,
    } = useBoardView();
    const board = getFragmentData(fragments.board, _boardFragment);
    const { filterExpression, onFilterExpressionChange } = useBoardViewFilter();
    const newTicketDialog = useNewTicketDialog();

    return (
        <div className={classNames(styles.boardHeader)}>
            <div className={styles.toastPosition}>
                <MultiselectToast />
            </div>
            <div className={classNames(styles.wrapper, styles.leftWrapper)}>
                <BoardHeaderName
                    boardDisplayName={board.display_name}
                    boardAccessType={board.access_type as CommonEnumValue<"BoardAccessType">}
                    onClick={openEditBoardDialog}
                    isArchived={!!board.archived_at}
                />
            </div>

            <div className={classNames(styles.wrapper, styles.rightWrapper)}>
                <div className={styles.boardControls}>
                    {isArchiveVisible || isTrashVisible ? null : (
                        <BoardFilter
                            className={styles.filter}
                            board={board}
                            space="BOARD"
                            filterExpression={filterExpression}
                            onChange={onFilterExpressionChange}
                            matchCount={breakpoints.smMin ? tickets.length : undefined}
                        />
                    )}
                    <div className={styles.boardStyleControls}>
                        <ViewAsListButton />
                        <ViewAsColumnsButton />
                    </div>
                    <CreateNewTicketButton
                        onClick={() => newTicketDialog.openWithProps({ board })}
                    />
                    <BoardMenu />
                </div>
            </div>
        </div>
    );
}

export function Board() {
    const {
        board: _boardFragment,
        handleBeforeCapture,
        handleDragEnd,
        isArchiveVisible,
        isTrashVisible,
        showListView,
    } = useBoardView();
    useBoardViewDocumentTitle();
    const board = getFragmentData(fragments.board, _boardFragment);

    const {
        cancelSelection,
        selection,
        selectHovered,
        setSelectionMode,
    } = useTicketSelectionContext();

    const { isFeatureEnabled } = useFeatureFlags();
    const isQuickEditsEnabled = isFeatureEnabled({ feature: Enums.Feature.QUICK_EDITS });

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (
                e.key === "Shift" &&
                !(e.ctrlKey || e.altKey || e.metaKey) &&
                isKeyboardShortcutEligible(e)
            ) {
                setSelectionMode({
                    mode: Enums.MultiselectMode.STARTING,
                    ifMode: Enums.MultiselectMode.OFF,
                });
            } else if (e.key !== "Shift" && e.shiftKey) {
                setSelectionMode({
                    mode: Enums.MultiselectMode.OFF,
                    ifMode: Enums.MultiselectMode.STARTING,
                });
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === "Shift" && isKeyboardShortcutEligible(e)) {
                setSelectionMode({
                    mode: Enums.MultiselectMode.OFF,
                    ifMode: Enums.MultiselectMode.STARTING,
                });
            }
        };

        // The above listeners correctly track the Shift key state if the window remains
        // focused. But a user could hold Shift, then blur the window (while holding
        // Shift), then let go of Shift, then refocus the window. Without special handling,
        // we would mistakenly think Shift is still being held. Here, we just assume that
        // when the window is refocused, Shift is probably not being held.
        const handleWindowFocus = () => {
            setSelectionMode({
                mode: Enums.MultiselectMode.OFF,
                ifMode: Enums.MultiselectMode.STARTING,
            });
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
        window.addEventListener("focus", handleWindowFocus);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
            window.removeEventListener("focus", handleWindowFocus);
        };
    }, [setSelectionMode]);

    const handleSelectionStart = useCallback(() => {
        selectHovered();
        setSelectionMode({
            mode: Enums.MultiselectMode.STARTING,
            ifMode: Enums.MultiselectMode.OFF,
        });
    }, [selectHovered, setSelectionMode]);

    const handleSelectionCancel = useCallback(() => {
        cancelSelection();

        if (isHTMLElement(document.activeElement)) {
            document.activeElement?.blur();
        }
    }, [cancelSelection]);

    useHotkey("E", () => handleSelectionStart(), { enabled: isQuickEditsEnabled }, [
        handleSelectionStart,
    ]);
    useHotkey(
        "Esc",
        handleSelectionCancel,
        { enabled: selection.mode !== Enums.MultiselectMode.OFF },
        [handleSelectionCancel]
    );

    return (
        <div className={styles.board}>
            <UserDispositionBanner board={board} />
            <BoardHeader />
            <DragDropContext onBeforeCapture={handleBeforeCapture} onDragEnd={handleDragEnd}>
                {showListView ? <ListView /> : <Columns />}
            </DragDropContext>
            <SideDrawer isOpen={isArchiveVisible} width={660}>
                <Archive />
            </SideDrawer>
            <SideDrawer isOpen={isTrashVisible} width={660}>
                <Trash />
            </SideDrawer>
        </div>
    );
}
