import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useDeleteTasklist() {
    const { replicache } = useReplicache();

    const deleteTasklist = useCallback(
        async ({ tasklistId }: { tasklistId: string }) => {
            await replicache.mutate.deleteTasklist({ tasklistId });
        },
        [replicache]
    );

    return { deleteTasklist };
}
