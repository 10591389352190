import { useCallback, useMemo } from "react";

type AbstractName = "space" | "workflowStage" | "workItem";

export const useNomenclature = () => {
    const nomenclature = useMemo((): Record<AbstractName, { singular: string; plural: string }> => {
        return {
            space: {
                singular: "Workspace",
                plural: "Workspaces",
            },
            workflowStage: {
                singular: "Stage",
                plural: "Stages",
            },
            workItem: {
                singular: "Topic",
                plural: "Topics",
            },
        };
    }, []);

    return { nomenclature };
};

export const useBuildUnauthorizedDisplayName = () => {
    const { nomenclature } = useNomenclature();

    const buildUnauthorizedDisplayName = useCallback(
        ({ abstractName }: { abstractName: AbstractName }) => {
            return `<Missing ${nomenclature[abstractName].singular.toLowerCase()}>`;
        },
        [nomenclature]
    );

    return { buildUnauthorizedDisplayName };
};
