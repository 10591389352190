import React from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import styles from "./BillingSubsectionSpinner.module.scss";

export type BillingSubsectionSpinnerProps = {
    className?: string;
};

export function BillingSubsectionSpinner({ className }: BillingSubsectionSpinnerProps) {
    return <Spinner className={classNames(className, styles.billingSubsectionSpinner)} size={32} />;
}
