import React from "react";

import classNames from "classnames";

import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { useStartWatchingTicket, useStopWatchingTicket } from "lib/mutations";

import styles from "./WatchingToggle.module.scss";
import { useDetailView } from "../context/DetailViewContext";

type WatchingToggleDisplayProps = {
    className?: string;
    disabled?: boolean;
    isWatching: boolean;
    onClick?: () => void;
    ticketId: string;
    tooltipText: string;
};

function WatchingToggleDisplay({
    className,
    disabled,
    isWatching,
    onClick = () => undefined,
    ticketId,
    tooltipText,
}: WatchingToggleDisplayProps) {
    return (
        <div className={classNames(className, styles.watchingToggle)}>
            <Tooltip
                content={tooltipText}
                placement="bottom-end"
                small
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [10, 2],
                        },
                    },
                }}
            >
                <BorderAnchorButton
                    className={styles.watchingToggle}
                    disabled={disabled}
                    minimal
                    instrumentation={{
                        elementName: "ticket_detail.watch_toggle",
                        eventData: { ticketId, checked: !isWatching },
                    }}
                    content={
                        <Icon
                            icon={isWatching ? "eye" : "eye-off"}
                            iconSet="lucide"
                            iconSize={18}
                        />
                    }
                    onClick={onClick}
                />
            </Tooltip>
        </div>
    );
}

WatchingToggle.Display = WatchingToggleDisplay;

type WatchingToggleProps = {
    className?: string;
};

export function WatchingToggle({ className }: WatchingToggleProps) {
    const {
        isCurrentUserOwner,
        isCurrentUserMember,
        isCurrentUserWatcher,
        ticketId,
    } = useDetailView();
    const { startWatchingTicket } = useStartWatchingTicket();
    const { stopWatchingTicket } = useStopWatchingTicket();

    const toggleWatching = async () => {
        if (isCurrentUserWatcher) {
            await stopWatchingTicket({ ticketId });
        } else {
            await startWatchingTicket({ ticketId });
        }
    };

    const disabled = isCurrentUserOwner || isCurrentUserMember;
    const tooltipText = isCurrentUserOwner
        ? "You're subscribed to notifications because you're the owner."
        : isCurrentUserMember
        ? "You're subscribed to notifications because you're a collaborator."
        : isCurrentUserWatcher
        ? "Unsubscribe from notifications"
        : "Subscribe to notifications";

    return (
        <WatchingToggle.Display
            className={className}
            disabled={disabled}
            onClick={toggleWatching}
            isWatching={isCurrentUserOwner || isCurrentUserMember || isCurrentUserWatcher}
            ticketId={ticketId}
            tooltipText={tooltipText}
        />
    );
}
