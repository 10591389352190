import React from "react";

import classNames from "classnames";

import styles from "./ProgressBar.module.scss";

export type ProgressBarProps = {
    /** Whether the bar should animate when the value changes. */
    animate?: boolean;

    /** Optional class name. */
    className?: string;

    /** Whether the bar should fill the width of the parent container. */
    fill?: boolean;

    /** Optional height in pixels. */
    heightPx?: number;

    /** Number between 0 and 100. */
    value: number;
};

export function ProgressBar({ animate, className, fill, heightPx = 2, value }: ProgressBarProps) {
    const clampedValue = Math.round(Math.max(0, Math.min(100, value || 0)));

    return (
        <div
            className={classNames(
                className,
                styles.progressBar,
                animate && styles.animate,
                fill && styles.fill,
                clampedValue === 100 && styles.complete
            )}
            role="progressbar"
            style={{ height: `${heightPx}px` }}
        >
            <div className={styles.meter} style={{ width: `${clampedValue}%` }} />
        </div>
    );
}
