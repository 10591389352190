import { useEffect, useRef } from "react";

import { AppData } from "AppData";
import { Config } from "Config";
import { createDeepLink } from "lib/DeepLinks";
import { Enums } from "lib/Enums";
import { OAuth } from "lib/OAuth";
import { useHistory } from "lib/Routing";

export function OAuthRedirect() {
    const { history } = useHistory();
    const didAttempt = useRef(false);

    useEffect(() => {
        if (didAttempt.current) {
            return;
        }

        didAttempt.current = true;

        const urlSearchParams = new URLSearchParams(history.location.search);
        const state = urlSearchParams.get("state");

        if (!state) {
            history.replace("/");
            return;
        }

        const decodedState = OAuth.decodeState({ state });

        if (
            decodedState.appType === Enums.AppType.DESKTOP &&
            Config.appType !== Enums.AppType.DESKTOP
        ) {
            AppData.redirectToDeepLinkUrl = createDeepLink(history.location);
            history.replace("/desktop/open");
        } else {
            history.replace({
                pathname: {
                    [Enums.OAuthKey.DISCORD]: "/oauth/redirect/discord",
                    [Enums.OAuthKey.GITHUB]: "/oauth/redirect/github",
                    [Enums.OAuthKey.SLACK]: "/oauth/redirect/slack",
                    [Enums.OAuthKey.TRELLO]: "/oauth/redirect/trello",
                }[decodedState.key],
                search: history.location.search,
                hash: history.location.hash,
            });
        }
    }, [history]);

    return null;
}
