import { markInputRule, markPasteRule } from "@tiptap/core";
import ExtensionStrike from "@tiptap/extension-strike";

export const regexes = [
    /(?:^|\s)((?:~)((?:[^~]+))(?:~))$/gm,
    /(?:^|\s)((?:~~)((?:[^~]+))(?:~~))$/gm,
];

export default ExtensionStrike.extend({
    addInputRules() {
        return regexes.map(regex => markInputRule({ find: regex, type: this.type }));
    },

    addPasteRules() {
        return regexes.map(regex => markPasteRule({ find: regex, type: this.type }));
    },
});
