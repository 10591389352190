import React, { useCallback } from "react";

import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";

type CheckboxInstrumentationEvent = Omit<InstrumentationEvent, "eventType">;

export type AbstractCheckboxProps = {
    children?: React.ReactNode;
    className?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    instrumentation: CheckboxInstrumentationEvent | null;
} & React.ComponentPropsWithoutRef<"input">;

export function AbstractCheckbox({
    children,
    className,
    inputRef,
    instrumentation,

    ...htmlInputProps
}: AbstractCheckboxProps) {
    const { onChange } = htmlInputProps;
    const safeOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);
        },
        [onChange]
    );

    const { recordCallback } = useInstrumentation();
    const wrappedOnChange = recordCallback(
        {
            eventType: instrumentation?.elementName ? Enums.InstrumentationEvent.CLICK : null,
            elementName: instrumentation?.elementName,
            eventData: instrumentation?.eventData,
            dedupeKey: Date.now(),
        },
        safeOnChange
    );

    return (
        <label
            className={className}
            onClick={event => {
                // When the content of the checkbox children is clicked, we don't want *that* event
                // to bubble up. Instead, we want this label element to handle that event, thereby
                // triggering an event on the input element, and for *that* event to bubble up.
                event.stopPropagation();
            }}
        >
            <input {...htmlInputProps} type="checkbox" onChange={wrappedOnChange} ref={inputRef} />
            {children}
        </label>
    );
}
