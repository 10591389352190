import { useCallback, useMemo } from "react";

import { TLabel } from "c9r-common";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useCurrentUser } from "contexts/UserContext";
import { Queries } from "lib/Queries";
import { useRecordTicketView } from "lib/TicketViews";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { useCreateTicket } from "lib/mutations";

/**
 * Represents the methods and states used for "add ticket anywhere", where a user can click
 * the board or list and an entry form pops open.
 *
 * Only one new ticket entry can be open at a time.
 */

type NewTicketEntryContent = {
    title: string;
    labels: TLabel[];
    ownerUserId: number | null;
    dueDate: Date | null;
};

const defaultContent: NewTicketEntryContent = {
    title: "",
    labels: [],
    ownerUserId: null,
    dueDate: null,
};

type NewTicketEntryPlacement =
    | {
          isPlaced: true;
          boardId: string;
          stageId: string;
          stagePos: number;
          lastClearedAt: number;
      }
    | {
          isPlaced: false;
          boardId: null;
          stageId: null;
          stagePos: null;
          lastClearedAt: number;
      };

const EPOCH = 0;

const defaultPlacement: NewTicketEntryPlacement = {
    isPlaced: false,
    boardId: null,
    stageId: null,
    stagePos: null,
    lastClearedAt: EPOCH,
};

export const newTicketEntryContentState = atom<NewTicketEntryContent>({
    key: "NewTicketEntryContent",
    default: defaultContent,
});

export const newTicketEntryPlacementState = atom<NewTicketEntryPlacement>({
    key: "NewTicketEntryPlacement",
    default: defaultPlacement,
});

export function useNewTicketEntry() {
    const currentUser = useCurrentUser();
    const setNewTicketEntryContentState = useSetRecoilState(newTicketEntryContentState);
    const setNewTicketEntryPlacementState = useSetRecoilState(newTicketEntryPlacementState);
    const { cancelSelection } = useTicketSelectionContext();

    const clear = useCallback(() => {
        setNewTicketEntryContentState(defaultContent);
        setNewTicketEntryPlacementState(prev => ({
            ...defaultPlacement,
            lastClearedAt: Date.now(),
        }));
    }, [setNewTicketEntryContentState, setNewTicketEntryPlacementState]);

    const setContent = useCallback(
        (newContent: Partial<NewTicketEntryContent>) => {
            setNewTicketEntryContentState(prev => ({ ...prev, ...newContent }));
        },
        [setNewTicketEntryContentState]
    );

    const moveTo = useCallback(
        ({
            boardId,
            stageId,
            stagePos,
        }: {
            boardId: string;
            stageId: string;
            stagePos: number;
        }) => {
            setNewTicketEntryPlacementState(prev => ({
                isPlaced: true,
                boardId,
                stageId,
                stagePos,
                lastClearedAt: prev.lastClearedAt,
            }));
        },
        [setNewTicketEntryPlacementState]
    );

    const openAt = useCallback(
        ({
            boardId,
            stageId,
            stagePos,
        }: {
            boardId: string;
            stageId: string;
            stagePos: number;
        }) => {
            cancelSelection();

            setNewTicketEntryContentState({
                ...defaultContent,
                ownerUserId: currentUser.org.is_multi_user ? null : currentUser.id,
            });

            setNewTicketEntryPlacementState(prev => ({
                isPlaced: true,
                boardId,
                stageId,
                stagePos,
                lastClearedAt: prev.lastClearedAt,
            }));
        },
        [
            cancelSelection,
            currentUser.id,
            currentUser.org.is_multi_user,
            setNewTicketEntryContentState,
            setNewTicketEntryPlacementState,
        ]
    );

    const value = useMemo(
        () => ({
            clear,
            setContent,
            moveTo,
            openAt,
        }),
        [clear, setContent, moveTo, openAt]
    );

    return value;
}

export function useNewTicketEntryContent() {
    return useRecoilValue(newTicketEntryContentState);
}

export function useNewTicketEntryPlacement() {
    return useRecoilValue(newTicketEntryPlacementState);
}

export function useNewTicketEntryMutations() {
    const currentUser = useCurrentUser();
    const prefetchQuery = usePrefetchQuery();
    const { recordTicketView } = useRecordTicketView();
    const newTicketEntry = useNewTicketEntry();
    const newTicketEntryContent = useNewTicketEntryContent();
    const newTicketEntryPlacement = useNewTicketEntryPlacement();
    const { createTicket } = useCreateTicket();

    const handleCreateTicket = useCallback(async () => {
        if (!newTicketEntryPlacement.isPlaced) {
            return null;
        }

        const newMinimalTicket = await createTicket({
            boardId: newTicketEntryPlacement.boardId,
            stageId: newTicketEntryPlacement.stageId,
            stagePos: newTicketEntryPlacement.stagePos,
            title: newTicketEntryContent.title,
            labels: newTicketEntryContent.labels.map(({ color, text }) => ({
                color,
                text,
            })),
            ownerUserId: newTicketEntryContent.ownerUserId ?? undefined,
            makeCurrentUserWatcher: true,
            dueDate: newTicketEntryContent.dueDate ?? undefined,
        });

        if (!newMinimalTicket) {
            return null;
        }

        newTicketEntry.clear();

        const { ref: ticketRef, id: ticketId } = newMinimalTicket;

        void recordTicketView({ ticketId });

        // Cache details page in case the user immediately navigates to it.
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref: ticketRef,
            },
        });

        return newMinimalTicket;
    }, [
        createTicket,
        currentUser,
        newTicketEntry,
        newTicketEntryContent,
        newTicketEntryPlacement,
        prefetchQuery,
        recordTicketView,
    ]);

    const value = useMemo(
        () => ({
            createTicket: handleCreateTicket,
        }),
        [handleCreateTicket]
    );

    return value;
}
