import React, { useRef, useState } from "react";

import { Errors } from "c9r-common";
import classNames from "classnames";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import isEmail from "validator/lib/isEmail";

import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { AbstractTextInput } from "components/ui/core/abstract/AbstractTextInput";
import { useAsyncWatcher } from "lib/Hooks";
import { Log } from "lib/Log";

import styles from "./DemoCtaForm.module.scss";

export type DemoCtaFormProps = {
    className?: string;
    inlineControls?: boolean;
};

/**
 * SEE IMPORTANT NOTE IN DemoIntro.tsx BEFORE CHANGING THIS COMPONENT
 */
export function DemoCtaForm({ className, inlineControls }: DemoCtaFormProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [didSubmit, setDidSubmit] = useState(false);
    const [showError, setShowError] = useState(false);
    const submission = useAsyncWatcher();

    const handleSubmit = async () => {
        if (submission.isInFlight) {
            return;
        }

        if (!emailAddress || !isEmail(emailAddress)) {
            setShowError(true);
            inputRef.current?.focus();
            return;
        }

        try {
            const response = await fetch(
                "https://api.hsforms.com/submissions/v3/integration/submit/44248469/45e7d8ce-6294-4332-90b2-8a886b8c1517",
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        fields: [
                            {
                                objectTypeId: "0-1",
                                name: "email",
                                value: emailAddress,
                            },
                        ],
                        context: {
                            pageName: "Demo",
                        },
                    }),
                }
            );

            if (!response.ok) {
                throw new Errors.UnexpectedCaseError({
                    status: response.status,
                    text: await response.text(),
                });
            }

            await new Promise(resolve => setTimeout(resolve, 1000));

            setDidSubmit(true);
        } catch (error) {
            Log.error("Failed to submit demo signup request", { error, emailAddress });

            AppToaster.error({
                message: "Sorry, something went wrong.",
            });
        }
    };

    return (
        <form
            className={classNames(className, styles.form)}
            onSubmit={e => {
                e.preventDefault();
                void submission.watch(handleSubmit)();
            }}
        >
            <SwitchTransition>
                <CSSTransition
                    classNames={{
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                    }}
                    key={didSubmit ? "submitted" : "caption"}
                    in
                    timeout={300}
                >
                    {didSubmit ? (
                        <span className={styles.submitMsg}>
                            Thanks for your interest! We'll reach out to you soon.
                        </span>
                    ) : (
                        <span className={styles.caption}>
                            Flat is currently in public beta. Sign up to get your invitation.
                        </span>
                    )}
                </CSSTransition>
            </SwitchTransition>

            <div
                className={classNames(
                    styles.formControls,
                    inlineControls && styles.formControlsInline
                )}
            >
                <AbstractTextInput
                    className={classNames(styles.ctaInput, showError && styles.ctaInputError)}
                    disabled={didSubmit}
                    inputRef={inputRef}
                    onChange={e => {
                        setShowError(false);
                        setEmailAddress(e.target.value);
                    }}
                    placeholder="Email address"
                    value={emailAddress}
                />
                <BorderButton
                    brandFlatFancyCta
                    className={styles.ctaButton}
                    content="Get early access"
                    disabled={didSubmit}
                    iconGap={6}
                    instrumentation={{
                        elementName: "demo_footer.cta_btn",
                    }}
                    leftIconProps={{
                        icon: "rocket",
                        iconSet: "lucide",
                        iconSize: 24,
                        strokeWidth: 1,
                    }}
                    loading={submission.isInFlight}
                    type="submit"
                />
            </div>
        </form>
    );
}
