import React, { useEffect } from "react";

import { Switch, useRouteMatch } from "react-router-dom";

import { useSetIsViewReady } from "components/loading/Loading";

import { AppRoute } from "./AppRoute";
import { DiscordOAuth2Redirect } from "./redirects/external/DiscordOAuth2Redirect";
import { GitHubOAuth2Redirect } from "./redirects/external/GitHubOAuth2Redirect";
import { SlackOAuth2Redirect } from "./redirects/external/SlackOAuth2Redirect";
import { TrelloAuthRedirect } from "./redirects/external/TrelloAuthRedirect";

export function AuthRedirectRoutes() {
    const setIsViewReady = useSetIsViewReady();
    const { path } = useRouteMatch();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    return (
        <Switch>
            <AppRoute path={`${path}/discord`}>
                <DiscordOAuth2Redirect />
            </AppRoute>

            <AppRoute path={`${path}/github`}>
                <GitHubOAuth2Redirect />
            </AppRoute>

            <AppRoute path={`${path}/slack`}>
                <SlackOAuth2Redirect />
            </AppRoute>

            <AppRoute path={`${path}/trello`}>
                <TrelloAuthRedirect />
            </AppRoute>
        </Switch>
    );
}
