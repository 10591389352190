import React from "react";

import classNames from "classnames";

import { Config } from "Config";
import { DemoFooter } from "components/demo/DemoFooter";
import { QueryLoader, TQueryResult } from "components/loading/QueryLoader";
import { useCurrentUser } from "contexts/UserContext";
import { useSemanticBreakpoints } from "lib/Breakpoints";
import { CssClasses, ProductTourElementClasses } from "lib/Constants";
import { Queries } from "lib/Queries";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { AppLayoutQuery } from "lib/graphql/__generated__/graphql";
import { AppLayoutProvider } from "views/layouts/app/AppLayoutContext";
import { NavBar } from "views/layouts/app/navbar/NavBar";
import { SideNav } from "views/layouts/app/sidenav/SideNav";

import styles from "./AppLayout.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment AppLayout_org on orgs {
            id

            ...NavBar_org
            ...SideNav_org
        }
    `),
};

export type AppLayoutProps = {
    children: React.ReactNode;
};

export function AppLayoutImpl({
    children,
    queryResult,
}: AppLayoutProps & { queryResult: TQueryResult<AppLayoutQuery> }) {
    const { isSideNavEnabled } = useSemanticBreakpoints();

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    const org = getFragmentData(fragments.org, queryResult.data?.org) ?? undefined;

    return (
        <>
            <div id={styles.layout} className={classNames(CssClasses.LAYOUT_ROOT)}>
                <div className={styles.banner} />
                <div className={classNames(styles.layoutContent, CssClasses.LAYOUT_ROOT)}>
                    <NavBar className={styles.navbar} org={org} />
                    {isSideNavEnabled ? (
                        <SideNav className={styles.sideNav} org={org} />
                    ) : (
                        <div className={styles.sideNav} />
                    )}

                    <div className={styles.main}>
                        {
                            // Only render main content once the sidenav and navbar have rendered
                            // This ensures that the navbar and sidenav appear immediately, even if the
                            // main content does an expensive query.
                            org ? children : null
                        }
                    </div>
                </div>
                {Config.demoUI.enabled ? (
                    <DemoFooter className={styles.footer} />
                ) : (
                    <div className={styles.footer} />
                )}
            </div>
            <div
                className={classNames(styles.highlightTarget, ProductTourElementClasses.LEFT_SIDE)}
            />
        </>
    );
}

/**
 * Main layout of the app. Pass children as the main "view" of the app (e.g., board page,
 * ticket details).
 */
export function AppLayout({ children }: AppLayoutProps) {
    const currentUser = useCurrentUser();

    return (
        <AppLayoutProvider>
            <QueryLoader
                query={AppLayout.queries.component}
                variables={{ orgId: currentUser.org_id }}
            >
                {({ queryResult }) => (
                    <AppLayoutImpl queryResult={queryResult}>{children}</AppLayoutImpl>
                )}
            </QueryLoader>
        </AppLayoutProvider>
    );
}

AppLayout.queries = {
    component: gql(/* GraphQL */ `
        query AppLayout($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                ...AppLayout_org
            }
        }
    `),
};

Queries.register({ component: "AppLayout", gqlMapByName: AppLayout.queries });
