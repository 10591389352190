import { useCallback } from "react";

import { TLabel } from "c9r-common";

import { useReplicache } from "lib/replicache/Context";

export function useUpdateLabel() {
    const { replicache } = useReplicache();

    const updateLabel = useCallback(
        async ({
            boardId,
            oldLabel,
            newLabel,
        }: {
            boardId: string;
            oldLabel: TLabel;
            newLabel: TLabel;
        }) => {
            await replicache.mutate.updateLabel({ boardId, oldLabel, newLabel });
        },
        [replicache]
    );

    return { updateLabel };
}

export function useDeleteLabel() {
    const { replicache } = useReplicache();

    const deleteLabel = useCallback(
        async ({ boardId, label }: { boardId: string; label: TLabel }) => {
            await replicache.mutate.deleteLabel({ boardId, label });
        },
        [replicache]
    );

    return { deleteLabel };
}

export function useUndeleteLabel() {
    const { replicache } = useReplicache();

    const undeleteLabel = useCallback(
        async ({ boardId, label }: { boardId: string; label: TLabel }) => {
            await replicache.mutate.undeleteLabel({ boardId, label });
        },
        [replicache]
    );

    return { undeleteLabel };
}
