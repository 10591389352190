import React, { useCallback, useEffect, useRef, useState } from "react";

import { Editor as TEditor } from "@tiptap/react";
import { ValueOf } from "c9r-common";
import classNames from "classnames";
import stringify from "fast-json-stable-stringify";
import hash from "object-hash";

import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Editor, EditorProps, EditorToolbarType } from "components/ui/editor/Editor";
import { EditorToolbarTooltip } from "components/ui/editor/EditorToolbar";
import { useBreakpoints } from "lib/Breakpoints";
import { DraftEnums, createDraftStorageKey, useDraft } from "lib/Drafts";
import { Enums } from "lib/Enums";
import { isMac } from "lib/OS";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useUpdateTicketDescription } from "lib/mutations";
import { TRichTextContentSerializers } from "lib/types/common/richText";

import styles from "./DescriptionEditor.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DescriptionEditor_ticket on tickets {
            id
            description_json

            board {
                id

                ...Editor_board
            }
        }
    `),
};

const linkUnfurlTypesToDisplay = Object.values(Enums.LinkUnfurlType);

export type DescriptionEditorProps = {
    autoFocus?: boolean;
    className?: string;
    editorToolbarType?: ValueOf<typeof EditorToolbarType>;
    onCancel?: () => void;
    onSave?: () => void;
    ticket: FragmentType<typeof fragments.ticket>;
} & Pick<EditorProps, "handleAttachmentUpload">;

export function DescriptionEditor({
    autoFocus,
    className,
    handleAttachmentUpload,
    onCancel,
    onSave,
    ticket: _ticketFragment,
}: DescriptionEditorProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const breakpoints = useBreakpoints();
    const { loadDraft, discardDraft, throttledSaveDraft } = useDraft({
        localStorageKey: createDraftStorageKey({
            entityType: DraftEnums.EntityTypes.TICKET,
            entityId: ticket.id,
            draftEntityType: DraftEnums.DraftEntityTypes.DESCRIPTION,
        }),
    });
    const draft = loadDraft();
    const [content, setContent] = useState(draft?.content || ticket.description_json);
    const contentAtDraftCreation = useRef(draft?.contentAtCreation || ticket.description_json);
    const [isDirty, setIsDirty] = useState(!!draft);
    const serializers = useRef<TRichTextContentSerializers | null>(null);
    const { updateTicketDescription } = useUpdateTicketDescription();

    const ticketId = ticket.id;

    // Handle the case where the description is independently changed by some other user. As
    // long as this user hasn't made any changes (not dirty), we should update.
    useEffect(() => {
        if (!isDirty) {
            setContent(loadDraft()?.content ?? ticket.description_json);
            contentAtDraftCreation.current = ticket.description_json;
        }
    }, [isDirty, loadDraft, ticket.id, ticket.description_json]);

    const handleCreate = useCallback((newSerializers: TRichTextContentSerializers) => {
        serializers.current = newSerializers;
    }, []);

    const handleUpdate = useCallback(() => {
        if (serializers.current) {
            throttledSaveDraft.callback({
                draft: {
                    content: serializers.current.getJSON(),
                    timestamp: loadDraft()?.timestamp ?? new Date().getTime(),
                    contentAtCreation: contentAtDraftCreation.current,
                },
            });
        }

        setIsDirty(true);
    }, [throttledSaveDraft, loadDraft]);

    const handleCancel = useCallback(() => {
        setIsDirty(false);
        discardDraft();

        // Always create a fresh content object to force the Editor to recognize that the
        // content has changed.
        setContent(JSON.parse(JSON.stringify(ticket.description_json)) || { type: "doc" });

        onCancel?.();
    }, [discardDraft, onCancel, ticket.description_json]);

    const handleClickBelow = useCallback(
        ({ editor }: { editor: TEditor }) => {
            if (content && !isDirty) {
                editor.chain().focus("end").enter().run();
            }
        },
        [content, isDirty]
    );

    const handleSave = useCallback(async () => {
        if (!serializers.current) {
            return;
        }

        // Descriptions should be null if it's empty.
        // This means we don't have descriptions that are just lots of empty paragraphs.
        const isPopulated = serializers.current.isPopulated();
        const descriptionJSON = isPopulated ? serializers.current.getJSON() : null;

        // Discard draft first, so that after the optimistic response, we don't accidentally
        // briefly re-render with the draft.
        discardDraft();

        void updateTicketDescription({
            ticketId,
            serializers: serializers.current,
            // As of July 2023, hashing `contentAtDraftCreation.current` directly was generating
            // false positive ticket overwrite detections in one dev's local env. We were not able
            // to determine the cause and decided to deterministically stringify
            // `contentAtDraftCreation.current` before hashing. `JSON.stringify` is not
            // deterministic, so we decided to use "fast-json-stable-stringify".
            previousDescriptionDigest: hash(stringify(contentAtDraftCreation.current)),
        });

        // Refresh content - clears any selected content.
        setContent(JSON.parse(JSON.stringify(descriptionJSON)) || { type: "doc" });
        setIsDirty(false);

        onSave?.();
    }, [discardDraft, onSave, ticketId, updateTicketDescription]);

    const toolbarRightElement = isDirty ? (
        <div className={styles.headerActionsWrapper}>
            <BorderButton
                // Quick hack: Ensure the "discard/cancel" text doesn't overlap the toolbar
                // on narrow devices.
                content={breakpoints.smMin ? "Discard changes" : "Cancel"}
                instrumentation={{
                    elementName: "description_editor.discard_btn",
                }}
                small
                minimal
                onClick={handleCancel}
            />
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Publish changes</div>
                        <div>
                            {" "}
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text="Enter" />
                        </div>
                    </>
                }
                disabled={!isDirty}
            >
                <BorderButton
                    brandCta
                    content="Done"
                    instrumentation={{
                        elementName: "description_editor.done_btn",
                        eventData: {
                            ticketId,
                            draftTimestamp: draft?.timestamp,
                        },
                    }}
                    disabled={!isDirty}
                    primary
                    small
                    onClick={handleSave}
                />
            </EditorToolbarTooltip>
        </div>
    ) : null;

    return (
        <div className={classNames(className, styles.descriptionEditor)}>
            <Editor
                autoFocus={autoFocus}
                board={ticket.board}
                className={styles.editor}
                clickBelowProps={{
                    targetClassName: styles.editorClickBelowTarget,
                    targetOnClick: handleClickBelow,
                }}
                content={content}
                editorContentClassName={classNames(
                    styles.editorContent,
                    isDirty && styles.editorContentDirty
                )}
                emoji
                handleAttachmentUpload={handleAttachmentUpload}
                images
                isDirty={isDirty}
                linkUnfurlTypes={linkUnfurlTypesToDisplay}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onKeyboardSubmit={handleSave}
                placeholderText="Write a description..."
                ticketReferences
                toolbarClassName={styles.editorToolbar}
                toolbarType={isDirty ? EditorToolbarType.STICKY_PALETTE : undefined}
                toolbarRightElement={isDirty ? toolbarRightElement : null}
            />
        </div>
    );
}
