import React from "react";

import classNames from "classnames";

import { Config } from "Config";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { BorderButton } from "components/ui/core/BorderButton";
import { TextButton } from "components/ui/core/TextButton";
import AppIcon from "img/appIcon.png";
import { parsedUserAgent } from "lib/UserAgent";

import styles from "./DownloadAppScreen.module.scss";
import { ContentBox } from "../ui/ContentBox";

export type DownloadAppScreenProps = {
    className?: string;
    onComplete: () => void;
};

export function DownloadAppScreen({ className, onComplete }: DownloadAppScreenProps) {
    const {
        os: { isMac, isWindows },
        device: { isDesktop },
    } = parsedUserAgent;

    const {
        headerClassName,
        headerText,
        subheaderText,
        ctaOpenNewTab,
        ctaShowDownloadIcon,
        ctaText,
        ctaUrl,
        showAvailabilityText,
        skipText,
    } = (() => {
        if (!isDesktop) {
            return {
                headerClassName: styles.headerWeak,
                headerText: "When you're back at your computer, download the Flat desktop app.",
                subheaderText: "Escape the clutter of a hundred browser tabs.",
                ctaOpenNewTab: false,
                ctaShowDownloadIcon: false,
                ctaText: "Got it",
                ctaUrl: undefined,
                showAvailabilityText: true,
                skipText: undefined,
            };
        }

        const defaultHeaderText = "We recommend using the Flat app.";
        const defaultSubheaderText = "Escape the clutter of a hundred browser tabs.";

        if (isMac) {
            return {
                headerClassName: styles.headerStrong,
                headerText: defaultHeaderText,
                subheaderText: defaultSubheaderText,
                ctaOpenNewTab: false,
                ctaShowDownloadIcon: true,
                ctaText: "Download for macOS",
                ctaUrl: Config.urls.app.direct.mac,
                showAvailabilityText: false,
                skipText: "Skip and continue in browser",
            };
        }

        if (isWindows) {
            return {
                headerClassName: styles.headerStrong,
                headerText: defaultHeaderText,
                subheaderText: defaultSubheaderText,
                ctaOpenNewTab: false,
                ctaShowDownloadIcon: true,
                ctaText: "Download for Windows",
                ctaUrl: Config.urls.app.direct.windows,
                showAvailabilityText: false,
                skipText: "Skip and continue in browser",
            };
        }

        return {
            headerClassName: styles.headerStrong,
            headerText: defaultHeaderText,
            subheaderText: defaultSubheaderText,
            ctaOpenNewTab: true,
            ctaShowDownloadIcon: true,
            ctaText: "Download",
            ctaUrl: Config.urls.app.fallback,
            showAvailabilityText: false,
            skipText: "Skip and continue in browser",
        };
    })();

    const ctaButtonProps = {
        className: styles.ctaBtn,
        content: ctaText,
        cta: true,
        fill: true,
        iconGap: 12,
        instrumentation: {
            elementName: ctaUrl
                ? "onboarding.download_app_btn"
                : "onboarding.download_app_later_btn",
        },
        leftIconProps: ctaShowDownloadIcon
            ? ({
                  icon: "download",
                  iconSet: "lucide",
                  iconSize: 26,
                  strokeWidth: 2,
              } as const)
            : undefined,
        large: true,
        target: ctaOpenNewTab ? "_blank" : undefined,
    };

    return (
        <ContentBox className={classNames(className, styles.contentBox)}>
            <div className={styles.screen}>
                <img alt="App Icon" src={AppIcon} width={68} />
                <div className={styles.text}>
                    <span className={headerClassName}>{headerText}</span>
                    <span>{subheaderText}</span>
                    {showAvailabilityText ? (
                        <span className={styles.availabilityText}>
                            Available for Mac, Windows, and Linux.
                        </span>
                    ) : null}
                </div>
                <div className={styles.buttons}>
                    {ctaUrl ? (
                        <BorderAnchorButton {...ctaButtonProps} href={ctaUrl} />
                    ) : (
                        <BorderButton {...ctaButtonProps} onClick={() => onComplete()} />
                    )}
                    {skipText ? (
                        <TextButton
                            className={styles.skipBtn}
                            link
                            text={skipText}
                            onClick={() => onComplete()}
                            instrumentation={{
                                elementName: "onboarding.download_app_skip_btn",
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </ContentBox>
    );
}
