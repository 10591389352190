import React from "react";

import classNames from "classnames";

import { Config } from "Config";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { useBreakpoints } from "lib/Breakpoints";

import { DemoCtaForm } from "./DemoCtaForm";
import styles from "./DemoFooter.module.scss";

export type DemoFooterProps = {
    className?: string;
};

export function DemoFooter({ className }: DemoFooterProps) {
    const breakpoints = useBreakpoints();

    return (
        <div className={classNames(className, styles.footer)}>
            <DemoCtaForm className={styles.form} inlineControls />
            {breakpoints.mdMax ? null : (
                <>
                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                    <a
                        role="link"
                        href={Config.urls.website}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BrandLogomark className={styles.logomark} height={32} />
                    </a>
                </>
            )}
        </div>
    );
}
