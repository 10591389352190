import React, { useEffect, useState } from "react";

import { FormGroup } from "@blueprintjs/core";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import isEmail from "validator/lib/isEmail";

import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { TextInput } from "components/ui/core/TextInput";
import { Enums } from "lib/Enums";
import { useAsyncWatcher, useDialog } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";

import { useChangeBillingDetails } from "./BillingMutations";
import {
    BillingSection,
    billingInfoSectionLastModifiedState,
    billingInfoState,
} from "./BillingState";
import { BillingSubsectionSpinner } from "./BillingSubsectionSpinner";
import styles from "./ContactInfoContent.module.scss";

type ContactInfoDialogProps = {
    billingEmailAddress?: string;
    isOpen: boolean;
    onChange: () => void;
    onClose: () => void;
};

function ContactInfoDialog({
    billingEmailAddress,
    isOpen,
    onChange,
    onClose,
}: ContactInfoDialogProps) {
    const [newEmailAddress, setNewEmailAddress] = useState(billingEmailAddress || "");
    const [isValid, setIsValid] = useState(true);
    const submission = useAsyncWatcher();
    const { recordEvent } = useInstrumentation();

    useEffect(() => {
        if (isOpen) {
            setNewEmailAddress(billingEmailAddress || "");
        }
    }, [isOpen, billingEmailAddress]);

    useEffect(() => {
        setIsValid(!!(newEmailAddress && isEmail(newEmailAddress)));
    }, [newEmailAddress]);

    const { changeBillingDetails } = useChangeBillingDetails();

    const handleSubmit = async () => {
        const emailAddress = newEmailAddress.trim();

        if (!emailAddress) {
            return;
        }

        await Promise.all([
            changeBillingDetails({ billingEmailAddress: emailAddress }),
            recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                elementName: "settings.billing_contact_dialog",
                dedupeKey: Date.now(),
            }),
        ]);
        await onChange?.();
        onClose();
        setNewEmailAddress("");
    };

    return (
        <Dialog
            title={billingEmailAddress ? "Update billing contact" : "Add billing contact"}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <FormGroup label="Email address" labelFor="new-email-address">
                    <TextInput
                        id="new-email-address"
                        autoFocus
                        fill
                        value={newEmailAddress}
                        onChange={e => setNewEmailAddress(e.target.value)}
                        onKeyboardSubmit={submission.watch(handleSubmit)}
                    />
                </FormGroup>
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton
                        disabled={submission.isInFlight}
                        content="Cancel"
                        onClick={onClose}
                        instrumentation={null}
                    />
                    <BorderButton
                        disabled={newEmailAddress?.trim() === billingEmailAddress || !isValid}
                        loading={submission.isInFlight}
                        content={billingEmailAddress ? "Update" : "Save"}
                        instrumentation={{
                            elementName: "settings.billing_contact_dialog.submit_btn",
                        }}
                        onClick={submission.watch(handleSubmit)}
                        primary
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}

export type ContactInfoContentProps = {
    className?: string;
    onChange: () => void;
};

export function ContactInfoContent({ className, onChange }: ContactInfoContentProps) {
    const billingInfo = useRecoilValue(billingInfoState);
    const billingInfoSectionLastModified = useRecoilValue(billingInfoSectionLastModifiedState);
    const dialog = useDialog();

    const billingEmailAddress = billingInfo?.billing_details?.billing_email_address;
    const contactInfoLastModifiedAt = billingInfoSectionLastModified[BillingSection.CONTACT_INFO];
    const isReady =
        billingInfo &&
        (!contactInfoLastModifiedAt ||
            (billingInfo.as_of && billingInfo.as_of > contactInfoLastModifiedAt));

    return (
        <>
            <section className={classNames(className, styles.contactInfoContent)}>
                {isReady ? (
                    <>
                        <span>{billingEmailAddress ?? "None"}</span>
                        <BorderButton
                            content={billingEmailAddress ? "Change" : "Add"}
                            instrumentation={{ elementName: "settings.change_billing_contact_btn" }}
                            onClick={dialog.open}
                            small
                        />
                    </>
                ) : (
                    <BillingSubsectionSpinner />
                )}
            </section>
            {billingInfo ? (
                <ContactInfoDialog
                    billingEmailAddress={billingEmailAddress}
                    isOpen={dialog.isOpen}
                    onChange={onChange}
                    onClose={dialog.close}
                />
            ) : null}
        </>
    );
}
