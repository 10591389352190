import React, { useEffect, useState } from "react";

import { CommonEnumValue, TicketReferenceBoard, authorizeTicketReference } from "c9r-common";

import { TSearchIndexTicket } from "components/search/TicketSearchIndex";
import {
    TicketMenuItemClassName,
    TicketMenuItemLayout,
    TicketMenuWrapper,
} from "components/shared/TicketMenu";
import {
    FilterableSelector,
    FilterableSelectorProps,
} from "components/ui/common/FilterableSelector";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuHeader } from "components/ui/core/MenuHeader";
import { MenuItem } from "components/ui/core/MenuItem";
import { useTicketSearch } from "contexts/TicketSearchContext";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { useMaybeControlledValue } from "lib/Hooks";
import { isDefined } from "lib/types/guards";

import styles from "./TicketSelector.module.scss";

export const TicketSelectorItemType = {
    TICKET: "TICKET",
    VIEW_FULL_RESULTS: "VIEW_FULL_RESULTS",
} as const;

type TicketSelectorTicketItem = {
    type: typeof TicketSelectorItemType["TICKET"];
    ticket: TSearchIndexTicket;
};

type TicketSelectorViewFullResultsItem = {
    type: typeof TicketSelectorItemType["VIEW_FULL_RESULTS"];
};

export type TicketSelectorItem = TicketSelectorTicketItem | TicketSelectorViewFullResultsItem;

export const TICKET_SELECTOR_VIEW_FULL_RESULTS_SENTINEL: TicketSelectorViewFullResultsItem = {
    type: TicketSelectorItemType.VIEW_FULL_RESULTS,
};

function areTicketSelectorItemsEqual(a: TicketSelectorItem, b: TicketSelectorItem) {
    return (
        (a.type === TicketSelectorItemType.VIEW_FULL_RESULTS &&
            b.type === TicketSelectorItemType.VIEW_FULL_RESULTS) ||
        (a.type === TicketSelectorItemType.TICKET &&
            b.type === TicketSelectorItemType.TICKET &&
            a.ticket.id === b.ticket.id)
    );
}

type NoTicketsZeroStateProps = {
    query: string;
};

function NoTicketsZeroState({ query }: NoTicketsZeroStateProps) {
    return (
        <div className={styles.noTicketsZeroState}>
            <Icon icon="search" iconSet="c9r" iconSize={20} strokeWidth={1.75} />
            <span>
                <strong>No open topics for </strong> "{query}"
            </span>
        </div>
    );
}

export type TicketSelectorProps = {
    excludedTicketIds?: string[];
    showRecentTicketsOnEmptyQuery?: boolean;
    showViewFullResults?: boolean;
    ticketReferenceContextAndOriginBoard?: {
        context: CommonEnumValue<"TicketReferenceContext">;
        originBoard: TicketReferenceBoard;
    };
} & Omit<
    FilterableSelectorProps<TicketSelectorItem>,
    | "items"
    | "itemsEqual"
    | "itemListRenderer"
    | "menuItemClassName"
    | "menuItemsClassName"
    | "menuItemTextRenderer"
    | "menuProps"
>;

export function TicketSelector({
    excludedTicketIds,
    showRecentTicketsOnEmptyQuery,
    showViewFullResults,
    ticketReferenceContextAndOriginBoard,
    ...filterableSelectorProps
}: TicketSelectorProps) {
    const [suggestedTickets, setSuggestedTickets] = useState<TSearchIndexTicket[]>([]);
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const { isTicketSearchIndexLoading, findTickets } = useTicketSearch();

    const [query, onQueryChange] = useMaybeControlledValue({
        value: filterableSelectorProps.query,
        onValueChange: filterableSelectorProps.onQueryChange,
        defaultValue: "",
    });

    const shouldShowResults = query || showRecentTicketsOnEmptyQuery;

    useEffect(() => {
        if (!shouldShowResults) {
            setSuggestedTickets([]);
            return;
        }

        if (isTicketSearchIndexLoading) {
            return;
        }

        const excludedTicketIdsSet = new Set(excludedTicketIds ?? []);

        setSuggestedTickets(
            findTickets({ searchQuery: query })
                .filter(t => !excludedTicketIdsSet.has(t.id))
                .sort((a, b) => {
                    if (!a.archived_at && b.archived_at) {
                        return -1;
                    }

                    if (!b.archived_at && a.archived_at) {
                        return 1;
                    }

                    return 0;
                })
        );
    }, [excludedTicketIds, findTickets, isTicketSearchIndexLoading, query, shouldShowResults]);

    const suggestedItems = ([] as TicketSelectorItem[])
        .concat(suggestedTickets.map(ticket => ({ type: TicketSelectorItemType.TICKET, ticket })))
        .concat(
            [
                query && showViewFullResults ? TICKET_SELECTOR_VIEW_FULL_RESULTS_SENTINEL : null,
            ].filter(isDefined)
        );

    return (
        <TicketMenuWrapper dependencies={[suggestedTickets, query]}>
            <FilterableSelector
                {...filterableSelectorProps}
                allowNoActiveItem={!!showViewFullResults}
                filterIconProps={{
                    icon: "search",
                    iconSet: "c9r",
                    iconSize: 16,
                }}
                filterPlaceholder={
                    shouldShowTicketRefs
                        ? "Search by topic ID, title, labels..."
                        : "Search by title, labels..."
                }
                items={suggestedItems}
                itemsEqual={areTicketSelectorItemsEqual}
                itemListRenderer={({ items, renderItem }) => {
                    if (!shouldShowResults) {
                        return null;
                    }

                    if (isTicketSearchIndexLoading) {
                        return (
                            <MenuItem
                                className={styles.loadingItem}
                                data-cy="ticket-selector-loading-placeholder"
                                disabled
                                text="Loading..."
                                instrumentation={null}
                            />
                        );
                    }

                    const openTicketItems = items
                        .map(item =>
                            item.type === TicketSelectorItemType.TICKET && !item.ticket.archived_at
                                ? item
                                : null
                        )
                        .filter(isDefined);
                    const archivedTicketItems = items
                        .map(item =>
                            item.type === TicketSelectorItemType.TICKET && item.ticket.archived_at
                                ? item
                                : null
                        )
                        .filter(isDefined);

                    const viewFullResultsItem = items.find(
                        item => item === TICKET_SELECTOR_VIEW_FULL_RESULTS_SENTINEL
                    );

                    const buildTicketItemRenderItemArgs = ({
                        ticketItem,
                    }: {
                        ticketItem: TicketSelectorTicketItem;
                    }) => {
                        if (!ticketReferenceContextAndOriginBoard) {
                            return { item: ticketItem };
                        }

                        const { isAuthorized, reason } = authorizeTicketReference({
                            ticketReference: {
                                ...ticketReferenceContextAndOriginBoard,
                                targetBoard: ticketItem.ticket.board,
                            },
                        });

                        return isAuthorized
                            ? { item: ticketItem }
                            : {
                                  item: ticketItem,
                                  menuItemProps: { disabled: true },
                                  tooltipProps: {
                                      content: reason,
                                      placement: "left" as const,
                                      small: true,
                                  },
                              };
                    };

                    return (
                        <>
                            {openTicketItems.length ? (
                                openTicketItems.map(ticketItem =>
                                    renderItem(buildTicketItemRenderItemArgs({ ticketItem }))
                                )
                            ) : (
                                <NoTicketsZeroState query={query} />
                            )}

                            {archivedTicketItems.length ? (
                                <>
                                    <MenuDivider className={styles.divider} />
                                    <MenuHeader>Archived</MenuHeader>
                                    {archivedTicketItems.map(ticketItem =>
                                        renderItem(buildTicketItemRenderItemArgs({ ticketItem }))
                                    )}
                                </>
                            ) : null}

                            {viewFullResultsItem &&
                            (openTicketItems.length || archivedTicketItems.length) ? (
                                <>
                                    <MenuDivider className={styles.divider} />

                                    {renderItem({
                                        item: viewFullResultsItem,
                                        menuItemProps: {
                                            icon: (
                                                <Icon
                                                    className={styles.viewFullResultsItemIcon}
                                                    icon="search"
                                                    iconSet="c9r"
                                                    iconSize={18}
                                                />
                                            ),
                                        },
                                    })}
                                </>
                            ) : null}
                        </>
                    );
                }}
                menuItemClassName={TicketMenuItemClassName}
                menuItemTextRenderer={item => {
                    switch (item.type) {
                        case TicketSelectorItemType.TICKET:
                            return <TicketMenuItemLayout ticket={item.ticket} />;

                        case TicketSelectorItemType.VIEW_FULL_RESULTS:
                            return (
                                <div className={styles.viewFullResultsItemContent}>
                                    <div>All search results for "{query}"</div>
                                    <Hotkey text="Enter" />
                                </div>
                            );

                        default:
                            return null;
                    }
                }}
                menuProps={{ className: styles.ticketSelector }}
                onNoActiveItemSelect={() => {
                    if (showViewFullResults && query) {
                        filterableSelectorProps.onSelect?.(
                            TICKET_SELECTOR_VIEW_FULL_RESULTS_SENTINEL
                        );
                    }
                }}
                onQueryChange={onQueryChange}
                query={query}
            />
        </TicketMenuWrapper>
    );
}
