import React, { useCallback, useState } from "react";

import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";
import { createCtx } from "lib/react/Context";
import { isDefined } from "lib/types/guards";

type TRadioValue = string | number;

const [useRadioGroup, ContextProvider] = createCtx<{
    name: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    selectedValue: TRadioValue;
}>();

type RadioInstrumentationEvent = Omit<InstrumentationEvent, "eventType">;

export type AbstractRadioGroupProps = {
    children: React.ReactNode;
    /**
     * Initial selected value when used in uncontrolled mode.
     */
    defaultSelectedValue?: TRadioValue;
    instrumentation: RadioInstrumentationEvent | null;
    name: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    /**
     * Selected value when used in controlled mode.
     */
    selectedValue?: TRadioValue;
};

export function AbstractRadioGroup({
    children,
    defaultSelectedValue,
    instrumentation,
    name,
    onChange,
    selectedValue: selectedValueControlled,
}: AbstractRadioGroupProps) {
    const isControlled = isDefined(selectedValueControlled);
    const [selectedValueUncontrolled, setSelectedValueUncontrolled] = useState<TRadioValue>(
        defaultSelectedValue ?? ""
    );
    const selectedValue = isControlled ? selectedValueControlled : selectedValueUncontrolled;
    const { recordEvent } = useInstrumentation();

    const wrappedOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (instrumentation?.elementName) {
                void recordEvent({
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: instrumentation?.elementName,
                    eventData: { ...instrumentation?.eventData, selectedValue: e.target.value },
                    dedupeKey: Date.now(),
                });
            }

            if (!isControlled) {
                setSelectedValueUncontrolled(e.target.value);
            }

            onChange?.(e);
        },
        [instrumentation, isControlled, recordEvent, onChange]
    );

    return (
        <ContextProvider value={{ onChange: wrappedOnChange, name, selectedValue }}>
            {children}
        </ContextProvider>
    );
}

export type AbstractRadioProps = {
    children?: React.ReactNode;
    className?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
} & React.ComponentPropsWithoutRef<"input">;

export function AbstractRadio({
    children,
    className,
    inputRef,

    ...htmlInputProps
}: AbstractRadioProps) {
    const { name, onChange, selectedValue } = useRadioGroup();

    return (
        <label
            className={className}
            onClick={event => {
                // When the content of the checkbox children is clicked, we don't want *that* event
                // to bubble up. Instead, we want this label element to handle that event, thereby
                // triggering an event on the input element, and for *that* event to bubble up.
                event.stopPropagation();
            }}
        >
            <input
                {...htmlInputProps}
                type="radio"
                checked={htmlInputProps.value === selectedValue}
                name={name}
                onChange={onChange}
                ref={inputRef}
            />
            {children}
        </label>
    );
}
