import React, { useCallback, useState } from "react";

import classNames from "classnames";
import { InView } from "react-intersection-observer";

import styles from "./ScrollDivider.module.scss";

export type ScrollDividerProps = {
    className?: string;
};

export function ScrollDivider({ className }: ScrollDividerProps) {
    const [showDivider, setShowDivider] = useState(false);

    const onScrollChange = useCallback((inView: boolean) => {
        setShowDivider(!inView);
    }, []);

    return (
        <>
            <hr
                className={classNames(className, styles.divider, showDivider && styles.showDivider)}
            />
            <InView as="div" onChange={onScrollChange} />
        </>
    );
}
