import React, { useCallback, useEffect, useRef } from "react";

import { TBlocker } from "c9r-common";
import classNames from "classnames";

import { TicketLink } from "components/ui/common/TicketLink";
import { ProductTourElementClasses } from "lib/Constants";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { DiscussionContent_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useCreateThreadWithBlocker, useCreateThreadWithComment } from "lib/mutations";
import { TRichTextContentSerializers } from "lib/types/common/richText";

import { CommentThread } from "./CommentThread";
import styles from "./DiscussionContent.module.scss";
import { NewBlockerEditor } from "./NewBlockerEditor";
import { NewCommentEditor } from "./NewCommentEditor";
import { NewDiscussionItemBox } from "./NewDiscussionItemBox";
import { useDetailView } from "../context/DetailViewContext";
import { useDetailViewDiscussion } from "../context/DetailViewDiscussionContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DiscussionContent_ticket on tickets {
            id

            blocker_of_threads(where: { resolved_at: { _is_null: true } }) {
                id

                ticket {
                    id

                    ...TicketLink_ticket
                }
            }

            board {
                id

                ...NewBlockerEditor_board
                ...NewCommentEditor_board
            }

            threads {
                id
                blocker_type
                opened_at
                resolved_at
                ...CommentThread_thread
            }

            ...CommentThread_ticket
        }
    `),
};

export type DiscussionContentProps = {
    onCancelNewThread?: () => void;
    onlyUnresolved?: boolean;
};

export function DiscussionContent({ onCancelNewThread, onlyUnresolved }: DiscussionContentProps) {
    const { ticket: _ticketFragment, blockedByTicketIds } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const blockerOfTickets = ticket.blocker_of_threads.reduce(
        (tickets, thread) =>
            tickets.some(t => t.id === thread.ticket.id) ? tickets : tickets.concat(thread.ticket),
        [] as DiscussionContent_ticketFragment["blocker_of_threads"][number]["ticket"][]
    );

    const {
        isAddingNewBlocker,
        isAddingNewComment,
        newBlockerDraftStorageKey,
        newCommentDraftStorageKey,
        setIsAddingNewBlocker,
        setIsAddingNewComment,
        showResolved,
    } = useDetailViewDiscussion();

    const newBlockerEditorRef = useRef<HTMLDivElement>(null);
    const newCommentEditorRef = useRef<HTMLDivElement>(null);

    const { createThreadWithBlocker } = useCreateThreadWithBlocker();
    const { createThreadWithComment } = useCreateThreadWithComment();

    const threadsToDisplay = ticket.threads
        .filter(thread => !thread.blocker_type)
        .filter(thread => !thread.resolved_at || (showResolved && !onlyUnresolved))
        .sort((a, b) => new Date(a.opened_at).getTime() - new Date(b.opened_at).getTime());
    const blockerThreadsToDisplay = ticket.threads
        .filter(thread => !!thread.blocker_type)
        .filter(thread => !thread.resolved_at || (showResolved && !onlyUnresolved))
        .sort((a, b) => new Date(a.opened_at).getTime() - new Date(b.opened_at).getTime());

    const delayedScrollIntoView = useCallback((ref: React.RefObject<HTMLDivElement>) => {
        // As of December 2021, there seems to be an unfortanate interaction between
        // scrollIntoView and the editor autofocus. Ideally,  we want the editor to
        // appear and be focused and simultanously to smoothly scroll
        // it into view. But by autofocusing the editor, browser behavior is to *immediately*
        // scroll it into view.
        //
        // As a quick good enough solution, just scroll after a timeout. It doesn't look
        // awful and is better than what we had before.
        setTimeout(() => {
            ref.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }, 150);
    }, []);

    useEffect(() => {
        if (isAddingNewComment) {
            delayedScrollIntoView(newCommentEditorRef);
        }
    }, [isAddingNewComment, delayedScrollIntoView]);

    useEffect(() => {
        if (isAddingNewBlocker) {
            delayedScrollIntoView(newBlockerEditorRef);
        }
    }, [isAddingNewBlocker, delayedScrollIntoView]);

    const commitNewThreadWithBlocker = async ({
        blocker,
        newAssignee,
    }: {
        blocker: TBlocker;
        newAssignee?: { id: number } | null;
    }) => {
        const assignedToUserId = newAssignee?.id || null;

        await createThreadWithBlocker({
            ticketId: ticket.id,
            blocker,
            assignedToUserId,
        });

        setIsAddingNewBlocker(false);
    };

    const commitNewThreadWithComment = async ({
        serializers,
        newAssignee,
    }: {
        serializers: React.MutableRefObject<TRichTextContentSerializers | null>;
        newAssignee?: { id: number } | null;
    }) => {
        const assignedToUserId = newAssignee?.id || null;
        const isPopulated = serializers.current?.isPopulated();

        if (isPopulated && serializers.current) {
            await createThreadWithComment({
                ticketId: ticket.id,
                serializers: serializers.current,
                assignedToUserId,
            });
        }

        setIsAddingNewComment(false);
    };

    return (
        <div
            className={classNames(
                styles.content,
                ProductTourElementClasses.TICKET_DISCUSSION_PANEL
            )}
        >
            {blockerOfTickets.length ? (
                <div className={styles.blockingSection}>
                    <div className={styles.blockingSectionHeader}>
                        Blocking {blockerOfTickets.length}{" "}
                        {blockerOfTickets.length === 1 ? "topic" : "topics"}
                    </div>
                    <ul className={styles.blockingSectionTicketLinks}>
                        {blockerOfTickets.map(t => (
                            <li key={t.id}>
                                <TicketLink
                                    className={styles.blockingSectionTicketLink}
                                    ticket={t}
                                    underline={false}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {blockerThreadsToDisplay.length ? (
                <ul className={styles.threads}>
                    {blockerThreadsToDisplay.map(thread => (
                        <CommentThread key={thread.id} thread={thread} ticket={ticket} />
                    ))}
                </ul>
            ) : null}

            {isAddingNewBlocker ? (
                <NewDiscussionItemBox ref={newBlockerEditorRef}>
                    <NewBlockerEditor
                        board={ticket.board}
                        draftStorageKey={newBlockerDraftStorageKey}
                        excludedTicketIds={[ticket.id, ...blockedByTicketIds]}
                        onCancel={() => {
                            setIsAddingNewBlocker(false);
                            onCancelNewThread?.();
                        }}
                        onSubmit={({ blocker, newAssignee }) =>
                            commitNewThreadWithBlocker({ blocker, newAssignee })
                        }
                    />
                </NewDiscussionItemBox>
            ) : null}

            {threadsToDisplay.length ? (
                <ul className={styles.threads}>
                    {threadsToDisplay.map(thread => (
                        <CommentThread key={thread.id} thread={thread} ticket={ticket} />
                    ))}
                </ul>
            ) : null}

            {isAddingNewComment ? (
                <NewDiscussionItemBox ref={newCommentEditorRef}>
                    <NewCommentEditor
                        board={ticket.board}
                        draftStorageKey={newCommentDraftStorageKey}
                        onCancel={() => {
                            setIsAddingNewComment(false);
                            onCancelNewThread?.();
                        }}
                        onSubmit={({ serializers, newAssignee }) =>
                            commitNewThreadWithComment({
                                serializers,
                                newAssignee,
                            })
                        }
                    />
                </NewDiscussionItemBox>
            ) : null}
        </div>
    );
}
