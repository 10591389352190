import { useEffect } from "react";

import { useHistory } from "lib/Routing";

export function InviteRedirect() {
    const { history } = useHistory();

    useEffect(() => {
        history.push("/");
    }, [history]);

    return null;
}
