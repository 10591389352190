import React from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./BoardHeaderName.module.scss";
import { BorderButton } from "../core/BorderButton";

export type BoardHeaderNameProps = {
    boardDisplayName: string;
    boardAccessType: CommonEnumValue<"BoardAccessType">;
    onClick: () => void;
    className?: string;
    isArchived?: boolean;
};

export function BoardHeaderName({
    boardDisplayName,
    boardAccessType,
    onClick,
    className,
    isArchived,
}: BoardHeaderNameProps) {
    return (
        <BorderButton
            content={
                <div className={classNames(className, styles.header)} data-cy="workspace-header">
                    {boardAccessType === CommonEnums.BoardAccessType.PRIVATE ? (
                        <Icon icon="lock" iconSet="c9r" iconSize={20} strokeWidthAbsolute={2} />
                    ) : (
                        <Icon icon="spaceFrame" iconSet="c9r" iconSize={20} strokeWidth={0.75} />
                    )}
                    <span>{boardDisplayName}</span>
                    {isArchived ? <span className={styles.archivedText}> (archived)</span> : null}
                </div>
            }
            flush
            minimal
            onClick={onClick}
            instrumentation={{
                elementName: "board_header.board_name_btn",
            }}
        />
    );
}
