import React, { createRef, useEffect, useRef, useState } from "react";

import { sortTickets } from "c9r-common";
import classNames from "classnames";
import {
    Draggable,
    DraggableProvidedDraggableProps,
    DraggableStateSnapshot,
    Droppable,
} from "react-beautiful-dnd";
import { InView } from "react-intersection-observer";

import { AppData } from "AppData";
import { EditableText } from "components/ui/core/EditableText";
import { NewTicketEntryId } from "lib/Constants";
import { dragAndDropEntity } from "lib/DragAndDrop";
import {
    moveToFirstPosition,
    moveToLastPosition,
    moveToPositionByIndex,
} from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useIsMounted, useSaveScrollPosition } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { Column_stageFragment, Column_ticketFragment } from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import styles from "./Column.module.scss";
import { DraggableCard, DraggableCardProps } from "./DraggableCard";
import { NewTicketCard } from "./NewTicketCard";
import { useNewTicketEntry, useNewTicketEntryPlacement } from "../NewTicketEntry";
import { StageMenu } from "../StageMenu";
import { TicketCount } from "../TicketCount";

const fragments = {
    stage: gql(/* GraphQL */ `
        fragment Column_stage on stages {
            id
            board_id
            deleted_at
            display_name
            role
            is_empty
            min_ticket_stage_pos
            max_ticket_stage_pos

            ...StageMenu_stage
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment Column_ticket on tickets {
            id
            stage_id
            stage_pos
            archived_at
            trashed_at

            ...DraggableCard_ticket
            ...StageMenu_ticket
        }
    `),
};

type ColumnHeaderProps = {
    stage: Column_stageFragment;
    tickets: Column_ticketFragment[];
    showTicketCounts?: boolean;
};

function ColumnHeader({ stage, tickets, showTicketCounts }: ColumnHeaderProps) {
    const displayNameRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(true);

    return (
        <div className={styles.columnHeaderWrapper}>
            <InView as="div" className={styles.visibilityDetector} onChange={setIsVisible} />
            <header>
                <EditableText
                    cancelIfEmpty
                    className={classNames(styles.stageName)}
                    contentEditable={false}
                    elementRef={displayNameRef}
                    placeholder="Enter column name..."
                    value={stage.display_name}
                />
                {showTicketCounts && <TicketCount ticketCount={tickets.length} />}
                <div style={{ flex: "1 1 auto" }} />
                <StageMenu
                    buttonClassName={styles.stageMenuButton}
                    className={classNames(
                        styles.stageMenuWrapper,
                        isVisible && styles.stageMenuWrapperIsVisible
                    )}
                    stage={stage}
                    tickets={tickets}
                />
            </header>
        </div>
    );
}

const DisplayItemType = {
    TICKET: "TICKET",
    NEW_TICKET: "NEW_TICKET",
} as const;

type TDisplayItem =
    | {
          type: typeof DisplayItemType["TICKET"];
          stagePos: number;
          id: string;
          ticket: Column_ticketFragment;
          ticketIndex: number;
      }
    | {
          type: typeof DisplayItemType["NEW_TICKET"];
          stagePos: number;
          id: string;
      };

export type ColumnProps = {
    className?: string;
    colIndex: number;
    isDragInProgress?: boolean;
    stage: FragmentType<typeof fragments.stage>;
    tickets: FragmentType<typeof fragments.ticket>[];
    showTicketCounts?: boolean;
} & Pick<
    DraggableCardProps,
    "onArchiveCard" | "onMoveCard" | "onTrashCard" | "prevStageId" | "nextStageId"
>;

export const Column = React.memo(
    ({
        className,
        stage: _stageFragment,
        tickets: _ticketFragments,
        showTicketCounts,
        onMoveCard,
        onArchiveCard,
        onTrashCard,
        prevStageId,
        nextStageId,
        isDragInProgress,
        colIndex,
    }: ColumnProps) => {
        // @ts-ignore
        const stage = getFragmentData(fragments.stage, _stageFragment);
        const tickets = _ticketFragments.map(t => getFragmentData(fragments.ticket, t));
        const ref = useRef<HTMLDivElement | null>(null);
        const columnCardsRef = useRef<HTMLUListElement | null>(null);
        const [cardsRefs, setCardsRefs] = useState<React.MutableRefObject<HTMLLIElement | null>[]>(
            []
        );
        const [isDraggingOver, setIsDraggingOver] = useState(false);
        const newTicketEntry = useNewTicketEntry();
        const newTicketEntryPlacement = useNewTicketEntryPlacement();
        const draggableId = dragAndDropEntity.getDndId("stage", stage.id);
        const isNewTicketEntryAvailable = !newTicketEntryPlacement.isPlaced;

        const sortedTickets = tickets
            .filter(t => t.stage_id === stage.id && !t.archived_at && !t.trashed_at)
            .sort(sortTickets());

        const displayItems = ([] as TDisplayItem[])
            .concat(
                sortedTickets.map((ticket, index) => ({
                    type: DisplayItemType.TICKET,
                    stagePos: ticket.stage_pos!,
                    id: ticket.id,
                    ticket,
                    // This is the index of the ticket in the list of tickets (needed for drag/drop and
                    // entity position calculations), *not* the index in the list of displayed items.
                    ticketIndex: index,
                }))
            )
            .concat(
                [
                    newTicketEntryPlacement.isPlaced && newTicketEntryPlacement.stageId === stage.id
                        ? {
                              type: DisplayItemType.NEW_TICKET,
                              stagePos: newTicketEntryPlacement.stagePos,
                              id: NewTicketEntryId,
                          }
                        : null,
                ].filter(isDefined)
            )
            .sort((a, b) => (a.stagePos ?? 0) - (b.stagePos ?? 0));

        useEffect(() => {
            // Make sure that the number of refs to be passed to the cards match the number of cards
            // and only create new refs if necessary.
            setCardsRefs(existingRefs =>
                Array.from(
                    { length: sortedTickets.length },
                    (_, i) => existingRefs[i] || createRef()
                )
            );
        }, [sortedTickets.length]);

        useSaveScrollPosition({
            scrollElementRef: columnCardsRef,
            component: "column",
            id: stage.id,
        });

        useEffect(() => {
            if (!ref.current || !columnCardsRef.current) {
                return;
            }

            if (isDragInProgress) {
                const { width } = window.getComputedStyle(ref.current);

                ref.current.style.minWidth = width;
                ref.current.style.maxWidth = width;

                AppData.stageWidthsById[stage.id] = Number(
                    window.getComputedStyle(columnCardsRef.current).width
                );
            } else {
                ref.current.style.minWidth = "";
                ref.current.style.maxWidth = "";
            }
        }, [isDragInProgress, stage.id, ref]);

        const CLICK_DURATION = 300;

        const handleOnClick = (e: React.MouseEvent) => {
            if (
                !isNewTicketEntryAvailable ||
                !columnCardsRef.current ||
                e.target !== columnCardsRef.current ||
                Date.now() - newTicketEntryPlacement.lastClearedAt < CLICK_DURATION ||
                isDraggingOver
            ) {
                return;
            }

            const y =
                e.clientY -
                columnCardsRef.current.getBoundingClientRect().top +
                columnCardsRef.current.scrollTop;

            const index = sortedTickets.findIndex(
                (_, i) =>
                    (i === 0 ||
                        y >=
                            (cardsRefs[i - 1]?.current?.offsetTop ?? 0) +
                                (cardsRefs[i - 1]?.current?.offsetHeight ?? 0)) &&
                    y <= (cardsRefs[i]?.current?.offsetTop ?? 0)
            );

            if ((index === -1 && !sortedTickets.length) || index === 0) {
                newTicketEntry.openAt({
                    boardId: stage.board_id,
                    stageId: stage.id,
                    stagePos: moveToFirstPosition({ minPos: stage.min_ticket_stage_pos }),
                });

                return;
            }

            if (index === -1 && sortedTickets.length) {
                newTicketEntry.openAt({
                    boardId: stage.board_id,
                    stageId: stage.id,
                    stagePos: moveToLastPosition({ maxPos: stage.max_ticket_stage_pos }),
                });

                return;
            }

            newTicketEntry.openAt({
                boardId: stage.board_id,
                stageId: stage.id,
                stagePos: moveToPositionByIndex({
                    sortedEntities: sortedTickets,
                    posFieldName: "stage_pos",
                    toIndex: index,
                }),
            });
        };

        const onCreateNewTicket = async ({ another = false }) => {
            if (another) {
                newTicketEntry.openAt({
                    boardId: stage.board_id,
                    stageId: stage.id,
                    stagePos: moveToPositionByIndex({
                        sortedEntities: displayItems,
                        posFieldName: "stagePos",
                        toIndex:
                            displayItems.findIndex(
                                ({ type }) => type === DisplayItemType.NEW_TICKET
                            ) + 1,
                    }),
                });
            }
        };

        function getStyle(
            style: DraggableProvidedDraggableProps["style"],
            snapshot: DraggableStateSnapshot
        ): React.CSSProperties {
            if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
                return { ...style };
            }

            const { duration } = snapshot.dropAnimation;

            return {
                ...style,
                transition: `all cubic-bezier(0.2,1.0,0.9,1.0) ${duration / 1.5}s`,
            };
        }

        const isMounted = useIsMounted();

        return (
            <Draggable draggableId={draggableId} index={colIndex} isDragDisabled>
                {(provided, snapshot) => (
                    <div
                        className={classNames(
                            className,
                            styles.column,
                            snapshot.isDragging && styles.dragging,
                            isDraggingOver && styles.draggingOver
                        )}
                        ref={e => {
                            ref.current = e;
                            provided.innerRef(e);
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                        <ColumnHeader
                            stage={stage}
                            tickets={tickets}
                            showTicketCounts={showTicketCounts}
                        />
                        <Droppable
                            droppableId={dragAndDropEntity.getDndId(
                                Enums.DndEntityTypes.STAGE,
                                stage.id
                            )}
                            type="BOARD_COLUMN"
                        >
                            {(droppableProvided, droppableSnapshot) => {
                                setImmediate(() => {
                                    if (isMounted()) {
                                        setIsDraggingOver(droppableSnapshot.isDraggingOver);
                                    }
                                });

                                return (
                                    <ul
                                        data-cy="board-column"
                                        className={classNames(
                                            styles.columnCards,
                                            droppableSnapshot.isDraggingOver && styles.draggingOver,
                                            isNewTicketEntryAvailable &&
                                                !droppableSnapshot.isDraggingOver &&
                                                styles.textCursor
                                        )}
                                        ref={e => {
                                            columnCardsRef.current = e;
                                            droppableProvided.innerRef(e);
                                        }}
                                        {...droppableProvided.droppableProps}
                                        onClick={handleOnClick}
                                    >
                                        {(() =>
                                            displayItems.map((displayItem, index) => {
                                                switch (displayItem.type) {
                                                    case DisplayItemType.TICKET: {
                                                        const { ticket, ticketIndex } = displayItem;

                                                        return (
                                                            <DraggableCard
                                                                cardRef={cardsRefs[ticketIndex]}
                                                                className={classNames(
                                                                    styles.columnCard,
                                                                    droppableSnapshot.isDraggingOver &&
                                                                        styles.draggingOver
                                                                )}
                                                                key={ticket.id}
                                                                ticket={ticket}
                                                                index={index}
                                                                onMoveCard={onMoveCard}
                                                                onArchiveCard={onArchiveCard}
                                                                onTrashCard={onTrashCard}
                                                                isFirstTicketInStage={
                                                                    ticketIndex === 0
                                                                }
                                                                isLastTicketInStage={
                                                                    ticketIndex ===
                                                                    sortedTickets.length - 1
                                                                }
                                                                prevStageId={prevStageId}
                                                                nextStageId={nextStageId}
                                                            />
                                                        );
                                                    }
                                                    case DisplayItemType.NEW_TICKET:
                                                        return (
                                                            <NewTicketCard
                                                                className={styles.columnCard}
                                                                key={displayItem.id}
                                                                boardId={stage.board_id}
                                                                index={index}
                                                                onCreate={onCreateNewTicket}
                                                                onCancel={newTicketEntry.clear}
                                                            />
                                                        );
                                                    default:
                                                        throw new Error("Unexpected item type");
                                                }
                                            }))()}
                                        {droppableProvided.placeholder}
                                    </ul>
                                );
                            }}
                        </Droppable>
                    </div>
                )}
            </Draggable>
        );
    }
);

Column.displayName = "Column";
