import React from "react";

import { compareDesc, isBefore, parseISO } from "date-fns";

import { useCurrentUser } from "contexts/UserContext";
import { EnumValue, Enums } from "lib/Enums";
import { TicketViewsInfoMap, useTicketViewsInfoMap } from "lib/TicketViews";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    MyWorkProvider_ticketFragment,
    MyWorkProvider_userFragment,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";
import { useReplicacheGraphQLLiveQuery } from "lib/replicache/graphql/LocalServer";
import { isDefined } from "lib/types/guards";

export type MyWorkContextValue = {
    isUnread: boolean;
    myTodosItems: TodoItem[];
};

const [useMyWork, ContextProvider] = createCtx<MyWorkContextValue>();

export { useMyWork };

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment MyWorkProvider_ticket on tickets {
            id
            archived_at
            ref
            slug
            stage_pos
            title
            trashed_at

            board {
                id
                display_name

                ...Editor_board
            }

            open_threads: threads(where: { resolved_at: { _is_null: true } }) {
                id
                assigned_at
                assigned_by_user_id
                assigned_to_user_id
                blocker_text
                blocker_type
                resolved_at

                assigner {
                    id
                    name
                }

                blocker_ticket {
                    id
                    ref
                    title
                }

                comments {
                    id
                    author_user_id
                    comment_json
                    posted_at

                    author {
                        id
                        name

                        ...Avatar_user
                    }
                }
            }

            owners {
                ticket_id
                user_id
                added_at
                added_by_user_id

                owner {
                    id
                }
            }

            stage {
                id
                board_pos
            }

            ...TicketMenuItemLayout_ticket
        }
    `),

    user: gql(/* GraphQL */ `
        fragment MyWorkProvider_user on users {
            id

            assigned_threads(
                where: {
                    resolved_at: { _is_null: true }
                    ticket: {
                        archived_at: { _is_null: true }
                        trashed_at: { _is_null: true }
                        board: { archived_at: { _is_null: true } }
                    }
                }
            ) {
                id

                ticket {
                    ...MyWorkProvider_ticket
                }
            }
        }
    `),
};

const MY_TODOS_ORDER = [Enums.TodoType.THREAD];

export type TodoItemThread = {
    todoType: "THREAD";
    todoData: {
        thread: MyWorkProvider_ticketFragment["open_threads"][number];
        comment: MyWorkProvider_ticketFragment["open_threads"][number]["comments"][number] | null;
    };
    url?: undefined;
    key: string | number;
    ticket: MyWorkProvider_ticketFragment;
    displayTimestamp: Date;
    unreadTimestamp: Date | null;
    isUnread?: boolean;
    isActive?: boolean;
};

export type TodoItem = TodoItemThread;

export type MyWorkProviderProps = {
    children: React.ReactNode;
};

export function MyWorkProvider({ children }: MyWorkProviderProps) {
    const currentUser = useCurrentUser();
    const componentQuery = useReplicacheGraphQLLiveQuery({
        query: MyWorkProvider.queries.component,
        variables: { userId: currentUser.id },
    });
    const { data } = componentQuery;

    const user = getFragmentData(fragments.user, data?.user);
    const lastViewedWorkAt = currentUser.last_viewed_work_at;
    const ticketMap = user ? MyWorkProvider._buildTicketMap({ user }) : {};
    const ticketViewsInfoMap = useTicketViewsInfoMap({ ticketIds: Object.keys(ticketMap) });

    const value = user
        ? MyWorkProvider._computeContextValue({
              lastViewedWorkAt,
              ticketMap,
              ticketViewsInfoMap,
              user,
          })
        : {
              isUnread: false,
              myTodosItems: [],
              myTicketsItems: [],
          };

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

MyWorkProvider._buildTicketMap = ({ user }: { user: MyWorkProvider_userFragment }) => {
    const allTicketsWithDupes = ([] as FragmentType<typeof fragments.ticket>[])
        .concat(user.assigned_threads.map(thread => thread.ticket))
        .map(ticketFragment => getFragmentData(fragments.ticket, ticketFragment))
        .filter(ticket => !ticket.archived_at && !ticket.trashed_at);

    return Object.fromEntries(allTicketsWithDupes.map(ticket => [ticket.id, ticket]));
};

MyWorkProvider._computeContextValue = ({
    lastViewedWorkAt,
    ticketMap,
    ticketViewsInfoMap,
    user,
}: {
    lastViewedWorkAt: string | null;
    ticketMap: {
        [k: string]: MyWorkProvider_ticketFragment;
    };
    ticketViewsInfoMap: TicketViewsInfoMap;
    user: MyWorkProvider_userFragment;
}) => {
    const myTodosItems = Object.values(ticketMap)
        .map(ticket => [
            ...MyWorkProvider._buildMyTodoItemsForTicket({
                ticket,
                todoType: Enums.TodoType.THREAD,
                user,
            }),
        ])
        .flat()
        .filter(isDefined)
        .sort(MyWorkProvider._sortMyTodoItems);

    const allItems = ([] as TodoItem[]).concat(myTodosItems);

    for (const item of allItems) {
        item.isUnread = MyWorkProvider._isItemUnread({ item, ticketViewsInfoMap });
    }

    const isUnread = MyWorkProvider._isMyWorkUnread({ items: allItems, lastViewedWorkAt });

    return { isUnread, myTodosItems };
};

MyWorkProvider._buildMyTodoItemsForTicket = ({
    ticket,
    todoType,
    user,
}: {
    ticket: MyWorkProvider_ticketFragment;
    todoType: EnumValue<"TodoType">;
    user: MyWorkProvider_userFragment;
}) => {
    switch (todoType) {
        case Enums.TodoType.THREAD: {
            const assignedThreads = (ticket.open_threads || []).filter(
                thread => !thread.resolved_at && thread.assigned_to_user_id === user.id
            );
            const items = [] as TodoItemThread[];

            for (const thread of assignedThreads) {
                items.push({
                    key: `${Enums.TodoType.THREAD}_${thread.id}`,
                    ticket,
                    displayTimestamp: new Date(thread.assigned_at!),
                    unreadTimestamp:
                        // As of April 2022, we didn't backfill assigned_by_user_id. Some users may have
                        // assigned threads to themselves in the past. To avoid those appearing
                        // as unread, we just treat it as read. The effect of this is that we only
                        // show the unread timestamp for thread assignments that occurred after we
                        // started setting assigned_by_user_id.
                        thread.assigned_by_user_id && thread.assigned_by_user_id !== user.id
                            ? new Date(thread.assigned_at!)
                            : null,
                    todoType: Enums.TodoType.THREAD,
                    todoData: {
                        thread,
                        comment: MyWorkProvider._pickComment({ thread }),
                    },
                });
            }

            return items;
        }

        default:
            return [];
    }
};

MyWorkProvider._isItemUnread = ({
    item,
    ticketViewsInfoMap,
}: {
    item: TodoItem;
    ticketViewsInfoMap: TicketViewsInfoMap;
}) => {
    const lastViewedAt = ticketViewsInfoMap[item.ticket.id]?.lastViewedAt ?? null;

    return !!(
        item.unreadTimestamp &&
        (!lastViewedAt || new Date(lastViewedAt) < new Date(item.unreadTimestamp))
    );
};

MyWorkProvider._isMyWorkUnread = ({
    items,
    lastViewedWorkAt,
}: {
    items: TodoItem[];
    lastViewedWorkAt: string | null;
}) => {
    return items.some(
        item =>
            item.isUnread &&
            (!lastViewedWorkAt ||
                (item.unreadTimestamp && item.unreadTimestamp > new Date(lastViewedWorkAt)))
    );
};

MyWorkProvider._pickComment = ({
    thread,
}: {
    thread: MyWorkProvider_ticketFragment["open_threads"][number];
}) => {
    // Try to pick the comment that simultaneously assigned the thread to the user when it was posted.
    const assigningComment = thread.comments.find(
        comment => comment.posted_at === thread.assigned_at
    );

    if (assigningComment) {
        return assigningComment;
    }

    const sortedComments = thread.comments
        .concat()
        .sort((a, b) => compareDesc(parseISO(a.posted_at), parseISO(b.posted_at)));

    // Try to pick the most recent comment in the thread with a timestamp before the thread was assigned.
    const preAssigningComment = sortedComments.filter(comment =>
        isBefore(parseISO(comment.posted_at), parseISO(thread.assigned_at!))
    )[0];

    if (preAssigningComment) {
        return preAssigningComment;
    }

    // Try to pick the most recent comment not by this user.
    const mostRecentColleagueComment = sortedComments.filter(
        comment => comment.author_user_id !== thread.assigned_to_user_id
    )[0];

    if (mostRecentColleagueComment) {
        return mostRecentColleagueComment;
    }

    // Try to pick the most recent comment, period. (There may not be one.)
    return sortedComments[0];
};

MyWorkProvider._sortMyTodoItems = (a: TodoItem, b: TodoItem) => {
    for (const todoType of MY_TODOS_ORDER) {
        if (a.todoType === todoType && b.todoType !== todoType) {
            return -1;
        }

        if (b.todoType === todoType && a.todoType !== todoType) {
            return 1;
        }
    }

    return b.displayTimestamp.getTime() - a.displayTimestamp.getTime();
};

MyWorkProvider.queries = {
    component: gql(/* GraphQL */ `
        query MyWorkProvider($userId: Int!) {
            user: users_by_pk(id: $userId) {
                ...MyWorkProvider_user
            }
        }
    `),
};
