import React, { useCallback, useMemo, useState } from "react";

import { CommonEnums, Transforms, sortStages } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { AppData } from "AppData";
import { integrationsSetupStatusState } from "AppState";
import { Config } from "Config";
import { BoardAndStagesMenuItem } from "components/shared/BoardAndStagesMenuItem";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { AppToaster } from "components/ui/core/AppToaster";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Tooltip } from "components/ui/core/Tooltip";
import { useMutations } from "contexts/MutationsContext";
import { useCurrentUser } from "contexts/UserContext";
import { CssClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { divideAndFlattenGroups } from "lib/Helpers";
import { useClipboard } from "lib/Hooks";
import { useHotkey } from "lib/Hotkeys";
import { useMoveTicketsUX, useSetTicketOwnerToCurrentUserUX } from "lib/MutationUX";
import { useTicketOwnershipInfo, useTicketWatcherInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { CardMenu_stageFragment } from "lib/graphql/__generated__/graphql";

import styles from "./CardMenu.module.scss";

const fragments = {
    stage: gql(/* GraphQL */ `
        fragment CardMenu_stage on stages {
            id
            board_id
            display_name
            role
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment CardMenu_ticket on tickets {
            id
            ref
            slug
            stage_id
            title
            archived_at
            trashed_at

            board {
                id
                access_type
                display_name
                settings
            }

            owners {
                ticket_id
                user_id
            }

            ...BoardAndStagesMenuItem_ticket
            ...TicketOwnershipInfo_ticket
            ...TicketWatcherInfo_ticket
        }
    `),
};

const terminalStages = [CommonEnums.StageRole.COMPLETE, undefined] as (string | undefined)[];

/**
 * Handle a card menu action. Pass null to omit that action from the menu, and omit or
 * to include the action with a default handler.
 */
type CardMenuHandler = (() => void) | null;

type CardMenuHandlers = {
    handleClone: CardMenuHandler;
    handleEdit: CardMenuHandler | null | undefined;
    handleMoveToStartOfStage: CardMenuHandler;
    handleMoveToEndOfStage: CardMenuHandler;
    handleMoveToPreviousStage: CardMenuHandler;
    handleMoveToNextStage: CardMenuHandler;
    handleMoveTicket:
        | (({ ticketId, toStageId }: { ticketId: string; toStageId: string }) => void)
        | null
        | undefined;
    handleOwnerRemove: CardMenuHandler;
    handleSetOwner: CardMenuHandler;
    toggleWatching: CardMenuHandler;
    handleCopyBranchName: CardMenuHandler;
    handleCopyTicketLink: CardMenuHandler;
    handleArchive: CardMenuHandler;
    handleMoveToTrash: CardMenuHandler;
};

function useCardMenuStages({ stageId }: { stageId?: string | null }) {
    const currentUser = useCurrentUser();

    const stage =
        getFragmentData(
            fragments.stage,
            currentUser.org.all_boards.flatMap(b => b.all_stages).find(s => s.id === stageId)
        ) || null;

    const board = currentUser.org.all_boards.find(b => b.id === stage?.board_id);

    const stages = board?.all_stages.filter(s => !s.deleted_at).sort(sortStages()) ?? [];

    const prevStage = getFragmentData(
        fragments.stage,
        stages[stages.findIndex(s => s.id === stage?.id) - 1]
    );

    const nextStage = getFragmentData(
        fragments.stage,
        stages[stages.findIndex(s => s.id === stage?.id) + 1]
    );

    return useMemo(() => ({ stage, prevStage, nextStage }), [stage, prevStage, nextStage]);
}

type CardMenuDisplayProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
    isFirstTicketInStage?: boolean;
    isLastTicketInStage?: boolean;
    isCurrentUserOwner?: boolean;
    isCurrentUserMember?: boolean;
    isCurrentUserWatcher?: boolean;
    stage: CardMenu_stageFragment | null;
    prevStage: CardMenu_stageFragment | null;
    nextStage: CardMenu_stageFragment | null;
} & CardMenuHandlers;

function CardMenuDisplay({
    className,
    ticket: _ticketFragment,
    isCurrentUserOwner,
    isCurrentUserMember,
    isCurrentUserWatcher,
    isFirstTicketInStage,
    isLastTicketInStage,
    stage,
    prevStage,
    nextStage,
    handleClone,
    handleEdit,
    handleMoveTicket,
    handleMoveToStartOfStage,
    handleMoveToEndOfStage,
    handleMoveToPreviousStage,
    handleMoveToNextStage,
    handleOwnerRemove,
    handleSetOwner,
    toggleWatching,
    handleCopyBranchName,
    handleCopyTicketLink,
    handleArchive,
    handleMoveToTrash,
}: CardMenuDisplayProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);
    const { isFeatureEnabled } = useFeatureFlags();

    const isArchived = !!ticket.archived_at;
    const isTrashed = !!ticket.trashed_at;
    const isClosed = isArchived || isTrashed;

    const isBoardPrivate = ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE;

    const isGithubIntegrationActive = integrationsSetupStatus.github;
    const isCodeEnabled = ticket.board.settings[CommonEnums.BoardSettingType.CODE]?.enabled;

    useHotkey(
        "E",
        () => handleEdit?.(),
        { enabled: !!(isMenuOpen && handleEdit), scopes: [Enums.HotkeyScope.MENU] },
        [handleEdit]
    );

    const cardMenuItems = divideAndFlattenGroups({
        itemGroups: [
            [
                handleMoveToNextStage &&
                stage &&
                stage.role !== CommonEnums.StageRole.COMPLETE &&
                nextStage &&
                !isClosed ? (
                    <MenuItem
                        key="move_forward"
                        icon={<Icon icon="arrow-right" iconSet="lucide" iconSize={18} />}
                        text={`Move to ${nextStage.display_name}`}
                        instrumentation={{
                            elementName: "card.menu.move_forward",
                            eventData: {
                                ticketId: ticket.id,
                                toStageId: nextStage.id,
                            },
                        }}
                        onClick={handleMoveToNextStage}
                    />
                ) : null,

                handleMoveToPreviousStage && prevStage && !isClosed ? (
                    <MenuItem
                        key="move_backward"
                        icon={<Icon icon="arrow-left" iconSet="lucide" iconSize={18} />}
                        text={`Move back to ${prevStage.display_name}`}
                        instrumentation={{
                            elementName: "card.menu.move_backward",
                            eventData: {
                                ticketId: ticket.id,
                                toStageId: prevStage.id,
                            },
                        }}
                        onClick={handleMoveToPreviousStage}
                    />
                ) : null,
                handleMoveToStartOfStage && stage && !isFirstTicketInStage && !isClosed ? (
                    <MenuItem
                        key="move_to_top"
                        icon={<Icon icon="arrow-up" iconSet="lucide" iconSize={18} />}
                        text={`Move to top of ${stage.display_name}`}
                        instrumentation={{
                            elementName: "card.menu.move_to_top",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleMoveToStartOfStage}
                    />
                ) : null,
                handleMoveToEndOfStage && stage && !isLastTicketInStage && !isClosed ? (
                    <MenuItem
                        key="move_to_bottom"
                        icon={<Icon icon="arrow-down" iconSet="lucide" iconSize={18} />}
                        text={`Move to bottom of ${stage.display_name}`}
                        instrumentation={{
                            elementName: "card.menu.move_to_bottom",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleMoveToEndOfStage}
                    />
                ) : null,
                handleMoveTicket && !isClosed ? (
                    <Tooltip
                        key="move_to"
                        className={styles.menuItemTooltip}
                        content={
                            <span className={styles.menuItemTooltipContent}>
                                Topics cannot be moved out of private workspaces.
                            </span>
                        }
                        disabled={!isBoardPrivate}
                        placement="bottom"
                        small
                        wide
                    >
                        <BoardAndStagesMenuItem
                            icon={<Icon icon="truck" iconSet="lucide" iconSize={18} />}
                            text="Move to"
                            disabled={isBoardPrivate}
                            excludedStageIds={[ticket.stage_id]}
                            instrumentation={{
                                elementName: "card.menu.move_to",
                                eventData: {
                                    ticketId: ticket.id,
                                },
                            }}
                            onSelect={({ stageId }: { stageId: string }) =>
                                handleMoveTicket({ ticketId: ticket.id, toStageId: stageId })
                            }
                        />
                    </Tooltip>
                ) : null,
                handleEdit && isFeatureEnabled({ feature: Enums.Feature.QUICK_EDITS }) ? (
                    <MenuItem
                        key="edit"
                        icon={<Icon icon="edit-2" iconSet="lucide" iconSize={18} />}
                        labelElement={<Hotkey text="E" />}
                        text="Quick edit"
                        instrumentation={{
                            elementName: "card.menu.edit",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleEdit}
                    />
                ) : null,
                handleClone ? (
                    <MenuItem
                        key="clone"
                        icon={<Icon icon="files" iconSet="lucide" iconSize={18} />}
                        text="Duplicate"
                        instrumentation={{
                            elementName: "card.menu.clone",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleClone}
                    />
                ) : null,
            ].filter(Boolean),
            [
                handleOwnerRemove && !terminalStages.includes(stage?.role) && isCurrentUserOwner ? (
                    <MenuItem
                        key="stop_owning"
                        icon={<Icon icon="user-minus" iconSet="lucide" iconSize={18} />}
                        text="Remove me as owner"
                        instrumentation={{
                            elementName: "card.menu.stop_owning",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleOwnerRemove}
                    />
                ) : null,
                handleSetOwner && !terminalStages.includes(stage?.role) && !isCurrentUserOwner ? (
                    <MenuItem
                        key="start_owning"
                        icon={<Icon icon="user-plus" iconSet="lucide" iconSize={18} />}
                        text="Make me owner"
                        instrumentation={{
                            elementName: "card.menu.start_owning",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleSetOwner}
                    />
                ) : null,
                toggleWatching &&
                !isCurrentUserOwner &&
                !isCurrentUserMember &&
                isCurrentUserWatcher ? (
                    <MenuItem
                        key="stop_watching"
                        icon={<Icon icon="eye-off" iconSet="lucide" iconSize={18} />}
                        text="Unsubscribe from notifications"
                        instrumentation={{
                            elementName: "card.menu.stop_watching",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={toggleWatching}
                    />
                ) : null,
                toggleWatching &&
                !isCurrentUserOwner &&
                !isCurrentUserMember &&
                !isCurrentUserWatcher ? (
                    <MenuItem
                        key="start_watching"
                        icon={<Icon icon="eye" iconSet="lucide" iconSize={18} />}
                        text="Subscribe to notifications"
                        instrumentation={{
                            elementName: "card.menu.start_watching",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={toggleWatching}
                    />
                ) : null,
            ].filter(Boolean),
            [
                handleCopyBranchName &&
                isGithubIntegrationActive &&
                isCodeEnabled &&
                !terminalStages.includes(stage?.role) ? (
                    <MenuItem
                        key="copy_branch"
                        icon={<Icon icon="codeBranch" iconSet="c9r" iconSize={18} />}
                        text="Copy branch name"
                        instrumentation={{
                            elementName: "card.menu.copy_branch",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleCopyBranchName}
                    />
                ) : null,
                handleCopyTicketLink && (
                    <MenuItem
                        key="copy_link"
                        text="Copy link"
                        icon={<Icon icon="link" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "card.menu.copy_link",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleCopyTicketLink}
                    />
                ),
            ].filter(Boolean),
            [
                handleArchive && (
                    <MenuItem
                        key="archive"
                        icon={<Icon icon="archive" iconSet="lucide" iconSize={18} />}
                        text="Archive"
                        instrumentation={{
                            elementName: "card.menu.archive",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleArchive}
                    />
                ),
                handleMoveToTrash && (
                    <MenuItem
                        key="trash"
                        icon={<Icon icon="trash" iconSet="lucide" iconSize={18} />}
                        text="Move to trash"
                        instrumentation={{
                            elementName: "card.menu.trash",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onClick={handleMoveToTrash}
                    />
                ),
            ],
        ],
        divider: i => <MenuDivider key={i} />,
    });

    return (
        <div
            className={className}
            onClick={e => {
                // Sweet hack to keep clicking on the menu button from opening the ticket details page.
                e.stopPropagation();
            }}
        >
            <MenuPopover
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [8, -4],
                        },
                    },
                }}
                content={<Menu>{cardMenuItems}</Menu>}
                placement="bottom-end"
                onOpening={() => setIsMenuOpen(true)}
                onClosing={() => setIsMenuOpen(false)}
            >
                <EllipsisButton
                    className={classNames(
                        styles.menuBtn,
                        isMenuOpen && styles.menuOpen,
                        isMenuOpen && CssClasses.MENU_TARGET_ACTIVE
                    )}
                    vertical
                    instrumentation={null}
                    active={isMenuOpen}
                />
            </MenuPopover>
        </div>
    );
}

function useDefaultCardMenuHandlers({
    ticket: _ticketFragment,
}: {
    ticket: FragmentType<typeof fragments.ticket>;
}) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const currentUser = useCurrentUser();
    const userId = currentUser.id;
    const ticketId = ticket.id;

    const { isCurrentUserWatcher } = useTicketWatcherInfo({ ticket });

    const {
        archiveTicket,
        cloneTicket,
        moveTicketToStartOfCurrentStage,
        moveTicketToEndOfCurrentStage,
        moveTicketByDirectionInWorkflow,
        setTicketOwner,
        removeTicketOwner,
        startWatchingTicket,
        stopWatchingTicket,
        trashTicket,
    } = useMutations();

    const { moveTicketsUX } = useMoveTicketsUX();

    const { copyTextToClipboard } = useClipboard();
    const { buildTicketUrl } = useUrlBuilders();
    const { setTicketOwnerToCurrentUserUX } = useSetTicketOwnerToCurrentUserUX({
        ticketId: ticket.id,
        ticketRef: ticket.ref,
    });

    const handleClone = useCallback(async () => {
        const result = await cloneTicket({ sourceTicketId: ticketId });

        if (!result) {
            AppToaster.error({
                message: "Something went wrong duplicating that topic.",
            });
            return;
        }

        AppData.scrollToTicketSetAt = Date.now();
        AppData.scrollToTicketId = result.ticketId;
    }, [cloneTicket, ticketId]);

    // As of December 2023, this is intentionally null. This handler must provided by the caller,
    // because not all pages of the app where CardMenu is used support multiselection/editing.
    const handleEdit = null;

    const handleMoveToStartOfStage = useCallback(async () => {
        if (!ticket.stage_id) {
            return;
        }

        await moveTicketToStartOfCurrentStage({ ticketId, onStageId: ticket.stage_id });
    }, [moveTicketToStartOfCurrentStage, ticketId, ticket.stage_id]);

    const handleMoveToEndOfStage = useCallback(async () => {
        if (!ticket.stage_id) {
            return;
        }

        await moveTicketToEndOfCurrentStage({ ticketId, onStageId: ticket.stage_id });
    }, [moveTicketToEndOfCurrentStage, ticketId, ticket.stage_id]);

    const handleMoveToPreviousStage = useCallback(async () => {
        if (!ticket.stage_id) {
            return;
        }

        await moveTicketByDirectionInWorkflow({
            ticketId,
            fromStageId: ticket.stage_id,
            toStageDirection: CommonEnums.Direction.BACK,
        });
    }, [moveTicketByDirectionInWorkflow, ticketId, ticket.stage_id]);

    const handleMoveToNextStage = useCallback(async () => {
        if (!ticket.stage_id) {
            return;
        }

        await moveTicketByDirectionInWorkflow({
            ticketId,
            fromStageId: ticket.stage_id,
            toStageDirection: CommonEnums.Direction.FORWARD,
        });
    }, [moveTicketByDirectionInWorkflow, ticketId, ticket.stage_id]);

    const handleMoveTicket = useCallback(
        async ({ toStageId }: { toStageId: string }) => {
            await moveTicketsUX({ ticketIds: [ticketId], toStageId });
        },
        [moveTicketsUX, ticketId]
    );

    const handleSetOwner = useCallback(async () => {
        await setTicketOwner({ ticketId, userId });
    }, [setTicketOwner, ticketId, userId]);

    const handleOwnerRemove = useCallback(async () => {
        await removeTicketOwner({ ticketId });
    }, [removeTicketOwner, ticketId]);

    const handleCopyBranchName = useCallback(async () => {
        await Promise.all([
            copyTextToClipboard({
                text: Transforms.ticketToBranchName({
                    ref: ticket.ref,
                    title: ticket.title,
                }),
                successToast: "Branch name copied to clipboard.",
            }),
            !ticket.owners.length && setTicketOwnerToCurrentUserUX(),
        ]);
    }, [copyTextToClipboard, setTicketOwnerToCurrentUserUX, ticket]);

    const handleCopyTicketLink = useCallback(async () => {
        await copyTextToClipboard({
            text: `${Config.urls.public}${
                buildTicketUrl({
                    ticketSlug: ticket.slug,
                    vanity: {
                        boardDisplayName: ticket.board.display_name,
                        ticketRef: ticket.ref,
                        ticketTitle: ticket.title,
                    },
                }).pathname
            }`,
            successToast: "Link copied to clipboard.",
        });
    }, [buildTicketUrl, copyTextToClipboard, ticket]);

    const toggleWatching = useCallback(async () => {
        if (isCurrentUserWatcher) {
            await stopWatchingTicket({ ticketId });
        } else {
            await startWatchingTicket({ ticketId });
        }
    }, [isCurrentUserWatcher, startWatchingTicket, stopWatchingTicket, ticketId]);

    const handleArchive = useCallback(async () => {
        await archiveTicket({ ticketId });
    }, [archiveTicket, ticketId]);

    const handleMoveToTrash = useCallback(async () => {
        await trashTicket({ ticketId });
    }, [trashTicket, ticketId]);

    return useMemo(
        () => ({
            handleClone,
            handleEdit,
            handleMoveToStartOfStage,
            handleMoveToEndOfStage,
            handleMoveToPreviousStage,
            handleMoveToNextStage,
            handleMoveTicket,
            handleSetOwner,
            handleOwnerRemove,
            handleCopyBranchName,
            handleCopyTicketLink,
            toggleWatching,
            handleArchive,
            handleMoveToTrash,
        }),
        [
            handleClone,
            handleEdit,
            handleMoveToStartOfStage,
            handleMoveToEndOfStage,
            handleMoveToPreviousStage,
            handleMoveToNextStage,
            handleMoveTicket,
            handleSetOwner,
            handleOwnerRemove,
            handleCopyBranchName,
            handleCopyTicketLink,
            toggleWatching,
            handleArchive,
            handleMoveToTrash,
        ]
    );
}

function useCardMenuHandler<T>(defaultHandler: T, providedHandler?: T | null) {
    if (providedHandler === null) {
        return null;
    }

    return providedHandler ?? defaultHandler;
}

export type CardMenuProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
    isFirstTicketInStage?: boolean;
    isLastTicketInStage?: boolean;
    prevStageId?: string;
    nextStageId?: string;
} & Partial<CardMenuHandlers>;

export function CardMenu({
    className,
    ticket: _ticketFragment,
    isFirstTicketInStage,
    isLastTicketInStage,
    handleClone: _handleClone,
    handleEdit: _handleEdit,
    handleMoveToStartOfStage: _handleMoveToStartOfStage,
    handleMoveToEndOfStage: _handleMoveToEndOfStage,
    handleMoveToPreviousStage: _handleMoveToPreviousStage,
    handleMoveToNextStage: _handleMoveToNextStage,
    handleMoveTicket: _handleMoveTicket,
    handleOwnerRemove: _handleOwnerRemove,
    handleSetOwner: _handleSetOwner,
    toggleWatching: _toggleWatching,
    handleCopyBranchName: _handleCopyBranchName,
    handleCopyTicketLink: _handleCopyTicketLink,
    handleArchive: _handleArchive,
    handleMoveToTrash: _handleMoveToTrash,
}: CardMenuProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const { isCurrentUserOwner, isCurrentUserMember } = useTicketOwnershipInfo({ ticket });
    const { isCurrentUserWatcher } = useTicketWatcherInfo({ ticket });

    const { stage, prevStage, nextStage } = useCardMenuStages({ stageId: ticket.stage_id });

    const defaultHandlers = useDefaultCardMenuHandlers({ ticket: _ticketFragment });

    return (
        <CardMenuDisplay
            className={className}
            ticket={_ticketFragment}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserMember={isCurrentUserMember}
            isCurrentUserWatcher={isCurrentUserWatcher}
            isFirstTicketInStage={isFirstTicketInStage}
            isLastTicketInStage={isLastTicketInStage}
            stage={stage}
            prevStage={prevStage}
            nextStage={nextStage}
            handleClone={useCardMenuHandler(defaultHandlers.handleClone, _handleClone)}
            handleEdit={useCardMenuHandler(defaultHandlers.handleEdit, _handleEdit)}
            handleMoveTicket={useCardMenuHandler(
                defaultHandlers.handleMoveTicket,
                _handleMoveTicket
            )}
            handleMoveToStartOfStage={useCardMenuHandler(
                defaultHandlers.handleMoveToStartOfStage,
                _handleMoveToStartOfStage
            )}
            handleMoveToEndOfStage={useCardMenuHandler(
                defaultHandlers.handleMoveToEndOfStage,
                _handleMoveToEndOfStage
            )}
            handleMoveToPreviousStage={useCardMenuHandler(
                defaultHandlers.handleMoveToPreviousStage,
                _handleMoveToPreviousStage
            )}
            handleMoveToNextStage={useCardMenuHandler(
                defaultHandlers.handleMoveToNextStage,
                _handleMoveToNextStage
            )}
            handleOwnerRemove={useCardMenuHandler(
                defaultHandlers.handleOwnerRemove,
                _handleOwnerRemove
            )}
            handleSetOwner={useCardMenuHandler(defaultHandlers.handleSetOwner, _handleSetOwner)}
            toggleWatching={useCardMenuHandler(defaultHandlers.toggleWatching, _toggleWatching)}
            handleCopyBranchName={useCardMenuHandler(
                defaultHandlers.handleCopyBranchName,
                _handleCopyBranchName
            )}
            handleCopyTicketLink={useCardMenuHandler(
                defaultHandlers.handleCopyTicketLink,
                _handleCopyTicketLink
            )}
            handleArchive={useCardMenuHandler(defaultHandlers.handleArchive, _handleArchive)}
            handleMoveToTrash={useCardMenuHandler(
                defaultHandlers.handleMoveToTrash,
                _handleMoveToTrash
            )}
        />
    );
}

CardMenu.Display = CardMenuDisplay;
