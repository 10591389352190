import React, { useEffect } from "react";

import { Config } from "Config";
import { useSetIsViewReady } from "components/loading/Loading";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { SupportMailto } from "components/shared/SupportMailto";
import { BorderButton } from "components/ui/core/BorderButton";
import { useDocumentTitle } from "lib/Hooks";

import styles from "./ErrorView.module.scss";

export type ErrorViewProps = {
    message?: string;
    showCaption?: boolean;
    showRefreshButton?: boolean;
};

export function ErrorView({
    message,
    showCaption = true,
    showRefreshButton = true,
}: ErrorViewProps) {
    const setIsViewReady = useSetIsViewReady();

    useDocumentTitle("Error");

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    return (
        <div className={styles.errorPage}>
            <div className={styles.wrapper}>
                <BrandLogomark className={styles.logomark} height={30} />
                <p className={styles.message}>{message || "Oops, something went wrong."}</p>

                {showCaption ? (
                    <>
                        <p className={styles.caption}>
                            Our team has been alerted. Meantime, try refreshing the page or visiting{" "}
                            <a href={Config.urls.status} target="_blank" rel="noopener noreferrer">
                                {Config.urls.status}
                            </a>{" "}
                            for any announcements.
                        </p>
                        <p className={styles.caption}>
                            If the error persists, <SupportMailto text="contact us" />.
                        </p>
                    </>
                ) : null}

                {showRefreshButton ? (
                    <p className={styles.link}>
                        <BorderButton
                            small
                            minimal
                            content="Refresh"
                            onClick={() => window.location.reload()}
                            instrumentation={{
                                elementName: "error_view.refresh_btn",
                            }}
                        />
                    </p>
                ) : null}
            </div>
        </div>
    );
}
