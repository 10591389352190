import React, { useCallback, useEffect, useRef } from "react";

import { CommonEnums, TicketDueDates, TicketSizes, ValueOf } from "c9r-common";
import isEqual from "react-fast-compare";

import { Label } from "components/shared/Label";
import { Avatar } from "components/ui/common/Avatar";
import {
    FilterableSelector,
    FilterableSelectorProps,
} from "components/ui/common/FilterableSelector";
import { Icon, IconProps } from "components/ui/core/Icon";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { Enums } from "lib/Enums";
import { divideAndFlattenGroups } from "lib/Helpers";
import { useMaybeControlledValue } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import {
    TicketActionSectionType,
    TicketActionSpec,
    TicketActionType,
    suggestTicketActions,
    useExecuteTicketAction,
} from "lib/TicketActions";
import { FragmentType, getFragmentData, gql, makeFragmentData } from "lib/graphql/__generated__";
import { Avatar_userFragmentDoc } from "lib/graphql/__generated__/graphql";

import styles from "./TicketActionsSelector.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment TicketActionsSelector_board on boards {
            id

            tickets {
                id

                owners {
                    ticket_id
                    user_id
                    type
                }
            }

            ...TicketActions_board
        }
    `),
};

const actionSectionTypesInDisplayOrder = [
    TicketActionSectionType.OWNERSHIP,
    TicketActionSectionType.LABELS,
    TicketActionSectionType.LOCATION,
    TicketActionSectionType.MISC,
    TicketActionSectionType.FREE_TEXT,
];

function renderLabelActionTarget(
    action: TicketActionSpec & { type: "ADD_LABEL" | "CREATE_LABEL" | "REMOVE_LABEL" }
) {
    return (
        <Label
            className={styles.actionItemTargetLabel}
            color={action.target.label.color}
            text={action.target.label.text}
        />
    );
}

function renderUserActionTarget(action: TicketActionSpec & { sectionType: "OWNERSHIP" }) {
    return (
        <span className={styles.actionItemContentUser}>
            <Avatar
                size={24}
                user={makeFragmentData(
                    {
                        id: action.target.user.id,
                        full_name: action.target.user.fullName,
                        name: action.target.user.name,
                        avatar_url: action.target.user.avatarUrl,
                    },
                    Avatar_userFragmentDoc
                )}
            />
            {action.target.user.name}
        </span>
    );
}

type TActionItemContent<T extends ValueOf<typeof TicketActionType>> = {
    iconProps: IconProps;
    title: React.ReactNode;
    connectingText?: React.ReactNode;
    renderTarget?: (action: TicketActionSpec & { type: T }) => React.ReactNode;
};

const actionItemContentMap: {
    [T in ValueOf<typeof TicketActionType>]: TActionItemContent<T>;
} = {
    [TicketActionType.ADD_LABEL]: {
        iconProps: { icon: "tag", iconSet: "lucide" },
        title: "Add label",
        renderTarget: renderLabelActionTarget,
    },
    [TicketActionType.ADD_MEMBER]: {
        iconProps: { icon: "user", iconSet: "lucide" },
        title: "Add collaborator",
        renderTarget: renderUserActionTarget,
    },
    [TicketActionType.ADD_OWNER]: {
        iconProps: { icon: "user", iconSet: "lucide" },
        title: "Set owner",
        connectingText: "to",
        renderTarget: renderUserActionTarget,
    },
    [TicketActionType.ARCHIVE]: {
        iconProps: { icon: "archive", iconSet: "lucide" },
        title: "Archive",
    },
    [TicketActionType.CHANGE_BOARD]: {
        iconProps: { icon: "truck", iconSet: "lucide" },
        title: "Move to",
        renderTarget: action => (
            <>
                {action.target.board.accessType === CommonEnums.BoardAccessType.PRIVATE ? (
                    <Icon
                        className={styles.actionItemTargetMoveIcon}
                        icon="lock"
                        iconSet="c9r"
                        iconSize={14}
                        strokeWidthAbsolute={1.5}
                        textInline
                    />
                ) : (
                    <Icon
                        className={styles.actionItemTargetMoveIcon}
                        icon="spaceFrame"
                        iconSet="c9r"
                        iconSize={14}
                        textInline
                    />
                )}{" "}
                {action.target.board.displayName} — {action.target.stage.displayName}
            </>
        ),
    },
    [TicketActionType.CHANGE_DUE_DATE]: {
        iconProps: { icon: "calendar", iconSet: "lucide" },
        title: "Change due date",
        connectingText: "to",
        renderTarget: action =>
            TicketDueDates.format({ dueDate: action.target.dueDate, shouldLowerCase: true }),
    },
    [TicketActionType.CHANGE_SIZE]: {
        iconProps: { icon: "bar-chart", iconSet: "lucide" },
        title: "Change size",
        connectingText: "to",
        renderTarget: action => TicketSizes.format(action.target.sizeSpec),
    },
    [TicketActionType.CHANGE_STAGE_FORWARD]: {
        iconProps: { icon: "arrow-right", iconSet: "lucide" },
        title: "Move to",
        renderTarget: action => action.target.stage.displayName,
    },
    [TicketActionType.CHANGE_STAGE_BACK]: {
        iconProps: { icon: "arrow-left", iconSet: "lucide" },
        title: "Move back to",
        renderTarget: action => action.target.stage.displayName,
    },
    [TicketActionType.CHANGE_TITLE]: {
        iconProps: { icon: "edit-2", iconSet: "lucide" },
        title: "Change title",
        connectingText: "to",
        renderTarget: action => `"${action.target.title}"`,
    },
    [TicketActionType.CREATE_LABEL]: {
        iconProps: { icon: "tag", iconSet: "lucide" },
        title: "Create and add label",
        renderTarget: renderLabelActionTarget,
    },
    [TicketActionType.REMOVE_LABEL]: {
        iconProps: { icon: "tag", iconSet: "lucide" },
        title: "Remove label",
        renderTarget: renderLabelActionTarget,
    },
    [TicketActionType.REMOVE_MEMBER]: {
        iconProps: { icon: "user", iconSet: "lucide" },
        title: "Remove collaborator",
        renderTarget: renderUserActionTarget,
    },
    [TicketActionType.REMOVE_OWNER]: {
        iconProps: { icon: "user", iconSet: "lucide" },
        title: "Remove owner",
        renderTarget: renderUserActionTarget,
    },
    [TicketActionType.TRASH]: {
        iconProps: { icon: "trash", iconSet: "lucide" },
        title: "Move to trash",
    },
} as const;

type ActionItemContentProps = {
    action: TicketActionSpec;
};

function ActionItemContent({ action }: ActionItemContentProps) {
    const { iconProps, title, connectingText, renderTarget } = actionItemContentMap[action.type];

    return (
        <div data-cy="ticket-actions-selector-action-content" className={styles.actionItemContent}>
            <Icon iconSize={18} {...iconProps} />
            <span className={styles.actionItemContentDescription}>
                <strong>{title}</strong>
                {connectingText ? <>&nbsp;{connectingText}</> : null}&nbsp;
                {
                    // @ts-expect-error
                    renderTarget ? renderTarget(action) : null
                }
            </span>
        </div>
    );
}

type TicketActionsSelectorProps = {
    board: FragmentType<typeof fragments.board>;
    ticketIds: string[];
} & Omit<
    FilterableSelectorProps<TicketActionSpec>,
    | "items"
    | "itemsEqual"
    | "itemListRenderer"
    | "menuItemClassName"
    | "menuItemsClassName"
    | "menuItemTextRenderer"
    | "menuProps"
>;

export function TicketActionsSelector({
    board: _boardFragment,
    ticketIds,
    ...filterableSelectorProps
}: TicketActionsSelectorProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const { recordEvent } = useInstrumentation();

    const MAX_ACTIONS_PER_SECTION = 100;

    const filterInputRefInternal = useRef<HTMLInputElement>(null);
    const filterInputRef = filterableSelectorProps.filterInputRef ?? filterInputRefInternal;

    const [query, onQueryChange] = useMaybeControlledValue({
        value: filterableSelectorProps.query,
        onValueChange: filterableSelectorProps.onQueryChange,
        defaultValue: "",
    });

    const suggestedActions = query
        ? suggestTicketActions({
              query,
              sectionTypes: actionSectionTypesInDisplayOrder,
              data: { board, ticketIds },
          })
        : [];

    const { executeAction } = useExecuteTicketAction({ board, ticketIds });
    const selectionChangedKey = ticketIds.sort((a, b) => a.localeCompare(b)).join("|");

    useEffect(() => {
        filterInputRef.current?.focus();
    }, [filterInputRef, selectionChangedKey]);

    const handleSelect = useCallback(
        async (action: TicketActionSpec) => {
            void executeAction({ action });
            void recordEvent({
                eventType: Enums.InstrumentationEvent.CLICK,
                elementName: `${filterableSelectorProps.elementName}.action`,
                eventData: {
                    actionType: action.type,
                    actionTarget: action.target,
                    ticketCount: ticketIds.length,
                },
            });

            filterInputRef.current?.focus();
        },
        [
            filterableSelectorProps.elementName,
            executeAction,
            filterInputRef,
            recordEvent,
            ticketIds.length,
        ]
    );

    return (
        <FilterableSelector
            itemsPerPage={8}
            paddingRelaxed
            resetActiveItemOnEmptyQuery
            scrollable
            showCloseButton
            {...filterableSelectorProps}
            filterCaption={
                <>
                    Editing {ticketIds.length.toLocaleString()}{" "}
                    {ticketIds.length === 1 ? "topic" : "topics"}
                </>
            }
            filterIconProps={{
                icon: "edit-2",
                iconSet: "lucide",
                iconSize: 14,
            }}
            filterInputRef={filterInputRef}
            filterPlaceholder={
                ticketIds.length === 1
                    ? "Enter a label, a user's name, a new title, a workspace name..."
                    : "Enter a label, a user's name, a workspace name..."
            }
            items={suggestedActions}
            itemsEqual={isEqual}
            itemListRenderer={({ items: actions, renderItem }) => {
                if (!query) {
                    return null;
                }

                return (
                    <>
                        {divideAndFlattenGroups({
                            itemGroups: actionSectionTypesInDisplayOrder.map(sectionType =>
                                actions
                                    .filter(action => action.sectionType === sectionType)
                                    .slice(0, MAX_ACTIONS_PER_SECTION)
                                    .map(action => renderItem({ item: action }))
                            ),
                            divider: () => <MenuDivider className={styles.divider} />,
                        })}
                    </>
                );
            }}
            menuProps={{ className: styles.menu }}
            menuItemClassName={styles.menuItem}
            menuItemsClassName={styles.menuItems}
            menuItemTextRenderer={action => <ActionItemContent action={action} />}
            onSelect={handleSelect}
            onQueryChange={onQueryChange}
            query={query}
        />
    );
}
