import React from "react";

import classNames from "classnames";

import styles from "./ContentBox.module.scss";

export type ContentBoxProps = {
    className?: string;
    children: React.ReactNode;
};

/**
 * The central box in the middle of the onboarding wizard pages.
 */
export function ContentBox({ className, children }: ContentBoxProps) {
    return <div className={classNames(className, styles.contentBox)}>{children}</div>;
}
