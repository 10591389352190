import React from "react";

import classNames from "classnames";

import { TimeAgo } from "components/ui/common/TimeAgo";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./CommentThreadEvent.module.scss";

const fragments = {
    ticket_history_event: gql(/* GraphQL */ `
        fragment CommentThreadEvent_ticket_history_event on ticket_history_events {
            id
            event_type
            event_at

            user {
                id
                name
            }

            event_on_user {
                id
                name
            }
        }
    `),
};

function EventNodeIcon() {
    return (
        <div className={styles.eventNodeIcon}>
            <Icon className={styles.circle} icon="circle" iconSet="c9r" iconSize={9} />
        </div>
    );
}

export type CommentThreadEventProps = {
    className?: string;
    ticketHistoryEvent: FragmentType<typeof fragments.ticket_history_event>;
};

export function CommentThreadEvent({
    className,
    ticketHistoryEvent: _ticketHistoryEventFragment,
}: CommentThreadEventProps) {
    const ticketHistoryEvent = getFragmentData(
        fragments.ticket_history_event,
        _ticketHistoryEventFragment
    );
    const currentUser = useCurrentUser();
    const userName =
        ticketHistoryEvent.user?.id === currentUser.id
            ? "me"
            : ticketHistoryEvent.user?.name || "Flat";
    const affectedUserName =
        ticketHistoryEvent.event_on_user?.id === currentUser.id
            ? "me"
            : ticketHistoryEvent.event_on_user?.name;

    const eventText = {
        OPEN_THREAD: `Opened by ${userName}`,
        ASSIGN_THREAD: `Assigned to ${affectedUserName}`,
        RESOLVE_THREAD: `Resolved by ${userName}`,
        REOPEN_THREAD: `Reopened by ${userName}`,
    }[ticketHistoryEvent.event_type];

    if (!eventText) {
        return null;
    }

    return (
        <li className={classNames(className, styles.threadEvent)}>
            {ticketHistoryEvent.event_type === "RESOLVE_THREAD" ? (
                <Icon
                    className={styles.resolvedIcon}
                    icon="check-circle"
                    iconSet="lucide"
                    iconSize={16}
                />
            ) : (
                <EventNodeIcon />
            )}
            <div className={styles.content}>
                <span className={styles.text}>{eventText}</span>
                &nbsp;
                <TimeAgo className={styles.date} date={ticketHistoryEvent.event_at} />
            </div>
        </li>
    );
}
