import React from "react";

import classNames from "classnames";

import { useFeedbackDialog } from "dialogs/FeedbackDialog";
import { useSemanticBreakpoints } from "lib/Breakpoints";
import { CssClasses } from "lib/Constants";
import { useIsTitleBarHidden } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import { BoardPicker } from "./BoardPicker";
import { FeedbackButton } from "./FeedbackButton";
import { History } from "./History";
import styles from "./NavBar.module.scss";
import { SearchBox } from "./SearchBox";
import { SideNavDisplayToggle } from "./SideNavDisplayToggle";
import { UserIcon } from "./UserIcon";
import { MyWorkButton } from "./myWork/MyWorkButton";
import { MyWorkProvider } from "./myWork/MyWorkContext";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment NavBar_org on orgs {
            id

            boards(where: { archived_at: { _is_null: true } }) {
                id
                display_name
            }

            ...BoardPicker_org
        }
    `),
};

export type NavBarProps = {
    className?: string;
    org?: FragmentType<typeof fragments.org>;
};

export function NavBar({ className, org: _orgFragment }: NavBarProps) {
    const feedbackDialog = useFeedbackDialog();
    const semanticBreakpoints = useSemanticBreakpoints();
    const isTitleBarHidden = useIsTitleBarHidden();

    const org = getFragmentData(fragments.org, _orgFragment);

    return (
        <div id={styles.navbar} className={classNames(className)}>
            {isTitleBarHidden ? <div className={classNames(styles.trafficLightsSpacer)} /> : null}
            <div className={classNames(styles.wrapper, styles.leftWrapper)}>
                {org ? (
                    semanticBreakpoints.isSideNavEnabled ? (
                        <SideNavDisplayToggle contentClassName={styles.sideNavDisplayToggle} />
                    ) : (
                        <BoardPicker org={org} />
                    )
                ) : null}
            </div>
            <div
                className={classNames(
                    styles.wrapper,
                    styles.centerWrapper,
                    CssClasses.DESKTOP_ONLY
                )}
            >
                <div className={styles.searchWrapper}>
                    <SearchBox />
                    <History />
                </div>
            </div>
            <div className={classNames(styles.wrapper, styles.rightWrapper)}>
                <MyWorkProvider>
                    <MyWorkButton />
                </MyWorkProvider>
                <FeedbackButton className={CssClasses.DESKTOP_ONLY} onClick={feedbackDialog.open} />
                <UserIcon />
            </div>
        </div>
    );
}
