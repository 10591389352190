import { useEffect, useState } from "react";

import { useSlackIntegration } from "lib/Integrations";

export function SlackOAuth2Redirect() {
    const [didAttempt, setDidAttempt] = useState(false);
    const { connectSlack } = useSlackIntegration();

    useEffect(() => {
        if (!didAttempt) {
            setDidAttempt(true);
            void connectSlack();
        }
    }, [didAttempt, connectSlack]);

    return null;
}
