import { useCallback, useEffect, useState } from "react";

import { CommonEnums } from "c9r-common";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { Storage } from "lib/Storage";
import { isAccessTokenAvailableState, useSetOrReplaceAccessToken } from "lib/auth/AccessToken";

const isPrefetching = !!Storage.Session.getNumber("demo.credentials.prefetchStartedAt");
let isRefreshing = !!Storage.Session.getNumber("demo.credentials.refreshStartedAt");
let identityId: string | null = null;

export const getCurrentDemoIdentityId = () => identityId;

/**
 * Helper hook to request an access token and user identity for the demo environment.
 * The access token and identityId are cached in local storage, and the demo is forced to refresh
 * once they expire.
 */
export const useDemoAccessTokenManager = ({
    onApproachingExpiration,
}: {
    onApproachingExpiration?: () => void;
}) => {
    const setOrReplaceAccessToken = useSetOrReplaceAccessToken();
    const isAccessTokenAvailable = useRecoilValue(isAccessTokenAvailableState);
    const [accessTokenExpiresAt, setAccessTokenExpiresAt] = useState<number | null>(null);
    const [error, setError] = useState<{ code?: string } | null>(null);

    const refreshAccessToken = useCallback(async () => {
        if (isRefreshing || isPrefetching) {
            return;
        }

        isRefreshing = true;
        Storage.Session.setNumber("demo.credentials.refreshStartedAt", Date.now());

        try {
            Log.info("Requesting new demo access token");

            const res = await fetch(Config.api.urlHttp, {
                method: "POST",
                body: JSON.stringify({
                    query: `
                        mutation {
                            demo_login {
                                ok
                                error
                                access_token
                                identity_id
                                expires_in
                            }
                        }
                    `,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const json = await res.json();
            const { data } = json;

            if (!data?.demo_login?.ok) {
                Log.error("Access token request failed", {
                    error: data?.demo_login?.error,
                    json,
                });
                setError(data?.demo_login?.error);

                return;
            }

            // We must set identityId before calling setOrReplaceAccessToken, so that
            // getCurrentDemoIdentityId already has the identityId value by the time we
            // try to start using the access token.
            identityId = data.demo_login.identity_id;

            const expiresAt = data.demo_login.expires_in * 1000 + Date.now();

            Storage.Local.setItem("demo.credentials", {
                accessToken: data.demo_login.access_token,
                identityId,
                expiresAt,
            });

            setAccessTokenExpiresAt(expiresAt);
            setError(null);

            Log.info("Starting demo with new access token", { identityId });

            setOrReplaceAccessToken(data.demo_login.access_token);
        } catch (e) {
            Log.error("Failed to get access token", { error: e });
            setError(e as { code?: string });
        } finally {
            isRefreshing = false;
            Storage.Session.removeItem("demo.credentials.refreshStartedAt");
        }
    }, [setOrReplaceAccessToken]);

    useEffect(() => {
        if (!isAccessTokenAvailable) {
            const savedCredentials = Storage.Local.getItem("demo.credentials");

            if (
                savedCredentials &&
                savedCredentials.expiresAt > Date.now() + Config.auth.preExpirationPromptMs
            ) {
                // We must set identityId before calling setOrReplaceAccessToken, so that
                // getCurrentDemoIdentityId already has the identityId value by the time we
                // try to start using the access token.
                identityId = savedCredentials.identityId;

                setOrReplaceAccessToken(savedCredentials.accessToken);
                setAccessTokenExpiresAt(savedCredentials.expiresAt);

                Log.info("Starting demo with cached access token", { identityId });
            } else {
                Storage.Local.removeItem("demo.credentials");
                void refreshAccessToken();
            }
        }
    }, [isAccessTokenAvailable, refreshAccessToken, setOrReplaceAccessToken]);

    useEffect(() => {
        if (!accessTokenExpiresAt) {
            return undefined;
        }

        const promptTimeout = setTimeout(
            () => onApproachingExpiration?.(),
            accessTokenExpiresAt - Date.now() - Config.auth.preExpirationPromptMs
        );

        const refreshTimeout = setTimeout(() => {
            window.location.reload();
        }, accessTokenExpiresAt - Date.now() - Config.auth.preExpirationRefreshMs);

        return () => {
            clearTimeout(promptTimeout);
            clearTimeout(refreshTimeout);
        };
    }, [accessTokenExpiresAt, onApproachingExpiration]);

    useInterval(
        () => {
            if (!Storage.Session.getItem("demo.credentials.prefetchStartedAt")) {
                const savedCredentials = Storage.Local.getItem("demo.credentials");

                if (savedCredentials) {
                    // We must set identityId before calling setOrReplaceAccessToken, so that
                    // getCurrentDemoIdentityId already has the identityId value by the time we
                    // try to start using the access token.
                    identityId = savedCredentials.identityId;

                    setOrReplaceAccessToken(savedCredentials.accessToken);
                    setAccessTokenExpiresAt(savedCredentials.expiresAt);
                    setError(null);

                    Log.info("Starting demo with prefetched access token", { identityId });
                } else {
                    void refreshAccessToken();
                }
            }
        },
        isAccessTokenAvailable ? null : Config.accessTokenPrefetchPollIntervalMs
    );

    return {
        isDemoUnavailable: error?.code === CommonEnums.ActionErrorCode.ACTION_DEMO_UNAVAILABLE,
        error,
    };
};
