import React from "react";

import classNames from "classnames";

import styles from "./Hotkey.module.scss";

export type HotkeyProps = {
    className?: string;
    text: React.ReactNode;
};

export function Hotkey({ className, text }: HotkeyProps) {
    return <kbd className={classNames(className, styles.hotkey)}>{text}</kbd>;
}
