import React, { useCallback, useMemo, useState } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { BoardAndStagesMenuItem } from "components/shared/BoardAndStagesMenuItem";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Tooltip } from "components/ui/core/Tooltip";
import { useMutations } from "contexts/MutationsContext";
import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useCurrentUser } from "contexts/UserContext";
import { divideAndFlattenGroups } from "lib/Helpers";
import { useArchiveManyTicketsUX, useMoveTicketsUX, useTrashManyTicketsUX } from "lib/MutationUX";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./BulkActionCardMenu.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment BulkActionCardMenu_ticket on tickets {
            id
            board_id

            board {
                id
                access_type
            }
        }
    `),
};

type BulkActionCardMenuProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function BulkActionCardMenu({
    className,
    ticket: _ticketFragment,
}: BulkActionCardMenuProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const {
        getSelectedTicketInfo,
        selection,
        selectionStateForCurrentUser,
    } = useTicketSelectionContext();
    const selectedTicketIds = useMemo(() => Array.from(selection.ticketIds), [selection.ticketIds]);

    const currentUser = useCurrentUser();

    const { archiveManyTicketsUX } = useArchiveManyTicketsUX();
    const { trashManyTicketsUX } = useTrashManyTicketsUX();
    const { moveTicketsUX } = useMoveTicketsUX();
    const { bulkMutate } = useMutations();

    const handleMoveTickets = useCallback(
        async ({ stageId }: { stageId: string }) => {
            await moveTicketsUX({ ticketIds: selectedTicketIds, toStageId: stageId });
        },
        [moveTicketsUX, selectedTicketIds]
    );

    const handleSetOwner = useCallback(async () => {
        await bulkMutate({
            mutations: selectedTicketIds.map(ticketId => ({
                name: "updateTicketOwner",
                args: { ticketId, userId: currentUser.id },
            })),
        });
    }, [bulkMutate, currentUser.id, selectedTicketIds]);

    const handleRemoveOwner = useCallback(async () => {
        await bulkMutate({
            mutations: selectedTicketIds.map(ticketId => ({
                name: "updateTicketOwner",
                args: { ticketId, userId: null },
            })),
        });
    }, [bulkMutate, selectedTicketIds]);

    const handleStartWatching = useCallback(async () => {
        await bulkMutate({
            mutations: selectedTicketIds.map(ticketId => ({
                name: "updateTicketWatchers",
                args: { ticketId, userIdsToAdd: [currentUser.id] },
            })),
        });
    }, [bulkMutate, currentUser.id, selectedTicketIds]);

    const handleStopWatching = useCallback(async () => {
        await bulkMutate({
            mutations: selectedTicketIds.map(ticketId => ({
                name: "updateTicketWatchers",
                args: { ticketId, userIdsToRemove: [currentUser.id] },
            })),
        });
    }, [bulkMutate, currentUser.id, selectedTicketIds]);

    const handleArchive = useCallback(async () => {
        await archiveManyTicketsUX({
            tickets: selectedTicketIds.map(ticketId => ({
                id: ticketId,
                stage_pos: getSelectedTicketInfo({ ticketId }).stagePos,
            })),
        });
    }, [archiveManyTicketsUX, getSelectedTicketInfo, selectedTicketIds]);

    const handleTrash = useCallback(async () => {
        await trashManyTicketsUX({
            tickets: selectedTicketIds.map(ticketId => ({
                id: ticketId,
                stage_pos: getSelectedTicketInfo({ ticketId }).stagePos,
            })),
        });
    }, [selectedTicketIds, getSelectedTicketInfo, trashManyTicketsUX]);

    const isBoardPrivate = ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE;

    const menuItems = divideAndFlattenGroups({
        itemGroups: [
            [
                <Tooltip
                    key="move_to"
                    className={styles.menuItemTooltip}
                    content={
                        <span className={styles.menuItemTooltipContent}>
                            Topics cannot be moved out of private workspaces.
                        </span>
                    }
                    disabled={!isBoardPrivate}
                    placement="bottom"
                    small
                    wide
                >
                    <BoardAndStagesMenuItem
                        icon={<Icon icon="truck" iconSet="lucide" iconSize={18} />}
                        text="Move all to"
                        disabled={isBoardPrivate}
                        instrumentation={{
                            elementName: "card.menu.move_to",
                            eventData: {
                                ticketId: ticket.id,
                            },
                        }}
                        onSelect={handleMoveTickets}
                    />
                </Tooltip>,
            ],
            [
                selectionStateForCurrentUser.isSomeTicketUnowned ? (
                    <MenuItem
                        key="start_owning"
                        icon={<Icon icon="user-plus" iconSet="lucide" iconSize={18} />}
                        text="Make me owner"
                        instrumentation={{
                            elementName: "card.bulk_action_menu.start_owning",
                            eventData: {
                                ticketIds: selectedTicketIds,
                            },
                        }}
                        onClick={handleSetOwner}
                    />
                ) : null,
                selectionStateForCurrentUser.isSomeTicketOwned ? (
                    <MenuItem
                        key="stop_owning"
                        icon={<Icon icon="user-minus" iconSet="lucide" iconSize={18} />}
                        text="Remove me as owner"
                        instrumentation={{
                            elementName: "card.bulk_action_menu.stop_owning",
                            eventData: {
                                ticketIds: selectedTicketIds,
                            },
                        }}
                        onClick={handleRemoveOwner}
                    />
                ) : null,
                selectionStateForCurrentUser.isSomeTicketNotWatched ? (
                    <MenuItem
                        key="start_watching"
                        icon={<Icon icon="eye" iconSet="lucide" iconSize={18} />}
                        text="Subscribe to notifications"
                        instrumentation={{
                            elementName: "card.bulk_action_menu.start_watching",
                            eventData: {
                                ticketIds: selectedTicketIds,
                            },
                        }}
                        onClick={handleStartWatching}
                    />
                ) : null,
                selectionStateForCurrentUser.isSomeTicketWatched ? (
                    <MenuItem
                        key="stop_watching"
                        icon={<Icon icon="eye-off" iconSet="lucide" iconSize={18} />}
                        text="Unsubscribe from notifications"
                        instrumentation={{
                            elementName: "card.bulk_action_menu.stop_watching",
                            eventData: {
                                ticketIds: selectedTicketIds,
                            },
                        }}
                        onClick={handleStopWatching}
                    />
                ) : null,
            ],
            [
                <MenuItem
                    key="archive"
                    icon={<Icon icon="archive" iconSet="lucide" iconSize={18} />}
                    text={`Archive ${selectedTicketIds.length.toLocaleString()} ${
                        selectedTicketIds.length === 1 ? "topic" : "topics"
                    }`}
                    instrumentation={{
                        elementName: "card.bulk_action_menu.archive",
                        eventData: {
                            ticketIds: selectedTicketIds,
                        },
                    }}
                    onClick={handleArchive}
                />,
                <MenuItem
                    key="trash"
                    icon={<Icon icon="trash" iconSet="lucide" iconSize={18} />}
                    text={`Trash ${selectedTicketIds.length.toLocaleString()} ${
                        selectedTicketIds.length === 1 ? "topic" : "topics"
                    }`}
                    instrumentation={{
                        elementName: "card.bulk_action_menu.trash",
                        eventData: {
                            ticketIds: selectedTicketIds,
                        },
                    }}
                    onClick={handleTrash}
                />,
            ],
        ],
        divider: i => <MenuDivider key={i} />,
    });

    return (
        <div
            className={className}
            onClick={e => {
                // Sweet hack to keep clicking on the menu button from opening the ticket details page.
                e.stopPropagation();
            }}
        >
            <MenuPopover
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [8, -4],
                        },
                    },
                }}
                content={<Menu>{menuItems}</Menu>}
                placement="bottom-end"
                onOpening={() => setIsMenuOpen(true)}
                onClosing={() => setIsMenuOpen(false)}
            >
                <EllipsisButton
                    className={classNames(styles.menuBtn, isMenuOpen && styles.menuOpen)}
                    vertical
                    instrumentation={null}
                    active={isMenuOpen}
                />
            </MenuPopover>
        </div>
    );
}
