import React, { useCallback, useEffect, useRef, useState } from "react";

import { DbColumnTypes } from "c9r-common";

import { useLiveViewQuery } from "components/loading/Loading";
import { AppToaster } from "components/ui/core/AppToaster";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Toggle } from "components/ui/core/Toggle";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { setThemeInStorage } from "lib/Theming";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { AppearanceSettings_userFragment } from "lib/graphql/__generated__/graphql";
import { useSaveAppearancePreferences, useSaveSettings } from "lib/mutations";

import styles from "./AppearanceSettings.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment AppearanceSettings_user on users {
            id
            appearance_preferences
        }
    `),
};

const colorSchemeOptions = [
    { key: Enums.ColorScheme.LIGHT_ALWAYS, text: "Light always" },
    { key: Enums.ColorScheme.DARK_ALWAYS, text: "Dark always" },
    { key: Enums.ColorScheme.FOLLOW_SYSTEM, text: "Follow system" },
];

const defaultAppearencePreferences: DbColumnTypes.UsersAppearancePreferences = {
    colorScheme: Enums.ColorScheme.FOLLOW_SYSTEM,
};

type AppearancePreferencesFormGroupProps = {
    user: AppearanceSettings_userFragment;
};

function AppearancePreferencesFormGroup({ user }: AppearancePreferencesFormGroupProps) {
    const fetchedAppearancePreferences = user.appearance_preferences;

    // While a legacy dark mode user is on the page, we continue to show the option. But once
    // they select new dark mode or light mode and navigate away, they can no longer go back.
    const isLegacyDarkModeAvailable = useRef(
        fetchedAppearancePreferences.colorScheme !== Enums.ColorScheme.LIGHT_ALWAYS &&
            fetchedAppearancePreferences.HIGH_CONTRAST?.enabled === false
    );

    const [appearancePreferences, setAppearancePreferences] = useState<
        DbColumnTypes.UsersAppearancePreferences
    >(
        fetchedAppearancePreferences && Object.keys(fetchedAppearancePreferences).length
            ? fetchedAppearancePreferences
            : defaultAppearencePreferences
    );

    useEffect(() => {
        if (fetchedAppearancePreferences) {
            setAppearancePreferences(
                Object.keys(fetchedAppearancePreferences || {}).length
                    ? fetchedAppearancePreferences
                    : defaultAppearencePreferences
            );
        }
    }, [fetchedAppearancePreferences]);

    const { saveAppearancePreferences } = useSaveAppearancePreferences();

    const handleSubmit = useCallback(async () => {
        try {
            setThemeInStorage(appearancePreferences, user.id);
            await saveAppearancePreferences({ appearancePreferences });
        } catch (error) {
            Log.error("Failed to save user profile", { error });
            AppToaster.error({
                message: "Sorry, something went wrong saving your changes.",
            });
        }
    }, [appearancePreferences, user.id, saveAppearancePreferences]);

    const [preferencesUpdated, setPreferencesUpdated] = useState(false);

    useEffect(() => {
        if (preferencesUpdated) {
            void handleSubmit();
            setPreferencesUpdated(false);
        }
    }, [preferencesUpdated, handleSubmit]);

    return (
        <div className={styles.formGroup}>
            <h4>Theme</h4>
            <div className={styles.colorSchemeDropdown}>
                <MenuPopover
                    content={
                        <Menu className={styles.colorSchemeMenu}>
                            {colorSchemeOptions.map(({ key, text }) => (
                                <MenuItem
                                    key={key}
                                    text={text}
                                    onClick={() => {
                                        setAppearancePreferences(prev => ({
                                            ...prev,
                                            colorScheme: Enums.ColorScheme[key],
                                            HIGH_CONTRAST: {
                                                enabled: isLegacyDarkModeAvailable.current
                                                    ? !!prev.HIGH_CONTRAST?.enabled
                                                    : true,
                                            },
                                        }));
                                        setPreferencesUpdated(true);
                                    }}
                                    instrumentation={null}
                                />
                            ))}
                        </Menu>
                    }
                    minimal
                    fill
                    placement="bottom-start"
                >
                    <DropdownButton
                        className={styles.colorSchemeDropdownBtn}
                        text={
                            colorSchemeOptions.find(
                                ({ key }) => key === appearancePreferences.colorScheme
                            )!.text
                        }
                        underline
                        instrumentation={null}
                    />
                </MenuPopover>
            </div>
            {appearancePreferences.colorScheme !== Enums.ColorScheme.LIGHT_ALWAYS &&
            isLegacyDarkModeAvailable.current ? (
                <Toggle
                    alignment="right"
                    inline
                    label="High contrast dark mode"
                    labelClassName={styles.toggleLabel}
                    large
                    checked={!!(appearancePreferences.HIGH_CONTRAST?.enabled ?? true)}
                    onChange={e => {
                        setAppearancePreferences(prev => ({
                            ...prev,
                            HIGH_CONTRAST: {
                                enabled: e.target.checked,
                            },
                        }));
                        setPreferencesUpdated(true);
                    }}
                    instrumentation={null}
                />
            ) : null}
        </div>
    );
}

function SettingsFormGroup() {
    const currentUser = useCurrentUser();

    const { saveSettings } = useSaveSettings();

    const [settings, setSettings] = useState(currentUser.settings);

    const handleNewSettings = useCallback(
        async (newSettings: DbColumnTypes.UsersSettings) => {
            try {
                await saveSettings({
                    settings: newSettings,
                });
            } catch (error) {
                Log.error("Failed to save user profile", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [saveSettings]
    );

    useEffect(() => {
        setSettings(currentUser.settings);
    }, [currentUser.settings]);

    return (
        <div className={styles.formGroup}>
            <Toggle
                alignment="right"
                inline
                label="Show topic IDs"
                labelClassName={styles.toggleLabel}
                large
                checked={!!settings.SHOW_TICKET_REFS?.enabled}
                onChange={e => {
                    void handleNewSettings({
                        ...settings,
                        SHOW_TICKET_REFS: {
                            ...settings.SHOW_TICKET_REFS,
                            enabled: e.target.checked,
                        },
                    });
                }}
                instrumentation={{
                    elementName: "settings.show_ticket_ids_toggle",
                    eventData: { checked: !settings.SHOW_TICKET_REFS?.enabled },
                }}
            />
        </div>
    );
}

export function AppearanceSettings() {
    const currentUser = useCurrentUser();
    const componentQuery = useLiveViewQuery({
        query: AppearanceSettings.queries.component,
        variables: {
            currentUserId: currentUser.id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const user = getFragmentData(fragments.user, componentQuery.data?.user);

    if (!user) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h1>Appearance</h1>
            <form onSubmit={e => e.preventDefault()}>
                <AppearancePreferencesFormGroup user={user} />
                <SettingsFormGroup />
            </form>
        </div>
    );
}

AppearanceSettings.queries = {
    component: gql(/* GraphQL */ `
        query AppearanceSettings($currentUserId: Int!) {
            user: users_by_pk(id: $currentUserId) {
                ...AppearanceSettings_user
            }
        }
    `),
};

Queries.register({ component: "AppearanceSettings", gqlMapByName: AppearanceSettings.queries });
