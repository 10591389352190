import { useMemo } from "react";

import { useMediaQuery } from "lib/Hooks";

// Standard breakpoints. Should be kept in sync with _breakpoints.scss.
// xs < phone < sm < iPad Mini (768px) < md < tablet < lg < laptop/desktop
const BREAKPOINTS = {
    xs: 0,
    sm: 481,
    md: 769,
    lg: 1025,
    xl: 1201,
};

export function useBreakpoints() {
    const xsMin = useMediaQuery(`(min-width: ${BREAKPOINTS.xs}px)`);
    const smMin = useMediaQuery(`(min-width: ${BREAKPOINTS.sm}px)`);
    const mdMin = useMediaQuery(`(min-width: ${BREAKPOINTS.md}px)`);
    const lgMin = useMediaQuery(`(min-width: ${BREAKPOINTS.lg}px)`);
    const xlMin = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`);

    const xsMax = useMediaQuery(`(max-width: ${BREAKPOINTS.sm - 1}px)`);
    const smMax = useMediaQuery(`(max-width: ${BREAKPOINTS.md - 1}px)`);
    const mdMax = useMediaQuery(`(max-width: ${BREAKPOINTS.lg - 1}px)`);
    const lgMax = useMediaQuery(`(max-width: ${BREAKPOINTS.xl - 1}px)`);

    const breakpoints = useMemo(
        () => ({ xsMin, smMin, mdMin, lgMin, xlMin, xsMax, smMax, mdMax, lgMax }),
        [xsMin, smMin, mdMin, lgMin, xlMin, xsMax, smMax, mdMax, lgMax]
    );

    return breakpoints;
}

export function useSemanticBreakpoints() {
    const breakpoints = useBreakpoints();

    // Semantic breakpoints. Should be kept in sync with _breakpoints.scss.
    const semanticBreakpoints = useMemo(
        () => ({
            isDetailViewDocumentPaddingTight: breakpoints.smMax,
            isDetailViewDiscussionControlsCondensed: breakpoints.smMax,
            isSideNavEnabled: breakpoints.lgMin,
            isTopicMetadataPickersCondensed: breakpoints.smMax,
        }),
        [breakpoints]
    );

    return semanticBreakpoints;
}
