import { Log } from "lib/Log";

/**
 * Wrapper around browser storage(s), like sessionStorage and localStorage, with helpful additional
 * behavior:
 * - Set a value in multiple storages (e.g., session and local) and read a value from the *first*
 *   storage that has the key. This behavior can be useful to track a user preference within a
 *   single browser tab (session) while intelligently defaulting that preference when a new tab
 *   is opened.
 * - Wrappers for common data types
 * - Error handling for setItem
 */
class BrowserStorage {
    storages: Storage[];

    constructor(storages: Storage[]) {
        this.storages = storages;
    }

    getItem(key: string) {
        let value;

        for (const storage of this.storages) {
            value = storage.getItem(key);

            if (value !== null) {
                try {
                    const parsedValue = JSON.parse(value);

                    if (typeof parsedValue === "object") {
                        return parsedValue;
                    }
                } catch (e) {
                    return value;
                }

                return value;
            }
        }

        return value;
    }

    getBoolean(key: string) {
        return this.getItem(key) === "true";
    }

    getNumber(key: string) {
        return Number(this.getItem(key));
    }

    setItem(key: string, value: object | string) {
        try {
            if (typeof value === "object") {
                for (const storage of this.storages) {
                    storage.setItem(key, JSON.stringify(value));
                }
            } else {
                for (const storage of this.storages) {
                    storage.setItem(key, value);
                }
            }
        } catch (error) {
            Log.warn("Failed to invoke setItem", { error, key });
        }
    }

    setBoolean(key: string, value: unknown) {
        return this.setItem(key, String(!!value));
    }

    setNumber(key: string, value: number) {
        return this.setItem(key, String(value));
    }

    removeItem(key: string) {
        for (const storage of this.storages) {
            storage.removeItem(key);
        }
    }

    clear() {
        for (const storage of this.storages) {
            storage.clear();
        }
    }
}

export const Storage = {
    All: new BrowserStorage([window.sessionStorage, window.localStorage]),
    Local: new BrowserStorage([window.localStorage]),
    Session: new BrowserStorage([window.sessionStorage]),
};
