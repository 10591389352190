import React from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";

import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { Link } from "lib/Routing";
import { useTicketOwnershipInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";

import styles from "./Card.module.scss";
import { BoardCard } from "./boardCard/BoardCard";
import { useBoardView } from "../BoardViewContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment Card_ticket on tickets {
            id
            ref
            slug
            title
            stage_id

            board {
                id
                display_name
            }

            owners {
                ticket_id
                user_id
            }

            ...BoardCard_ticket
            ...TicketOwnershipInfo_ticket
        }
    `),
};

export type CardProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
    isDragging?: boolean;
    isDropping?: boolean;
    onMoveCard: ({
        ticketId,
        toStageDirection,
        withinStageDirection,
    }:
        | {
              ticketId: string;
              toStageDirection: CommonEnumValue<"Direction">;
              withinStageDirection?: undefined;
          }
        | {
              ticketId: string;
              toStageDirection?: undefined;
              withinStageDirection: CommonEnumValue<"Direction">;
          }) => void;
    onArchiveCard: ({ ticketId }: { ticketId: string }) => void;
    onTrashCard: ({ ticketId }: { ticketId: string }) => void;
    isFirstTicketInStage?: boolean;
    isLastTicketInStage?: boolean;
    nextStageId?: string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prevStageId?: string;
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
};

export const Card = React.memo(
    ({
        className,
        ticket: _ticketFragment,
        isDragging,
        isDropping,
        onMoveCard,
        onArchiveCard,
        onTrashCard,
        isFirstTicketInStage,
        isLastTicketInStage,
        nextStageId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        prevStageId,
        onClickFilterTrigger,
    }: CardProps) => {
        const ticket = getFragmentData(fragments.ticket, _ticketFragment);
        const { recordCallback } = useInstrumentation();
        const prefetchQuery = usePrefetchQuery();
        const currentUser = useCurrentUser();
        const { isDragInProgress, highlightMyTickets } = useBoardView();
        const { selection, selectTickets } = useTicketSelectionContext();
        const isSelected = selection.ticketIds.has(ticket.id);
        const { buildTicketUrl } = useUrlBuilders();
        const { isFeatureEnabled } = useFeatureFlags();

        const { isCurrentUserOwner, isCurrentUserMember } = useTicketOwnershipInfo({ ticket });

        const handleEdit = isFeatureEnabled({ feature: Enums.Feature.QUICK_EDITS })
            ? () => {
                  selectTickets({ ticketIds: [ticket.id] });
              }
            : null;

        const handleMoveToStartOfStage = () => {
            onMoveCard({
                ticketId: ticket.id,
                withinStageDirection: CommonEnums.Direction.BACK,
            });
        };

        const handleMoveToEndOfStage = () => {
            onMoveCard({
                ticketId: ticket.id,
                withinStageDirection: CommonEnums.Direction.FORWARD,
            });
        };

        const handleMoveToPreviousStage = () => {
            onMoveCard({
                ticketId: ticket.id,
                toStageDirection: CommonEnums.Direction.BACK,
            });
        };

        const handleMoveToNextStage = () => {
            onMoveCard({
                ticketId: ticket.id,
                toStageDirection: CommonEnums.Direction.FORWARD,
            });
        };

        const handleArchive = () => {
            onArchiveCard({
                ticketId: ticket.id,
            });
        };

        const handleMoveToTrash = () => {
            onTrashCard({
                ticketId: ticket.id,
            });
        };

        const prefetchTicketDetails = () => {
            void prefetchQuery({
                query: Queries.get({ component: "DetailView", name: "component" }),
                variables: {
                    orgId: currentUser.org_id,
                    ref: ticket.ref,
                },
            });
        };

        return (
            <Link
                className={classNames(className, styles.card)}
                onFocus={() => prefetchTicketDetails()}
                onMouseOver={() => prefetchTicketDetails()}
                to={
                    buildTicketUrl({
                        ticketSlug: ticket.slug,
                        vanity: {
                            boardDisplayName: ticket.board.display_name,
                            ticketRef: ticket.ref,
                            ticketTitle: ticket.title,
                        },
                    }).pathname
                }
                onClick={recordCallback({
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: "card",
                    eventData: { ticketId: ticket.id },
                })}
                role="link"
            >
                <BoardCard
                    isDragging={isDragging}
                    isDropping={isDropping}
                    isMuted={
                        (highlightMyTickets &&
                            !(isCurrentUserOwner || isCurrentUserMember) &&
                            !(selection.mode === Enums.MultiselectMode.ACTIVE && isSelected)) ||
                        (isDragInProgress &&
                            selection.mode === Enums.MultiselectMode.ACTIVE &&
                            !isSelected)
                    }
                    isSelected={isSelected}
                    ticket={ticket}
                    isFirstTicketInStage={isFirstTicketInStage}
                    isLastTicketInStage={isLastTicketInStage}
                    handleEdit={handleEdit}
                    handleMoveToStartOfStage={handleMoveToStartOfStage}
                    handleMoveToEndOfStage={handleMoveToEndOfStage}
                    handleMoveToPreviousStage={handleMoveToPreviousStage}
                    handleMoveToNextStage={handleMoveToNextStage}
                    handleArchive={handleArchive}
                    handleMoveToTrash={handleMoveToTrash}
                    onClickFilterTrigger={onClickFilterTrigger}
                />
            </Link>
        );
    }
);

Card.displayName = "Card";
