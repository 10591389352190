import { useCallback, useRef } from "react";

import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context"; // eslint-disable-line import/no-extraneous-dependencies
import { onError } from "@apollo/client/link/error"; // eslint-disable-line import/no-extraneous-dependencies
import { RetryLink } from "@apollo/client/link/retry"; // eslint-disable-line import/no-extraneous-dependencies
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities"; // eslint-disable-line import/no-extraneous-dependencies
import { createClient as createGraphQLWsClient } from "graphql-ws";

import { AppData } from "AppData";
import { Config } from "Config";
import { EnumValue, Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { getCurrentAccessToken } from "lib/auth/AccessToken";
import { useInternalLogout } from "lib/auth/Logout";

import { typePolicies } from "./typePolicies";

export function useCreateApolloClient() {
    const { internalLogout } = useInternalLogout();
    const logoutTimeout = useRef<number>();

    const logoutWithDelay = useCallback(() => {
        if (!logoutTimeout.current) {
            logoutTimeout.current = window.setTimeout(internalLogout, 5000);
            Log.error("Logging out due to expired JWT");
        }
    }, [internalLogout]);

    type TApiRoleTypeForQueries = typeof Enums.ApiRoleType.IDENTITY | typeof Enums.ApiRoleType.USER;
    type TWebSocketInfo = {
        accessToken?: string | null;
        link?: GraphQLWsLink;
        keepAliveTimeout?: number;
        socket?: WebSocket;
    };

    // If the App rerenders for any reason, we should use the same Apollo cache and client
    // (so we don't have to refetch data), not instatiate new ones. This ref holds the
    // Apollo cache and client.
    const apolloRef = useRef<{
        cache?: InMemoryCache;
        errorLink?: ApolloLink;
        httpLink?: HttpLink;
        retryLink?: RetryLink;
        httpAuthLink?: ApolloLink;
        wsInfoByApiRoleType: Record<TApiRoleTypeForQueries, TWebSocketInfo>;
        client?: ApolloClient<NormalizedCacheObject>;
    }>({
        wsInfoByApiRoleType: {
            [Enums.ApiRoleType.IDENTITY]: {},
            [Enums.ApiRoleType.USER]: {},
        },
    });

    const reconnect = useCallback(
        ({
            apiRoleType,
            code,
            reason,
        }: {
            apiRoleType: TApiRoleTypeForQueries;
            code?: number;
            reason?: string;
        }) => {
            apolloRef.current.wsInfoByApiRoleType[apiRoleType]?.socket?.close(code ?? 4000, reason);
            Log.info("WebSocket closed", { apiRoleType });
        },
        []
    );

    // The access token is provided on the WebSocket only at connection time. But what if
    // the access token changes, e.g., it was expired and refreshed, or the person changes
    // orgs and gets a new access token? To account for that, check if the access token
    // used at the time of connection is still current. If not, close the socket so that
    // a new one can be established.
    const reconnectOnStaleAccessToken = useCallback(
        ({ apiRoleType }: { apiRoleType: TApiRoleTypeForQueries }) => {
            const wsInfo = apolloRef.current.wsInfoByApiRoleType[apiRoleType];

            if (wsInfo && wsInfo.accessToken !== getCurrentAccessToken()) {
                Log.info("WebSocket has stale access token", { apiRoleType });
                reconnect({ apiRoleType, reason: "Stale access token" });
            }
        },
        [reconnect]
    );

    const bumpKeepAliveTimeout = useCallback(
        ({ apiRoleType }: { apiRoleType: TApiRoleTypeForQueries }) => {
            const wsInfo = apolloRef.current.wsInfoByApiRoleType[apiRoleType];

            window.clearTimeout(wsInfo.keepAliveTimeout);
            wsInfo.keepAliveTimeout = window.setTimeout(() => {
                Log.warn("WebSocket keepalive timed out", { apiRoleType });
                reconnect({ apiRoleType, reason: "Server timeout" });
            }, Config.apollo.socketKeepAliveTimeoutMs);
        },
        [reconnect]
    );

    const createApolloClient = useCallback(() => {
        apolloRef.current.cache =
            apolloRef.current.cache ||
            new InMemoryCache({
                addTypename: true,
                // As of May 2022, this option is not listed at https://www.apollographql.com/docs/react/caching/cache-configuration/#configuration-options
                // but from reading the apollo-client codebase it appears to be a legit option that
                // simply wasn't reflected in the documentation.
                //
                // Before apollo client v3.4.14, this option was on by default. In that version, it was
                // made off by default. It's possible that turning it back on will result in memory
                // growth over time, but at the time of this writing, this is the cheapest/safest way
                // to fix a serious apollo bug causing #1915.
                canonizeResults: true,
                typePolicies,
            });

        apolloRef.current.errorLink =
            apolloRef.current.errorLink ||
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) =>
                        // eslint-disable-next-line no-console
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                    );

                    if (graphQLErrors.some(({ message }) => message.includes("JWTExpired"))) {
                        logoutWithDelay();
                    }
                }

                if (networkError) {
                    // eslint-disable-next-line no-console
                    console.log(`[Network error]: ${networkError}`);
                    // eslint-disable-next-line no-console
                    console.log(networkError);
                }
            });

        apolloRef.current.httpLink =
            apolloRef.current.httpLink ||
            new HttpLink({
                uri: Config.api.urlHttp,
            });

        apolloRef.current.retryLink =
            apolloRef.current.retryLink ||
            new RetryLink({
                delay: {
                    initial: 500,
                    max: Infinity,
                    jitter: false,
                },
                attempts: {
                    max: 5,
                    retryIf: error => !!error && navigator.onLine,
                },
            });

        apolloRef.current.httpAuthLink =
            apolloRef.current.httpAuthLink ||
            setContext((_, { apiRoleType }) => {
                const headers = {
                    ...(apiRoleType && {
                        "x-hasura-role": {
                            [Enums.ApiRoleType.IDENTITY]: AppData.identityRole,
                            // Intentionally undefined. As of November 2023, JWTs set a
                            // default role of "user", or "read_only_admin" for the special
                            // read only admin role. In either case, the ApiRoleType.USER
                            // simply means to use that default.
                            [Enums.ApiRoleType.USER]: undefined,
                            [Enums.ApiRoleType.USER_ORG_ADMIN]: "user_org_admin",
                        }[apiRoleType as EnumValue<"ApiRoleType">],
                    }),
                };

                const accessToken = getCurrentAccessToken();

                if (accessToken) {
                    return {
                        headers: {
                            ...headers,
                            authorization: `Bearer ${accessToken}`,
                        },
                    };
                }

                return { headers };
            });

        for (const apiRoleType of [Enums.ApiRoleType.IDENTITY, Enums.ApiRoleType.USER] as const) {
            const wsInfo = apolloRef.current.wsInfoByApiRoleType[apiRoleType];

            wsInfo.link =
                wsInfo.link ||
                new GraphQLWsLink(
                    createGraphQLWsClient({
                        url: Config.api.urlWs,
                        connectionParams: () => {
                            wsInfo.accessToken = getCurrentAccessToken();

                            return {
                                headers: {
                                    authorization: `Bearer ${wsInfo.accessToken}`,
                                    ...(apiRoleType === Enums.ApiRoleType.IDENTITY && {
                                        "x-hasura-role": AppData.identityRole,
                                    }),
                                },
                            };
                        },
                        lazy: true,
                        on: {
                            connected: socket => {
                                wsInfo.socket = socket as WebSocket;
                                bumpKeepAliveTimeout({ apiRoleType });
                                Log.info("WebSocket connected", { apiRoleType });
                            },
                            ping: received => {
                                reconnectOnStaleAccessToken({ apiRoleType });

                                if (received) {
                                    bumpKeepAliveTimeout({ apiRoleType });
                                }
                            },
                            pong: () => reconnectOnStaleAccessToken({ apiRoleType }),
                            message: () => reconnectOnStaleAccessToken({ apiRoleType }),
                            error: () => reconnectOnStaleAccessToken({ apiRoleType }),
                        },
                        retryAttempts: Number.MAX_SAFE_INTEGER,
                        retryWait: () =>
                            new Promise(resolve =>
                                setTimeout(resolve, Config.apollo.socketRestartDelayMs)
                            ),
                        shouldRetry: () => true,
                    })
                );
        }

        apolloRef.current.client =
            apolloRef.current.client ||
            new ApolloClient({
                // Use HTTP for queries and mutations and WebSocket for subscriptions.
                // See https://www.apollographql.com/docs/react/data/subscriptions/#3-use-different-transports-for-different-operations
                link: ApolloLink.from([
                    apolloRef.current.errorLink,
                    ApolloLink.split(
                        ({ query }) => {
                            const definition = getMainDefinition(query);

                            return (
                                definition.kind === "OperationDefinition" &&
                                definition.operation === "subscription"
                            );
                        },
                        // Subscriptions
                        ApolloLink.split(
                            ({ getContext }) =>
                                getContext().apiRoleType === Enums.ApiRoleType.IDENTITY,
                            apolloRef.current.wsInfoByApiRoleType[Enums.ApiRoleType.IDENTITY].link!,
                            apolloRef.current.wsInfoByApiRoleType[Enums.ApiRoleType.USER].link!
                        ),
                        // Other operations (queries, mutations)
                        ApolloLink.from([
                            apolloRef.current.httpAuthLink,
                            apolloRef.current.retryLink,
                            apolloRef.current.httpLink,
                        ])
                    ),
                ]),
                cache: apolloRef.current.cache,
            });

        return apolloRef.current.client;
    }, [bumpKeepAliveTimeout, logoutWithDelay, reconnectOnStaleAccessToken]);

    return { createApolloClient };
}
