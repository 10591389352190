import React from "react";

import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { PaymentCardLogo } from "components/ui/common/PaymentCard";
import { BorderButton } from "components/ui/core/BorderButton";
import { useDialog } from "lib/Hooks";

import {
    BillingSection,
    billingInfoSectionLastModifiedState,
    billingInfoState,
} from "./BillingState";
import { BillingSubsectionSpinner } from "./BillingSubsectionSpinner";
import styles from "./PaymentMethodContent.module.scss";
import { PaymentMethodDialog } from "./PaymentMethodDialog";

type CardExpirationDateProps = {
    expMonth: number;
    expYear: number;
};

function CardExpirationDate({ expMonth, expYear }: CardExpirationDateProps) {
    return (
        <>
            {String(expMonth).length === 1 ? `0${expMonth}` : expMonth}/
            {String(expYear).length === 4 ? String(expYear).slice(-2) : expYear}
        </>
    );
}

type CardNumberProps = {
    last4: string;
};

function CardNumber({ last4 }: CardNumberProps) {
    return <>•••• {last4}</>;
}

type CardDetailsProps = {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
};

function CardDetails({ brand, last4, expMonth, expYear }: CardDetailsProps) {
    const cardLogo = <PaymentCardLogo brand={brand} />;

    return (
        <span className={styles.cardDetails}>
            {cardLogo || "Card"}
            <span>
                <CardNumber last4={last4} /> (expires{" "}
                <CardExpirationDate expMonth={expMonth} expYear={expYear} />)
            </span>
        </span>
    );
}

export type PaymentMethodContentProps = {
    className?: string;
    onChange: () => void;
};

export function PaymentMethodContent({ className, onChange }: PaymentMethodContentProps) {
    const billingInfo = useRecoilValue(billingInfoState);
    const billingInfoSectionLastModified = useRecoilValue(billingInfoSectionLastModifiedState);
    const dialog = useDialog();

    const paymentMethod = billingInfo?.payment_methods?.[0];
    const isMissingPaymentMethod = !paymentMethod;
    const paymentMethodLastModifiedAt =
        billingInfoSectionLastModified[BillingSection.PAYMENT_METHOD];
    const isReady =
        billingInfo &&
        (!paymentMethodLastModifiedAt ||
            (billingInfo.as_of && billingInfo.as_of > paymentMethodLastModifiedAt));

    return (
        <>
            <section className={classNames(className, styles.paymentMethodContent)}>
                {isReady ? (
                    <>
                        {paymentMethod ? (
                            <CardDetails
                                brand={paymentMethod.card.brand}
                                last4={paymentMethod.card.last4}
                                expMonth={paymentMethod.card.exp_month}
                                expYear={paymentMethod.card.exp_year}
                            />
                        ) : (
                            <span>No card on file</span>
                        )}
                        <BorderButton
                            content={isMissingPaymentMethod ? "Add card" : "Change"}
                            instrumentation={{ elementName: "settings.change_payment_method_btn" }}
                            onClick={dialog.open}
                            small
                        />
                    </>
                ) : (
                    <BillingSubsectionSpinner />
                )}
            </section>
            {billingInfo ? (
                <PaymentMethodDialog
                    isMissingPaymentMethod={isMissingPaymentMethod}
                    isOpen={dialog.isOpen}
                    onChange={onChange}
                    onClose={dialog.close}
                />
            ) : null}
        </>
    );
}
