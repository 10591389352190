import { Editor } from "@tiptap/core";

export const serializeText = ({
    editor,
    from = 0,
    to = editor.state.doc.nodeSize - 2,
    blockSeparator = "\n",
    leafText,
}: {
    editor: Editor;
    from?: number;
    to?: number;
    blockSeparator?: string;
    leafText?: string;
}) => {
    let text = "";
    let separated = true;

    const textSerializers = Object.fromEntries(
        Object.entries(editor.schema.nodes)
            .filter(([, node]) => node.spec.toText)
            .map(([name, node]) => [name, node.spec.toText])
    );

    editor.state.doc.nodesBetween(
        from,
        to,
        (node, pos) => {
            const textSerializer = textSerializers[node.type.name];

            if (textSerializer) {
                text += textSerializer({ node });
                separated = !blockSeparator;
            } else if (node.isText) {
                text += node?.text?.slice(Math.max(from, pos) - pos, to - pos);
                separated = false;
            } else if (node.isLeaf && leafText) {
                text += leafText;
                separated = !blockSeparator;
            } else if (!separated && node.isBlock && blockSeparator) {
                text += blockSeparator;
            }
        },
        0
    );

    return text;
};
