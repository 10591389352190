import { useEffect, useMemo } from "react";

import { ValueOf } from "c9r-common";

import { useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";

export const UserViewTabType = {
    PLAN: "PLAN",
    DUE_DATES: "DUE_DATES",
} as const;

export function useUserViewRouting({ userId }: { userId: number }) {
    const location = useLocation();
    const urlHashIncludesValue = (value: string) =>
        location.hash
            .slice(1) // Drop the leading #
            .split(";")
            .includes(value);
    const storageKey = `user.${userId}.mostRecentUserTab`;
    const activeTab: ValueOf<typeof UserViewTabType> = urlHashIncludesValue("plan")
        ? UserViewTabType.PLAN
        : urlHashIncludesValue("dates")
        ? UserViewTabType.DUE_DATES
        : Storage.All.getItem(storageKey) ?? UserViewTabType.PLAN;

    useEffect(() => {
        Storage.All.setItem(storageKey, activeTab);
    }, [activeTab, storageKey]);

    return useMemo(
        () => ({
            activeTab,
        }),
        [activeTab]
    );
}
