import React, { useState } from "react";

import { FormGroup } from "@blueprintjs/core";
import { useRecoilValue } from "recoil";

import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { useShareFeedback } from "lib/mutations";

import styles from "./FeedbackDialog.module.scss";

const dialogState = dialogStateFamily<null>("FeedbackDialog");

export function useFeedbackDialog() {
    return useDialogSingleton(dialogState);
}

export function FeedbackDialog() {
    const { isOpen } = useRecoilValue(dialogState);
    const dialog = useFeedbackDialog();
    const [feedbackText, setFeedbackText] = useState("");
    const { shareFeedback } = useShareFeedback();

    const handleSubmit = () => {
        if (feedbackText) {
            void shareFeedback(
                { text: feedbackText },
                {
                    onCompleted: () => {
                        AppToaster.info({
                            message: "THANK YOU – for helping us make Flat better for everyone.",
                        });
                    },
                }
            );

            dialog.close();
        }

        setFeedbackText("");
    };

    return (
        <Dialog
            title="Feedback"
            isOpen={isOpen}
            onClose={dialog.close}
            className={styles.feedbackDialog}
        >
            <Dialog.Body>
                <FormGroup
                    label="What could Flat be doing better, or what should we be working on?"
                    labelFor={styles.feedbackTextArea}
                >
                    <textarea
                        id={styles.feedbackTextArea}
                        value={feedbackText}
                        onChange={e => {
                            setFeedbackText(e.target.value);
                        }}
                        autoFocus
                        rows={4}
                    />
                </FormGroup>
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton
                        content="Submit"
                        instrumentation={{
                            elementName: "feedback_dialog.submit_btn",
                        }}
                        onClick={() => handleSubmit()}
                        primary
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
