import { useCallback, useEffect } from "react";

import { OAuthError } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { isAccessTokenAvailableState, useSetOrReplaceAccessToken } from "lib/auth/AccessToken";
import { useAuth0 } from "lib/auth/Auth0";

let isRefreshing = false;

export const useRefreshAuth0AccessToken = () => {
    const setOrReplaceAccessToken = useSetOrReplaceAccessToken();
    const { getAccessTokenSilently, login } = useAuth0();

    const refreshAccessToken = useCallback(
        async ({
            ignoreCache,
            preferredUserId,
        }: { ignoreCache?: boolean; preferredUserId?: number } = {}) => {
            if (isRefreshing) {
                return;
            }

            isRefreshing = true;

            try {
                setOrReplaceAccessToken(
                    // As of October 2023, in Chrome Incognito mode and also Firefox, getAccessTokenSilently
                    // can fail in local dev with error login_required. This can come up when, for example,
                    // switching between orgs or onboarding into a new org. It happens because, in local dev,
                    // we don't have a custom login domain, so the Auth0 cookie is on a separate domain,
                    // which Chrome Incognito and Firefox restrict access to. By contrast, it's not a problem
                    // in production where our custom login domain shares a top-level domain with the app.
                    // See #3474.
                    await getAccessTokenSilently({
                        cacheMode: ignoreCache ? "off" : "on",
                        authorizationParams: {
                            u: preferredUserId,
                        },
                    })
                );
            } catch (error) {
                Log.debug("Failed to get access token", { error });

                if (
                    error instanceof OAuthError &&
                    ["login_required", "consent_required", "interaction_required"].includes(
                        error.error
                    )
                ) {
                    await login();
                }
            } finally {
                isRefreshing = false;
            }
        },
        [getAccessTokenSilently, login, setOrReplaceAccessToken]
    );

    return { refreshAccessToken };
};

/**
 * Helper hook to transparently ensure we have an unexpired GraphQL API access token issued by
 * Auth0.
 *
 * Rather than wait to detect that the access token has *actually* expired, we just call
 * getTokenSilently() periodically. getTokenSilently() will never return an expired token, and it
 * will also request a new access token if the one in the cache is *about* to expire (within a
 * minute; see https://github.com/auth0/auth0-spa-js/blob/ab5054811a0ab855b31cc16b9830db271af6d363/src/Auth0Client.ts#L1290.
 */
export const useAuth0AccessTokenManager = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const isAccessTokenAvailable = useRecoilValue(isAccessTokenAvailableState);
    const { refreshAccessToken } = useRefreshAuth0AccessToken();

    useEffect(() => {
        if (!isLoading && isAuthenticated && !isAccessTokenAvailable) {
            void refreshAccessToken();
        }
    }, [isAuthenticated, isLoading, isAccessTokenAvailable, refreshAccessToken]);

    useInterval(() => {
        if (isAccessTokenAvailable) {
            void refreshAccessToken();
        }
    }, Config.accessTokenCheckIntervalMs);
};
