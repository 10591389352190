import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useDeleteTask() {
    const { replicache } = useReplicache();

    const deleteTask = useCallback(
        async ({ taskId }: { taskId: string }) => {
            await replicache.mutate.deleteTask({ taskId });
        },
        [replicache]
    );

    return { deleteTask };
}
