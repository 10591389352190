import React, { useCallback, useEffect, useState } from "react";

import { ThemeClassNames } from "lib/Constants";
import { createCtx } from "lib/react/Context";

export type ThemeContextValue = {
    isDarkMode: boolean;
};

const [useThemeContext, ContextProvider] = createCtx<ThemeContextValue>();

export { useThemeContext };

export type ThemeContextProviderProps = {
    children: React.ReactNode;
};

export function ThemeContextProvider({ children }: ThemeContextProviderProps) {
    const [isDarkMode, setIsDarkMode] = useState(
        document.body.classList.contains(ThemeClassNames.DARK)
    );

    const handleMutation = useCallback(() => {
        setIsDarkMode(document.body.classList.contains(ThemeClassNames.DARK));
    }, [setIsDarkMode]);

    useEffect(() => {
        const observer = new MutationObserver(handleMutation);

        observer.observe(document.body, {
            attributeFilter: ["class"],
        });

        return () => {
            observer.disconnect();
        };
    }, [handleMutation]);

    return <ContextProvider value={{ isDarkMode }}>{children}</ContextProvider>;
}
