import React from "react";

import classNames from "classnames";
import { isYesterday } from "date-fns";
import ReactTimeAgo from "react-timeago";

const unitAbbreviations = {
    second: "s",
    minute: "m",
    hour: "h",
    day: "d",
    week: "w",
    month: "mo",
    year: "y",
};

export type TimeAgoProps = {
    abbreviate?: boolean;
    className?: string;
    date: string | Date;
};

export function TimeAgo({ abbreviate, className, date }: TimeAgoProps) {
    return (
        <ReactTimeAgo
            className={classNames(className)}
            date={date}
            formatter={(value, unit, suffix, epochMilliseconds, nextFormatter) => {
                if (unit === "second" || suffix === "from now") {
                    return "just now";
                }

                // According to the ReactTimeAgo docs, epochMilliseconds is "The result of Date.now() or the result of a custom now prop.",
                // but it is in fact the date that was passed in. See https://github.com/nmn/react-timeago/blame/master/src/index.js#L154.
                if (isYesterday(new Date(epochMilliseconds))) {
                    return "yesterday";
                }

                if (abbreviate) {
                    return `${value}${unitAbbreviations[unit]} ${suffix}`;
                }

                // As of January 2023, the type for nextFormatter mistakenly required args, but it
                // doesn't actually take any. For simplicity, just ignore the error rather than
                // try to override the interface.
                // @ts-expect-error
                return nextFormatter?.();
            }}
            title={new Date(date).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            })}
        />
    );
}
