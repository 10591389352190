import React from "react";

import { Popover2, Popover2Props } from "@blueprintjs/popover2";
import classNames from "classnames";

import { SelectorMenu, SelectorMenuProps } from "./SelectorMenu";
import styles from "./Suggest.module.scss";

export type SuggestProps<TItem> = {
    children: React.ReactNode;
    popoverProps?: Omit<Popover2Props, "content">;
} & SelectorMenuProps<TItem>;

export function Suggest<TItem>({
    children,
    popoverProps,
    ...selectorMenuProps
}: SuggestProps<TItem>) {
    return (
        <Popover2
            fill
            minimal
            placement={popoverProps?.placement ?? "bottom-start"}
            transitionDuration={0}
            usePortal
            {...popoverProps}
            popoverClassName={classNames(popoverProps?.popoverClassName, styles.suggestPopover)}
            content={
                <SelectorMenu
                    {...selectorMenuProps}
                    filterInputProps={{
                        ...selectorMenuProps.filterInputProps,
                        className: classNames(
                            selectorMenuProps.filterInputProps?.className,
                            styles.suggestFilterInput
                        ),
                    }}
                />
            }
        >
            {children}
        </Popover2>
    );
}
