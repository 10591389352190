import React from "react";

import classNames from "classnames";

import { Avatar } from "components/ui/common/Avatar";
import { useCurrentUser } from "contexts/UserContext";
import { Link } from "lib/Routing";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserViewHeader_userFragment } from "lib/graphql/__generated__/graphql";

import styles from "./UserViewHeader.module.scss";
import { UserViewTabType, useUserViewRouting } from "../UserViewRouting";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserViewHeader_user on users {
            id
            name
            ...Avatar_user
        }
    `),
};

type HeaderTitleProps = {
    user: UserViewHeader_userFragment;
};

function HeaderTitle({ user }: HeaderTitleProps) {
    const currentUser = useCurrentUser();
    const isCurrentUser = currentUser.id === user.id;
    const displayName = `${user.name}${isCurrentUser ? " (me)" : ""}`;

    return (
        <div className={styles.title}>
            <Avatar user={user} size={32} />
            <h1>{displayName}</h1>
        </div>
    );
}

type HeaderTabsProps = {
    user: UserViewHeader_userFragment;
};

function HeaderTabs({ user }: HeaderTabsProps) {
    const { activeTab } = useUserViewRouting({ userId: user.id });

    return (
        <div className={styles.tabs}>
            <Link
                className={classNames(
                    styles.tabName,
                    activeTab === UserViewTabType.PLAN && styles.tabActive
                )}
                role="link"
                to="#plan"
            >
                Plan
            </Link>
            <Link
                className={classNames(
                    styles.tabName,
                    activeTab === UserViewTabType.DUE_DATES && styles.tabActive
                )}
                role="link"
                to="#dates"
            >
                Dates
            </Link>
        </div>
    );
}

export type UserViewHeaderProps = {
    className?: string;
    user: FragmentType<typeof fragments.user>;
};

export function UserViewHeader({ className, user: _userFragment }: UserViewHeaderProps) {
    const user = getFragmentData(fragments.user, _userFragment);

    return (
        <header className={classNames(className, styles.header)}>
            <HeaderTitle user={user} />
            <HeaderTabs user={user} />
        </header>
    );
}
