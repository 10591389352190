import React from "react";

import classNames from "classnames";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./ParentTickets.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment ParentTickets_ticket on tickets {
            id

            child_of_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    ticket: { trashed_at: { _is_null: true } }
                }
            ) {
                id

                ticket {
                    id
                    title

                    child_of_tasks(
                        where: {
                            deleted_at: { _is_null: true }
                            ticket: { trashed_at: { _is_null: true } }
                        }
                    ) {
                        id

                        ticket {
                            id
                            title
                        }
                    }
                }
            }
        }
    `),
};

export type ParentTicketsProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function ParentTickets({ className, ticket: _ticketFragment }: ParentTicketsProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    if (!ticket.child_of_tasks.length) {
        return null;
    }

    const parentTickets = ticket.child_of_tasks
        .map(({ ticket: t }) => t)
        .sort((a, b) => a.title.localeCompare(b.title));

    const firstParentTicket = parentTickets[0];
    const remainingParentTicketsCount = parentTickets.length - 1;

    return (
        <div className={classNames(className, styles.parentTickets)}>
            {firstParentTicket.title}
            {!!remainingParentTicketsCount && ` +${remainingParentTicketsCount}`}
        </div>
    );
}
