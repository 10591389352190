import React from "react";

import { BoardFilter, BoardFilterProps } from "components/shared/BoardFilter";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { BoardViewProvider_boardFragment } from "lib/graphql/__generated__/graphql";

import styles from "./SideDrawerTicketTableHeader.module.scss";

type TicketCountProps = {
    count: number;
};

function TicketCount({ count }: TicketCountProps) {
    return <span className={styles.ticketCount}>{count.toLocaleString()} topics</span>;
}

export type SideDrawerTicketTableHeaderProps = {
    board?: BoardViewProvider_boardFragment;
    elementName: string;
    filterExpression?: string | null;
    onClose: () => void;
    onFilterExpressionChange?: BoardFilterProps["onChange"];
    space?: "ARCHIVE" | "TRASH";
    ticketCount?: number;
    title: string;
};

export function SideDrawerTicketTableHeader({
    board,
    elementName,
    filterExpression,
    onClose,
    onFilterExpressionChange,
    space,
    ticketCount,
    title,
}: SideDrawerTicketTableHeaderProps) {
    const isFilterable = !!onFilterExpressionChange;

    return (
        <div className={styles.header}>
            <div className={styles.headerContent}>
                <div className={styles.boardInfo}>
                    <span className={styles.title}>{title}</span>
                    {isFilterable && typeof ticketCount === "number" ? (
                        <TicketCount count={ticketCount} />
                    ) : null}
                </div>
                <div className={styles.controls}>
                    {isFilterable && (
                        <BoardFilter
                            board={board}
                            space={space}
                            filterExpression={filterExpression}
                            onChange={onFilterExpressionChange}
                        />
                    )}
                </div>
                <BorderButton
                    content={<Icon icon="x" iconSet="lucide" iconSize={24} strokeWidth={1} />}
                    instrumentation={{ elementName: `${elementName}.close_btn` }}
                    onClick={onClose}
                    flush
                    minimal
                    square
                />
            </div>
        </div>
    );
}
