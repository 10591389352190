import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const TicketHistoryEventResolvers: TResolvers = {
    query_root: {
        ticket_history_events: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.ticketHistoryEvents.findAll()
            )
        ),

        ticket_history_events_by_pk: buildResolver(async (parent, args, { api }) =>
            api.ticketHistoryEvents.getById({ id: args.id })
        ),
    },

    ticket_history_events: {
        event_on_merge_request: buildResolver(async (parent, args, { api }) =>
            parent.event_on_merge_request_id
                ? api.mergeRequests.getByIdOrThrow({ id: parent.event_on_merge_request_id })
                : null
        ),

        event_on_stage: buildResolver(async (parent, args, { api }) =>
            parent.event_on_stage_id
                ? api.stages.getByIdOrThrow({ id: parent.event_on_stage_id })
                : null
        ),

        event_on_task: buildResolver(async (parent, args, { api }) =>
            parent.event_on_task_id
                ? api.tasks.getByIdOrThrow({ id: parent.event_on_task_id })
                : null
        ),

        event_on_tasklist: async (parent, args, { api }) =>
            parent.event_on_tasklist_id
                ? api.tasklists.getByIdOrThrow({ id: parent.event_on_tasklist_id })
                : null,

        event_on_thread: buildResolver(async (parent, args, { api }) =>
            parent.event_on_thread_id
                ? api.threads.getByIdOrThrow({ id: parent.event_on_thread_id })
                : null
        ),

        event_on_ticket_attachment: buildResolver(async (parent, args, { api }) =>
            parent.event_on_ticket_attachment_id
                ? api.ticketAttachments.getByIdOrThrow({ id: parent.event_on_ticket_attachment_id })
                : null
        ),

        event_on_user: buildResolver(async (parent, args, { userContextApi }) =>
            parent.event_on_user_id
                ? userContextApi.getUserById({ userId: parent.event_on_user_id })
                : null
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),

        user: buildResolver(async (parent, args, { userContextApi }) =>
            parent.user_id ? userContextApi.getUserById({ userId: parent.user_id }) : null
        ),
    },
};
