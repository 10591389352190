import { useEffect, useState } from "react";

import { useDiscordIntegration } from "lib/Integrations";

export function DiscordOAuth2Redirect() {
    const [didAttempt, setDidAttempt] = useState(false);
    const { connectDiscord } = useDiscordIntegration();

    useEffect(() => {
        if (!didAttempt) {
            setDidAttempt(true);
            void connectDiscord();
        }
    }, [didAttempt, connectDiscord]);

    return null;
}
