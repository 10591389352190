import React from "react";

import classNames from "classnames";

import { useTicketDueDateInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./DueDate.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DueDate_ticket on tickets {
            ...TicketDueDateInfo_ticket
        }
    `),
};

export type DueDateProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function DueDate({ className, ticket: _ticketFragment }: DueDateProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { formattedDueDate, isUpcomingSoon, isOverdue } = useTicketDueDateInfo({ ticket });

    if (!formattedDueDate) {
        return null;
    }

    return (
        <span
            className={classNames(
                className,
                styles.dueDate,
                isUpcomingSoon && styles.upcoming,
                isOverdue && styles.overdue
            )}
        >
            {formattedDueDate}
        </span>
    );
}
