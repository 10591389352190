import React, { useEffect, useRef, useState } from "react";

import { Slider } from "@blueprintjs/core";
import { CommonEnums } from "c9r-common";
import ReactAvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { resizeImageBlob } from "lib/Helpers";
import { useAsyncWatcher, useFilePicker } from "lib/Hooks";
import { useUploadAsset } from "lib/Uploads";
import { useSetOrRemoveUserAvatar } from "lib/mutations";

import styles from "./EditAvatarDialog.module.scss";

const dialogState = dialogStateFamily<null>("EditAvatarDialogState");

export function useEditAvatarDialog() {
    return useDialogSingleton(dialogState);
}

export function EditAvatarDialog() {
    const { isOpen } = useRecoilValue(dialogState);
    const dialog = useEditAvatarDialog();
    const isOnline = useRecoilValue(networkStatusState);
    const editorRef = useRef<ReactAvatarEditor>(null);
    const submission = useAsyncWatcher();
    const [scale, setScale] = useState(1.5);
    const [baseImage, setBaseImage] = useState<File | null>(null);
    const { upload } = useUploadAsset();
    const { acceptedFiles, getRootProps, isDragActive } = useDropzone({
        accept: "image/*",
        noDragEventsBubbling: true,
    });
    const { openFilePicker } = useFilePicker();
    const { setOrRemoveUserAvatar } = useSetOrRemoveUserAvatar();

    useEffect(() => {
        if (isOpen) {
            setScale(1.5);
            setBaseImage(null);
        }
    }, [isOpen]);

    useEffect(() => {
        setBaseImage(acceptedFiles[0]);
    }, [acceptedFiles]);

    const onSave = async () => {
        let resolve: (value?: unknown) => any;
        const promise = new Promise(_resolve => {
            resolve = _resolve;
        });

        editorRef.current?.getImageScaledToCanvas().toBlob(async blob => {
            try {
                if (!blob) {
                    throw new Error("Failed to get image blob from canvas");
                }

                const mimeType = "image/jpeg";
                const resizedBlob = await resizeImageBlob({
                    blob,
                    width: 128,
                    height: 128,
                    mimeType,
                });

                if (!resizedBlob) {
                    throw new Error("Failed to resize image blob");
                }

                if (!isOnline) {
                    AppToaster.danger({
                        message: "Sorry, changing your profile photo is not available offline.",
                    });

                    return;
                }

                const { assetUrl } = await upload({
                    assetType: CommonEnums.UserUploadAssetType.AVATAR,
                    mimeType,
                    blob: resizedBlob,
                    filename: `${uuidv4()}.jpg`,
                    showToast: true,
                });

                if (!assetUrl) {
                    return;
                }

                await setOrRemoveUserAvatar({ avatarUrl: assetUrl });

                dialog.close();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            } finally {
                resolve();
            }
        });

        return promise;
    };

    return (
        <Dialog
            title="Profile photo"
            isOpen={isOpen}
            className={styles.editAvatarDialog}
            onClose={dialog.close}
        >
            <Dialog.Body>
                <div
                    {...getRootProps({
                        style: {
                            opacity: isDragActive ? 0.5 : undefined,
                            border: isDragActive ? "2px dashed #106ba3" : "2px dashed transparent",
                        },
                    })}
                >
                    <div>
                        {baseImage ? (
                            <ReactAvatarEditor
                                ref={editorRef}
                                image={baseImage}
                                width={256}
                                height={256}
                                border={92}
                                borderRadius={9999}
                                color={[255, 255, 255, 0.6]}
                                scale={scale}
                            />
                        ) : (
                            <div className={styles.avatarEditorPlaceholder}>Drag a photo here</div>
                        )}
                    </div>
                </div>

                {baseImage ? (
                    <Slider
                        stepSize={0.001}
                        showTrackFill={false}
                        labelRenderer={false}
                        value={scale}
                        min={1}
                        max={2}
                        onChange={setScale}
                    />
                ) : null}
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton
                        content="Choose file"
                        onClick={() => {
                            openFilePicker({ accept: "image/*" }, files => setBaseImage(files[0]));
                        }}
                        instrumentation={{
                            elementName: "edit_avatar_dialog.pick_file_btn",
                        }}
                    />
                    <div style={{ flex: "1 1 auto" }} />
                    <BorderButton content="Cancel" onClick={dialog.close} instrumentation={null} />
                    <BorderButton
                        content="Apply"
                        disabled={!baseImage}
                        loading={submission.isInFlight}
                        onClick={submission.watch(onSave)}
                        instrumentation={{
                            elementName: "edit_avatar_dialog.submit_btn",
                        }}
                        primary
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
