import React from "react";

import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { useSelectParentTicketDialog } from "dialogs/SelectParentTicketDialog";
import { divideAndFlattenGroups } from "lib/Helpers";
import { Queries } from "lib/Queries";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";

import styles from "./TicketAncestry.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketAncestry_ticket on tickets {
            id

            child_of_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    ticket: { trashed_at: { _is_null: true } }
                }
            ) {
                id

                ticket {
                    id
                    title
                    ref
                    slug

                    board {
                        id
                        display_name
                    }

                    child_of_tasks(
                        where: {
                            deleted_at: { _is_null: true }
                            ticket: { trashed_at: { _is_null: true } }
                        }
                    ) {
                        id

                        ticket {
                            id
                            title
                            ref
                            slug

                            board {
                                id
                                display_name
                            }
                        }
                    }
                }
            }
        }
    `),
};

export type TicketAncestryProps = {
    className?: string;
};

export function TicketAncestry({ className }: TicketAncestryProps) {
    const currentUser = useCurrentUser();
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const prefetchQuery = usePrefetchQuery();
    const { buildTicketUrl } = useUrlBuilders();

    const prefetchTicketDetails = ({ ticketRef }: { ticketRef: string }) => {
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref: ticketRef,
            },
        });
    };

    const parentTicketLinks = ticket.child_of_tasks
        .map(({ ticket: t }) => t)
        .sort((a, b) => a.title.localeCompare(b.title))
        .flatMap(parentTicket => {
            const ParentTicketLink = () => (
                <Link
                    className={styles.link}
                    to={
                        buildTicketUrl({
                            ticketSlug: parentTicket.slug,
                            vanity: {
                                boardDisplayName: parentTicket.board.display_name,
                                ticketRef: parentTicket.ref,
                                ticketTitle: parentTicket.title,
                            },
                        }).pathname
                    }
                    onFocus={() => prefetchTicketDetails({ ticketRef: parentTicket.ref })}
                    onMouseOver={() => prefetchTicketDetails({ ticketRef: parentTicket.ref })}
                >
                    {parentTicket.title}
                </Link>
            );

            return parentTicket.child_of_tasks.length ? (
                parentTicket.child_of_tasks.map(({ ticket: grandParentTicket }) => {
                    const GrandParentTicketLink = () => (
                        <Link
                            className={styles.link}
                            to={
                                buildTicketUrl({
                                    ticketSlug: grandParentTicket.slug,
                                    vanity: {
                                        boardDisplayName: grandParentTicket.board.display_name,
                                        ticketRef: grandParentTicket.ref,
                                        ticketTitle: grandParentTicket.title,
                                    },
                                }).pathname
                            }
                            onFocus={() =>
                                prefetchTicketDetails({ ticketRef: grandParentTicket.ref })
                            }
                            onMouseOver={() =>
                                prefetchTicketDetails({ ticketRef: grandParentTicket.ref })
                            }
                        >
                            {grandParentTicket.title}
                        </Link>
                    );

                    return (
                        <>
                            <GrandParentTicketLink />
                            <Icon
                                className={styles.chevron}
                                icon="chevron-right"
                                iconSet="lucide"
                                iconSize={14}
                                strokeWeight={1}
                            />
                            <ParentTicketLink />
                        </>
                    );
                })
            ) : (
                <ParentTicketLink />
            );
        });

    return (
        <TicketAncestryDisplay
            className={className}
            ticketId={ticket.id}
            parentTickets={parentTicketLinks}
        />
    );
}

type TicketAncestryDisplayProps = {
    className?: string;
    ticketId: string;
    parentTickets: React.ReactNode[];
};

function TicketAncestryDisplay({ className, ticketId, parentTickets }: TicketAncestryDisplayProps) {
    const selectParentTicketDialog = useSelectParentTicketDialog();

    return parentTickets?.length ? (
        <span className={classNames(className, styles.ticketAncestry)}>
            {divideAndFlattenGroups({
                itemGroups: parentTickets.map(p => [p]),
                divider: () => <span className={styles.pipe}>|</span>,
            })}
        </span>
    ) : (
        <div className={styles.addParentButtonWrapper}>
            <BorderButton
                className={styles.addParentButton}
                minimal
                zeroPadding
                useHoverEffect={false}
                content={
                    <span className={styles.addParentButtonContent}>
                        <Icon icon="plus" iconSet="lucide" iconSize={16} />
                        <span>Parent</span>
                    </span>
                }
                onClick={() =>
                    selectParentTicketDialog.openWithProps({
                        childTicketId: ticketId,
                        initialIneligibleTicketIds: [ticketId],
                    })
                }
                instrumentation={null}
            />
        </div>
    );
}

TicketAncestry.Display = TicketAncestryDisplay;
