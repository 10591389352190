import React, { useCallback, useEffect, useState } from "react";

import { Popover2 } from "@blueprintjs/popover2";
import classNames from "classnames";

import { UnreadBadge } from "components/ui/common/UnreadBadge";
import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Hotspot } from "components/ui/core/Hotspot";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useHotkey } from "lib/Hotkeys";
import { useInstrumentation } from "lib/Instrumentation";
import { useUpdateLastViewedWorkAt } from "lib/mutations";

import styles from "./MyWorkButton.module.scss";
import { MyWorkContent } from "./MyWorkContent";
import { useMyWork } from "./MyWorkContext";

const PopupState = {
    OPENED: "OPENED",
    OPENED_VIA_KEYBOARD: "OPENED_VIA_KEYBOARD",
    CLOSED: "CLOSED",
};

export type MyWorkButtonProps = {
    className?: string;
};

export function MyWorkButton({ className }: MyWorkButtonProps) {
    const currentUser = useCurrentUser();
    const myWork = useMyWork();
    const { recordEvent } = useInstrumentation();
    const [popupState, setPopupState] = useState(PopupState.CLOSED);
    const { updateLastViewedWorkAt } = useUpdateLastViewedWorkAt();
    const isOpen = popupState !== PopupState.CLOSED;

    useHotkey(
        "T",
        () =>
            setPopupState(prev =>
                prev === PopupState.CLOSED ? PopupState.OPENED_VIA_KEYBOARD : PopupState.CLOSED
            ),
        [setPopupState]
    );

    useEffect(() => {
        if (isOpen) {
            void recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_VIEW,
                elementName: "my_work",
                eventData: { isUnread: myWork.isUnread },
                dedupeKey: Date.now(),
            });
        }
    }, [isOpen, myWork.isUnread, recordEvent]);

    useEffect(() => {
        if (isOpen && !currentUser.isReadOnly) {
            void updateLastViewedWorkAt();
        }
    }, [currentUser.id, currentUser.isReadOnly, isOpen, updateLastViewedWorkAt]);

    const handleInteraction = useCallback((next: boolean) => {
        setPopupState(prev =>
            next ? (prev === PopupState.CLOSED ? PopupState.OPENED : prev) : PopupState.CLOSED
        );
    }, []);

    const closePopover = useCallback(() => setPopupState(PopupState.CLOSED), []);

    const numTodos = myWork.myTodosItems.length;

    return (
        <div className={classNames(className, styles.myWorkButton)}>
            <Popover2
                targetTagName="div"
                className={styles.popoverTarget}
                popoverClassName={styles.popover}
                content={
                    <MyWorkContent
                        defaultKeyboardEnabled={popupState === PopupState.OPENED_VIA_KEYBOARD}
                        onItemSelected={closePopover}
                    />
                }
                minimal
                isOpen={isOpen}
                onInteraction={handleInteraction}
                placement="bottom-end"
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [4, 4],
                        },
                    },
                    preventOverflow: {
                        enabled: true,
                        options: {
                            padding: { left: 24, right: 24 },
                        },
                    },
                    flip: {
                        enabled: false,
                    },
                }}
                hasBackdrop
                shouldReturnFocusOnClose
            >
                <Hotspot
                    hotspotKey={Enums.HotspotKey.MY_WORK}
                    contentPlacement="right"
                    disabled={isOpen}
                    offset={[0, 12]}
                    placement="right"
                    usePortal={false}
                >
                    <Tooltip
                        targetTagName="div"
                        className={styles.popoverTarget}
                        content={
                            <span>
                                Threads <Hotkey text="T" />
                            </span>
                        }
                        disabled={isOpen}
                        openOnTargetFocus={false}
                        placement="bottom"
                        small
                    >
                        <BorderButton
                            className={classNames(
                                styles.button,
                                numTodos > 0 ? styles.active : styles.inactive
                            )}
                            instrumentation={{
                                elementName: "navbar.my_work_btn",
                                eventData: { isUnread: myWork.isUnread },
                            }}
                            minimal
                            content={
                                <span className={styles.buttonContent}>
                                    {myWork.isUnread ? (
                                        <UnreadBadge className={styles.unreadBadge} />
                                    ) : null}
                                    <Icon
                                        iconSet="c9r"
                                        icon="chatBubbleWithLines"
                                        iconSize={22}
                                        strokeWidth={1.5}
                                    />
                                    {numTodos ? (
                                        <span className={styles.numTodos}>{numTodos}</span>
                                    ) : null}
                                </span>
                            }
                            textHover
                            themeDark
                        />
                    </Tooltip>
                </Hotspot>
            </Popover2>
        </div>
    );
}
