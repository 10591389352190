import { useCallback } from "react";

import { CommonEnums } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useEnqueueDataImport() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation EnqueueDataImport($orgId: Int!, $source: String!, $sourceDataUrl: String!) {
                insert_imports_one(
                    object: { org_id: $orgId, source: $source, source_data_url: $sourceDataUrl }
                ) {
                    id
                }
            }
        `)
    );

    const enqueueDataImport = useCallback(
        async (
            {
                source,
                sourceDataUrl,
            }: {
                source: typeof CommonEnums.ImportSource.TRELLO;
                sourceDataUrl: string;
            },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const orgId = currentUser.org_id;

            return mutation({
                ...mutationOptions,
                variables: { orgId, source, sourceDataUrl },
            });
        },
        [currentUser.org_id, mutation]
    );

    return { enqueueDataImport };
}
