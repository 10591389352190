import { Errors } from "c9r-common";

import { Log } from "lib/Log";
import { IReadApi } from "lib/replicache/Api";

/**
 * Helper class to apply a Hasura-style "where" clause. As of May 2023, this is intended to
 * facilitate transitioning the app away from querying the Hasura API directly and to querying
 * Replicache. Consequently, this class only bothers to implement the Hasura-style where
 * operators that we use in practice. Other operators could be added relatively straightforwardly
 * if needed.
 *
 * For simplicity, this class does not have static type checking to enforce that the where clause
 * references fields and associations that actually exist. Instead these are checked at runtime.
 */
export class WhereClause {
    api: IReadApi;

    // This list of associations is not necessarily complete. If we need to support a where clause
    // in an assodication not listed here, it's fine to add it.
    private static Associations: Record<string, Record<string, { idField: string }>> = {
        boards: {
            stage: { idField: "stage_id" },
        },
        comments: {
            thread: { idField: "thread_id" },
        },
        stages: {
            board: { idField: "board_id" },
        },
        tasks: {
            child_ticket: { idField: "child_ticket_id" },
            tasklist: { idField: "tasklist_id" },
            ticket: { idField: "ticket_id" },
        },
        threads: {
            ticket: { idField: "ticket_id" },
        },
        tickets: {
            board: { idField: "board_id" },
            stage: { idField: "stage_id" },
        },
        tickets_merge_requests: {
            merge_request: { idField: "merge_request_id" },
            ticket: { idField: "ticket_id" },
        },
        tickets_owners: {
            owner: { idField: "user_id" },
            ticket: { idField: "ticket_id" },
        },
    };

    constructor({ api }: { api: IReadApi }) {
        this.api = api;
    }

    async check<T extends { __typename: string }>({
        entry,
        where,
    }: {
        entry: T;
        where?: {} | null;
    }): Promise<T | null> {
        if (!where) {
            return entry;
        }

        // We check the where clauses in two loops.

        // This loop is first, where we look at simple non-association fields, because if
        // any of these fail, we can abort early without wasting time looking up the associations.
        for (const [associationOrScalarField, clause] of Object.entries(where)) {
            if (!clause) {
                Log.debug("Where clause is empty", { where, clause });
                throw new Errors.UnexpectedCaseError({
                    reason: "Where clause is empty",
                    where,
                    clause,
                });
            }

            const association =
                WhereClause.Associations[entry.__typename]?.[associationOrScalarField];

            if (!association) {
                const scalarField = associationOrScalarField as keyof T;

                for (const [op, val] of Object.entries(clause)) {
                    switch (op) {
                        case "_eq":
                            // _eq cannot be used to compare to null. Use _is_null instead.
                            // This mimics the behavior of Hasura.
                            if (entry[scalarField] === null) {
                                return null;
                            }

                            if (entry[scalarField] !== val) {
                                return null;
                            }

                            break;

                        case "_is_null":
                            if ((entry[scalarField] === null) !== val) {
                                return null;
                            }

                            break;

                        case "_in":
                            if (!val.includes(entry[scalarField])) {
                                return null;
                            }

                            break;

                        case "_nin":
                            if (val.includes(entry[scalarField])) {
                                return null;
                            }

                            break;

                        default:
                            Log.debug("Unsupported where clause", { scalarField, op });
                            throw new Errors.UnexpectedCaseError({
                                reason: "Unsupported where clause",
                                where,
                                scalarField,
                                op,
                            });
                    }
                }
            }
        }

        // Now we must loop again and look at the association fields, since the non-association
        // fields have all passed.
        for (const [associationOrScalarField, clause] of Object.entries(where)) {
            if (!clause) {
                continue;
            }

            const association =
                WhereClause.Associations[entry.__typename]?.[associationOrScalarField];

            if (association) {
                const id = entry[association.idField as keyof T] as string | undefined;

                if (!id) {
                    Log.debug("ID field for association is not populated", { where, association });
                    throw new Errors.UnexpectedCaseError({
                        reason: "ID field for association is not populated",
                        where,
                        association,
                    });
                }

                if (
                    !(await this.check({
                        entry: await this.api.entries.getByIdOrThrow({ id }),
                        where: clause,
                    }))
                ) {
                    return null;
                }
            }
        }

        return entry;
    }
}
