import React from "react";

import classNames from "classnames";

import styles from "./BillingSubsection.module.scss";

export type BillingSubsectionProps = {
    className?: string;
    children?: React.ReactNode;
    title: string;
};

export function BillingSubsection({ className, children, title }: BillingSubsectionProps) {
    return (
        <section className={classNames(className, styles.billingSubsection)}>
            <header>{title}</header>
            {children}
        </section>
    );
}
