import React, { useEffect, useState } from "react";

import classNames from "classnames";

import {
    TicketSelector,
    TicketSelectorItem,
    TicketSelectorItemType,
} from "components/shared/TicketSelector";
import { BorderButton } from "components/ui/core/BorderButton";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useToggle } from "lib/Hooks";
import { useHotkey } from "lib/Hotkeys";
import { useInstrumentation, useRecordTicketSearch } from "lib/Instrumentation";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";

import styles from "./SearchBox.module.scss";

export type SearchBoxProps = {
    className?: string;
};

export function SearchBox({ className }: SearchBoxProps) {
    const currentUser = useCurrentUser();
    const { getCurrentLocation, history } = useHistory();
    const [query, setQuery] = useState("");
    const { recordEvent } = useInstrumentation();
    const { recordTicketSearch } = useRecordTicketSearch({
        elementName: "navbar.search",
    });
    const { buildSearchUrl, buildTicketUrl } = useUrlBuilders();
    const searchBoxPopover = useToggle();

    useHotkey("/", () => searchBoxPopover.open(), [searchBoxPopover]);
    useEffect(() => recordTicketSearch(query), [query, recordTicketSearch]);

    const handleOnItemSelect = (suggestItem: TicketSelectorItem) => {
        const redirectUrl =
            suggestItem.type === TicketSelectorItemType.VIEW_FULL_RESULTS
                ? `${buildSearchUrl().pathname}${query ? `?query=${query}&page=1` : ""}`
                : buildTicketUrl({
                      ticketSlug: suggestItem.ticket.slug,
                      vanity: {
                          boardDisplayName: suggestItem.ticket.board.display_name,
                          ticketRef: suggestItem.ticket.ref,
                          ticketTitle: suggestItem.ticket.title,
                      },
                  }).pathname;

        const elementName =
            suggestItem.type === TicketSelectorItemType.VIEW_FULL_RESULTS
                ? "navbar.search.view_full_results"
                : "navbar.search.result";

        const eventData =
            suggestItem.type === TicketSelectorItemType.VIEW_FULL_RESULTS
                ? {
                      searchQuery: query,
                  }
                : {
                      ticketId: suggestItem.ticket.id,
                      isArchived: !!suggestItem.ticket.archived_at,
                      searchQuery: query,
                  };

        history.push(redirectUrl, {
            from: { location: getCurrentLocation() },
        });

        void recordEvent({
            eventType: Enums.InstrumentationEvent.CLICK,
            elementName,
            eventData,
        });

        searchBoxPopover.close();
    };

    return (
        <div className={classNames(className, styles.searchBox)}>
            <MenuPopover
                canEscapeKeyClose={query === ""}
                className={styles.ticketSelectorPopover}
                content={
                    <TicketSelector
                        autoFocus
                        onQueryChange={setQuery}
                        onSelect={handleOnItemSelect}
                        query={query}
                        paddingTight
                        resetActiveItemOnEmptyQuery
                        showViewFullResults
                    />
                }
                placement="bottom"
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [0, -30],
                        },
                    },
                }}
                isOpen={searchBoxPopover.isOpen}
                onClose={() => searchBoxPopover.close()}
                onClosed={() => setQuery("")}
                transitionDuration={0}
            >
                <BorderButton
                    alignContentLeft
                    content={<>Search {currentUser.org.display_name}</>}
                    className={styles.searchButton}
                    data-cy="search-btn"
                    leftIconProps={{
                        icon: "search",
                        iconSet: "c9r",
                        iconSize: 20,
                    }}
                    instrumentation={{ elementName: "navbar.search.btn" }}
                    useHoverEffect={false}
                    onClick={() => searchBoxPopover.open()}
                    themeDarkHighContrast
                />
            </MenuPopover>
        </div>
    );
}
