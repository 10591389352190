import { Config } from "Config";

export function createDeepLink({
    pathname,
    search,
    hash,
}: {
    /** The pathname. Should start with a "/". */
    pathname?: string;
    search?: string;
    hash?: string;
} = {}) {
    const url = new URL(`${Config.customProtocol}:/${pathname ?? "/"}`);

    url.search = search ?? "";
    url.hash = hash ?? "";

    return url.href;
}

export function useRedirectToDesktop({ deepLinkUrl }: { deepLinkUrl: string }) {}
