import { useCallback } from "react";

import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useUpdateOrgDisplayName() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation UpdateOrgDisplayName($orgId: Int!, $displayName: String!) {
                update_orgs_by_pk(
                    pk_columns: { id: $orgId }
                    _set: { display_name: $displayName }
                ) {
                    id
                    display_name
                }
            }
        `)
    );

    const updateOrgDisplayName = useCallback(
        async (
            { displayName }: { displayName: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const orgId = currentUser.org_id;

            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: { orgId, displayName },
                optimisticResponse: {
                    update_orgs_by_pk: {
                        __typename: "orgs",
                        id: currentUser.org_id,
                        display_name: displayName,
                    },
                },
            });
        },
        [currentUser.org_id, mutation]
    );

    return { updateOrgDisplayName };
}
