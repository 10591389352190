import React from "react";

import classNames from "classnames";

import styles from "./NewDiscussionItemBox.module.scss";

export type NewDiscussionItemBoxProps = {
    className?: string;
    children: React.ReactNode;
};

export const NewDiscussionItemBox = React.forwardRef<HTMLDivElement, NewDiscussionItemBoxProps>(
    ({ className, children }, ref) => {
        return (
            <div
                data-cy="new-discussion-item-box"
                ref={ref}
                className={classNames(className, styles.newDiscussionItemBox)}
            >
                {children}
            </div>
        );
    }
);
