import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import styles from "./BoardStatusToast.module.scss";

export type BoardStatusToastProps = {
    className?: string;
    children?: React.ReactNode;
    isOpen?: boolean;
};

export function BoardStatusToast({ className, children, isOpen }: BoardStatusToastProps) {
    const lastChildren = useRef(children);

    useEffect(() => {
        if (isOpen) {
            lastChildren.current = children;
        }
    }, [isOpen, children]);

    return (
        <CSSTransition
            in={isOpen}
            timeout={{
                enter: 200,
                exit: 30,
            }}
            classNames={{ ...styles }}
            mountOnEnter
            unmountOnExit
        >
            <div className={classNames(className, styles.boardStatusToast)}>
                {isOpen ? children : lastChildren.current}
            </div>
        </CSSTransition>
    );
}
