import React, { useLayoutEffect, useRef, useState } from "react";

import { Popover2 } from "@blueprintjs/popover2";

import { QueryLoader } from "components/loading/QueryLoader";
import { TicketActionsSelector } from "components/shared/TicketActionsSelector";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { EditTicketsToast_boardFragment } from "lib/graphql/__generated__/graphql";

import styles from "./EditTicketsToast.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment EditTicketsToast_board on boards {
            id
            ...TicketActionsSelector_board
        }
    `),
};

export type EditTicketsToastProps = {
    boardId: string;
    onClose?: () => void;
    isOpen?: boolean;
    ticketIds: string[];
};

type EditTicketsToastImplProps = {
    board: EditTicketsToast_boardFragment;
    onClose?: () => void;
    isOpen?: boolean;
    ticketIds: string[];
};

function EditTicketsToastImpl({ board, onClose, isOpen, ticketIds }: EditTicketsToastImplProps) {
    const filterInputRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState("");
    const elementName = "board.edit_tickets_menu";

    useLayoutEffect(() => {
        if (isOpen) {
            setQuery("");
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const shouldOutsideClickCancel = !!query;

    return (
        <Popover2
            canEscapeKeyClose={false}
            content={
                <TicketActionsSelector
                    board={board}
                    elementName={elementName}
                    filterInputRef={filterInputRef}
                    onClose={onClose}
                    onQueryChange={setQuery}
                    query={query}
                    ticketIds={ticketIds}
                />
            }
            hasBackdrop={shouldOutsideClickCancel}
            isOpen={isOpen}
            modifiers={{
                arrow: { enabled: false },
                offset: {
                    enabled: true,
                    options: { offset: [0, -30] },
                },
            }}
            onInteraction={(next, e) => {
                if (!next && shouldOutsideClickCancel) {
                    if (query) {
                        setQuery("");
                        setImmediate(() => {
                            filterInputRef.current?.focus();
                        });
                    } else {
                        onClose?.();
                    }
                }
            }}
            placement="bottom"
            popoverClassName={styles.popover}
            transitionDuration={0}
            // As of January 2024, not using a portal ensures that, if this toast/popup is open,
            // and subsequently opens a popup from the navbar that overlaps with it (search, recent
            // tickets, or threads), the latter will appear on top.
            //
            // There's probably a better way to do this using stacking contexts.
            usePortal={false}
        >
            <div />
        </Popover2>
    );
}

export function EditTicketsToast({ boardId, onClose, isOpen, ticketIds }: EditTicketsToastProps) {
    return (
        <QueryLoader query={EditTicketsToast.queries.component} variables={{ boardId }}>
            {({ queryResult }) => {
                const board = getFragmentData(fragments.board, queryResult.data?.board);

                if (!board) {
                    return null;
                }

                return (
                    <EditTicketsToastImpl
                        board={board}
                        onClose={onClose}
                        isOpen={isOpen}
                        ticketIds={ticketIds}
                    />
                );
            }}
        </QueryLoader>
    );
}

EditTicketsToast.queries = {
    component: gql(/* GraphQL */ `
        query EditTicketsToast($boardId: uuid!) {
            board: boards_by_pk(id: $boardId) {
                ...EditTicketsToast_board
            }
        }
    `),
};
