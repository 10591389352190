import { useCallback } from "react";

import { useAuth0 as useAuth0Original } from "@auth0/auth0-react";

import { Storage } from "lib/Storage";
import { clearLoginHint } from "lib/auth/Login";

// Wrapper around Auth0 prepopulating some methods with default behavior.
export const useAuth0 = () => {
    const { loginWithRedirect, logout: auth0Logout, ...rest } = useAuth0Original();

    const login = useCallback(
        /**
         * Redirect the user to the login/signup page.
         */
        async ({
            hintEmailAddress,
            isSignup,
        }: {
            /**
             * If provided, will be used to prefill or otherwise suggest the email address
             * the user should log in with.
             */
            hintEmailAddress?: string;

            /** Whether the user logging in from the signup flow. */
            isSignup?: boolean;
        } = {}) => {
            const loginHint =
                hintEmailAddress ?? Storage.Local.getItem("auth.login.hint")?.emailAddress;

            const authorizationParams = {
                login_hint: loginHint,
                prompt: !loginHint ? "select_account" : undefined,

                // Used to choose whether the login screen opens by default to the login tab or
                // signup tab. See Auth0 login.html.
                screen_hint: isSignup ? "signup" : undefined,
            } as const;

            await loginWithRedirect({
                // Store the route that the user was visiting before they get redirected to the
                // login page. That way, when they get redirected back after a successful login,
                // we can redirect them back to that same page.
                appState: {
                    pathname: window.location.pathname,
                    search: window.location.search,
                    hash: window.location.hash,
                },

                authorizationParams,
            });
        },
        [loginWithRedirect]
    );

    const logout = useCallback(() => {
        clearLoginHint();

        auth0Logout({
            logoutParams: {
                returnTo: window.electron?.config.isPackaged
                    ? // Special sentinel. It's configured in Auth0 as a valid logout redirect URL,
                      // but it doesn't actually exist. Instead, the main Electron process catches
                      // an attempt to redirect to this URL, and then reloads the main window.
                      "https://logout.flat.app"
                    : window.location.origin,
            },
        });
    }, [auth0Logout]);

    return { login, logout, ...rest };
};
