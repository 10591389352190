import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useRecoilValue } from "recoil";
import { ReadTransaction, Replicache } from "replicache";
import { useSubscribe } from "replicache-react";

import { useSetDataSyncLoading } from "components/loading/Loading";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { createCtx } from "lib/react/Context";

import { TReplicache, createReplicacheInstance } from "./Factory";
import { usePokeReplicache } from "./Poke";
import { EntryKeys } from "./entries/EntryKeys";

export const REPLICACHE_QUERY_LOADING = "REPLICACHE_QUERY_LOADING";

const [useReplicache, ContextProvider] = createCtx<{
    replicache: TReplicache;
}>();

function usePullOnNetworkRestored({ replicache }: { replicache: TReplicache }) {
    const isOnline = useRecoilValue(networkStatusState);
    const lastIsOnline = useRef(isOnline);

    useEffect(() => {
        if (isOnline && !lastIsOnline.current) {
            replicache.pull();
        }

        lastIsOnline.current = isOnline;
    }, [isOnline, replicache]);
}

type ReplicacheProviderProps = {
    children?: React.ReactNode;

    // Whether to create a "stub" Replicache instance that doesn't communicate with the backend.
    // Useful in some tests and Storybook.
    isStub?: boolean;
};

function ReplicacheProvider({ children, isStub }: ReplicacheProviderProps) {
    const currentUser = useCurrentUser();
    const { isFeatureEnabled } = useFeatureFlags();
    const setDataSyncLoading = useSetDataSyncLoading();

    const isReplicachePullEnabled = !isStub;
    const shouldUseTestLicenseKey =
        isFeatureEnabled({
            feature: Enums.Feature.REPLICACHE_USE_TEST_LICENSE,
        }) || isStub;

    const isReplicachePullEnabledRef = useRef(isReplicachePullEnabled);

    useEffect(() => {
        isReplicachePullEnabledRef.current = isReplicachePullEnabled;
    }, [isReplicachePullEnabled]);

    const shouldUseFakePull = useCallback(() => !isReplicachePullEnabledRef.current, []);

    // The idea here is to not destroy and recreate the Replicache instance on every render.
    // In Strict Mode, however, ReplicacheProvider will necessarily get rendered twice, so
    // we would end up with two instances, except that createReplicacheInstance itself
    // memoizes the result.
    const { pause, replicache } = useMemo(() => {
        return createReplicacheInstance({
            userId: currentUser.id,
            userOrgId: currentUser.org_id,
            shouldUseFakePull,
            shouldUseTestLicenseKey,
        });
    }, [currentUser.id, currentUser.org_id, shouldUseFakePull, shouldUseTestLicenseKey]);

    useEffect(() => {
        return pause;
    }, [pause]);

    usePokeReplicache({ replicache });
    usePullOnNetworkRestored({ replicache });

    const metadata = useSubscribe(
        replicache,
        async txn => txn.get(EntryKeys.buildKey({ type: EntryKeys.Type.METADATA })),
        null
    );
    const isMetadataPresent = !!metadata;

    useEffect(() => {
        setDataSyncLoading({ isReady: isMetadataPresent, isRequired: true });

        return () => {
            setDataSyncLoading({ isReady: false, isRequired: false });
        };
    }, [setDataSyncLoading, isMetadataPresent]);

    const value = useMemo(() => ({ replicache }), [replicache]);

    // The app cannot continue doing/showing anything until we have data in Replicache.
    if (!isStub && !metadata) {
        return null;
    }

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

function useReplicacheSubscription<T extends any | undefined>(
    fn: (tx: ReadTransaction) => Promise<T>,
    def: T,
    deps: Parameters<typeof useSubscribe>[3]
): T {
    const { replicache } = useReplicache();

    // TODO: This removes the read-only type check from Replicache. Is there a way to easily keep it?
    // @ts-expect-error
    return useSubscribe<T>(replicache, fn, def, deps);
}

function useReplicachePreload() {
    const { replicache } = useReplicache();

    if (!replicache) {
        throw new Error("Cannot call usePreload without replicache");
    }

    return useCallback(
        async (...args: Parameters<Replicache["query"]>) => {
            await replicache.query(...args);
        },
        [replicache]
    );
}

export { ReplicacheProvider, useReplicache, useReplicachePreload, useReplicacheSubscription };
