import { useEffect } from "react";

import { useApolloClient } from "@apollo/client";

import { useMaybeCurrentUser } from "AppState";
import { ThemeClassNames } from "lib/Constants";
import { Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { Storage } from "lib/Storage";
import { gql } from "lib/graphql/__generated__";

const OSDarkSchemeCheck = window.matchMedia?.("(prefers-color-scheme: dark)");

export function setThemeClassName() {
    const theme = Storage.Local.getItem("theme");
    const baseTheme = theme?.colorScheme;
    const withhighContrast = !!(theme?.HIGH_CONTRAST?.enabled ?? true);

    const addClassNameToBody = (newClassName: string, darkhighContrast = false) => {
        Object.values(ThemeClassNames).forEach(cn => {
            document.body.classList.remove(cn);
        });

        document.body.classList.add(newClassName);

        if (darkhighContrast) {
            document.body.classList.add(ThemeClassNames.HIGH_CONTRAST);
        }
    };

    switch (baseTheme) {
        case Enums.ColorScheme.LIGHT_ALWAYS: {
            addClassNameToBody(ThemeClassNames.LIGHT);
            break;
        }

        case Enums.ColorScheme.DARK_ALWAYS: {
            addClassNameToBody(ThemeClassNames.DARK, withhighContrast);
            break;
        }

        case Enums.ColorScheme.FOLLOW_SYSTEM:
        default: {
            addClassNameToBody(
                OSDarkSchemeCheck?.matches ? ThemeClassNames.DARK : ThemeClassNames.LIGHT,
                OSDarkSchemeCheck?.matches && withhighContrast
            );
            break;
        }
    }
}

export function setThemeInStorage(appearancePreferences: object, userId: number) {
    if (userId) {
        Storage.Local.setItem("theme", appearancePreferences);
        // Force current window to be notified of change.
        window.dispatchEvent(new Event("storage"));
    }
}

export function useThemeManager() {
    const currentUser = useMaybeCurrentUser();
    const currentUserId = currentUser?.id;
    const client = useApolloClient();

    useEffect(() => {
        window.addEventListener("storage", setThemeClassName);

        return () => {
            window.removeEventListener("storage", setThemeClassName);
        };
    }, []);

    const OSDarkSchemeEventListener = (action: "add" | "remove") => {
        const eventType =
            // In modern browsers, MediaQueryList has addEventListener, but in older
            // browsers, it has addListener. TypeScript only knows the former, but we
            // need a runtime check just in case.
            // @ts-expect-error Because TypeScript thinks this condition is always true.
            OSDarkSchemeCheck.addEventListener && OSDarkSchemeCheck.removeEventListener
                ? "change"
                : null;

        const eventListener = ({
            add: eventType ? "addEventListener" : "addListener",
            remove: eventType ? "removeEventListener" : "removeListener",
        } as const)[action];

        const params = [eventType, setThemeClassName].filter(Boolean);

        // In modern browsers, MediaQueryList has addEventListener, but in older
        // browsers, it has addListener. TypeScript only knows the former.
        // @ts-expect-error
        OSDarkSchemeCheck[eventListener](...params);
    };

    useEffect(() => {
        // This isn't *that* important, so if it fails (e.g., in older browsers), don't crash the
        // entire app.
        try {
            OSDarkSchemeEventListener("add");
        } catch (error) {
            Log.warn("Failed to listen to media query for dark mode", { error });
        }

        return () => {
            try {
                OSDarkSchemeEventListener("remove");
            } catch (error) {
                Log.warn("Failed to clean up listener for media query for dark mode", { error });
            }
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!currentUserId) {
                return;
            }

            const result = await client.query({
                query: gql(/* GraphQL */ `
                    query ThemeManagerGetUserAppearancePreferences($currentUserId: Int!) {
                        user: users_by_pk(id: $currentUserId) {
                            id
                            appearance_preferences
                        }
                    }
                `),
                variables: {
                    currentUserId,
                },
            });

            const appearancePreferences = Object.keys(
                result.data?.user?.appearance_preferences || {}
            ).length
                ? result.data.user!.appearance_preferences
                : null;

            if (appearancePreferences) {
                setThemeInStorage(appearancePreferences, currentUserId);
            }
        })();
    }, [currentUserId, client]);
}
