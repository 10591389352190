import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useUpdateTaskAssignee() {
    const { replicache } = useReplicache();

    const updateTaskAssignee = useCallback(
        async ({
            taskId,
            assignedToUserId,
        }: {
            taskId: string;
            assignedToUserId: number | null;
        }) => {
            await replicache.mutate.updateTask({ taskId, assignedToUserId });
        },
        [replicache]
    );

    return { updateTaskAssignee };
}

export function useUpdateTaskDueDate() {
    const { replicache } = useReplicache();

    const updateTaskDueDate = useCallback(
        async ({ taskId, dueDate }: { taskId: string; dueDate: Date | null }) => {
            await replicache.mutate.updateTask({ taskId, dueDate });
        },
        [replicache]
    );

    return { updateTaskDueDate };
}

export function useUpdateTaskCompletion() {
    const { replicache } = useReplicache();

    const updateTaskCompletion = useCallback(
        async ({ taskId, isComplete }: { taskId: string; isComplete: boolean }) => {
            await replicache.mutate.updateTask({ taskId, isComplete });
        },
        [replicache]
    );

    return { updateTaskCompletion };
}

export function useUpdateTaskTitle() {
    const { replicache } = useReplicache();

    const updateTaskTitle = useCallback(
        async ({ taskId, title }: { taskId: string; title: string }) => {
            await replicache.mutate.updateTask({ taskId, title });
        },
        [replicache]
    );

    return { updateTaskTitle };
}
