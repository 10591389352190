import React from "react";

import classNames from "classnames";

import styles from "./TicketCount.module.scss";

export type TicketCountProps = {
    className?: string;
    ticketCount: number;
};

export function TicketCount({ className, ticketCount }: TicketCountProps) {
    return (
        <span className={classNames(className, styles.ticketCount)}>
            &nbsp;&nbsp;({ticketCount})
        </span>
    );
}
