import { useCallback } from "react";

import { DocumentNode, FetchPolicy, useApolloClient } from "@apollo/client";

import { EnumValue } from "lib/Enums";
import { Log } from "lib/Log";

export function usePrefetchQuery() {
    const apolloClient = useApolloClient();

    return useCallback(
        async ({
            query,
            variables,
            fetchPolicy,
            apiRoleType,
            usesLegacyApi,
        }: {
            query: DocumentNode;
            variables?: Record<string, any>;
            fetchPolicy?: FetchPolicy;
            apiRoleType?: EnumValue<"ApiRoleType">;
            usesLegacyApi?: boolean;
        }) => {
            try {
                if (usesLegacyApi) {
                    await apolloClient.query({
                        query,
                        variables,
                        fetchPolicy,
                        context: {
                            apiRoleType,
                        },
                    });
                }
            } catch (error) {
                Log.warn("Failed to prefetch query", { error });
            }
        },
        [apolloClient]
    );
}
