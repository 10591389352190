import React, { useCallback, useMemo, useState } from "react";

import classNames from "classnames";

import { useMaybeCurrentOrg } from "AppState";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { TextButton } from "components/ui/core/TextButton";
import { useIsTitleBarHidden } from "lib/Hooks";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { createCtx } from "lib/react/Context";

import styles from "./FullPageModal.module.scss";

type TReturnTo = {
    pathname: string;
    search?: string;
    hash?: string;
};

export type FullPageModalContextValue = {
    setReturnTo: (newReturnTo: TReturnTo) => void;
};

const [useFullPageModalContext, ContextProvider] = createCtx<FullPageModalContextValue>();

export { useFullPageModalContext };

export type FullPageModalProps = {
    children: React.ReactNode;
};

export function FullPageModal({ children }: FullPageModalProps) {
    const currentOrg = useMaybeCurrentOrg();
    const { buildCurrentOrgUrl } = useUrlBuilders();
    const { history } = useHistory();
    const [{ returnTo }, setLocationState] = useState<{ returnTo?: TReturnTo }>({
        returnTo: history.location.state?.from?.location,
    });
    const returnToUrl = returnTo
        ? `${returnTo.pathname}${returnTo.search || ""}${returnTo.hash || ""}`
        : currentOrg
        ? buildCurrentOrgUrl().pathname
        : "/";

    const setReturnTo = useCallback((newReturnTo: TReturnTo) => {
        setLocationState(prev => ({ ...prev, returnTo: newReturnTo }));
    }, []);

    const methods = useMemo(() => ({ setReturnTo }), [setReturnTo]);

    const isTitleBarHidden = useIsTitleBarHidden();

    return (
        <ContextProvider value={methods}>
            <div
                className={classNames(
                    styles.fullPageModal,
                    isTitleBarHidden && styles.titleBarHidden
                )}
            >
                {children}
                <TextButton
                    className={classNames(
                        styles.backButton,
                        isTitleBarHidden && styles.titleBarHidden
                    )}
                    link
                    instrumentation={{ elementName: "full_page_modal.back_btn" }}
                    text={
                        <>
                            <Icon icon="arrow-left" iconSet="lucide" iconSize={16} />
                            Back
                        </>
                    }
                    onClick={() => history.push(returnToUrl)}
                    data-cy="full-page-modal-back-btn"
                />
                <BorderButton
                    className={styles.closeButton}
                    content={<Icon icon="x" iconSet="lucide" iconSize={24} />}
                    instrumentation={{ elementName: "full_page_modal.close_btn" }}
                    minimal
                    onClick={() => history.push(returnToUrl)}
                />
            </div>
        </ContextProvider>
    );
}
