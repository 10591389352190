import React, { useEffect, useRef, useState } from "react";

import { Suggest } from "@blueprintjs/select";
import { CommonEnums } from "c9r-common";
import classNames from "classnames";
import isEmail from "validator/lib/isEmail";

import { BrandLogomark } from "components/shared/BrandLogomark";
import { Avatar } from "components/ui/common/Avatar";
import { UserMenuItem } from "components/ui/common/UserMenuItem";
import { AppToaster } from "components/ui/core/AppToaster";
import { Banner } from "components/ui/core/Banner";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentUser } from "contexts/UserContext";
import { ExternalTicketSources } from "lib/Constants";
import { useAsyncWatcher, useDialog } from "lib/Hooks";
import { Log } from "lib/Log";
import { useNomenclature } from "lib/Nomenclature";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserDispositionBanner_boardFragment } from "lib/graphql/__generated__/graphql";
import { useDisposeUser } from "lib/mutations";
import { isDefined } from "lib/types/guards";

import styles from "./UserDispositionBanner.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment UserDispositionBanner_board on boards {
            id

            authorized_users {
                user {
                    id
                    name
                    is_pending_disposition

                    identity {
                        id
                        email_address
                    }

                    ...Avatar_user
                    ...UserMenuItem_user
                }
            }

            import {
                id
                source
            }
        }
    `),
};

type TUser = NonNullable<UserDispositionBanner_boardFragment["authorized_users"][number]["user"]>;

const UserSelectorChangeType = {
    INPUT: "INPUT",
    USER: "USER",
} as const;

type TUserSelectorChange =
    | {
          type: typeof UserSelectorChangeType.INPUT;
          input: string;
      }
    | {
          type: typeof UserSelectorChangeType.USER;
          userId: number;
      };

type UserPillProps = {
    onClear?: () => void;
    user: TUser;
};

function UserPill({ onClear, user }: UserPillProps) {
    return (
        <span className={styles.userPill}>
            <Avatar user={user} size={26} />
            <span className={styles.userPillName}>{user.name}</span>
            <BorderButton
                className={styles.userPillDeleteBtn}
                content={<Icon icon="x" iconSet="lucide" iconSize={16} />}
                minimal
                small
                onClick={onClear}
                instrumentation={null}
            />
        </span>
    );
}

type UserSelectorProps = {
    autoFocus?: boolean;
    board: UserDispositionBanner_boardFragment;
    onChange: (change: TUserSelectorChange) => void;
};

function UserSelector({ autoFocus, board, onChange }: UserSelectorProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState("");
    const [hasError, setHasError] = useState(false);
    const [selectedUser, setSelectedUser] = useState<TUser | null>(null);

    const availableUsers = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .filter(user => !user.is_pending_disposition);

    if (selectedUser) {
        return (
            <UserPill
                user={selectedUser}
                onClear={() => {
                    setSelectedUser(null);
                    setInput("");
                    setHasError(false);
                    onChange?.({ type: UserSelectorChangeType.INPUT, input: "" });

                    // Wait for the next render, when the input reappears, before focusing.
                    setImmediate(() => {
                        inputRef.current?.focus();
                    });
                }}
            />
        );
    }

    return (
        <Suggest
            className={styles.userPicker}
            fill
            query={input}
            popoverProps={{ minimal: true }}
            inputProps={{
                className: classNames(
                    styles.userPickerInput,
                    hasError && styles.userPickerInputHasError
                ),
                onBlur: () => {
                    setTimeout(() => {
                        if (input && !isEmail(input)) {
                            setHasError(true);
                        }
                    }, 500);
                },
                placeholder: "Email address or username",
                autoFocus,
                inputRef,
            }}
            inputValueRenderer={() => input}
            items={availableUsers}
            itemPredicate={(query, user) =>
                user.identity?.email_address.toLowerCase().includes(query.toLowerCase()) ||
                user.name.toLowerCase().includes(query.toLowerCase())
            }
            itemListRenderer={({ filteredItems, query, renderItem }) => {
                if (!query) {
                    return null;
                }

                if (!filteredItems.length) {
                    return null;
                }

                return <Menu>{filteredItems.map(renderItem)}</Menu>;
            }}
            itemRenderer={(user, { handleClick, modifiers }) => {
                if (!modifiers.matchesPredicate) {
                    return null;
                }

                return (
                    <UserMenuItem
                        active={modifiers.active}
                        key={user.id}
                        onClick={handleClick}
                        showAvatarAsIcon
                        user={user}
                        instrumentation={{
                            elementName: "user_disposition_dialog.user_menu_item",
                            eventData: { userId: user.id },
                        }}
                    />
                );
            }}
            onItemSelect={user => {
                setSelectedUser(user);
                setInput("");
                onChange?.({ type: UserSelectorChangeType.USER, userId: user.id });
            }}
            onQueryChange={query => {
                const matchingUserByEmailAddress = availableUsers.find(
                    user =>
                        user.identity?.email_address &&
                        query &&
                        user.identity?.email_address.toLowerCase() === query.toLowerCase()
                );

                if (matchingUserByEmailAddress) {
                    setSelectedUser(matchingUserByEmailAddress);
                    setInput("");
                    onChange?.({
                        type: UserSelectorChangeType.USER,
                        userId: matchingUserByEmailAddress.id,
                    });
                } else {
                    setInput(query);
                    setHasError(false);
                    onChange?.({ type: UserSelectorChangeType.INPUT, input: query });
                }
            }}
        />
    );
}

type TDisposition =
    | {
          type: typeof CommonEnums.UserDispositionType.MERGE_TO_EXISTING;
          params: {
              toUserId: number;
          };
      }
    | {
          type: typeof CommonEnums.UserDispositionType.INVITE_VIA_EMAIL;
          params: {
              emailAddress: string;
          };
      };

type UserDispositionDialogProps = {
    board: UserDispositionBanner_boardFragment;
    isOpen: boolean;
    onClose: () => void;
};

function UserDispositionDialog({ board, isOpen, onClose }: UserDispositionDialogProps) {
    const submission = useAsyncWatcher();
    const [dispositionsByUserId, setDispositionsByUserId] = useState<
        Record<number, TDisposition | null>
    >({});
    const { disposeUser } = useDisposeUser();

    const usersPendingDisposition = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .filter(user => user.is_pending_disposition);
    const dispositions = Object.entries(dispositionsByUserId)
        .map(([userId, details]) => (details ? { userId: Number(userId), ...details } : null))
        .filter(isDefined)
        .filter(
            d =>
                !(
                    d.type === CommonEnums.UserDispositionType.INVITE_VIA_EMAIL &&
                    (!d.params.emailAddress || !isEmail(d.params.emailAddress))
                )
        );
    const invitationCount = dispositions.filter(
        d => d.type === CommonEnums.UserDispositionType.INVITE_VIA_EMAIL
    ).length;
    const mergeCount = dispositions.filter(
        d => d.type === CommonEnums.UserDispositionType.MERGE_TO_EXISTING
    ).length;

    const sourceInfo = ExternalTicketSources[board.import!.source]!;

    const getUserString = (count: number) => (count === 1 ? "user" : "users");

    useEffect(() => {
        if (isOpen) {
            setDispositionsByUserId({});
        }
    }, [isOpen]);

    const handleSubmit = async () => {
        try {
            await Promise.all(
                dispositions.map(d =>
                    disposeUser({
                        userId: d.userId,
                        dispositionType: d.type,
                        dispositionParams: d.params,
                    })
                )
            );

            AppToaster.success({
                message: "Success! It may take a few moments for the changes to appear.",
            });

            onClose();
        } catch (error) {
            Log.error("Failed to dispose users", { error });

            AppToaster.error({
                message: "Sorry, something went wrong.",
            });
        }
    };

    return (
        <Dialog
            title={`Import ${sourceInfo.displayName} users`}
            isOpen={isOpen}
            className={styles.dialog}
            onClose={onClose}
        >
            <Dialog.Body>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <img
                                    className={styles.sourceLogo}
                                    alt={sourceInfo.displayName}
                                    height="24"
                                    src={sourceInfo.logo}
                                />
                            </th>
                            <th />
                            <th>
                                <BrandLogomark height={24} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersPendingDisposition.map((user, i) => (
                            <>
                                <tr>
                                    <td className={styles.sourceUser}>
                                        <Avatar
                                            className={styles.sourceAvatar}
                                            user={user}
                                            size={32}
                                        />
                                        <span>{user.name}</span>
                                    </td>
                                    <td>
                                        {dispositionsByUserId[user.id] ? (
                                            <Icon
                                                icon="arrow-right"
                                                iconSet="lucide"
                                                iconSize={18}
                                            />
                                        ) : null}
                                    </td>
                                    <td>
                                        <UserSelector
                                            autoFocus={i === 0}
                                            board={board}
                                            onChange={change => {
                                                if (change.type === UserSelectorChangeType.USER) {
                                                    setDispositionsByUserId(prev => ({
                                                        ...prev,
                                                        [user.id]: {
                                                            type:
                                                                CommonEnums.UserDispositionType
                                                                    .MERGE_TO_EXISTING,
                                                            params: {
                                                                toUserId: change.userId,
                                                            },
                                                        },
                                                    }));
                                                } else if (
                                                    change.type === UserSelectorChangeType.INPUT
                                                ) {
                                                    setDispositionsByUserId(prev => ({
                                                        ...prev,
                                                        [user.id]: change.input
                                                            ? {
                                                                  type:
                                                                      CommonEnums
                                                                          .UserDispositionType
                                                                          .INVITE_VIA_EMAIL,
                                                                  params: {
                                                                      emailAddress: change.input,
                                                                  },
                                                              }
                                                            : null,
                                                    }));
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </Dialog.Body>
            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton
                        content={`Invite ${invitationCount} ${getUserString(
                            invitationCount
                        )}, connect ${mergeCount} ${getUserString(mergeCount)}`}
                        cta
                        instrumentation={{
                            elementName: "user_disposition_dialog.submit_btn",
                            eventData: { boardId: board.id, invitationCount, mergeCount },
                        }}
                        debounceIntervalMs={1000}
                        disabled={!dispositions.length}
                        loading={submission.isInFlight}
                        onClick={submission.watch(handleSubmit)}
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}

export type UserDispositionBannerProps = {
    board: FragmentType<typeof fragments.board>;
    className?: string;
};

export function UserDispositionBanner({
    board: _boardFragment,
    className,
}: UserDispositionBannerProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const dialog = useDialog();
    const currentUser = useCurrentUser();
    const { nomenclature } = useNomenclature();

    const usersPendingDisposition = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .filter(user => user.is_pending_disposition);

    if (currentUser.role !== CommonEnums.UserRole.USER_ORG_ADMIN) {
        return null;
    }

    if (!usersPendingDisposition.length) {
        return null;
    }

    if (!board.import) {
        return null;
    }

    const sourceInfo = ExternalTicketSources[board.import.source];

    if (!sourceInfo) {
        return null;
    }

    return (
        <>
            <Banner
                className={classNames(className, styles.banner)}
                contentClassName={styles.bannerContent}
                content={
                    <>
                        <span className={styles.bannerText}>
                            This {nomenclature.space.singular.toLowerCase()} was imported from{" "}
                            {sourceInfo.displayName} and references {usersPendingDisposition.length}{" "}
                            unrecognized {usersPendingDisposition.length === 1 ? "user" : "users"}.{" "}
                        </span>
                        {usersPendingDisposition.length <= 10 ? (
                            <span>
                                {usersPendingDisposition.map(user => (
                                    <span className={styles.avatar} key={user.id}>
                                        <Avatar size={26} showTooltip user={user} />
                                    </span>
                                ))}
                            </span>
                        ) : null}
                        <TextButton
                            link
                            instrumentation={{
                                elementName: "board.dispose_users_btn",
                                eventData: { boardId: board.id },
                            }}
                            text="Let's fix this"
                            type="button"
                            onClick={() => dialog.open()}
                        />
                    </>
                }
                warning
            />
            <UserDispositionDialog board={board} isOpen={dialog.isOpen} onClose={dialog.close} />
        </>
    );
}
