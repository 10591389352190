import React from "react";

import { CommonEnumValue, CommonEnums, TMergeRequestBuildStatusInfo } from "c9r-common";
import classNames from "classnames";

import { TimeAgo } from "components/ui/common/TimeAgo";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { BuildToolUrlDisplayText } from "lib/Constants";
import { Enums } from "lib/Enums";
import { Fragments } from "lib/Fragments";
import { useInstrumentation } from "lib/Instrumentation";
import { useRecordTicketView } from "lib/TicketViews";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

import styles from "./MergeRequest.module.scss";

const fragments = {
    mergeRequest: gql(/* GraphQL */ `
        fragment MergeRequest_merge_request on merge_requests {
            id

            lines_added
            lines_removed
            merged_at
            title
            url

            ...merge_request_full

            merged_by_user {
                id
                name
            }
        }
    `),
};

export type BuildStatusIconProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    className?: string;
    iconSize?: number;
};

/**
 * The icon shown next to a merge request title indicating if the build is passing, failed, etc.
 */
export function BuildStatusIcon({ buildStatus, className, iconSize }: BuildStatusIconProps) {
    if (!buildStatus) {
        return null;
    }

    const { buildStatusIcon, buildStatusIconSet, buildStatusClass } = ({
        [CommonEnums.MergeRequestBuildStatus.OK]: {
            buildStatusIcon: "buildPassing",
            buildStatusIconSet: "c9r",
            buildStatusClass: styles.passing,
        },
        [CommonEnums.MergeRequestBuildStatus.PENDING]: {
            buildStatusIcon: "buildPending",
            buildStatusIconSet: "c9r",
            buildStatusClass: styles.pending,
        },
        [CommonEnums.MergeRequestBuildStatus.FAILED]: {
            buildStatusIcon: "buildFailing",
            buildStatusIconSet: "c9r",
            buildStatusClass: styles.notPassing,
        },
    } as const)[buildStatus] || {
        buildStatusIcon: null,
        buildStatusIconSet: null,
        buildStatusClass: null,
    };

    if (!buildStatusIcon || !buildStatusIconSet) {
        return null;
    }

    return (
        <Icon
            className={classNames(className, styles.buildStatusIcon, buildStatusClass)}
            icon={buildStatusIcon}
            iconSet={buildStatusIconSet}
            iconSize={iconSize ?? 12}
            strokeWeight={2}
        />
    );
}

export type BuildToolLinkProps = {
    className?: string;
    buildToolStatusInfo: TMergeRequestBuildStatusInfo;
    elementName?: string;
    mergeRequestId: string;
};

/**
 * Link to a build tool for a merge request. Unstyled.
 */
export function BuildToolLink({
    className,
    buildToolStatusInfo,
    elementName,
    mergeRequestId,
}: BuildToolLinkProps) {
    const { recordCallback } = useInstrumentation();

    return (
        <a
            className={className}
            target="_blank"
            rel="noopener noreferrer"
            href={buildToolStatusInfo.url}
            onClick={recordCallback(
                {
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: elementName ?? "pr_build_tool_status_link",
                    eventData: { mergeRequestId, buildToolStatusInfo },
                },
                e => {
                    e.stopPropagation();
                    return false;
                }
            )}
        >
            {BuildToolUrlDisplayText[buildToolStatusInfo.urlType]}
            <Icon icon="chevron-right" iconSet="lucide" iconSize={14} strokeWeight={1.33} />
        </a>
    );
}

export type LineDiffProps = {
    className?: string;
    linesAdded: number;
    linesRemoved: number;
};

/**
 * A merge request line diff.
 */
export function LineDiff({ className, linesAdded, linesRemoved }: LineDiffProps) {
    return (
        <div className={classNames(className, styles.lineDiff)}>
            <span className={styles.linesAdded}>{linesAdded.toLocaleString()}</span>
            <span className={styles.linesRemoved}>{linesRemoved.toLocaleString()}</span>
        </div>
    );
}

export type MergeRequestIconProps = {
    className?: string;
    status: CommonEnumValue<"MergeRequestStatus">;
    iconSize?: number;
    strokeWeight?: number;
};

/**
 * The icon shown next to a merge request indicating if it's open, merged, etc.
 */
export function MergeRequestIcon({
    className,
    status,
    iconSize,
    strokeWeight,
}: MergeRequestIconProps) {
    return (
        <Icon
            className={classNames(className, styles.mrIcon, status)}
            icon={
                status === CommonEnums.MergeRequestStatus.OPEN ||
                status === CommonEnums.MergeRequestStatus.CLOSED
                    ? "codeMergeRequest"
                    : "codeMerged"
            }
            iconSet="c9r"
            iconSize={iconSize ?? 18}
            strokeWeight={strokeWeight ?? 1.25}
        />
    );
}

export type MergeRequestTitleProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    className?: string;
    elementName?: string;
    mergeRequestId: string;
    showBuildStatusIcon?: boolean;
    title: string;
    url: string;
};

export function MergeRequestTitle({
    buildStatus,
    className,
    elementName,
    mergeRequestId,
    showBuildStatusIcon,
    title,
    url,
}: MergeRequestTitleProps) {
    const { recordCallback } = useInstrumentation();
    const { recordTicketViewByMergeRequestId } = useRecordTicketView();

    return (
        <a
            className={classNames(className, styles.title)}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            onClick={recordCallback(
                {
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: elementName ?? "pr_link",
                    eventData: { mergeRequestId },
                },
                () => recordTicketViewByMergeRequestId({ mergeRequestId })
            )}
        >
            <span className={styles.titleText}>{title}</span>
            {showBuildStatusIcon ? (
                <BuildStatusIcon buildStatus={buildStatus} className={styles.titleStatusIcon} />
            ) : null}
        </a>
    );
}

export type MergeRequestSubtitleProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    className?: string;
    mergeRequestId: string;

    showBuildToolLinks?: boolean;
    buildToolsStatusInfo?: TMergeRequestBuildStatusInfo[];

    showLineDiff?: boolean;
    linesAdded?: number;
    linesRemoved?: number;
};

export function MergeRequestSubtitle({
    buildStatus,
    buildToolsStatusInfo,
    className,
    linesAdded,
    linesRemoved,
    mergeRequestId,
    showBuildToolLinks,
    showLineDiff,
}: MergeRequestSubtitleProps) {
    return (
        <div className={classNames(className, styles.subtitle)}>
            {showLineDiff && typeof linesAdded === "number" && typeof linesRemoved === "number" ? (
                <LineDiff
                    className={styles.lineDiff}
                    linesAdded={linesAdded}
                    linesRemoved={linesRemoved}
                />
            ) : null}
            {showBuildToolLinks && buildToolsStatusInfo
                ? buildToolsStatusInfo.map(buildToolStatusInfo => {
                      return (
                          <BuildToolItem
                              className={styles.subtitleBuildToolLink}
                              key={JSON.stringify(buildToolStatusInfo)}
                              buildToolStatusInfo={buildToolStatusInfo}
                              mergeRequestId={mergeRequestId}
                              buildStatus={buildStatus}
                          />
                      );
                  })
                : null}
        </div>
    );
}

export type MergeRequestStatusSummaryProps = {
    abbreviate?: boolean;
    className?: string;
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    hasChangesRequested?: boolean;
    hasMergeConflict?: boolean;
    lastActivityAt: string;
    reviewerNames: string[];
};

/**
 * Status text about a merge request. Unstyled.
 */
export function MergeRequestStatusSummary({
    abbreviate,
    className,
    buildStatus,
    hasChangesRequested,
    hasMergeConflict,
    lastActivityAt,
    reviewerNames,
}: MergeRequestStatusSummaryProps) {
    return (
        <span className={className}>
            <TimeAgo abbreviate={abbreviate} date={lastActivityAt} />
            {(() => {
                if (hasMergeConflict) {
                    return "; merge conflict";
                }

                if (reviewerNames.length) {
                    return (
                        <>
                            {"; "}
                            {reviewerNames.join(", ")}
                            {" to review"}
                        </>
                    );
                }

                if (hasChangesRequested) {
                    return "; changes requested";
                }

                if (!buildStatus) {
                    return null;
                }

                return (
                    {
                        [CommonEnums.MergeRequestBuildStatus.PENDING]: "; pending",
                        [CommonEnums.MergeRequestBuildStatus.OK]: "; ready to merge",
                        [CommonEnums.MergeRequestBuildStatus.FAILED]: "",
                    }[buildStatus] || null
                );
            })()}
        </span>
    );
}

/**
 * Helper hook to do common formatting logic for merge requests.
 */
export function useFormattedMergeRequest({
    mr: _mrFragment,
}: {
    mr: FragmentType<typeof Fragments.standard.merge_request.full>;
}) {
    const mr = getFragmentData(Fragments.standard.merge_request.full, _mrFragment);
    const currentUser = useCurrentUser();

    const status = mr.merged_at
        ? CommonEnums.MergeRequestStatus.MERGED
        : mr.closed_at
        ? CommonEnums.MergeRequestStatus.CLOSED
        : CommonEnums.MergeRequestStatus.OPEN;

    const buildStatus = mr.build_status;
    const buildToolsStatusInfo =
        mr.build_status_info?.map(({ normalized }) => normalized).filter(isDefined) ?? [];

    const ageInSecs = (Date.now() - new Date(mr.last_activity_at ?? mr.opened_at).getTime()) / 1000;
    const isDelayed = status === CommonEnums.MergeRequestStatus.OPEN && ageInSecs > 86400;

    const hasChangesRequested = !!mr.has_changes_requested;
    const hasMergeConflict = !!mr.has_merge_conflict;

    const lastActivityAt = mr.last_activity_at ?? mr.opened_at;

    const reviewerNames = (mr.pending_reviews as { name: string; identifier?: string }[]).map(
        ({ name, identifier }) => {
            if (identifier && mr.provider === CommonEnums.MergeRequestProvider.GITHUB) {
                const matchingUser = currentUser.org.users.find(u => u.github_login === identifier);

                if (matchingUser) {
                    return matchingUser.name;
                }
            }

            return name;
        }
    );

    const shouldShowBuildStatusIcon = status === CommonEnums.MergeRequestStatus.OPEN;
    const shouldShowBuildToolLinks =
        status === CommonEnums.MergeRequestStatus.OPEN &&
        buildStatus !== CommonEnums.MergeRequestBuildStatus.PENDING;
    const shouldShowLineDiff =
        status === CommonEnums.MergeRequestStatus.OPEN &&
        typeof mr.lines_added === "number" &&
        typeof mr.lines_removed === "number";

    return {
        buildStatus,
        buildToolsStatusInfo,
        isDelayed,
        hasChangesRequested,
        hasMergeConflict,
        lastActivityAt,
        reviewerNames,
        shouldShowBuildStatusIcon,
        shouldShowBuildToolLinks,
        shouldShowLineDiff,
        status,
    };
}

export const MergeRequestUtils = {
    shouldShowBuildToolLink({
        buildStatus,
        buildToolStatusInfo,
    }: {
        buildStatus: CommonEnumValue<"MergeRequestBuildStatus">;
        buildToolStatusInfo: TMergeRequestBuildStatusInfo;
    }) {
        const buildHasPassedWithPreview =
            buildStatus === CommonEnums.MergeRequestBuildStatus.OK &&
            buildToolStatusInfo.urlType ===
                CommonEnums.MergeRequestBuildStatusUrlType.NETLIFY_PREVIEW;
        const buildHasFailedWithLog =
            buildStatus === CommonEnums.MergeRequestBuildStatus.FAILED &&
            buildToolStatusInfo.urlType === CommonEnums.MergeRequestBuildStatusUrlType.NETLIFY_LOG;

        return buildHasPassedWithPreview || buildHasFailedWithLog;
    },
};

export type MergeRequestProps = {
    elementName?: string;
    mr: FragmentType<typeof fragments.mergeRequest>;
};

export function MergeRequest({ elementName, mr: _mrFragment }: MergeRequestProps) {
    const mr = getFragmentData(fragments.mergeRequest, _mrFragment);
    const {
        buildStatus,
        buildToolsStatusInfo,
        isDelayed,
        hasChangesRequested,
        hasMergeConflict,
        lastActivityAt,
        reviewerNames,
        shouldShowBuildStatusIcon,
        shouldShowBuildToolLinks,
        shouldShowLineDiff,
        status,
    } = useFormattedMergeRequest({ mr });

    return (
        <MergeRequestDisplay
            buildStatus={buildStatus}
            delayed={isDelayed}
            elementName={elementName}
            hasChangesRequested={hasChangesRequested}
            hasMergeConflict={hasMergeConflict}
            linesAdded={mr.lines_added ?? undefined}
            linesRemoved={mr.lines_removed ?? undefined}
            mergedAt={mr.merged_at ?? undefined}
            mergedByUserName={mr.merged_by_user?.name}
            mergeRequestId={mr.id}
            lastActivityAt={lastActivityAt}
            reviewerNames={reviewerNames}
            status={status}
            title={mr.title}
            url={mr.url}
            buildToolsStatusInfo={buildToolsStatusInfo}
            shouldShowBuildStatusIcon={shouldShowBuildStatusIcon}
            shouldShowBuildToolLinks={shouldShowBuildToolLinks}
            shouldShowLineDiff={shouldShowLineDiff}
        />
    );
}

type BuildToolItemProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    buildToolStatusInfo: TMergeRequestBuildStatusInfo;
    className?: string;
    mergeRequestId: string;
};

function BuildToolItem({
    buildStatus,
    buildToolStatusInfo,
    className,
    mergeRequestId,
}: BuildToolItemProps) {
    if (!buildStatus) {
        return null;
    }

    return MergeRequestUtils.shouldShowBuildToolLink({ buildStatus, buildToolStatusInfo }) ? (
        <BuildToolLink
            className={className}
            buildToolStatusInfo={buildToolStatusInfo}
            elementName="card.pr_build_tool_status_link"
            mergeRequestId={mergeRequestId}
        />
    ) : null;
}

type MergeRequestDisplayProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    delayed?: boolean;
    elementName?: string;
    hasChangesRequested?: boolean;
    hasMergeConflict?: boolean;
    mergedAt?: string;
    mergedByUserName?: string;
    mergeRequestId: string;
    lastActivityAt: string;
    reviewerNames: string[];
    status: CommonEnumValue<"MergeRequestStatus">;
    title: string;
    url: string;
    shouldShowBuildStatusIcon?: boolean;

    shouldShowBuildToolLinks?: boolean;
    buildToolsStatusInfo?: TMergeRequestBuildStatusInfo[];

    shouldShowLineDiff?: boolean;
    linesAdded?: number;
    linesRemoved?: number;
};

function MergeRequestDisplay({
    buildStatus,
    delayed,
    elementName,
    hasChangesRequested,
    hasMergeConflict,
    linesAdded,
    linesRemoved,
    mergedAt,
    mergedByUserName,
    mergeRequestId,
    lastActivityAt,
    reviewerNames = [],
    status,
    title,
    url,
    buildToolsStatusInfo = [],
    shouldShowBuildStatusIcon,
    shouldShowBuildToolLinks,
    shouldShowLineDiff,
}: MergeRequestDisplayProps) {
    return (
        <div className={classNames(styles.mergeRequest, styles[status], delayed && styles.delayed)}>
            <MergeRequestIcon className={styles.mergeRequestIcon} status={status} />
            <div className={styles.mergeRequestInfo}>
                <div className={styles.mergeRequestStaticInfo}>
                    <MergeRequestTitle
                        buildStatus={buildStatus}
                        className={styles.mergeRequestTitle}
                        elementName={elementName}
                        mergeRequestId={mergeRequestId}
                        showBuildStatusIcon={shouldShowBuildStatusIcon}
                        title={title}
                        url={url}
                    />
                    <MergeRequestSubtitle
                        buildStatus={buildStatus}
                        buildToolsStatusInfo={buildToolsStatusInfo}
                        className={styles.mergeRequestSubtitle}
                        linesAdded={linesAdded}
                        linesRemoved={linesRemoved}
                        mergeRequestId={mergeRequestId}
                        showBuildToolLinks={shouldShowBuildToolLinks}
                        showLineDiff={shouldShowLineDiff}
                    />
                </div>
                <div className={styles.mergeRequestDynamicInfo}>
                    {status === CommonEnums.MergeRequestStatus.OPEN ? (
                        <MergeRequestStatusSummary
                            className={styles.mergeRequestStatusSummary}
                            buildStatus={buildStatus}
                            hasChangesRequested={hasChangesRequested}
                            hasMergeConflict={hasMergeConflict}
                            lastActivityAt={lastActivityAt}
                            reviewerNames={reviewerNames}
                        />
                    ) : null}
                    {status === CommonEnums.MergeRequestStatus.MERGED ? (
                        <span className={styles.detailedStatus}>
                            merged <TimeAgo date={mergedAt!} />
                            {mergedByUserName ? ` by ${mergedByUserName}` : null}
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

MergeRequest.Display = MergeRequestDisplay;
