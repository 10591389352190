import React from "react";

import classNames from "classnames";

import styles from "./Menu.module.scss";

export type MenuProps = {
    children: React.ReactNode;
    ulRef?: React.Ref<HTMLUListElement>;
} & React.ComponentPropsWithoutRef<"ul">;

export function Menu({ children, ulRef, ...htmlUlProps }: MenuProps) {
    return (
        <ul
            ref={ulRef}
            role="menu"
            {...htmlUlProps}
            className={classNames(htmlUlProps.className, styles.menu)}
        >
            {children}
        </ul>
    );
}
