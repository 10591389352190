import React, { useState } from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";

import { useLiveViewQuery } from "components/loading/Loading";
import { Avatar } from "components/ui/common/Avatar";
import { ConfirmationModal } from "components/ui/common/ConfirmationModal";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { PunctuatedList } from "components/ui/common/PunctuatedList";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Toggle } from "components/ui/core/Toggle";
import { useCurrentUser } from "contexts/UserContext";
import { useInvitePeopleDialog } from "dialogs/InvitePeopleDialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { formatUserEmailAddress } from "lib/Helpers";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { TeamSettings_orgFragment } from "lib/graphql/__generated__/graphql";
import {
    useChangeOrgDomainSignupEnabled,
    useChangeUserEnabled,
    useChangeUserRole,
    useReinviteUser,
} from "lib/mutations";
import { isDefined } from "lib/types/guards";
import { NotFoundView } from "views/error/NotFoundView";

import styles from "./TeamSettings.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment TeamSettings_org on orgs {
            id
            display_name
            is_domain_signup_enabled

            domains {
                id
                domain
            }

            users {
                id
                disabled_at
                full_name
                is_pending_disposition
                name
                last_logged_in_at
                role
                pending_invite_sent_at

                identity {
                    id
                    email_address
                }

                ...Avatar_user
            }
        }
    `),
};

type TOrg = TeamSettings_orgFragment;
type TUser = TOrg["users"][number];

type TeamSettingsContentProps = {
    org: TOrg;
};

export function TeamSettingsContent({ org }: TeamSettingsContentProps) {
    const currentUser = useCurrentUser();
    const { gateFeature } = useFeatureFlags();
    const invitePeopleDialog = useInvitePeopleDialog();
    const [changeUserEnabledState, setChangeUserEnabledState] = useState<{
        isModalOpen: boolean;
        user: TUser | null;
    }>({
        isModalOpen: false,
        user: null,
    });

    const { changeUserEnabled } = useChangeUserEnabled();
    const { changeUserRole } = useChangeUserRole();
    const { reinviteUser } = useReinviteUser();
    const { changeOrgDomainSignupEnabled } = useChangeOrgDomainSignupEnabled();

    const roleToDisplayText = {
        [CommonEnums.UserRole.USER]: "Member",
        [CommonEnums.UserRole.USER_ORG_ADMIN]: "Admin",
        [CommonEnums.UserRole.USER_ORG_GUEST]: "Guest",
    } as const;
    const allowGuests = !!currentUser.org.is_multi_board;
    const isDomainSignupEnabled = org.is_domain_signup_enabled;

    const getUserStatus = (user: TUser) => {
        if (user.disabled_at) {
            return "Deactivated";
        }

        return !user.last_logged_in_at ? "Pending" : "Active";
    };

    const calcPendingInviteDays = (user: TUser) => {
        if (!user.pending_invite_sent_at) {
            return null;
        }

        return Math.floor(
            (Date.now() - new Date(user.pending_invite_sent_at).getTime()) / 86400000
        );
    };

    const userRows = org.users
        .filter(user => !user.is_pending_disposition)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map(user => ({
            user,
            status: getUserStatus(user),
            pendingInviteDays: calcPendingInviteDays(user),
        }));

    const handleReinviteUser = async ({ user }: { user: TUser }) => {
        if (!gateFeature({ feature: Enums.Feature.MANAGE_USERS })) {
            return;
        }

        try {
            const result = await reinviteUser({ userId: user.id });

            if (result.data?.reinvite_user.ok) {
                AppToaster.success({
                    message: "Invitation sent!",
                });
            } else {
                const errorCode = (result.data?.reinvite_user.error as any).code;

                switch (errorCode) {
                    default:
                        throw new Error(errorCode);
                }
            }
        } catch (error) {
            Log.error("Failed to reinvite user", { error });
            AppToaster.error({
                message: "Sorry, something went wrong sending the invitation.",
            });
        }
    };

    const handleChangeUserEnabled = async ({
        user,
        newIsEnabled,
    }: {
        user: TUser;
        newIsEnabled: boolean;
    }) => {
        if (!gateFeature({ feature: Enums.Feature.MANAGE_USERS })) {
            return;
        }

        await changeUserEnabled({
            userId: user.id,
            isEnabled: newIsEnabled,
        });
    };

    const handleChangeDomainSignup = async (isEnabled: boolean) => {
        try {
            await changeOrgDomainSignupEnabled({ isEnabled, orgId: currentUser.org_id });
        } catch (error) {
            Log.error("Failed to save domain signup preference", { error });
            AppToaster.error({
                message: "Sorry, something went wrong saving your changes.",
            });
        }
    };
    const handleChangeUserRole = async ({
        user,
        newRole,
    }: {
        user: TUser;
        newRole: CommonEnumValue<"UserRole">;
    }) => {
        if (!gateFeature({ feature: Enums.Feature.MANAGE_USERS })) {
            return;
        }

        await changeUserRole({
            userId: user.id,
            role: newRole,
        });
    };

    const tableSections = [
        {
            className: styles.usersTableAdmins,
            title: "Admins",
            userRows: userRows.filter(
                r =>
                    r.user.role === CommonEnums.UserRole.USER_ORG_ADMIN &&
                    r.status !== "Deactivated"
            ),
        },
        {
            className: styles.usersTableMembers,
            title: "Members",
            userRows: userRows.filter(
                r => r.user.role === CommonEnums.UserRole.USER && r.status !== "Deactivated"
            ),
        },
        {
            className: styles.usersTableGuests,
            title: "Guests",
            userRows: userRows.filter(
                r =>
                    r.user.role === CommonEnums.UserRole.USER_ORG_GUEST &&
                    r.status !== "Deactivated"
            ),
        },
        {
            className: styles.usersTableDeactivated,
            title: "Deactivated",
            userRows: userRows.filter(r => r.status === "Deactivated"),
        },
    ].filter(Boolean);

    return (
        <div className={styles.teamSettings}>
            <div className={styles.header}>
                <h1 className={styles.title}>People</h1>
                <BorderButton
                    className={styles.inviteTeammatesButton}
                    contentClassName={styles.inviteTeammatesButtonContent}
                    content="Invite teammates"
                    brandCta
                    onClick={() => invitePeopleDialog.open()}
                    instrumentation={null}
                />
            </div>

            {!!org.domains.length && (
                <div className={styles.domainSignupSection}>
                    <Toggle
                        checked={isDomainSignupEnabled}
                        label={
                            <h4 className={styles.domainSignupToggleLabel}>
                                Enable self-signup for{" "}
                                <PunctuatedList joinText="and">
                                    {org.domains.map(({ domain }) => (
                                        <span key={domain}>@{domain}</span>
                                    ))}
                                </PunctuatedList>
                            </h4>
                        }
                        inline
                        large
                        instrumentation={{
                            elementName: "settings.domain_signup_btn",
                            eventData: { checked: !isDomainSignupEnabled },
                        }}
                        onChange={e => {
                            void handleChangeDomainSignup(e.target.checked);
                        }}
                    />
                    <p>
                        Lets anyone with an{" "}
                        <PunctuatedList joinText="or">
                            {org.domains.map(({ domain }) => (
                                <span key={domain} className={styles.orgDomain}>
                                    @{domain}
                                </span>
                            ))}
                        </PunctuatedList>{" "}
                        email address join this organization without an invitation.
                    </p>
                </div>
            )}
            <div className={styles.usersTableWrapper}>
                <table className={styles.usersTable}>
                    {tableSections.map(section =>
                        section.userRows.length ? (
                            <tbody key={section.title} className={section.className}>
                                <tr className={styles.meta}>
                                    <td className={styles.name} />
                                    <td className={styles.fullName} />
                                    <td className={styles.email} />
                                    <td className={styles.role} />
                                    <td className={styles.menu} />
                                </tr>
                                <tr className={styles.usersTableSectionTitleRow}>
                                    <td colSpan={5}>{section.title}</td>
                                </tr>
                                {section.userRows.map(row => (
                                    <tr key={row.user.id} className={styles.userRow}>
                                        <td className={styles.userUsername}>
                                            <Avatar
                                                className={styles.avatar}
                                                user={row.user}
                                                size={40}
                                            />
                                            {row.user.name}
                                        </td>
                                        <td className={styles.userFullName}>
                                            {row.user.full_name || "-"}
                                        </td>
                                        <td className={styles.userEmailAddress}>
                                            {formatUserEmailAddress({
                                                emailAddress:
                                                    row.user.identity?.email_address ?? "",
                                            })}
                                            {row.pendingInviteDays ? (
                                                <span className={styles.pendingInviteTimeAgo}>
                                                    {`(pending ${row.pendingInviteDays} ${
                                                        row.pendingInviteDays > 1 ? "days" : "day"
                                                    })`}
                                                </span>
                                            ) : null}
                                        </td>
                                        <td>
                                            {row.user.id === currentUser.id ? (
                                                <div className={styles.userRoleMenuPlaceholder}>
                                                    {roleToDisplayText[row.user.role]}
                                                </div>
                                            ) : row.status === "Deactivated" ? (
                                                <DropdownButton
                                                    contentClassName={styles.tableText}
                                                    disabled={!!row.user.disabled_at}
                                                    minimal
                                                    text={roleToDisplayText[row.user.role]}
                                                    instrumentation={null}
                                                />
                                            ) : (
                                                <MenuPopover
                                                    content={
                                                        <Menu>
                                                            {[
                                                                CommonEnums.UserRole.USER,
                                                                CommonEnums.UserRole.USER_ORG_ADMIN,
                                                                allowGuests ||
                                                                row.user.role ===
                                                                    CommonEnums.UserRole
                                                                        .USER_ORG_GUEST
                                                                    ? CommonEnums.UserRole
                                                                          .USER_ORG_GUEST
                                                                    : null,
                                                            ]
                                                                .filter(isDefined)
                                                                .map(role => (
                                                                    <MenuItem
                                                                        key={role}
                                                                        className={styles.tableText}
                                                                        text={
                                                                            roleToDisplayText[role]
                                                                        }
                                                                        onClick={() =>
                                                                            handleChangeUserRole({
                                                                                user: row.user,
                                                                                newRole: role,
                                                                            })
                                                                        }
                                                                        instrumentation={null}
                                                                    />
                                                                ))}
                                                        </Menu>
                                                    }
                                                    minimal
                                                    placement="bottom-start"
                                                >
                                                    <DropdownButton
                                                        contentClassName={styles.tableText}
                                                        minimal
                                                        text={roleToDisplayText[row.user.role]}
                                                        instrumentation={null}
                                                    />
                                                </MenuPopover>
                                            )}
                                        </td>
                                        <td className={styles.userMenu}>
                                            {row.user.id === currentUser.id ? null : (
                                                <MenuPopover
                                                    content={
                                                        <Menu>
                                                            {row.status === "Pending" ? (
                                                                <MenuItem
                                                                    text={
                                                                        row.user
                                                                            .pending_invite_sent_at
                                                                            ? "Resend invitation"
                                                                            : "Send invitation"
                                                                    }
                                                                    onClick={() =>
                                                                        handleReinviteUser({
                                                                            user: row.user,
                                                                        })
                                                                    }
                                                                    instrumentation={null}
                                                                />
                                                            ) : null}
                                                            <MenuItem
                                                                text={
                                                                    row.user.disabled_at
                                                                        ? "Reactivate"
                                                                        : "Deactivate"
                                                                }
                                                                onClick={() =>
                                                                    setChangeUserEnabledState({
                                                                        isModalOpen: true,
                                                                        user: row.user,
                                                                    })
                                                                }
                                                                instrumentation={null}
                                                            />
                                                        </Menu>
                                                    }
                                                    minimal
                                                    placement="bottom-start"
                                                    modifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            options: {
                                                                offset: [8, -4],
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <EllipsisButton
                                                        vertical
                                                        instrumentation={null}
                                                    />
                                                </MenuPopover>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : null
                    )}
                </table>
            </div>
            <ConfirmationModal
                isOpen={changeUserEnabledState.isModalOpen}
                body={
                    changeUserEnabledState.user?.disabled_at
                        ? `${changeUserEnabledState.user?.name} will be able to access your Flat organization.`
                        : `${changeUserEnabledState.user?.name} will no longer be able to access Flat. Their past comments, topics, and activity will still be visible.`
                }
                title={`${changeUserEnabledState.user?.disabled_at ? "Reactivate" : "Deactivate"} ${
                    changeUserEnabledState.user?.name
                }`}
                submitButtonText={
                    changeUserEnabledState.user?.disabled_at ? "Reactivate" : "Deactivate"
                }
                onClose={() => setChangeUserEnabledState({ isModalOpen: false, user: null })}
                onSubmit={() => {
                    if (changeUserEnabledState.user) {
                        void handleChangeUserEnabled({
                            user: changeUserEnabledState.user,
                            newIsEnabled: !!changeUserEnabledState.user?.disabled_at,
                        });
                        setChangeUserEnabledState({ isModalOpen: false, user: null });
                    }
                }}
            />
        </div>
    );
}

export function TeamSettings() {
    const currentUser = useCurrentUser();

    const componentQuery = useLiveViewQuery({
        query: TeamSettings.queries.component,
        variables: {
            orgId: currentUser.org_id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (currentUser.role !== CommonEnums.UserRole.USER_ORG_ADMIN) {
        return <NotFoundView />;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const org = getFragmentData(fragments.org, componentQuery.data?.org);

    if (!org) {
        return null;
    }

    return <TeamSettingsContent org={org} />;
}

TeamSettings.queries = {
    component: gql(/* GraphQL */ `
        query TeamSettings($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                ...TeamSettings_org
            }
        }
    `),
};

Queries.register({ component: "TeamSettings", gqlMapByName: TeamSettings.queries });
