import { useEffect } from "react";

import { AppToaster } from "components/ui/core/AppToaster";
import { Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { OAuth } from "lib/OAuth";
import { useHistory } from "lib/Routing";
import { useDefaultRedirectUrl } from "lib/Urls";
import { useTrelloConnection } from "lib/import/trello/TrelloConnection";

export function TrelloAuthRedirect() {
    const { history } = useHistory();
    const { saveTrelloToken } = useTrelloConnection();
    const defaultRedirectUrl = useDefaultRedirectUrl();

    const urlSearchParams = new URLSearchParams(history.location.search);
    const state = urlSearchParams.get("state");

    const fragmentUrlSearchParams = new URLSearchParams(history.location.hash.slice(1));
    const token = fragmentUrlSearchParams.get("token");
    const error = fragmentUrlSearchParams.get("error");

    const toastError = (msg?: string) => {
        AppToaster.error({
            message: `Something went wrong connecting to Trello${
                msg ? ` (${msg})` : ""
            }. Please try again.`,
        });
    };

    useEffect(() => {
        (async () => {
            if (error) {
                Log.error("Trello setup failed", { reason: error });
                toastError(error);
                history.replace(defaultRedirectUrl.pathname);
                return;
            }

            if (!token) {
                Log.error("Trello setup failed", { reason: "Missing token" });
                toastError();
                history.replace(defaultRedirectUrl.pathname);
                return;
            }

            const decodedState = OAuth.validateAndDecodeStateWithKey({
                key: Enums.OAuthKey.TRELLO,
                state,
            });

            if (!decodedState) {
                Log.error("Trello setup failed", { reason: "State mismatch" });
                toastError();
                history.replace(defaultRedirectUrl.pathname);
                return;
            }

            saveTrelloToken(token);

            history.replace(decodedState.path);
        })();
    }, [error, defaultRedirectUrl.pathname, saveTrelloToken, state, token, history]);

    return null;
}
