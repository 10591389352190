import { useCallback } from "react";

import { useCurrentUser } from "contexts/UserContext";
import { useReplicache } from "lib/replicache/Context";

/**
 * Make the current user watch a ticket. Does nothing if they're already watching it.
 */
export function useStartWatchingTicket() {
    const currentUser = useCurrentUser();
    const { replicache } = useReplicache();

    const startWatchingTicket = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            await replicache.mutate.updateTicketWatchers({
                ticketId,
                userIdsToAdd: [currentUser.id],
            });
        },
        [currentUser.id, replicache]
    );

    return { startWatchingTicket };
}

/**
 * Make the current user no longer watch a ticket. Does nothing if they're already not watching it.
 */
export function useStopWatchingTicket() {
    const currentUser = useCurrentUser();
    const { replicache } = useReplicache();

    const stopWatchingTicket = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            await replicache.mutate.updateTicketWatchers({
                ticketId,
                userIdsToRemove: [currentUser.id],
            });
        },
        [currentUser.id, replicache]
    );

    return { stopWatchingTicket };
}
