import React from "react";

import classNames from "classnames";

import styles from "./ContentBox.module.scss";

export type ContentBoxProps = {
    className?: string;
    children: React.ReactNode;
    tight?: boolean;
} & React.ComponentPropsWithoutRef<"div">;

/**
 * Central content box in the middle of the details page.
 */
export function ContentBox({ className, children, tight, ...htmlDivProps }: ContentBoxProps) {
    return (
        <div
            className={classNames(className, styles.contentBox, tight && styles.tight)}
            {...htmlDivProps}
        >
            {children}
        </div>
    );
}
