import { createContext, useContext } from "react";

// See https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
export function createCtx<A extends {} | null>() {
    const ctx = createContext<A | undefined>(undefined);

    function useCtx() {
        const value = useContext(ctx);

        if (value === undefined) {
            throw new Error("useCtx must be inside a Provider with a value");
        }

        return value;
    }
    return [useCtx, ctx.Provider] as const;
}
