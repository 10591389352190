import { useCallback, useMemo } from "react";

import { useMaybeCurrentOrg, useMaybeCurrentUser } from "AppState";
import { Config, FeatureConfig } from "Config";
import { AppToaster } from "components/ui/core/AppToaster";
import { EnumValue, Enums } from "lib/Enums";

function useFeatureMethods({
    getFeatureData,
}: {
    getFeatureData: ({ feature }: { feature: EnumValue<"Feature"> }) => FeatureConfig;
}) {
    const isFeatureEnabled = useCallback(
        ({ feature }: { feature: EnumValue<"Feature"> }) => !!getFeatureData({ feature }).enabled,
        [getFeatureData]
    );

    const getFeatureDisabledMessage = useCallback(
        ({ feature }: { feature: EnumValue<"Feature"> }) =>
            getFeatureData({ feature }).message || Config.features.config.defaultMessage,
        [getFeatureData]
    );

    const getFeatureValue = useCallback(
        ({ feature }: { feature: EnumValue<"Feature"> }) =>
            getFeatureData({ feature }).value ?? null,
        [getFeatureData]
    );

    const gateFeature = useCallback(
        ({ feature, showToast = true }: { feature: EnumValue<"Feature">; showToast?: boolean }) => {
            const isEnabled = isFeatureEnabled({ feature });

            if (!isEnabled && showToast) {
                AppToaster.info({
                    message: getFeatureDisabledMessage({ feature }),
                });
            }

            return isEnabled;
        },
        [getFeatureDisabledMessage, isFeatureEnabled]
    );

    const featureFlags = useMemo(
        () => ({
            isFeatureEnabled,
            getFeatureDisabledMessage,
            getFeatureValue,
            gateFeature,
        }),
        [isFeatureEnabled, getFeatureDisabledMessage, getFeatureValue, gateFeature]
    );

    return featureFlags;
}

/**
 * Feature flag methods including org and user overrides. Safe to use only in the parts of the app
 * with a specific user.
 */
export function useFeatureFlags() {
    const currentOrg = useMaybeCurrentOrg();
    const currentUser = useMaybeCurrentUser();

    const getFeatureData = useCallback(
        ({ feature }: { feature: EnumValue<"Feature"> }) => {
            const orgAppConfig = currentOrg?.app_config;
            const userAppConfig = currentUser?.app_config;

            if (!Enums.Feature[feature]) {
                throw new Error(`Unrecognized feature "${feature}"`);
            }

            if (userAppConfig?.features?.[feature]) {
                return (userAppConfig.features[feature] as unknown) as FeatureConfig;
            }

            if (orgAppConfig?.features?.[feature]) {
                return (orgAppConfig.features[feature] as unknown) as FeatureConfig;
            }

            return Config.features.byFeature[feature];
        },
        [currentOrg, currentUser]
    );

    return useFeatureMethods({ getFeatureData });
}
