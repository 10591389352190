import { useEffect } from "react";

import { CommonEnums } from "c9r-common";

import { useHistory } from "lib/Routing";
import { Storage } from "lib/Storage";

export function SignupRedirect() {
    const { history } = useHistory();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const planKey =
            {
                free: CommonEnums.SubscriptionPlanKey.FREE_PLAN,
                standard: CommonEnums.SubscriptionPlanKey.STANDARD_PLAN,
            }[urlSearchParams.get("plan") ?? "null"] ?? null;
        const billingInterval =
            {
                month: CommonEnums.SubscriptionBillingInterval.MONTH,
                year: CommonEnums.SubscriptionBillingInterval.YEAR,
            }[urlSearchParams.get("interval") ?? "null"] ?? null;

        if (planKey || billingInterval) {
            Storage.Local.setItem("signup.preferences", {
                planKey,
                billingInterval,
                asOf: Date.now(),
            });
        }

        history.push("/");
    }, [history]);

    return null;
}
