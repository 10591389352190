import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import { Popover2, Popover2InteractionKind, Popover2Props } from "@blueprintjs/popover2";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { isDemoIntroModalDisplayedState, isHotspotSettingEnabledState } from "AppState";
import { Config } from "Config";
import { useCurrentMinimalUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { EnumValue, Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { gql } from "lib/graphql/__generated__";

import styles from "./Hotspot.module.scss";

type BeaconPointDirection = "up" | "left" | "right" | "down";

export type HotspotContextState = Record<
    EnumValue<"HotspotKey">,
    {
        numAcks: number;
        didAckThisSession: boolean;
        isPendingAck: boolean;
    }
>;

// This can be a plain object because our usage is not reactive.
const SessionVisibilityState = {} as Record<EnumValue<"HotspotKey">, boolean>;

export const HotspotContext = createContext<
    | {
          state: HotspotContextState;
          recordSessionAck: ({ hotspotKey }: { hotspotKey: EnumValue<"HotspotKey"> }) => void;
      }
    | undefined
>(undefined);

export function HotspotProvider({ children }: { children: React.ReactNode }) {
    const currentUser = useCurrentMinimalUser();
    const [state, setState] = useState<HotspotContextState>(
        Object.fromEntries(
            Object.values(Enums.HotspotKey).map(hotspotKey => {
                const numAcks =
                    (currentUser.hotspot_interactions &&
                        currentUser.hotspot_interactions.find(hi => hi.hotspot_key === hotspotKey)
                            ?.acks) ||
                    0;

                return [
                    hotspotKey,
                    {
                        numAcks,
                        didAckThisSession: false,
                        isPendingAck: Config.hotspots.byHotspotKey[hotspotKey].enabled,
                    },
                ];
            })
        ) as HotspotContextState
    );

    useEffect(() => {
        setState(prev => {
            if (!currentUser.hotspot_interactions) {
                return { ...prev };
            }

            const newState = { ...prev };

            for (const hi of currentUser.hotspot_interactions) {
                const hotspotKey = hi.hotspot_key as EnumValue<"HotspotKey">;

                if (!newState[hotspotKey]) {
                    continue;
                }

                newState[hotspotKey].numAcks = hi.acks;
                newState[hotspotKey].isPendingAck =
                    Config.hotspots.byHotspotKey[hotspotKey].enabled &&
                    !newState[hotspotKey].didAckThisSession;
            }

            return newState;
        });
    }, [currentUser.hotspot_interactions]);

    const recordSessionAck = useCallback(
        ({ hotspotKey }: { hotspotKey: EnumValue<"HotspotKey"> }) => {
            setState(prev => ({
                ...prev,
                [hotspotKey]: {
                    ...prev[hotspotKey],
                    didAckThisSession: true,
                    isPendingAck: false,
                },
            }));
        },
        []
    );

    const value = useMemo(() => ({ recordSessionAck, state }), [recordSessionAck, state]);

    return <HotspotContext.Provider value={value}>{children}</HotspotContext.Provider>;
}

function useRecordHotspotView({ hotspotKey }: { hotspotKey: EnumValue<"HotspotKey"> }) {
    const currentUser = useCurrentMinimalUser();
    const { recordEvent } = useInstrumentation();
    const [recordView] = useMutation(
        gql(/* GraphQL */ `
            mutation HotspotRecordView($hotspotKey: String!, $userId: Int!) {
                insert_hotspot_interactions_one(
                    object: { hotspot_key: $hotspotKey, views: 0, last_viewed_at: "now()" }
                    on_conflict: {
                        constraint: hotspot_interactions_user_id_hotspot_key_key
                        update_columns: [last_viewed_at]
                    }
                ) {
                    id
                }

                update_hotspot_interactions(
                    where: { user_id: { _eq: $userId }, hotspot_key: { _eq: $hotspotKey } }
                    _set: { last_viewed_at: "now()" }
                    _inc: { views: 1 }
                ) {
                    returning {
                        id
                        views
                        last_viewed_at
                    }
                }
            }
        `)
    );

    const recordHotspotView = useCallback(() => {
        if (!SessionVisibilityState[hotspotKey]) {
            return;
        }

        void recordView({
            variables: { userId: currentUser.id, hotspotKey },
        });
        void recordEvent({
            eventType: Enums.InstrumentationEvent.HOTSPOT_VIEW,
            eventData: { hotspotKey },
        });
    }, [currentUser.id, hotspotKey, recordEvent, recordView]);

    return { recordHotspotView };
}

export function useRecordHotspotAck({ hotspotKey }: { hotspotKey: EnumValue<"HotspotKey"> }) {
    const currentUser = useCurrentMinimalUser();
    const hotspotContext = useContext(HotspotContext);
    const { recordEvent } = useInstrumentation();
    const [recordAck] = useMutation(
        gql(/* GraphQL */ `
            mutation HotspotRecordAck($hotspotKey: String!, $userId: Int!) {
                insert_hotspot_interactions_one(
                    object: { hotspot_key: $hotspotKey, acks: 0, last_acked_at: "now()" }
                    on_conflict: {
                        constraint: hotspot_interactions_user_id_hotspot_key_key
                        update_columns: [last_acked_at]
                    }
                ) {
                    id
                }

                update_hotspot_interactions(
                    where: { user_id: { _eq: $userId }, hotspot_key: { _eq: $hotspotKey } }
                    _set: { last_acked_at: "now()" }
                    _inc: { acks: 1 }
                ) {
                    returning {
                        id
                        acks
                        last_acked_at
                    }
                }
            }
        `)
    );

    const recordHotspotAck = useCallback(() => {
        if (!SessionVisibilityState[hotspotKey]) {
            return;
        }

        void recordAck({
            variables: { userId: currentUser.id, hotspotKey },
        });
        void recordEvent({
            eventType: Enums.InstrumentationEvent.HOTSPOT_ACK,
            eventData: { hotspotKey },
        });

        hotspotContext?.recordSessionAck({ hotspotKey });
    }, [currentUser.id, hotspotContext, hotspotKey, recordAck, recordEvent]);

    return { recordHotspotAck };
}

export type HotspotProps = {
    /**
     * Hotspots can be configureed to display only if this optional value is in the list
     * of configured values. Useful for showing a hotspot only on a particular instance
     * of a target rather than all instances.
     */
    allowListValue?: string;

    /**
     * Which direction the beacon should point. Normally this is inferred from "placement", but can
     * be provided if the inferred direction is not correct.
     */
    beaconPointDirection?: BeaconPointDirection;

    /** The target of the Hotspot. */
    children: React.ReactNode;

    /** Class name(s) to apply on the child element. See Popover2 docs. */
    className?: string;

    /** Offset for positioning the content popup relative to the beacon. See Popover2 docs. */
    contentOffset?: [number, number];

    /** Where the content of the popup should be displayed relative to the beacon. See Popover2 docs. */
    contentPlacement?: Popover2Props["placement"];

    /** Whether the hotspot should be disabled (not shown). */
    disabled?: boolean;

    /** Whether the popover wrapper element should fill its parents. See Popover2 docs. */
    fill?: boolean;

    /** A key from Enums.HotspotKey. */
    hotspotKey: EnumValue<"HotspotKey">;

    /** Offset for positioning the beacon. See Popover2 docs. */
    offset?: [number, number];

    /** Where the beacon should be displayed relative to target. See Popover2 docs. */
    placement?: Popover2Props["placement"];

    /**
     * Function to determine if the hotspot should display. Useful if the logic for display can't
     * be captured by a simple allowList.
     */
    predicate?: () => boolean;

    /** See Popover2 docs. */
    targetTagName?: Popover2Props["targetTagName"];

    /**
     * Whether the beacon should be displayed using a portal. Typically,
     * beacons should be displayed _without_ a portal so that it positions correctly even if the target
     * scrolls, is dragged, etc.
     */
    usePortal?: boolean;
};

/**
 * A "hotspot" is a clickable "beacon" that, when clicked, shows a popup with some arbitrary
 * content. Useful for highlighting product features and explaining what they do.
 *
 * The implementation uses a blueprint Popover2 to place the beacon. Therefore, some of the props
 * are passed directly through to the underlying Popover.
 */
export function Hotspot({
    allowListValue,
    beaconPointDirection: _beaconPointDirection,
    children,
    className,
    contentOffset,
    contentPlacement,
    disabled: _disabled,
    fill,
    hotspotKey,
    offset,
    placement = "right",
    predicate = () => true,
    targetTagName,
    usePortal,
}: HotspotProps) {
    const hotspotContext = useContext(HotspotContext);
    const isHotspotSettingEnabled = useRecoilValue(isHotspotSettingEnabledState);
    const isDemoIntroModalDisplayed = useRecoilValue(isDemoIntroModalDisplayedState);
    const [shouldRender, setShouldRender] = useState(isHotspotSettingEnabled);
    const { recordHotspotView } = useRecordHotspotView({ hotspotKey });
    const { recordHotspotAck } = useRecordHotspotAck({ hotspotKey });

    const hotspotConfig = Config.hotspots.byHotspotKey[hotspotKey];
    const disabled =
        !isHotspotSettingEnabled ||
        _disabled ||
        !shouldRender ||
        isDemoIntroModalDisplayed ||
        !hotspotConfig ||
        (hotspotConfig.allowList &&
            allowListValue &&
            !hotspotConfig.allowList.includes(allowListValue)) ||
        (Config.hotspots.config.usePrerequisites &&
            hotspotConfig.prerequisites &&
            hotspotConfig.prerequisites.some(key => hotspotContext?.state[key].isPendingAck)) ||
        !hotspotContext?.state[hotspotKey].isPendingAck ||
        !predicate();

    useEffect(() => {
        if (!isHotspotSettingEnabled) {
            setShouldRender(false);
            return () => undefined;
        }

        const timeout = setTimeout(
            () => setShouldRender(true),
            0 + Math.random() * (Config.hotspots.config.maxRenderDelayMs - 0)
        );

        return () => clearTimeout(timeout);
    }, [isHotspotSettingEnabled]);

    useEffect(() => {
        SessionVisibilityState[hotspotKey] = !disabled;
    }, [disabled, hotspotKey]);

    const placementSide = placement.split(/[-_]/)[0];
    const beaconPointDirection =
        _beaconPointDirection ??
        {
            bottom: "up" as const,
            left: "right" as const,
            right: "left" as const,
            top: "down" as const,
        }[placementSide] ??
        ("up" as const);

    const numRemaining = hotspotContext
        ? Object.values(hotspotContext.state).filter(v => v.isPendingAck).length - 1
        : undefined;

    return (
        <HotspotDisplay
            beaconPointDirection={beaconPointDirection}
            className={className}
            content={Hotspot.content[hotspotKey]}
            contentOffset={contentOffset}
            contentPlacement={contentPlacement}
            disabled={disabled}
            fill={fill}
            numRemaining={numRemaining}
            onView={recordHotspotView}
            onAck={recordHotspotAck}
            offset={offset}
            placement={placement}
            targetTagName={targetTagName}
            usePortal={usePortal}
        >
            {children}
        </HotspotDisplay>
    );
}

type HotspotDisplayProps = {
    beaconPointDirection: BeaconPointDirection;
    children: React.ReactNode;
    className?: string;
    content?: Popover2Props["content"];
    contentOffset?: [number, number];
    contentPlacement?: Popover2Props["placement"];
    disabled?: boolean;
    fill?: boolean;
    numRemaining?: number;
    offset?: [number, number];
    onAck: () => void;
    onView: () => void;
    placement?: Popover2Props["placement"];
    targetTagName?: Popover2Props["targetTagName"];
    usePortal?: boolean;
};

function HotspotDisplay({
    beaconPointDirection,
    children,
    className,
    content,
    contentOffset,
    contentPlacement,
    disabled,
    fill,
    numRemaining, // eslint-disable-line @typescript-eslint/no-unused-vars
    onAck = () => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
    onView = () => undefined,
    offset,
    placement,
    targetTagName,
    usePortal = true,
}: HotspotDisplayProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover2
            autoFocus={false}
            className={classNames(className, styles.beaconTarget)}
            content={
                <ContentPopover
                    content={
                        <>
                            <div className={styles.hotspotContent}>{content}</div>
                        </>
                    }
                    isOpen={isOpen}
                    offset={contentOffset}
                    onInteraction={setIsOpen}
                    placement={contentPlacement || placement}
                >
                    <button
                        className={styles.beaconButton}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (!isOpen) {
                                onView();
                            }

                            setIsOpen(prev => !prev);
                        }}
                        type="button"
                    >
                        <Hotspot.Beacon pointDirection={beaconPointDirection} />
                    </button>
                </ContentPopover>
            }
            disabled={disabled}
            enforceFocus={false}
            fill={fill}
            isOpen
            modifiers={{
                arrow: { enabled: false },
                flip: {
                    enabled: false,
                },
                offset: {
                    enabled: !!offset,
                    options: { offset },
                },
                preventOverflow: {
                    enabled: false,
                },
            }}
            placement={placement}
            popoverClassName={styles.beaconPopover}
            targetTagName={targetTagName}
            usePortal={usePortal}
        >
            {children}
        </Popover2>
    );
}

Hotspot.Display = HotspotDisplay;

type ContentPopoverProps = {
    children: React.ReactNode;
    className?: string;
    content: Popover2Props["content"];
    isOpen: boolean;
    offset?: [number, number];
    onInteraction?: Popover2Props["onInteraction"];
    placement?: Popover2Props["placement"];
};

function ContentPopover({
    children,
    className,
    content,
    isOpen,
    offset,
    onInteraction,
    placement,
}: ContentPopoverProps) {
    const breakpoints = useBreakpoints();

    return (
        <Popover2
            content={content}
            enforceFocus={false}
            interactionKind={Popover2InteractionKind.HOVER}
            isOpen={isOpen}
            modifiers={{
                // As of February 2022, there appears to be a small bug in Popover2 or the underyling
                // popper library. When the popover is displayed, the animation should "emanate" the
                // popover out of the corner or edge that's aligned to the target, based on "placement".
                // For example, if the upper-left corner of the popover is what's aligned to thet target,
                // the animation should emanate it from that corner.
                //
                // It appears this works only if arrow is enabled. Therefore, we enable arrow and hide
                // it via CSS (if desired).
                arrow: { enabled: true },
                offset: {
                    enabled: true,
                    options: { offset: offset || [0, 20] },
                },
                preventOverflow: {
                    enabled: true,
                    options: {
                        padding: 12,
                    },
                },
            }}
            onInteraction={onInteraction}
            placement={breakpoints.smMax ? "auto" : placement}
            popoverClassName={classNames(className, styles.contentPopover)}
        >
            {children}
        </Popover2>
    );
}

Hotspot.ContentPopover = ContentPopover;

type BeaconProps = {
    className?: string;
    inline?: boolean;
    pointDirection: BeaconPointDirection;
};

function Beacon({ className, inline, pointDirection }: BeaconProps) {
    return (
        <span
            className={classNames(
                className,
                styles.beacon,
                inline && styles.inline,
                pointDirection && styles[pointDirection]
            )}
        >
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="m 19.985911,32.500039 c -8.9036,0 -18.242911,-12.5 -18.242911,-12.5 0,0 9.339311,-12.500039 18.242911,-12.500039 6.9035,0 12.5,5.59644 12.5,12.500039 0,6.9035 -5.5965,12.5 -12.5,12.5 z" />
            </svg>
        </span>
    );
}

Hotspot.Beacon = Beacon;

Hotspot.content = {
    [Enums.HotspotKey.BLOCKER_THREADS]: (
        <>
            <p>
                First-class blockers are one of Flat's superpowers, letting anyone record, assign,
                and discuss what's stopping progress, so there's never a question like "is this
                blocked?", "what's it blocked on?", "who's responsible for addressing it?", or
                "what's the latest update?".
            </p>
            <p>
                Blockers are free-form text, not just a reference to another topic, letting you
                capture all sorts of situations like:
                <ul>
                    <li>a developer can't proceed because the design is ambiguous or incomplete</li>
                    <li>work is blocked until a customer responds to an important question</li>
                    <li>
                        a technical investment can't proceed until a third-party dependency supports
                        a needed capability
                    </li>
                </ul>
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_CHILD_TICKETS]: (
        <>
            <p>
                These progress bars indicate that the topic has children and where each one is in
                its workflow. One child is blocked, shown in red.
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_OPEN_PR]: (
        <>
            <p>
                Seeing open PRs right on the board, including their live build/review status, makes
                it easier to stay on top of development work and impossible to miss a PR or code
                review that's been lingering.
            </p>
            <p>
                When a PR has been inactive for more than a day, Flat highlights it as a nudge to
                keep things moving.
            </p>
            <p>
                Also, this is a real PR! Try clicking it to see how Flat makes things easier in
                GitHub too.
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_OPEN_BLOCKER]: (
        <>
            <p>
                Free-form blockers let anyone flag a topic that's stuck, along with the reason why.
                Blockers are prominent on the board so it's clear to everyone what the issue is and
                who is responsible for getting things moving again.
            </p>
            <p>
                Also, each blocker has its own comment thread to support focused discussion. Try
                clicking into this topic for an example.
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_OPEN_THREAD]: (
        <>
            <p>
                Flat's comment threads are assignable and visible right on the board, so discussions
                pending follow-up don't get lost like they easily can in email or chat.
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_PROGRESS_BAR]: (
        <>
            <p>
                This progress bar means the topic has a checklist{" "}
                <em>for the stage it's currently in</em>. That makes it easy for managers to keep
                tabs on how the work is coming along without pestering anyone for updates.
            </p>

            <p>Try clicking into this topic to see the corresponding checklist.</p>
        </>
    ),
    [Enums.HotspotKey.BOARD_CARD_STALE_PR]: (
        <>
            <p>
                When a PR has been inactive for more than a day, Flat highlights it as a nudge to
                keep things moving.
            </p>
        </>
    ),
    [Enums.HotspotKey.BOARD_FILTER]: (
        <>
            <p>
                Try typing anything to <em>instantly</em> focus the board on a subset of topics.
            </p>
        </>
    ),
    [Enums.HotspotKey.CHECKLIST]: (
        <>
            <p>
                Checklists provide a very lightweight way to break down work without the hassle of
                subtickets.
            </p>

            <p>
                A twist: checklists can be mapped to the stages in your workflow. This lets your
                team break down the different kinds of work as they see fit. For example, a designer
                could create a checklist of screens, a developer could create a checklist of
                components and integration tests, a tester could create a test plan checklist, and a
                PM could create a list of acceptance criteria.
            </p>
        </>
    ),
    [Enums.HotspotKey.MY_WORK]: (
        <>
            <p>
                Quickly see everything on your plate – across all of your boards – including topics
                you own and discussions you've been asked to follow up on.
            </p>
            <p>
                And if you connect your account to GitHub, your pending PR reviews are included too.
            </p>
        </>
    ),
    [Enums.HotspotKey.OWNER_AND_TEAM]: (
        <>
            <p>Flat's ownership model is flexible.</p>
            <p>
                Designate a single topic <em>owner</em> for clear accountability.
            </p>
            <p>
                Afterward, optionally add additional teammates working on the topic as{" "}
                <em>collaborators</em> to keep track of what's on everyone's plate.
            </p>
        </>
    ),
    [Enums.HotspotKey.SUGGESTED_BRANCH_NAME]: (
        <>
            <p>
                Pull requests are automatically linked to topics based on branch names, so devs can
                say goodbye to pasting topic IDs into every commit.
            </p>
            <p>
                Flat provides a branch name for convenience, but you can also make up your own, as
                long as it incorporates the topic ID in a recognizable way.
            </p>
        </>
    ),
    [Enums.HotspotKey.THREADED_DISCUSSIONS]: (
        <>
            <p>Flat's discussion threads are what you've been missing.</p>
            <p>
                They're prominently visible in this dedicated sidebar, so it's impossible to miss
                when there's an open discussion.
            </p>
            <p>
                They're assignable, so it's clear who needs to follow up and harder for them to drop
                the ball.
            </p>
            <p>
                And they're dismissable, so your topics don't get cluttered with old conversations.
            </p>
        </>
    ),
    [Enums.HotspotKey.WORK_STRUCTURES]: (
        <>
            <p>
                Flat gives you flexibility in breaking down work into smaller tasks and rolling it
                up into epics or other larger structures.
            </p>
            <p>
                Checklists provide a lightweight way to break down work without the overhead of
                extra topics. But they can also include links to other topics on any board, allowing
                a flexible parent-child relationship. Topics can have multiple parents, too.
            </p>
            <p>
                You don't have to commit to any particular structure or way of working; just do what
                works now and adjust over time as your team grows and your needs change; Flat easily
                evolves with you.
            </p>
        </>
    ),
};
