import React from "react";

import { CommonEnums, TicketSizes, sortLabels } from "c9r-common";
import classNames from "classnames";

import { DraftReminder } from "components/shared/DraftReminder";
import { Label } from "components/shared/Label";
import {
    BuildStatusIcon,
    MergeRequestIcon,
    useFormattedMergeRequest,
} from "components/shared/MergeRequest";
import { TasksProgressBar, TasksProgressReport } from "components/shared/TasksProgress";
import { DynamicWidthTableCell } from "components/ui/common/DynamicWidthTable";
import { Checkbox } from "components/ui/core/Checkbox";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { divideAndFlattenGroups, maybeQuote } from "lib/Helpers";
import { useInstrumentation } from "lib/Instrumentation";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import {
    useTaskDueDateInfo,
    useTaskOwnershipInfo,
    useTicketDueDateInfo,
    useTicketOwnershipInfo,
    useTicketParentsInfo,
    useTicketWatcherInfo,
} from "lib/TicketInfo";
import { useRecordTicketView } from "lib/TicketViews";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TicketRowContentActivity_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useUpdateTaskCompletion } from "lib/mutations";

import styles from "./TicketRowContent.module.scss";

const tasksProgressTaskTypes = [CommonEnums.TaskType.TASK, CommonEnums.TaskType.CHILD_TICKET];

const fragments = {
    TaskRowContentCheckbox: {
        task: gql(/* GraphQL */ `
            fragment TaskRowContentCheckbox_task on tasks {
                id
                is_complete
            }
        `),
    },
    TaskRowContentMainInfo: {
        task: gql(/* GraphQL */ `
            fragment TaskRowContentMainInfo_task on tasks {
                id
                title
            }
        `),
    },
    TaskRowContentAncestry: {
        task: gql(/* GraphQL */ `
            fragment TaskRowContentAncestry_task on tasks {
                id

                ticket {
                    id
                    title
                }
            }
        `),
    },
    TaskRowContentDueDate: {
        task: gql(/* GraphQL */ `
            fragment TaskRowContentDueDate_task on tasks {
                ...TaskDueDateInfo_task
            }
        `),
    },
    TaskRowContentOwnership: {
        task: gql(/* GraphQL */ `
            fragment TaskRowContentOwnership_task on tasks {
                ...TaskOwnershipInfo_task
            }
        `),
    },
    TicketRowContentReference: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentReference_ticket on tickets {
                id
                ref
            }
        `),
    },
    TicketRowContentMainInfo: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentMainInfo_ticket on tickets {
                id
                title
                size_spec

                board {
                    id
                    settings
                }

                label_attachments {
                    color
                    text
                }

                ...TicketOwnershipInfo_ticket
            }
        `),
    },
    TicketRowContentAncestry: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentAncestry_ticket on tickets {
                ...TicketParentsInfo_ticket
            }
        `),
    },
    TicketRowContentActivity: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentActivity_ticket on tickets {
                id

                assigned_threads: threads(
                    where: {
                        resolved_at: { _is_null: true }
                        assigned_to_user_id: { _is_null: false }
                    }
                ) {
                    id
                    blocker_type
                    opened_at
                    assigned_at
                    resolved_at

                    assignee {
                        id
                        name
                    }
                }

                blocked_threads: threads(
                    where: { resolved_at: { _is_null: true }, blocker_type: { _is_null: false } }
                ) {
                    id
                    blocker_text
                    blocker_type
                    opened_at
                    assigned_at
                    resolved_at

                    blocker_ticket {
                        id
                        title
                    }
                }

                blocker_of_threads(where: { resolved_at: { _is_null: true } }) {
                    id

                    ticket {
                        id
                    }
                }

                merge_requests {
                    merge_request {
                        id
                        opened_at
                        closed_at
                        merged_at
                        title
                        url

                        ...merge_request_full
                        ...MergeRequest_merge_request
                    }
                }
            }
        `),
    },
    TicketRowContentBoard: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentBoard_ticket on tickets {
                id

                board {
                    id
                    access_type
                    display_name
                }
            }
        `),
    },
    TicketRowContentStage: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentStage_ticket on tickets {
                id

                stage {
                    id
                    display_name
                }
            }
        `),
    },
    TicketRowContentProgress: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentProgress_ticket on tickets {
                id
                stage_id

                tasklists(where: { deleted_at: { _is_null: true } }) {
                    id
                    stage_id
                    tasks(where: { deleted_at: { _is_null: true } }) {
                        id
                        ...TasksProgress_task
                    }
                }
            }
        `),
    },
    TicketRowContentOwnership: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentOwnership_ticket on tickets {
                ...TicketOwnershipInfo_ticket
                ...TicketWatcherInfo_ticket
            }
        `),
    },
    TicketRowContentDueDate: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRowContentDueDate_ticket on tickets {
                ...TicketDueDateInfo_ticket
            }
        `),
    },
};

export type TicketRowTableCellProps = {
    columnClassName?: string;
    children: React.ReactNode;
};

export function TicketRowTableCell({ columnClassName, children }: TicketRowTableCellProps) {
    return (
        <DynamicWidthTableCell className={classNames(styles.tableCell, columnClassName)}>
            {children}
        </DynamicWidthTableCell>
    );
}

export type TaskRowContentCheckboxProps = {
    className?: string;
    onChangeTaskCompletion?: ({
        taskId,
        isComplete,
    }: {
        taskId: string;
        isComplete: boolean;
    }) => void;
    task: FragmentType<typeof fragments.TaskRowContentCheckbox.task>;
};

export function TaskRowContentCheckbox({
    className,
    onChangeTaskCompletion,
    task: _taskFragment,
}: TaskRowContentCheckboxProps) {
    const task = getFragmentData(fragments.TaskRowContentCheckbox.task, _taskFragment);
    const { updateTaskCompletion } = useUpdateTaskCompletion();
    const isComplete = task.is_complete;

    return (
        <Checkbox
            className={className}
            checked={task.is_complete}
            inline
            instrumentation={{
                elementName: "task_row.completion_checkbox",
                eventData: { taskId: task.id, checked: !isComplete },
            }}
            onChange={event => {
                onChangeTaskCompletion?.({ taskId: task.id, isComplete: event.target.checked });

                void updateTaskCompletion({ taskId: task.id, isComplete: event.target.checked });

                event.preventDefault();
            }}
        />
    );
}

export type TicketRowContentIconProps = {
    className?: string;
};

export function TicketRowContentIcon({ className }: TicketRowContentIconProps) {
    return (
        <Icon
            className={classNames(className, styles.icon)}
            icon="file-text"
            iconSet="lucide"
            iconSize={18}
            strokeWidth={1}
        />
    );
}

export type TicketRowContentReferenceProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentReference.ticket>;
};

export function TicketRowContentReference({
    className,
    ticket: _ticketFragment,
}: TicketRowContentReferenceProps) {
    const ticket = getFragmentData(fragments.TicketRowContentReference.ticket, _ticketFragment);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.reference)}>
            {ticket.ref}
        </TicketRowTableCell>
    );
}

export type TaskRowContentMainInfoProps = {
    className?: string;
    task: FragmentType<typeof fragments.TaskRowContentMainInfo.task>;
};

export function TaskRowContentMainInfo({
    className,
    task: _taskFragment,
}: TaskRowContentMainInfoProps) {
    const task = getFragmentData(fragments.TaskRowContentMainInfo.task, _taskFragment);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.mainInfo)}>
            <span className={classNames(styles.title)}>{task.title}</span>
        </TicketRowTableCell>
    );
}

export type TicketRowContentMainInfoProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentMainInfo.ticket>;
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
    titleOnly?: boolean;
};

export function TicketRowContentMainInfo({
    className,
    ticket: _ticketFragment,
    onClickFilterTrigger,
    titleOnly,
}: TicketRowContentMainInfoProps) {
    const ticket = getFragmentData(fragments.TicketRowContentMainInfo.ticket, _ticketFragment);

    const ticketSizeSpec = ticket.size_spec;
    const boardTicketSizes = ticket.board.settings[CommonEnums.BoardSettingType.SIZES];

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.mainInfo)}>
            <span className={styles.title}>{ticket.title}</span>
            {!titleOnly && !!ticket.label_attachments.length && (
                <span className={styles.labels}>
                    {ticket.label_attachments
                        .concat()
                        .sort(sortLabels())
                        .map(label => (
                            <Tooltip
                                key={`${label.color}|${label.text}`}
                                className={styles.labelWrapper}
                                content={<span>Filter workspace by label</span>}
                                openOnTargetFocus={false}
                                placement="bottom"
                                small
                                disabled={!onClickFilterTrigger}
                            >
                                <Label
                                    className={styles.label}
                                    color={label.color}
                                    text={label.text}
                                    small
                                    onClick={
                                        onClickFilterTrigger
                                            ? e =>
                                                  onClickFilterTrigger(
                                                      e,
                                                      `label:${maybeQuote(label.text)}`
                                                  )
                                            : undefined
                                    }
                                />
                            </Tooltip>
                        ))}
                </span>
            )}
            {!titleOnly && ticketSizeSpec && boardTicketSizes?.enabled && (
                <span className={styles.size}>
                    {TicketSizes.format({
                        value: ticketSizeSpec.value,
                        unit: ticketSizeSpec.unit,
                        abbr: true,
                    })}
                </span>
            )}
        </TicketRowTableCell>
    );
}

export type TaskRowContentAncestryProps = {
    className?: string;
    task: FragmentType<typeof fragments.TaskRowContentAncestry.task>;
};

export function TaskRowContentAncestry({
    className,
    task: _taskFragment,
}: TaskRowContentAncestryProps) {
    const task = getFragmentData(fragments.TaskRowContentAncestry.task, _taskFragment);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.ancestry)}>
            <span className={styles.ancestryText}>
                <Icon
                    className={styles.ancestryIcon}
                    icon="file-text"
                    iconSet="lucide"
                    iconSize={14}
                />{" "}
                {task.ticket.title}
            </span>
        </TicketRowTableCell>
    );
}

export type TicketRowContentAncestryProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentAncestry.ticket>;
};

export function TicketRowContentAncestry({
    className,
    ticket: _ticketFragment,
}: TicketRowContentAncestryProps) {
    const ticket = getFragmentData(fragments.TicketRowContentAncestry.ticket, _ticketFragment);
    const { parentTicketsText } = useTicketParentsInfo({ ticket });

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.ancestry)}>
            {parentTicketsText ? (
                <span className={styles.ancestryText}>{parentTicketsText}</span>
            ) : null}
        </TicketRowTableCell>
    );
}

type BlockersSectionProps = {
    className?: string;
    ticket: TicketRowContentActivity_ticketFragment;
};

function BlockersSection({ className, ticket }: BlockersSectionProps) {
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    const blockedThreads = ticket.blocked_threads
        .filter(thread => !thread.resolved_at && !!thread.blocker_type)
        .sort(
            (a, b) =>
                (a.assigned_at
                    ? new Date(a.assigned_at).getTime()
                    : new Date(a.opened_at).getTime()) -
                (b.assigned_at
                    ? new Date(b.assigned_at).getTime()
                    : new Date(b.opened_at).getTime())
        );

    return blockedThreads.length ? (
        <span className={classNames(className, styles.blockersSection)}>
            <Icon
                className={styles.blockerIcon}
                icon="x-octagon"
                iconSet="lucide"
                iconSize={16}
                strokeWeight={1.5}
            />
            <div className={styles.blockers}>
                {divideAndFlattenGroups({
                    itemGroups: blockedThreads.map(thread => [
                        <span key={thread.id} className={classNames(styles.blocker)}>
                            {thread.blocker_type === CommonEnums.BlockerType.TEXT ? (
                                thread.blocker_text
                            ) : (
                                <>
                                    <Icon
                                        className={styles.blockerTicketIcon}
                                        icon="file-text"
                                        iconSet="lucide"
                                        iconSize={14}
                                        strokeWidthAbsolute={1}
                                    />
                                    {thread.blocker_ticket?.title ??
                                        buildUnauthorizedDisplayName({ abstractName: "workItem" })}
                                </>
                            )}
                        </span>,
                    ]),
                    divider: <span className={styles.blockerDivider}>•</span>,
                })}
            </div>
        </span>
    ) : null;
}

type BlockingSectionProps = {
    ticket: TicketRowContentActivity_ticketFragment;
};

function BlockingSection({ ticket }: BlockingSectionProps) {
    const blockerOfTickets = ticket.blocker_of_threads.reduce(
        (tickets, thread) =>
            tickets.some(t => t.id === thread.ticket.id) ? tickets : tickets.concat(thread.ticket),
        [] as TicketRowContentActivity_ticketFragment["blocker_of_threads"][number]["ticket"][]
    );

    if (!blockerOfTickets.length) {
        return null;
    }

    return (
        <span className={styles.blockingSection}>
            Blocking {blockerOfTickets.length} {blockerOfTickets.length === 1 ? "topic" : "topics"}
        </span>
    );
}

type AssignedThreadProps = {
    assignee: { id: number; name: string };
    count: number;
};

function AssignedThread({ assignee, count }: AssignedThreadProps) {
    const currentUser = useCurrentUser();

    return (
        <span
            className={classNames(
                styles.assignedThread,
                assignee.id === currentUser.id && styles.assignedToMe
            )}
        >
            <Icon icon="chatBubble" iconSet="c9r" iconSize={14} className={styles.threadIcon} />
            <span>
                {count.toLocaleString()} {count === 1 ? "thread" : "threads"} for&nbsp;
                <span className={styles.assigneeName}>
                    {assignee.id === currentUser.id ? "me" : assignee.name}
                </span>
            </span>
        </span>
    );
}

type AssignedThreadsProps = {
    ticket: TicketRowContentActivity_ticketFragment;
};

function AssignedThreads({ ticket }: AssignedThreadsProps) {
    type TThread = TicketRowContentActivity_ticketFragment["assigned_threads"][number];
    type TAssignedThread = TThread & {
        assigned_at: NonNullable<TThread["assigned_at"]>;
        assignee: NonNullable<TThread["assignee"]>;
    };

    const assignedThreads: TAssignedThread[] = ticket.assigned_threads
        .filter((thread): thread is TAssignedThread => !thread.resolved_at && !!thread.assignee)
        .filter(thread => !thread.blocker_type); // Exclude blockers because they're rendered separately
    const assignedThreadsByUserId = assignedThreads.reduce<Record<number, TAssignedThread[]>>(
        (acc, thread) => {
            acc[thread.assignee.id] = acc[thread.assignee.id] || [];
            acc[thread.assignee.id].push(thread);

            return acc;
        },
        {}
    );

    const assignedThreadAggregates = Object.entries(assignedThreadsByUserId)
        .map(([, threads]) => ({
            assignee: threads[0].assignee,
            threads,
            count: threads.length,
            oldestAssignedAt: new Date(
                Math.min(...threads.map(th => new Date(th.assigned_at).getTime()))
            ).toISOString(),
        }))
        .sort(
            (a, b) =>
                new Date(a.oldestAssignedAt).getTime() - new Date(b.oldestAssignedAt).getTime()
        );

    return assignedThreadAggregates.length ? (
        <span className={styles.assignedThreads}>
            {assignedThreadAggregates.map(({ assignee, count }) => (
                <AssignedThread key={assignee.id} assignee={assignee} count={count} />
            ))}
        </span>
    ) : null;
}

type PullRequestProps = {
    mr: TicketRowContentActivity_ticketFragment["merge_requests"][number]["merge_request"];
};

function PullRequest({ mr }: PullRequestProps) {
    const { buildStatus, isDelayed, status } = useFormattedMergeRequest({ mr });
    const { recordCallback } = useInstrumentation();
    const { recordTicketViewByMergeRequestId } = useRecordTicketView();

    return (
        <a
            key={mr.id}
            className={classNames(styles[status], styles.pullRequest, isDelayed && styles.delayed)}
            target="_blank"
            rel="noopener noreferrer"
            href={mr.url}
            onClick={recordCallback(
                {
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: "ticket_row.pr_link",
                    eventData: { mergeRequestId: mr.id },
                },
                e => {
                    void recordTicketViewByMergeRequestId({ mergeRequestId: mr.id });
                    e.stopPropagation();
                    return false;
                }
            )}
        >
            <MergeRequestIcon
                className={styles.prIcon}
                status={status}
                iconSize={16}
                strokeWeight={undefined}
            />
            <span className={styles.prTitle}>{mr.title}&nbsp;</span>
            <BuildStatusIcon
                buildStatus={buildStatus}
                className={styles.prStatus}
                iconSize={undefined}
            />
        </a>
    );
}

type PullRequestsProps = {
    className?: string;
    ticket: TicketRowContentActivity_ticketFragment;
};

function PullRequests({ className, ticket }: PullRequestsProps) {
    const openMergeRequests = ticket.merge_requests
        .map(mr => mr.merge_request)
        .filter(mr => !mr.closed_at)
        .filter(mr => !mr.merged_at)
        .sort((mrA, mrB) => new Date(mrA.opened_at).getTime() - new Date(mrB.opened_at).getTime());

    return openMergeRequests.length ? (
        <span className={classNames(className, styles.pullRequests)}>
            {openMergeRequests.map(mr => (
                <PullRequest key={mr.id} mr={mr} />
            ))}
        </span>
    ) : null;
}

type MergedPullRequestsCountProps = {
    className?: string;
    ticket: TicketRowContentActivity_ticketFragment;
};

function MergedPullRequestsCount({ className, ticket }: MergedPullRequestsCountProps) {
    const mergedMrCount = ticket.merge_requests.filter(mr => mr.merge_request.merged_at).length;

    return mergedMrCount ? (
        <span className={className}>
            <Icon className={styles.mergedMrIcon} icon="codeMerged" iconSet="c9r" iconSize={14} />
            <span>{mergedMrCount}</span>
        </span>
    ) : null;
}

type TaskRowContentActivityProps = {
    className?: string;
};

export function TaskRowContentActivity({ className }: TaskRowContentActivityProps) {
    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.activity)}>
            {null}
        </TicketRowTableCell>
    );
}

type TicketRowContentActivityProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentActivity.ticket>;
};

export function TicketRowContentActivity({
    className,
    ticket: _ticketFragment,
}: TicketRowContentActivityProps) {
    const ticket = getFragmentData(fragments.TicketRowContentActivity.ticket, _ticketFragment);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.activity)}>
            <DraftReminder className={styles.draftReminder} ticket={ticket} />
            <BlockersSection ticket={ticket} />
            <BlockingSection ticket={ticket} />
            <AssignedThreads ticket={ticket} />
            <PullRequests ticket={ticket} />
            <MergedPullRequestsCount className={styles.mergedMrCount} ticket={ticket} />
        </TicketRowTableCell>
    );
}

type TicketRowContentBoardProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentBoard.ticket>;
};

export function TicketRowContentBoard({
    className,
    ticket: _ticketFragment,
}: TicketRowContentBoardProps) {
    const ticket = getFragmentData(fragments.TicketRowContentBoard.ticket, _ticketFragment);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.board)}>
            {ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                <Icon icon="lock" iconSet="c9r" iconSize={12} strokeWidthAbsolute={1.5} />
            ) : (
                <Icon icon="spaceFrame" iconSet="c9r" iconSize={12} />
            )}
            <span className={styles.boardDisplayName}>{ticket.board.display_name}</span>
        </TicketRowTableCell>
    );
}

type TicketRowContentStageProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentStage.ticket>;
};

export function TicketRowContentStage({
    className,
    ticket: _ticketFragment,
}: TicketRowContentStageProps) {
    const ticket = getFragmentData(fragments.TicketRowContentStage.ticket, _ticketFragment);

    if (!ticket.stage) {
        return null;
    }

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.stage)}>
            <span className={styles.stageDisplayName}>{ticket.stage.display_name}</span>
        </TicketRowTableCell>
    );
}

type TicketRowContentProgressProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentProgress.ticket>;
};

export function TicketRowContentProgress({
    className,
    ticket: _ticketFragment,
}: TicketRowContentProgressProps) {
    const ticket = getFragmentData(fragments.TicketRowContentProgress.ticket, _ticketFragment);
    const tasks = ticket.tasklists
        .filter(tl => tl.stage_id === ticket.stage_id)
        .flatMap(tl => tl.tasks);

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.progress)}>
            {!!tasks.length && (
                <>
                    <TicketRowTableCell columnClassName={styles.progressBarWrapper}>
                        <TasksProgressBar
                            className={styles.progressBar}
                            tasks={tasks}
                            taskTypes={tasksProgressTaskTypes}
                            small
                        />
                    </TicketRowTableCell>
                    <TicketRowTableCell columnClassName={styles.progressReportWrapper}>
                        <TasksProgressReport
                            className={styles.progressReport}
                            tasks={tasks}
                            taskTypes={tasksProgressTaskTypes}
                        />
                    </TicketRowTableCell>
                </>
            )}
        </TicketRowTableCell>
    );
}

type TaskRowContentOwnershipProps = {
    className?: string;
    task: FragmentType<typeof fragments.TaskRowContentOwnership.task>;
};

export function TaskRowContentOwnership({ className, task: _task }: TaskRowContentOwnershipProps) {
    const task = getFragmentData(fragments.TaskRowContentOwnership.task, _task);
    const { ownershipSummaryText } = useTaskOwnershipInfo({ task });

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.ownershipSummary)}>
            {ownershipSummaryText && [ownershipSummaryText].filter(Boolean)}
        </TicketRowTableCell>
    );
}

type TicketRowContentOwnershipProps = {
    className?: string;
    isHighlighted?: boolean;
    ticket: FragmentType<typeof fragments.TicketRowContentOwnership.ticket>;
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
};

export function TicketRowContentOwnership({
    className,
    isHighlighted,
    ticket: _ticketFragment,
    onClickFilterTrigger,
}: TicketRowContentOwnershipProps) {
    const ticket = getFragmentData(fragments.TicketRowContentOwnership.ticket, _ticketFragment);
    const {
        ownerUser,
        isCurrentUserOwner,
        isCurrentUserMember,
        ownershipSummaryText,
    } = useTicketOwnershipInfo({ ticket });
    const { isCurrentUserWatcher } = useTicketWatcherInfo({ ticket });

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.ownershipSummary)}>
            {ownershipSummaryText ? (
                <Tooltip
                    content={<span>Filter workspace by owner</span>}
                    openOnTargetFocus={false}
                    placement="bottom"
                    small
                    disabled={!(onClickFilterTrigger && ownerUser)}
                >
                    <span
                        className={classNames(isHighlighted && styles.ownershipSummaryHighlighted)}
                        onClick={
                            onClickFilterTrigger && ownerUser
                                ? e =>
                                      onClickFilterTrigger(e, `owner:${maybeQuote(ownerUser.name)}`)
                                : undefined
                        }
                    >
                        {ownershipSummaryText}
                    </span>
                </Tooltip>
            ) : null}
            {!isCurrentUserOwner && !isCurrentUserMember && isCurrentUserWatcher && (
                <Icon className={styles.watchingIcon} icon="eye" iconSet="lucide" iconSize={12} />
            )}
        </TicketRowTableCell>
    );
}

type TaskRowContentDueDateProps = {
    className?: string;
    task: FragmentType<typeof fragments.TaskRowContentDueDate.task>;
};

export function TaskRowContentDueDate({
    className,
    task: _taskFragment,
}: TaskRowContentDueDateProps) {
    const task = getFragmentData(fragments.TaskRowContentDueDate.task, _taskFragment);
    const { formattedDueDate, isUpcomingSoon, isOverdue } = useTaskDueDateInfo({ task });

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.dueDate)}>
            {formattedDueDate && (
                <span
                    className={classNames(
                        styles.formattedDueDate,
                        isUpcomingSoon && styles.upcoming,
                        isOverdue && styles.overdue
                    )}
                >
                    {formattedDueDate}
                </span>
            )}
        </TicketRowTableCell>
    );
}

type TicketRowContentDueDateProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.TicketRowContentDueDate.ticket>;
};

export function TicketRowContentDueDate({
    className,
    ticket: _ticketFragment,
}: TicketRowContentDueDateProps) {
    const ticket = getFragmentData(fragments.TicketRowContentDueDate.ticket, _ticketFragment);
    const { formattedDueDate, isUpcomingSoon, isOverdue } = useTicketDueDateInfo({ ticket });

    return (
        <TicketRowTableCell columnClassName={classNames(className, styles.dueDate)}>
            {formattedDueDate && (
                <span
                    className={classNames(
                        styles.formattedDueDate,
                        isUpcomingSoon && styles.upcoming,
                        isOverdue && styles.overdue
                    )}
                >
                    {formattedDueDate}
                </span>
            )}
        </TicketRowTableCell>
    );
}

type TicketRowContentPlaceholderProps = {
    classNameKey:
        | "reference"
        | "mainInfo"
        | "ancestry"
        | "activity"
        | "board"
        | "stage"
        | "progress"
        | "progressBarWrapper"
        | "progressReportWrapper"
        | "ownershipSummary"
        | "dueDate";
};

export function TicketRowContentPlaceholder({ classNameKey }: TicketRowContentPlaceholderProps) {
    return <TicketRowTableCell columnClassName={styles[classNameKey]}>{null}</TicketRowTableCell>;
}

export const TicketRowContentColumnType = {
    ACTIVITY: "ACTIVITY",
    ANCESTRY: "ANCESTRY",
    BOARD: "BOARD",
    DUE_DATE: "DUE_DATE",
    MAIN_INFO: "MAIN_INFO",
    OWNERSHIP: "OWNERSHIP",
    PROGRESS: "PROGRESS",
    REFERERENCE: "REFERENCE",
    STAGE: "STAGE",
};

export const TicketRowContentDynamicWidthTableColumnDefinitions = [
    {
        columnType: TicketRowContentColumnType.REFERERENCE,
        columnClassName: styles.reference,
    },
    {
        columnType: TicketRowContentColumnType.MAIN_INFO,
        columnClassName: styles.mainInfo,
        minWidth: 250,
        shrinkable: true,
    },
    {
        columnType: TicketRowContentColumnType.ANCESTRY,
        columnClassName: styles.ancestry,
        minWidth: 50,
        shrinkable: true,
    },
    {
        columnType: TicketRowContentColumnType.ACTIVITY,
        columnClassName: styles.activity,
        shrinkable: true,
    },
    {
        columnType: TicketRowContentColumnType.BOARD,
        columnClassName: styles.board,
        maxWidth: 120,
        minWidth: 100,
        shrinkable: true,
    },
    {
        columnType: TicketRowContentColumnType.STAGE,
        columnClassName: styles.stage,
        maxWidth: 120,
        minWidth: 100,
        shrinkable: true,
    },
    {
        columnType: TicketRowContentColumnType.PROGRESS,
        columnClassName: styles.progress,
        minWidth: 100,
        maxWidth: 100,
        shrinkable: true,
    },
    {
        columnClassName: styles.progressBarWrapper,
        maxWidth: 100,
        shrinkable: true,
    },
    {
        columnClassName: styles.progressReportWrapper,
    },
    {
        columnType: TicketRowContentColumnType.OWNERSHIP,
        columnClassName: styles.ownershipSummary,
    },
    {
        columnType: TicketRowContentColumnType.DUE_DATE,
        columnClassName: styles.dueDate,
    },
];
