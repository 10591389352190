import { useCallback } from "react";

import { CommonEnumValue } from "c9r-common";

import { useReplicache } from "lib/replicache/Context";

/**
 * Changes the user's plan for a ticket, creating a record if necessary.
 */
export function useChangeTicketPlans() {
    const { replicache } = useReplicache();

    const changeTicketPlans = useCallback(
        async (
            plans: {
                ticketId: string;
                planType: CommonEnumValue<"UserTicketPlanType">;
                planPos: number;
            }[]
        ) => {
            await Promise.all(
                plans.map(plan =>
                    replicache.mutate.updateTicketPlan({
                        ticketId: plan.ticketId,
                        planPos: plan.planPos,
                        planType: plan.planType,
                    })
                )
            );
        },
        [replicache]
    );

    return { changeTicketPlans };
}
