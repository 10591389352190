import React, { useCallback, useMemo, useState } from "react";

import { Transforms, isDefined } from "c9r-common";

import { Config } from "Config";
import { moveToLastPosition } from "lib/EntityPositioning";
import { useTicketOwnershipInfo, useTicketWatcherInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { DetailViewProvider_ticketFragment } from "lib/graphql/__generated__/graphql";
import {
    useArchiveTicket,
    useChangeTicketMembers,
    useTrashTicket,
    useUnarchiveTicket,
    useUntrashTicket,
    useUpdateTaskAssignee,
} from "lib/mutations";
import { createCtx } from "lib/react/Context";

export type DetailViewContextValue = {
    archiveTicket: () => Promise<void>;
    blockedByTicketIds: string[];
    isArchivable: boolean;
    isArchived: boolean;
    isClosed: boolean;
    isCurrentUserOwner: boolean;
    isCurrentUserMember: boolean;
    isCurrentUserWatcher: boolean;
    isTicketHistoryDisplayed: boolean;
    isTrashable: boolean;
    isTrashed: boolean;
    setOrRemoveTicketTaskAssignee: ({
        taskId,
        assignedToUserId,
    }: {
        taskId: string;
        assignedToUserId?: number | null | undefined;
    }) => Promise<void>;
    suggestedBranchName: string;
    ticket: DetailViewProvider_ticketFragment;
    ticketId: string;
    ticketUrl: string;
    trashTicket: () => Promise<void>;
    toggleIsTicketHistoryDisplayed: () => void;
    unarchiveTicket: () => Promise<void>;
    untrashTicket: () => Promise<void>;
};

const [useDetailView, ContextProvider] = createCtx<DetailViewContextValue>();

export { useDetailView };

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DetailViewProvider_ticket on tickets {
            id
            archived_at
            ref
            slug
            title
            trashed_at

            board {
                id
                display_name
            }

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id
                stage_id

                tasks(where: { deleted_at: { _is_null: true } }) {
                    ...TaskStatusInfo_task
                }
            }

            threads {
                id
                blocker_ticket_id
                resolved_at
            }

            owners {
                ticket_id
                user_id
                type

                owner {
                    id
                }
            }

            stage {
                id
                max_ticket_stage_pos
                role
            }

            watchers {
                watcher {
                    id
                }
            }

            ...BoardAndStagesMenuItem_ticket
            ...TicketOwnershipInfo_ticket
            ...TicketWatcherInfo_ticket

            # Nested components that call useDetailView()
            ...DetailViewHeader_ticket
            ...DetailViewMain_ticket
            ...DiscussionContent_ticket
            ...ResolvedThreadsSideDrawer_ticket
            ...TicketAncestry_ticket
            ...TicketDocument_ticket
            ...TicketMenuButton_ticket
        }
    `),
};

type DetailViewProviderInternalProps = {
    children: React.ReactNode;
    ticket: FragmentType<typeof fragments.ticket>;
};

function DetailViewProviderInternal({
    children,
    ticket: _ticketFragment,
}: DetailViewProviderInternalProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const [isTicketHistoryDisplayed, setIsTicketHistoryDisplayed] = useState(false);
    const { archiveTicket: archiveTicketMutation } = useArchiveTicket();
    const { changeTicketMembers: changeTicketMembersMutation } = useChangeTicketMembers();
    const { updateTaskAssignee: updateTaskAssigneeMutation } = useUpdateTaskAssignee();
    const { trashTicket: trashTicketMutation } = useTrashTicket();
    const { unarchiveTicket: unarchiveTicketMutation } = useUnarchiveTicket();
    const { untrashTicket: untrashTicketMutation } = useUntrashTicket();

    const ticketId = ticket.id;

    const { buildTicketUrl } = useUrlBuilders();
    const ticketUrl = `${Config.urls.public}${
        buildTicketUrl({
            ticketSlug: ticket.slug,
            vanity: {
                boardDisplayName: ticket.board.display_name,
                ticketRef: ticket.ref,
                ticketTitle: ticket.title,
            },
        }).pathname
    }`;

    const { ownerUser, isCurrentUserOwner, isCurrentUserMember } = useTicketOwnershipInfo({
        ticket,
    });
    const { isCurrentUserWatcher } = useTicketWatcherInfo({ ticket });

    const suggestedBranchName = Transforms.ticketToBranchName({
        ref: ticket.ref,
        title: ticket.title,
    });

    const toggleIsTicketHistoryDisplayed = useCallback(() => {
        setIsTicketHistoryDisplayed(prev => !prev);
    }, []);

    const isArchived = !!ticket.archived_at;
    const isTrashed = !!ticket.trashed_at;
    const isClosed = isArchived || isTrashed;
    const isTrashable = !isClosed;
    const isArchivable = !isClosed;

    const blockedByTicketIds = ticket.threads
        .filter(thread => !thread.resolved_at)
        .map(thread => thread.blocker_ticket_id)
        .filter(isDefined);

    const archiveTicket = useCallback(async () => {
        await archiveTicketMutation({ ticketId });
    }, [archiveTicketMutation, ticketId]);

    const trashTicket = useCallback(async () => {
        await trashTicketMutation({ ticketId });
    }, [trashTicketMutation, ticketId]);

    const unarchiveTicket = useCallback(async () => {
        await unarchiveTicketMutation({
            ticketId,
            stagePos: moveToLastPosition({
                maxPos: ticket.stage?.max_ticket_stage_pos,
            }),
        });
    }, [unarchiveTicketMutation, ticketId, ticket.stage]);

    const untrashTicket = useCallback(async () => {
        await untrashTicketMutation({
            ticketId,
            stagePos: moveToLastPosition({
                maxPos: ticket.stage?.max_ticket_stage_pos,
            }),
        });
    }, [untrashTicketMutation, ticketId, ticket.stage]);

    const setOrRemoveTicketTaskAssignee = useCallback(
        async ({
            taskId,
            assignedToUserId,
        }: {
            taskId: string;
            assignedToUserId?: number | null;
        }) => {
            await Promise.all([
                updateTaskAssigneeMutation({
                    taskId,
                    assignedToUserId: assignedToUserId ?? null,
                }),
                assignedToUserId && assignedToUserId !== ownerUser?.id
                    ? changeTicketMembersMutation({
                          ticketId,
                          userIdsToAdd: [assignedToUserId],
                          userIdsToRemove: [],
                      })
                    : null,
            ]);
        },
        [changeTicketMembersMutation, ownerUser?.id, ticketId, updateTaskAssigneeMutation]
    );

    const value = useMemo(
        () => ({
            archiveTicket,
            blockedByTicketIds,
            isArchivable,
            isArchived,
            isClosed,
            isCurrentUserOwner,
            isCurrentUserMember,
            isCurrentUserWatcher,
            isTicketHistoryDisplayed,
            isTrashable,
            isTrashed,
            setOrRemoveTicketTaskAssignee,
            suggestedBranchName,
            ticket,
            ticketId,
            ticketUrl,
            trashTicket,
            toggleIsTicketHistoryDisplayed,
            unarchiveTicket,
            untrashTicket,
        }),
        [
            archiveTicket,
            blockedByTicketIds,
            isArchivable,
            isArchived,
            isClosed,
            isCurrentUserOwner,
            isCurrentUserMember,
            isCurrentUserWatcher,
            isTicketHistoryDisplayed,
            isTrashable,
            isTrashed,
            setOrRemoveTicketTaskAssignee,
            suggestedBranchName,
            ticket,
            ticketId,
            ticketUrl,
            trashTicket,
            toggleIsTicketHistoryDisplayed,
            unarchiveTicket,
            untrashTicket,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

export type DetailViewProviderProps = DetailViewProviderInternalProps;

export function DetailViewProvider({ children, ticket: _ticketFragment }: DetailViewProviderProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <DetailViewProviderInternal ticket={_ticketFragment} key={ticket.id}>
            {children}
        </DetailViewProviderInternal>
    );
}
