import { useCallback } from "react";

import { useMaybeCurrentIdentity } from "AppState";
import { EnumValue, Enums } from "lib/Enums";
import { useHistory } from "lib/Routing";
import { useDeleteReplicacheDbs } from "lib/replicache/Helpers";

const broadcastChannel = new BroadcastChannel("FLAT_LOGOUT");

// This is separately exported for parts of the app that need to log out but
// can't use useLogout because they are not within the main app context providers
// that include Replicache.
export function useInternalLogout() {
    const { history } = useHistory();

    const internalLogout = useCallback(() => {
        history.push("/logout");
    }, [history]);

    return { internalLogout };
}

function useHandleLogout() {
    const identity = useMaybeCurrentIdentity();
    const { internalLogout } = useInternalLogout();
    const { deleteReplicacheDbs } = useDeleteReplicacheDbs();

    return useCallback(
        ({ logoutType }: { logoutType: EnumValue<"LogoutType"> }) => {
            switch (logoutType) {
                case Enums.LogoutType.INTERNAL:
                    internalLogout();
                    break;

                case Enums.LogoutType.USER_INITIATED:
                    void deleteReplicacheDbs({ userIds: identity?.users.map(u => u.id) ?? [] });
                    internalLogout();
                    break;

                default:
            }
        },
        [deleteReplicacheDbs, identity, internalLogout]
    );
}

export function useLogout() {
    const handleLogout = useHandleLogout();
    const logout = useCallback(
        ({ logoutType }: { logoutType: EnumValue<"LogoutType"> }) => {
            broadcastChannel.postMessage(logoutType);
            handleLogout({ logoutType });
        },
        [handleLogout]
    );

    return { logout };
}

export function useHandleLogoutEvents() {
    const handleLogout = useHandleLogout();

    broadcastChannel.onmessage = () => {
        // Always do an internal logout when a logout occurred in another tab.
        // The other tab handled the user-initiated aspects, if any.
        handleLogout({ logoutType: Enums.LogoutType.INTERNAL });
    };
}
