import React, { Fragment } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { AlertBadge } from "components/ui/common/AlertBadge";
import { useCurrentUser } from "contexts/UserContext";
import { CssClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useNomenclature } from "lib/Nomenclature";
import { NavLink } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { isDefined } from "lib/types/guards";

import styles from "./SettingsSideNav.module.scss";

export function SettingsSideNav() {
    const currentUser = useCurrentUser();
    const { buildSettingsUrl } = useUrlBuilders();
    const { isFeatureEnabled } = useFeatureFlags();
    const { nomenclature } = useNomenclature();
    const items = [
        {
            header: "Personal settings",
            navItems: [
                {
                    item: "profile",
                    displayName: (
                        <>
                            Profile
                            {!currentUser.identity.is_email_address_verified && <AlertBadge />}
                        </>
                    ),
                },
                {
                    item: "notifications",
                    displayName: "Notifications",
                },
                {
                    item: "appearance",
                    displayName: "Appearance",
                },
            ],
        },
        currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN
            ? {
                  header: "Admin settings",
                  navItems: [
                      {
                          item: "general",
                          displayName: "General",
                      },
                      {
                          item: "people",
                          displayName: "People",
                      },
                      {
                          item: "workspaces",
                          displayName: nomenclature.space.plural,
                      },
                      {
                          item: "integrations",
                          displayName: "Integrations",
                      },
                      {
                          item: "import",
                          displayName: "Import data",
                      },
                      isFeatureEnabled({ feature: Enums.Feature.ACCOUNT_SETTINGS })
                          ? {
                                item: "account",
                                displayName: "Account",
                            }
                          : null,
                  ].filter(isDefined),
              }
            : null,
    ].filter(isDefined);

    return (
        <nav className={classNames(styles.settingsSideNav, CssClasses.SCROLLABLE)}>
            {items.map(({ header, navItems }) => (
                <Fragment key={header}>
                    <h3>{header}</h3>
                    {navItems.map(({ item, displayName }) => {
                        const path = `${buildSettingsUrl().pathname}/${item}`;
                        return (
                            <NavLink
                                activeClassName={styles.active}
                                key={item}
                                to={path}
                                role="tab"
                            >
                                {displayName}
                            </NavLink>
                        );
                    })}
                </Fragment>
            ))}
        </nav>
    );
}
