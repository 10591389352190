import React, { useEffect } from "react";

import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import { useResettingState } from "lib/Hooks";
import { useLocation } from "lib/Routing";

import styles from "./SideDrawer.module.scss";

export type SideDrawerProps = {
    className?: string;
    children?: React.ReactNode;
    isOpen?: boolean;
    width?: number;
};

export function SideDrawer({ className, children, isOpen, width }: SideDrawerProps) {
    const location = useLocation();
    const [animate, setAnimate] = useResettingState(true, 0);

    useEffect(() => {
        setAnimate(false);
    }, [location.pathname, setAnimate]);

    return (
        <CSSTransition
            in={isOpen}
            timeout={{
                enter: animate ? 300 : 0,
                exit: animate ? 150 : 0,
            }}
            classNames={{ ...styles }}
            mountOnEnter
            unmountOnExit
        >
            <div
                className={classNames(className, styles.sideDrawer, animate && styles.animate)}
                style={{ "--SideDrawer-width": `${width || 500}px` } as React.CSSProperties}
            >
                {children}
            </div>
        </CSSTransition>
    );
}
