import React, { useCallback, useEffect, useRef } from "react";

import MiniSearch from "minisearch";
import { atom, useSetRecoilState } from "recoil";
import { ExperimentalDiffOperation, ExperimentalNoIndexDiff } from "replicache";

import { Config } from "Config";
import { useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { useTicketViewsLocal } from "lib/TicketViewsLocal";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { TicketSearchIndex_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useReplicache } from "lib/replicache/Context";
import { Entry } from "lib/replicache/entries/EntryTypes";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

import { buildIndex, buildTicketDocument, fieldBoosts } from "./TicketSearchIndexHelpers";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketSearchIndex_ticket on tickets {
            id
            ref
            slug
            title
            description_json
            archived_at

            board {
                id
                access_type
                display_name

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    role
                    display_name
                    board_pos
                }

                ...TicketReference_board
            }

            comments {
                id

                ...CommentsSearchIndex_comment
            }

            label_attachments {
                color
                text
            }

            stage {
                id
                role
                display_name
                board_pos
            }

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id

                stage {
                    id
                }

                tasks(where: { deleted_at: { _is_null: true } }) {
                    id
                    title
                    tasklist_pos

                    ...TasksSearchIndex_task
                }
            }

            threads {
                id
                blocker_text

                comments {
                    id
                    comment_text
                }
            }

            ...SearchResult_ticket
            ...TicketActivityDatesInfo_ticket
            ...TicketLink_ticket
            ...TicketMenuItemLayout_ticket
        }
    `),
};

let searchIndex: MiniSearch<any> | null = null;
let ticketMap: Record<string, TicketSearchIndex_ticketFragment>;

function removeTicketFromIndex({ ticketId }: { ticketId: string }) {
    const ticket = ticketMap[ticketId];

    if (!ticket) {
        return;
    }

    delete ticketMap[ticketId];
    searchIndex?.remove(buildTicketDocument({ ticket }));
}

function upsertTicketInIndex({ ticket }: { ticket: TicketSearchIndex_ticketFragment }) {
    removeTicketFromIndex({ ticketId: ticket.id });

    ticketMap[ticket.id] = ticket;
    searchIndex?.add(buildTicketDocument({ ticket }));
}

type TicketIdsToReindex = {
    high: Set<string>;
    low: Set<string>;
};

function getValue({ operation }: { operation: ExperimentalDiffOperation<string> }) {
    return operation.op === "del" ? operation.oldValue : operation.newValue;
}

function TicketSearchIndexReplicache() {
    const { replicache } = useReplicache();
    const setIsTicketSearchIndexLoading = useSetRecoilState(TicketSearchIndex.isLoadingState);
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const ticketsIdsToReindexRef = useRef<TicketIdsToReindex>({
        high: new Set<string>(),
        low: new Set<string>(),
    });
    const isReindexingRef = useRef<boolean>(false);
    const lastUpdatedAtRef = useRef<number>(0);

    const createSearchIndex = useCallback(async () => {
        searchIndex = buildIndex({ tickets: [] });
        ticketMap = {};
    }, []);

    const getTicketsById = useCallback(
        async ({ ticketIds }: { ticketIds: string[] }) => {
            const result = await replicacheGraphQLClient.readQuery({
                query: TicketSearchIndex.queries.ticketsById,
                variables: { ticketIds },
            });

            return result?.tickets.map(t => getFragmentData(fragments.ticket, t)) || [];
        },
        [replicacheGraphQLClient]
    );

    const reindexBatchOfTicketIds = useCallback(
        async ({ ticketIds }: { ticketIds: string[] }) => {
            const updatedTickets = Object.fromEntries(
                (await getTicketsById({ ticketIds })).map(t => [t.id, t])
            );

            ticketIds.forEach(ticketId => {
                updatedTickets[ticketId]
                    ? upsertTicketInIndex({ ticket: updatedTickets[ticketId] })
                    : removeTicketFromIndex({ ticketId });
            });
        },
        [getTicketsById]
    );

    const getBatchOfTicketIdsToReindex = useCallback(() => {
        const batch = [
            ...Array.from(ticketsIdsToReindexRef.current.high.values()),
            ...Array.from(ticketsIdsToReindexRef.current.low.values()),
        ].slice(0, Config.ticketSearchIndex.reindexBatchSize);

        batch.forEach(ticketId => {
            ticketsIdsToReindexRef.current.high.delete(ticketId);
            ticketsIdsToReindexRef.current.low.delete(ticketId);
        });

        return batch;
    }, []);

    const addTicketIdToReindex = useCallback(
        ({ ticketId, priority }: { ticketId: string; priority: "high" | "low" }) => {
            ticketsIdsToReindexRef.current[priority].add(ticketId);
        },
        []
    );

    const handleOperation = useCallback(
        (operation: ExperimentalDiffOperation<string>) => {
            const entry = getValue({ operation }) as Entry;

            switch (entry.__typename) {
                case "tickets": {
                    addTicketIdToReindex({
                        ticketId: entry.id,
                        priority: "high",
                    });

                    break;
                }
                case "comments":
                case "tasks":
                case "threads": {
                    addTicketIdToReindex({
                        ticketId: entry.ticket_id,
                        priority: "high",
                    });

                    break;
                }
                default: {
                    // do nothing
                }
            }
        },
        [addTicketIdToReindex]
    );

    const handleDiff = useCallback(
        (diff: ExperimentalNoIndexDiff) => {
            diff.forEach(handleOperation);
        },
        [handleOperation]
    );

    // As of September 2023, TicketSearchIndex is a very old component and due for a rewrite.
    // It uses a weird pattern with module-scoped variables like searchIndex and replicacheTickets.
    // Since these aren't within the component, they need to be reset on mount. Otherwise,
    // when switching between orgs, tickets from an org no longer being viewed will still be in
    // the search index!
    useEffect(() => {
        searchIndex?.removeAll();
        searchIndex = null;
        ticketMap = {};

        setIsTicketSearchIndexLoading(true);

        const timeout = window.setTimeout(() => {
            void createSearchIndex();
        }, Config.ticketSearchIndex.initialLoadDelayMs);

        return () => window.clearTimeout(timeout);
    }, [createSearchIndex, setIsTicketSearchIndexLoading]);

    useEffect(() => {
        const cleanup = replicache.experimentalWatch(handleDiff, {
            initialValuesInFirstDiff: true,
        });

        return () => {
            cleanup();
        };
    }, [handleDiff, replicache]);

    useInterval(async () => {
        if (!searchIndex) {
            return;
        }

        if (isReindexingRef.current) {
            return;
        }

        if (Date.now() - lastUpdatedAtRef.current < Config.ticketSearchIndex.reindexThrottleMs) {
            return;
        }

        const ticketIds = getBatchOfTicketIdsToReindex();

        if (!ticketIds.length) {
            setIsTicketSearchIndexLoading(false);
            return;
        }

        const startTime = Date.now();
        isReindexingRef.current = true;

        await reindexBatchOfTicketIds({ ticketIds });

        lastUpdatedAtRef.current = Date.now();
        isReindexingRef.current = false;

        Log.debug("Reindexed batch of tickets", {
            batchSize: ticketIds.length,
            elapsedMs: lastUpdatedAtRef.current - startTime,
        });
    }, Config.ticketSearchIndex.reindexCheckInterval);

    return null;
}

export type TSearchIndexTicket = TicketSearchIndex_ticketFragment;
let getWhenLastViewedLocally: ({ ticketId }: { ticketId: string }) => number;

export function TicketSearchIndex() {
    getWhenLastViewedLocally = useTicketViewsLocal().getWhenLastViewedLocally;

    return <TicketSearchIndexReplicache />;
}

TicketSearchIndex.isLoadingState = atom<boolean>({
    key: "TicketSearchIndexIsLoading",
    default: true,
});

TicketSearchIndex.allTickets = () =>
    Object.values(ticketMap).sort(
        (a, b) =>
            getWhenLastViewedLocally({ ticketId: b.id }) -
            getWhenLastViewedLocally({ ticketId: a.id })
    ) || [];

TicketSearchIndex.findTicketBySlug = ({ ticketSlug }: { ticketSlug: string }) => {
    return Object.values(ticketMap).find(t => t.slug === ticketSlug) ?? null;
};

TicketSearchIndex.findTickets = ({
    searchQuery: rawSearchQuery,
    ticketIdOnly,
    ticketRefOnly,
    maxResults,
    // Set to true to search only archived, false to exclude archived. If omitted, search both.
    archived = undefined,
    fields = [],
}: {
    searchQuery: string;
    ticketIdOnly?: boolean;
    ticketRefOnly?: boolean;
    maxResults?: number;
    archived?: boolean | undefined;
    fields?: string[];
}) => {
    if (!searchIndex) {
        return [];
    }

    const tickets = Object.values(ticketMap).sort(
        (a, b) =>
            getWhenLastViewedLocally({ ticketId: b.id }) -
            getWhenLastViewedLocally({ ticketId: a.id })
    );

    const searchQuery = rawSearchQuery.replace(/#(\d*)/gi, "$1");

    if (!searchQuery) {
        return tickets
            .filter(ticket => typeof archived !== "boolean" || archived === !!ticket.archived_at)
            .map(ticket => ({ ticket, matches: [] }))
            .slice(0, maxResults);
    }

    if (ticketIdOnly) {
        return tickets
            .filter(
                ticket =>
                    ticket.id === searchQuery &&
                    (typeof archived !== "boolean" || !!archived === !!ticket.archived_at)
            )
            .map(ticket => ({ ticket, matches: ["id"] }))
            .slice(0, maxResults);
    }

    if (ticketRefOnly) {
        const matchingTickets = [];
        const matchingTicket = tickets.find(t => t.ref === searchQuery);

        if (matchingTicket) {
            matchingTickets.push(matchingTicket);
        }

        matchingTickets.push(
            ...tickets.filter(
                t =>
                    t.ref.startsWith(searchQuery) &&
                    !(matchingTicket && t.ref === matchingTicket.ref)
            )
        );

        return matchingTickets
            .filter(ticket => typeof archived !== "boolean" || !!archived === !!ticket.archived_at)
            .map(ticket => ({ ticket, matches: ["ref"] }))
            .slice(0, maxResults);
    }

    const getSearchOptions = () => ({
        boostDocument: (documentId: string) => (ticketMap[documentId]?.archived_at ? 0.4 : 1),
        ...(fields?.length && {
            fields,
            boost: Object.fromEntries(
                Object.entries(fieldBoosts).filter(([field]) => fields.includes(field))
            ),
        }),
    });

    return searchIndex
        .search(searchQuery, getSearchOptions())
        .map(({ id, score, match }) => ({
            score,
            ticket: ticketMap[id],
            matches: Array.from(new Set(Object.values(match).flatMap(m => m))),
        }))
        .filter(({ ticket }) => !!ticket)
        .filter(
            ({ ticket }) => typeof archived !== "boolean" || !!archived === !!ticket!.archived_at
        )
        .sort((a, b) => {
            const scoreDiff = b.score - a.score;

            if (scoreDiff) {
                return scoreDiff;
            }

            const lastViewedAtDiff =
                getWhenLastViewedLocally({ ticketId: b.ticket.id }) -
                getWhenLastViewedLocally({ ticketId: a.ticket.id });

            return lastViewedAtDiff;
        })
        .map(({ ticket, matches }) => ({
            ticket,
            matches,
        }))
        .slice(0, maxResults);
};

TicketSearchIndex.queries = {
    component: gql(/* GraphQL */ `
        query TicketSearchIndex($orgId: Int!) {
            tickets(
                where: {
                    org_id: { _eq: $orgId }
                    trashed_at: { _is_null: true }
                    board: { archived_at: { _is_null: true } }
                }
            ) {
                ...TicketSearchIndex_ticket
            }
        }
    `),
    ticketsById: gql(/* GraphQL */ `
        query TicketSearchIndexTicketsById($ticketIds: [uuid!]!) {
            tickets(
                where: {
                    id: { _in: $ticketIds }
                    trashed_at: { _is_null: true }
                    board: { archived_at: { _is_null: true } }
                }
            ) {
                ...TicketSearchIndex_ticket
            }
        }
    `),
};

Queries.register({ component: "TicketSearchIndex", gqlMapByName: TicketSearchIndex.queries });
