export const OnboardingDemographics = {
    Roles: [
        { value: "DESIGNER", displayText: "designer" },
        { value: "DEVELOPER", displayText: "developer" },
        { value: "LEADERSHIP", displayText: "leadership" },
        { value: "PRODUCT_MANAGER", displayText: "product manager" },
        { value: "TESTER", displayText: "tester" },
        { value: "DIVIDER", displayText: "" },
        { value: "OTHER", displayText: "other" },
    ],
    TeamSizes: [
        { value: "ALONE", displayText: "just me" },
        { value: "2_TO_5", displayText: "2 to 5" },
        { value: "6_TO_10", displayText: "6 to 10" },
        { value: "11_TO_25", displayText: "11 to 25" },
        { value: "26_UP", displayText: "more than 25" },
    ],
    Tools: [
        { value: "ASANA", displayText: "Asana" },
        { value: "GITHUB_ISSUES", displayText: "GitHub Issues" },
        { value: "JIRA", displayText: "Jira" },
        { value: "MONDAY", displayText: "Monday" },
        { value: "NOTION", displayText: "Notion" },
        { value: "SHORTCUT", displayText: "Shortcut" },
        { value: "TRELLO", displayText: "Trello" },
        { value: "DIVIDER", displayText: "" },
        { value: "OTHER", displayText: "other" },
        { value: "NONE", displayText: "none" },
    ],
};

export const DefaultOrgDemographics = {
    teamSize: "2_TO_5",
    tool: "TRELLO",
};
