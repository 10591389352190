import React, { useCallback, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { FormGroup } from "@blueprintjs/core";
import { CommonEnums, P, UserSignin } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { useDebouncedCallback } from "use-debounce";

import { integrationsSetupStatusState } from "AppState";
import { Config } from "Config";
import { useLiveViewQuery } from "components/loading/Loading";
import { AlertBadge } from "components/ui/common/AlertBadge";
import { Avatar } from "components/ui/common/Avatar";
import { InfoCircle } from "components/ui/common/InfoCircle";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { TextInput } from "components/ui/core/TextInput";
import { Tooltip } from "components/ui/core/Tooltip";
import { useCurrentUser } from "contexts/UserContext";
import { useChangeEmailAddressDialog } from "dialogs/ChangeEmailAddressDialog";
import { useEditAvatarDialog } from "dialogs/EditAvatarDialog";
import githubLogo from "img/github_octocat.png";
import googleLogo from "img/google.png";
import { Enums } from "lib/Enums";
import { formatUserEmailAddress } from "lib/Helpers";
import { useAsyncWatcher } from "lib/Hooks";
import { useGitHubIntegration } from "lib/Integrations";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { Regexes } from "lib/Regexes";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { UserSettings_userFragment } from "lib/graphql/__generated__/graphql";
import { useSetOrRemoveUserAvatar, useUpdateUserFullName, useUpdateUsername } from "lib/mutations";
import { isError } from "lib/types/guards";

import styles from "./ProfileSettings.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserSettings_user on users {
            id

            avatar_url
            full_name
            github_login
            name
            slack_user_id
            slack_dm_channel_id

            identity {
                id
                email_address
            }

            ...Avatar_user
        }
    `),
};

type TUser = UserSettings_userFragment;

type ProfileSettingsContentProps = {
    user: TUser;
};

function ProfileSettingsContent({ user }: ProfileSettingsContentProps) {
    const currentUser = useCurrentUser();
    const { buildSettingsUrl } = useUrlBuilders();
    const [fullName, setFullName] = useState(user.full_name ?? "");
    const [username, setUsername] = useState(user.name);
    const [isUsernameValid, setIsUsernameValid] = useState(true);
    const { updateUsername } = useUpdateUsername();
    const { updateUserFullName } = useUpdateUserFullName();
    const { setOrRemoveUserAvatar } = useSetOrRemoveUserAvatar();

    const editAvatarDialog = useEditAvatarDialog();
    const changeEmailAddressDialog = useChangeEmailAddressDialog();
    const resendVerificationEmailSubmission = useAsyncWatcher();
    const disconnectGitHubSubmission = useAsyncWatcher();
    const client = useApolloClient();
    const { authorizeGitHub, disconnectGitHub } = useGitHubIntegration();
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);

    const isAdmin = currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN;
    const isUsingGoogleSSO =
        UserSignin.getSigninType({ auth0Id: currentUser.identity.auth0_id }) ===
        CommonEnums.UserSigninType.GOOGLE;

    const handleSaveFullName = useCallback(
        async (newFullName: string) => {
            try {
                await P.retry(() => updateUserFullName({ fullName: newFullName }), {
                    maxAttempts: 3,
                });
            } catch (error) {
                Log.error("Failed to save user full name", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [updateUserFullName]
    );

    const debouncedSaveFullName = useDebouncedCallback(handleSaveFullName, 1000, {
        leading: true,
        trailing: true,
    }).callback;

    const handleSaveUsername = useCallback(
        async (newUsername: string) => {
            try {
                await P.retry(() => updateUsername({ username: newUsername }), {
                    maxAttempts: 3,
                });
            } catch (error) {
                if (isError(error) && error.message.includes("users_org_id_name_key")) {
                    Log.warn("User tried to set duplicate username", { newUsername });
                    AppToaster.error({
                        message: `Someone else on your team is already using "${newUsername}".`,
                    });
                } else {
                    Log.error("Failed to save username", { error });
                    AppToaster.error({
                        message: "Sorry, something went wrong saving your changes.",
                    });
                }
            }
        },
        [updateUsername]
    );

    const debouncedSaveUsername = useDebouncedCallback(handleSaveUsername, 1000, {
        leading: true,
        trailing: true,
    }).callback;

    const handleResendVerificationEmail = async () => {
        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation ProfileSettingsResendVerificationEmail {
                    resend_verification_email {
                        ok
                    }
                }
            `),
        });

        if (!result.data?.resend_verification_email.ok) {
            Log.error("Email verification failed", { reason: "Backend error" });
            AppToaster.error({
                message: "Something went wrong resending the verification email. Please try again.",
            });
        } else {
            AppToaster.success({
                message: "The verification email has been resent.",
            });
        }
    };

    const handleRemoveAvatar = async () => {
        await setOrRemoveUserAvatar({ avatarUrl: null });
    };

    return (
        <div className={styles.userProfileView}>
            <h1>Profile</h1>

            <div className={styles.settingsWrapper}>
                <div className={styles.settingsSection}>
                    <div className={styles.nameEmailForm}>
                        <FormGroup
                            className={styles.formGroup}
                            label="Full name"
                            labelFor="full-name-input"
                        >
                            <TextInput
                                value={fullName}
                                id="full-name-input"
                                onChange={e => {
                                    setFullName(e.target.value);
                                    void debouncedSaveFullName(e.target.value);
                                }}
                                onBlur={e => {
                                    void handleSaveFullName(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            className={styles.formGroup}
                            helperText={
                                !isUsernameValid
                                    ? "Your display name must be 2-40 letters, numbers, underscores, or hyphens."
                                    : null
                            }
                            intent={!isUsernameValid ? Enums.BlueprintIntent.DANGER : undefined}
                            label="Display name"
                            labelFor="display-name-input"
                        >
                            <TextInput
                                value={username}
                                id="display-name-input"
                                onChange={e => {
                                    const isValid = !!e.target.value.match(Regexes.VALID_USERNAME);

                                    setUsername(e.target.value);
                                    setIsUsernameValid(isValid);

                                    if (isValid) {
                                        void debouncedSaveUsername(e.target.value);
                                    }
                                }}
                                onBlur={e => {
                                    if (isUsernameValid) {
                                        void handleSaveUsername(e.target.value);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            className={classNames(
                                styles.formGroup,
                                !currentUser.identity.is_email_address_verified &&
                                    styles.verifyEmailFormGroup
                            )}
                            label={
                                <>
                                    {!currentUser.identity.is_email_address_verified ? (
                                        <>
                                            Email (verification required)
                                            <AlertBadge large />
                                        </>
                                    ) : (
                                        "Email"
                                    )}
                                </>
                            }
                        >
                            <div className={styles.emailAddress}>
                                {isUsingGoogleSSO && (
                                    <Tooltip
                                        className={styles.googleTooltip}
                                        content="Your email address is managed by your Google account."
                                        placement="top-start"
                                        small
                                        openOnTargetFocus={false}
                                    >
                                        <img alt="Google" src={googleLogo} height={20} />
                                    </Tooltip>
                                )}
                                {formatUserEmailAddress({
                                    emailAddress: user.identity!.email_address,
                                })}
                                {!isUsingGoogleSSO && (
                                    <BorderButton
                                        content="Change"
                                        onClick={changeEmailAddressDialog.open}
                                        small
                                        instrumentation={null}
                                    />
                                )}
                            </div>
                            {!currentUser.identity.is_email_address_verified && (
                                <div className={styles.emailVerification}>
                                    <div>
                                        Click the link in the email we sent to verify your address.
                                    </div>
                                    <BorderButton
                                        loading={resendVerificationEmailSubmission.isInFlight}
                                        content="Resend email"
                                        onClick={resendVerificationEmailSubmission.watch(
                                            handleResendVerificationEmail
                                        )}
                                        instrumentation={null}
                                        small
                                    />
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup
                            className={styles.formGroup}
                            label={
                                <div className={styles.githubUsernameLabel}>
                                    <span>GitHub username</span>
                                    {!user.github_login && (
                                        <InfoCircle
                                            className={styles.githubUsernameTip}
                                            content="Provide your GitHub username to see your personal development activity and PR review requests right in Flat."
                                        />
                                    )}
                                </div>
                            }
                        >
                            <div className={styles.githubUsernameGroupContent}>
                                {user.github_login ? (
                                    <>
                                        <div className={styles.githubUsername}>
                                            {user.github_login}
                                            <BorderButton
                                                content="Delete"
                                                loading={disconnectGitHubSubmission.isInFlight}
                                                onClick={disconnectGitHubSubmission.watch(
                                                    disconnectGitHub
                                                )}
                                                small
                                                instrumentation={null}
                                            />
                                        </div>
                                        {!integrationsSetupStatus.github &&
                                            (isAdmin ? (
                                                <div className={styles.githubUsernameCaption}>
                                                    Finish setting up the GitHub integration by
                                                    installing our{" "}
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={Config.githubAppUrl}
                                                    >
                                                        GitHub App
                                                    </a>
                                                    . Learn more at{" "}
                                                    <Link
                                                        to={`${
                                                            buildSettingsUrl().pathname
                                                        }/integrations`}
                                                    >
                                                        Admin Settings | Integrations
                                                    </Link>
                                                    .
                                                </div>
                                            ) : (
                                                <div
                                                    className={classNames(
                                                        styles.githubUsernameCaption,
                                                        styles.githubUsernameCaptionAlert
                                                    )}
                                                >
                                                    For PRs to appear in Flat, ask a team admin to
                                                    finish setting up the GitHub integration.
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <BorderButton
                                        className={styles.githubUsernameBtn}
                                        content={
                                            <div className={styles.githubUsernameBtnContent}>
                                                <img alt="GitHub" src={githubLogo} height={16} />
                                                Provide via OAuth
                                            </div>
                                        }
                                        onClick={authorizeGitHub}
                                        instrumentation={null}
                                    />
                                )}
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className={styles.avatarSection}>
                    <Avatar user={currentUser} size={128} />
                    <BorderButton
                        className={styles.changeAvatarBtn}
                        small
                        content={user.avatar_url ? "Change photo" : "Add photo"}
                        onClick={editAvatarDialog.open}
                        instrumentation={null}
                        primary
                    />
                    {user.avatar_url && (
                        <BorderButton
                            className={styles.removeAvatarBtn}
                            minimal
                            small
                            content="Remove"
                            onClick={handleRemoveAvatar}
                            instrumentation={null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export function ProfileSettings() {
    const currentUser = useCurrentUser();

    const componentQuery = useLiveViewQuery({
        query: ProfileSettings.queries.component,
        variables: {
            currentUserId: currentUser.id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const user = getFragmentData(fragments.user, componentQuery.data?.user);

    if (!user) {
        return null;
    }

    return <ProfileSettingsContent user={user} />;
}

ProfileSettings.queries = {
    component: gql(/* GraphQL */ `
        query ProfileSettings($currentUserId: Int!) {
            user: users_by_pk(id: $currentUserId) {
                ...UserSettings_user
            }
        }
    `),
};

Queries.register({ component: "ProfileSettings", gqlMapByName: ProfileSettings.queries });
