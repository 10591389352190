import React, { useEffect } from "react";

import { CommonEnums } from "c9r-common";

import { useSetIsViewReady } from "components/loading/Loading";
import { SupportMailto } from "components/shared/SupportMailto";
import { BorderButton } from "components/ui/core/BorderButton";
import AsanaLogo from "img/asana.png";
import GitHubLogo from "img/github.png";
import JiraLogo from "img/jira.png";
import MondayLogo from "img/monday.png";
import NotionLogo from "img/notion.png";
import ShortcutLogo from "img/shortcut.png";
import TrelloLogo from "img/trello.png";
import { Queries } from "lib/Queries";
import { useAsanaImport } from "lib/import/asana/AsanaImport";
import { useGitHubImport } from "lib/import/github/GitHubImport";
import { useJiraImport } from "lib/import/jira/JiraImport";
import { useMondayImport } from "lib/import/monday/MondayImport";
import { useNotionImport } from "lib/import/notion/NotionImport";
import { useShortcutImport } from "lib/import/shortcut/ShortcutImport";
import { useTrelloImport } from "lib/import/trello/TrelloImport";

import styles from "./ImportSettings.module.scss";

export function ImportSettings() {
    const { initiateAsanaImport, asanaImportDialog } = useAsanaImport();
    const { initiateGitHubImport, githubImportDialog } = useGitHubImport();
    const { initiateJiraImport, jiraImportDialog } = useJiraImport();
    const { initiateMondayImport, mondayImportDialog } = useMondayImport();
    const { initiateNotionImport, notionImportDialog } = useNotionImport();
    const { initiateShortcutImport, shortcutImportDialog } = useShortcutImport();
    const { initiateTrelloImport, trelloImportDialog } = useTrelloImport();

    const setIsViewReady = useSetIsViewReady();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    return (
        <div className={styles.container}>
            <h1>Import Data</h1>

            <div className={styles.importSources}>
                <div>
                    <img alt="Trello" src={TrelloLogo} className={styles.trelloLogo} />

                    <BorderButton
                        content="Import from Trello"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.TRELLO },
                        }}
                        onClick={initiateTrelloImport}
                    />
                    {trelloImportDialog}
                </div>

                <div>
                    <img alt="Monday" src={MondayLogo} />

                    <BorderButton
                        content="Import from Monday"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.MONDAY },
                        }}
                        onClick={initiateMondayImport}
                    />
                    {mondayImportDialog}
                </div>

                <div>
                    <img alt="Notion" src={NotionLogo} />

                    <BorderButton
                        content="Import from Notion"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.NOTION },
                        }}
                        onClick={initiateNotionImport}
                    />
                    {notionImportDialog}
                </div>

                <div>
                    <img alt="Asana" src={AsanaLogo} />

                    <BorderButton
                        content="Contact us"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.ASANA },
                        }}
                        onClick={initiateAsanaImport}
                    />
                    {asanaImportDialog}
                </div>

                <div>
                    <img alt="Shortcut" src={ShortcutLogo} />

                    <BorderButton
                        content="Contact us"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.SHORTCUT },
                        }}
                        onClick={initiateShortcutImport}
                    />
                    {shortcutImportDialog}
                </div>

                <div>
                    <img alt="GitHub" src={GitHubLogo} />

                    <BorderButton
                        content="Contact us"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.GITHUB },
                        }}
                        onClick={initiateGitHubImport}
                    />
                    {githubImportDialog}
                </div>

                <div>
                    <img alt="Jira" src={JiraLogo} />

                    <BorderButton
                        content="Contact us"
                        instrumentation={{
                            elementName: "settings.start_import_btn",
                            eventData: { source: CommonEnums.ImportSource.JIRA },
                        }}
                        onClick={initiateJiraImport}
                    />
                    {jiraImportDialog}
                </div>
            </div>

            <p>
                Need to import data from someplace else? <SupportMailto text="Contact us" />
            </p>
        </div>
    );
}

ImportSettings.queries = {};

Queries.register({ component: "ImportSettings", gqlMapByName: ImportSettings.queries });
