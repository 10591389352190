import React from "react";

import classNames from "classnames";

import { BorderButton, BorderButtonProps } from "components/ui/core/BorderButton";

import styles from "./DropdownButton.module.scss";

export type DropdownButtonProps = {
    className?: string;
    text?: React.ReactNode;
    underline?: boolean;
    iconSize?: number;
    strokeWeight?: number;
} & Omit<BorderButtonProps, "content" | "rightIconProps">;

export function DropdownButton({
    className,
    text,
    underline,
    iconSize = 14,
    strokeWeight = 1.33,
    ...buttonProps
}: DropdownButtonProps) {
    return (
        <BorderButton
            {...buttonProps}
            className={classNames(className, styles.dropdownButton, underline && styles.underline)}
            contentClassName={classNames(buttonProps.contentClassName, styles.content)}
            content={!!text && <span>{text}</span>}
            iconSeparate
            rightIconProps={{
                icon: "chevron-down",
                iconSet: "lucide",
                iconSize: iconSize ?? 14,
                strokeWeight: strokeWeight ?? 1.33,
            }}
        />
    );
}
