import React from "react";

import { Classes } from "@blueprintjs/core";

import { BorderButton } from "components/ui/core/BorderButton";
import { DatePicker, DatePickerProps } from "components/ui/core/DatePicker";
import { MenuPopover, MenuPopoverProps } from "components/ui/core/MenuPopover";
import { useBreakpoints } from "lib/Breakpoints";

import styles from "./DatePopover.module.scss";

export type DatePopoverContentProps = {
    getInstrumentation?: DatePickerProps["getInstrumentation"];
    onSelect: (day?: Date | null) => void;
    selectedDate?: Date;
};

export function DatePopoverContent({
    getInstrumentation,
    onSelect,
    selectedDate,
}: DatePopoverContentProps) {
    const breakpoints = useBreakpoints();

    return (
        <div className={styles.datePopoverContent}>
            <DatePicker
                getInstrumentation={getInstrumentation}
                numberOfMonths={breakpoints.lgMin ? 2 : 1}
                onSelect={onSelect}
                required
                selectedDate={selectedDate}
            />
            <BorderButton
                className={Classes.POPOVER_DISMISS}
                content="No due date"
                small
                minimal
                onClick={() => {
                    onSelect?.(null);
                }}
                instrumentation={null}
            />
        </div>
    );
}

export type DatePopoverProps = {
    children?: React.ReactNode;
    className?: string;
    placement?: MenuPopoverProps["placement"];
    popoverProps?: MenuPopoverProps;
} & DatePopoverContentProps;

export function DatePopover({
    className,
    children,
    getInstrumentation,
    onSelect,
    placement,
    popoverProps,
    selectedDate,
}: DatePopoverProps) {
    return (
        <MenuPopover
            {...popoverProps}
            targetClassName={className}
            content={
                <DatePopoverContent
                    getInstrumentation={getInstrumentation}
                    onSelect={onSelect}
                    selectedDate={selectedDate}
                />
            }
            placement={placement ?? "bottom"}
        >
            {children}
        </MenuPopover>
    );
}
