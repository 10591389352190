import { Logger, StatusType, datadogLogs } from "@datadog/browser-logs";
import { CustomError } from "c9r-common";

import { Config } from "Config";
import { Env } from "Env";

// The service and version options must match the values provided in the plugin that uploads
// source maps to Datadog.
datadogLogs.init({
    clientToken: Config.datadog.clientToken,
    env: Env.name ?? undefined,
    service: "flat_react_app",
    version: Env.commitSha ?? undefined,
    forwardErrorsToLogs: Config.datadog.forwardErrorsToLogs,
    sessionSampleRate: Config.datadog.sessionSampleRate,
});

const loggers = Config.logging.transports.map(transport =>
    datadogLogs.createLogger(transport, {
        level: Config.logging.level,
        handler: transport,
    })
);

function extractErrorFromContext<T>(context?: {
    error?: CustomError<T> | Error | object | string | null;
}) {
    if (context?.error instanceof Error) {
        return {
            contextWithoutError: {
                ...context,
                error: undefined,
                ...(context.error instanceof CustomError
                    ? { errorData: context.error.data }
                    : undefined),
            },
            error: context.error,
        };
    }

    return { contextWithoutError: context, error: undefined };
}

type LogContext = object;
type LogLevel = StatusType;

function forEachLogger<C extends (arg0: Logger) => void>(cb: C) {
    for (const logger of loggers) {
        cb(logger);
    }
}

class MasterLogger {
    static addContext(key: string, value: any) {
        // Add it to the global logger so the context also gets included when
        // console logs, network logs, are forwarded, not just calls directly
        // into the log functions.
        datadogLogs.addLoggerGlobalContext(key, value);
    }

    static log(level: LogLevel, message: string, context?: LogContext) {
        forEachLogger(logger => {
            const { contextWithoutError, error } = extractErrorFromContext(context);

            return logger[level](message, contextWithoutError, error);
        });
    }

    static debug(message: string, context?: LogContext) {
        MasterLogger.log("debug", message, context);
    }

    static info(message: string, context?: LogContext) {
        MasterLogger.log("info", message, context);
    }

    static warn(message: string, context?: LogContext) {
        MasterLogger.log("warn", message, context);
    }

    static error(message: string, context?: LogContext) {
        MasterLogger.log("error", message, context);
    }
}

const masterLogger = MasterLogger;

if (Env.isDeployPreview) {
    masterLogger.addContext("isDeployPreview", true);
}

export const Log = masterLogger;
