import React, { useEffect, useState } from "react";

import classNames from "classnames";

import { EditableText } from "components/ui/core/EditableText";
import { CssClasses } from "lib/Constants";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useUpdateTicketTitle } from "lib/mutations";

import styles from "./TitleEditor.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TitleEditor_ticket on tickets {
            id
            title
        }
    `),
};

export type TitleEditorProps = {
    className?: string;
    inputClassName?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TitleEditor({
    className,
    inputClassName,
    ticket: _ticketFragment,
}: TitleEditorProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const [ticketId, setTicketId] = useState(ticket.id);
    const [originalTitle, setOriginalTitle] = useState(ticket.title);
    const { updateTicketTitle } = useUpdateTicketTitle();

    const handleTitleConfirm = async (text: string | null) => {
        if (!text || text === originalTitle) {
            return;
        }

        await updateTicketTitle({ ticketId, title: text });
    };

    useEffect(() => {
        setTicketId(ticket.id);
        setOriginalTitle(ticket.title);
    }, [ticket]);

    return (
        <h1
            className={classNames(
                className,
                styles.titleEditor,
                CssClasses.ALWAYS_SHOW_FOCUS_INDICATOR
            )}
        >
            <EditableText
                className={classNames(inputClassName, styles.editableText)}
                value={ticket.title}
                onConfirm={({ value }) => handleTitleConfirm(value)}
                placeholder="Topic title"
            />
        </h1>
    );
}
