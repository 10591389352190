export class Hex {
    static encode(str: string) {
        const e = encodeURIComponent(str);

        return e.split("").reduce((acc, _, i) => `${acc}${e.charCodeAt(i).toString(16)}`, "");
    }

    static decode(hex: string) {
        let str = "";

        for (let i = 0; i < hex.length; i += 2) {
            str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        }

        return decodeURIComponent(str);
    }
}
