import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./AlertBadge.module.scss";

export type AlertBadgeProps = {
    className?: string;
    large?: boolean;
};

export function AlertBadge({ className, large }: AlertBadgeProps) {
    return (
        <Icon
            className={classNames(className, styles.alertBadge)}
            icon="filledCircle"
            iconSet="c9r"
            iconSize={large ? 14 : 12}
        />
    );
}
