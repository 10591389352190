import React, { useRef, useState } from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { currentBoardIdState } from "AppState";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { useCreateBoardDialog } from "dialogs/CreateBoardDialog";
import { useRedirectToBoard, useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { BoardPicker_orgFragment } from "lib/graphql/__generated__/graphql";

import styles from "./BoardPicker.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment BoardPicker_org on orgs {
            id

            all_boards: boards {
                id
                access_type
                archived_at
                display_name
                slug
            }
        }
    `),
};

type BoardTextProps = {
    boardAccessType: CommonEnumValue<"BoardAccessType">;
    boardName: string;
    onClick: () => void;
};

function BoardText({ boardAccessType, boardName, onClick }: BoardTextProps) {
    return (
        <TextButton
            className={styles.boardText}
            onClick={onClick}
            text={
                <>
                    {boardAccessType === CommonEnums.BoardAccessType.PRIVATE ? (
                        <Icon
                            className={styles.boardIcon}
                            icon="lock"
                            iconSet="c9r"
                            iconSize={18}
                            strokeWidthAbsolute={1.5}
                        />
                    ) : (
                        <Icon
                            className={styles.boardIcon}
                            icon="spaceFrame"
                            iconSet="c9r"
                            iconSize={18}
                            strokeWidth={0.5}
                        />
                    )}
                    <span>{boardName}</span>
                </>
            }
            instrumentation={null}
        />
    );
}

type BoardSelectorMenuProps = {
    org: BoardPicker_orgFragment;
};

function BoardSelectorMenu({ org }: BoardSelectorMenuProps) {
    const { buildBoardUrl } = useUrlBuilders();
    const createBoardDialog = useCreateBoardDialog();
    const { redirectToBoard } = useRedirectToBoard();

    return (
        <Menu>
            {org.all_boards
                .filter(board => !board.archived_at)
                .sort((a, b) => (a.display_name < b.display_name ? -1 : 1))
                .map(board => {
                    const boardLink = board
                        ? buildBoardUrl({
                              boardSlug: board.slug,
                              vanity: {
                                  boardDisplayName: board.display_name,
                              },
                          }).pathname
                        : "/";

                    return (
                        <MenuItem
                            key={board.id}
                            text={
                                <div className={styles.boardDisplayName}>
                                    {board.display_name}
                                    {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                                        <Icon
                                            icon="lock"
                                            iconSet="c9r"
                                            iconSize={10.5}
                                            strokeWidthAbsolute={1.5}
                                        />
                                    ) : null}
                                </div>
                            }
                            to={boardLink}
                            instrumentation={{
                                elementName: "navbar.boards_menu.board",
                                eventData: {
                                    boardId: board.id,
                                },
                            }}
                        />
                    );
                })}
            <MenuDivider />
            <MenuItem
                icon={<Icon icon="spaceFramePlus" iconSet="c9r" iconSize={18} />}
                onClick={() => createBoardDialog.openWithProps({ onCreate: redirectToBoard })}
                text="Create a workspace"
                instrumentation={{
                    elementName: "navbar.boards_menu.new",
                }}
            />
        </Menu>
    );
}

type BoardPickerDisplayProps = {
    className?: string;
    currentBoard: BoardPicker_orgFragment["all_boards"][number];
    org: BoardPicker_orgFragment;
};

function BoardPickerDisplay({ className, currentBoard, org }: BoardPickerDisplayProps) {
    const [isOpen, setIsOpen] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const handleOnClick = () => setIsOpen(!isOpen);

    return (
        <span className={styles.boardPicker}>
            <div
                ref={targetRef}
                className={classNames(styles.popoverTarget, isOpen && styles.popoverOpen)}
            >
                <BoardText
                    boardAccessType={currentBoard.access_type as CommonEnumValue<"BoardAccessType">}
                    boardName={currentBoard.display_name}
                    onClick={handleOnClick}
                />
                <MenuPopover
                    content={<BoardSelectorMenu org={org} />}
                    placement="bottom-end"
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [17, 8],
                            },
                        },
                        flip: {
                            // If flip is enabled, then if a team has a very long board name
                            // and a short board name, the menu will be very wide (due to the former)
                            // but bottom-end alignment will start pretty far left on the screen
                            // when the short board name is selected (due to the latter). In that
                            // scenario, the popover will flip, causing the offset alignment above
                            // to no longer be correct.
                            // See ticket #997 for an example.
                            enabled: false,
                        },
                    }}
                    isOpen={isOpen}
                    onInteraction={(next, e) => {
                        if (e && e.target instanceof Element) {
                            // If this check is not in place, onInteraction runs when the popover is closed
                            // via the board header and sets the state back to open.
                            if (!targetRef.current?.contains(e.target)) {
                                setIsOpen(next);
                            }
                        }
                    }}
                >
                    <BorderButton
                        className={classNames(className, styles.boardPickerChevron)}
                        minimal
                        onClick={handleOnClick}
                        content={
                            <Icon
                                icon="chevron-down"
                                iconSet="lucide"
                                iconSize={14}
                                strokeWidth={2}
                            />
                        }
                        instrumentation={null}
                    />
                </MenuPopover>
            </div>
        </span>
    );
}

export type BoardPickerProps = {
    className?: string;
    org: FragmentType<typeof fragments.org>;
};

export function BoardPicker({ className, org: _orgFragment }: BoardPickerProps) {
    const org = getFragmentData(fragments.org, _orgFragment);
    const currentBoardId = useRecoilValue(currentBoardIdState);
    const currentBoard = org.all_boards.find(b => b.id === currentBoardId);

    return currentBoard ? (
        <BoardPickerDisplay className={className} currentBoard={currentBoard} org={org} />
    ) : null;
}

BoardPicker.Display = BoardPickerDisplay;
