import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { DbColumnTypes } from "c9r-common";

import { useMaybeCurrentIdentity, useMaybeCurrentUser } from "AppState";
import { EnumValue, Enums } from "lib/Enums";
import { tryUpdateCache } from "lib/apollo/tryUpdateCache";
import { gql } from "lib/graphql/__generated__";

const mutations = {
    shallowUpdateIdentityAppState: gql(/* GraphQL */ `
        mutation ShallowUpdateIdentityAppState($identityId: uuid!, $partialAppState: jsonb!) {
            update_identities_by_pk(
                pk_columns: { id: $identityId }
                _append: { app_state: $partialAppState }
            ) {
                id
                app_state
            }
        }
    `),

    shallowUpdateUserAppState: gql(/* GraphQL */ `
        mutation ShallowUpdateUserAppState($userId: Int!, $partialAppState: jsonb!) {
            update_users_by_pk(
                pk_columns: { id: $userId }
                _append: { app_state: $partialAppState }
            ) {
                id
                app_state
            }
        }
    `),
};

export function usePersistedAppState() {
    const currentIdentity = useMaybeCurrentIdentity();
    const currentUser = useMaybeCurrentUser();
    const [identityMutation] = useMutation(mutations.shallowUpdateIdentityAppState);
    const [userMutation] = useMutation(mutations.shallowUpdateUserAppState);

    const shallowUpdateIdentityAppState = useCallback(
        async (
            partialAppState: Partial<DbColumnTypes.IdentitiesAppState>,
            options?: { apiRoleType?: EnumValue<"ApiRoleType"> }
        ) => {
            if (!currentIdentity?.id || currentIdentity?.isReadOnly) {
                return;
            }

            await identityMutation({
                variables: {
                    partialAppState,
                    identityId: currentIdentity.id,
                },
                context: {
                    apiRoleType: options?.apiRoleType ?? Enums.ApiRoleType.IDENTITY,
                },
                optimisticResponse: {
                    update_identities_by_pk: {
                        __typename: "identities",
                        id: currentIdentity.id,
                        app_state: {}, // Optimistic update handled below
                    },
                },
                update: tryUpdateCache((cache, result) => {
                    const updatedIdentity = result.data?.update_identities_by_pk;

                    if (!updatedIdentity) {
                        return;
                    }

                    const fragment = gql(/* GraphQL */ `
                        fragment UpdateIdentityAppStateCacheUpdate on users {
                            id
                            app_state
                        }
                    `);

                    const cachedIdentity = cache.readFragment({
                        id: `identities:${updatedIdentity.id}`,
                        fragment,
                    });

                    if (!cachedIdentity) {
                        return;
                    }

                    cache.writeFragment({
                        id: `identities:${updatedIdentity.id}`,
                        fragment,
                        data: {
                            ...cachedIdentity,
                            app_state: {
                                ...updatedIdentity.app_state,
                                ...partialAppState,
                            },
                        },
                    });
                }),
            });
        },
        [currentIdentity?.id, currentIdentity?.isReadOnly, identityMutation]
    );

    const shallowUpdateUserAppState = useCallback(
        async (
            partialAppState: Partial<DbColumnTypes.UsersAppState>,
            options?: { apiRoleType?: EnumValue<"ApiRoleType"> }
        ) => {
            if (!currentUser?.id || currentUser.isReadOnly) {
                return;
            }

            await userMutation({
                variables: {
                    partialAppState,
                    userId: currentUser.id,
                },
                context: {
                    apiRoleType: options?.apiRoleType,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        app_state: {}, // Optimistic update handled below
                    },
                },
                update: tryUpdateCache((cache, result) => {
                    const updatedUser = result.data?.update_users_by_pk;

                    if (!updatedUser) {
                        return;
                    }

                    const fragment = gql(/* GraphQL */ `
                        fragment UpdateUserAppStateCacheUpdate on users {
                            id
                            app_state
                        }
                    `);

                    const cachedUser = cache.readFragment({
                        id: `users:${updatedUser.id}`,
                        fragment,
                    });

                    if (!cachedUser) {
                        return;
                    }

                    cache.writeFragment({
                        id: `users:${updatedUser.id}`,
                        fragment,
                        data: {
                            ...cachedUser,
                            app_state: {
                                ...updatedUser.app_state,
                                ...partialAppState,
                            },
                        },
                    });
                }),
            });
        },
        [currentUser?.id, currentUser?.isReadOnly, userMutation]
    );

    return { shallowUpdateIdentityAppState, shallowUpdateUserAppState };
}
