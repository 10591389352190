import { useCallback } from "react";

import { Config } from "Config";
import { Enums } from "lib/Enums";
import { useOAuth } from "lib/OAuth";
import { Storage } from "lib/Storage";

const credentialsStorageKey = "trello.credentials";

class TrelloCredentials {
    static clearToken() {
        Storage.Local.removeItem(credentialsStorageKey);
    }

    static getToken() {
        const credentials = Storage.Local.getItem(credentialsStorageKey);

        if (
            credentials &&
            credentials.expiresAt > Date.now() + Config.trello.tokenPreExpirationInvalidationMs
        ) {
            return credentials.token;
        }

        return null;
    }

    static saveToken({ token, expiresAt }: { token: string; expiresAt: number }) {
        Storage.Local.setItem("trello.credentials", { token, expiresAt });
    }
}

const useTrelloConnection = () => {
    const { buildAuthorizeUrl } = useOAuth();

    const connectTrello = useCallback(() => {
        window.location.href = buildAuthorizeUrl({ key: Enums.OAuthKey.TRELLO });
    }, [buildAuthorizeUrl]);

    const disconnectTrello = useCallback(async () => {
        TrelloCredentials.clearToken();
    }, []);

    const saveTrelloToken = useCallback((token: string) => {
        if (!token) {
            return;
        }

        TrelloCredentials.saveToken({
            token,
            expiresAt: Date.now() + Config.trello.tokenExpirationMs,
        });
    }, []);

    return { connectTrello, disconnectTrello, saveTrelloToken };
};

export { useTrelloConnection, TrelloCredentials };
