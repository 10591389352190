import React from "react";

import classNames from "classnames";

import styles from "./MenuHeader.module.scss";

export type MenuHeaderProps = {
    children?: React.ReactNode;
    className?: string;
};

export function MenuHeader({ children, className }: MenuHeaderProps) {
    return <li className={classNames(className, styles.menuHeader)}>{children}</li>;
}
