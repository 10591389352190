import React, { ReactNode } from "react";

import { CommonEnums, DbColumnTypes, TSizeScheme, TicketSizes } from "c9r-common";
import { useRecoilValue } from "recoil";

import { integrationsSetupStatusState } from "AppState";
import { Config } from "Config";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Toggle, ToggleProps } from "components/ui/core/Toggle";

import styles from "./BoardSettings.module.scss";

type TCode = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.CODE];
type TDueDates = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.DUE_DATES];
type TSizes = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.SIZES];

type BoardSettingsProps = {
    code: TCode;
    dueDates: TDueDates;
    sizes: TSizes;
    onCodeChange: ({ code }: { code: TCode }) => void;
    onDueDatesChange: ({ dueDates }: { dueDates: TDueDates }) => void;
    onSizesChange: ({ sizes }: { sizes: TSizes }) => void;
};

export function BoardSettings({
    code,
    dueDates,
    sizes,
    onCodeChange,
    onDueDatesChange,
    onSizesChange,
}: BoardSettingsProps) {
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);

    return (
        <div className={styles.settings}>
            <DueDatesSetting dueDates={dueDates} onDueDatesChange={onDueDatesChange} />
            <SizesSetting sizes={sizes} onSizesChange={onSizesChange} />
            {integrationsSetupStatus.github ? (
                <CodeSetting code={code} onCodeChange={onCodeChange} />
            ) : null}
        </div>
    );
}

type CodeSettingProps = {
    code: BoardSettingsProps["code"];
    onCodeChange: BoardSettingsProps["onCodeChange"];
};

function CodeSetting({ code, onCodeChange }: CodeSettingProps) {
    const displayText = "Software development";

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
        onCodeChange({ code: { enabled: e.target.checked } });

    const controls = (
        <BoardSettingToggle
            checked={code?.enabled}
            instrumentation={null}
            label={displayText}
            onChange={handleToggle}
        />
    );

    const caption = (
        <>
            Automatically link GitHub pull requests to topics, highlight stuck PRs, and more.{" "}
            <a href={Config.urls.docs.github} target="_blank" rel="noreferrer">
                Learn more.
            </a>
        </>
    );

    return <BoardSetting caption={caption} controls={controls} />;
}

type DueDatesSettingProps = {
    dueDates: BoardSettingsProps["dueDates"];
    onDueDatesChange: BoardSettingsProps["onDueDatesChange"];
};

function DueDatesSetting({ dueDates, onDueDatesChange }: DueDatesSettingProps) {
    const displayText = "Due dates";

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
        onDueDatesChange({ dueDates: { enabled: e.target.checked } });

    const controls = (
        <BoardSettingToggle
            checked={dueDates?.enabled}
            instrumentation={null}
            label={displayText}
            onChange={handleToggle}
        />
    );

    const caption = (
        <>
            Track when topics and tasks are due, and see them arranged by date in your personal
            planner{" "}
            <a href={Config.urls.docs.dueDates} target="_blank" rel="noreferrer">
                Learn more.
            </a>
        </>
    );

    return <BoardSetting caption={caption} controls={controls} />;
}

type SizesSettingProps = {
    sizes: BoardSettingsProps["sizes"];
    onSizesChange: BoardSettingsProps["onSizesChange"];
};

function SizesSetting({ sizes, onSizesChange }: SizesSettingProps) {
    const displayText = "Sizes/estimates";

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
        onSizesChange({
            sizes: {
                enabled: e.target.checked,
                scheme: sizes?.scheme ?? TicketSizes.DefaultTicketSizeScheme,
            },
        });

    const handleSelectScheme = ({ scheme }: { scheme: TSizeScheme }) =>
        onSizesChange({ sizes: { enabled: true, scheme } });

    const controls = (
        <div className={styles.sizesSettingControls}>
            <BoardSettingToggle
                checked={sizes?.enabled}
                instrumentation={null}
                label={displayText}
                onChange={handleToggle}
            />
            {sizes?.enabled ? (
                <MenuPopover
                    content={
                        <Menu>
                            {TicketSizes.TicketSizeSchemeOptions.map(
                                ({ scheme, displayText: schemeDisplayText }) => (
                                    <MenuItem
                                        instrumentation={null}
                                        key={scheme.unit}
                                        onClick={() => handleSelectScheme({ scheme })}
                                        text={schemeDisplayText}
                                    />
                                )
                            )}
                        </Menu>
                    }
                    fill
                    minimal
                    placement="bottom-start"
                    targetClassName={styles.sizeSchemeMenuPopoverTarget}
                >
                    <DropdownButton
                        className={styles.sizeSchemeButton}
                        flushLeft
                        iconSize={12}
                        instrumentation={null}
                        minimal
                        text={
                            TicketSizes.TicketSizeSchemeOptions.find(
                                ({ scheme: { unit } }) => unit === sizes.scheme.unit
                            )?.displayText
                        }
                        tighter
                    />
                </MenuPopover>
            ) : null}
        </div>
    );

    const caption = (
        <>
            Track how much work a topic is expected to entail.{" "}
            <a href={Config.urls.docs.sizing} target="_blank" rel="noreferrer">
                Learn more.
            </a>
        </>
    );

    return <BoardSetting caption={caption} controls={controls} />;
}

type BoardSettingProps = {
    caption: ReactNode;
    controls: ReactNode;
};

function BoardSetting({ caption, controls }: BoardSettingProps) {
    return (
        <div className={styles.setting}>
            <div className={styles.controls}>{controls}</div>
            <div className={styles.caption}>{caption}</div>
        </div>
    );
}

type BoardSettingToggleProps = ToggleProps;

function BoardSettingToggle({ ...toggleProps }: BoardSettingToggleProps) {
    return <Toggle alignment="left" long loose {...toggleProps} />;
}
