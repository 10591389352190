import React, { useEffect, useLayoutEffect } from "react";

import { Redirect, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { loadingState, useSetIsAppReady, useSetIsViewReady } from "components/loading/Loading";
import { FullPageModal } from "components/shared/FullPageModal";
import { useCurrentIdentity } from "contexts/IdentityContext";
import { useCurrentUser } from "contexts/UserContext";
import { useOnboardingWelcomeDialog } from "dialogs/OnboardingWelcomeDialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { isUserOnboarding } from "lib/Helpers";
import { usePersistedAppState } from "lib/PersistedAppState";
import { useRouteParams } from "lib/Routing";
import { RoutePathType, RoutePatternsByPathType } from "lib/Urls";
import { isUserIdInAccessToken, waitForAccessToken } from "lib/auth/AccessToken";
import { useRefreshAuth0AccessToken } from "lib/auth/Auth0AccessTokenManager";
import { BoardPage } from "views/board/BoardPage";
import { NotFoundView } from "views/error/NotFoundView";
import { AppLayout } from "views/layouts/app/AppLayout";
import { DelayedRedirectView } from "views/misc/DelayedRedirectView";
import { SearchView } from "views/search/SearchView";
import { Settings } from "views/settings/Settings";
import { DetailView } from "views/ticketDetail/DetailView";
import { UserView } from "views/user/UserView";
import { NoBoardsZeroStateView } from "views/zeroStates/NoBoardsZeroStateView";

import { AppRoute } from "./AppRoute";
import { DefaultBoardRedirect } from "./redirects/internal/DefaultBoardRedirect";

function useMaybeOpenOnboardingWelcomeDialog() {
    const currentUser = useCurrentUser();
    const { isViewReady } = useRecoilValue(loadingState);
    const { isFeatureEnabled } = useFeatureFlags();
    const { shallowUpdateUserAppState } = usePersistedAppState();
    const dialog = useOnboardingWelcomeDialog();

    const isOnboardingFeatureEnabled = isFeatureEnabled({ feature: Enums.Feature.ONBOARDING });
    const {
        onboardingWelcomeDialogViewedAt,
        onboardingWelcomeDialogExempt,
    } = currentUser.app_state;

    useEffect(() => {
        if (!isOnboardingFeatureEnabled) {
            return;
        }

        // Ensure the dialog doesn't appear long before the actual content is visible.
        if (!isViewReady) {
            return;
        }

        if (!onboardingWelcomeDialogViewedAt && !onboardingWelcomeDialogExempt) {
            dialog.openWithProps({
                isUserOrgProvisioner:
                    currentUser.org.provisioned_by_identity_id === currentUser.identity.id,
            });

            void shallowUpdateUserAppState({
                onboardingWelcomeDialogViewedAt: new Date(Date.now()).toISOString(),
            });
        }
    }, [
        currentUser.org.provisioned_by_identity_id,
        currentUser.identity.id,
        dialog,
        isOnboardingFeatureEnabled,
        isViewReady,
        onboardingWelcomeDialogViewedAt,
        onboardingWelcomeDialogExempt,
        shallowUpdateUserAppState,
    ]);
}

function OrgRoutesSwitch() {
    useMaybeOpenOnboardingWelcomeDialog();

    return (
        <Switch>
            <AppRoute exact path={RoutePatternsByPathType[RoutePathType.ORG]}>
                <DefaultBoardRedirect />
            </AppRoute>

            {/* Settings */}
            <AppRoute
                appIsReady
                path={RoutePatternsByPathType[RoutePathType.SETTINGS]}
                requireOnline
            >
                <FullPageModal>
                    <Settings />
                </FullPageModal>
            </AppRoute>

            {/* Search */}
            <AppRoute appIsReady path={RoutePatternsByPathType[RoutePathType.SEARCH]}>
                <FullPageModal>
                    <SearchView />
                </FullPageModal>
            </AppRoute>

            {/* Main app layout/surface */}
            <AppRoute>
                <AppLayout>
                    <Switch>
                        <AppRoute appIsReady path={RoutePatternsByPathType[RoutePathType.SETUP]}>
                            <NoBoardsZeroStateView />
                        </AppRoute>

                        <AppRoute
                            appIsReady
                            path={RoutePatternsByPathType[RoutePathType.WORKSPACE]}
                        >
                            <BoardPage />
                        </AppRoute>

                        <AppRoute appIsReady path={RoutePatternsByPathType[RoutePathType.TOPIC]}>
                            <DetailView />
                        </AppRoute>

                        <AppRoute appIsReady path={RoutePatternsByPathType[RoutePathType.USER]}>
                            <UserView />
                        </AppRoute>

                        {/* Catchall route */}
                        <AppRoute appIsReady component={NotFoundView} />
                    </Switch>
                </AppLayout>
            </AppRoute>
        </Switch>
    );
}

/**
 * Route handling for all routes under a specific org.
 */
export function OrgRoutes() {
    const currentIdentity = useCurrentIdentity();
    const currentUser = useCurrentUser();
    const setIsAppReady = useSetIsAppReady();
    const setIsViewReady = useSetIsViewReady();
    const { isFeatureEnabled } = useFeatureFlags();

    const { currentOrgSlug } = useRouteParams<{ currentOrgSlug: string }>();
    const { refreshAccessToken } = useRefreshAuth0AccessToken();
    const isCurrentOrg = currentUser.org.slug === currentOrgSlug;
    const requiredUser = currentIdentity.users.find(u => u.org.slug === currentOrgSlug);
    const isOrgFound = !!requiredUser;
    const isNewAccessTokenRequired = !isCurrentOrg && isOrgFound;

    useLayoutEffect(() => {
        (async () => {
            if (isNewAccessTokenRequired) {
                setIsViewReady(false);
                setIsAppReady(false);

                await refreshAccessToken({ ignoreCache: true, preferredUserId: requiredUser.id });
                await waitForAccessToken({
                    predicates: [() => isUserIdInAccessToken({ userId: requiredUser.id })],
                });

                setIsAppReady(true);
            }
        })();
    }, [
        currentOrgSlug,
        isNewAccessTokenRequired,
        refreshAccessToken,
        requiredUser?.id,
        setIsAppReady,
        setIsViewReady,
    ]);

    if (!isOrgFound) {
        return <NotFoundView />;
    }

    if (isNewAccessTokenRequired) {
        return null;
    }

    if (requiredUser.org.disabled_at) {
        return <DelayedRedirectView to="/" message="Your organization has been deleted." />;
    }

    if (isFeatureEnabled({ feature: Enums.Feature.ONBOARDING }) && isUserOnboarding(currentUser)) {
        return <Redirect to={`/organizations/setup?o=${currentOrgSlug}`} />;
    }

    return <OrgRoutesSwitch />;
}
