import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useArchiveTicket() {
    const { replicache } = useReplicache();

    const archiveTicket = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            await replicache.mutate.archiveTicket({ ticketId });
        },
        [replicache]
    );

    return { archiveTicket };
}

export function useUnarchiveTicket() {
    const { replicache } = useReplicache();

    const unarchiveTicket = useCallback(
        async ({ ticketId, stagePos }: { ticketId: string; stagePos: number }) => {
            await replicache.mutate.unarchiveTicket({ ticketId, stagePos });
        },
        [replicache]
    );

    return { unarchiveTicket };
}
