import { useCallback } from "react";

import { useCurrentUser } from "contexts/UserContext";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useUpdateLastViewedWorkAt() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation UpdateLastViewedWorkAt($userId: Int!) {
                update_users_by_pk(
                    pk_columns: { id: $userId }
                    _set: { last_viewed_work_at: "now()" }
                ) {
                    id
                    last_viewed_work_at
                }
            }
        `)
    );

    const updateLastViewedWorkAt = useCallback(
        async (mutationOptions?: MutationCallbackOptions) => {
            // We don't do an optimistic response here, because there may be clock skew between our
            // time and the server. If we're significantly off, that could affect the accuracy of
            // displaying the unread badge.
            return mutation({
                ...mutationOptions,
                variables: {
                    userId: currentUser.id,
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { updateLastViewedWorkAt };
}
