import { Log } from "lib/Log";

/**
 * Wrapper for all Apollo cache updates. An error when updating the cache should not be fatal.
 * Worst case, the UI will simply be delayed.
 */
export function tryUpdateCache<A extends any[], C extends (...args: A) => void>(cb: C) {
    return (...args: A) => {
        try {
            cb(...args);
        } catch (error) {
            Log.error("Failed to update cache", { error });
        }
    };
}
