import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { useQuery } from "@apollo/client";
import { CommonEnums, TrelloClient, ValueOf } from "c9r-common";

import { Config } from "Config";
import { ImportNotes } from "components/shared/ImportNotes";
import { SupportMailto } from "components/shared/SupportMailto";
import { ProgressBar } from "components/ui/common/ProgressBar";
import { ProgressText } from "components/ui/common/ProgressText";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog, useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { Storage } from "lib/Storage";
import { useUrlBuilders } from "lib/Urls";
import { gql } from "lib/graphql/__generated__";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { useImportData } from "lib/import/ImportData";
import { TrelloCredentials, useTrelloConnection } from "lib/import/trello/TrelloConnection";

import styles from "./TrelloImport.module.scss";

type TrelloBoard = { id: string; name: string };

function TrelloImportProgress({
    importId,
    trelloBoard,
    onComplete,
    onFailure,
}: {
    importId: number;
    trelloBoard: TrelloBoard;
    onComplete: ({ importRecord, newBoard }: { importRecord: any; newBoard: any }) => void;
    onFailure: () => void;
}) {
    const pollIntervalMs = 1300;
    const [percentComplete, setPercentComplete] = useState(0);

    // To monitor import progress, could use a subscription, but a polled query is probably better
    // in this case since we know the data will be changing.
    const { data } = useQuery(TrelloImportProgress.queries.import, {
        variables: { importId: importId || 0 },
        pollInterval: pollIntervalMs,
        fetchPolicy: "no-cache",
    });

    const importRecord = data?.import;

    // The import doesn't itself start tracking progress until it's created and begins to be
    // processed. But before that, the data is fetched. Fudge the progress from fetching
    // by just incrementing the percent complete on a timer up to this value.
    const fakeFetchPercentComplete = 25;

    useEffect(() => {
        if (importRecord?.job_status === CommonEnums.JobStatus.COMPLETE) {
            const newBoard = importRecord.boards[0];

            // Give the progress bar a chance to show 100%.
            setTimeout(() => onComplete({ importRecord, newBoard }), 600);
        }
    }, [importRecord, onComplete]);

    useEffect(() => {
        if (importRecord?.job_status === CommonEnums.JobStatus.FAILED) {
            onFailure();
        }
    }, [importRecord, onFailure]);

    useInterval(() => {
        if (importRecord) {
            return;
        }

        setPercentComplete(
            prev => prev + (fakeFetchPercentComplete - prev) / (6 + 2 * Math.random())
        );
    }, pollIntervalMs);

    useEffect(() => {
        if (!importRecord) {
            return;
        }

        if (importRecord.job_status === CommonEnums.JobStatus.COMPLETE) {
            setPercentComplete(100);
        } else {
            setPercentComplete(
                fakeFetchPercentComplete +
                    (importRecord.est_pct_complete ?? 0) * (100 - fakeFetchPercentComplete)
            );
        }
    }, [importRecord]);

    return (
        <>
            <h4>Importing "{trelloBoard.name}"</h4>
            <span className={styles.importPctComplete}>{Math.round(percentComplete)}%</span>
            <ProgressBar value={percentComplete} animate fill />
        </>
    );
}

function TrelloImportSuccess({
    importNotes,
    trelloBoard,
}: {
    importNotes?: { items: string[] } | null;
    trelloBoard: TrelloBoard;
}) {
    const [showNotes, setShowNotes] = useState(false);

    if (!importNotes) {
        return (
            <>
                <h4>Success!</h4>
                <p>Import of "{trelloBoard.name}" completed successfully.</p>
            </>
        );
    }

    return (
        <>
            <h4>Success!</h4>
            <p>
                Import of "{trelloBoard.name}" completed successfully, with{" "}
                {importNotes.items.length.toLocaleString()} notes.
            </p>
            <p>
                <TextButton
                    link
                    text={showNotes ? "Hide import notes" : "Show import notes"}
                    onClick={() => setShowNotes(prev => !prev)}
                    instrumentation={{
                        elementName: "trello_import.show_notes_btn",
                        eventData: { showNotes: !showNotes },
                    }}
                />
            </p>
            {showNotes ? (
                <p>
                    <ImportNotes className={styles.importNotes} importNotes={importNotes} />
                </p>
            ) : null}
        </>
    );
}

TrelloImportProgress.queries = {
    import: gql(/* GraphQL */ `
        query TrelloImportProgress($importId: bigint!) {
            import: imports_by_pk(id: $importId) {
                id
                est_pct_complete
                notes
                job_status

                boards {
                    id
                    display_name
                    slug
                }
            }
        }
    `),
};

type TrelloImportDialogProps = { isOpen: boolean; onClose?: () => void };

function TrelloImportDialog({ isOpen, onClose }: TrelloImportDialogProps) {
    const currentUser = useCurrentUser();
    const prefetchQuery = usePrefetchQuery();
    const { history } = useHistory();
    const { buildBoardUrl } = useUrlBuilders();
    const { importData } = useImportData();

    // TODO: This could really be a discriminated union type, enforcing the valid combinations
    // of screen and the other fields.
    type State = {
        screen: ValueOf<typeof TrelloImportDialog.Screen>;
        importId: any;
        didPrefetchBoard?: boolean;
        importRecord?: any;
        newBoard: any;
        trelloBoards?: TrelloBoard[] | null;
        trelloBoardId?: TrelloBoard["id"] | null;
    };

    const [state, dispatch] = useReducer(
        (prev: State, action: any): State => {
            switch (action.type) {
                case "reset":
                    return {
                        screen: TrelloImportDialog.Screen.LOADING,
                        importId: null,
                        newBoard: null,
                        trelloBoards: null,
                        trelloBoardId: null,
                    };
                case "fetched_trello_boards":
                    if (!action.trelloBoards.length) {
                        return {
                            ...prev,
                            screen: TrelloImportDialog.Screen.NO_BOARDS,
                        };
                    }

                    return {
                        ...prev,
                        trelloBoards: action.trelloBoards,
                        trelloBoardId: action.trelloBoards[0].id,
                        screen: TrelloImportDialog.Screen.SELECT_BOARD,
                    };
                case "selected_board":
                    return {
                        ...prev,
                        trelloBoardId: action.trelloBoardId,
                    };
                case "started_fetch":
                    return {
                        ...prev,
                        screen: TrelloImportDialog.Screen.FETCHING_IMPORT_DATA,
                    };
                case "started_import":
                    return {
                        ...prev,
                        importId: action.importId,
                        screen: TrelloImportDialog.Screen.IMPORTING,
                    };
                case "import_complete":
                    return {
                        ...prev,
                        importRecord: action.importRecord,
                        newBoard: action.newBoard,
                        screen: TrelloImportDialog.Screen.SUCCESS,
                    };
                case "prefetched_board":
                    return {
                        ...prev,
                        didPrefetchBoard: true,
                    };
                case "error":
                    return {
                        screen: TrelloImportDialog.Screen.ERROR,
                        importId: null,
                        newBoard: null,
                        didPrefetchBoard: false,
                        trelloBoards: null,
                        trelloBoardId: null,
                    };
                default:
                    throw new Error();
            }
        },
        {
            screen: TrelloImportDialog.Screen.LOADING,
            importId: null,
            newBoard: null,
            trelloBoards: null,
            trelloBoardId: null,
        }
    );

    const trelloClient = useMemo(
        () =>
            new TrelloClient({
                appKey: Config.trello.appKey,
                token: TrelloCredentials.getToken(),
            }),
        []
    );

    useEffect(() => {
        if (isOpen) {
            dispatch({ type: "reset" });
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        (async () => {
            try {
                const trelloBoards = await trelloClient.request({ path: "members/me/boards" });

                await new Promise<void>(resolve => {
                    setTimeout(() => resolve(), 1200);
                });

                dispatch({ type: "fetched_trello_boards", trelloBoards });
            } catch (error) {
                Log.error("Failed to fetch data for Trello import", { error });

                dispatch({ type: "error" });
            }
        })();
    }, [isOpen, trelloClient]);

    useEffect(() => {
        const maybePrefetchQuery = async () => {
            if (state.screen === TrelloImportDialog.Screen.SUCCESS) {
                // By refetching the board query using network-only, we ensure that the new board is
                // available before the app navigates to it.
                await prefetchQuery({
                    query: Queries.get({ component: "UserContext", name: "component" }),
                    variables: {
                        userId: currentUser.id,
                    },
                    fetchPolicy: "network-only",
                });
                dispatch({ type: "prefetched_board" });
            }
        };

        void maybePrefetchQuery();
    }, [currentUser, prefetchQuery, state.screen]);

    const canClose = !(
        state.screen === TrelloImportDialog.Screen.FETCHING_IMPORT_DATA ||
        state.screen === TrelloImportDialog.Screen.IMPORTING
    );
    const selectedTrelloBoard = state.trelloBoards?.find(
        trelloBoard => trelloBoard.id === state.trelloBoardId
    );

    return (
        <Dialog
            className={styles.dialog}
            title="Import from Trello"
            isOpen={isOpen}
            canEscapeKeyClose={canClose}
            canOutsideClickClose={canClose}
            isCloseButtonShown={canClose}
            onClose={onClose}
        >
            {(() => {
                switch (state.screen) {
                    case TrelloImportDialog.Screen.LOADING:
                        return (
                            <>
                                <Dialog.Body>
                                    <p>
                                        <ProgressText text="Loading your Trello boards" />
                                    </p>
                                </Dialog.Body>
                                <Dialog.Footer />
                            </>
                        );

                    case TrelloImportDialog.Screen.NO_BOARDS:
                        return (
                            <>
                                <Dialog.Body>
                                    <h4>
                                        It looks like you don't have access to any Trello boards.
                                    </h4>

                                    <p>
                                        Not what you expected? <SupportMailto text="Contact us" />
                                        and we can help.
                                    </p>
                                </Dialog.Body>

                                <Dialog.Footer />
                            </>
                        );

                    case TrelloImportDialog.Screen.SELECT_BOARD:
                        return (
                            <>
                                <Dialog.Body>
                                    <h4>Which Trello board would you like to import?</h4>
                                    <MenuPopover
                                        content={
                                            <Menu>
                                                {state.trelloBoards!.map(trelloBoard => (
                                                    <MenuItem
                                                        key={trelloBoard.id}
                                                        text={trelloBoard.name}
                                                        onClick={() =>
                                                            dispatch({
                                                                type: "selected_board",
                                                                trelloBoardId: trelloBoard.id,
                                                            })
                                                        }
                                                        instrumentation={null}
                                                    />
                                                ))}
                                            </Menu>
                                        }
                                        fill
                                        minimal
                                        placement="bottom-start"
                                    >
                                        <DropdownButton
                                            className={styles.trelloBoardPicker}
                                            text={selectedTrelloBoard?.name || "--"}
                                            fill
                                            underline
                                            instrumentation={null}
                                        />
                                    </MenuPopover>
                                </Dialog.Body>

                                <Dialog.Footer className={styles.dialogFooter}>
                                    <Dialog.FooterActions>
                                        <BorderButton
                                            content="Begin import"
                                            cta
                                            disabled={!state.trelloBoardId}
                                            onClick={async () => {
                                                dispatch({ type: "started_fetch" });

                                                const importId = await importData({
                                                    source: CommonEnums.ImportSource.TRELLO,
                                                    fetchParams: {
                                                        trelloBoardId: state.trelloBoardId!,
                                                    },
                                                });

                                                dispatch({ type: "started_import", importId });
                                            }}
                                            fill
                                            instrumentation={{
                                                elementName: "trello_import.start_btn",
                                            }}
                                        />
                                    </Dialog.FooterActions>
                                </Dialog.Footer>
                            </>
                        );

                    case TrelloImportDialog.Screen.FETCHING_IMPORT_DATA:
                    case TrelloImportDialog.Screen.IMPORTING:
                        return (
                            <>
                                <Dialog.Body>
                                    <TrelloImportProgress
                                        importId={state.importId}
                                        trelloBoard={selectedTrelloBoard!}
                                        onComplete={({ importRecord, newBoard }) =>
                                            dispatch({
                                                type: "import_complete",
                                                importRecord,
                                                newBoard,
                                            })
                                        }
                                        onFailure={() => dispatch({ type: "error" })}
                                    />
                                </Dialog.Body>
                                <Dialog.Footer />
                            </>
                        );

                    case TrelloImportDialog.Screen.SUCCESS:
                        return (
                            <>
                                <Dialog.Body>
                                    <TrelloImportSuccess
                                        importNotes={state.importRecord.notes}
                                        trelloBoard={selectedTrelloBoard!}
                                    />
                                </Dialog.Body>

                                <Dialog.Footer className={styles.dialogFooter}>
                                    <Dialog.FooterActions>
                                        <BorderButton
                                            content="View board"
                                            cta
                                            loading={!state.didPrefetchBoard}
                                            onClick={() => {
                                                setImmediate(() => {
                                                    history.push(
                                                        buildBoardUrl({
                                                            boardSlug: state.newBoard?.slug,
                                                            vanity: {
                                                                boardDisplayName:
                                                                    state.newBoard?.display_name,
                                                            },
                                                        }).pathname
                                                    );
                                                });
                                            }}
                                            instrumentation={{
                                                elementName: "trello_import.view_board_btn",
                                                eventData: { boardId: state.newBoard?.id },
                                            }}
                                        />
                                    </Dialog.FooterActions>
                                </Dialog.Footer>
                            </>
                        );

                    case TrelloImportDialog.Screen.ERROR:
                        return (
                            <>
                                <Dialog.Body>
                                    <p>Sorry, something went wrong. Our team is looking into it.</p>
                                </Dialog.Body>

                                <Dialog.Footer />
                            </>
                        );

                    default:
                        return null;
                }
            })()}
        </Dialog>
    );
}

TrelloImportDialog.Screen = {
    LOADING: "LOADING",
    SELECT_BOARD: "SELECT_BOARD",
    NO_BOARDS: "NO_BOARDS",
    FETCHING_IMPORT_DATA: "FETCHING_IMPORT_DATA",
    IMPORTING: "IMPORTING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
} as const;

const useTrelloImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();
    const { connectTrello } = useTrelloConnection();
    const storageKey = "trello.connection.lastAttemptedAt";

    useEffect(() => {
        if (Storage.Local.getNumber(storageKey) && TrelloCredentials.getToken()) {
            dialog.open();
        }

        Storage.Local.removeItem(storageKey);
    }, [dialog]);

    const trelloImportDialog = useMemo(
        () => <TrelloImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateTrelloImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        if (TrelloCredentials.getToken()) {
            dialog.open();
        } else {
            Storage.Local.setNumber(storageKey, Date.now());
            connectTrello();
        }
    }, [connectTrello, dialog, gateFeature]);

    return { initiateTrelloImport, trelloImportDialog };
};

export { useTrelloImport };
