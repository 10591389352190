import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./BlockerBadge.module.scss";

export type BlockerBadgeProps = {
    className?: string;
};

export function BlockerBadge({ className }: BlockerBadgeProps) {
    return (
        <div className={classNames(className, styles.blockerBadge)}>
            <Icon
                icon="x-octagon"
                iconSet="lucide"
                iconSize={16}
                strokeWeight={1.5}
                className={styles.blockerIcon}
            />
            <span className={styles.blockerText}>BLOCKER</span>
        </div>
    );
}
