import React from "react";

import { Popover2InteractionKind } from "@blueprintjs/popover2";
import { CommonEnums, authorizeTicketReference, sortStages } from "c9r-common";

import { UserSelect } from "components/ui/common/UserSelect";
import { DatePopoverContent } from "components/ui/core/DatePopover";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { MenuPopover, MenuPopoverProps } from "components/ui/core/MenuPopover";
import { Tooltip } from "components/ui/core/Tooltip";
import { Enums } from "lib/Enums";
import { divideAndFlattenGroups } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    TasklistItemMenuPopover_orgFragment,
    TasklistItemMenuPopover_taskFragment,
} from "lib/graphql/__generated__/graphql";
import { useUpdateTaskDueDate } from "lib/mutations";
import { isDefined } from "lib/types/guards";

import styles from "./TasklistItemMenuPopover.module.scss";
import { useDetailView } from "../context/DetailViewContext";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment TasklistItemMenuPopover_task on tasks {
            id
            assigned_to_user_id
            due_date
            task_type

            tasklist {
                id

                ticket {
                    id

                    board {
                        id
                        access_type

                        authorized_users {
                            user {
                                id
                                name
                                ...UserSelect_user
                            }
                        }
                    }
                }
            }
        }
    `),
    org: gql(/* GraphQL */ `
        fragment TasklistItemMenuPopover_org on orgs {
            id
            is_multi_board

            boards(where: { archived_at: { _is_null: true } }) {
                id
                access_type
                display_name

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    role
                    display_name
                    board_pos
                }
            }
        }
    `),
};

type BoardMenuItemProps = {
    board: TasklistItemMenuPopover_orgFragment["boards"][number];
    children: React.ReactNode;
    isMultiBoard: boolean;
} & Omit<MenuItemProps, "children" | "instrumentation" | "text">;

function BoardMenuItem({ board, children, isMultiBoard, ...menuItemProps }: BoardMenuItemProps) {
    return (
        <>
            {isMultiBoard ? (
                <MenuItem
                    text={
                        <div className={styles.boardDisplayName}>
                            {board.display_name}
                            {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                                <Icon
                                    icon="lock"
                                    iconSet="c9r"
                                    iconSize={10.5}
                                    strokeWidthAbsolute={1.5}
                                />
                            ) : null}
                        </div>
                    }
                    instrumentation={null}
                    {...menuItemProps}
                >
                    {children}
                </MenuItem>
            ) : (
                children
            )}
        </>
    );
}

type StageMenuItemProps = {
    instrumentation: MenuItemProps["instrumentation"];
    onClick: () => void;
    stage: TasklistItemMenuPopover_orgFragment["boards"][number]["stages"][number];
};

function StageMenuItem({ instrumentation, onClick, stage }: StageMenuItemProps) {
    return (
        <MenuItem text={stage.display_name} instrumentation={instrumentation} onClick={onClick} />
    );
}

type BoardAndStagesSubMenusProps = {
    onPromoteToTicket: (params: {
        boardId: string;
        stageId: string;
        taskId: string;
    }) => Promise<void>;
    org: TasklistItemMenuPopover_orgFragment;
    task: TasklistItemMenuPopover_taskFragment;
};

function BoardAndStagesSubMenus({ onPromoteToTicket, org, task }: BoardAndStagesSubMenusProps) {
    return (
        <>
            {org.boards
                .concat()
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map(board => {
                    const { isAuthorized, reason } = authorizeTicketReference({
                        ticketReference: {
                            context: CommonEnums.TicketReferenceContext.HIERARCHY,
                            originBoard: task.tasklist.ticket.board,
                            targetBoard: board,
                        },
                    });

                    return (
                        <Tooltip
                            key={board.id}
                            className={styles.menuItemWrapper}
                            content={reason ?? ""}
                            disabled={isAuthorized}
                            modifiers={{ offset: { enabled: false } }}
                            placement="left"
                            small
                        >
                            <BoardMenuItem
                                isMultiBoard={!!org.is_multi_board}
                                board={board}
                                disabled={!isAuthorized}
                            >
                                {board.stages
                                    .concat()
                                    .sort(sortStages())
                                    .map(stage => (
                                        <StageMenuItem
                                            key={stage.id}
                                            stage={stage}
                                            instrumentation={{
                                                elementName: "task.promote_btn",
                                                eventData: {
                                                    boardId: board.id,
                                                    stageId: stage.id,
                                                    taskId: task.id,
                                                },
                                            }}
                                            onClick={() =>
                                                onPromoteToTicket({
                                                    boardId: board.id,
                                                    stageId: stage.id,
                                                    taskId: task.id,
                                                })
                                            }
                                        />
                                    ))}
                            </BoardMenuItem>
                        </Tooltip>
                    );
                })}
        </>
    );
}

export type TasklistItemMenuPopoverProps = {
    children: React.ReactNode;
    onDelete: () => void;
    onPromoteToTicket: (params: {
        boardId: string;
        stageId: string;
        taskId: string;
    }) => Promise<void>;
    popoverProps: Omit<MenuPopoverProps, "children">;
    org: FragmentType<typeof fragments.org>;
    task: FragmentType<typeof fragments.task>;
};

export function TasklistItemMenuPopover({
    children,
    onDelete,
    onPromoteToTicket,
    popoverProps,
    org: _orgFragment,
    task: _taskFragment,
}: TasklistItemMenuPopoverProps) {
    const org = getFragmentData(fragments.org, _orgFragment);
    const task = getFragmentData(fragments.task, _taskFragment);
    const { setOrRemoveTicketTaskAssignee } = useDetailView();
    const { updateTaskDueDate } = useUpdateTaskDueDate();
    const authorizedUsers = task.tasklist.ticket.board.authorized_users.map(au => au.user);

    const handleDueDateChange = async (dueDate?: Date | null) => {
        await updateTaskDueDate({ taskId: task.id, dueDate: dueDate ?? null });
    };

    const handleAssigneeChange = async ({
        assignedToUserId,
    }: {
        assignedToUserId?: number | null;
    }) => {
        await setOrRemoveTicketTaskAssignee({ taskId: task.id, assignedToUserId });
    };

    const menuItems = divideAndFlattenGroups({
        itemGroups: [
            [
                task.task_type === CommonEnums.TaskType.TASK && !task.assigned_to_user_id ? (
                    // Normally a submenu is a child of the menu item. But here we make the menu item
                    // the target of what is effectively the submenu (the user selector), because
                    // UserSelect is itself a popover and MenuItem assumes it's children are not.
                    <UserSelect
                        key="user"
                        fill
                        interactionKind={Popover2InteractionKind.HOVER}
                        hoverCloseDelay={0}
                        getInstrumentation={user => ({
                            elementName: "task.task_menu.assignee_picker",
                            eventData: {
                                taskId: task.id,
                                userId: user?.id ?? null,
                            },
                        })}
                        placement="right-start"
                        onSelect={assignee => {
                            void handleAssigneeChange({
                                assignedToUserId: assignee?.id ?? null,
                            });
                        }}
                        users={authorizedUsers
                            .filter(isDefined)
                            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))}
                        makeFirstOptionCurrentUser
                        hideMenuItemIconMode={Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED}
                    >
                        <MenuItem
                            text="Assign to"
                            icon={<Icon icon="user-plus" iconSet="lucide" iconSize={18} />}
                            instrumentation={{
                                elementName: "task.task_menu.assign_user",
                            }}
                            showSubmenuChevron
                            // Normally a menu item, when appearing as a submenu item, should dismiss its
                            // popover (the menu). But since we're doing the reverse here, where the "submenu"
                            // user picker is in fact the parent, clicking the menu item should not
                            // dismiss it. The picker should remain open when clicking this menu item.
                            shouldDismissPopover={false}
                        />
                    </UserSelect>
                ) : null,

                task.task_type === CommonEnums.TaskType.TASK && !!task.assigned_to_user_id ? (
                    <MenuItem
                        text="Unassign"
                        icon={<Icon icon="user-minus" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "task.task_menu.unassign_user",
                        }}
                        onClick={() => handleAssigneeChange({ assignedToUserId: null })}
                    />
                ) : null,

                task.task_type === CommonEnums.TaskType.TASK && !task.due_date ? (
                    <MenuItem
                        text="Add due date"
                        icon={<Icon icon="calendar-plus" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "task.task_menu.add_due_date",
                        }}
                        popoverProps={{
                            placement: "right",
                        }}
                    >
                        <DatePopoverContent
                            key="date"
                            getInstrumentation={() => ({
                                elementName: "task.task_menu.due_date_picker",
                                eventData: {
                                    taskId: task.id,
                                },
                            })}
                            onSelect={handleDueDateChange}
                            selectedDate={undefined}
                        />
                    </MenuItem>
                ) : null,

                task.task_type === CommonEnums.TaskType.TASK && !!task.due_date ? (
                    <MenuItem
                        text="Remove due date"
                        icon={<Icon icon="calendar-minus" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "task.task_menu.remove_due_date",
                        }}
                        onClick={() => handleDueDateChange(null)}
                    />
                ) : null,
            ].filter(isDefined),
            [
                task.task_type === CommonEnums.TaskType.TASK ? (
                    <MenuItem
                        text="Promote to topic in"
                        icon={<Icon icon="file-up" iconSet="lucide" iconSize={18} />}
                        instrumentation={null}
                    >
                        <BoardAndStagesSubMenus
                            org={org}
                            onPromoteToTicket={onPromoteToTicket}
                            task={task}
                        />
                    </MenuItem>
                ) : null,
                <MenuItem
                    key="delete"
                    text={
                        task.task_type === CommonEnums.TaskType.CHILD_TICKET
                            ? "Detach child topic"
                            : "Delete"
                    }
                    icon={
                        <Icon
                            icon={
                                task.task_type === CommonEnums.TaskType.CHILD_TICKET ? "x" : "trash"
                            }
                            iconSet="lucide"
                            iconSize={18}
                        />
                    }
                    instrumentation={{
                        elementName: "task.task_menu.delete_task",
                        eventData: { taskId: task.id },
                    }}
                    onClick={onDelete}
                />,
            ].filter(isDefined),
        ],
        divider: i => <MenuDivider key={i} />,
    });

    return (
        <MenuPopover content={<Menu>{menuItems}</Menu>} {...popoverProps}>
            {children}
        </MenuPopover>
    );
}
