import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { useRecoilValue } from "recoil";

import { AppData } from "AppData";
import { useCurrentMinimalUser } from "contexts/UserContext";
import { Log } from "lib/Log";
import { ticketViewsLocalState, useTicketViewsLocal } from "lib/TicketViewsLocal";
import { useLiveQuery } from "lib/apollo/useLiveQuery";
import { gql } from "lib/graphql/__generated__";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

const queries = {
    component: gql(/* GraphQL */ `
        query TicketViews($userId: Int!, $ticketIds: [uuid!]!) {
            ticket_detail_views(
                where: { user_id: { _eq: $userId }, ticket_id: { _in: $ticketIds } }
            ) {
                ticket_id
                user_id
                last_viewed_at
            }
        }
    `),
};

export type TicketViewsInfoMap = {
    [ticketId: string]: { lastViewedAt: number | null };
};

export function useTicketViewsInfoMap({ ticketIds }: { ticketIds: string[] }): TicketViewsInfoMap {
    const currentMinimalUser = useCurrentMinimalUser();
    const componentQuery = useLiveQuery({
        query: queries.component,
        variables: {
            userId: currentMinimalUser.id,
            ticketIds,
        },
    });

    const ticketViewInfoByTicketIdLocal = useRecoilValue(ticketViewsLocalState);
    const ticketViewInfoByTicketIdServer = componentQuery.data
        ? Object.fromEntries(
              componentQuery.data.ticket_detail_views.map(tdv => [
                  tdv.ticket_id,
                  { lastViewedAt: new Date(tdv.last_viewed_at).getTime() },
              ])
          )
        : {};

    return Object.fromEntries(
        ticketIds.map(ticketId => [
            ticketId,
            {
                lastViewedAt:
                    ticketViewInfoByTicketIdLocal[ticketId]?.lastViewedAt ||
                    ticketViewInfoByTicketIdServer[ticketId]?.lastViewedAt
                        ? Math.max(
                              ticketViewInfoByTicketIdLocal[ticketId]?.lastViewedAt ?? 0,
                              ticketViewInfoByTicketIdServer[ticketId]?.lastViewedAt ?? 0
                          )
                        : null,
            },
        ])
    );
}

export function useRecordTicketView() {
    const apolloClient = useApolloClient();
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const { recordTicketViewLocally } = useTicketViewsLocal();

    const recordTicketView = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            if (!AppData.currentUser || AppData.currentUser.isReadOnly) {
                return;
            }

            try {
                await apolloClient.mutate({
                    mutation: gql(/* GraphQL */ `
                        mutation RecordTicketView($ticketId: uuid!) {
                            insert_ticket_detail_views_one(
                                object: { ticket_id: $ticketId, last_viewed_at: "now()" }
                                on_conflict: {
                                    constraint: ticket_detail_views_pkey
                                    update_columns: [last_viewed_at]
                                }
                            ) {
                                ticket_id
                                user_id
                                last_viewed_at

                                ticket {
                                    id

                                    ticket_detail_views {
                                        ticket_id
                                        user_id
                                        last_viewed_at
                                    }
                                }
                            }
                        }
                    `),
                    variables: { ticketId },
                });

                Log.debug("Recorded view for ticket", { ticketId });
            } catch (error) {
                Log.warn("Failed to record ticket view", { error, ticketId });
            }

            recordTicketViewLocally({ ticketId });
        },
        [apolloClient, recordTicketViewLocally]
    );

    const recordTicketViewByMergeRequestId = useCallback(
        async ({ mergeRequestId }: { mergeRequestId: string }) => {
            if (!AppData.currentUser || AppData.currentUser.isReadOnly) {
                return;
            }

            try {
                const args = {
                    query: gql(/* GraphQL */ `
                        query RecordTicketViewByMergeRequestId($mergeRequestId: uuid!) {
                            merge_request: merge_requests_by_pk(id: $mergeRequestId) {
                                id
                                tickets {
                                    ticket {
                                        id
                                    }
                                }
                            }
                        }
                    `),
                    variables: { mergeRequestId },
                };

                const tickets = (await replicacheGraphQLClient.readQuery(args))?.merge_request
                    ?.tickets;

                if (tickets) {
                    await Promise.all(
                        tickets.map(t => recordTicketView({ ticketId: t.ticket.id }))
                    );
                }
            } catch (error) {
                Log.warn("Failed to record ticket view for merge request", {
                    error,
                    mergeRequestId,
                });
            }
        },
        [recordTicketView, replicacheGraphQLClient]
    );

    return { recordTicketView, recordTicketViewByMergeRequestId };
}
