import commandScore from "command-score";

/**
 * Perform a fuzzy match comparing a query to a given text. This function is intended for
 * use cases where a user is typing commands in a command-style palette, and may have entered
 * only a portion of the command so far, possibly with typos.
 */
export function isFuzzyCommandMatch({
    query,
    text,
    threshold = 0.01,
}: {
    query: string;
    text: string;

    /** Value between 0 and 1. 1 is a perfect match, and 0 is no way it's a match. */
    threshold?: number;
}) {
    return commandScore(text, query) >= threshold;
}
