import React from "react";

import { IToastProps, Position, Toaster } from "@blueprintjs/core";

import { Icon, IconProps } from "components/ui/core/Icon";
import { Enums } from "lib/Enums";
import { Log } from "lib/Log";

import styles from "./AppToaster.module.scss";

const BlueprintToaster = Toaster.create({
    className: styles.appToaster,
    position: Position.TOP,
});

type ShowToastWithIntentArgs = {
    message: React.ReactNode;
    icon?: React.ReactElement<IconProps> | null;
    action?: IToastProps["action"];
    timeout?: IToastProps["timeout"];
};

export const AppToaster = {
    clear: BlueprintToaster.clear.bind(BlueprintToaster),
    dismiss: BlueprintToaster.dismiss.bind(BlueprintToaster),
    getToasts: BlueprintToaster.getToasts.bind(BlueprintToaster),
    show: BlueprintToaster.show.bind(BlueprintToaster),

    info: ({ message, icon, action, timeout }: ShowToastWithIntentArgs) =>
        BlueprintToaster.show({
            message,
            icon,
            className: Enums.BlueprintIntent.PRIMARY,
            action,
            timeout,
        }),

    success: ({ message, icon, action, timeout }: ShowToastWithIntentArgs) =>
        AppToaster.show({
            message,
            icon:
                icon === null
                    ? null
                    : icon ?? <Icon icon="checkCircle" iconSet="c9r" iconSize={24} />,
            className: Enums.BlueprintIntent.SUCCESS,
            action,
            timeout,
        }),

    danger: ({ message, icon, action, timeout }: ShowToastWithIntentArgs) => {
        return AppToaster.show({
            message,
            icon:
                icon === null
                    ? null
                    : icon ?? <Icon icon="alert-triangle" iconSet="lucide" iconSize={24} />,
            className: Enums.BlueprintIntent.DANGER,
            action,
            timeout,
        });
    },

    error: ({ message, icon, action, timeout }: ShowToastWithIntentArgs) => {
        Log.error("User received error toast", { toastMessage: message });

        return AppToaster.danger({ message, icon, action, timeout });
    },
};
