import React from "react";

import { CommonEnums, sortStages } from "c9r-common";
import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { useFilePicker } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useCreateTasklist } from "lib/mutations";

import styles from "./DetailContentPalette.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DetailContentPalette_ticket on tickets {
            id

            board {
                id

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    board_pos
                    deleted_at
                    display_name
                    role
                }
            }

            stage {
                id
                board_pos
                role
            }

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id
                deleted_at
            }
        }
    `),
};

export type DetailContentPaletteProps = {
    className?: string;
    handleAttachmentUpload: (files: File[]) => void;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function DetailContentPalette({
    className,
    handleAttachmentUpload,
    ticket: _ticketFragment,
}: DetailContentPaletteProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { createTasklist } = useCreateTasklist();
    const { openFilePicker } = useFilePicker();
    const stagesWithChecklists = ticket.board.stages
        .filter(
            s => !s.deleted_at && s.role === CommonEnums.StageRole.IMPLEMENTATION && s.board_pos
        )
        .sort(sortStages());
    const hasTasklist = !!ticket.tasklists.filter(tl => !tl.deleted_at).length;

    const handleCreateTasklist = async () => {
        const stage =
            stagesWithChecklists.find(s => s.board_pos! >= ticket.stage.board_pos!) ||
            stagesWithChecklists
                .concat()
                .reverse()
                .find(s => s.board_pos! <= ticket.stage.board_pos!)!;
        const stageId = stage.id;
        const title = hasTasklist ? "Untitled" : "Tasks";

        await createTasklist({
            ticketId: ticket.id,
            stageId,
            title,
        });
    };

    return (
        <div className={classNames(className, styles.palette)}>
            <Tooltip content="Attach a file" openOnTargetFocus={false} placement="bottom" small>
                <BorderButton
                    className={styles.button}
                    instrumentation={{
                        elementName: "ticket_detail.palette.attach_btn",
                    }}
                    content={<Icon icon="paperclip" iconSet="lucide" />}
                    minimal
                    onClick={() => {
                        openFilePicker({ multiple: true }, handleAttachmentUpload);
                    }}
                    small
                />
            </Tooltip>
            {stagesWithChecklists.length ? (
                <Tooltip
                    content="Add a checklist"
                    openOnTargetFocus={false}
                    placement="bottom"
                    small
                >
                    <BorderButton
                        className={styles.button}
                        instrumentation={{
                            elementName: "ticket_detail.palette.checklist_btn",
                        }}
                        content={<Icon icon="check-square" iconSet="lucide" />}
                        minimal
                        onClick={handleCreateTasklist}
                        small
                    />
                </Tooltip>
            ) : null}
        </div>
    );
}
