import React from "react";

import classNames from "classnames";

import styles from "./ImportNotes.module.scss";

export type ImportNotesProps = {
    className?: string;
    importNotes: { items: string[] };
};

export function ImportNotes({ className, importNotes }: ImportNotesProps) {
    // This assumes importNotes.type === "LIST". If in the future we have other formats,
    // we can render them differently here.

    return (
        <ul className={classNames(className, styles.importNotes)}>
            {importNotes.items.map(item => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );
}
