import React, { useCallback, useEffect, useRef, useState } from "react";

import { ApolloProvider, useApolloClient } from "@apollo/client";
import { IdToken } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AppData } from "AppData";
import { useMaybeCurrentUserId } from "AppState";
import { Config } from "Config";
import { Env } from "Env";
import { DemoIntro } from "components/demo/DemoIntro";
import { DemoOutroDialog } from "components/demo/DemoOutro";
import { DemoTourProvider } from "components/demo/DemoTour";
import { LoadingOverlay } from "components/loading/Loading";
import { EmailVerificationSync } from "components/misc/EmailVerificationSync";
import { useHeartbeatRecorder } from "components/misc/HeartbeatRecorder";
import { IntegrationsStatusMonitor } from "components/monitors/IntegrationsStatusMonitor";
import { useNetworkStatusMonitor } from "components/monitors/NetworkStatusMonitor";
import { TicketSearchIndex } from "components/search/TicketSearchIndex";
import { BoardAndStagesMenuContextProvider } from "components/shared/BoardAndStagesMenuContext";
import { AppToaster } from "components/ui/core/AppToaster";
import { HotspotProvider } from "components/ui/core/Hotspot";
import { Icon } from "components/ui/core/Icon";
import { IdentityProvider, useCurrentIdentity } from "contexts/IdentityContext";
import { MutationsProvider } from "contexts/MutationsContext";
import { TicketSearchProvider } from "contexts/TicketSearchContext";
import {
    Auth0UserProvider,
    DemoUserProvider,
    isFullCurrentUser,
    useCurrentMinimalUser,
    useCurrentUser,
} from "contexts/UserContext";
import { Dialogs } from "dialogs/Dialogs";
import { CssClasses } from "lib/Constants";
import { useDraftsSynchronization } from "lib/Drafts";
import { electronWindowState } from "lib/ElectronWindowState";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { Focus } from "lib/Focus";
import { isIdentityOnboarding } from "lib/Helpers";
import { useDocumentTitle, useIsTitleBarHidden } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import { Log } from "lib/Log";
import { useHistory, useLocation, useRouteMatch } from "lib/Routing";
import { setThemeClassName, useThemeManager } from "lib/Theming";
import { useRecordTicketView } from "lib/TicketViews";
import { TicketViewsLocalProvider } from "lib/TicketViewsLocal";
import { useTrackingUid } from "lib/Tracking";
import { RoutePathType, RoutePatternsByPathType } from "lib/Urls";
import { parsedUserAgent } from "lib/UserAgent";
import { useCreateApolloClient } from "lib/apollo/useCreateApolloClient";
import { isAccessTokenAvailableState } from "lib/auth/AccessToken";
import { useAuth0 } from "lib/auth/Auth0";
import { useAuth0AccessTokenManager } from "lib/auth/Auth0AccessTokenManager";
import {
    getCurrentDemoIdentityId,
    useDemoAccessTokenManager,
} from "lib/auth/DemoAccessTokenManager";
import { useIdentityCookieManager } from "lib/auth/IdentityCookieManager";
import { recordLoginHint } from "lib/auth/Login";
import { useHandleLogoutEvents } from "lib/auth/Logout";
import { gql } from "lib/graphql/__generated__";
import { ReplicacheProvider } from "lib/replicache/Context";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";
import { useVersioning } from "lib/versioning/useVersioning";
import { AppRoute } from "routing/AppRoute";
import { AuthRedirectRoutes } from "routing/AuthRedirectRoutes";
import { DebugRoutes } from "routing/DebugRoutes";
import { OrgRoutes } from "routing/OrgRoutes";
import { GitHubInstallationRedirect } from "routing/redirects/external/GitHubInstallationRedirect";
import { OAuthRedirect } from "routing/redirects/external/OAuthRedirect";
import {
    CurrentOrgRedirect,
    CurrentOrgSettingsRedirect,
} from "routing/redirects/internal/CurrentOrgRedirect";
import { EmailVerifiedRedirect } from "routing/redirects/internal/EmailVerifiedRedirect";
import { InviteRedirect } from "routing/redirects/internal/InviteRedirect";
import { JoinRedirect } from "routing/redirects/internal/JoinRedirect";
import { LoginRedirect } from "routing/redirects/internal/LoginRedirect";
import { LogoutRedirect } from "routing/redirects/internal/LogoutRedirect";
import { SignupRedirect } from "routing/redirects/internal/SignupRedirect";
import { ErrorBoundary } from "views/error/ErrorBoundary";
import { ErrorView } from "views/error/ErrorView";
import { NotFoundView } from "views/error/NotFoundView";
import { DesktopAppRedirectView } from "views/misc/DesktopAppRedirectView";
import { EmailVerificationView } from "views/misc/EmailVerificationView";
import { OnboardingNewOrgRedirect, OnboardingView } from "views/onboarding/OnboardingView";
import { OrganizationsView } from "views/organizations/OrganizationsView";

import styles from "./App.module.scss";

Sentry.init({
    dsn: Config.sentry.dsn ?? undefined,
    environment: Config.sentry.environment,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
});

Focus.init();

Log.addContext("ua", parsedUserAgent);
Log.info("App started");

const useLogRocket = () => {
    const didInit = useRef(false);
    const identity = useCurrentIdentity();
    const identityId = identity.id;
    const orgIds = identity.users.map(u => u.org_id);

    useEffect(() => {
        if (didInit.current) {
            return;
        }

        if (!Config.logrocket.enabled) {
            return;
        }

        const excludedIdentityIds = new Set(Config.logrocket.excludedIdentityIds);
        const excludedOrgIds = new Set(Config.logrocket.excludedOrgIds);

        if (excludedIdentityIds.has(identityId)) {
            return;
        }

        // This exclusion is slightly broader than it needs to be, because it will exclude all
        // sessions from an identity if *any* of their org users is in an excluded org.
        if (orgIds.some(orgId => excludedOrgIds.has(orgId))) {
            return;
        }

        didInit.current = true;

        const isDesktopApp = !!window.electron;

        LogRocket.init(Config.logrocket.projectId, {
            release: Env.commitSha ?? undefined,
            shouldDebugLog: false,
            // If we ever do enable network recording, by sure to sanitize the authorization header
            // in the request, and perhaps also data in the response.
            network: { isEnabled: false },
        });

        LogRocket.getSessionURL(sessionURL => {
            Sentry.configureScope(scope => {
                scope.setExtra("logRocketSessionURL", sessionURL);
            });
            Log.addContext("logRocketSessionURL", sessionURL);
        });

        LogRocket.track("AppStarted", { isDesktopApp });
    }, [identityId, orgIds]);
};

const useRecordLoginHint = () => {
    const identity = useCurrentIdentity();

    useEffect(() => {
        if (!identity.isReadOnly) {
            recordLoginHint({ emailAddress: identity.email_address });
        }
    }, [identity.email_address, identity.isReadOnly]);
};

const useRecordPageViews = () => {
    const { pathname } = useLocation();
    const { recordEvent } = useInstrumentation();

    useEffect(() => {
        void recordEvent({ eventType: Enums.InstrumentationEvent.PAGE_VIEW });
    }, [pathname, recordEvent]);
};

const useRecordTicketDetailViews = () => {
    const currentUser = useCurrentMinimalUser();
    const match = useRouteMatch<{ ticketSlug?: string }>(
        RoutePatternsByPathType[RoutePathType.TOPIC]
    );
    const apolloClient = useApolloClient();
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const { recordTicketView } = useRecordTicketView();

    const ticketSlug = match?.params.ticketSlug;

    useEffect(() => {
        if (ticketSlug) {
            (async () => {
                if (!currentUser.org_id) {
                    return;
                }

                const args = {
                    query: gql(/* GraphQL */ `
                        query RecordTicketDetailViewGetTicketIdBySlug(
                            $orgId: Int!
                            $slug: String!
                        ) {
                            tickets(where: { org_id: { _eq: $orgId }, slug: { _eq: $slug } }) {
                                id
                            }
                        }
                    `),
                    variables: {
                        orgId: currentUser.org_id,
                        slug: ticketSlug,
                    },
                };

                const tickets = (await replicacheGraphQLClient.readQuery(args))?.tickets;

                if (tickets?.length) {
                    await recordTicketView({ ticketId: tickets[0].id });
                }
            })();
        }
    }, [apolloClient, currentUser.org_id, recordTicketView, replicacheGraphQLClient, ticketSlug]);
};

type ContentProvidersProps = {
    children: React.ReactNode;
};

function ContentProviders({ children }: ContentProvidersProps) {
    const currentUser = useCurrentUser();

    return (
        <ReplicacheProvider>
            <MutationsProvider>
                <HotspotProvider>
                    <BoardAndStagesMenuContextProvider org={currentUser.org}>
                        <TicketSearchProvider>
                            <TicketViewsLocalProvider>{children}</TicketViewsLocalProvider>
                        </TicketSearchProvider>
                    </BoardAndStagesMenuContextProvider>
                </HotspotProvider>
            </MutationsProvider>
        </ReplicacheProvider>
    );
}

function AppContent() {
    const currentMinimalUser = useCurrentMinimalUser();

    useRecordTicketDetailViews();
    useHandleLogoutEvents();

    return (
        <>
            {isFullCurrentUser(currentMinimalUser) ? <TicketSearchIndex /> : null}
            {isFullCurrentUser(currentMinimalUser) ? <IntegrationsStatusMonitor /> : null}

            <Switch>
                <AppRoute exact path="/">
                    <CurrentOrgRedirect />
                </AppRoute>

                <AppRoute path="/settings">
                    <CurrentOrgSettingsRedirect />
                </AppRoute>

                <AppRoute path={RoutePatternsByPathType[RoutePathType.ORG]}>
                    <OrgRoutes />
                </AppRoute>

                <AppRoute appIsReady component={NotFoundView} />
            </Switch>

            {/* Only render dialogs here (not at a higher level) so that all of the "normal" app data, like the current user's org, are available. */}
            <Dialogs />
            {Config.demoUI.enabled ? <DemoOutroDialog /> : null}
        </>
    );
}

/**
 * Routes after we have a specific user, not just an identity. But note, the user we have may
 * not match the route they are trying to view! For example, a person may log in, accessed org A,
 * then gone back to the organization selection page and selected org B. The handling here must
 * switch to the user associated with org B.
 */
function Routes() {
    const { isFeatureEnabled } = useFeatureFlags();

    useDocumentTitle();
    useDraftsSynchronization();
    useHeartbeatRecorder();
    useThemeManager();
    useVersioning();
    useIdentityCookieManager();

    return (
        <div id={styles.appRoutesLayout} className={CssClasses.LAYOUT_ROOT}>
            <Switch>
                {/* Debug routes */}
                {isFeatureEnabled({ feature: Enums.Feature.DEBUG_ROUTES }) ? (
                    <AppRoute appIsReady path="/_debug">
                        <DebugRoutes />
                    </AppRoute>
                ) : null}

                <AppRoute path="/oauth/redirect">
                    <AuthRedirectRoutes />
                </AppRoute>

                <AppRoute path="/github_installation_redirect">
                    <GitHubInstallationRedirect
                        installationId={
                            new URLSearchParams(window.location.search).get("installation_id") ??
                            undefined
                        }
                    />
                </AppRoute>

                {/* The app "proper" */}
                <AppRoute>
                    <ContentProviders>
                        {Config.demoUI.enabled ? (
                            <DemoTourProvider>
                                <DemoIntro>
                                    <AppContent />
                                </DemoIntro>
                            </DemoTourProvider>
                        ) : (
                            <AppContent />
                        )}
                    </ContentProviders>
                </AppRoute>
            </Switch>
        </div>
    );
}

function Auth0UserWrapper() {
    const userId = useMaybeCurrentUserId();

    if (!userId) {
        return null;
    }

    return (
        <Auth0UserProvider userId={userId}>
            <Routes />
        </Auth0UserProvider>
    );
}

function DemoUserWrapper() {
    const userId = useMaybeCurrentUserId();

    if (!userId) {
        return null;
    }

    return (
        <DemoUserProvider userId={userId}>
            <Routes />
        </DemoUserProvider>
    );
}

/**
 * Routes for an identity who is logged in but hasn't yet selected an org and gotten
 * bound to a specific user.
 *
 * Any routes included cannot assume that a user exists, only an identity.
 */
function LoggedInRoutes() {
    const identity = useCurrentIdentity();
    const UserWrapper = {
        AUTH0: Auth0UserWrapper,
        CONSTRUCTOR_DEMO: DemoUserWrapper,
    }[Config.auth.provider];

    if (!UserWrapper) {
        throw new Error("Unknown auth provider");
    }

    useLogRocket();
    useRecordLoginHint();
    useRecordPageViews();

    return (
        <>
            {Config.showLoadingOverlay ? <LoadingOverlay /> : null}

            <Switch>
                {/*
                    Invite and signup routes
                    *Before* a user is logged in, Auth0LoginWrapper checks these routes manually
                    and redirects the user to the login/signup page. After successful login,
                    the user is redirected back to these same routes, which are handled here.
                */}
                <AppRoute path="/join">
                    <JoinRedirect />
                </AppRoute>

                <AppRoute path="/invite">
                    <InviteRedirect />
                </AppRoute>

                <AppRoute path="/signup">
                    <SignupRedirect />
                </AppRoute>

                {/*
                    Email verification. A person is redirected by the app to /email_verification if their
                    email is not verified but should be, and they are redirected by Auth0 to /email_verified
                    after clicking the link in the verification email.
                */}
                <AppRoute path="/email_verified">
                    <EmailVerificationSync>
                        <EmailVerifiedRedirect />
                    </EmailVerificationSync>
                </AppRoute>

                {Config.isMultiOrgEnabled ? (
                    <AppRoute appIsReady path="/email_verification">
                        <EmailVerificationSync>
                            <EmailVerificationView />
                        </EmailVerificationSync>
                    </AppRoute>
                ) : null}

                {/*
                    Conditional catch all route requiring email verification before accessing
                    any routes afterward.
                */}
                {Config.isMultiOrgEnabled &&
                !identity.is_email_address_verified &&
                isIdentityOnboarding(identity) ? (
                    <AppRoute>
                        <Redirect to="/email_verification" />
                    </AppRoute>
                ) : null}

                <AppRoute>
                    <EmailVerificationSync>
                        <Switch>
                            {/* Organization creation and selection */}
                            {Config.isMultiOrgEnabled ? (
                                <AppRoute exact path="/">
                                    <Redirect to="/organizations" />
                                </AppRoute>
                            ) : null}

                            {Config.isMultiOrgEnabled ? (
                                <AppRoute appIsReady exact path="/organizations" requireOnline>
                                    <OrganizationsView />
                                </AppRoute>
                            ) : null}

                            {Config.isMultiOrgEnabled ? (
                                <AppRoute exact path="/organizations/new">
                                    <OnboardingNewOrgRedirect />
                                </AppRoute>
                            ) : null}

                            {Config.isMultiOrgEnabled ? (
                                <AppRoute
                                    appIsReady
                                    exact
                                    path="/organizations/setup"
                                    requireOnline
                                >
                                    <OnboardingView />
                                </AppRoute>
                            ) : null}

                            <AppRoute>
                                <UserWrapper />
                            </AppRoute>
                        </Switch>
                    </EmailVerificationSync>
                </AppRoute>
            </Switch>
        </>
    );
}

function Auth0LoginWrapper() {
    const { getIdTokenClaims, isAuthenticated, isLoading, login } = useAuth0();
    const [claims, setClaims] = useState<IdToken | null>(null);
    const isAccessTokenAvailable = useRecoilValue(isAccessTokenAvailableState);
    const { createApolloClient } = useCreateApolloClient();

    useAuth0AccessTokenManager();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            const isEncodedTokenSupported = ["/join", "/invite", "/signup"].includes(
                window.location.pathname
            );
            const isSignup = ["/invite", "/signup"].includes(window.location.pathname);

            // As of June 2022, we don't represent invitation as first-class objects. The email
            // address here is just used to prepopulate the signup form if the user clicks the link.
            // The reason for calling it a token and encoding it is just to make it look normal
            // to a casual user.
            const encodedToken = new URLSearchParams(window.location.search).get("token");
            let emailAddress;

            if (encodedToken && isEncodedTokenSupported) {
                try {
                    emailAddress = JSON.parse(Buffer.from(encodedToken, "hex").toString())
                        .emailAddress;
                } catch {
                    Log.error("Unable to parse invite token");
                }
            }

            void login({ hintEmailAddress: emailAddress, isSignup });
        }
    }, [isLoading, isAuthenticated, login]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            (async () => {
                setClaims((await getIdTokenClaims()) ?? null);
            })();
        }
    }, [isLoading, isAuthenticated, getIdTokenClaims]);

    if (isLoading || !isAuthenticated || !isAccessTokenAvailable || !claims) {
        return null;
    }

    AppData.identityRole =
        (claims["https://flat.app/id/claims/identityRole"] as string) ?? "identity";

    return (
        <ApolloProvider client={createApolloClient()}>
            <IdentityProvider
                identityId={claims["https://flat.app/id/claims/identityId"] as string}
                isReadOnly={claims["https://flat.app/id/claims/patches"]?.isReadOnly}
            >
                <ErrorBoundary>
                    <LoggedInRoutes />
                </ErrorBoundary>
            </IdentityProvider>
        </ApolloProvider>
    );
}

function DemoLoginWrapper() {
    const showRefreshPrompt = useCallback(() => {
        AppToaster.info({
            icon: <Icon icon="refresh-cw" iconSet="lucide" iconSize={24} />,
            message: "Your session has expired. Refresh to start again.",
            action: {
                onClick: () => {
                    window.location.reload();
                },
                text: "Refresh",
            },
            timeout: 0,
        });
    }, []);

    const isAccessTokenAvailable = useRecoilValue(isAccessTokenAvailableState);
    const { isDemoUnavailable, error } = useDemoAccessTokenManager({
        onApproachingExpiration: showRefreshPrompt,
    });

    const { createApolloClient } = useCreateApolloClient();

    if (isDemoUnavailable || error) {
        return (
            <ErrorView
                message={
                    isDemoUnavailable
                        ? "Sorry, the demo isn't available now. Please try again later."
                        : undefined
                }
                showCaption={false}
                showRefreshButton={false}
            />
        );
    }

    if (!isAccessTokenAvailable) {
        return null;
    }

    return (
        <ApolloProvider client={createApolloClient()}>
            <IdentityProvider identityId={getCurrentDemoIdentityId()!}>
                <ErrorBoundary>
                    <LoggedInRoutes />
                </ErrorBoundary>
            </IdentityProvider>
        </ApolloProvider>
    );
}

function WindowDragTarget() {
    const isTitleBarHidden = useIsTitleBarHidden();

    return isTitleBarHidden ? <div className={styles.windowDragTarget} /> : null;
}

/**
 * The top-level app.
 *
 * Any routes included here must be functional even if the person is not logged in.
 */
export function App() {
    const { history } = useHistory();

    useNetworkStatusMonitor();
    useTrackingUid({ setOrTouchOnRender: true });

    const LoginWrapper = {
        AUTH0: Auth0LoginWrapper,
        CONSTRUCTOR_DEMO: DemoLoginWrapper,
    }[Config.auth.provider];

    if (!LoginWrapper) {
        throw new Error("Unknown auth provider");
    }

    useEffect(() => {
        window.electron?.onNavigationRequest?.((event, value) => {
            if (value.back) {
                history.goBack();
            } else if (value.forward) {
                history.goForward();
            }
        });
    }, [history]);

    useEffect(() => {
        window.electron?.onDeepLink?.((event, { location }) => {
            history.push(location);
        });
    }, [history]);

    const setElectronWindowState = useSetRecoilState(electronWindowState);

    useEffect(() => {
        const newElectronWindowState = window.electron?.windowState;

        if (newElectronWindowState) {
            setElectronWindowState(newElectronWindowState);
        }
    }, [setElectronWindowState]);

    useEffect(() => {
        window.electron?.onWindowStateChange?.((event, { newState }) => {
            setElectronWindowState(newState);
        });
    }, [setElectronWindowState]);

    useEffect(() => {
        setThemeClassName();
    }, []);

    return (
        <>
            <WindowDragTarget />
            <Switch>
                <Route path="/login">
                    <LoginRedirect />
                </Route>
                <Route path="/logout">
                    <LogoutRedirect />
                </Route>
                <Route path="/desktop/open" exact>
                    <DesktopAppRedirectView />
                </Route>
                <Route path="/oauth/redirect" exact>
                    <OAuthRedirect />
                </Route>
                <Route>
                    <LoginWrapper />
                </Route>
            </Switch>
        </>
    );
}
