import React, { useEffect } from "react";

import { CommonEnums } from "c9r-common";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDocumentTitle } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { useRouteMatch } from "lib/Routing";
import { useRedirectToFullPathname, useUrlBuilders } from "lib/Urls";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { isDefined } from "lib/types/guards";
import { NotFoundView } from "views/error/NotFoundView";

import { AccountSettings } from "./AccountSettings";
import { AppearanceSettings } from "./AppearanceSettings";
import { BoardSettings } from "./BoardSettings";
import { GeneralSettings } from "./GeneralSettings";
import { ImportSettings } from "./ImportSettings";
import { IntegrationsSettings } from "./IntegrationsSettings";
import { NotificationsSettings } from "./NotificationsSettings";
import { ProfileSettings } from "./ProfileSettings";
import styles from "./Settings.module.scss";
import { SettingsSideNav } from "./SettingsSideNav";
import { TeamSettings } from "./TeamSettings";
import { billingInfoState } from "./billing/BillingState";

export function Settings() {
    const prefetchQuery = usePrefetchQuery();
    const currentUser = useCurrentUser();
    const { isFeatureEnabled } = useFeatureFlags();
    const breakpoints = useBreakpoints();
    const setBillingInfo = useSetRecoilState(billingInfoState);
    const { buildSettingsUrl } = useUrlBuilders();
    const { path } = useRouteMatch();
    const route = useRouteMatch<{ slug: string }>({
        path: `${path}/:slug`,
        strict: true,
        sensitive: true,
    });

    useDocumentTitle("Settings");
    useRedirectToFullPathname({
        fullPathname: [buildSettingsUrl().pathname, route?.params.slug ?? "profile"]
            .filter(isDefined)
            .join("/"),
    });

    useEffect(() => {
        return () => {
            setBillingInfo(null);
        };
    }, [setBillingInfo]);

    const routes = [
        {
            slug: "profile",
            component: ProfileSettings,
            prefetchArgs: {
                query: Queries.get({ component: "ProfileSettings", name: "component" }),
                variables: { currentUserId: currentUser.id },
            },
        },
        {
            slug: "notifications",
            component: NotificationsSettings,
            prefetchArgs: {
                query: Queries.get({ component: "NotificationsSettings", name: "component" }),
                variables: { currentUserId: currentUser.id },
            },
        },
        {
            slug: "appearance",
            component: AppearanceSettings,
            prefetchArgs: {
                query: Queries.get({ component: "AppearanceSettings", name: "component" }),
                variables: { currentUserId: currentUser.id },
            },
        },
        ...(currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN
            ? [
                  {
                      slug: "general",
                      component: GeneralSettings,
                      prefetchArgs: {
                          query: Queries.get({
                              component: "GeneralSettings",
                              name: "component",
                          }),
                          variables: { orgId: currentUser.org_id },
                      },
                  },
                  {
                      slug: "workspaces",
                      component: BoardSettings,
                      prefetchArgs: {
                          query: Queries.get({ component: "BoardSettings", name: "component" }),
                          variables: { orgId: currentUser.org_id },
                      },
                  },
                  {
                      slug: "import",
                      component: ImportSettings,
                      prefetchArgs: {
                          query: Queries.get({ component: "ImportSettings", name: "component" }),
                          variables: { orgId: currentUser.org_id },
                      },
                  },
                  {
                      slug: "people",
                      component: TeamSettings,
                      prefetchArgs: {
                          query: Queries.get({ component: "TeamSettings", name: "component" }),
                          variables: { orgId: currentUser.org_id },
                      },
                  },
                  {
                      slug: "integrations",
                      component: IntegrationsSettings,
                      prefetchArgs: {
                          query: Queries.get({
                              component: "IntegrationsSettings",
                              name: "component",
                          }),
                          variables: { orgId: currentUser.org_id },
                      },
                  },
                  isFeatureEnabled({ feature: Enums.Feature.ACCOUNT_SETTINGS })
                      ? {
                            slug: "account",
                            component: AccountSettings,
                        }
                      : null,
              ].filter(isDefined)
            : []),
    ];

    for (const routeInfo of routes) {
        if (routeInfo.prefetchArgs?.query) {
            void prefetchQuery({ ...routeInfo.prefetchArgs, usesLegacyApi: true });
        }
    }

    return (
        <>
            <Route path={path} exact>
                <Redirect to={`${buildSettingsUrl().pathname}/profile`} />
            </Route>
            {routes.some(routeInfo => routeInfo.slug === route?.params.slug) ? (
                <div className={styles.container}>
                    {/* This is a quick stopgap to ensure a settings page is viewable on mobile if
                    a user somehow navigates to it. As of December 2022, we don't show a link
                    to Settings on mobile, however, a user could still land on a settings page
                    via a direct link (such as a billing notification that links to billing). */}
                    {breakpoints.lgMin && <SettingsSideNav />}
                    <div className={styles.vr} />
                    <div className={styles.content}>
                        <Switch>
                            {routes.map(routeInfo => (
                                <Route
                                    key={routeInfo.slug}
                                    path={`${path}/${routeInfo.slug}`}
                                    component={routeInfo.component}
                                />
                            ))}
                        </Switch>
                    </div>
                </div>
            ) : (
                <NotFoundView />
            )}
        </>
    );
}
