import React, { useCallback, useMemo, useState } from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import { AppData } from "AppData";
import { QueryLoader, TQueryResult } from "components/loading/QueryLoader";
import {
    TicketRowContentColumnType,
    TicketRowContentDynamicWidthTableColumnDefinitions,
    TicketRowTableCell,
} from "components/shared/ticketList/TicketRowContent";
import {
    DynamicWidthTable,
    DynamicWidthTableColumnDefinition,
} from "components/ui/common/DynamicWidthTable";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { moveToLastPosition } from "lib/EntityPositioning";
import { isTicketMatchingFilterTerms, parseFilterExpression } from "lib/Filter";
import { Queries } from "lib/Queries";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    ArchiveQuery,
    Archive_boardFragment,
    TicketFilter_ticketFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { useUnarchiveTicket } from "lib/mutations";
import { createCtx } from "lib/react/Context";

import styles from "./Archive.module.scss";
import { SideDrawerTicketTableHeader } from "./SideDrawerTicketTableHeader";
import {
    TicketDate,
    TicketOwnershipSummary,
    TicketRow,
    TicketRowContent,
    TicketRows,
    TicketTableColumnName,
    TicketTableHeader,
} from "./TicketTable";
import { useBoardView } from "../BoardViewContext";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment Archive_board on boards {
            id
            display_name

            tickets(where: { archived_at: { _is_null: false } }, order_by: { archived_at: desc }) {
                id
                ref
                title
                archived_at

                stage {
                    id
                    max_ticket_stage_pos
                }

                ...TicketFilter_ticket
                ...TicketTable_ticket
            }
        }
    `),
};

const DynamicWidthTableColumnDefinitions = [
    {
        columnClassName: styles.archivedDateColumn,
    },
    {
        ...TicketRowContentDynamicWidthTableColumnDefinitions.find(
            d => d.columnType === TicketRowContentColumnType.MAIN_INFO
        ),
        minWidth: undefined,
    } as DynamicWidthTableColumnDefinition,
    {
        columnClassName: styles.ownerColumn,
        maxWidth: 120,
    },
    {
        columnClassName: styles.buttonColumn,
        displayIfEmpty: true,
    },
];

type ArchiveContextValue = {
    board: Archive_boardFragment;
    condensedFormat: boolean;
    filterExpression: string;
    setFilterExpression: React.Dispatch<React.SetStateAction<string>>;
    tickets: Archive_boardFragment["tickets"];
};

const [useArchive, ContextProvider] = createCtx<ArchiveContextValue>();

type ArchiveProviderProps = {
    children: React.ReactNode;
    board: FragmentType<typeof fragments.board>;
};

function ArchiveProvider({ children, board: _boardFragment }: ArchiveProviderProps) {
    const board = getFragmentData(fragments.board, _boardFragment);

    const currentUser = useCurrentUser();
    const [filterExpression, setFilterExpression] = useState(
        AppData.archiveFilterExpressionByBoardId[board.id] || ""
    );

    const breakpoints = useBreakpoints();
    const condensedFormat = breakpoints.smMax;

    const isTicketMatchingFilterExpression = useCallback(
        ({ ticket }: { ticket: FragmentType<typeof TicketFilter_ticketFragmentDoc> }) =>
            isTicketMatchingFilterTerms({
                ticket,
                userId: currentUser.id,
                filterTerms: parseFilterExpression({ filterExpression }),
            }),
        [currentUser.id, filterExpression]
    );

    const tickets = board.tickets
        .filter(ticket => !!ticket.archived_at)
        .filter(ticket => isTicketMatchingFilterExpression({ ticket }));

    const value = useMemo(
        () => ({
            board,
            condensedFormat,
            filterExpression,
            setFilterExpression,
            tickets,
        }),
        [board, condensedFormat, filterExpression, tickets]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

function HeaderPlaceholder() {
    const { toggleIsArchiveVisible } = useBoardView();

    return (
        <SideDrawerTicketTableHeader
            elementName="board_archive"
            onClose={toggleIsArchiveVisible}
            title="Archive"
        />
    );
}

function Header() {
    const { board, toggleIsArchiveVisible } = useBoardView();
    const { filterExpression, setFilterExpression, tickets } = useArchive();

    const onFilterExpressionChange = useCallback(
        (newFilterExpression?: string | null) => {
            setFilterExpression(newFilterExpression || "");
            AppData.archiveFilterExpressionByBoardId[board.id] = newFilterExpression;
        },
        [board.id, setFilterExpression]
    );

    return (
        <SideDrawerTicketTableHeader
            board={board}
            space="ARCHIVE"
            elementName="board_archive"
            filterExpression={filterExpression}
            onClose={toggleIsArchiveVisible}
            onFilterExpressionChange={onFilterExpressionChange}
            ticketCount={tickets.length}
            title="Archive"
        />
    );
}

type TicketArchiveButtonProps = {
    ticket: Archive_boardFragment["tickets"][number];
};

function TicketArchiveButton({ ticket }: TicketArchiveButtonProps) {
    const { unarchiveTicket } = useUnarchiveTicket();

    const ticketId = ticket.id;

    const handleUnarchive = useCallback(async () => {
        await unarchiveTicket({
            ticketId,
            stagePos: moveToLastPosition({
                maxPos: ticket.stage?.max_ticket_stage_pos,
            }),
        });
    }, [ticketId, ticket.stage, unarchiveTicket]);

    return (
        <Tooltip
            className={styles.archiveButtonWrapper}
            content="Unarchive"
            placement="top"
            small
            openOnTargetFocus={false}
        >
            <BorderButton
                className={styles.archiveButton}
                contentClassName={styles.archiveButtonContent}
                content={<Icon icon="unarchive" iconSet="c9r" iconSize={20} strokeWidth={1} />}
                instrumentation={{
                    elementName: "board_archive.ticket_row.unarchive_btn",
                    eventData: { ticketId },
                }}
                onClick={handleUnarchive}
                preventDefault
                flush
                minimal
                small
                square
                tighter
            />
        </Tooltip>
    );
}

function MainTableZeroState() {
    return (
        <div className={styles.mainTableZeroState}>
            <div>
                <p>Archive topics when you're done with them to keep your boards uncluttered.</p>
                <p>You can unarchive topics at any time.</p>
            </div>
        </div>
    );
}

function MainTableTicketRows() {
    const { condensedFormat, tickets } = useArchive();

    return (
        <TicketRows>
            {tickets
                .sort(
                    (a, b) =>
                        new Date(b.archived_at!).getTime() - new Date(a.archived_at!).getTime()
                )
                .map(ticket => (
                    <TicketRow
                        className={styles.ticketRow}
                        key={ticket.id}
                        elementName="board_archive.ticket_row"
                        ticket={ticket}
                    >
                        <TicketRowTableCell columnClassName={styles.archivedDateColumn}>
                            <TicketDate date={ticket.archived_at} />
                        </TicketRowTableCell>
                        <TicketRowContent ticket={ticket} titleOnly={condensedFormat} />
                        {!condensedFormat ? (
                            <>
                                <TicketRowTableCell columnClassName={styles.ownerColumn}>
                                    <TicketOwnershipSummary ticket={ticket} />
                                </TicketRowTableCell>
                                <TicketRowTableCell columnClassName={styles.buttonColumn}>
                                    <TicketArchiveButton ticket={ticket} />
                                </TicketRowTableCell>
                            </>
                        ) : null}
                    </TicketRow>
                ))}
        </TicketRows>
    );
}

function MainTable() {
    const { board, condensedFormat, filterExpression } = useArchive();
    const isZeroState = !board.tickets.some(ticket => !!ticket.archived_at);

    return (
        <DynamicWidthTable
            columnDefinitions={DynamicWidthTableColumnDefinitions}
            dependencies={[board, filterExpression]}
        >
            <div className={styles.mainTable}>
                <TicketTableHeader>
                    <TicketTableColumnName className={styles.archivedDateColumn}>
                        Archived
                    </TicketTableColumnName>
                    <TicketTableColumnName className={styles.titleColumn}>
                        Title
                    </TicketTableColumnName>
                    {!condensedFormat ? (
                        <>
                            <TicketTableColumnName className={styles.ownerColumn}>
                                Owner
                            </TicketTableColumnName>
                            <TicketTableColumnName className={styles.buttonColumn} />{" "}
                        </>
                    ) : null}
                </TicketTableHeader>
                {isZeroState ? <MainTableZeroState /> : <MainTableTicketRows />}
            </div>
        </DynamicWidthTable>
    );
}

function Content() {
    return (
        <div className={classNames(styles.content)}>
            <Header />
            <MainTable />
        </div>
    );
}

function ArchiveImpl({ queryResult }: { queryResult: TQueryResult<ArchiveQuery> }) {
    if (queryResult.loading && !queryResult.data) {
        return (
            <div className={styles.loadingWrapper}>
                <HeaderPlaceholder />
                <Spinner className={styles.spinner} size={36} />
            </div>
        );
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    return (
        <ArchiveProvider board={queryResult.data!.board!}>
            <Content />
        </ArchiveProvider>
    );
}

export function Archive() {
    const { board } = useBoardView();

    return (
        <QueryLoader query={Archive.queries.component} variables={{ boardId: board.id }}>
            {({ queryResult }) => <ArchiveImpl queryResult={queryResult} />}
        </QueryLoader>
    );
}

Archive.queries = {
    component: gql(/* GraphQL */ `
        query Archive($boardId: uuid!) {
            board: boards_by_pk(id: $boardId) {
                ...Archive_board
            }
        }
    `),
};

Queries.register({ component: "Archive", gqlMapByName: Archive.queries });
