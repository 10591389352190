import React from "react";

import classNames from "classnames";

import { useTicketProgressInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./ChildTicketProgressBar.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment ChildTicketProgressBar_ticket on tickets {
            id
            archived_at

            stage {
                id
                role
            }

            ...TicketProgressInfo_ticket
        }
    `),
};

export type ChildTicketProgressBarProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket> | null;
    decreaseOpacity?: boolean;
};

export function ChildTicketProgressBar({
    className,
    ticket: _ticketFragment,
    decreaseOpacity,
}: ChildTicketProgressBarProps) {
    const ticket = _ticketFragment ? getFragmentData(fragments.ticket, _ticketFragment) : null;
    const { isBlocked, progressPercent } = useTicketProgressInfo({ ticket });

    return (
        <div
            className={classNames(
                className,
                styles.childTicketProgressBar,
                !isBlocked &&
                    (ticket
                        ? ticket.archived_at
                            ? styles.COMPLETE
                            : styles[ticket.stage.role]
                        : styles.PRE_IMPLEMENTATION),
                isBlocked && styles.blocked,
                decreaseOpacity && styles.decreaseOpacity
            )}
            style={
                {
                    "--card-child-ticket-width": `${progressPercent}%`,
                } as React.CSSProperties
            }
        />
    );
}
