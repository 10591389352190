import React, { useMemo } from "react";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { BoardAndStagesMenuContextProvider_orgFragment } from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment BoardAndStagesMenuContextProvider_org on orgs {
            id

            ...BoardAndStagesMenuItem_org
        }
    `),
};

type BoardAndStagesMenuContextValue = {
    org: BoardAndStagesMenuContextProvider_orgFragment;
};

const [useBoardAndStagesMenuContext, ContextProvider] = createCtx<BoardAndStagesMenuContextValue>();

export { useBoardAndStagesMenuContext };

export type BoardAndStagesMenuContextProviderProps = {
    children: React.ReactNode;
    org: FragmentType<typeof fragments.org>;
};

export function BoardAndStagesMenuContextProvider({
    children,
    org: _orgFragment,
}: BoardAndStagesMenuContextProviderProps) {
    const org = getFragmentData(fragments.org, _orgFragment);

    const value = useMemo(() => ({ org }), [org]);

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
