import React, { useEffect, useState } from "react";

import { CommonEnums, sortTasks } from "c9r-common";
import classNames from "classnames";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { dragAndDropEntity } from "lib/DragAndDrop";
import { moveToPositionByIndex } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Log } from "lib/Log";
import { useGetTaskStatusInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TasklistsSection_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useMoveTask } from "lib/mutations/tasks/moveTask";
import { isDefined } from "lib/types/guards";

import { TasklistSection } from "./TasklistSection";
import styles from "./TasklistsSection.module.scss";
import { useTasklistsSectionContext } from "./TasklistsSectionContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TasklistsSection_ticket on tickets {
            id

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id
                added_at
                deleted_at
                uuid

                stage {
                    id
                    board_pos
                }

                tasks(where: { deleted_at: { _is_null: true } }) {
                    id
                    tasklist_id
                    tasklist_pos
                    task_type

                    ...TaskStatusInfo_task
                }

                ...TasklistSection_tasklist
            }
        }
    `),
};

type TTasklist = TasklistsSection_ticketFragment["tasklists"][number];

export type TasklistsSectionProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TasklistsSection({ className, ticket: _ticketFragment }: TasklistsSectionProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { recordEvent } = useInstrumentation();
    const { getTaskStatusInfo } = useGetTaskStatusInfo();
    const { moveTask } = useMoveTask();
    const { getIsTasklistCollapsed, containerRef } = useTasklistsSectionContext();
    const [_tasklists, setTasklists] = useState(ticket.tasklists);

    useEffect(() => {
        setTasklists(ticket.tasklists);
    }, [ticket.tasklists]);

    const tasklists = _tasklists
        .filter(tasklist => !tasklist.deleted_at)
        .sort((a, b) =>
            // A tasklist always has a stage, but if it's a guest user, it may not be visible
            // to then, so we have to check it exists.
            a.stage && b.stage && a.stage.id !== b.stage.id
                ? (a.stage.board_pos ?? 0) - (b.stage.board_pos ?? 0)
                : new Date(a.added_at).getTime() - new Date(b.added_at).getTime()
        );

    const handleDragEnd = (result: DropResult) => {
        const { draggableId, destination, source } = result;
        const sameStage = destination?.droppableId === source.droppableId;

        if (!destination || (sameStage && destination.index === source.index)) {
            return;
        }

        const maybeCollapseTasks = (tasklist: TTasklist) =>
            getIsTasklistCollapsed(tasklist.id)
                ? tasklist.tasks.filter(task => !getTaskStatusInfo({ task }).isComplete)
                : tasklist.tasks;

        const tasks = ticket.tasklists
            .flatMap(tl => maybeCollapseTasks(tl))
            .filter(task => !getTaskStatusInfo({ task }).isHidden);

        const toIndex = destination.index;
        const toTasklistId = dragAndDropEntity.getRootId(destination.droppableId);
        const toStageId = ticket.tasklists.find(tl => tl.id === toTasklistId)?.stage.id;
        const movedTask = tasks.find(t => t.id === draggableId);

        if (!toTasklistId) {
            Log.error("Unable to find tasklist for stage", {
                ticketId: ticket.id,
                stageId: toStageId,
            });

            return;
        }

        if (!movedTask) {
            Log.error("Unable to find task being moved", {
                ticketId: ticket.id,
                draggableId,
            });

            return;
        }

        const tasklistPos = moveToPositionByIndex({
            sortedEntities: tasks.filter(t => t.tasklist_id === toTasklistId).sort(sortTasks()),
            posFieldName: "tasklist_pos",
            toIndex,
            entityId: movedTask.id,
        });

        void recordEvent({
            eventType: Enums.InstrumentationEvent.DRAG,
            elementName: "task",
            eventData: { taskId: movedTask.id, toIndex, toStageId: !sameStage ? toStageId : null },
        });

        void moveTask({
            taskId: movedTask.id,
            fromTasklistId: movedTask.tasklist_id,
            toTasklistId,
            tasklistPos,
        });

        // As of June 2023, react-beautiful-dnd expects that when a drag ends, the app state
        // is updated *synchronously* so that the dropped item appears immediately in the dropped
        // position. If the state is updated asynchronously, the dropped item will temporarily
        // appear back in its old position until the state update is processed.
        //
        // Replicache processes state updates asynchronously. It's usually within the same frame,
        // but not always.
        //
        // Thus, we synchronously update the state ourselves to ensure the drop looks seamless.
        setTasklists(prev => [
            ...prev.map(tasklist => ({
                ...tasklist,
                tasks: [
                    ...tasklist.tasks.filter(task => task.id !== movedTask.id),
                    tasklist.id === toTasklistId
                        ? {
                              ...movedTask,
                              tasklist_id: toTasklistId,
                              tasklist_pos: tasklistPos,
                          }
                        : null,
                ].filter(isDefined),
            })),
        ]);
    };

    return (
        <div
            className={classNames(
                className,
                styles.section,
                tasklists.length && styles.isPopulated
            )}
            ref={containerRef}
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                {tasklists.map((tasklist, i) => (
                    <TasklistSection
                        className={classNames(
                            styles.tasklistSection,
                            i === ticket.tasklists.length - 1 && styles.tasklistSectionLast
                        )}
                        hotspotDisabled={i > 0}
                        hotspotKey={
                            tasklist.tasks.some(
                                task => task.task_type === CommonEnums.TaskType.CHILD_TICKET
                            )
                                ? Enums.HotspotKey.WORK_STRUCTURES
                                : Enums.HotspotKey.CHECKLIST
                        }
                        // Use uuid as key, rather than id, because id may be a fake optimistic
                        // value that may change once the real tasklist is created, whereas uuid
                        // will never change.
                        key={tasklist.uuid}
                        tasklist={tasklist}
                    />
                ))}
            </DragDropContext>
        </div>
    );
}
