import React from "react";

import classNames from "classnames";

import { Icon, IconProps } from "components/ui/core/Icon";
import {
    AbstractTextInput,
    AbstractTextInputProps,
} from "components/ui/core/abstract/AbstractTextInput";

import styles from "./TextInputGroup.module.scss";

export type TextInputGroupProps = {
    alwaysShowFocusWithinIndicator?: Boolean;
    leftElement?: React.ReactNode;
    leftIconProps?: IconProps;
    rightElement?: React.ReactNode;
    rightIconProps?: IconProps;
} & AbstractTextInputProps;

export function TextInputGroup({
    alwaysShowFocusWithinIndicator,
    leftElement,
    leftIconProps,
    rightElement,
    rightIconProps,
    ...abstractTextInputProps
}: TextInputGroupProps) {
    return (
        <div
            className={classNames(
                abstractTextInputProps.className,
                // We are importing the TextInput component styles in the TextInputGroup styles
                // and applying them here so that the components can be styled consistently.
                styles.textInput,
                styles.textInputGroup,
                alwaysShowFocusWithinIndicator && styles.alwaysShowFocusWithinIndicator,
                abstractTextInputProps?.disabled && styles.disabled
            )}
        >
            {leftElement ? <>{leftElement}</> : null}
            {leftIconProps ? <Icon {...leftIconProps} /> : null}
            <AbstractTextInput {...abstractTextInputProps} className={styles.input} />
            {rightElement ? <>{rightElement}</> : null}
            {rightIconProps ? <Icon {...rightIconProps} /> : null}
        </div>
    );
}
