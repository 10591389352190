import React from "react";

export type PunctuatedListProps = {
    children: React.ReactNode;
    joinText: string;
};

/**
 * Helper to produce an English punctuated list like "foo, bar, and baz", with
 * commas and the final join word in the appropriate place. Correctly handles cases
 * where there is just one item ("foo") or two items ("foo and bar").
 */
export function PunctuatedList({ children, joinText }: PunctuatedListProps) {
    const count = React.Children.count(children);

    return (
        <>
            {React.Children.map(children, (child, i) => (
                <>
                    {i > 0 && i === count - 1 ? <> {joinText} </> : ""}
                    {child}
                    {count > 2 && i < count - 1 ? ", " : " "}
                </>
            ))}
        </>
    );
}
