import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";

import styles from "./DetailedMenuItem.module.scss";

export type DetailedMenuItemProps = {
    className?: string;
    title: string;
    subtitle?: string;
    caption?: string;
    selected?: boolean;
} & Pick<MenuItemProps, "active" | "disabled" | "instrumentation" | "onClick">;

export function DetailedMenuItem({
    className,
    title,
    subtitle,
    caption,
    selected,

    ...menuItemProps
}: DetailedMenuItemProps) {
    return (
        <MenuItem
            {...menuItemProps}
            className={classNames(className, styles.detailedMenuItem)}
            icon={
                selected ? (
                    <Icon icon="check" iconSet="lucide" iconSize={16} />
                ) : (
                    <Icon icon="blank" iconSet="c9r" iconSize={16} />
                )
            }
            text={
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <span className={styles.title}>{title}</span>
                        {subtitle ? <span className={styles.subtitle}>{subtitle}</span> : null}
                    </div>
                    {caption ? <div className={styles.caption}>{caption}</div> : null}
                </div>
            }
        />
    );
}
