import React, { useCallback, useMemo, useRef, useState } from "react";

import { Editor as TEditor } from "@tiptap/react";
import { ValueOf } from "c9r-common";
import classNames from "classnames";

import { BorderButton, BorderButtonProps } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon, IconProps } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { MenuPopover, MenuPopoverProps } from "components/ui/core/MenuPopover";
import { Tooltip, TooltipProps } from "components/ui/core/Tooltip";
import { useInputPromptDialog } from "dialogs/InputPromptDialog";
import { divideAndFlattenGroups } from "lib/Helpers";
import { useFilePicker, useWindowSize } from "lib/Hooks";
import { isMac } from "lib/OS";
import { createCtx } from "lib/react/Context";

import styles from "./EditorToolbar.module.scss";

type TBasicCommand =
    | "clearFormatting"
    | "clearNodes"
    | "toggleBlockquote"
    | "toggleBulletList"
    | "toggleBold"
    | "toggleCodeBlock"
    | "toggleItalic"
    | "toggleOrCreateLink"
    | "toggleOrderedList"
    | "toggleStrike";

const ToolbarMenuType = {
    EXTRAS: "EXTRAS",
    HEADING: "HEADING",
    IMAGE: "IMAGE",
} as const;

type EditorToolbarContextValue = {
    closeMenu: ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => void;
    currentOpenMenuType: ValueOf<typeof ToolbarMenuType> | null;
    editor: TEditor;
    handleImageUpload?: (files: File[]) => void;
    isVisible: boolean;
    openMenu: ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => void;
    setCurrentOpenMenuType: React.Dispatch<
        React.SetStateAction<ValueOf<typeof ToolbarMenuType> | null>
    >;
    toggleMenu: ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => void;
};

const [useEditorToolbarContext, ContextProvider] = createCtx<EditorToolbarContextValue>();

type EditorToolbarContextProviderProps = {
    children?: React.ReactNode;
    editor: TEditor;
    handleImageUpload?: (files: File[]) => void;
    isVisible: boolean;
};

function EditorToolbarContextProvider({
    children,
    editor,
    handleImageUpload,
    isVisible,
}: EditorToolbarContextProviderProps) {
    const [currentOpenMenuType, setCurrentOpenMenuType] = useState<ValueOf<
        typeof ToolbarMenuType
    > | null>(null);

    const closeMenu = useCallback(
        ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => {
            // Close only if the current menu type is the one we're trying to close.
            setCurrentOpenMenuType(prev => (prev === menuType ? null : prev));
        },
        [setCurrentOpenMenuType]
    );

    const openMenu = useCallback(
        ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => {
            setCurrentOpenMenuType(menuType);
        },
        [setCurrentOpenMenuType]
    );

    const toggleMenu = useCallback(
        ({ menuType }: { menuType: ValueOf<typeof ToolbarMenuType> }) => {
            setCurrentOpenMenuType(prev => (prev === menuType ? null : menuType));
        },
        [setCurrentOpenMenuType]
    );

    const value = useMemo(
        () => ({
            closeMenu,
            currentOpenMenuType,
            editor,
            handleImageUpload,
            isVisible,
            openMenu,
            setCurrentOpenMenuType,
            toggleMenu,
        }),
        [
            closeMenu,
            currentOpenMenuType,
            editor,
            handleImageUpload,
            isVisible,
            openMenu,
            setCurrentOpenMenuType,
            toggleMenu,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

type ToolbarButtonProps = {
    borderButtonProps?: Partial<BorderButtonProps>;
    command?: TBasicCommand;
    iconProps: IconProps;
    isActive?: boolean;
    nodeName?: string;
    onClick?: () => void;
};

function ToolbarButton({
    borderButtonProps,
    command,
    iconProps,
    isActive,
    nodeName,
    onClick,
}: ToolbarButtonProps) {
    const { editor } = useEditorToolbarContext();

    return (
        <BorderButton
            minimal
            small
            {...borderButtonProps}
            className={classNames(
                styles.toolbarButton,
                ((nodeName && editor.isActive(nodeName)) || isActive) && styles.isActive
            )}
            contentClassName={styles.toolbarButtonContent}
            instrumentation={{
                elementName: "editor.toolbar_btn",
                eventData: { command, nodeName },
            }}
            content={<Icon {...iconProps} />}
            onClick={() => {
                if (onClick) {
                    onClick();
                    editor.chain().focus().run();
                }

                if (command) {
                    setImmediate(() => {
                        editor.chain().focus()[command]().run();
                    });
                }
            }}
            tabIndex={-1}
        />
    );
}

type ToolbarMenuItemProps = {
    command?: TBasicCommand;
    instrumentationEventData?: object | null;
    menuType: ValueOf<typeof ToolbarMenuType>;
} & Omit<MenuItemProps, "instrumentation">;

function ToolbarMenuItem({
    command,
    instrumentationEventData,
    menuType,
    ...menuItemProps
}: ToolbarMenuItemProps) {
    const { editor, toggleMenu } = useEditorToolbarContext();

    return (
        <MenuItem
            {...menuItemProps}
            instrumentation={{
                elementName: "editor.toolbar_menu_item",
                eventData: { command, ...instrumentationEventData },
            }}
            onClick={(...args) => {
                toggleMenu({ menuType });

                if (menuItemProps.onClick) {
                    menuItemProps.onClick(...args);
                    editor.chain().focus().run();
                }

                if (command) {
                    setImmediate(() => {
                        editor.chain().focus()[command]().run();
                    });
                }
            }}
        />
    );
}

type ToolbarButtonGroupProps = {
    children: React.ReactNode;
};

function ToolbarButtonGroup({ children }: ToolbarButtonGroupProps) {
    return <div className={styles.toolbarPaletteButtonGroup}>{children}</div>;
}

type ToolbarMenuProps = {
    children: React.ReactNode;
    menuType: ValueOf<typeof ToolbarMenuType>;
} & MenuPopoverProps;

function ToolbarMenu({ children, menuType, ...menuPopoverProps }: ToolbarMenuProps) {
    const targetRef = useRef<HTMLDivElement>(null);
    const { closeMenu, currentOpenMenuType, openMenu } = useEditorToolbarContext();

    const isOpen = currentOpenMenuType === menuType;

    return (
        <div ref={targetRef}>
            <MenuPopover
                autoFocus={false}
                enforceFocus={false}
                placement="top-start"
                usePortal={false}
                {...menuPopoverProps}
                targetClassName={classNames(
                    menuPopoverProps?.targetClassName,
                    styles.toolbarMenuPopoverTarget
                )}
                isOpen={isOpen}
                onClosing={(...args) => {
                    closeMenu({ menuType });
                    menuPopoverProps?.onClosing?.(...args);
                }}
                onInteraction={(next, e) => {
                    if (e && e.target instanceof Element) {
                        // If this check is not in place, onInteraction runs when
                        // the popover is closed via the toolbar.
                        if (!targetRef.current?.contains(e.target)) {
                            if (next) {
                                openMenu({ menuType });
                            } else {
                                closeMenu({ menuType });
                            }
                        }
                    }

                    menuPopoverProps?.onInteraction?.(next, e);
                }}
            >
                {children}
            </MenuPopover>
        </div>
    );
}

function HeadingsMenu() {
    const { currentOpenMenuType, editor, toggleMenu } = useEditorToolbarContext();

    const menuType = ToolbarMenuType.HEADING;
    const headingLevels = [1, 2, 3, 4, 5, 6] as const;

    return (
        <ToolbarMenu
            content={
                <Menu className={styles.headingsMenu}>
                    <ToolbarMenuItem
                        menuType={menuType}
                        key={0}
                        text="Normal text"
                        command="clearNodes"
                    />
                    {headingLevels.map(headingLevel => (
                        <ToolbarMenuItem
                            menuType={menuType}
                            key={headingLevel}
                            className={classNames(
                                editor.isActive("heading", { level: headingLevel }) &&
                                    styles.isActive
                            )}
                            text={React.createElement(
                                `h${headingLevel}`,
                                { className: styles[`headingLevel${headingLevel}MenuItem`] },
                                `Heading ${headingLevel}`
                            )}
                            onClick={() => {
                                editor.chain().focus().toggleHeading({ level: headingLevel }).run();
                            }}
                            instrumentationEventData={{
                                command: "toggleHeading",
                                level: headingLevel,
                            }}
                        />
                    ))}
                </Menu>
            }
            menuType={menuType}
        >
            <EditorToolbarTooltip
                content={<div>Heading</div>}
                disabled={currentOpenMenuType === menuType}
            >
                <BorderButton
                    className={classNames(
                        styles.toolbarButton,
                        (editor.isActive("heading") || currentOpenMenuType === menuType) &&
                            styles.isActive
                    )}
                    content={
                        <>
                            <Icon icon="heading" iconSet="lucide" />
                            <Icon
                                icon="chevron-down"
                                iconSet="lucide"
                                iconSize={14}
                                strokeWidth={2}
                            />
                        </>
                    }
                    contentClassName={styles.toolbarButtonContent}
                    instrumentation={{
                        elementName: "editor.toolbar_btn.heading",
                    }}
                    minimal
                    onClick={() => {
                        toggleMenu({ menuType });
                        editor.chain().focus().run();
                    }}
                    small
                    square
                    tabIndex={-1}
                />
            </EditorToolbarTooltip>
        </ToolbarMenu>
    );
}

type AddImageMenuProps = {
    children: React.ReactNode;
};

function AddImageMenu({ children }: AddImageMenuProps) {
    const { editor, handleImageUpload } = useEditorToolbarContext();
    const { openFilePicker } = useFilePicker();
    const inputPromptDialog = useInputPromptDialog();

    const menuType = ToolbarMenuType.IMAGE;

    if (!handleImageUpload) {
        return null;
    }

    return (
        <ToolbarMenu
            content={
                <Menu>
                    <ToolbarMenuItem
                        menuType={menuType}
                        icon={<Icon icon="upload" iconSet="c9r" iconSize={18} />}
                        text="Upload file"
                        onClick={() => {
                            openFilePicker({ accept: "image/*", multiple: true }, files =>
                                handleImageUpload(files)
                            );
                        }}
                        instrumentationEventData={{
                            command: "insertImageByUpload",
                        }}
                    />
                    <ToolbarMenuItem
                        menuType={menuType}
                        icon={<Icon icon="link" iconSet="lucide" iconSize={18} />}
                        text="By URL"
                        onClick={() => {
                            inputPromptDialog.openWithProps({
                                inputType: "url",
                                promptText: "Enter or paste an image URL",
                                onCancel: () => {
                                    editor?.chain().focus().run();
                                },
                                onSubmit: ({ text }) => {
                                    editor.chain().focus().insertImageByUrl({ url: text }).run();
                                },
                                // We return focus to the editor ourselves, above.
                                shouldReturnFocusOnClose: false,
                            });
                        }}
                        instrumentationEventData={{
                            command: "insertImageByUrl",
                        }}
                    />
                </Menu>
            }
            menuType={menuType}
        >
            {children}
        </ToolbarMenu>
    );
}

type CondensedExtrasMenuProps = {
    children: React.ReactNode;
};

function CondensedExtrasMenu({ children }: CondensedExtrasMenuProps) {
    const { handleImageUpload } = useEditorToolbarContext();
    const { openFilePicker } = useFilePicker();

    const menuType = ToolbarMenuType.EXTRAS;

    return (
        <ToolbarMenu
            content={
                <Menu>
                    {divideAndFlattenGroups({
                        itemGroups: [
                            [
                                <ToolbarMenuItem
                                    command="toggleStrike"
                                    icon={<Icon icon="strikethrough" iconSet="c9r" iconSize={18} />}
                                    key="strikethrough"
                                    menuType={menuType}
                                    text="Strikethrough"
                                />,
                                <ToolbarMenuItem
                                    command="clearFormatting"
                                    icon={
                                        <Icon icon="clearFormatting" iconSet="c9r" iconSize={18} />
                                    }
                                    key="clearFormatting"
                                    menuType={menuType}
                                    text="Clear formatting"
                                />,
                            ],
                            [
                                <ToolbarMenuItem
                                    command="toggleBulletList"
                                    icon={<Icon icon="bulletList" iconSet="c9r" iconSize={20} />}
                                    key="bulletList"
                                    menuType={menuType}
                                    text="Bulleted list"
                                />,
                                <ToolbarMenuItem
                                    command="toggleOrderedList"
                                    icon={<Icon icon="orderedList" iconSet="c9r" iconSize={20} />}
                                    key="orderedList"
                                    menuType={menuType}
                                    text="Numbered list"
                                />,
                            ],
                            [
                                <ToolbarMenuItem
                                    command="toggleBlockquote"
                                    icon={<Icon icon="blockquote" iconSet="c9r" iconSize={18} />}
                                    key="blockquote"
                                    menuType={menuType}
                                    text="Blockquote"
                                />,
                                <ToolbarMenuItem
                                    command="toggleCodeBlock"
                                    icon={<Icon icon="code" iconSet="lucide" iconSize={18} />}
                                    key="codeBlock"
                                    menuType={menuType}
                                    text="Code block"
                                />,
                            ],
                            [
                                <ToolbarMenuItem
                                    command="toggleOrCreateLink"
                                    icon={<Icon icon="link" iconSet="lucide" iconSize={18} />}
                                    key="link"
                                    menuType={menuType}
                                    text="Link"
                                />,
                                handleImageUpload ? (
                                    <ToolbarMenuItem
                                        menuType={menuType}
                                        icon={<Icon icon="image" iconSet="lucide" iconSize={18} />}
                                        text="Upload image"
                                        onClick={() => {
                                            openFilePicker(
                                                { accept: "image/*", multiple: true },
                                                files => handleImageUpload(files)
                                            );
                                        }}
                                        instrumentationEventData={{
                                            command: "insertImageByUpload",
                                        }}
                                    />
                                ) : null,
                            ],
                        ],
                        divider: () => <MenuDivider />,
                    })}
                </Menu>
            }
            menuType={menuType}
        >
            {children}
        </ToolbarMenu>
    );
}

export function EditorToolbarTooltip({ content, ...tooltipProps }: TooltipProps) {
    const { isVisible } = useEditorToolbarContext();

    return (
        <Tooltip
            className={styles.toolbarTooltip}
            content={<div className={styles.toolbarTooltipContent}>{content}</div>}
            openOnTargetFocus={false}
            placement="bottom"
            small
            modifiers={{
                offset: {
                    enabled: true,
                    options: {
                        offset: [0, 12],
                    },
                },
            }}
            {...tooltipProps}
            disabled={!isVisible || tooltipProps.disabled}
        />
    );
}

type FormattingGroupProps = {
    bold?: boolean;
    italic?: boolean;
    strikethrough?: boolean;
    headings?: boolean;
};

function FormattingGroup({
    bold = true,
    italic = true,
    strikethrough = true,
    headings = true,
}: FormattingGroupProps) {
    return (
        <ToolbarButtonGroup>
            {bold ? (
                <EditorToolbarTooltip
                    content={
                        <>
                            <div>Bold</div>
                            <div>
                                <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                                +
                                <Hotkey text="B" />
                            </div>
                        </>
                    }
                >
                    <ToolbarButton
                        borderButtonProps={{ square: true }}
                        command="toggleBold"
                        iconProps={{
                            icon: "bold",
                            iconSet: "lucide",
                            strokeWeight: 2,
                        }}
                        nodeName="bold"
                    />
                </EditorToolbarTooltip>
            ) : null}
            {italic ? (
                <EditorToolbarTooltip
                    content={
                        <>
                            <div>Italic</div>
                            <div>
                                <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                                +
                                <Hotkey text="I" />
                            </div>
                        </>
                    }
                >
                    <ToolbarButton
                        borderButtonProps={{ square: true }}
                        command="toggleItalic"
                        iconProps={{
                            icon: "italic",
                            iconSet: "lucide",
                        }}
                        nodeName="italic"
                    />
                </EditorToolbarTooltip>
            ) : null}
            {strikethrough ? (
                <EditorToolbarTooltip
                    content={
                        <>
                            <div>Strikethrough</div>
                            <div>
                                <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                                +
                                <Hotkey text="Shift" />
                                +
                                <Hotkey text="X" />
                            </div>
                        </>
                    }
                >
                    <ToolbarButton
                        borderButtonProps={{ square: true }}
                        command="toggleStrike"
                        iconProps={{
                            icon: "strikethrough",
                            iconSet: "c9r",
                        }}
                        nodeName="strike"
                    />
                </EditorToolbarTooltip>
            ) : null}
            {headings ? <HeadingsMenu /> : null}
        </ToolbarButtonGroup>
    );
}

function HeadingsGroup() {
    return (
        <ToolbarButtonGroup>
            <HeadingsMenu />
        </ToolbarButtonGroup>
    );
}

function ListsGroup() {
    const { isVisible } = useEditorToolbarContext();

    return (
        <ToolbarButtonGroup>
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Bulleted list</div>
                        <div>
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text="Shift" />
                            +
                            <Hotkey text="8" />
                        </div>
                    </>
                }
                disabled={!isVisible}
            >
                <ToolbarButton
                    borderButtonProps={{ square: true, tighterer: true }}
                    command="toggleBulletList"
                    iconProps={{
                        icon: "bulletList",
                        iconSet: "c9r",
                        iconSize: 20,
                    }}
                    nodeName="bulletList"
                />
            </EditorToolbarTooltip>
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Numbered list</div>
                        <div>
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text="Shift" />
                            +
                            <Hotkey text="7" />
                        </div>
                    </>
                }
                disabled={!isVisible}
            >
                <ToolbarButton
                    borderButtonProps={{ square: true, tighterer: true }}
                    command="toggleOrderedList"
                    iconProps={{
                        icon: "orderedList",
                        iconSet: "c9r",
                        iconSize: 20,
                    }}
                    nodeName="orderedList"
                />
            </EditorToolbarTooltip>
        </ToolbarButtonGroup>
    );
}

function BlockFormattingGroup() {
    return (
        <ToolbarButtonGroup>
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Blockquote</div>
                        <div>
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text="Shift" />
                            +
                            <Hotkey text="B" />
                        </div>
                    </>
                }
            >
                <ToolbarButton
                    borderButtonProps={{ square: true }}
                    command="toggleBlockquote"
                    iconProps={{
                        icon: "blockquote",
                        iconSet: "c9r",
                    }}
                    nodeName="blockquote"
                />
            </EditorToolbarTooltip>
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Code block</div>
                        <div>
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text={isMac() ? "Option" : "Alt"} />
                            +
                            <Hotkey text="C" />
                        </div>
                    </>
                }
            >
                <ToolbarButton
                    borderButtonProps={{ square: true }}
                    command="toggleCodeBlock"
                    iconProps={{
                        icon: "code",
                        iconSet: "lucide",
                    }}
                    nodeName="codeBlock"
                />
            </EditorToolbarTooltip>
            <EditorToolbarTooltip content={<div>Clear formatting</div>}>
                <ToolbarButton
                    borderButtonProps={{ square: true }}
                    command="clearFormatting"
                    iconProps={{
                        icon: "clearFormatting",
                        iconSet: "c9r",
                    }}
                />
            </EditorToolbarTooltip>
        </ToolbarButtonGroup>
    );
}

function InsertElementsGroup() {
    const {
        currentOpenMenuType,
        handleImageUpload,
        isVisible,
        setCurrentOpenMenuType,
        toggleMenu,
    } = useEditorToolbarContext();

    return (
        <ToolbarButtonGroup>
            <EditorToolbarTooltip
                content={
                    <>
                        <div>Link</div>
                        <div>
                            <Hotkey text={isMac() ? "⌘" : "Ctrl"} />
                            +
                            <Hotkey text="K" />
                        </div>
                    </>
                }
                disabled={!isVisible}
            >
                <ToolbarButton
                    borderButtonProps={{ square: true }}
                    command="toggleOrCreateLink"
                    iconProps={{
                        icon: "link",
                        iconSet: "lucide",
                    }}
                    nodeName="link"
                    onClick={() => {
                        setCurrentOpenMenuType(null);
                    }}
                />
            </EditorToolbarTooltip>
            {handleImageUpload ? (
                <AddImageMenu>
                    <EditorToolbarTooltip
                        content={<span>Insert image</span>}
                        disabled={!isVisible || currentOpenMenuType === ToolbarMenuType.IMAGE}
                    >
                        <ToolbarButton
                            borderButtonProps={{ square: true }}
                            iconProps={{
                                icon: "image",
                                iconSet: "lucide",
                            }}
                            isActive={currentOpenMenuType === ToolbarMenuType.IMAGE}
                            onClick={() => {
                                toggleMenu({ menuType: ToolbarMenuType.IMAGE });
                            }}
                        />
                    </EditorToolbarTooltip>
                </AddImageMenu>
            ) : null}
        </ToolbarButtonGroup>
    );
}

function CondensedExtrasGroup() {
    const { currentOpenMenuType, toggleMenu } = useEditorToolbarContext();

    return (
        <ToolbarButtonGroup>
            <CondensedExtrasMenu>
                <BorderButton
                    className={classNames(
                        styles.toolbarButton,
                        currentOpenMenuType === ToolbarMenuType.EXTRAS && styles.isActive
                    )}
                    content={
                        <>
                            <Icon icon="ellipsis" iconSet="c9r" />
                            <Icon
                                icon="chevron-down"
                                iconSet="lucide"
                                iconSize={14}
                                strokeWidth={2}
                            />
                        </>
                    }
                    contentClassName={styles.toolbarButtonContent}
                    instrumentation={{
                        elementName: "editor.toolbar_btn.extras",
                    }}
                    minimal
                    onClick={() => {
                        toggleMenu({ menuType: ToolbarMenuType.EXTRAS });
                    }}
                    small
                    square
                    tabIndex={-1}
                />
            </CondensedExtrasMenu>
        </ToolbarButtonGroup>
    );
}

function CondensedToolbarContent() {
    return (
        <>
            <FormattingGroup strikethrough={false} />
            <CondensedExtrasGroup />
        </>
    );
}

function NormalToolbarContent() {
    return (
        <>
            <FormattingGroup headings={false} />
            <HeadingsGroup />
            <ListsGroup />
            <BlockFormattingGroup />
            <InsertElementsGroup />
        </>
    );
}

export type EditorToolbarProps = {
    className?: string;
    editor: TEditor;
    handleImageUpload?: (files: File[]) => void;
    rightElement?: React.ReactNode;
    useStickyScrolling?: boolean;
    visible: boolean;
};

export function EditorToolbar({
    className,
    editor,
    handleImageUpload,
    rightElement,
    useStickyScrolling,
    visible,
}: EditorToolbarProps) {
    const ref = useRef<HTMLDivElement>(null);

    // Rerender if window size changes, so that we can recompute the toolbar width and decide
    // whether to show the condensed version.
    useWindowSize();

    if (!editor) {
        return null;
    }

    const useCondensedToolbar = ref.current ? ref.current?.offsetWidth < 400 : null;

    return (
        <EditorToolbarContextProvider
            editor={editor}
            handleImageUpload={handleImageUpload}
            isVisible={visible}
        >
            <div
                className={classNames(
                    className,
                    styles.toolbarPalette,
                    useStickyScrolling && styles.toolbarPaletteSticky
                )}
            >
                <div className={styles.toolbarPaletteButtons} ref={ref}>
                    {useCondensedToolbar === null ? null : useCondensedToolbar ? (
                        <CondensedToolbarContent />
                    ) : (
                        <NormalToolbarContent />
                    )}
                </div>
                {rightElement ?? null}
            </div>
        </EditorToolbarContextProvider>
    );
}
