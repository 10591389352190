import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Storage } from "lib/Storage";
import { createCtx } from "lib/react/Context";

type AppLayoutState = {
    isSideNavCollapsed?: boolean;
};

export type AppLayoutContextValue = {
    toggleIsSideNavCollapsed: () => void;
    appLayoutState: AppLayoutState;
};

const [useAppLayout, ContextProvider] = createCtx<AppLayoutContextValue>();

export { useAppLayout };

export type AppLayoutProviderProps = {
    children: React.ReactNode;
};

export function AppLayoutProvider({ children }: AppLayoutProviderProps) {
    const storageKey = "layout";
    const [appLayoutState, setAppLayoutState] = useState<AppLayoutState>(
        (Storage.All.getItem(storageKey) ?? {}) as AppLayoutState
    );

    useEffect(() => {
        Storage.All.setItem(storageKey, appLayoutState);
    }, [appLayoutState, storageKey]);

    const toggleIsSideNavCollapsed = useCallback(() => {
        setAppLayoutState(prev => ({ ...prev, isSideNavCollapsed: !prev.isSideNavCollapsed }));
    }, []);

    const value = useMemo(
        () => ({
            appLayoutState,
            toggleIsSideNavCollapsed,
        }),
        [appLayoutState, toggleIsSideNavCollapsed]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
