import React from "react";

import { QueryResult as ApolloQueryResult } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";

import { useTrackViewQueryLoading } from "components/loading/Loading";
import { useLiveQuery } from "lib/apollo/useLiveQuery";
import {
    ReplicacheQueryResult,
    useReplicacheGraphQLLiveQuery,
} from "lib/replicache/graphql/LocalServer";

export type TQueryResult<TData> = ApolloQueryResult<TData> | ReplicacheQueryResult<TData>;

type TChildren<TData = any> = ({
    queryResult,
}: {
    queryResult: TQueryResult<TData>;
}) => React.ReactNode;

type LoaderProps<TData = any, TVariables extends Record<string, any> = Record<string, any>> = {
    children: TChildren<TData>;
    query: TypedDocumentNode<TData, TVariables>;
    variables?: TVariables;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LegacyLoader<TData = any, TVariables extends Record<string, any> = Record<string, any>>({
    children,
    query,
    variables,
}: LoaderProps<TData, TVariables>) {
    const queryResult = useLiveQuery({ query, variables });

    return <>{children({ queryResult })}</>;
}

function ReplicacheLoader<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
>({ children, query, variables }: LoaderProps<TData, TVariables>) {
    const queryResult = useReplicacheGraphQLLiveQuery({ query, variables });

    return <>{children({ queryResult })}</>;
}

function TrackViewQuery<TData = any>({
    children,
    queryResult,
}: {
    children: TChildren<TData>;
    queryResult: TQueryResult<TData>;
}) {
    useTrackViewQueryLoading(queryResult);

    return <>{children({ queryResult })}</>;
}

export type QueryLoaderProps<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
> = {
    children: TChildren<TData>;
    query: TypedDocumentNode<TData, TVariables>;
    variables?: TVariables;
};

export function QueryLoader<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
>({ children, query, variables }: QueryLoaderProps<TData, TVariables>) {
    const Loader = ReplicacheLoader;

    return (
        <Loader query={query} variables={variables}>
            {children}
        </Loader>
    );
}

export function ViewQueryLoader<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
>({ children, query, variables }: QueryLoaderProps<TData, TVariables>) {
    const Loader = ReplicacheLoader;
    return (
        <Loader query={query} variables={variables}>
            {({ queryResult }) => (
                <TrackViewQuery queryResult={queryResult}>{children}</TrackViewQuery>
            )}
        </Loader>
    );
}
