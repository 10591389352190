import React, { useRef } from "react";

import { CommonEnums, sortLabels } from "c9r-common";
import classNames from "classnames";

import { Label } from "components/shared/Label";
import { DynamicWidthTable } from "components/ui/common/DynamicWidthTable";
import { Icon } from "components/ui/core/Icon";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./TicketMenu.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketMenuItemLayout_ticket on tickets {
            id
            ref
            title

            board {
                id
                access_type
                display_name
            }

            label_attachments {
                color
                text
            }
        }
    `),
};

export type TicketMenuWrapperProps = {
    children: React.ReactNode;

    /** List of dependencies that indicate when any content in the table may have changed, so that the column widths can be recomputed. */
    dependencies: any[];
};

export function TicketMenuWrapper({ children, dependencies }: TicketMenuWrapperProps) {
    return (
        <DynamicWidthTable
            columnDefinitions={TicketMenuWrapperDynamicWidthTableColumnDefinitions}
            dependencies={dependencies}
        >
            {children}
        </DynamicWidthTable>
    );
}

export type TicketMenuItemProps = {
    className?: string;
    children: React.ReactNode;
} & MenuItemProps;

export function TicketMenuItem({ className, children, ...menuItemProps }: TicketMenuItemProps) {
    return (
        <MenuItem className={classNames(className, styles.ticketMenuItem)} {...menuItemProps}>
            {children}
        </MenuItem>
    );
}

export const TicketMenuItemClassName = styles.ticketMenuItem;

export type TicketMenuItemLayoutProps = {
    showBoardName?: boolean;
    showLabels?: boolean;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TicketMenuItemLayout({
    showBoardName = true,
    showLabels = true,
    ticket: _ticketFragment,
}: TicketMenuItemLayoutProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const maxLabelsToDisplay = 3;
    const ticketLayoutContainerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLSpanElement>(null);
    const currentUser = useCurrentUser();
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    return (
        <div ref={ticketLayoutContainerRef} className={styles.ticketMenuItemLayout}>
            <div className={styles.ticket}>
                <Icon
                    className={styles.ticketIcon}
                    icon="file-text"
                    iconSet="lucide"
                    iconSize={16}
                    strokeWidth={1}
                />
                <span ref={titleRef} className={styles.title}>
                    {ticket.title}
                </span>
                {shouldShowTicketRefs ? (
                    <span className={styles.reference}>(#{ticket.ref})</span>
                ) : null}
                {showLabels && !!ticket.label_attachments.length && (
                    <span className={styles.labels}>
                        {ticket.label_attachments
                            .slice(0, maxLabelsToDisplay)
                            .sort(sortLabels())
                            .map(label => (
                                <Label
                                    key={`${label.color}|${label.text}`}
                                    className={styles.label}
                                    color={label.color}
                                    text={label.text}
                                    small
                                />
                            ))}
                        {ticket.label_attachments.length > maxLabelsToDisplay && (
                            <span className={styles.labelsOverflow}>
                                +{ticket.label_attachments.length - maxLabelsToDisplay}
                            </span>
                        )}
                    </span>
                )}
            </div>
            {showBoardName && currentUser.org.is_multi_board && (
                <>
                    <div style={{ flex: "1 1 auto" }} />
                    <div className={styles.board}>
                        {ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                            <Icon
                                icon="lock"
                                iconSet="c9r"
                                iconSize={12}
                                strokeWidthAbsolute={1.5}
                            />
                        ) : (
                            <Icon icon="spaceFrame" iconSet="c9r" iconSize={12} strokeWeight={1} />
                        )}
                        <span className={styles.boardName}>{ticket.board.display_name}</span>
                    </div>
                </>
            )}
        </div>
    );
}

export const TicketMenuWrapperDynamicWidthTableColumnDefinitions = [
    {
        columnClassName: styles.board,
    },
];
