import React, { useEffect, useState } from "react";

import { Log } from "lib/Log";

export function DebugConsoleError() {
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.error("Message from console.error");
    }, []);

    return <p>An error has been logged via console.error.</p>;
}

export function DebugFetchError() {
    useEffect(() => {
        (async () => {
            try {
                await fetch("http://sdfdsfsd");
            } catch (error) {
                Log.error("Caught a fetch error", { error });
            }
        })();
    }, []);

    return <p>A fetch error was triggered and caught.</p>;
}

export function DebugLogError() {
    useEffect(() => {
        Log.error("Message from Log.error", { contextFieldExample: "foobar" });
    }, []);

    return <p>An error has been logged via Log.error.</p>;
}

export function DebugReactComponentError() {
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowError(true);
        }, 5000);
    }, []);

    if (showError) {
        const obj = {};

        // @ts-expect-error
        // eslint-disable-next-line no-console
        console.log(obj.foo.bar);

        return null;
    }

    return <p>A React component rendering error will be generated in a few seconds.</p>;
}

export function DebugUncaughtError() {
    useEffect(() => {
        setTimeout(() => {
            throw new Error("Message from Error object");
        }, 5000);
    }, []);

    return <p>An error will be thrown in a few seconds.</p>;
}
