export type ResolvedFieldSpecifier = {
    entry: {
        __typename: string;
        id: string;
    };
    fieldName: string;
};

/**
 * Helper class to cache resolved values (or promises for those values) of GraphQL fields that
 * are expensive to compute. Useful for resolving computed fields.
 */
export class ResolvedFieldValueCache {
    cache: Map<string, unknown>;

    constructor() {
        this.cache = new Map<string, unknown>();
    }

    private static buildKey({ entry, fieldName }: ResolvedFieldSpecifier) {
        return `${entry.__typename}:${entry.id}:${fieldName}`;
    }

    get<T>({ entry, fieldName }: ResolvedFieldSpecifier) {
        return this.cache.get(ResolvedFieldValueCache.buildKey({ entry, fieldName })) as
            | T
            | undefined;
    }

    set<T>({ entry, fieldName }: ResolvedFieldSpecifier & {}, value: T) {
        this.cache.set(ResolvedFieldValueCache.buildKey({ entry, fieldName }), value);

        return value;
    }
}
