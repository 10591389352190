import React, { useCallback, useEffect, useRef, useState } from "react";

import { offset } from "@floating-ui/dom";
import classNames from "classnames";
import Shepherd from "shepherd.js";

import { Config } from "Config";
import { BorderButton } from "components/ui/core/BorderButton";
import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { ProductTourElementClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useOnPathChange } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { createCtx } from "lib/react/Context";
import { isDefined, isSVGElement } from "lib/types/guards";

import styles from "./DemoTour.module.scss";

function getTimelineButtonId({ stepId }: { stepId: string }) {
    return `demo-timeline__${stepId}`;
}

function useGetDetailsForNavigateTo() {
    const currentUser = useCurrentUser();
    const { buildBoardUrl, buildTicketUrl, buildUserUrl } = useUrlBuilders();

    const getDetailsForNavigateTo = useCallback(
        ({ navigateTo }: { navigateTo: TNavigateTo }) => {
            switch (navigateTo.appPage) {
                case Enums.AppPage.BOARD:
                    return {
                        pathname: buildBoardUrl({
                            boardSlug: navigateTo.boardSlug,
                        }).pathname,
                        prefetchArgs: null,
                    };

                case Enums.AppPage.TICKET_DETAIL:
                    return {
                        pathname: buildTicketUrl({
                            ticketSlug: navigateTo.ticketSlug,
                        }).pathname,
                        prefetchArgs: {
                            query: Queries.get({ component: "DetailView", name: "component" }),
                            variables: {
                                orgId: currentUser.org_id,
                                ref: navigateTo.ticketRef,
                            },
                        },
                    };

                case Enums.AppPage.USER: {
                    const userId = currentUser.org.users.find(u => u.name === navigateTo.username)
                        ?.id;

                    return {
                        pathname: buildUserUrl({
                            userSlug: navigateTo.userSlug,
                        }).pathname,
                        prefetchArgs: userId
                            ? {
                                  query: Queries.get({ component: "PlanView", name: "component" }),
                                  variables: {
                                      userId,
                                  },
                              }
                            : null,
                    };
                }

                default:
                    return { pathname: null, prefetchArgs: null };
            }
        },
        [buildBoardUrl, buildTicketUrl, buildUserUrl, currentUser.org_id, currentUser.org.users]
    );

    return { getDetailsForNavigateTo };
}

async function waitForElement({
    selector,
    intervalMs = 100,
    timeoutMs = 500,
}: {
    selector: string;
    intervalMs?: number;
    timeoutMs?: number;
}) {
    const startTime = Date.now();

    while (Date.now() - startTime < timeoutMs) {
        const element = document.querySelector(selector);

        if (element) {
            return element;
        }

        await new Promise(resolve => setTimeout(resolve, intervalMs));
    }

    return null;
}

type TNavigateTo =
    | {
          appPage: typeof Enums.AppPage.BOARD;
          desktopOnly?: boolean;
          boardDisplayName: string;
          boardSlug: string;
      }
    | {
          appPage: typeof Enums.AppPage.TICKET_DETAIL;
          desktopOnly?: boolean;
          ticketRef: string;
          ticketSlug: string;
      }
    | {
          appPage: typeof Enums.AppPage.USER;
          desktopOnly?: boolean;
          username: string;
          userSlug: string;
      };

type StepConfig = {
    id: string;
    displayName: string;
    attachTo?: {
        selector: string;
        on:
            | "top"
            | "top-start"
            | "top-end"
            | "bottom"
            | "bottom-start"
            | "bottom-end"
            | "right"
            | "right-start"
            | "right-end"
            | "left"
            | "left-start"
            | "left-end";
    };
    navigateTo?: TNavigateTo;
    waitForSelector?: string;
} & Pick<
    Shepherd.Step.StepOptions,
    | "arrow"
    | "floatingUIOptions"
    | "modalOverlayOpeningPadding"
    | "modalOverlayOpeningRadius"
    | "showOn"
    | "title"
    | "text"
>;

const stepsConfig: StepConfig[] = [
    {
        id: "content",
        displayName: "Content",
        arrow: false,
        attachTo: {
            selector: `.${ProductTourElementClasses.TICKET_HEADER_AND_DOCUMENT}`,
            on: "right-start",
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: -20, alignmentAxis: 20 })],
        },
        navigateTo: {
            appPage: Enums.AppPage.TICKET_DETAIL,
            ticketRef: Config.demoUI.tour.exampleTicketRef,
            ticketSlug: Config.demoUI.tour.exampleTicketSlug,
        },
        title: "<strong>Topics</strong> capture everything about all the things",
        text: `
            <p>
                You've heard "get on the same page"? Well, here's the page. Flat's
                <strong>topics</strong> keep the pieces of the work together — the description,
                links, files, and checklists — so everyone can stop hunting for things all day.
            </p>

            <ul>
                <li>Create a topic for everything your team needs to deliver, discuss, or stay on top of.</li>
                <li>Use the built-in document to keep detailed context (like what needs to be done and why) together with all the links and files.</li>
                <li>Easily break work down into smaller tasks or roll it up into larger goals.</li>
            </ul>
        `,
    },
    {
        id: "discussions",
        displayName: "Discussions",
        arrow: false,
        attachTo: {
            selector: `.${ProductTourElementClasses.TICKET_DISCUSSION_PANEL}`,
            on: "left-start",
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 20, alignmentAxis: 50 })],
        },
        modalOverlayOpeningPadding: 8,
        modalOverlayOpeningRadius: 4,
        navigateTo: {
            appPage: Enums.AppPage.TICKET_DETAIL,
            ticketRef: Config.demoUI.tour.exampleTicketRef,
            ticketSlug: Config.demoUI.tour.exampleTicketSlug,
        },
        title: "<strong>Topic threads</strong> are as easy as chat, minus the anxiety",
        text: `
            <p>
                Flat’s <strong>topic threads</strong> let you ask questions, make requests, and
                raise issues without interrupting your teammates and without worrying about
                them becoming dropped balls.
            </p>

            <ul>
                <li>Threads are designed for focused, async conversations that resolve to decisions or actions — not aimless, endless chats.</li>
                <li>Blockers are special threads for highlighting problems. And unlike chat messages, they stick around until the issue's resolved.</li>
            </ul>
         `,
    },
    {
        id: "workspaces",
        displayName: "Workspaces",
        attachTo: {
            selector: `.${ProductTourElementClasses.SIDENAV_WORKSPACES_SECTION}`,
            on: "right",
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 16 })],
        },
        navigateTo: {
            appPage: Enums.AppPage.TICKET_DETAIL,
            ticketRef: Config.demoUI.tour.exampleTicketRef,
            ticketSlug: Config.demoUI.tour.exampleTicketSlug,
        },
        title: "<strong>Workspaces</strong> organize your organization",
        text: `
            <p>
                Simple and flexible <strong>workspaces</strong> keep topics organized by
                department, major project, or long-running program. They’re great for
                bringing cross-functional teams together, too.
            </p>

            <ul>
                <li>Workspaces are public by default to help break down silos in your organization.</li>
            </ul>
         `,
    },
    {
        id: "visibility",
        displayName: "Visibility",
        arrow: false,
        attachTo: {
            selector: `.${ProductTourElementClasses.WORKSPACE_STAGE}`,
            on: "left-start",
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 20, alignmentAxis: 50 })],
        },
        modalOverlayOpeningRadius: 4,
        navigateTo: {
            appPage: Enums.AppPage.BOARD,
            boardDisplayName: Config.demoUI.tour.exampleBoardDisplayName,
            boardSlug: Config.demoUI.tour.exampleBoardSlug,
        },
        title: "Always know the state of play",
        text: `
            <p>
                The <strong>workspace board view</strong> gives you the big picture at a glance.
                Know where every topic stands, who’s working on what, and how it’s all coming
                along, without having to ask.
            </p>

            <ul>
                <li>Every workspace has its own easily-customized workflow.</li>
                <li>Threads and blockers are surfaced so it’s clear to everyone when there’s a conversation or problem in need of follow-up.</li>
            </ul>
        `,
    },
    {
        id: "planner",
        displayName: "Planner",
        arrow: false,
        attachTo: {
            selector: `.${ProductTourElementClasses.LEFT_SIDE}`,
            on: "right",
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 20, crossAxis: -100 })],
        },
        navigateTo: {
            appPage: Enums.AppPage.USER,
            desktopOnly: true,
            username: Config.demoUI.tour.exampleUsername,
            userSlug: Config.demoUI.tour.exampleUserSlug,
        },
        title: "Workload becomes workday in your <strong>planner</strong>",
        text: `
            <p>
                Flat’s <strong>personal planner</strong> consolidates all of your work across
                all workspaces into one place.
            </p>
            <ul>
                <li>Prioritize your work into simple <i>Now</i>/<i>Next</i>/<i>Later</i> buckets. Everything you haven’t prioritized yet is in your <i>Inbox</i>.</li>
                <li>Use the <i>Dates</i> view to stay on top of upcoming deadlines.</li>
                <li>View your teammates’ plans to make sure they’re aligned on priorities without having to chat and interrupt them.</li>
            </ul>
        `,
    },
    {
        id: "end",
        displayName: "Why Flat?",
        navigateTo: {
            appPage: Enums.AppPage.BOARD,
            boardDisplayName: Config.demoUI.tour.exampleBoardDisplayName,
            boardSlug: Config.demoUI.tour.exampleBoardSlug,
        },
        title: "That's all there is to it. So <strong>why Flat</strong>?",
        text: `
            <p>
                Because a teamwork tool shouldn’t <em>add</em> to your team’s workload.
            </p>
            <p>
                Because if a tool is too hard to use, let’s face it — your team probably won’t!
            </p>
            <p>
                Flat lets you track, organize, and discuss your team’s work in <em>the simplest,
                lightest, and fastest way possible</em>.
            </p>
            <p>
                With Flat, you get a calm home base with no complex setup and no learning
                curve. So your team can focus on getting the <em>real</em> work done.
            </p>
        `,
        waitForSelector: `.${ProductTourElementClasses.WORKSPACE_STAGE}`,
    },
];

function useStepsConfig({
    setIsNavigating,
}: {
    setIsNavigating: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const breakpoints = useBreakpoints();
    const { history } = useHistory();
    const { getDetailsForNavigateTo } = useGetDetailsForNavigateTo();
    const isMobile = breakpoints.smMax;

    const createShepherdStep = useCallback(
        ({
            stepConfig,
            prevStepConfig,
            nextStepConfig,
        }: {
            stepConfig: StepConfig;
            prevStepConfig: StepConfig | null;
            nextStepConfig: StepConfig | null;
        }): Shepherd.Step.StepOptions => {
            const attachTo = isMobile
                ? undefined
                : stepConfig.attachTo
                ? { element: stepConfig.attachTo.selector, on: stepConfig.attachTo.on }
                : ({
                      element: `#${getTimelineButtonId({ stepId: stepConfig.id })}`,
                      on: "top",
                  } as const);

            return {
                ...stepConfig,
                attachTo,
                async beforeShowPromise() {
                    if (stepConfig.navigateTo && !(isMobile && stepConfig.navigateTo.desktopOnly)) {
                        const { pathname } = getDetailsForNavigateTo({
                            navigateTo: stepConfig.navigateTo,
                        });

                        if (pathname && history.location.pathname !== pathname) {
                            setIsNavigating(true);
                            history.push(pathname);
                        }
                    }

                    if (attachTo) {
                        await waitForElement({ selector: attachTo.element });
                    }

                    if (stepConfig.waitForSelector) {
                        await waitForElement({ selector: stepConfig.waitForSelector });
                    }

                    setImmediate(() => setIsNavigating(false));
                },
                buttons: [
                    prevStepConfig
                        ? {
                              classes: classNames(
                                  styles.stepPopupNavButton,
                                  styles.stepPopupNavButtonBack
                              ),
                              text: "Back",
                              action() {
                                  // Shepherd binds this to the tour, but TypeScript can't infer that.
                                  // @ts-ignore
                                  this.back();
                              },
                          }
                        : null,
                    nextStepConfig
                        ? {
                              classes: classNames(
                                  styles.stepPopupNavButton,
                                  styles.stepPopupNavButtonNext
                              ),
                              text: `Next stop: ${nextStepConfig.displayName}`,
                              action() {
                                  // Shepherd binds this to the tour, but TypeScript can't infer that.
                                  // @ts-ignore
                                  this.next();
                              },
                          }
                        : {
                              classes: classNames(styles.stepPopupNavButton),
                              text: "Close",
                              action() {
                                  // Shepherd binds this to the tour, but TypeScript can't infer that.
                                  // @ts-ignore
                                  this.complete();
                                  // @ts-ignore
                                  this.cancel();
                              },
                          },
                ].filter(isDefined),
                floatingUIOptions: isMobile
                    ? undefined
                    : !stepConfig.floatingUIOptions && !stepConfig.attachTo
                    ? {
                          middleware: [offset({ mainAxis: 20 })],
                      }
                    : stepConfig.floatingUIOptions,
            };
        },
        [getDetailsForNavigateTo, history, isMobile, setIsNavigating]
    );

    return { createShepherdStep };
}

const [useDemoTour, ContextProvider] = createCtx<{
    activeStepId: string | null;
    startTourAt: (stepId: string) => void;
    tour: Shepherd.Tour;
}>();

export { useDemoTour };

export type DemoTourProviderProps = {
    children?: React.ReactNode;
};

export function DemoTourProvider({ children }: DemoTourProviderProps) {
    const prefetchQuery = usePrefetchQuery();
    const [isNavigating, setIsNavigating] = useState(false);
    const { createShepherdStep } = useStepsConfig({ setIsNavigating });
    const { getDetailsForNavigateTo } = useGetDetailsForNavigateTo();
    const [activeStepId, setActiveStepId] = useState<string | null>(null);

    const tourRef = useRef(
        new Shepherd.Tour({
            defaultStepOptions: {
                arrow: true,
                cancelIcon: {
                    enabled: false,
                },
                classes: styles.stepPopup,
            },
            useModalOverlay: true,
            steps: stepsConfig.map((stepConfig, i) =>
                createShepherdStep({
                    stepConfig,
                    prevStepConfig: stepsConfig[i - 1] ?? null,
                    nextStepConfig: stepsConfig[i + 1] ?? null,
                })
            ),
        })
    );
    const tour = tourRef.current;

    const startTourAt = useCallback(
        (stepId: string) => {
            // As of May 2023, annoyingly, Shepherd doesn't expose a direct method to start a tour
            // at anything other than the first step. To work around that, we set all steps before
            // the given one to not show, then start the tour, then reset them.

            for (const stepConfig of stepsConfig.filter(s => s.id !== stepId)) {
                const step = tour.getById(stepConfig.id);

                if (step) {
                    step.options.showOn = () => false;
                }
            }

            tour.start();

            for (const stepConfig of stepsConfig) {
                const step = tour.getById(stepConfig.id);

                if (step) {
                    step.options.showOn = () => true;
                }
            }
        },
        [tour]
    );

    useEffect(() => {
        const events = ["cancel", "complete", "show"];
        const syncActiveStepId = ({ step }: { step?: Shepherd.Step } = {}) => {
            setActiveStepId(step?.id ?? null);
        };

        events.forEach(event => tour.on(event, syncActiveStepId));

        return () => {
            events.forEach(event => tour.off(event, syncActiveStepId));
        };
    }, [tour]);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (
                event.target &&
                isSVGElement(event.target) &&
                event.target.parentElement &&
                event.target.parentElement.classList.contains("shepherd-modal-is-visible") &&
                event.target.parentElement.classList.contains("shepherd-modal-overlay-container")
            ) {
                tour.cancel();
            }
        };

        document.addEventListener("click", listener);

        return () => {
            document.removeEventListener("click", listener);
        };
    }, [tour]);

    useOnPathChange(() => {
        if (!isNavigating) {
            tour.cancel();
        }
    });

    useEffect(() => {
        for (const stepConfig of stepsConfig) {
            if (stepConfig.navigateTo) {
                const { prefetchArgs } = getDetailsForNavigateTo({
                    navigateTo: stepConfig.navigateTo,
                });

                if (prefetchArgs) {
                    void prefetchQuery(prefetchArgs);
                }
            }
        }
    }, [getDetailsForNavigateTo, prefetchQuery]);

    return (
        <ContextProvider value={{ activeStepId, startTourAt, tour }}>{children}</ContextProvider>
    );
}

type DemoTimelineButtonProps = {
    stepConfig: StepConfig;
    position: "leftmost" | "interior" | "rightmost";
};

function DemoTimelineButton({ stepConfig, position }: DemoTimelineButtonProps) {
    const { activeStepId, startTourAt, tour } = useDemoTour();

    return (
        <BorderButton
            id={getTimelineButtonId({ stepId: stepConfig.id })}
            className={classNames(styles.button, stepConfig.id === activeStepId && styles.selected)}
            content={
                <>
                    <div className={styles.graphic}>
                        <div className={classNames(styles.track, styles[position])} />
                        <div className={styles.station} />
                    </div>
                    <div className={styles.text}>{stepConfig.displayName}</div>
                </>
            }
            onClick={() => {
                if (activeStepId !== stepConfig.id) {
                    if (tour.isActive()) {
                        tour.show(stepConfig.id);
                    } else {
                        startTourAt(stepConfig.id);
                    }
                }

                if (activeStepId === stepConfig.id) {
                    tour.cancel();
                }
            }}
            instrumentation={{
                elementName: "demo_tour.timeline_btn",
                eventData: {
                    stepId: stepConfig.id,
                },
            }}
            minimal
            sharpest
            zeroPadding
        />
    );
}

export function DemoTimelineButtons() {
    const getButtonPosition = useCallback((index: number) => {
        if (index === 0) {
            return "leftmost";
        }

        if (index === stepsConfig.length - 1) {
            return "rightmost";
        }

        return "interior";
    }, []);

    return (
        <div className={styles.buttons}>
            {stepsConfig.map((stepConfig, index) => (
                <DemoTimelineButton
                    key={stepConfig.id}
                    stepConfig={stepConfig}
                    position={getButtonPosition(index)}
                />
            ))}
        </div>
    );
}

export function DemoTimelineStartTourButton() {
    const { activeStepId, tour } = useDemoTour();
    const isActive = !!activeStepId;

    return (
        <BorderButton
            className={classNames(styles.startTourButton)}
            content={!isActive ? "Start tour" : "Exit tour"}
            flushLeft
            leftIconProps={
                !isActive
                    ? {
                          icon: "map",
                          iconSet: "lucide",
                          iconSize: 24,
                          strokeWidth: 1,
                      }
                    : undefined
            }
            minimal
            onClick={() => {
                if (tour.isActive()) {
                    tour.cancel();
                } else {
                    tour.start();
                }
            }}
            instrumentation={{
                elementName: "demo_tour.start_tour_btn",
            }}
        />
    );
}
