import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";

import { useCommentSearchIndex } from "components/search/CommentsSearchIndex";
import { useTaskSearchIndex } from "components/search/TasksSearchIndex";
import { TSearchIndexTicket, TicketSearchIndex } from "components/search/TicketSearchIndex";
import { useTicketSearch } from "contexts/TicketSearchContext";
import { useRecordTicketSearch } from "lib/Instrumentation";
import { useHistory } from "lib/Routing";
import { getFragmentData } from "lib/graphql/__generated__";
import {
    CommentsSearchIndex_commentFragment,
    TasksSearchIndex_taskFragment,
    TicketActivityDatesInfo_ticketFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

export type TSearchInOption = { name: string; fields: string[] };
export type TSortByOption = {
    name: string;
    fn: (a: TSearchIndexTicket, b: TSearchIndexTicket) => number;
    searchParamKey: string;
};

const searchInOptions: TSearchInOption[] = [
    { name: "Title", fields: ["title"] },
    { name: "Labels", fields: ["labels"] },
    { name: "Description", fields: ["description"] },
    { name: "Tasks", fields: ["tasks"] },
    { name: "Comments", fields: ["comments", "blockers"] },
];

export type SearchViewContextValue = {
    areIndicesLoading: boolean;
    query: string;
    handleQueryChange: (newQuery: string) => void;
    selectedSearchInOptions: TSearchInOption[];
    handleSearchInChange: (searchInOption: TSearchInOption, removeSearchInOption?: boolean) => void;
    searchInOptions: {
        name: string;
        fields: string[];
    }[];
    searchResultItems: {
        ticket: TSearchIndexTicket;
        matches: string[];
    }[];
    sortByOptions: TSortByOption[];
    sortByOption: TSortByOption;
    setSortByOption: React.Dispatch<React.SetStateAction<TSortByOption>>;
    matchingCommentsByTicketId: Record<string, CommentsSearchIndex_commentFragment[]>;
    matchingTasksByTicketId: Record<string, TasksSearchIndex_taskFragment[]>;
    pageSize: number;
    currentPageNum: number;
    maxPageNum: number;
    firstIndexToDisplay: number;
    goToPageNum: ({ pageNum }?: { pageNum?: number | undefined }) => void;
    goForward: ({ increment }?: { increment?: number | undefined }) => void;
    goBackward: ({ decrement }?: { decrement?: number | undefined }) => void;
    maybeSlicePages: () => number[];
};

const [useSearchView, ContextProvider] = createCtx<SearchViewContextValue>();

export { useSearchView };

function useGetPageNumFromUrlQuery() {
    const { history } = useHistory();

    return useCallback(() => {
        const pageQueryParam = new URLSearchParams(history.location.search).get("page");

        if (!pageQueryParam) {
            return null;
        }

        const pageQueryNum = parseInt(pageQueryParam);

        return isNaN(pageQueryNum) || pageQueryNum < 1 ? null : pageQueryNum;
    }, [history]);
}

/**
 * Hook to manage the search view pagination.
 */
const usePagination = ({
    count = 0,
    pageSize = 10,
    pagesToDisplay = 7,
    isDataReady,
}: {
    /** The amount of items to be paginated. */
    count?: number;

    /** The amount of items to be displayed per page. */
    pageSize?: number;

    /** The max amount of pages to be displayed. */
    pagesToDisplay?: number;

    /** Whether the data used for the count has been loaded. */
    isDataReady?: boolean;
}) => {
    const { setUrlSearchParam } = useHistory();
    const getPageNumFromUrlQuery = useGetPageNumFromUrlQuery();
    const [currentPageNum, setCurrentPageNum] = useState(getPageNumFromUrlQuery() ?? 1);
    const [maxPageNum, setMaxPageNum] = useState(0);
    const [firstIndexToDisplay, setFirstIndexToDisplay] = useState(0);

    useLayoutEffect(() => setMaxPageNum(count <= pageSize ? 1 : Math.ceil(count / pageSize)), [
        count,
        pageSize,
    ]);

    useLayoutEffect(
        () => setFirstIndexToDisplay(currentPageNum <= 1 ? 0 : (currentPageNum - 1) * pageSize),
        [currentPageNum, pageSize]
    );

    useEffect(() => setUrlSearchParam({ key: "page", val: String(currentPageNum) }), [
        currentPageNum,
        setUrlSearchParam,
    ]);

    useEffect(() => {
        if (isDataReady) {
            // This handles setting the current page from the URL if the query param exceeds the number of available pages.
            // The trick here is to use the updated maxPageNum set in the previous useLayoutEffect as soon as it gets updated
            // and then only run this the first time the data used for the count (e.g. search results) gets loaded.
            setMaxPageNum(updatedMaxPageNum => {
                const pageNumFromUrlQuery = getPageNumFromUrlQuery();

                if (!pageNumFromUrlQuery) {
                    return updatedMaxPageNum;
                }

                if (pageNumFromUrlQuery > updatedMaxPageNum) {
                    setCurrentPageNum(updatedMaxPageNum);
                }

                if (pageNumFromUrlQuery < 1) {
                    setCurrentPageNum(1);
                }

                return updatedMaxPageNum;
            });
        }
    }, [getPageNumFromUrlQuery, isDataReady]);

    const goToPageNum = useCallback(({ pageNum = 1 } = {}) => setCurrentPageNum(pageNum), []);

    const goForward = useCallback(
        ({ increment = 1 } = {}) =>
            setCurrentPageNum(curr => Math.min(curr + increment, maxPageNum)),
        [maxPageNum]
    );

    const goBackward = useCallback(
        ({ decrement = 1 } = {}) => setCurrentPageNum(curr => Math.max(curr - decrement, 0)),
        []
    );

    const maybeSlicePages = useCallback(() => {
        const numOfSidePages = Math.floor(pagesToDisplay / 2);

        if (maxPageNum < pagesToDisplay) {
            return [];
        }

        if (currentPageNum <= numOfSidePages) {
            return [0, pagesToDisplay];
        }

        if (currentPageNum > maxPageNum - numOfSidePages) {
            return [maxPageNum - pagesToDisplay, maxPageNum];
        }

        return [currentPageNum - numOfSidePages - 1, currentPageNum + numOfSidePages];
    }, [currentPageNum, maxPageNum, pagesToDisplay]);

    const value = useMemo(
        () => ({
            currentPageNum,
            maxPageNum,
            firstIndexToDisplay, // Index in the data array for the first item that appears on the current page.
            goToPageNum, // Function that goes to a particular page num.
            goForward, // Function to go forward by some number of pages, default 1.
            goBackward, // Function to go backward by some number of pages, default 1.
            maybeSlicePages, // Function to truncate pages to specified pagesToDisplay.
        }),
        [
            currentPageNum,
            maxPageNum,
            firstIndexToDisplay,
            goToPageNum,
            goForward,
            goBackward,
            maybeSlicePages,
        ]
    );

    return value;
};

const useSearchIn = () => {
    const { history, setUrlSearchParam } = useHistory();
    const getInitialSearchInOptions = () => {
        const urlSearchParamValues = new URLSearchParams(history.location.search)
            .get("include")
            ?.split(" ")
            .filter(Boolean);

        const matchingSearchInValues = urlSearchParamValues?.length
            ? searchInOptions.filter(({ fields: [field] }) => urlSearchParamValues.includes(field))
            : [];

        return matchingSearchInValues?.length ? matchingSearchInValues : searchInOptions;
    };
    const [selectedSearchInOptions, setSelectedSearchInOptions] = useState(
        getInitialSearchInOptions()
    );

    useEffect(
        () =>
            setUrlSearchParam({
                key: "include",
                val: selectedSearchInOptions.map(({ fields: [field] }) => field).join(" "),
            }),
        [selectedSearchInOptions, setUrlSearchParam]
    );

    const result = useMemo(
        () => ({
            selectedSearchInOptions,
            setSelectedSearchInOptions,
        }),
        [selectedSearchInOptions]
    );

    return result;
};

const useSortBy = () => {
    const { history, setUrlSearchParam } = useHistory();
    const getTicketActivityFragment = useCallback(
        (ticketFragment: TSearchIndexTicket) =>
            getFragmentData(TicketActivityDatesInfo_ticketFragmentDoc, ticketFragment),
        []
    );
    const sortByOptions = useMemo<TSortByOption[]>(
        () => [
            { name: "Relevance", fn: () => 0, searchParamKey: "default" },
            {
                name: "Recently created",
                fn: (a: TSearchIndexTicket, b: TSearchIndexTicket) =>
                    new Date(
                        getTicketActivityFragment(b).create_or_import_event[0]?.event_at
                    ).getTime() -
                    new Date(
                        getTicketActivityFragment(a).create_or_import_event[0]?.event_at
                    ).getTime(),
                searchParamKey: "created",
            },
            {
                name: "Recently updated",
                fn: (a: TSearchIndexTicket, b: TSearchIndexTicket) =>
                    new Date(
                        getTicketActivityFragment(b).most_recent_update_event[0]?.event_at
                    ).getTime() -
                    new Date(
                        getTicketActivityFragment(a).most_recent_update_event[0]?.event_at
                    ).getTime(),
                searchParamKey: "updated",
            },
            {
                name: "Least recently created",
                fn: (a: TSearchIndexTicket, b: TSearchIndexTicket) =>
                    new Date(
                        getTicketActivityFragment(a).create_or_import_event[0]?.event_at
                    ).getTime() -
                    new Date(
                        getTicketActivityFragment(b).create_or_import_event[0]?.event_at
                    ).getTime(),
                searchParamKey: "created_desc",
            },
            {
                name: "Least recently updated",
                fn: (a: TSearchIndexTicket, b: TSearchIndexTicket) =>
                    new Date(
                        getTicketActivityFragment(a).most_recent_update_event[0]?.event_at
                    ).getTime() -
                    new Date(
                        getTicketActivityFragment(b).most_recent_update_event[0]?.event_at
                    ).getTime(),
                searchParamKey: "updated_desc",
            },
        ],
        [getTicketActivityFragment]
    );

    const urlSearchParamValue = new URLSearchParams(history.location.search).get("sort");
    const [sortByOption, setSortByOption] = useState(
        sortByOptions.find(o => o.searchParamKey === urlSearchParamValue) ?? sortByOptions[0]
    );

    useEffect(
        () =>
            setUrlSearchParam({
                key: "sort",
                val: sortByOption.searchParamKey,
            }),
        [sortByOption, setUrlSearchParam]
    );

    const result = useMemo(
        () => ({
            sortByOption,
            setSortByOption,
            sortByOptions,
        }),
        [sortByOption, sortByOptions]
    );

    return result;
};

export type SearchViewProviderProps = {
    children: React.ReactNode;
};

export function SearchViewProvider({ children }: SearchViewProviderProps) {
    const { history, setUrlSearchParam } = useHistory();
    const [query, setQuery] = useState(
        new URLSearchParams(history.location.search).get("query") ?? ""
    );
    const [searchResultItems, setSearchResultItems] = useState<
        {
            ticket: TSearchIndexTicket;
            matches: string[];
        }[]
    >([]);
    const [allTickets, setAllTickets] = useState<TSearchIndexTicket[] | null>(null);
    const [isSearchResultsReady, setIsSearchResultsReady] = useState(false);
    const { sortByOptions, sortByOption, setSortByOption } = useSortBy();
    const { selectedSearchInOptions, setSelectedSearchInOptions } = useSearchIn();
    const { recordTicketSearch } = useRecordTicketSearch({ elementName: "search.input" });
    const { findTicketsWithMatches, isTicketSearchIndexLoading } = useTicketSearch();
    const comments = useMemo(
        () => (allTickets ? allTickets.flatMap(ticket => ticket.comments) : null),
        [allTickets]
    );
    const shouldReturnComments = useMemo(
        () => selectedSearchInOptions.some(({ fields: [field] }) => field === "comments"),
        [selectedSearchInOptions]
    );
    const tasks = useMemo(
        () =>
            allTickets
                ? allTickets.flatMap(ticket => ticket.tasklists.flatMap(t => t.tasks))
                : null,
        [allTickets]
    );
    const shouldReturnTasks = useMemo(
        () => selectedSearchInOptions.some(({ fields: [field] }) => field === "tasks"),
        [selectedSearchInOptions]
    );
    const { matchingCommentsByTicketId, isCommentSearchIndexLoading } = useCommentSearchIndex({
        comments,
        query,
        shouldReturnComments,
    });
    const { matchingTasksByTicketId, isTaskSearchIndexLoading } = useTaskSearchIndex({
        tasks,
        query,
        shouldReturnTasks,
    });
    const pageSize = 10;
    const pagesToDisplay = 7;
    const {
        currentPageNum,
        maxPageNum,
        firstIndexToDisplay,
        goToPageNum,
        goForward,
        goBackward,
        maybeSlicePages,
    } = usePagination({
        count: searchResultItems.length,
        pageSize,
        pagesToDisplay,
        isDataReady: isSearchResultsReady,
    });

    useEffect(() => {
        if (!isTicketSearchIndexLoading) {
            setSearchResultItems(
                findTicketsWithMatches({
                    searchQuery: query,
                    maxResults: Infinity,
                    fields: ["ref", ...selectedSearchInOptions.flatMap(({ fields }) => fields)],
                })
            );
            setIsSearchResultsReady(true);
        }
    }, [findTicketsWithMatches, isTicketSearchIndexLoading, query, selectedSearchInOptions]);

    useEffect(() => {
        if (!isTicketSearchIndexLoading) {
            setAllTickets(TicketSearchIndex.allTickets());
        }
    }, [isTicketSearchIndexLoading]);

    useEffect(() => recordTicketSearch(query), [query, recordTicketSearch]);

    useEffect(() => setUrlSearchParam({ key: "query", val: query }), [query, setUrlSearchParam]);

    const handleQueryChange = useCallback(
        (newQuery: string) => {
            setQuery(newQuery);
            goToPageNum({
                pageNum: 1,
            });
        },
        [goToPageNum]
    );

    const handleSearchInChange = useCallback(
        (searchInOption: TSearchInOption, removeSearchInOption = false) => {
            setSelectedSearchInOptions(prev =>
                removeSearchInOption
                    ? prev.filter(({ name }) => name !== searchInOption.name)
                    : [searchInOption, ...prev]
            );
            goToPageNum({
                pageNum: 1,
            });
        },
        [goToPageNum, setSelectedSearchInOptions]
    );

    const value = useMemo(
        () => ({
            areIndicesLoading:
                isTicketSearchIndexLoading ||
                isCommentSearchIndexLoading ||
                isTaskSearchIndexLoading,
            query,
            handleQueryChange,
            selectedSearchInOptions,
            handleSearchInChange,
            searchInOptions,
            searchResultItems,
            sortByOptions,
            sortByOption,
            setSortByOption,
            matchingCommentsByTicketId,
            matchingTasksByTicketId,
            pageSize,
            currentPageNum,
            maxPageNum,
            firstIndexToDisplay,
            goToPageNum,
            goForward,
            goBackward,
            maybeSlicePages,
        }),
        [
            query,
            handleQueryChange,
            selectedSearchInOptions,
            handleSearchInChange,
            searchResultItems,
            sortByOptions,
            sortByOption,
            setSortByOption,
            isCommentSearchIndexLoading,
            matchingCommentsByTicketId,
            isTaskSearchIndexLoading,
            matchingTasksByTicketId,
            isTicketSearchIndexLoading,
            currentPageNum,
            maxPageNum,
            firstIndexToDisplay,
            goToPageNum,
            goForward,
            goBackward,
            maybeSlicePages,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
