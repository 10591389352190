import React from "react";

import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon, IconProps } from "components/ui/core/Icon";
import { SelectorMenu, SelectorMenuProps } from "components/ui/core/SelectorMenu";

import styles from "./FilterableSelector.module.scss";

export type FilterableSelectorProps<TItem> = {
    autoFocus?: boolean;

    elementName?: string;

    /**
     * Optional element to show at the right of the filter input..
     */
    filterCaption?: React.ReactNode;

    /**
     * Details about the icon to show at the left of the filter input.
     */
    filterIconProps?: IconProps;

    /**
     * Placeholder text for the filter input.
     */
    filterInputRef?: React.RefObject<HTMLInputElement>;

    /**
     * Placeholder text for the filter input.
     */
    filterPlaceholder?: string;

    /**
     * Whether the filter icon should be relatively weaker than normal.
     */
    filterWeakIcon?: boolean;

    /**
     * Callback when user cancels (via ESC or the cancel button).
     */
    onClose?: () => void;

    /**
     * Whether to use more relaxed padding.
     */
    paddingRelaxed?: boolean;

    /**
     * Whether to use tighter padding.
     */
    paddingTight?: boolean;

    /**
     * Whether the menu items are scrollable. Useful for a selector that are potentially showing
     * many items. Recommended to set to false if the number of items the selector may show is
     * capped to a fixed and relatively small number that always fits on screen.
     */
    scrollable?: boolean;

    /**
     * Whether to show an explicit close button.
     */
    showCloseButton?: boolean;
} & Omit<SelectorMenuProps<TItem>, "filterable" | "filterInputProps">;

// Something about zero state, whether to show the divider before there are results

export function FilterableSelector<TItem>({
    autoFocus = true,
    elementName,
    filterCaption,
    filterIconProps,
    filterInputRef,
    filterPlaceholder,
    filterWeakIcon,
    onClose,
    paddingRelaxed,
    paddingTight,
    scrollable,
    showCloseButton,
    ...selectorMenuProps
}: FilterableSelectorProps<TItem>) {
    return (
        <SelectorMenu
            {...selectorMenuProps}
            filterable
            filterInputProps={{
                autoFocus,
                className: styles.filterInput,
                inputRef: filterInputRef,
                leftIconProps: filterIconProps
                    ? {
                          iconSize: 16,
                          strokeWidth: 1,
                          ...filterIconProps,
                          className: classNames(
                              filterIconProps.className,
                              styles.filterIcon,
                              filterWeakIcon && styles.filterIconWeak
                          ),
                      }
                    : undefined,
                onKeyDown: e => {
                    if (e.key === "Escape") {
                        if (!selectorMenuProps.query) {
                            onClose?.();
                        }
                    }
                },
                placeholder: filterPlaceholder,
                rightElement: (
                    <div className={styles.filterInputRightElement}>
                        {filterCaption ? (
                            <span className={styles.filterInputCaption}>{filterCaption}</span>
                        ) : null}
                        {showCloseButton ? (
                            <BorderButton
                                data-cy="filterable-selector-close-btn"
                                className={styles.closeButton}
                                content={<Icon icon="x" iconSet="lucide" iconSize={18} />}
                                flush
                                minimal
                                small
                                square
                                tighterer
                                onClick={() => {
                                    onClose?.();
                                }}
                                instrumentation={
                                    elementName ? { elementName: `${elementName}.close_btn` } : null
                                }
                            />
                        ) : null}
                    </div>
                ),
            }}
            menuItemsClassName={classNames(
                selectorMenuProps.menuItemsClassName,
                styles.menuItems,
                scrollable && styles.menuItemsScrollable
            )}
            menuProps={{
                ...selectorMenuProps.menuProps,
                className: classNames(
                    selectorMenuProps.menuProps?.className,
                    styles.menu,
                    paddingRelaxed && styles.paddingRelaxed,
                    paddingTight && styles.paddingTight
                ),
            }}
        />
    );
}
