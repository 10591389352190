import { useCallback } from "react";

import { v4 as uuidv4 } from "uuid";

import { useReplicache } from "lib/replicache/Context";

export function useCreateTasklist() {
    const { replicache } = useReplicache();

    const createTasklist = useCallback(
        async ({
            ticketId,
            stageId,
            title,
        }: {
            ticketId: string;
            stageId: string;
            title: string;
        }) => {
            const legacyUuid = uuidv4();
            const tasklistId = uuidv4();

            await replicache.mutate.createTasklist({
                tasklistId,
                ticketId,
                stageId,
                title,
                legacyUuid,
            });

            return tasklistId;
        },
        [replicache]
    );

    return { createTasklist };
}
