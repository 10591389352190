import { useCallback } from "react";

import { Location as TLocation } from "history";
import {
    Link as ReactRouterLink,
    LinkProps as ReactRouterLinkProps,
    NavLink as ReactRouterNavLink,
    useHistory as useReactRouterHistory,
    useLocation as useReactRouterLocation,
    useParams as useReactRouterParams,
    useRouteMatch as useReactRouterRouteMatch,
} from "react-router-dom";

import { Enums } from "lib/Enums";

type PageDetailsBoard = { appPage: typeof Enums.AppPage.BOARD; boardId: string };
type PageDetailsSearchResults = { appPage: typeof Enums.AppPage.SEARCH_RESULTS };
type PageDetailsSettings = { appPage: typeof Enums.AppPage.SETTINGS };
type PageDetailsTicketDetail = { appPage: typeof Enums.AppPage.TICKET_DETAIL; ticketId: string };
type PageDetailsUser = { appPage: typeof Enums.AppPage.USER; userId: number };
type PageDetails =
    | PageDetailsBoard
    | PageDetailsSearchResults
    | PageDetailsSettings
    | PageDetailsTicketDetail
    | PageDetailsUser;

export type LocationState =
    | { from: { location: TLocation<LocationState>; pageDetails?: PageDetails } }
    | undefined;

export type LinkProps = ReactRouterLinkProps<LocationState>;
export const Link = ReactRouterLink as ReactRouterLink<LocationState>;
export const NavLink = ReactRouterNavLink as ReactRouterNavLink<LocationState>;

export const useLocation = () => useReactRouterLocation<LocationState>();
export const useRouteMatch = useReactRouterRouteMatch;
export const useRouteParams = useReactRouterParams;

export function useHistory() {
    const history = useReactRouterHistory<LocationState>();

    const getCurrentLocation = useCallback(
        () => ({
            pathname: history.location?.pathname,
            search: history.location?.search,
            hash: history.location?.hash,
            state: undefined,
        }),
        [history]
    );

    const setUrlSearchParam = useCallback(
        ({ key, val }: { key: string; val: string }) => {
            const location = JSON.parse(JSON.stringify(history.location));
            const search = new URLSearchParams(location.search);

            if (val) {
                search.set(key, val);
            } else {
                search.delete(key);
            }

            location.search = search.toString();
            history.replace(location);
        },
        [history]
    );

    return { history, getCurrentLocation, setUrlSearchParam };
}

export type THistory = ReturnType<typeof useHistory>["history"];
