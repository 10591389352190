import ExtensionCode from "@tiptap/extension-code";
import { Selection, TextSelection } from "prosemirror-state";

export default ExtensionCode.extend({
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),

            ArrowLeft: () => {
                const { state } = this.editor;
                const { selection } = state;

                if (
                    this.editor.isActive("code") &&
                    selection instanceof TextSelection &&
                    selection.eq(Selection.atStart(state.doc)) &&
                    !!selection.$from.nodeAfter &&
                    !!selection.$cursor
                ) {
                    this.editor.commands.insertContentAt(selection.$cursor.pos, " ");
                    return true;
                }

                return false;
            },
            ArrowRight: () => {
                const { state } = this.editor;
                const { selection } = state;

                if (
                    this.editor.isActive("code") &&
                    selection instanceof TextSelection &&
                    selection.eq(Selection.atEnd(state.doc)) &&
                    !!selection.$from.nodeBefore &&
                    !!selection.$cursor
                ) {
                    this.editor.commands.insertContentAt(selection.$cursor.pos, " ");
                    return true;
                }

                return false;
            },
        };
    },
});
