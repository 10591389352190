import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const TaskResolvers: TResolvers = {
    query_root: {
        tasks: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.tasks.findAll())
        ),

        tasks_by_pk: buildResolver(async (parent, args, { api }) =>
            api.tasks.getById({ id: args.id })
        ),
    },

    tasks: {
        assignee: buildResolver(async (parent, args, { userContextApi }) =>
            parent.assigned_to_user_id
                ? userContextApi.getUserById({ userId: parent.assigned_to_user_id })
                : null
        ),

        child_ticket: buildResolver(async (parent, args, { api }) =>
            parent.child_ticket_id ? api.tickets.getById({ id: parent.child_ticket_id }) : null
        ),

        tasklist: buildResolver(async (parent, args, { api }) =>
            api.tasklists.getByIdOrThrow({ id: parent.tasklist_id })
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),
    },
};
