import { useCallback } from "react";

import { CommonEnumValue } from "c9r-common";
import { v4 as uuidv4 } from "uuid";

import { useReplicache } from "lib/replicache/Context";

export function useCreateStage() {
    const { replicache } = useReplicache();

    const createStage = useCallback(
        async ({
            boardId,
            displayName,
            boardPos,
            role,
        }: {
            boardId: string;
            displayName: string;
            boardPos: number;
            role?: CommonEnumValue<"StageRole">;
        }) => {
            const stageId = uuidv4();

            await replicache.mutate.createStage({
                stageId,
                boardId,
                displayName,
                boardPos,
                role,
            });
        },
        [replicache]
    );

    return { createStage };
}

export function useDeleteStage() {
    const { replicache } = useReplicache();

    const deleteStage = useCallback(
        async ({ stageId }: { stageId: string }) => {
            await replicache.mutate.deleteStage({ stageId });
        },
        [replicache]
    );

    return { deleteStage };
}

export function useUpdateStage() {
    const { replicache } = useReplicache();

    const updateStage = useCallback(
        async ({
            stageId,
            displayName,
            boardPos,
            role,
        }: {
            stageId: string;
            displayName: string;
            boardPos: number;
            role: CommonEnumValue<"StageRole">;
        }) => {
            await replicache.mutate.updateStage({ stageId, displayName, boardPos, role });
        },
        [replicache]
    );

    return { updateStage };
}
