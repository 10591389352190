import React, { useCallback } from "react";

import classNames from "classnames";
import {
    Draggable,
    DraggableProvidedDraggableProps,
    DraggableStateSnapshot,
    DraggingStyle,
} from "react-beautiful-dnd";

import { AppData } from "AppData";
import { useBreakpoints } from "lib/Breakpoints";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { Enums } from "lib/Enums";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./DraggableCard.module.scss";
import { useBoardViewFilter } from "../BoardFilterContext";
import { useBoardView } from "../BoardViewContext";
import { Card, CardProps } from "../card/Card";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DraggableCard_ticket on tickets {
            id

            ...Card_ticket
        }
    `),
};

export type DraggableCardProps = {
    cardRef?: React.MutableRefObject<HTMLLIElement | null>;
    className?: string;
    index: number;
    isDragDisabled?: boolean;
    ticket: FragmentType<typeof fragments.ticket>;
} & Pick<
    CardProps,
    | "isFirstTicketInStage"
    | "isLastTicketInStage"
    | "onArchiveCard"
    | "onMoveCard"
    | "onTrashCard"
    | "prevStageId"
    | "nextStageId"
>;

export const DraggableCard = React.memo(
    ({
        cardRef,
        className,
        index,
        isDragDisabled,
        isFirstTicketInStage,
        isLastTicketInStage,
        onMoveCard,
        onArchiveCard,
        onTrashCard,
        prevStageId,
        nextStageId,
        ticket: _ticketFragment,
    }: DraggableCardProps) => {
        const ticket = getFragmentData(fragments.ticket, _ticketFragment);
        const breakpoints = useBreakpoints();
        const { draggingId } = useBoardView();
        const draggableId = dragAndDropEntity.getDndId(Enums.DndEntityTypes.TICKET, ticket.id);

        function getStyle(
            style: DraggableProvidedDraggableProps["style"],
            snapshot: DraggableStateSnapshot
        ): React.CSSProperties {
            // NB: This should match the margin specified in the columnCard class in the Column styles
            // to ensure that the cards do not shift when a card is being dragged.
            const marginBottom = draggingId === draggableId ? "12px" : undefined;

            if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
                return { ...style, marginBottom };
            }

            const { duration } = snapshot.dropAnimation;

            return {
                ...style,
                marginBottom,
                width:
                    (snapshot.draggingOver &&
                        AppData.stageWidthsById[Number(snapshot.draggingOver)]) ||
                    (style as DraggingStyle)?.width ||
                    undefined,
                transition: `all cubic-bezier(0.2,1.0,0.9,1.0) ${duration / 1.5}s`,
            };
        }

        const { appendTermToFilterExpression } = useBoardViewFilter();

        const handleClickFilterTrigger = useCallback(
            (e: React.MouseEvent<HTMLSpanElement>, term: string) => {
                e.preventDefault();
                e.stopPropagation();

                appendTermToFilterExpression(term);
            },
            [appendTermToFilterExpression]
        );

        return (
            <Draggable
                draggableId={draggableId}
                index={index}
                isDragDisabled={isDragDisabled || breakpoints.mdMax}
            >
                {(provided, snapshot) => (
                    <li
                        className={classNames(
                            className,
                            styles.draggableCard,
                            snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging
                        )}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // provided.dragHandleProps includes setting tabIndex, but we don't need
                        // that, because we know that the entire Card component is a link, which
                        // itself is tabbable. Setting tabIndex to -1 here does not interfere with
                        // being able to drag/drop the card via keyboard.
                        tabIndex={-1}
                        ref={e => {
                            if (cardRef) {
                                // eslint-disable-next-line no-param-reassign
                                cardRef.current = e;
                            }
                            provided.innerRef(e);
                        }}
                        style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                        <Card
                            ticket={ticket}
                            isDragging={snapshot.isDragging}
                            isDropping={snapshot.isDropAnimating}
                            onMoveCard={onMoveCard}
                            onArchiveCard={onArchiveCard}
                            onTrashCard={onTrashCard}
                            isFirstTicketInStage={isFirstTicketInStage}
                            isLastTicketInStage={isLastTicketInStage}
                            prevStageId={prevStageId}
                            nextStageId={nextStageId}
                            onClickFilterTrigger={handleClickFilterTrigger}
                        />
                    </li>
                )}
            </Draggable>
        );
    }
);

DraggableCard.displayName = "DraggableCard";
