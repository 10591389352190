import React from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { Radio, RadioGroup } from "components/ui/core/Radio";
import { Tooltip } from "components/ui/core/Tooltip";

import styles from "./BoardAccessTypeRadio.module.scss";

export type BoardAccessTypeRadioProps = {
    boardAccessType: CommonEnumValue<"BoardAccessType">;
    className?: string;
    disablePublic?: boolean;
    orgDisplayName: string | null;
    setBoardAccessType: (accessType: CommonEnumValue<"BoardAccessType">) => void;
} & React.ComponentPropsWithoutRef<"div">;

export function BoardAccessTypeRadio({
    boardAccessType,
    className,
    disablePublic,
    orgDisplayName,
    setBoardAccessType,
    ...htmlDivProps
}: BoardAccessTypeRadioProps) {
    const isPrivateBoardWarningDisplayed =
        boardAccessType === CommonEnums.BoardAccessType.PRIVATE && !disablePublic;

    return (
        <div className={classNames(className, styles.radio)} {...htmlDivProps}>
            <RadioGroup
                instrumentation={null}
                name="board_access_type"
                onChange={e =>
                    setBoardAccessType(e.target.value as CommonEnumValue<"BoardAccessType">)
                }
                selectedValue={boardAccessType}
            >
                <Tooltip
                    content="Private workspaces cannot be made public."
                    disabled={!disablePublic}
                    placement="right"
                    showFast
                    small
                >
                    <Radio
                        disabled={!!disablePublic}
                        inline
                        label={
                            <div className={styles.label}>
                                <div className={styles.title}>Public</div>
                                <div className={styles.captions}>
                                    <div className={styles.description}>
                                        {`Anyone in ${
                                            orgDisplayName ?? "your organization"
                                        } can access`}
                                    </div>
                                </div>
                            </div>
                        }
                        value={CommonEnums.BoardAccessType.PUBLIC}
                    />
                </Tooltip>
                <Radio
                    inline
                    label={
                        <div className={styles.label}>
                            <div className={styles.title}>
                                Private{" "}
                                <Icon
                                    icon="lock"
                                    iconSet="c9r"
                                    iconSize={12}
                                    strokeWidthAbsolute={1.5}
                                />
                            </div>
                            <div className={styles.captions}>
                                <div className={styles.description}>
                                    Only people added to the workspace as members will have access
                                </div>
                                <div className={styles.warning}>
                                    {isPrivateBoardWarningDisplayed ? (
                                        <>
                                            <Icon
                                                icon="alert-triangle"
                                                iconSet="lucide"
                                                iconSize={12}
                                                strokeWidth={2}
                                            />
                                            Private workspaces cannot later be made public
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    }
                    value={CommonEnums.BoardAccessType.PRIVATE}
                />
            </RadioGroup>
        </div>
    );
}
