import { isDefined } from "lib/types/guards";

import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const StageResolvers: TResolvers = {
    query_root: {
        stages: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.stages.findAll())
        ),

        stages_by_pk: buildResolver(async (parent, args, { api }) =>
            api.stages.getById({ id: args.id })
        ),
    },

    stages: {
        board: buildResolver(async (parent, args, { api }) =>
            api.boards.getByIdOrThrow({ id: parent.board_id })
        ),

        is_empty: buildResolver(async (parent, args, { api }) => {
            const tickets = (await api.tickets.findByStageId({ stageId: parent.id }))
                .filter(ticket => !ticket.archived_at && !ticket.trashed_at)
                .filter(isDefined);

            return !tickets.length;
        }),

        min_ticket_stage_pos: buildResolver(async (parent, args, { api }) => {
            const ticketStagePositions = (await api.tickets.findByStageId({ stageId: parent.id }))
                .map(ticket => ticket.stage_pos)
                .filter(isDefined);

            return ticketStagePositions.length ? Math.min(...ticketStagePositions) : null;
        }),

        max_ticket_stage_pos: buildResolver(async (parent, args, { api }) => {
            const ticketStagePositions = (await api.tickets.findByStageId({ stageId: parent.id }))
                .map(ticket => ticket.stage_pos)
                .filter(isDefined);

            return ticketStagePositions.length ? Math.max(...ticketStagePositions) : null;
        }),

        org: buildResolver(async (parent, args, { userContextApi }) => userContextApi.getOrg()),

        tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.tickets.findByStageId({ stageId: parent.id })
            )
        ),
    },
};
