import { CurrentIdentity } from "lib/types/common/currentIdentity";
import { CurrentOrg, CurrentUser } from "lib/types/common/currentUser";

// This file contains global *non*-reactive data as a quick convenient way to
// share some info across the app.
export const AppData: {
    // Non-reactive ways of accessing these, useful in certain app-wide hooks.
    currentIdentity: CurrentIdentity | null;
    currentOrg: CurrentOrg | null;
    currentUser: CurrentUser | null;

    identityRole: string;
    redirectToDeepLinkUrl: string | null;
    scrollToTicketSetAt: number | null;
    scrollToTicketId: string | null;
    lastCreatedStageAt: number | null;
    openDialogCount: number;
    archiveFilterExpressionByBoardId: Record<string, string | null | undefined>;
    trashFilterExpressionByBoardId: Record<string, string | null | undefined>;
    stageWidthsById: Record<string, number>;
} = {
    currentIdentity: null,
    currentOrg: null,
    currentUser: null,

    identityRole: "identity",
    redirectToDeepLinkUrl: null,
    scrollToTicketSetAt: null,
    scrollToTicketId: null,
    lastCreatedStageAt: null,
    openDialogCount: 0,
    archiveFilterExpressionByBoardId: {},
    trashFilterExpressionByBoardId: {},
    stageWidthsById: {},
};
