import React from "react";

import { ChangeEmailAddressDialog } from "./ChangeEmailAddressDialog";
import { ConnectZapierDialog } from "./ConnectZapierDialog";
import { CreateBoardDialog } from "./CreateBoardDialog";
import { DeleteAccountDialog } from "./DeleteAccountDialog";
import { EditAvatarDialog } from "./EditAvatarDialog";
import { EditBoardDialog } from "./EditBoardDialog";
import { FeedbackDialog } from "./FeedbackDialog";
import { InputPromptDialog } from "./InputPromptDialog";
import { InvitePeopleDialog } from "./InvitePeopleDialog";
import { NewTicketDialog } from "./NewTicketDialog";
import { OnboardingWelcomeDialog } from "./OnboardingWelcomeDialog";
import { SelectParentTicketDialog } from "./SelectParentTicketDialog";

export function Dialogs() {
    return (
        <>
            <ChangeEmailAddressDialog />
            <ConnectZapierDialog />
            <CreateBoardDialog />
            <DeleteAccountDialog />
            <EditAvatarDialog />
            <EditBoardDialog />
            <FeedbackDialog />
            <InputPromptDialog />
            <InvitePeopleDialog />
            <NewTicketDialog />
            <OnboardingWelcomeDialog />
            <SelectParentTicketDialog />
        </>
    );
}
