import React from "react";

import classNames from "classnames";

import styles from "./Breadcrumb.module.scss";

export type BreadcrumbProps = {
    className?: string;
    disabled?: boolean;
    isCurrent?: boolean;
    onClick: () => void;
    small?: boolean;
};

export function Breadcrumb({ className, disabled, isCurrent, onClick, small }: BreadcrumbProps) {
    return (
        <button
            className={classNames(
                className,
                styles.breadcrumb,
                isCurrent && styles.isCurrent,
                small && styles.small
            )}
            type="button"
            disabled={disabled}
            onClick={onClick}
        />
    );
}
