import { gql } from "lib/graphql/__generated__";

type GqlMap = Record<string, unknown>;

export class Fragments {
    private static _REGISTRY: Record<string, GqlMap> = {};

    static register({ component, gqlMapByName }: { component: string; gqlMapByName: GqlMap }) {
        Fragments._REGISTRY[component] = gqlMapByName;
    }

    static get({ component, name }: { component: string; name: string }) {
        return Fragments._REGISTRY[component][name];
    }

    static standard = {
        merge_request: {
            full: gql(/* GraphQL */ `
                fragment merge_request_full on merge_requests {
                    id
                    provider
                    opened_at
                    merged_at
                    closed_at
                    last_activity_at
                    url
                    title
                    build_status
                    build_status_info
                    lines_added
                    lines_removed
                    has_merge_conflict
                    has_changes_requested
                    pending_reviews
                }
            `),
        },
    };
}
