import React, { useCallback, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { Spinner } from "@blueprintjs/core";
import { CommonEnums, TicketDueDates, TicketSizes } from "c9r-common";
import classNames from "classnames";
import { differenceInCalendarDays, parse as parseDate } from "date-fns";

import { Config } from "Config";
import { Label } from "components/shared/Label";
import { TicketLink } from "components/ui/common/TicketLink";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { TruncatedText } from "components/ui/common/TruncatedText";
import { Icon } from "components/ui/core/Icon";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { BotUser } from "lib/BotUser";
import { ExternalTicketSources } from "lib/Constants";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { Queries } from "lib/Queries";
import { useAutoRestartSubscription } from "lib/apollo/useAutoRestartSubscription";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { TicketHistoryEvents_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";
import { isDefined } from "lib/types/guards";

import styles from "./TicketHistoryEvents.module.scss";

const fragments = {
    blockerTicket: gql(/* GraphQL */ `
        fragment TicketHistoryEvents_blocker_ticket on tickets {
            id

            ...TicketLink_ticket
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment TicketHistoryEvents_ticket on tickets {
            id
            ticket_history_events_by_event_at: ticket_history_events(order_by: { event_at: desc }) {
                id
                ticket_id
                user_id
                event_at
                event_type
                event_details

                import {
                    id
                    source
                }

                ticket {
                    id
                    external_source_display_id
                }

                user {
                    id
                    name
                }

                event_on_ticket_label_attachment {
                    color
                    text
                }

                event_on_merge_request {
                    id
                    title
                    url
                }

                event_on_stage {
                    id
                    display_name

                    board {
                        id
                        display_name
                    }
                }

                event_on_task {
                    id
                    title

                    ticket {
                        id

                        ...TicketLink_ticket
                    }

                    child_ticket {
                        id

                        ...TicketLink_ticket
                    }
                }

                event_on_ticket_resource {
                    id
                    title
                    url
                }

                event_on_user {
                    id
                    name
                }

                event_on_ticket_attachment {
                    id
                    title
                    deleted_at
                }
            }
        }
    `),
};

type TEvent = TicketHistoryEvents_ticketFragment["ticket_history_events_by_event_at"][number];

const isEventInvolvingBlocker = ({ event }: { event: TEvent }) =>
    event.event_details.new?.blockerText || event.event_details.new?.blockerTicketId;

const icons = {
    ADD_LABEL: () => <Icon icon="tag" iconSet="lucide" />,
    ADD_OWNER: () => <Icon icon="user-plus" iconSet="lucide" />,
    ADD_MEMBER: () => <Icon icon="user-plus" iconSet="lucide" />,
    ADD_RESOURCE: () => <Icon icon="link" iconSet="lucide" />,
    ADD_TASK: () => <Icon icon="plus-square" iconSet="lucide" />,
    ADD_TASK_ASSIGNEE: () => <Icon icon="user-plus" iconSet="lucide" />,
    ADD_CHILD_TICKET: () => <Icon icon="attachChild" iconSet="c9r" />,
    ADD_PARENT_TICKET: () => <Icon icon="attachParent" iconSet="c9r" />,
    ADD_ATTACHMENT: () => <Icon iconSet="lucide" icon="file-plus" />,
    ARCHIVE: () => <Icon icon="archive" iconSet="lucide" />,
    CHANGE_BLOCKER: () => <Icon icon="x-octagon" iconSet="lucide" />,
    CHANGE_BOARD: () => <Icon icon="truck" iconSet="lucide" />,
    CHANGE_DESCRIPTION: () => <Icon icon="edit-2" iconSet="lucide" />,
    CHANGE_DUE_DATE: (event: TEvent) => {
        if (!event.event_details.old!.dueDate && event.event_details.new!.dueDate) {
            return <Icon icon="calendar-plus" iconSet="lucide" />;
        }

        if (event.event_details.old!.dueDate && !event.event_details.new!.dueDate) {
            return <Icon icon="calendar-minus" iconSet="lucide" />;
        }

        return <Icon icon="calendar" iconSet="lucide" />;
    },
    CHANGE_OWNER: () => <Icon icon="user-plus" iconSet="lucide" />,
    CHANGE_SIZE: () => <Icon icon="bar-chart" iconSet="lucide" />,
    CHANGE_STAGE: (event: TEvent) => {
        return event.event_details.change!.direction === CommonEnums.Direction.FORWARD ? (
            <Icon icon="arrow-right" iconSet="lucide" />
        ) : (
            <Icon icon="arrow-left" iconSet="lucide" />
        );
    },
    CHANGE_TASK_ASSIGNEE: () => <Icon icon="user-plus" iconSet="lucide" />,
    CHANGE_TASK_COMPLETION: (event: TEvent) => {
        return event.event_details.new!.is_complete ? (
            <Icon icon="check-square" iconSet="lucide" />
        ) : (
            <Icon icon="square" iconSet="lucide" />
        );
    },
    CHANGE_TASK_DUE_DATE: (event: TEvent) => {
        if (!event.event_details.old!.dueDate && event.event_details.new!.dueDate) {
            return <Icon icon="calendar-plus" iconSet="lucide" />;
        }

        if (event.event_details.old!.dueDate && !event.event_details.new!.dueDate) {
            return <Icon icon="calendar-minus" iconSet="lucide" />;
        }

        return <Icon icon="calendar" iconSet="lucide" />;
    },
    CHANGE_TASK_TITLE: () => <Icon icon="edit-2" iconSet="lucide" />,
    CHANGE_TITLE: () => <Icon icon="edit-2" iconSet="lucide" />,
    CREATE_TICKET: () => <Icon icon="smallFilledCircle" iconSet="c9r" />,
    DELETE_TASK: () => <Icon icon="x" iconSet="lucide" />,
    DELETE_ATTACHMENT: () => <Icon iconSet="lucide" icon="file-minus" />,
    IMPORT_TICKET: () => <Icon icon="package" iconSet="lucide" />,
    MERGE_MERGE_REQUEST: () => (
        <Icon icon="codeMerged" iconSet="c9r" iconSize={18} strokeWeight={0.75} />
    ),
    OPEN_MERGE_REQUEST: () => (
        <Icon icon="codeMergeRequest" iconSet="c9r" iconSize={18} strokeWeight={0.75} />
    ),
    OPEN_THREAD: (event: TEvent) => {
        if (isEventInvolvingBlocker({ event })) {
            return <Icon icon="x-octagon" iconSet="lucide" />;
        }

        return null;
    },
    PROMOTE_TASK_TO_CHILD_TICKET: () => <Icon icon="promote" iconSet="c9r" />,
    REMOVE_LABEL: () => <Icon icon="tag" iconSet="lucide" />,
    REMOVE_OWNER: () => <Icon icon="user-minus" iconSet="lucide" />,
    REMOVE_MEMBER: () => <Icon icon="user-minus" iconSet="lucide" />,
    REMOVE_RESOURCE: () => <Icon icon="link" iconSet="lucide" />,
    REMOVE_CHILD_TICKET: () => <Icon icon="detachChild" iconSet="c9r" />,
    REMOVE_PARENT_TICKET: () => <Icon icon="detachParent" iconSet="c9r" />,
    REMOVE_TASK_ASSIGNEE: () => <Icon icon="user-minus" iconSet="lucide" />,
    REOPEN_THREAD: (event: TEvent) => {
        if (isEventInvolvingBlocker({ event })) {
            return <Icon icon="x-octagon" iconSet="lucide" />;
        }

        return null;
    },
    RESOLVE_THREAD: (event: TEvent) => {
        if (isEventInvolvingBlocker({ event })) {
            return <Icon icon="x-octagon" iconSet="lucide" />;
        }

        return null;
    },
    TRASH: () => <Icon icon="trash" iconSet="lucide" />,
    UNARCHIVE: () => <Icon icon="life-buoy" iconSet="lucide" />,
    UNTRASH: () => <Icon icon="life-buoy" iconSet="lucide" />,
} as const;

function getIconForEvent(event: TEvent) {
    return (
        icons[event.event_type as keyof typeof icons](event) || <Icon icon="blank" iconSet="c9r" />
    );
}

type TruncatedSpanProps = {
    text: string;
    maxLength: number;
};

function TruncatedSpan({ text, maxLength }: TruncatedSpanProps) {
    return (
        <TruncatedText
            as="span"
            className={styles.truncatedTextTarget}
            text={text}
            maxLength={maxLength}
            tooltipProps={{ placement: "top" }}
        />
    );
}

type TaskTextProps = {
    event: TEvent;
};

function TaskText({ event }: TaskTextProps) {
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    return (
        <>
            {event.event_on_stage
                ? event.event_on_stage.display_name
                : buildUnauthorizedDisplayName({ abstractName: "workflowStage" })}{" "}
            task "
            <TruncatedSpan text={event.event_details.new!.title} maxLength={64} />"
        </>
    );
}

function useFormatEvent() {
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    const buildFormattedBlocker = useCallback(
        async ({
            event,
            old,
            maxBlockerTextLength = 128,
        }: {
            event: TEvent;
            old?: boolean;
            maxBlockerTextLength?: number;
        }) => {
            const eventDetails = event.event_details;
            const key = old ? "old" : "new";

            if (eventDetails[key]?.blockerText) {
                return (
                    <>
                        "
                        <TruncatedSpan
                            text={eventDetails[key]?.blockerText}
                            maxLength={maxBlockerTextLength}
                        />
                        "
                    </>
                );
            }

            const result = await replicacheGraphQLClient.readQuery({
                query: TicketHistoryEvents.queries.blockerTicketById,
                variables: { blockerTicketId: eventDetails[key]?.blockerTicketId },
            });

            const blockerTicket = getFragmentData(fragments.blockerTicket, result?.tickets_by_pk);

            return <TicketLink iconSize={14} ticket={blockerTicket} />;
        },
        [replicacheGraphQLClient]
    );

    const formatEvent = useCallback(
        async ({ event }: { event: TEvent }) => {
            const eventDetails = event.event_details;

            switch (event.event_type) {
                case "ADD_LABEL":
                    return (
                        <span>
                            Label "
                            <Label
                                className={styles.label}
                                color={event.event_on_ticket_label_attachment!.color}
                                text={event.event_on_ticket_label_attachment!.text}
                            />
                            " added
                        </span>
                    );

                case "ADD_OWNER":
                    return `${event.event_on_user!.name} made the owner`;

                case "ADD_MEMBER":
                    return `${event.event_on_user!.name} added as a collaborator`;

                case "ADD_RESOURCE":
                    return (
                        <span>
                            Resource "
                            <a target="_" href={eventDetails.new!.url}>
                                {eventDetails.new!.title}
                            </a>
                            " added
                        </span>
                    );

                case "ADD_TASK":
                    return (
                        <span>
                            <TaskText event={event} /> added
                        </span>
                    );

                case "ADD_TASK_ASSIGNEE":
                    return (
                        <span>
                            <TaskText event={event} /> assigned to {event.event_on_user!.name}
                        </span>
                    );

                case "ADD_CHILD_TICKET":
                    return (
                        <span>
                            Attached child topic{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.child_ticket} />
                        </span>
                    );

                case "ADD_PARENT_TICKET":
                    // As of January 2024, we do not display unauthorized parent topics, so to be consistent, we
                    // also do not display associated topic history events.
                    return event.event_on_task?.ticket ? (
                        <span>
                            Attached to parent topic{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.ticket} />
                        </span>
                    ) : null;

                case "ADD_ATTACHMENT":
                    return !event.event_on_ticket_attachment!.deleted_at ? (
                        <span>
                            Attachment "
                            <a target="_" href={eventDetails.new!.url}>
                                <TruncatedSpan
                                    text={event.event_on_ticket_attachment!.title}
                                    maxLength={64}
                                />
                            </a>
                            " added
                        </span>
                    ) : (
                        `Attachment "${event.event_on_ticket_attachment!.title}" added`
                    );

                case "ARCHIVE":
                    return "Archived";

                case "CHANGE_BLOCKER":
                    return (
                        <span>
                            Blocker{" "}
                            {
                                await buildFormattedBlocker({
                                    event,
                                    old: true,
                                    maxBlockerTextLength: 80,
                                })
                            }{" "}
                            changed to{" "}
                            {
                                await buildFormattedBlocker({
                                    event,
                                    maxBlockerTextLength: 80,
                                })
                            }
                        </span>
                    );

                case "CHANGE_BOARD":
                    return event.event_on_stage
                        ? `Moved to [${event.event_on_stage.board.display_name}] ${event.event_on_stage.display_name}`
                        : `Moved to ${buildUnauthorizedDisplayName({ abstractName: "space" })}`;

                case "CHANGE_DESCRIPTION":
                    return "Description edited";

                case "CHANGE_DUE_DATE": {
                    if (!eventDetails.new!.dueDate) {
                        return "Due date removed";
                    }

                    if (!eventDetails.old!.dueDate) {
                        return `Due date set to ${TicketDueDates.format({
                            dueDate: eventDetails.new!.dueDate,
                            shouldLowerCase: true,
                        })}`;
                    }

                    const oldDate = parseDate(eventDetails.old!.dueDate, "yyyy-MM-dd", new Date());
                    const newDate = parseDate(eventDetails.new!.dueDate, "yyyy-MM-dd", new Date());
                    const difference = differenceInCalendarDays(newDate, oldDate);
                    const unit = Math.abs(difference) === 1 ? "day" : "days";

                    if (difference > 0) {
                        return `Due date pushed out ${Math.abs(
                            difference
                        ).toLocaleString()} ${unit} to ${TicketDueDates.format({
                            dueDate: eventDetails.new!.dueDate,
                            shouldLowerCase: true,
                        })}`;
                    }

                    if (difference < 0) {
                        return `Due date moved up ${Math.abs(
                            difference
                        ).toLocaleString()} ${unit} to ${TicketDueDates.format({
                            dueDate: eventDetails.new!.dueDate,
                            shouldLowerCase: true,
                        })}`;
                    }

                    // Not expected to ever happen, but just in case, display something reasonable.
                    return `Due date set to ${TicketDueDates.format({
                        dueDate: eventDetails.new!.dueDate,
                        shouldLowerCase: true,
                    })}`;
                }

                case "CHANGE_OWNER":
                    return `Owner changed to ${event.event_on_user!.name}`;

                case "CHANGE_SIZE":
                    if (eventDetails.new!.sizeSpec === null) {
                        return "Size changed to unsized";
                    }

                    return `Size changed to ${TicketSizes.format({
                        value: eventDetails.new!.sizeSpec.value,
                        unit: eventDetails.new!.sizeSpec.unit,
                    })}`;

                case "CHANGE_STAGE":
                    // If the topic history event is for a topic being moved within an unauthorized workspace,
                    // then do not display the topic history event.
                    return event.event_on_stage
                        ? `Moved${
                              eventDetails.change!.direction === CommonEnums.Direction.FORWARD
                                  ? ""
                                  : " back"
                          } to ${event.event_on_stage.display_name}`
                        : null;

                case "CHANGE_TASK_ASSIGNEE":
                    return (
                        <span>
                            <TaskText event={event} /> assignee changed to{" "}
                            {event.event_on_user!.name}
                        </span>
                    );

                case "CHANGE_TASK_COMPLETION":
                    if (eventDetails.new!.is_complete) {
                        return (
                            <span>
                                <TaskText event={event} /> checked off
                            </span>
                        );
                    }

                    return (
                        <span>
                            <TaskText event={event} /> unchecked
                        </span>
                    );

                case "CHANGE_TASK_DUE_DATE": {
                    if (!eventDetails.new!.dueDate) {
                        return (
                            <span>
                                <TaskText event={event} /> due date removed
                            </span>
                        );
                    }

                    if (!eventDetails.old!.dueDate) {
                        return (
                            <span>
                                <TaskText event={event} /> due date set to{" "}
                                {TicketDueDates.format({
                                    dueDate: eventDetails.new!.dueDate,
                                    shouldLowerCase: true,
                                })}
                            </span>
                        );
                    }

                    const oldDate = parseDate(eventDetails.old!.dueDate, "yyyy-MM-dd", new Date());
                    const newDate = parseDate(eventDetails.new!.dueDate, "yyyy-MM-dd", new Date());
                    const difference = differenceInCalendarDays(newDate, oldDate);
                    const unit = Math.abs(difference) === 1 ? "day" : "days";

                    if (difference > 0) {
                        return (
                            <span>
                                <TaskText event={event} /> due date pushed out{" "}
                                {Math.abs(difference).toLocaleString()} {unit} to{" "}
                                {TicketDueDates.format({
                                    dueDate: eventDetails.new!.dueDate,
                                    shouldLowerCase: true,
                                })}
                            </span>
                        );
                    }

                    if (difference < 0) {
                        return (
                            <span>
                                <TaskText event={event} /> due date moved up{" "}
                                {Math.abs(difference).toLocaleString()} {unit} to{" "}
                                {TicketDueDates.format({
                                    dueDate: eventDetails.new!.dueDate,
                                    shouldLowerCase: true,
                                })}
                            </span>
                        );
                    }

                    // Not expected to ever happen, but just in case, display something reasonable.
                    return (
                        <span>
                            <TaskText event={event} /> due date set to{" "}
                            {TicketDueDates.format({
                                dueDate: eventDetails.new!.dueDate,
                                shouldLowerCase: true,
                            })}
                        </span>
                    );
                }

                case "CHANGE_TASK_TITLE":
                    return (
                        <span>
                            {event.event_on_stage
                                ? event.event_on_stage.display_name
                                : buildUnauthorizedDisplayName({
                                      abstractName: "workflowStage",
                                  })}{" "}
                            task "
                            <TruncatedSpan text={eventDetails.old!.title} maxLength={40} />" renamed
                            to "
                            <TruncatedSpan text={eventDetails.new!.title} maxLength={40} />"
                        </span>
                    );

                case "CHANGE_TITLE":
                    return (
                        <span>
                            Title changed to "
                            <TruncatedSpan text={eventDetails.new!.title} maxLength={64} />"
                        </span>
                    );

                case "CREATE_TICKET": {
                    const source = event.import?.source ?? null;
                    const sourceInfo = source ? ExternalTicketSources[source] : null;

                    return [
                        "Created",
                        sourceInfo ? `(in ${sourceInfo.displayName})` : null,
                        event.event_on_stage ? `in ${event.event_on_stage.display_name}` : null,
                    ]
                        .filter(isDefined)
                        .join(" ");
                }

                case "DELETE_TASK":
                    return (
                        <span>
                            <TaskText event={event} /> deleted
                        </span>
                    );

                case "DELETE_ATTACHMENT":
                    return `Attachment "${event.event_on_ticket_attachment!.title}" deleted`;

                case "IMPORT_TICKET": {
                    const source = event.import?.source ?? null;
                    const sourceInfo = source ? ExternalTicketSources[source] : null;
                    const externalSourceDisplayId = event.ticket.external_source_display_id;

                    return [
                        "Imported",
                        sourceInfo ? `from ${sourceInfo.displayName}` : null,
                        sourceInfo
                            ? externalSourceDisplayId && `(${externalSourceDisplayId})`
                            : null,
                        event.event_on_stage ? `into ${event.event_on_stage.display_name}` : null,
                    ]
                        .filter(isDefined)
                        .join(" ");
                }

                case "MERGE_MERGE_REQUEST":
                    return (
                        <span>
                            Pull request "
                            <a target="_" href={event.event_on_merge_request!.url}>
                                <TruncatedSpan
                                    text={event.event_on_merge_request!.title}
                                    maxLength={64}
                                />
                            </a>
                            " merged
                        </span>
                    );

                case "OPEN_MERGE_REQUEST":
                    return (
                        <span>
                            Pull request "
                            <a target="_" href={event.event_on_merge_request!.url}>
                                <TruncatedSpan
                                    text={event.event_on_merge_request!.title}
                                    maxLength={64}
                                />
                            </a>
                            " opened
                        </span>
                    );

                case "OPEN_THREAD":
                    return isEventInvolvingBlocker({ event }) ? (
                        <span>Blocker {await buildFormattedBlocker({ event })} added</span>
                    ) : null;

                case "PROMOTE_TASK_TO_CHILD_TICKET":
                    return shouldShowTicketRefs ? (
                        <span>
                            Promoted task "
                            <TruncatedSpan text={eventDetails.old!.title} maxLength={64} />" to
                            topic{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.child_ticket} />
                        </span>
                    ) : (
                        <span>
                            Promoted task "
                            <TruncatedSpan text={eventDetails.old!.title} maxLength={64} />" to a{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.child_ticket} />
                        </span>
                    );

                case "REMOVE_LABEL":
                    return (
                        <span>
                            Label "
                            <Label
                                className={styles.label}
                                color={event.event_on_ticket_label_attachment!.color}
                                text={event.event_on_ticket_label_attachment!.text}
                            />
                            " removed
                        </span>
                    );

                case "REMOVE_OWNER":
                    return `${event.event_on_user!.name} removed as the owner`;

                case "REMOVE_MEMBER":
                    return `${event.event_on_user!.name} removed as a collaborator`;

                case "REMOVE_RESOURCE":
                    return (
                        <span>
                            {"Resource "}
                            <a target="_" href={eventDetails.new!.url}>
                                {eventDetails.new!.title}
                            </a>
                            {" removed"}
                        </span>
                    );

                case "REMOVE_CHILD_TICKET":
                    return (
                        <span>
                            Detached child topic{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.child_ticket} />
                        </span>
                    );

                case "REMOVE_PARENT_TICKET":
                    // As of January 2024, we do not display unauthorized parent topics, so to be consistent, we
                    // also do not display associated topic history events.
                    return event.event_on_task?.ticket ? (
                        <span>
                            Detached from parent topic{" "}
                            <TicketLink iconSize={14} ticket={event.event_on_task?.ticket} />
                        </span>
                    ) : null;

                case "REMOVE_TASK_ASSIGNEE":
                    return (
                        <span>
                            <TaskText event={event} /> unassigned from {event.event_on_user!.name}
                        </span>
                    );

                case "REOPEN_THREAD":
                    return isEventInvolvingBlocker({ event }) ? (
                        <span>Blocker {await buildFormattedBlocker({ event })} reopened</span>
                    ) : null;

                case "RESOLVE_THREAD":
                    return isEventInvolvingBlocker({ event }) ? (
                        <span>Blocker {await buildFormattedBlocker({ event })} resolved</span>
                    ) : null;

                case "TRASH":
                    return "Moved to trash";

                case "UNARCHIVE":
                    return "Unarchived";

                case "UNTRASH":
                    return "Removed from trash";

                default:
                    return null;
            }
        },
        [buildFormattedBlocker, buildUnauthorizedDisplayName, shouldShowTicketRefs]
    );

    return formatEvent;
}

function collapseTicketHistoryEvents(ticketHistoryEvents: TEvent[]): TEvent[] {
    const eventsToCollapse = ["CHANGE_DESCRIPTION", "CHANGE_TITLE"];

    return (
        ticketHistoryEvents
            .reverse()
            // Collapse events of the same applicable event types to the latest event,
            // if created within an hour of each other.
            .filter((event, i, arr) => {
                const nextItemIndex = i + 1;

                if (
                    nextItemIndex < arr.length &&
                    eventsToCollapse.includes(event.event_type) &&
                    event.event_type === arr[nextItemIndex].event_type &&
                    event.user_id === arr[nextItemIndex].user_id &&
                    new Date(arr[nextItemIndex].event_at).getTime() -
                        new Date(event.event_at).getTime() <
                        3600000
                ) {
                    return false;
                }
                return true;
            })
            // Collapse REMOVE_OWNER immediately followed by ADD_OWNER events into a
            // CHANGE_OWNER event.
            .map((event, i, arr) => {
                const nextItemIndex = i + 1;
                const prevItemIndex = i - 1;
                const timeDiff = 5;

                if (
                    arr[nextItemIndex] &&
                    event.event_type === "REMOVE_OWNER" &&
                    arr[nextItemIndex].event_type === "ADD_OWNER" &&
                    new Date(arr[nextItemIndex].event_at).getTime() -
                        new Date(event.event_at).getTime() <
                        timeDiff
                ) {
                    return {
                        ...event,
                        event_type: "CHANGE_OWNER",
                        event_on_user: arr[nextItemIndex].event_on_user,
                    };
                }

                if (
                    arr[prevItemIndex] &&
                    event.event_type === "ADD_OWNER" &&
                    arr[prevItemIndex].event_type === "REMOVE_OWNER" &&
                    new Date(event.event_at).getTime() -
                        new Date(arr[prevItemIndex].event_at).getTime() <
                        timeDiff
                ) {
                    return null;
                }
                return event;
            })
            .filter(isDefined)
            // Collapse REMOVE_TASK_ASSIGNEE immediately followed by ADD_TASK_ASSIGNEE events into a
            // CHANGE_TASK_ASSIGNEE event.
            .map((event, i, arr) => {
                const nextItemIndex = i + 1;
                const prevItemIndex = i - 1;
                const timeDiff = 5;

                if (
                    arr[nextItemIndex] &&
                    event.event_type === "REMOVE_TASK_ASSIGNEE" &&
                    arr[nextItemIndex].event_type === "ADD_TASK_ASSIGNEE" &&
                    new Date(arr[nextItemIndex].event_at).getTime() -
                        new Date(event.event_at).getTime() <
                        timeDiff
                ) {
                    return {
                        ...event,
                        event_type: "CHANGE_TASK_ASSIGNEE",
                        event_on_user: arr[nextItemIndex].event_on_user,
                    };
                }

                if (
                    arr[prevItemIndex] &&
                    event.event_type === "ADD_TASK_ASSIGNEE" &&
                    arr[prevItemIndex].event_type === "REMOVE_TASK_ASSIGNEE" &&
                    new Date(event.event_at).getTime() -
                        new Date(arr[prevItemIndex].event_at).getTime() <
                        timeDiff
                ) {
                    return null;
                }
                return event;
            })
            .filter(isDefined)
            .reverse()
    );
}

export type TicketHistoryEventsProps = {
    className?: string;
    ticketId: string;
};

export function TicketHistoryEvents({ className, ticketId }: TicketHistoryEventsProps) {
    const formatEvent = useFormatEvent();

    // As of June 2023, we query for history events here within the component rather than the
    // parent view, because users view it relatively infrequently, and the data is not all
    // kept in Replicache.
    const queryResult = useQuery(TicketHistoryEvents.queries.component, {
        variables: { ticketId },
        fetchPolicy: "no-cache",
    });

    // We could use useLiveQuery above, but we can take advantage of the fact that ticket
    // history events never change once created. Thus, we don't really need to listen to
    // the entire query to know if something has changed (which is more load on the backend),
    // we can just subscribe to see if there's a new event.
    useAutoRestartSubscription(TicketHistoryEvents.subscriptions.ticketHistoryEventsByTicketId, {
        variables: { ticketId },
        onSubscriptionData: () => queryResult.refetch(),
    });

    const [collapsedTicketHistoryEvents, setCollapsedTicketHistoryEvents] = useState<
        {
            event: TEvent;
            formattedEvent: string | JSX.Element | null;
        }[]
    >([]);

    useEffect(() => {
        (async () => {
            const ticket = getFragmentData(fragments.ticket, queryResult.data?.tickets_by_pk);

            if (!ticket) {
                return;
            }

            const ticketHistoryEvents = ticket.ticket_history_events_by_event_at.concat();

            const _collapsedTicketHistoryEvents = collapseTicketHistoryEvents(ticketHistoryEvents);

            const createTicketEventIndex = _collapsedTicketHistoryEvents.findIndex(
                e => e.event_type === "CREATE_TICKET"
            );

            // Make sure the creation event is displayed first, in case it has the same timestamp as
            // some other events (and so isn't necessarily first in the array).
            if (createTicketEventIndex >= 0) {
                const [createTicketEvent] = _collapsedTicketHistoryEvents.splice(
                    createTicketEventIndex,
                    1
                );

                _collapsedTicketHistoryEvents.push(createTicketEvent);
            }

            setCollapsedTicketHistoryEvents(
                await Promise.all(
                    _collapsedTicketHistoryEvents
                        .map(event => event as TEvent)
                        .map(async event => ({
                            event,
                            formattedEvent: await formatEvent({ event }),
                        }))
                )
            );
        })();
    }, [formatEvent, queryResult.data?.tickets_by_pk]);

    const eventsRequiringUserIdForCaption = ["MERGE_MERGE_REQUEST", "OPEN_MERGE_REQUEST"];

    if (queryResult.loading && !queryResult.data) {
        return <Spinner className={styles.spinner} size={32} />;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    return (
        <div className={classNames(className, styles.history)}>
            <ul className={styles.historyEvents}>
                {collapsedTicketHistoryEvents
                    .map(({ event, formattedEvent }) => {
                        if (!formattedEvent) {
                            return null;
                        }

                        const userName = (event.user ?? BotUser).name;

                        return (
                            <li key={event.id} className={styles.event}>
                                <div className={styles.track}>
                                    {getIconForEvent(event)}
                                    <div className={styles.connector} />
                                </div>
                                <div className={styles.eventContent}>
                                    <div className={styles.action}>{formattedEvent}</div>
                                    <div className={styles.caption}>
                                        <TimeAgo className={styles.date} date={event.event_at} />
                                        {!eventsRequiringUserIdForCaption.includes(
                                            event.event_type
                                        ) || event.user?.id ? (
                                            <span className={styles.user}>
                                                &nbsp;by&nbsp;{userName}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        );
                    })
                    .filter(Boolean)}
            </ul>
            {Config.demoUI.enabled ? (
                <p className={styles.demoMessage}>
                    Because this is a demo, this topic doesn't have a complete history.
                </p>
            ) : null}
        </div>
    );
}

TicketHistoryEvents.queries = {
    blockerTicketById: gql(/* GraphQL */ `
        query TicketHistoryEventsBlockerTicketById($blockerTicketId: uuid!) {
            tickets_by_pk(id: $blockerTicketId) {
                ...TicketHistoryEvents_blocker_ticket
            }
        }
    `),

    component: gql(/* GraphQL */ `
        query TicketHistoryEvents($ticketId: uuid!) {
            tickets_by_pk(id: $ticketId) {
                ...TicketHistoryEvents_ticket
            }
        }
    `),
};

TicketHistoryEvents.subscriptions = {
    ticketHistoryEventsByTicketId: gql(/* GraphQL */ `
        subscription TicketHistoryEventsByTicketId($ticketId: uuid!) {
            ticket_history_events(
                where: { ticket_id: { _eq: $ticketId } }
                order_by: { event_at: desc }
                limit: 1
            ) {
                id
            }
        }
    `),
};

Queries.register({ component: "TicketHistoryEvents", gqlMapByName: TicketHistoryEvents.queries });
