import React, { useEffect, useRef } from "react";

import {
    isKeyboardTextAreaCancel,
    isKeyboardTextAreaSubmit,
    isKeyboardTextInputCancel,
    isKeyboardTextInputSubmit,
} from "lib/Keyboard";

export type AbstractTextInputProps = {
    className?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    onKeyboardCancel?: () => void;
    onKeyboardSubmit?: () => void;
    treatAsTextAreaForKeyboard?: boolean;
} & React.ComponentPropsWithoutRef<"input">;

export function AbstractTextInput({
    className,
    inputRef,
    onKeyboardCancel,
    onKeyboardSubmit,
    treatAsTextAreaForKeyboard = false,

    ...htmlInputProps
}: AbstractTextInputProps) {
    const [isCancel, isSubmit] = treatAsTextAreaForKeyboard
        ? [isKeyboardTextAreaCancel, isKeyboardTextAreaSubmit]
        : [isKeyboardTextInputCancel, isKeyboardTextInputSubmit];

    // As of October 2023, in Chrome, in detail view, when the discussion panel was not populated
    // and the user selected "New blocker", the blocker input box would become focused (as per
    // `document.activeElement`) but would not have a cursor or accept keyboard input. Blurring
    // and refocusing the input box as follows resolved the issue. Decided to apply the fix here
    // instead of in `NewBlockerEditor` in case any other components using `AbstractTextInput` had
    // the bug unbeknown to us. (See `Select` > `handleItemAddAndRemove` for a similar issue.)
    const backupInputRef = useRef(null);
    const ref = inputRef ?? backupInputRef;

    useEffect(() => {
        if (htmlInputProps.autoFocus) {
            setImmediate(() => {
                ref.current?.blur();
                ref.current?.focus();
            });
        }
    }, [htmlInputProps.autoFocus, ref]);

    return (
        <input
            type="text"
            {...htmlInputProps}
            className={className}
            onKeyDown={event => {
                if (onKeyboardCancel && isCancel(event)) {
                    event.preventDefault();
                    onKeyboardCancel();
                }

                if (onKeyboardSubmit && isSubmit(event)) {
                    event.preventDefault();
                    onKeyboardSubmit();
                }

                htmlInputProps.onKeyDown?.(event);
            }}
            ref={ref}
        />
    );
}
