import React, { useEffect, useState } from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { parse as parseDate } from "date-fns";
import { Draggable } from "react-beautiful-dnd";

import { DueDateButtonDisplay, OwnerButtonDisplay } from "components/shared/MetadataPickers";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { Checkbox } from "components/ui/core/Checkbox";
import { EditableText } from "components/ui/core/EditableText";
import { Icon } from "components/ui/core/Icon";
import { Enums } from "lib/Enums";
import { useAsyncWatcher } from "lib/Hooks";
import { useTaskDueDateInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useUpdateTaskDueDate } from "lib/mutations";
import { isDefined } from "lib/types/guards";

import styles from "./TasklistItem.module.scss";
import { TasklistItemMenuPopover, TasklistItemMenuPopoverProps } from "./TasklistItemMenuPopover";
import { useTasklistsSectionContext } from "./TasklistsSectionContext";
import { useDetailView } from "../context/DetailViewContext";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment TasklistItem_task on tasks {
            id
            title
            assigned_to_user_id
            is_complete
            deleted_at
            due_date

            tasklist {
                id

                ticket {
                    id

                    board {
                        id

                        authorized_users {
                            user {
                                id
                                name
                                ...OwnerButtonDisplay_user
                            }
                        }
                    }
                }
            }

            ...TasklistItemMenuPopover_task
            ...TaskDueDateInfo_task
        }
    `),

    org: gql(/* GraphQL */ `
        fragment TasklistItem_org on orgs {
            ...TasklistItemMenuPopover_org
        }
    `),
};

export type TasklistItemProps = {
    index: number;
    onIsCompleteChange: (isComplete: boolean) => void;
    onTitleChange: (params: { title: string | null; didBlur: boolean }) => void;
    org: FragmentType<typeof fragments.org>;
    task: FragmentType<typeof fragments.task>;
} & Pick<TasklistItemMenuPopoverProps, "onDelete" | "onPromoteToTicket">;

export function TasklistItem({
    index,
    onIsCompleteChange,
    onDelete,
    onTitleChange,
    onPromoteToTicket,
    org: _orgFragment,
    task: _taskFragment,
}: TasklistItemProps) {
    const org = getFragmentData(fragments.org, _orgFragment);
    const task = getFragmentData(fragments.task, _taskFragment);
    const { focusPreviousTask } = useTasklistsSectionContext();
    const { setOrRemoveTicketTaskAssignee } = useDetailView();
    const [isComplete, setIsComplete] = useState(task.is_complete);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { updateTaskDueDate } = useUpdateTaskDueDate();
    const { isUpcomingSoon, isOverdue } = useTaskDueDateInfo({ task });
    const submission = useAsyncWatcher();

    useEffect(() => setIsComplete(task.is_complete), [task.is_complete]);

    const handleDueDateChange = async (dueDate?: Date | null) => {
        await updateTaskDueDate({ taskId: task.id, dueDate: dueDate ?? null });
    };

    const handleAssigneeChange = async ({
        assignedToUserId,
    }: {
        assignedToUserId?: number | null;
    }) => {
        await setOrRemoveTicketTaskAssignee({ taskId: task.id, assignedToUserId });
    };

    const authorizedUsers = task.tasklist.ticket.board.authorized_users.map(au => au.user);

    return (
        <Draggable key={task.id} draggableId={`${task.id.toString()}`} index={index}>
            {(provided, snapshot) => (
                <li
                    className={classNames(
                        styles.task,
                        isComplete && styles.completed,
                        isMenuOpen && styles.menuOpen,
                        snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging,
                        snapshot.isDropAnimating && styles.isDropAnimating
                    )}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <Icon
                        className={styles.dragHandle}
                        icon="dragHandleLarge"
                        iconSet="c9r"
                        {...provided.dragHandleProps}
                    />
                    {submission.isInFlight ? (
                        <Spinner
                            className={styles.spinner}
                            intent={Enums.BlueprintIntent.PRIMARY}
                            size={16}
                        />
                    ) : (
                        <Checkbox
                            className={styles.checkbox}
                            inline
                            checked={isComplete}
                            instrumentation={{
                                elementName: "task.completion_checkbox",
                                eventData: { taskId: task.id, checked: !isComplete },
                            }}
                            onChange={event => {
                                const { checked } = event.target;
                                setIsComplete(checked);
                                onIsCompleteChange(checked);
                            }}
                        />
                    )}
                    <div className={styles.contentWrapper}>
                        <EditableText
                            disabled={submission.isInFlight}
                            className={styles.editableText}
                            value={task.title ?? undefined}
                            onConfirm={({ value, didBlur }) =>
                                onTitleChange({ title: value, didBlur })
                            }
                            onKeyDown={(e, { value }) => {
                                if (e.key === "Backspace" && !value) {
                                    e.preventDefault();
                                    focusPreviousTask();
                                    // No need to call onDelete() here, because by focusing the previous
                                    // task, the current one is blurred, so onConfirm will fire, and
                                    // onTitleChnage will delete the (now empty) task.
                                }
                            }}
                            placeholder="Enter task details..."
                            data-tasklists-arrow-nav
                        />
                        <div className={styles.metadataWrapper}>
                            {!!task.assigned_to_user_id && (
                                <OwnerButtonDisplay
                                    className={styles.metadataSelect}
                                    buttonClassName={styles.metadataButton}
                                    ownerId={task.assigned_to_user_id}
                                    onSelect={assignee => {
                                        void handleAssigneeChange({
                                            assignedToUserId: assignee?.id ?? null,
                                        });
                                    }}
                                    selectableUsers={authorizedUsers
                                        .filter(isDefined)
                                        .sort((a, b) =>
                                            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                                        )
                                        .concat(
                                            // @ts-ignore
                                            task.assigned_to_user_id
                                                ? [{ id: null, name: "Nobody" }]
                                                : []
                                        )}
                                    getInstrumentation={user => ({
                                        elementName: "task.assignee_picker",
                                        eventData: {
                                            taskId: task.id,
                                            userId: user?.id ?? null,
                                        },
                                    })}
                                    small
                                />
                            )}
                            {!!task.due_date && (
                                <DueDateButtonDisplay
                                    className={styles.metadataSelect}
                                    buttonClassName={styles.metadataButton}
                                    dueDate={
                                        task.due_date
                                            ? parseDate(task.due_date, "yyyy-MM-dd", new Date())
                                            : undefined
                                    }
                                    isUpcomingSoon={isUpcomingSoon}
                                    isOverdue={isOverdue}
                                    getInstrumentation={() => ({
                                        elementName: "task.due_date_picker",
                                        eventData: {
                                            taskId: task.id,
                                        },
                                    })}
                                    onSelect={handleDueDateChange}
                                    small
                                />
                            )}
                        </div>
                    </div>

                    <TasklistItemMenuPopover
                        org={org}
                        onPromoteToTicket={submission.watch(onPromoteToTicket)}
                        onDelete={onDelete}
                        task={task}
                        popoverProps={{
                            modifiers: {
                                offset: {
                                    enabled: true,
                                    options: {
                                        offset: [8, -4],
                                    },
                                },
                            },
                            placement: "bottom-end",
                            onOpening: () => setIsMenuOpen(true),
                            onClosing: () => setIsMenuOpen(false),
                        }}
                    >
                        <EllipsisButton
                            className={styles.ellipsisButton}
                            vertical
                            instrumentation={null}
                        />
                    </TasklistItemMenuPopover>
                </li>
            )}
        </Draggable>
    );
}
