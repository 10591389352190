import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CommonEnumValue, sortStages, sortTickets } from "c9r-common";
import { DropResult } from "react-beautiful-dnd";

import { useEditBoardDialog } from "dialogs/EditBoardDialog";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { Storage } from "lib/Storage";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    BoardViewProvider_boardFragment,
    BoardViewProvider_ticketFragment,
    EditBoardDialog_boardFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment BoardViewProvider_board on boards {
            id
            display_name

            stages(where: { deleted_at: { _is_null: true } }) {
                id
                board_id
                board_pos
                deleted_at
                display_name
                is_empty
                min_ticket_stage_pos
                max_ticket_stage_pos
                role

                # Nested components that call useBoardView()
                ...Columns_stage
                ...List_stage
            }

            ...BoardView_board
            ...BoardFilter_board
            ...EditBoardDialog_board
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment BoardViewProvider_ticket on tickets {
            id
            archived_at
            stage_id
            stage_pos
            trashed_at

            # Nested components that call useBoardView()
            ...Columns_ticket
            ...List_ticket
        }
    `),
};

export type BoardViewContextValue = {
    board: BoardViewProvider_boardFragment;
    boardStages: BoardViewProvider_boardFragment["stages"];
    tickets: BoardViewProvider_ticketFragment[];
    onMoveCard: ({
        ticketId,
        toStageDirection,
        withinStageDirection,
    }:
        | {
              ticketId: string;
              toStageDirection: CommonEnumValue<"Direction">;
              withinStageDirection?: undefined;
          }
        | {
              ticketId: string;
              toStageDirection?: undefined;
              withinStageDirection: CommonEnumValue<"Direction">;
          }) => void;
    onArchiveCard: ({ ticketId }: { ticketId: string }) => void;
    onTrashCard: ({ ticketId }: { ticketId: string }) => void;
    onArchiveStageTickets: ({ stageId }: { stageId: string }) => void;
    boardDroppableType: string;
    draggingId: string | null;
    isDragInProgress: boolean;
    handleBeforeCapture: ({ draggableId }: { draggableId: string }) => void;
    handleDragEnd: (result: DropResult) => void;
    collapsedStageIds: string[];
    setStageCollapse: ({ stageId, isCollapsed }: { stageId: string; isCollapsed: boolean }) => void;
    toggleStageCollapse: (stageId: string) => void;
    isArchiveVisible: boolean;
    isTrashVisible: boolean;
    toggleIsArchiveVisible: () => void;
    toggleIsTrashVisible: () => void;
    highlightMyTickets: boolean;
    onHighlightMyTicketChange: () => void;
    showTicketCounts: boolean;
    onShowTicketCountsChange: () => void;
    setFlipListViewStageOrder: React.Dispatch<React.SetStateAction<boolean>>;
    setShowListView: React.Dispatch<React.SetStateAction<boolean>>;
    showListView: boolean;
    onShowListViewChange: () => void;
    flipListViewStageOrder: boolean;
    openEditBoardDialog: () => void;
};

const [useBoardView, ContextProvider] = createCtx<BoardViewContextValue>();

export { useBoardView };

export type BoardViewProviderProps = {
    children: React.ReactNode;
    board: FragmentType<typeof fragments.board>;
    tickets: FragmentType<typeof fragments.ticket>[];
    onDragCard: ({
        ticketId,
        toStageId,
        toIndexDisplayed,
    }: {
        ticketId: string;
        toStageId: string;
        toIndexDisplayed: number;
    }) => void;
    onMoveCard: ({
        ticketId,
        toStageDirection,
        withinStageDirection,
    }:
        | {
              ticketId: string;
              toStageDirection: CommonEnumValue<"Direction">;
              withinStageDirection?: undefined;
          }
        | {
              ticketId: string;
              toStageDirection?: undefined;
              withinStageDirection: CommonEnumValue<"Direction">;
          }) => void;
    onArchiveCard: ({ ticketId }: { ticketId: string }) => void;
    onTrashCard: ({ ticketId }: { ticketId: string }) => void;
    onArchiveStageTickets: ({ stageId }: { stageId: string }) => void;
};

export function BoardViewProvider({
    children,
    board: _boardFragment,
    tickets: _ticketFragments,
    onDragCard,
    onMoveCard,
    onArchiveCard,
    onTrashCard,
    onArchiveStageTickets,
}: BoardViewProviderProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const rawTickets = _ticketFragments.map(t => getFragmentData(fragments.ticket, t));
    const boardDroppableType = "BOARD";
    const boardStages = board.stages.filter(stage => !stage.deleted_at).sort(sortStages());
    const tickets = useMemo(
        () => rawTickets.filter(t => !t.archived_at && !t.trashed_at).sort(sortTickets()),
        [rawTickets]
    );
    const [isArchiveVisible, setIsArchiveVisible] = useState(
        Storage.Session.getBoolean(`board.${board.id}.isArchiveOpen`)
    );
    const [isTrashVisible, setIsTrashVisible] = useState(
        Storage.Session.getBoolean(`board.${board.id}.isTrashOpen`)
    );
    const [highlightMyTickets, setHighlightMyTickets] = useState(
        Storage.All.getBoolean(`board.${board.id}.highlightMine`)
    );
    const [showTicketCounts, setShowTicketCounts] = useState(
        Storage.All.getBoolean(`board.${board.id}.showTicketCounts`)
    );
    const [showListView, setShowListView] = useState(
        Storage.All.getBoolean(`board.${board.id}.showListView`)
    );
    const [flipListViewStageOrder, setFlipListViewStageOrder] = useState(
        Storage.All.getBoolean(`board.${board.id}.flipListViewStageOrder`)
    );
    const [collapsedStageIds, setCollapsedStageIds] = useState<string[]>(
        Storage.All.getItem(`board.${board.id}.collapsedStageIds`) ?? []
    );

    const [draggingId, setDraggingId] = useState<string | null>(null);
    const isDragInProgress = !!draggingId;

    useEffect(() => {
        Storage.Session.setBoolean(`board.${board.id}.isArchiveOpen`, isArchiveVisible);
    }, [isArchiveVisible, board.id]);

    useEffect(() => {
        Storage.Session.setBoolean(`board.${board.id}.isTrashOpen`, isTrashVisible);
    }, [isTrashVisible, board.id]);

    useEffect(() => {
        Storage.All.setBoolean(`board.${board.id}.highlightMine`, highlightMyTickets);
    }, [highlightMyTickets, board.id]);

    useEffect(() => {
        Storage.All.setBoolean(`board.${board.id}.showTicketCounts`, showTicketCounts);
    }, [showTicketCounts, board.id]);

    useEffect(() => {
        Storage.All.setBoolean(`board.${board.id}.showListView`, showListView);
    }, [showListView, board.id]);

    useEffect(() => {
        Storage.All.setBoolean(`board.${board.id}.flipListViewStageOrder`, flipListViewStageOrder);
    }, [flipListViewStageOrder, board.id]);

    useEffect(() => {
        Storage.All.setItem(`board.${board.id}.collapsedStageIds`, collapsedStageIds);
    }, [collapsedStageIds, board.id]);

    const handleBeforeCapture = useCallback(({ draggableId }: { draggableId: string }) => {
        setDraggingId(draggableId);
    }, []);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { draggableId, destination, source } = result;
            const itemId = dragAndDropEntity.getRootId(draggableId);

            setDraggingId(null);

            if (
                !destination ||
                (destination.droppableId === source.droppableId &&
                    destination.index === source.index)
            ) {
                return;
            }

            const toStageId = dragAndDropEntity.getRootId(destination.droppableId);
            const fromStageId = dragAndDropEntity.getRootId(source.droppableId);
            let toIndexDisplayed = destination.index;

            if (showListView && collapsedStageIds.includes(toStageId)) {
                const stages = board.stages.filter(stage => !stage.deleted_at);

                const toStage = stages.find(s => s.id === toStageId);
                const fromStage = stages.find(s => s.id === fromStageId);

                // Move the ticket to the first position of the collapsed stage (that it's being moved to)
                // if the from stage is above it, or to the last position if the from stage is below it.
                toIndexDisplayed =
                    (toStage?.board_pos ?? 0) < (fromStage?.board_pos ?? 0)
                        ? 0
                        : Number.POSITIVE_INFINITY;
            }

            const ticketId = itemId || draggableId;

            onDragCard({
                ticketId,
                toStageId,
                toIndexDisplayed,
            });
        },
        [board.stages, collapsedStageIds, onDragCard, showListView]
    );

    const toggleIsArchiveVisible = useCallback(() => {
        setIsArchiveVisible(prev => !prev);
    }, []);

    const toggleIsTrashVisible = useCallback(() => {
        setIsTrashVisible(prev => !prev);
    }, []);

    const toggleHighlightMyTickets = useCallback(() => {
        setHighlightMyTickets(prev => !prev);
    }, []);

    const toggleShowTicketCounts = useCallback(() => {
        setShowTicketCounts(prev => !prev);
    }, []);

    const toggleShowListView = useCallback(() => {
        setShowListView(prev => !prev);
    }, []);

    const editBoardDialog = useEditBoardDialog();

    const openEditBoardDialog = useCallback(() => {
        editBoardDialog.openWithProps({
            board: getFragmentData(EditBoardDialog_boardFragmentDoc, board),
            redirectOnNameChange: true,
        });
    }, [board, editBoardDialog]);

    const setStageCollapse = useCallback(
        ({ stageId, isCollapsed }: { stageId: string; isCollapsed: boolean }) => {
            if (!isCollapsed) {
                setCollapsedStageIds(prev => prev.filter(id => id !== stageId));
            } else if (!collapsedStageIds.includes(stageId)) {
                setCollapsedStageIds(prev => prev.concat(stageId));
            }
        },
        [collapsedStageIds]
    );

    const toggleStageCollapse = useCallback(
        (stageId: string) => {
            if (collapsedStageIds.includes(stageId)) {
                setCollapsedStageIds(prev => prev.filter(id => id !== stageId));
            } else {
                setCollapsedStageIds(prev => prev.concat(stageId));
            }
        },
        [collapsedStageIds]
    );

    const value = useMemo(
        () => ({
            board,
            boardStages,
            tickets,
            // Ticket actions.
            onMoveCard,
            onArchiveCard,
            onTrashCard,
            onArchiveStageTickets,
            // Drag-and-drop context.
            boardDroppableType,
            draggingId,
            isDragInProgress,
            handleBeforeCapture,
            handleDragEnd,
            collapsedStageIds,
            setStageCollapse,
            toggleStageCollapse,
            // Board menu context.
            isArchiveVisible,
            isTrashVisible,
            toggleIsArchiveVisible,
            toggleIsTrashVisible,
            highlightMyTickets,
            onHighlightMyTicketChange: toggleHighlightMyTickets,
            showTicketCounts,
            onShowTicketCountsChange: toggleShowTicketCounts,
            setFlipListViewStageOrder,
            setShowListView,
            showListView,
            onShowListViewChange: toggleShowListView,
            flipListViewStageOrder,
            openEditBoardDialog,
        }),
        [
            board,
            boardStages,
            collapsedStageIds,
            draggingId,
            flipListViewStageOrder,
            handleBeforeCapture,
            handleDragEnd,
            highlightMyTickets,
            isArchiveVisible,
            isDragInProgress,
            isTrashVisible,
            onArchiveCard,
            onArchiveStageTickets,
            onMoveCard,
            onTrashCard,
            openEditBoardDialog,
            setStageCollapse,
            showListView,
            showTicketCounts,
            tickets,
            toggleHighlightMyTickets,
            toggleIsArchiveVisible,
            toggleIsTrashVisible,
            toggleShowListView,
            toggleShowTicketCounts,
            toggleStageCollapse,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
