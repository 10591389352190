import React, { useCallback, useMemo } from "react";

import { useRecoilValue } from "recoil";

import { TSearchIndexTicket, TicketSearchIndex } from "components/search/TicketSearchIndex";
import { createCtx } from "lib/react/Context";

export type TicketSearchContextValue = {
    findTickets: (params?: {
        searchQuery?: string | undefined;
        maxResults?: number | undefined;
        ticketRefOnly?: boolean | undefined;
    }) => TSearchIndexTicket[];
    findTicketsWithMatches: (params?: {
        searchQuery?: string | undefined;
        maxResults?: number | undefined;
        ticketRefOnly?: boolean | undefined;
        fields?: string[] | undefined;
    }) => {
        ticket: TSearchIndexTicket;
        matches: string[];
    }[];
    isTicketSearchIndexLoading: boolean;
};

const [useTicketSearch, ContextProvider] = createCtx<TicketSearchContextValue>();

export { useTicketSearch };

export type TicketSearchProviderProps = {
    children: React.ReactNode;
};

export function TicketSearchProvider({ children }: TicketSearchProviderProps) {
    const isTicketSearchIndexLoading = useRecoilValue(TicketSearchIndex.isLoadingState);

    const findTickets = useCallback(
        ({
            searchQuery = "",
            maxResults = 10,
            ticketRefOnly = false,
        }: {
            searchQuery?: string;
            maxResults?: number;
            ticketRefOnly?: boolean;
        } = {}) =>
            TicketSearchIndex.findTickets({
                searchQuery,
                maxResults,
                ticketRefOnly,
            }).map(({ ticket }) => ticket),
        []
    );

    const findTicketsWithMatches = useCallback(
        ({
            searchQuery = "",
            maxResults = 10,
            ticketRefOnly = false,
            fields = [],
        }: {
            searchQuery?: string;
            maxResults?: number;
            ticketRefOnly?: boolean;
            fields?: string[];
        } = {}) =>
            TicketSearchIndex.findTickets({
                searchQuery,
                maxResults,
                ticketRefOnly,
                fields,
            }),
        []
    );

    const value = useMemo(
        () => ({
            findTickets,
            findTicketsWithMatches,
            isTicketSearchIndexLoading,
        }),
        [findTickets, findTicketsWithMatches, isTicketSearchIndexLoading]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
