import { useCallback, useMemo, useRef } from "react";

import { useApolloClient } from "@apollo/client";
import isEqual from "react-fast-compare";
import { useDebouncedCallback } from "use-debounce";

import { AppData } from "AppData";
import { Env } from "Env";
import VersioningInfo from "VersioningInfo";
import { BrowserSessionId } from "lib/Constants";
import { EnumValue, Enums } from "lib/Enums";
import { Log } from "lib/Log";
import { useHistory } from "lib/Routing";
import { gql } from "lib/graphql/__generated__";

export type InstrumentationEvent = {
    /** The type of event. */
    eventType: EnumValue<"InstrumentationEvent">;

    /** The name of a UI element that the event occurred on */
    elementName?: string | null;

    /** Arbitrary data to record along with the event */
    eventData?: object | null;

    /**
     * If omitted, consecutive calls will be deduplicated if eventType, elementName, and
     * eventData are unchanged (e.g., so that multiple consecutive clicks of the same button in
     * don't record mulitple events). Provide a dedupeKey to allow recording consective
     * events that are otherwise identical.
     */
    dedupeKey?: string | number | null;
};

type RecordInstrumentationEventVariables = {
    userId: number | null;
    browserSessionId: string;
    pathname: string;
    clientVersion: string;
    clientSha: string | null;
} & InstrumentationEvent;

export function useInstrumentation() {
    const client = useApolloClient();
    const { history } = useHistory();
    const latestEventRef = useRef<RecordInstrumentationEventVariables>();

    const recordEvent = useCallback(
        async ({
            eventType,
            elementName = null,
            eventData = null,
            dedupeKey = null,
        }: InstrumentationEvent) => {
            if (
                !AppData.currentIdentity ||
                AppData.currentIdentity.isReadOnly ||
                AppData.currentUser?.isReadOnly
            ) {
                return;
            }

            const variables: RecordInstrumentationEventVariables = {
                userId: AppData.currentUser?.id ?? null,
                browserSessionId: BrowserSessionId,
                pathname: history.location.pathname,
                eventType,
                elementName,
                eventData,
                clientVersion: String(VersioningInfo.current),
                clientSha: Env.commitSha,
            };

            const event = { ...variables, dedupeKey };

            if (latestEventRef.current && isEqual(latestEventRef.current, event)) {
                return;
            }

            latestEventRef.current = event;

            try {
                await client.mutate({
                    mutation: gql(/* GraphQL */ `
                        mutation InstrumentationRecordEvent(
                            $userId: Int
                            $browserSessionId: String!
                            $pathname: String!
                            $eventType: String!
                            $elementName: String
                            $eventData: jsonb
                            $clientVersion: String!
                            $clientSha: String
                        ) {
                            insert_user_app_events_one(
                                object: {
                                    user_id: $userId
                                    browser_session_id: $browserSessionId
                                    pathname: $pathname
                                    event_type: $eventType
                                    element_name: $elementName
                                    event_data: $eventData
                                    client_version: $clientVersion
                                    client_sha: $clientSha
                                }
                            ) {
                                id
                            }
                        }
                    `),
                    variables,
                    context: {
                        apiRoleType: Enums.ApiRoleType.IDENTITY,
                    },
                });
            } catch (error) {
                Log.warn("Failed to record instrumentation event", { error, event });
            }
        },
        [client, history]
    );

    const recordCallback: <A extends any[], C extends (...args: A) => any>(
        eventArgs: Omit<InstrumentationEvent, "eventType"> & {
            eventType: EnumValue<"InstrumentationEvent"> | null;
        },
        cb?: C
    ) => (...args: A) => void = useCallback(
        (eventArgs, cb) => (...args) => {
            if (eventArgs?.eventType) {
                void recordEvent({ ...eventArgs, eventType: eventArgs.eventType });
            }

            cb?.(...args);
        },
        [recordEvent]
    );

    return useMemo(
        () => ({
            recordEvent,
            recordCallback,
        }),
        [recordEvent, recordCallback]
    );
}

export function useRecordTicketSearch({ elementName }: { elementName?: string }) {
    const recordEventDebounceIntervalMs = 1000;
    const { recordEvent } = useInstrumentation();

    const recordSearchEvent = useCallback(
        (s: string) => {
            if (!s) {
                return;
            }

            void recordEvent({
                eventType: Enums.InstrumentationEvent.SEARCH,
                elementName,
                eventData: { searchQuery: s },
                dedupeKey: s,
            });
        },
        [recordEvent, elementName]
    );

    const recordTicketSearch = useDebouncedCallback(
        recordSearchEvent,
        recordEventDebounceIntervalMs
    ).callback;

    return { recordTicketSearch };
}
