import React from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { TicketLink } from "components/ui/common/TicketLink";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { BotUser } from "lib/BotUser";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./Blocker.module.scss";

const fragments = {
    thread: gql(/* GraphQL */ `
        fragment Blocker_thread on threads {
            id
            blocker_added_at
            blocker_author_user_id
            blocker_text
            blocker_type

            blocker_author {
                id
                name
            }

            blocker_ticket {
                id

                ...TicketLink_ticket
            }
        }
    `),
};

export type BlockerProps = {
    className?: string;
    thread: FragmentType<typeof fragments.thread>;
};

export function Blocker({ className, thread: _threadFragment }: BlockerProps) {
    const thread = getFragmentData(fragments.thread, _threadFragment);

    if (!thread.blocker_type) {
        return null;
    }

    return (
        <div className={classNames(className, styles.blocker)}>
            {thread.blocker_type === CommonEnums.BlockerType.TEXT ? (
                <div className={styles.blockerSummary}>{thread.blocker_text}</div>
            ) : (
                <TicketLink
                    className={styles.blockerSummary}
                    ticket={thread.blocker_ticket}
                    underline={false}
                />
            )}
            <div className={styles.caption}>
                <TimeAgo date={thread.blocker_added_at!} />{" "}
                {thread.blocker_author_user_id
                    ? thread.blocker_author
                        ? `by ${thread.blocker_author.name}`
                        : ""
                    : `by ${BotUser.name}`}
            </div>
        </div>
    );
}
