import React from "react";

import classNames from "classnames";

import {
    AbstractTextInput,
    AbstractTextInputProps,
} from "components/ui/core/abstract/AbstractTextInput";

import styles from "./TextInput.module.scss";

export type TextInputProps = {
    fill?: boolean;
} & AbstractTextInputProps;

export function TextInput({ fill, ...abstractTextInputProps }: TextInputProps) {
    return (
        <AbstractTextInput
            {...abstractTextInputProps}
            className={classNames(
                abstractTextInputProps.className,
                styles.textInput,
                abstractTextInputProps.disabled && styles.disabled,
                fill && styles.fill
            )}
        />
    );
}
