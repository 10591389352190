import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Storage } from "lib/Storage";
import { createCtx } from "lib/react/Context";

/**
 * Custom hook to handle up/ down arrow navigation. It expects that there will be a container
 * wrapping the navigable elements and that each navigable element will have an attribute
 * identifying it as such.
 */
const useArrowNavigation = (
    /**
     * The attribute (likely a data-* attribute) used to identify navigable elements. This
     * will also be used to set each element's index for navigation purposes.
     */
    attribute: string
) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [elements, setElements] = useState<Element[]>([]);

    const handleMutation: MutationCallback = useCallback(
        mutationsList => {
            // Make sure that the element list is updated if the children in the container change.
            if (mutationsList.some(m => ["childList", "attributes"].includes(m.type))) {
                const newElements = containerRef.current?.querySelectorAll(`[${attribute}]`);

                if (newElements) {
                    setElements(Array.from(newElements));
                }
            }
        },
        [attribute, setElements]
    );

    const focusPrevious = useCallback(
        ({ shouldWrap }: { shouldWrap?: boolean } = {}) => {
            if (!document.activeElement) {
                return;
            }

            const focusedIndex = [...elements].indexOf(document.activeElement);

            if (focusedIndex === -1) {
                return;
            }

            const isFirstElementFocused = focusedIndex === 0;

            if (isFirstElementFocused && !shouldWrap) {
                return;
            }

            // Focus the previous element, or loop to the last if we're at the top of the list.
            const elementToFocus = containerRef.current?.querySelector(
                `[${attribute}="${isFirstElementFocused ? elements.length - 1 : focusedIndex - 1}"]`
            );

            if (elementToFocus && elementToFocus instanceof HTMLElement) {
                elementToFocus.focus();
            }
        },
        [elements, attribute]
    );

    const focusNext = useCallback(
        ({ shouldWrap }: { shouldWrap?: boolean } = {}) => {
            if (!document.activeElement) {
                return;
            }

            const focusedIndex = [...elements].indexOf(document.activeElement);

            if (focusedIndex === -1) {
                return;
            }

            const isLastElementFocused = focusedIndex === elements.length - 1;

            if (isLastElementFocused && !shouldWrap) {
                return;
            }

            // Focus the next element, or loop back to the first if we're at the end of the list.
            const elementToFocus = containerRef.current?.querySelector(
                `[${attribute}="${isLastElementFocused ? 0 : focusedIndex + 1}"]`
            );

            if (elementToFocus && elementToFocus instanceof HTMLElement) {
                elementToFocus.focus();
            }
        },
        [elements, attribute]
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (!document.activeElement) {
                return;
            }

            const focusedIndex = [...elements].indexOf(document.activeElement);

            if (focusedIndex === -1) {
                return;
            }

            if (e.key === "ArrowDown") {
                e.preventDefault();
                focusNext({ shouldWrap: true });
                return;
            }

            if (e.key === "ArrowUp") {
                e.preventDefault();
                focusPrevious({ shouldWrap: true });
            }
        },
        [elements, focusNext, focusPrevious]
    );

    useEffect(() => {
        const newElements = containerRef.current?.querySelectorAll(`[${attribute}]`);

        setElements(newElements ? Array.from(newElements) : []);
    }, [attribute]);

    useEffect(() => {
        // Elements might not have predictable or easily accessible indices, so here we are setting an index
        // for each element with the identifying attribute.
        elements.forEach((e, i) => e.setAttribute(attribute, String(i)));
    }, [elements, attribute]);

    useEffect(() => {
        if (containerRef.current) {
            const currentContainer = containerRef.current;
            const observer = new MutationObserver(handleMutation);

            observer.observe(currentContainer, {
                childList: true,
                subtree: true,
                attributeFilter: [attribute],
            });
            currentContainer.addEventListener("keydown", handleKeyDown, false);

            return () => {
                observer.disconnect();
                currentContainer.removeEventListener("keydown", handleKeyDown, false);
            };
        }

        return undefined;
    }, [attribute, handleMutation, handleKeyDown]);

    const value = useMemo(
        () => ({
            containerRef,
            focusNext,
            focusPrevious,
        }),
        [containerRef, focusNext, focusPrevious]
    );

    return value;
};

export type TasklistsSectionContextValue = {
    containerRef: React.RefObject<HTMLDivElement>;
    focusNextTask: (params?: { shouldWrap?: boolean | undefined }) => void;
    focusPreviousTask: (params?: { shouldWrap?: boolean | undefined }) => void;
    collapsedTasklists: string[];
    toggleTasklistCollapse: (tasklistId: string) => void;
    getIsTasklistCollapsed: (tasklistId: string) => boolean;
};

const [useTasklistsSectionContext, ContextProvider] = createCtx<TasklistsSectionContextValue>();

export { useTasklistsSectionContext };

export type TasklistsSectionContextProviderProps = {
    children: React.ReactNode;
};

export function TasklistsSectionContextProvider({
    children,
}: TasklistsSectionContextProviderProps) {
    const { containerRef, focusNext, focusPrevious } = useArrowNavigation(
        "data-tasklists-arrow-nav"
    );
    const localStorageKey = "collapsedTasklists";
    const [collapsedTasklists, setCollapsedTasklists] = useState<string[]>(
        Storage.Local.getItem(localStorageKey) || []
    );

    const toggleTasklistCollapse = useCallback(
        (tasklistId: string) => {
            const updatedCollapsedTasklists = collapsedTasklists.some(id => id === tasklistId)
                ? collapsedTasklists.filter(id => id !== tasklistId)
                : [...collapsedTasklists, tasklistId];

            setCollapsedTasklists(updatedCollapsedTasklists);

            if (updatedCollapsedTasklists.length) {
                Storage.Local.setItem(localStorageKey, updatedCollapsedTasklists);
            } else {
                Storage.Local.removeItem(localStorageKey);
            }
        },
        [collapsedTasklists]
    );

    const getIsTasklistCollapsed = useCallback(
        (tasklistId: string) => collapsedTasklists.some(id => id === tasklistId),
        [collapsedTasklists]
    );

    const value = useMemo(
        () => ({
            containerRef,
            focusNextTask: focusNext,
            focusPreviousTask: focusPrevious,
            collapsedTasklists,
            toggleTasklistCollapse,
            getIsTasklistCollapsed,
        }),
        [
            containerRef,
            focusNext,
            focusPrevious,
            collapsedTasklists,
            toggleTasklistCollapse,
            getIsTasklistCollapsed,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
