import React, { Fragment, useRef } from "react";

import classNames from "classnames";
import { Droppable } from "react-beautiful-dnd";

import { Config } from "Config";
import { ProductTourElementClasses } from "lib/Constants";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { Enums } from "lib/Enums";
import { useSaveScrollPosition } from "lib/Hooks";
import { getFragmentData, gql } from "lib/graphql/__generated__";

import { Column } from "./Column";
import styles from "./Columns.module.scss";
import { useBoardView } from "../BoardViewContext";

const fragments = {
    stage: gql(/* GraphQL */ `
        fragment Columns_stage on stages {
            id
            display_name

            ...Column_stage
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment Columns_ticket on tickets {
            id
            stage_id

            ...Column_ticket
        }
    `),
};

export function Columns() {
    const {
        board,
        boardStages: _boardStageFragments,
        isDragInProgress,
        tickets: _ticketFragments,
        onMoveCard,
        onArchiveCard,
        onTrashCard,
        boardDroppableType,
        showTicketCounts,
    } = useBoardView();
    const boardStages = _boardStageFragments.map(s => getFragmentData(fragments.stage, s));
    const tickets = _ticketFragments.map(t => getFragmentData(fragments.ticket, t));
    const boardAreaRef = useRef<HTMLDivElement>(null);
    const columnsRef = useRef<HTMLDivElement | null>(null);

    useSaveScrollPosition({ scrollElementRef: boardAreaRef, component: "board", id: board.id });

    return (
        <div className={classNames(styles.columnsWrapper)} ref={boardAreaRef}>
            <Droppable
                droppableId={dragAndDropEntity.getDndId(Enums.DndEntityTypes.BOARD, board.id)}
                type={boardDroppableType}
                direction="horizontal"
                isDropDisabled
            >
                {provided => {
                    return (
                        <div
                            className={styles.columns}
                            ref={e => {
                                columnsRef.current = e;
                                provided.innerRef(e);
                            }}
                            {...provided.droppableProps}
                        >
                            {boardStages.map((stage, index, arr) => (
                                <Fragment key={stage.id}>
                                    <Column
                                        className={classNames(
                                            styles.column,
                                            board.display_name ===
                                                Config.demoUI.tour.exampleBoardDisplayName &&
                                                stage.display_name ===
                                                    Config.demoUI.tour.exampleStageDisplayName &&
                                                ProductTourElementClasses.WORKSPACE_STAGE
                                        )}
                                        colIndex={index}
                                        stage={stage}
                                        tickets={tickets.filter(t => t.stage_id === stage.id)}
                                        showTicketCounts={showTicketCounts}
                                        onMoveCard={onMoveCard}
                                        onArchiveCard={onArchiveCard}
                                        onTrashCard={onTrashCard}
                                        prevStageId={index > 0 ? arr[index - 1].id : undefined}
                                        nextStageId={
                                            index + 1 < arr.length ? arr[index + 1].id : undefined
                                        }
                                        isDragInProgress={isDragInProgress}
                                    />
                                </Fragment>
                            ))}
                            {provided.placeholder}
                            <div className={styles.rightSpacer} />
                        </div>
                    );
                }}
            </Droppable>
        </div>
    );
}
