import React, { useCallback } from "react";

import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { format as formatDate } from "date-fns";
import * as Locales from "date-fns/locale";
import { DayPicker, DayPickerSingleProps } from "react-day-picker";

import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";

import "react-day-picker/dist/style.css";

import styles from "./DatePicker.module.scss";
import { IconSvg } from "./Icon";

/**
 * Get our best guess of which day the week starts on in the user's current locale, with 0 Sunday,
 * 1 Monday, etc.
 */
function tryGetWeekStartsOn(): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
    const defaultWeekStartsOn = 0;

    try {
        const { locale: localeName } = Intl.DateTimeFormat().resolvedOptions();
        const locale = new Intl.Locale(localeName);

        // First try the browser API. As of October 2023, it's inconsistent and not yet fully
        // supported, but it's probably most accurate.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
        const weekInfo: { firstDay: 1 | 7 } | undefined =
            // @ts-ignore because TS definitions aren't aware of the new API.
            typeof locale.weekInfo === "function" ? locale.weekInfo() : locale.weekInfo;

        if (weekInfo?.firstDay === 7) {
            return 0;
        }

        if (weekInfo?.firstDay === 1) {
            return 1;
        }

        // Next try using one the locales defined in date-fns.
        const [language, country] = navigator.language.split("-");

        // date-fns Locales object is keyed by strings like 'de' or 'enUS'. Try to find one
        // that matches the language *and* country, or if not that, just the language.
        return (
            Locales[`${language}${country}` as keyof typeof Locales]?.options?.weekStartsOn ??
            Locales[language as keyof typeof Locales]?.options?.weekStartsOn ??
            defaultWeekStartsOn
        );
    } catch (error) {
        return defaultWeekStartsOn;
    }
}

function CustomIconLeft() {
    return <IconSvg icon="chevron-left" iconSet="lucide" />;
}

function CustomIconRight() {
    return <IconSvg icon="chevron-right" iconSet="lucide" />;
}

export type DatePickerProps = {
    className?: string;
    getInstrumentation?: () => Omit<InstrumentationEvent, "eventType">;
    selectedDate?: Date;
    shouldDismissPopover?: boolean;
} & Pick<DayPickerSingleProps, "numberOfMonths" | "onSelect" | "required">;

export function DatePicker({
    className,
    getInstrumentation,
    numberOfMonths,
    onSelect,
    required = undefined,
    selectedDate,
    shouldDismissPopover = true,
}: DatePickerProps) {
    const { recordCallback } = useInstrumentation();

    const wrappedOnSelect = useCallback(
        (date?: Date) => {
            const instrumentation = getInstrumentation?.();

            return recordCallback(
                {
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: instrumentation?.elementName ?? "date_picker",
                    eventData: {
                        ...(instrumentation?.eventData || {}),
                        date: date ? formatDate(date, "yyyy-MM-dd") : null,
                    },
                },
                onSelect
            )(date);
        },
        [getInstrumentation, onSelect, recordCallback]
    );

    return (
        <DayPicker
            className={classNames(className, styles.datePicker)}
            classNames={{
                day: classNames("rdp-day", shouldDismissPopover && Classes.POPOVER_DISMISS),
            }}
            components={{ IconLeft: CustomIconLeft, IconRight: CustomIconRight }}
            defaultMonth={selectedDate}
            mode="single"
            numberOfMonths={numberOfMonths}
            onSelect={wrappedOnSelect}
            required={required}
            selected={selectedDate}
            weekStartsOn={tryGetWeekStartsOn()}
        />
    );
}
