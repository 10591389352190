import React, { useCallback, useEffect, useMemo } from "react";

import { Dialog as BlueprintDialog, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { RecoilState, SerializableParam, atomFamily, useRecoilState } from "recoil";

import { AppData } from "AppData";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Enums } from "lib/Enums";
import { useHotkeyScope } from "lib/Hotkeys";

import styles from "./Dialog.module.scss";

export type DialogState<TDialogProps> = {
    isOpen: boolean;
    props: TDialogProps | null;
};

export const dialogStateFamily: <TDialogProps>(
    param: SerializableParam
) => RecoilState<DialogState<TDialogProps>> = atomFamily({
    key: "DialogState",
    default: () =>
        ({
            isOpen: false,
            props: null,
        } as DialogState<any>),
});

export function useDialogSingleton<TDialogProps>(atom: RecoilState<DialogState<TDialogProps>>) {
    const [state, setState] = useRecoilState(atom);

    const open = useCallback(() => {
        setState({ isOpen: true, props: null });
    }, [setState]);

    const openWithProps = useCallback(
        (props: TDialogProps) => {
            setState({ isOpen: true, props });
        },
        [setState]
    );

    const close = useCallback(() => {
        setState(prev => ({ ...prev, isOpen: false }));
    }, [setState]);

    // This is a bit subtle!
    //
    // When the hook first runs, isOpen is false. Therefore, openDialogCount doesn't immediately
    // get incremented, which is correct.
    //
    // Afterward, the cleanup function runs when isOpen changes or on unmount. If isOpen changes
    // to true, openDialogCount gets incremented. If it changes back to false, the cleanup function
    // runs (and isOpen is true, because the cleanup has a closure over the prior variable) and
    // openDialogCount gets decremented. Likewise if the component unmounts, the cleanup function
    // runs and decrements openDialogCount only if the dialog was open at the time.
    useEffect(() => {
        if (state.isOpen) {
            AppData.openDialogCount += 1;
        }

        return () => {
            if (state.isOpen) {
                AppData.openDialogCount -= 1;
            }
        };
    }, [state.isOpen]);

    const dialog = useMemo(
        () => ({
            open,
            openWithProps,
            close,
        }),
        [open, openWithProps, close]
    );

    return dialog;
}

export type DialogProps = {
    autoFocus?: boolean;
    backdropClassName?: string;
    canEscapeKeyClose?: boolean;
    canOutsideClickClose?: boolean;
    className?: string;
    children?: React.ReactNode;
    enforceFocus?: boolean;
    /**
     * Force the dialog to fill the viewport (useful on mobile). If true, you must also
     * wrap the dialog content in Dialog.Content.
     */
    fillViewport?: boolean;
    isCloseButtonShown?: boolean;
    isOpen: boolean;
    lazy?: boolean;
    onClose?: (event: React.SyntheticEvent<HTMLElement, Event>) => void;
    onClosed?: (node: HTMLElement) => void;
    onClosing?: (node: HTMLElement) => void;
    onOpened?: (node: HTMLElement) => void;
    onOpening?: (node: HTMLElement) => void;
    portalClassName?: string;
    shouldReturnFocusOnClose?: boolean;
    title?: React.ReactNode;
    transitionDuration?: number;
    usePortal?: boolean;
};

export function Dialog({
    autoFocus,
    backdropClassName,
    canEscapeKeyClose,
    canOutsideClickClose,
    className,
    children,
    enforceFocus,
    fillViewport, // If true, must also wrap dialog content in Dialog.Content
    isCloseButtonShown,
    isOpen,
    lazy,
    onClose,
    onClosed,
    onClosing,
    onOpened,
    onOpening,
    portalClassName,
    shouldReturnFocusOnClose,
    title,
    transitionDuration,
    usePortal,
}: DialogProps) {
    useHotkeyScope({ scope: Enums.HotkeyScope.DIALOG, isEnabled: isOpen });

    return (
        <BlueprintDialog
            autoFocus={autoFocus}
            backdropClassName={classNames(backdropClassName, styles.backdrop)}
            canEscapeKeyClose={canEscapeKeyClose}
            canOutsideClickClose={canOutsideClickClose}
            className={classNames(className, styles.dialog, fillViewport && styles.fillViewport)}
            enforceFocus={enforceFocus}
            isCloseButtonShown={title ? isCloseButtonShown : undefined}
            isOpen={isOpen}
            lazy={lazy}
            onClose={onClose}
            onClosed={onClosed}
            onClosing={onClosing}
            onOpened={onOpened}
            onOpening={onOpening}
            portalClassName={portalClassName}
            shouldReturnFocusOnClose={shouldReturnFocusOnClose}
            title={title}
            transitionDuration={transitionDuration}
            usePortal={usePortal}
        >
            {children}
        </BlueprintDialog>
    );
}

// Override method on BlueprintDialog so that we can render our own style close button.
// @ts-expect-error We're intentionally overriding a method that's marked private.
BlueprintDialog.prototype.maybeRenderCloseButton = function maybeRenderCloseButton() {
    if (this.props.isCloseButtonShown !== false) {
        return <Dialog.CloseButton onClose={this.props.onClose} />;
    }

    return undefined;
};

export type DialogContentProps = {
    children?: React.ReactNode;
} & React.ComponentProps<"div">;

/**
 * Optional wrapper element to contain Dialog.Header, Dialog.Body, and Dialog.Footer.
 */
function DialogContent({ children, ...htmlDivProps }: DialogContentProps) {
    return (
        <div {...htmlDivProps} className={classNames(htmlDivProps.className, styles.content)}>
            {children}
        </div>
    );
}

Dialog.Content = DialogContent;

export type DialogHeaderProps = {
    className?: string;
    children?: React.ReactNode;
};

function DialogHeader({ children, className }: DialogHeaderProps) {
    return (
        <div className={classNames(className, styles.header, Classes.DIALOG_HEADER)}>
            {children}
        </div>
    );
}

Dialog.Header = DialogHeader;

export type DialogBodyProps = {
    className?: string;
    children?: React.ReactNode;
};

function DialogBody({ children, className }: DialogBodyProps) {
    return (
        <div className={classNames(className, styles.body, Classes.DIALOG_BODY)}>{children}</div>
    );
}

Dialog.Body = DialogBody;

export type DialogFooterProps = {
    className?: string;
    children?: React.ReactNode;
};

function DialogFooter({ children, className }: DialogFooterProps) {
    return (
        <div className={classNames(className, styles.footer, Classes.DIALOG_FOOTER)}>
            {children}
        </div>
    );
}

Dialog.Footer = DialogFooter;

export type DialogFooterActionsProps = {
    className?: string;
    children?: React.ReactNode;
};

function DialogFooterActions({ children, className }: DialogFooterActionsProps) {
    return (
        <div className={classNames(className, styles.footerActions, Classes.DIALOG_FOOTER_ACTIONS)}>
            {children}
        </div>
    );
}

Dialog.FooterActions = DialogFooterActions;

export type DialogCloseButtonProps = {
    className?: string;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

function DialogCloseButton({ className, onClose }: DialogCloseButtonProps) {
    return (
        <BorderButton
            className={classNames(className, styles.closeButton)}
            content={<Icon icon="x" iconSet="lucide" iconSize={18} />}
            minimal
            onClick={onClose}
            instrumentation={{
                elementName: "dialog.close_btn",
            }}
        />
    );
}

Dialog.CloseButton = DialogCloseButton;
