import React from "react";

import { CommonEnumValue, CommonEnums, TMergeRequestBuildStatusInfo } from "c9r-common";
import classNames from "classnames";

import {
    BuildStatusIcon,
    BuildToolLink,
    LineDiff,
    MergeRequestIcon,
    MergeRequestStatusSummary,
    MergeRequestUtils,
    useFormattedMergeRequest,
} from "components/shared/MergeRequest";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { TruncatedText } from "components/ui/common/TruncatedText";
import { Hotspot } from "components/ui/core/Hotspot";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { useRecordTicketView } from "lib/TicketViews";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    CardInfoBoxes_threadFragment,
    CardInfoBoxes_ticketFragment,
} from "lib/graphql/__generated__/graphql";

import { CardInfoBox } from "./CardInfoBox";
import styles from "./CardInfoBoxes.module.scss";

const fragments = {
    thread: gql(/* GraphQL */ `
        fragment CardInfoBoxes_thread on threads {
            id
            assigned_at
            blocker_added_at
            blocker_type
            opened_at
            resolved_at

            assignee {
                id
                name
            }
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment CardInfoBoxes_ticket on tickets {
            id
            title

            assigned_threads: threads(
                where: { resolved_at: { _is_null: true }, assigned_to_user_id: { _is_null: false } }
            ) {
                ...CardInfoBoxes_thread
            }

            blocked_threads: threads(
                where: { resolved_at: { _is_null: true }, blocker_type: { _is_null: false } }
            ) {
                ...CardInfoBoxes_thread
            }

            blocker_of_threads(where: { resolved_at: { _is_null: true } }) {
                id

                ticket {
                    id
                    ref
                    title
                }
            }

            merge_requests {
                merge_request {
                    id
                    closed_at
                    merged_at
                    opened_at
                    lines_added
                    lines_removed
                    title
                    url

                    ...merge_request_full
                }
            }
        }
    `),
};

type CardInfoBoxBlockingProps = {
    blockerOfTickets: CardInfoBoxes_ticketFragment["blocker_of_threads"][number]["ticket"][];
};

function CardInfoBoxBlocking({ blockerOfTickets }: CardInfoBoxBlockingProps) {
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    if (!blockerOfTickets.length) {
        return null;
    }

    return (
        <CardInfoBox className={styles.blocking}>
            <div className={styles.blockingHeader}>
                Blocking {blockerOfTickets.length}{" "}
                {blockerOfTickets.length === 1 ? "topic" : "topics"}
            </div>
            <ul className={styles.blockingTickets}>
                {blockerOfTickets.map(t => (
                    <li key={t.id} className={styles.blockingTicket}>
                        <div className={styles.blockingTicketIconWrapper}>
                            <Icon
                                className={styles.blockingTicketIcon}
                                icon="file-text"
                                iconSet="lucide"
                                iconSize={14}
                                strokeWidthAbsolute={1}
                            />
                        </div>
                        <div>
                            <TruncatedText as="span" text={t.title} maxLength={100} />
                            {shouldShowTicketRefs ? (
                                <span className={styles.blockingTicketRef}> (#{t.ref})</span>
                            ) : null}
                        </div>
                    </li>
                ))}
            </ul>
        </CardInfoBox>
    );
}

type CardInfoBoxThreadProps = {
    assignee: Exclude<CardInfoBoxes_threadFragment["assignee"], null | undefined>;
    count: number;
    oldestAssignedAt: string;
    ticketTitle: string;
};

function CardInfoBoxThread({
    assignee,
    count,
    oldestAssignedAt,
    ticketTitle,
}: CardInfoBoxThreadProps) {
    const currentUser = useCurrentUser();

    return (
        <CardInfoBox
            className={classNames(
                styles.cardThread,
                assignee.id === currentUser.id && styles.assignedToMe
            )}
        >
            <Icon icon="chatBubble" iconSet="c9r" iconSize={14} className={styles.threadIcon} />
            <div>
                <span>
                    {count.toLocaleString()} {count === 1 ? "thread" : "threads"} for
                </span>{" "}
                <Hotspot
                    allowListValue={ticketTitle}
                    contentPlacement="bottom"
                    hotspotKey={Enums.HotspotKey.BOARD_CARD_OPEN_THREAD}
                    offset={[0, 24]}
                    placement="right"
                    usePortal={false}
                >
                    <span className={styles.assigneeName}>
                        {assignee.id === currentUser.id ? "me" : assignee.name}
                    </span>
                </Hotspot>
            </div>
            <div style={{ flex: "1 1 auto" }} />
            <TimeAgo className={styles.date} abbreviate date={oldestAssignedAt} />
        </CardInfoBox>
    );
}

type BuildToolItemProps = {
    buildStatus?: CommonEnumValue<"MergeRequestBuildStatus"> | null;
    buildToolStatusInfo: TMergeRequestBuildStatusInfo;
    mergeRequestId: string;
};

function BuildToolItem({ buildStatus, buildToolStatusInfo, mergeRequestId }: BuildToolItemProps) {
    if (!buildStatus) {
        return null;
    }

    return MergeRequestUtils.shouldShowBuildToolLink({ buildStatus, buildToolStatusInfo }) ? (
        <>
            <hr />
            <BuildToolLink
                className={styles.buildToolLink}
                buildToolStatusInfo={buildToolStatusInfo}
                elementName="card.pr_build_tool_status_link"
                mergeRequestId={mergeRequestId}
            />
        </>
    ) : null;
}

type CardInfoBoxPullRequestProps = {
    mr: CardInfoBoxes_ticketFragment["merge_requests"][number]["merge_request"];
};

function CardInfoBoxPullRequest({ mr }: CardInfoBoxPullRequestProps) {
    const {
        buildStatus,
        buildToolsStatusInfo,
        isDelayed,
        hasChangesRequested,
        hasMergeConflict,
        lastActivityAt,
        reviewerNames,
        shouldShowBuildStatusIcon,
        shouldShowBuildToolLinks,
        shouldShowLineDiff,
        status,
    } = useFormattedMergeRequest({ mr });

    const { recordCallback } = useInstrumentation();
    const { recordTicketViewByMergeRequestId } = useRecordTicketView();

    return (
        <Hotspot
            allowListValue={mr.title}
            hotspotKey={Enums.HotspotKey.BOARD_CARD_OPEN_PR}
            offset={[0, -4]}
            placement="left"
            usePortal={false}
            targetTagName="div"
        >
            <CardInfoBox className={styles.pullRequestWrapper}>
                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                <a
                    className={classNames(
                        styles[status],
                        styles.pullRequest,
                        isDelayed && styles.delayed
                    )}
                    target="_blank"
                    role="link"
                    rel="noopener noreferrer"
                    href={mr.url}
                    onClick={recordCallback(
                        {
                            eventType: Enums.InstrumentationEvent.CLICK,
                            elementName: "card.pr_link",
                            eventData: { mergeRequestId: mr.id },
                        },
                        e => {
                            void recordTicketViewByMergeRequestId({ mergeRequestId: mr.id });
                            e.stopPropagation();

                            return false;
                        }
                    )}
                >
                    <MergeRequestIcon className={styles.prIcon} status={status} />

                    <div>
                        <span className={styles.prTitleAndStatusWrapper}>
                            <span className={styles.prTitle}>{mr.title}</span>
                            {shouldShowBuildStatusIcon ? (
                                <span className={styles.prStatusWrapper}>
                                    &nbsp;
                                    <BuildStatusIcon
                                        buildStatus={buildStatus}
                                        className={styles.prStatus}
                                    />
                                </span>
                            ) : null}
                        </span>
                        {shouldShowLineDiff &&
                        typeof mr.lines_added === "number" &&
                        typeof mr.lines_removed === "number" ? (
                            <LineDiff
                                className={styles.lineDiff}
                                linesAdded={mr.lines_added}
                                linesRemoved={mr.lines_removed}
                            />
                        ) : null}
                        {status === CommonEnums.MergeRequestStatus.OPEN ? (
                            <Hotspot
                                allowListValue={mr.title}
                                className={styles.detailedStatusWrapper}
                                hotspotKey={Enums.HotspotKey.BOARD_CARD_STALE_PR}
                                offset={[0, 18]}
                                placement="left"
                                usePortal={false}
                                targetTagName="div"
                            >
                                <MergeRequestStatusSummary
                                    abbreviate
                                    className={styles.detailedStatus}
                                    buildStatus={buildStatus}
                                    hasChangesRequested={hasChangesRequested}
                                    hasMergeConflict={hasMergeConflict}
                                    lastActivityAt={lastActivityAt}
                                    reviewerNames={reviewerNames}
                                />
                            </Hotspot>
                        ) : null}
                    </div>
                </a>
                {shouldShowBuildToolLinks
                    ? buildToolsStatusInfo.map(buildToolStatusInfo => {
                          return (
                              <BuildToolItem
                                  key={JSON.stringify(buildToolStatusInfo)}
                                  buildToolStatusInfo={buildToolStatusInfo}
                                  mergeRequestId={mr.id}
                                  buildStatus={buildStatus}
                              />
                          );
                      })
                    : null}
            </CardInfoBox>
        </Hotspot>
    );
}

export type CardInfoBoxesProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function CardInfoBoxes({ className, ticket: _ticketFragment }: CardInfoBoxesProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const blockerOfTickets = ticket.blocker_of_threads.reduce(
        (tickets, thread) =>
            tickets.some(t => t.id === thread.ticket.id) ? tickets : tickets.concat(thread.ticket),
        [] as CardInfoBoxes_ticketFragment["blocker_of_threads"][number]["ticket"][]
    );

    const assignedThreadsByUserId = ticket.assigned_threads
        .map(threadFragment => getFragmentData(fragments.thread, threadFragment))
        .filter(thread => !thread.resolved_at && !!thread.assignee) // Reapply query filters
        .filter(thread => !thread.blocker_type) // Exclude blockers because they're rendered separately
        .reduce((acc, thread) => {
            acc[thread.assignee!.id] = acc[thread.assignee!.id] || [];
            acc[thread.assignee!.id].push(thread);

            return acc;
        }, {} as Record<number, CardInfoBoxes_threadFragment[]>);

    const assignedThreadAggregates = Object.entries(assignedThreadsByUserId)
        .map(([, threads]) => ({
            assignee: threads[0].assignee!,
            threads,
            count: threads.length,
            oldestAssignedAt: new Date(
                Math.min(...threads.map(th => new Date(th.assigned_at!).getTime()))
            ).toISOString(),
        }))
        .sort(
            (a, b) =>
                new Date(a.oldestAssignedAt).getTime() - new Date(b.oldestAssignedAt).getTime()
        );

    const openMergeRequests = ticket.merge_requests
        .map(mr => mr.merge_request)
        .filter(mr => !mr.closed_at)
        .filter(mr => !mr.merged_at)
        .sort((mrA, mrB) => new Date(mrA.opened_at).getTime() - new Date(mrB.opened_at).getTime());

    if (!blockerOfTickets.length && !assignedThreadAggregates.length && !openMergeRequests.length) {
        return null;
    }

    return (
        <div className={classNames(className, styles.cardInfoBoxes)}>
            <CardInfoBoxBlocking blockerOfTickets={blockerOfTickets} />
            {assignedThreadAggregates.map(({ assignee, count, oldestAssignedAt }) => (
                <CardInfoBoxThread
                    key={assignee.id}
                    assignee={assignee}
                    oldestAssignedAt={oldestAssignedAt}
                    count={count}
                    ticketTitle={ticket.title}
                />
            ))}
            {openMergeRequests.map(mr => (
                <CardInfoBoxPullRequest key={mr.id} mr={mr} />
            ))}
        </div>
    );
}
