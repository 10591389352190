import ExtensionListItem from "@tiptap/extension-list-item";

import { Focus } from "lib/Focus";

export default ExtensionListItem.extend({
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),

            Tab: () => {
                const listItemWasSunk = this.editor.commands.sinkListItem(this.name);

                if (listItemWasSunk) {
                    Focus.temporarilyDisableTabFocusStyling();
                } else {
                    Focus.enableTabFocusStyling();
                }

                return listItemWasSunk;
            },
            "Shift-Tab": () => {
                const listItemWasLifted = this.editor.commands.liftListItem(this.name);

                if (listItemWasLifted) {
                    Focus.temporarilyDisableTabFocusStyling();
                } else {
                    Focus.enableTabFocusStyling();
                }

                return listItemWasLifted;
            },
        };
    },
});
