// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import { Overlay as BlueprintOverlay } from "@blueprintjs/core";
import classNames from "classnames";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import spinner from "img/spinner.webp";
import spinner2x from "img/spinner@2x.webp";
import { UseLiveQueryType, useLiveQuery } from "lib/apollo/useLiveQuery";

import styles from "./Loading.module.scss";

export const loadingState = atom({
    key: "LoadingState",
    default: {
        dataSync: {
            isReady: false,
            isRequired: false,
        },
        isAppReady: false,
        isViewReady: false,
    },
});

export const useSetIsAppReady = () => {
    const setLoadingState = useSetRecoilState(loadingState);
    const setIsAppReady = useCallback(
        (isReady: boolean) => {
            setLoadingState(prev => ({ ...prev, isAppReady: isReady }));
        },
        [setLoadingState]
    );

    return setIsAppReady;
};

export const useSetDataSyncLoading = () => {
    const setLoadingState = useSetRecoilState(loadingState);
    const setDataSyncLoading = useCallback(
        ({ isReady, isRequired }: { isReady: boolean; isRequired: boolean }) => {
            setLoadingState(prev => ({
                ...prev,
                dataSync: { isReady, isRequired },
            }));
        },
        [setLoadingState]
    );

    return setDataSyncLoading;
};

export const useSetIsViewReady = () => {
    const setLoadingState = useSetRecoilState(loadingState);
    const setIsViewReady = useCallback(
        (isReady: boolean) => {
            setLoadingState(prev => ({ ...prev, isViewReady: isReady }));
        },
        [setLoadingState]
    );

    return setIsViewReady;
};

export function LoadingOverlay() {
    const timeout = useRef<number>();
    const { dataSync, isAppReady, isViewReady } = useRecoilValue(loadingState);
    const setIsAppReady = useSetIsAppReady();
    const isReady = isAppReady && (!dataSync.isRequired || dataSync.isReady);

    const [showOverlay, setShowOverlay] = useState(true);
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        if (isViewReady && !isAppReady) {
            setIsAppReady(true);
        }
    }, [isAppReady, isViewReady, setIsAppReady]);

    useEffect(() => {
        if (!isReady) {
            setShowSpinner(true);
            setShowOverlay(true);
        }

        if (showOverlay && isReady) {
            setShowSpinner(false);
            timeout.current = window.setTimeout(() => setShowOverlay(false), 200);
        }

        return () => {
            window.clearTimeout(timeout.current);
        };
    }, [isReady, showOverlay]);

    return (
        <BlueprintOverlay
            autoFocus={false}
            backdropClassName={classNames(styles.backdrop)}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            // Necessary to set enforceFocus to false. The loading overlay appears immediately
            // while app content renders underneath. We want the app content to be able to use
            // autoFocus, if relevant.
            //
            // The downside is that a user could conceivably Tab through elements underneath
            // the overlay. But that's unlikely.
            enforceFocus={false}
            isOpen={showOverlay}
            transitionDuration={300}
            transitionName="loading-overlay"
        >
            <div data-cy="loading-wrapper" className={styles.loadingWrapper}>
                <BrandedLoadingSpinner isHidden={!showSpinner} />
            </div>
        </BlueprintOverlay>
    );
}

function BrandedLoadingSpinner({ isHidden }: { isHidden?: boolean }) {
    const ref = useRef<HTMLImageElement>(null);

    // This is an awkward way to ensure that, when the loading spinner animated image renders, it
    // starts from the first frame. We do that by temporarily making the image a transparent single
    // pixel GIF, then swap back to the real image.
    useEffect(() => {
        if (ref.current) {
            const src = ref.current.getAttribute("src");
            const srcSet = ref.current.getAttribute("srcset") ?? "";

            ref.current.src =
                "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
            ref.current.srcset = "";

            setImmediate(() => {
                if (ref.current && src) {
                    ref.current.src = src;
                    ref.current.srcset = srcSet;
                }
            });
        }
    }, []);

    return (
        <img
            alt="Loading spinner"
            className={classNames(styles.spinner, isHidden && styles.hideSpinner)}
            ref={ref}
            srcSet={`${spinner}, ${spinner2x} 2x`}
            src={spinner2x}
            width={150}
        />
    );
}

export const useTrackViewQueryLoading = (queryResult: { loading: boolean; data?: unknown }) => {
    const setIsViewReady = useSetIsViewReady();

    useEffect(() => {
        setIsViewReady(!queryResult.loading || !!queryResult.data);
    }, [setIsViewReady, queryResult.loading, queryResult.data]);
};

export const useViewQuery: typeof useQuery = (...args) => {
    const queryResult = useQuery(...args);

    useTrackViewQueryLoading(queryResult);

    return queryResult;
};

export const useLiveViewQuery: UseLiveQueryType = (...args) => {
    const queryResult = useLiveQuery(...args);

    useTrackViewQueryLoading(queryResult);

    return queryResult;
};
