import React, { useCallback, useMemo } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog } from "components/ui/core/Dialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog } from "lib/Hooks";

import styles from "./NotionImport.module.scss";

type NotionImportDialogProps = { isOpen: boolean; onClose?: () => void };

function NotionImportDialog({ isOpen, onClose }: NotionImportDialogProps) {
    return (
        <Dialog
            className={styles.dialog}
            title="Import from Notion"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <p>
                    To get started importing from Notion, first{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href="https://www.notion.so/help/export-your-content#export-as-html"
                    >
                        export your account's data as HTML
                    </a>
                    .
                </p>
                <p>
                    Then, contact us at <SupportMailto />. We'll be happy to work with you – for
                    free – to get your data into Flat.
                </p>
            </Dialog.Body>
            <Dialog.Footer />
        </Dialog>
    );
}

const useNotionImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();

    const notionImportDialog = useMemo(
        () => <NotionImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateNotionImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        dialog.open();
    }, [dialog, gateFeature]);

    return { initiateNotionImport, notionImportDialog };
};

export { useNotionImport };
