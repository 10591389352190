import React, { useEffect, useState } from "react";

import { useMaybeCurrentIdentity } from "AppState";
import { Config } from "Config";
import { OnboardingToaster } from "components/ui/common/OnboardingToaster";
import { AppToaster } from "components/ui/core/AppToaster";
import { Icon } from "components/ui/core/Icon";
import { isIdentityOnboarding } from "lib/Helpers";
import { Log } from "lib/Log";
import { useSyncEmailVerification } from "lib/mutations";

export type EmailVerificationSyncProps = {
    children: React.ReactNode;
};

export function EmailVerificationSync({ children }: EmailVerificationSyncProps) {
    const currentIdentity = useMaybeCurrentIdentity();
    const { syncEmailVerification } = useSyncEmailVerification();
    const [didAttemptSync, setDidAttemptSync] = useState(false);

    useEffect(() => {
        if (!currentIdentity) {
            return;
        }

        if (
            currentIdentity.isReadOnly ||
            currentIdentity.is_email_address_verified ||
            didAttemptSync
        ) {
            return;
        }

        (async () => {
            const syncResult = await syncEmailVerification();

            if (!syncResult.data?.sync_email_verification.ok) {
                Log.error("Failed to sync email verification", { reason: "Backend error" });
            }

            const updatedIdentity = syncResult.data?.sync_email_verification.identity;

            if (isIdentityOnboarding(currentIdentity)) {
                if (updatedIdentity?.is_email_address_verified) {
                    OnboardingToaster.show({
                        icon: <Icon icon="check" iconSet="lucide" iconSize={24} />,
                        message: "Your email has been verified.",
                    });
                }
            } else if (!syncResult.data?.sync_email_verification.ok) {
                AppToaster.error({
                    message: "Something went wrong verifying your email.",
                });
            } else if (updatedIdentity?.is_email_address_verified) {
                AppToaster.success({
                    message: "Your email has been verified.",
                });
            }

            setDidAttemptSync(true);
        })();
    }, [currentIdentity, didAttemptSync, syncEmailVerification]);

    if (Config.auth.provider !== "AUTH0") {
        return <>{children}</>;
    }

    if (
        currentIdentity?.isReadOnly ||
        currentIdentity?.is_email_address_verified ||
        didAttemptSync
    ) {
        return <>{children}</>;
    }

    return null;
}
