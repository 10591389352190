import React, { useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import { FormGroup } from "@blueprintjs/core";
import { DbColumnTypes } from "c9r-common";
import classNames from "classnames";

import { Config } from "Config";
import { InfoCircle } from "components/ui/common/InfoCircle";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { useBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useAsyncWatcher } from "lib/Hooks";
import { Log } from "lib/Log";
import { Storage } from "lib/Storage";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useProvisionOrg } from "lib/mutations";

import styles from "./SetupOrgScreen.module.scss";
import { DefaultOrgDemographics, OnboardingDemographics } from "../OnboardingConstants";
import { TWizardContext } from "../OnboardingTypes";
import { ContentBox } from "../ui/ContentBox";
import { DropdownMenu } from "../ui/DropdownMenu";
import { TextInput } from "../ui/TextInput";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment SetupOrgScreen_org on orgs {
            id
            demographic_info
            display_name
        }
    `),
};

export type SetupOrgScreenProps = {
    className?: string;
    org?: FragmentType<typeof fragments.org>;
    onComplete: (params?: Partial<TWizardContext>) => void;
};

export function SetupOrgScreen({ className, org: _orgFragment, onComplete }: SetupOrgScreenProps) {
    const existingOrg = getFragmentData(fragments.org, _orgFragment) ?? undefined;
    const breakpoints = useBreakpoints();

    const { provisionOrg } = useProvisionOrg();
    const [updateOrg] = useMutation(SetupOrgScreen.mutations.updateOrg, {
        context: {
            apiRoleType: Enums.ApiRoleType.IDENTITY,
        },
    });

    const displayNameInputRef = useRef<HTMLInputElement>(null);
    const [displayNameHasError, setDisplayNameHasError] = useState(false);

    const [displayName, orgDisplayName] = useState(existingOrg?.display_name ?? "");
    const [demographicInfo, setDemographicInfo] = useState<DbColumnTypes.OrgsDemographicInfo>({
        teamSize: existingOrg?.demographic_info?.teamSize ?? DefaultOrgDemographics.teamSize,
        tool: existingOrg?.demographic_info?.tool ?? DefaultOrgDemographics.tool,
    });

    const submission = useAsyncWatcher();

    const inputsInOrder = [{ ref: displayNameInputRef, isValid: !!displayName.trim() }];
    const firstInvalidInput = inputsInOrder.find(({ isValid }) => !isValid);

    const handleUpdateOrg = async ({ orgId }: { orgId: number }) => {
        Log.info("Updating org info", { orgId });

        await updateOrg({
            variables: {
                orgId,
                demographicInfo,
                displayName,
            },
            optimisticResponse: {
                update_orgs_by_pk: {
                    __typename: "orgs",
                    id: orgId,
                    demographic_info: demographicInfo,
                    display_name: displayName,
                },
            },
        });

        Log.info("Updated org info", { orgId });
    };

    const handleSubmit = async () => {
        setDisplayNameHasError(!displayName.trim());

        if (firstInvalidInput?.ref.current) {
            firstInvalidInput.ref.current.focus();
            return;
        }

        const startTime = Date.now();

        // Ensure the submission isn't instant, so it actually "feels" like something happened.
        const delaySubmission = async () =>
            new Promise<void>(resolve => {
                setTimeout(() => resolve(), Math.max(900 - (Date.now() - startTime), 0));
            });

        try {
            if (existingOrg) {
                await handleUpdateOrg({ orgId: existingOrg.id });
                await delaySubmission();
                onComplete();
            } else {
                const signupPreferences = Storage.Local.getItem("signup.preferences");
                let planKey = null;
                let billingInterval = null;

                if (
                    signupPreferences?.asOf &&
                    Date.now() - signupPreferences.asOf <= Config.onboarding.signupPreferencesTtlMs
                ) {
                    planKey = signupPreferences?.planKey ?? null;
                    billingInterval = signupPreferences?.billingInterval ?? null;
                }

                Log.info("Provisioning a new org", { planKey, billingInterval });

                const result = await provisionOrg({
                    displayName,
                    planKey,
                    billingInterval,
                });

                if (!result.data?.provision_org.ok) {
                    throw new Error((result.data?.provision_org.error as any).code);
                }

                const org = result.data.provision_org!.org!;
                const orgId = org.id;
                const orgSlug = org.slug;
                const userId = org.users[0].id;

                Log.info("Provisioned a new org", { orgId, planKey, billingInterval });

                await handleUpdateOrg({ orgId });
                await delaySubmission();
                onComplete({ orgId, orgSlug, userId });
            }
        } catch (error) {
            Log.error("Failed to create or update org", { error, orgId: existingOrg?.id });
            AppToaster.error({
                message: "Sorry, something went wrong setting up your organization.",
            });
        }
    };

    return (
        <ContentBox className={classNames(className, styles.contentBox)}>
            <div className={styles.screen}>
                <FormGroup
                    className={styles.inputGroup}
                    label={
                        <>
                            <label className={styles.inputRowLabel} htmlFor="orgName">
                                Organization name
                            </label>
                        </>
                    }
                >
                    <TextInput
                        id="orgName"
                        autoFocus
                        fill
                        hasError={displayNameHasError}
                        inputRef={displayNameInputRef}
                        placeholder="Wright Bicycles"
                        value={displayName}
                        onChange={e => {
                            orgDisplayName(e.target.value);
                            setDisplayNameHasError(false);
                        }}
                    />
                </FormGroup>
                <div className={classNames(styles.inputGroup, styles.demographicsInputGroup)}>
                    <div className={styles.demographicsInputRow}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                            className={classNames(styles.inputRowLabel, styles.dropdownLabel)}
                            htmlFor="teamSizeDropdown"
                        >
                            My team size is
                        </label>
                        <DropdownMenu
                            currentValue={
                                demographicInfo.teamSize || DefaultOrgDemographics.teamSize
                            }
                            id="teamSizeDropdown"
                            items={OnboardingDemographics.TeamSizes}
                            onChange={newTeamSize => {
                                setDemographicInfo((prev: any) => ({
                                    ...prev,
                                    teamSize: newTeamSize,
                                }));
                            }}
                        />
                    </div>
                    <div className={styles.demographicsInputRow}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                            className={classNames(styles.inputRowLabel, styles.dropdownLabel)}
                            htmlFor="currentToolDropdown"
                        >
                            {breakpoints.lgMin ? "My team is currently using" : "We're using"}
                        </label>
                        <DropdownMenu
                            currentValue={demographicInfo.tool || DefaultOrgDemographics.tool}
                            id="currentToolDropdown"
                            items={OnboardingDemographics.Tools}
                            onChange={newTool => {
                                setDemographicInfo((prev: any) => ({
                                    ...prev,
                                    tool: newTool,
                                }));
                            }}
                        />
                        <InfoCircle
                            className={styles.infoCircle}
                            content={<span>This will help us import your data.</span>}
                            placement="right"
                        />
                    </div>
                </div>
                <BorderButton
                    className={classNames(styles.ctaBtn, styles.submitBtn)}
                    cta
                    fill
                    large
                    content={!existingOrg ? "Create organization" : "Update organization"}
                    loading={submission.isInFlight}
                    onClick={submission.watch(handleSubmit, {
                        delayMs: firstInvalidInput ? undefined : 750,
                    })}
                    instrumentation={{
                        elementName: "onboarding.setup_org_btn",
                    }}
                />
            </div>
        </ContentBox>
    );
}

SetupOrgScreen.mutations = {
    updateOrg: gql(/* GraphQL */ `
        mutation SetupOrgScreenUpdateOrg(
            $orgId: Int!
            $displayName: String!
            $demographicInfo: jsonb!
        ) {
            update_orgs_by_pk(
                pk_columns: { id: $orgId }
                _set: { demographic_info: $demographicInfo, display_name: $displayName }
            ) {
                id
                demographic_info
                display_name
            }
        }
    `),
};
