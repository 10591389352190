import { useEffect, useMemo, useState } from "react";

import * as Comlink from "comlink";
import MiniSearch from "minisearch";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TasksSearchIndex_taskFragment } from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import TaskSearchIndexWorker, { SearchIndexWorkerApi } from "./SearchIndex.worker";
import { buildIndexOptions } from "./SearchIndexHelpers";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment TasksSearchIndex_task on tasks {
            id
            title
            ticket_id
        }
    `),
};

const taskSearchIndexWorker = Comlink.wrap<typeof SearchIndexWorkerApi>(
    new TaskSearchIndexWorker()
);

export const useTaskSearchIndex = ({
    tasks: _taskFragments,
    query,
    shouldReturnTasks,
}: {
    tasks: FragmentType<typeof fragments.task>[] | null;
    query: string;
    shouldReturnTasks?: boolean;
}) => {
    const [searchIndex, setSearchIndex] = useState<MiniSearch | null>(null);
    const [isTaskSearchIndexLoading, setIsTaskSearchIndexLoading] = useState(true);

    const tasks = useMemo(
        () => (_taskFragments ? _taskFragments.map(t => getFragmentData(fragments.task, t)) : null),
        [_taskFragments]
    );

    const tasksById = useMemo(
        () => (tasks ? Object.fromEntries(tasks.map(task => [task.id, task])) : null),
        [tasks]
    );

    useEffect(() => {
        (async () => {
            setIsTaskSearchIndexLoading(true);

            if (!tasks) {
                return;
            }

            const indexOptions = {
                fields: ["title"],
                storeFields: ["id", "ticket_id"],
            };

            const data = tasks.map(task => ({
                id: task.id,
                title: task.title,
                ticket_id: task.ticket_id,
            }));

            const serializedIndex = await taskSearchIndexWorker.buildIndex({ indexOptions, data });

            setSearchIndex(MiniSearch.loadJSON(serializedIndex, buildIndexOptions(indexOptions)));

            setIsTaskSearchIndexLoading(false);
        })();
    }, [tasks]);

    const matchingTasks = useMemo(() => {
        if (
            !shouldReturnTasks ||
            !tasks ||
            !tasksById ||
            !searchIndex ||
            isTaskSearchIndexLoading
        ) {
            return [];
        }

        const searchQuery = query.replace(/#(\d*)/gi, "$1");

        if (!searchQuery) {
            return tasks;
        }

        return searchIndex
            .search(searchQuery)
            .map(({ id }) => tasksById[id])
            .filter(isDefined);
    }, [isTaskSearchIndexLoading, query, searchIndex, shouldReturnTasks, tasks, tasksById]);

    const matchingTasksByTicketId = useMemo(() => {
        return matchingTasks.reduce((acc, c) => {
            acc[c.ticket_id] = acc[c.ticket_id] ?? [];
            acc[c.ticket_id].push(c);
            return acc;
        }, {} as Record<string, TasksSearchIndex_taskFragment[]>);
    }, [matchingTasks]);

    const result = useMemo(
        () => ({
            matchingTasks,
            matchingTasksByTicketId,
            isTaskSearchIndexLoading,
        }),
        [isTaskSearchIndexLoading, matchingTasks, matchingTasksByTicketId]
    );

    return result;
};
