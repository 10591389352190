import { useCallback } from "react";

import { CommonEnumValue } from "c9r-common";

import { useCurrentIdentity } from "contexts/IdentityContext";
import { Enums } from "lib/Enums";
import { Queries } from "lib/Queries";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useProvisionOrg() {
    const currentIdentity = useCurrentIdentity();

    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ProvisionOrg(
                $displayName: String!
                $planKey: String
                $billingInterval: String
            ) {
                provision_org(
                    display_name: $displayName
                    plan_key: $planKey
                    billing_interval: $billingInterval
                ) {
                    ok
                    error
                    org_id

                    org {
                        id
                        slug

                        users {
                            id
                        }
                    }
                }
            }
        `)
    );

    const provisionOrg = useCallback(
        async (
            {
                displayName,
                planKey,
                billingInterval,
            }: {
                displayName: string;
                planKey?: CommonEnumValue<"SubscriptionPlanKey">;
                billingInterval?: CommonEnumValue<"SubscriptionBillingInterval">;
            },
            mutationOptions?: Omit<
                MutationCallbackOptions,
                "awaitRefetchQueries" | "refetchQueries"
            >
        ) => {
            return mutation({
                ...mutationOptions,
                awaitRefetchQueries: true,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.IDENTITY,
                },
                refetchQueries: [
                    {
                        context: {
                            apiRoleType: Enums.ApiRoleType.IDENTITY,
                        },
                        query: Queries.get({ component: "IdentityContext", name: "component" }),
                        variables: {
                            identityId: currentIdentity.id,
                        },
                    },
                ],
                variables: { displayName, planKey, billingInterval },
            });
        },
        [currentIdentity.id, mutation]
    );

    return { provisionOrg };
}
