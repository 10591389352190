import { isDefined } from "lib/types/guards";

import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const TicketResolvers: TResolvers = {
    query_root: {
        tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.tickets.findAll())
        ),

        tickets_by_pk: buildResolver(async (parent, args, { api }) =>
            api.tickets.getById({ id: args.id })
        ),
    },

    tickets: {
        attachments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.ticketAttachments.findByTicketId({ ticketId: parent.id })
            )
        ),

        board: buildResolver(async (parent, args, { api }) =>
            api.boards.getByIdOrThrow({ id: parent.board_id })
        ),

        blocker_of_threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (
                    await Promise.all(
                        parent.blocker_of_threads.map(thread =>
                            api.threads.getById({ id: thread.id })
                        )
                    )
                ).filter(isDefined)
            )
        ),

        child_of_tasks: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (
                    await Promise.all(
                        parent.child_of_tasks.map(task => api.tasks.getById({ id: task.id }))
                    )
                ).filter(isDefined)
            )
        ),

        comments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.comments.findByTicketId({ ticketId: parent.id })
            )
        ),

        merge_requests: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (
                    await Promise.all(
                        parent.merge_requests.map(tm =>
                            api.mergeRequests.getById({ id: tm.merge_request_id })
                        )
                    )
                )
                    .filter(isDefined)
                    .map(
                        mr =>
                            ({
                                __typename: "tickets_merge_requests",
                                merge_request_id: mr.id,
                                ticket_id: parent.id,
                                merge_request: mr,
                                ticket: parent,
                            } as const)
                    )
            )
        ),

        owners: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { userContextApi }) =>
                parent.owners
                    .map(to => {
                        const owner = userContextApi.getUserById({ userId: to.user_id });

                        if (!owner) {
                            return null;
                        }

                        return {
                            __typename: "tickets_owners",
                            user_id: to.user_id,
                            ticket_id: parent.id,
                            added_at: to.added_at,
                            type: to.type,
                            owner,
                            ticket: parent,
                        } as const;
                    })
                    .filter(isDefined)
            )
        ),

        stage: buildResolver(async (parent, args, { api }) =>
            api.stages.getByIdOrThrow({ id: parent.stage_id })
        ),

        org: buildResolver(async (parent, args, { userContextApi }) => userContextApi.getOrg()),

        tasklists: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.tasklists.findByTicketId({ ticketId: parent.id })
            )
        ),

        tasks: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.tasks.findByTicketId({ ticketId: parent.id })
            )
        ),

        threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.threads.findByTicketId({ ticketId: parent.id })
            )
        ),

        ticket_history_events: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.ticketHistoryEvents.findByTicketId({ ticketId: parent.id })
            )
        ),

        watchers: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { userContextApi }) =>
                parent.watchers
                    .map(tw => {
                        const watcher = userContextApi.getUserById({ userId: tw.user_id });

                        if (!watcher) {
                            return null;
                        }

                        return {
                            __typename: "tickets_watchers",
                            user_id: tw.user_id,
                            ticket_id: parent.id,
                            watcher,
                            ticket: parent,
                        } as const;
                    })
                    .filter(isDefined)
            )
        ),
    },
};
