import { FocusStyleManager } from "@blueprintjs/core";

const className = "bp3-focus-disabled";

/**
 * Wrapper around blueprint's FocusStyleManager with additional helper methods.
 */
export class Focus {
    static init() {
        // How does FocusStyleManager ensure we only show the focus outline when the user hits tab, not
        // focuses with the mouse? It adds the class bp3-focus-disabled to the top-level html element
        // when focus outlines should not be shown (last interaction was a mouse) and removes the class
        // when focus outlines should be shown (last interaction was the tab key).
        //
        // But there's a slight bug. When the app is first loaded, the class is not present by default.
        // That means if an element is in focus when the app loads, the outline will show, which would
        // be odd.
        //
        // Of course, we could have added this class to the static HTML, but adding it here, with this
        // comment, makes it clearer.
        FocusStyleManager.onlyShowFocusOnTabs();
        document.getElementsByTagName("html")[0]?.classList.add(className);
    }

    static enableTabFocusStyling() {
        document.getElementsByTagName("html")[0]?.classList.remove(className);
    }

    static temporarilyDisableTabFocusStyling() {
        const initiallyHadClass = document
            .getElementsByTagName("html")[0]
            ?.classList.contains(className);

        FocusStyleManager.alwaysShowFocus();
        document.getElementsByTagName("html")[0]?.classList.add(className);

        setImmediate(() => {
            FocusStyleManager.onlyShowFocusOnTabs();

            if (!initiallyHadClass) {
                setImmediate(() =>
                    document.getElementsByTagName("html")[0]?.classList.remove(className)
                );
            }
        });
    }
}
