import React from "react";

import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover, MenuPopoverProps } from "components/ui/core/MenuPopover";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment TasklistChildTicketMenuPopover_task on tasks {
            id
            task_type
        }
    `),
};

export type TasklistChildTicketMenuPopoverProps = {
    children: React.ReactNode;
    onDelete: () => void;
    popoverProps?: Omit<MenuPopoverProps, "children">;
    task: FragmentType<typeof fragments.task>;
};

export function TasklistChildTicketMenuPopover({
    children,
    onDelete,
    popoverProps,
    task: _taskFragment,
}: TasklistChildTicketMenuPopoverProps) {
    const task = getFragmentData(fragments.task, _taskFragment);

    return (
        <MenuPopover
            content={
                <Menu>
                    <MenuItem
                        key="delete"
                        text="Detach child topic"
                        icon={<Icon icon="x" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "task.task_menu.detach_child_ticket",
                            eventData: { taskId: task.id },
                        }}
                        onClick={onDelete}
                    />
                </Menu>
            }
            {...popoverProps}
        >
            {children}
        </MenuPopover>
    );
}
