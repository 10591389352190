import React from "react";

import ExtensionImage from "@tiptap/extension-image";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import classNames from "classnames";
import { atom, useRecoilValue } from "recoil";
import isURL from "validator/lib/isURL";

import { ProgressBar } from "components/ui/common/ProgressBar";
import { ProgressText } from "components/ui/common/ProgressText";
import { pendingUploadsState } from "lib/Uploads";

import styles from "./Image.module.scss";
import editorStyles from "../Editor.module.scss";

export const pendingImageIdsState = atom<string[]>({
    key: "PendingImageIds",
    default: [],
});

type ImageNodeViewProps = {
    node: any;
    selected: boolean;
};

function ImageNodeView({ node, selected }: ImageNodeViewProps) {
    const isPendingImageIdActive = !!useRecoilValue(pendingImageIdsState).find(
        id => id === node.attrs.pendingImageId
    );
    const pendingUpload = useRecoilValue(pendingUploadsState).find(
        ({ uuid }) => uuid === node.attrs.uploadId
    );

    if (isPendingImageIdActive || pendingUpload) {
        return (
            <NodeViewWrapper as="div" className={styles.placeholder}>
                <div className={styles.wrapper}>
                    <ProgressText
                        className={styles.progressText}
                        intervalMs={500}
                        text="Uploading image"
                    />
                    <ProgressBar
                        heightPx={4}
                        fill
                        value={pendingUpload ? pendingUpload.progressFraction * 100 : 0}
                    />
                </div>
            </NodeViewWrapper>
        );
    }

    return (
        <NodeViewWrapper
            as="a"
            href={node.attrs.src}
            role="link"
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.imageAnchor, selected && editorStyles.selectedNode)}
        >
            {/* By setting contenteditable to true, when image is selected using the browser's
            normal text selection, it renders the usual selection highlight. */}
            <img
                contentEditable
                data-drag-handle
                src={node.attrs.src}
                alt={node.attrs.alt}
                title={node.attrs.title}
            />
        </NodeViewWrapper>
    );
}

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        imageExtension: {
            insertImageByUrl: ({ url }: { url: string }) => ReturnType;
        };
    }
}

export default ExtensionImage.extend({
    addAttributes() {
        return {
            ...this.parent?.(),

            pendingImageId: {
                default: null,
            },

            uploadId: {
                default: null,
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),

            insertImageByUrl: ({ url }) => ({ chain }) => {
                if (!url || !isURL(url, { require_protocol: false, validate_length: false })) {
                    chain().focus().run();

                    return true;
                }

                const src = isURL(url, { require_protocol: true, validate_length: false })
                    ? url
                    : `https://${url}`;

                chain().focus().setImage({ src }).run();

                return true;
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageNodeView);
    },
});
