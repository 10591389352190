import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom";
import { HotkeysProvider } from "react-hotkeys-hook";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import "./index.scss";

import { App } from "App";
import { Config } from "Config";
import { ThemeContextProvider } from "contexts/ThemeContext";
import { Enums } from "lib/Enums";
import { useHistory } from "lib/Routing";

import * as serviceWorker from "./serviceWorker";

// BrowserRouter does not work in Electron's file based environment.
// We could use HashRouter, but that does not support location.state.
// Therefore, we use MemoryRouter.
const Router = window.electron ? MemoryRouter : BrowserRouter;

function Wrapper() {
    const { history } = useHistory();

    return (
        <Auth0Provider
            authorizationParams={{
                audience: "hasura",
                redirect_uri: `${window.location.origin}/login`,
            }}
            domain={Config.auth0.domain}
            clientId={Config.auth0.clientId}
            cacheLocation="memory"
            onRedirectCallback={appState => {
                // If the login had an error, we want to show that error instead of the app.
                // To accomplish that, we just do _nothing_ here. As a result, the app will
                // render the "/login" route, which will display the error.
                if (new URLSearchParams(window.location.search).get("error")) {
                    return;
                }

                // We replace the current history item with the index route for two purposes:
                // - Removes the auth code from the history
                // - Ensures that, if entering the app immediately on a full screen modal,
                //   there is a route to go "back" to
                history.replace("/");
                history.push(`${appState.pathname}${appState.search || ""}${appState.hash || ""}`);
            }}
            skipRedirectCallback={window.location.pathname.includes("/oauth/redirect")}
        >
            <HotkeysProvider initiallyActiveScopes={[Enums.HotkeyScope.DEFAULT]}>
                <ThemeContextProvider>
                    <App />
                </ThemeContextProvider>
            </HotkeysProvider>
        </Auth0Provider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <Router>
                <Wrapper />
            </Router>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("appRoot")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
