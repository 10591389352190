import { Positioner } from "c9r-common";

import { Config } from "Config";

type EntityId = number | string;

// TODO: This should really be generic, with some way to enforce that the posFieldName exists
// and points to a number.
type Entity = { id: EntityId };

const positioner = new Positioner({ config: Config.entityPositioning });

export const generateDefaultPos = positioner.generateDefaultPos.bind(positioner);
export const generateRandomPos = positioner.generateRandomPos.bind(positioner);
export const generatePositionsBetween = positioner.generatePositionsBetween.bind(positioner);
export const interpolateMissingPositions = positioner.interpolateMissingPositions.bind(positioner);
export const moveToFirstPosition = positioner.moveToFirstPosition.bind(positioner);
export const moveToLastPosition = positioner.moveToLastPosition.bind(positioner);
export const moveToPosition = positioner.moveToPosition.bind(positioner);

/**
 * Compute the new position for an entity that has been created in or moved within a list, e.g.,
 * when creating or moving a ticket, task, etc.
 */
export function moveToPositionByIndex<TEntity extends Entity>({
    sortedEntities,
    posFieldName,
    toIndex,
    entityId,
}: {
    /**
     * The list of entities, possibly empty. Each must have an 'id' property. Must already
     * be sorted in the desired order.
     */
    sortedEntities: TEntity[];

    /** The name of the entity field that has the position. */
    posFieldName: keyof TEntity;

    /** Index to move the entity to. */
    toIndex: number;

    /** The id of the entity being moved. Omit if creating a new entity. */
    entityId?: EntityId;
}) {
    const filteredEntities = sortedEntities.filter(e => e.id !== entityId);
    const firstEntity = filteredEntities[0];
    const lastEntity = filteredEntities[filteredEntities.length - 1];
    const entityBefore = filteredEntities[toIndex - 1];
    const entityAfter = filteredEntities[toIndex];

    if (!filteredEntities.length) {
        return positioner.generateDefaultPos();
    }

    if (toIndex <= 0) {
        return moveToFirstPosition({
            minPos: firstEntity && ((firstEntity[posFieldName] as unknown) as number),
        });
    }

    if (toIndex >= filteredEntities.length) {
        return moveToLastPosition({
            maxPos: lastEntity && ((lastEntity[posFieldName] as unknown) as number),
        });
    }

    return entityBefore && entityBefore[posFieldName] && entityAfter && entityAfter[posFieldName]
        ? (((entityBefore[posFieldName] as unknown) as number) +
              ((entityAfter[posFieldName] as unknown) as number)) /
              2
        : positioner.generateDefaultPos();
}
