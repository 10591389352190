import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { Editor as TCoreEditor } from "@tiptap/core";
import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Editor } from "components/ui/editor/Editor";
import { useDraft } from "lib/Drafts";
import { Enums } from "lib/Enums";
import { scrollElementIntoViewIfNeeded } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TRichTextContentSerializers } from "lib/types/common/richText";

import { CommentAssignmentControls } from "./CommentAssignmentControls";
import { CommentExplanatoryFooter } from "./CommentExplanatoryFooter";
import { useCommentAssignmentState } from "./CommentState";
import styles from "./NewCommentEditor.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment NewCommentEditor_board on boards {
            id

            authorized_users {
                user {
                    id

                    ...CommentAssignmentControls_user
                }
            }

            ...CommentAssignmentState_board
            ...Editor_board
        }
    `),
};

export type NewCommentEditorProps = {
    board: FragmentType<typeof fragments.board>;
    className?: string;
    draftStorageKey?: string;
    isReply?: boolean;
    isResolved?: boolean;
    onCancel?: () => void;
    onChange?: (isPopulated: boolean) => void;
    onStart?: () => void;
    onSubmit?: ({
        serializers,
        shouldAssign,
        newAssignee,
        shouldResolve,
    }: {
        serializers: React.MutableRefObject<TRichTextContentSerializers | null>;
        shouldAssign: boolean;
        newAssignee: { id: number } | null;
        shouldResolve: boolean;
    }) => void;
    recentUserIds?: number[];
    threadAssignedToUserId?: number | null;
};

export function NewCommentEditor({
    board: _boardFragment,
    className,
    draftStorageKey = "",
    isReply,
    isResolved,
    onCancel,
    onChange,
    onStart,
    onSubmit,
    recentUserIds = [],
    threadAssignedToUserId,
}: NewCommentEditorProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const { loadDraft, discardDraft, throttledSaveDraft } = useDraft({
        localStorageKey: draftStorageKey,
    });
    const draft = useRef(loadDraft());
    const [isPopulated, setIsPopulated] = useState(!!draft.current?.content.text);
    const [commentText, setCommentText] = useState(draft.current?.content.text ?? null);
    const [visible, setVisible] = useState(false);
    const footerRef = useRef<HTMLDivElement>(null);
    const footerScrollTimeout = useRef<number>();
    const serializers = useRef<TRichTextContentSerializers | null>(null);
    const editorRef = useRef<any | null>(null);

    const {
        assignToName,
        isAssigningToCurrentUser,
        mentionedUserIds,
        newAssignee,
        possibleAssignmentUsers,
        setNewAssigneeUserId,
        setShouldAssign,
        shouldAssign,
    } = useCommentAssignmentState({
        board,
        commentText,
        recentUserIds,
        threadAssignedToUserId,
    });

    const showDropdown = isReply && !isResolved && !shouldAssign;

    const handleCreate = useCallback(
        (newSerializers: TRichTextContentSerializers, editor: TCoreEditor) => {
            editorRef.current = editor;
            serializers.current = newSerializers;
        },
        []
    );

    useLayoutEffect(() => {
        // Only make new comment editor visible once all DOM mutations have taken place.
        setVisible(true);
    }, []);

    // Delete draft on unmount if text is empty.
    useEffect(() => {
        return () => {
            if (!commentText?.trim()) {
                discardDraft();
            }
        };
    }, [commentText, discardDraft]);

    const handleUpdate = () => {
        const newIsPopulated = serializers.current?.isPopulated();
        const newCommentText = serializers.current?.getText();

        if (newIsPopulated && onStart) {
            onStart();
        }

        throttledSaveDraft.callback({
            draft: {
                // Need to save JSON for editor content.
                content: {
                    json: serializers.current?.getJSON(),
                    text: serializers.current?.getText(),
                },
                timestamp: loadDraft()?.timestamp ?? new Date().getTime(),
            },
        });
        onChange?.(!!newIsPopulated);
        setIsPopulated(!!newIsPopulated);
        setCommentText(newCommentText);

        // The idea here is to make sure that the cancel/submit buttons are in view after
        // the user stops typing.
        //
        // As of May 2022, this must be in a setTimeout or setImmediate. If not, then on iOS,
        // typing into the editor results in the cursor being visually displayed in the wrong
        // position.
        window.clearTimeout(footerScrollTimeout.current);
        footerScrollTimeout.current = window.setTimeout(() => {
            if (!footerRef.current) {
                return;
            }

            scrollElementIntoViewIfNeeded({
                element: footerRef.current,
                scrollIntoViewArg: {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                },
            });
        }, 250);
    };

    const handleCancel = useCallback(() => {
        clearTimeout(footerScrollTimeout.current);
        discardDraft();
        onCancel?.();
    }, [onCancel, discardDraft]);

    const handleSubmit = useCallback(
        ({ shouldResolve }: { shouldResolve?: boolean } = {}) => {
            clearTimeout(footerScrollTimeout.current);
            discardDraft();
            editorRef.current.commands.trimContent();

            onSubmit?.({
                serializers,
                shouldAssign,
                newAssignee: shouldAssign ? newAssignee : null,
                shouldResolve: !!shouldResolve,
            });
        },
        [onSubmit, shouldAssign, newAssignee, discardDraft]
    );

    const handleKeyboardCancel = useCallback(() => {
        if (!serializers.current?.isPopulated()) {
            handleCancel();
        }
    }, [handleCancel]);

    let submitButtonText = isReply ? "Reply" : "Comment";

    if (!isPopulated && isReply && newAssignee && shouldAssign) {
        submitButtonText = `Assign to ${assignToName}`;
    }

    return (
        <div className={classNames(className, styles.newCommentEditor, visible && styles.visible)}>
            <Editor
                board={board}
                className={styles.editor}
                autoFocus
                content={draft.current?.content.json ?? null}
                placeholderText={() => {
                    if (isResolved) {
                        return "Adding a comment will reopen this thread...";
                    }

                    if (isReply) {
                        return "Reply or @mention someone";
                    }

                    return "Comment or @mention someone";
                }}
                minHeight={80}
                maxHeight={240}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onKeyboardCancel={handleKeyboardCancel}
                onKeyboardSubmit={handleSubmit}
                images
                linkUnfurlTypes={[Enums.LinkUnfurlType.LOOM]}
                mentions
                ticketReferences
                emoji
            />
            <CommentAssignmentControls
                assignToName={assignToName}
                className={styles.commentAssignmentControls}
                checked={shouldAssign}
                onSelectAssignee={userToAssign => {
                    if (userToAssign) {
                        setShouldAssign(true);
                        setNewAssigneeUserId(userToAssign.id);
                    }
                }}
                onToggleShouldAssign={() => {
                    setShouldAssign(prev => !prev);
                }}
                selectableUsers={possibleAssignmentUsers}
            />

            {!isAssigningToCurrentUser && shouldAssign && newAssignee ? (
                <CommentExplanatoryFooter className={styles.explanatoryFooter}>
                    {newAssignee.name} will be notified that they are responsible for following up
                    on and resolving this thread.
                </CommentExplanatoryFooter>
            ) : null}
            {mentionedUserIds.length >= 1 &&
            !(
                mentionedUserIds.length === 1 &&
                newAssignee &&
                mentionedUserIds.includes(newAssignee?.id) &&
                shouldAssign
            ) ? (
                <CommentExplanatoryFooter className={styles.explanatoryFooter}>
                    Mentioned users will be notified about your comment.
                </CommentExplanatoryFooter>
            ) : null}
            <div className={styles.footerActions} ref={footerRef}>
                <BorderButton onClick={handleCancel} content="Cancel" instrumentation={null} />
                <span className={styles.submitBtnWrapper}>
                    <BorderButton
                        data-cy="new-comment-submit-btn"
                        className={classNames([styles.submitBtn, showDropdown && styles.dropdown])}
                        content={submitButtonText}
                        debounceIntervalMs={1000}
                        disabled={!(isPopulated || (isReply && shouldAssign))}
                        instrumentation={null}
                        onClick={() => handleSubmit()}
                        primary
                    />
                    {showDropdown && (
                        <MenuPopover
                            content={
                                <Menu>
                                    <MenuItem
                                        text={`${submitButtonText} and resolve`}
                                        onClick={() => handleSubmit({ shouldResolve: true })}
                                        instrumentation={null}
                                    />
                                </Menu>
                            }
                            minimal
                            fill
                            placement="top-end"
                            targetClassName={styles.submitMenuTarget}
                        >
                            <DropdownButton
                                className={styles.submitMenuBtn}
                                disabled={!isPopulated}
                                instrumentation={null}
                                primary
                            />
                        </MenuPopover>
                    )}
                </span>
            </div>
        </div>
    );
}
