import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const TasklistResolvers: TResolvers = {
    query_root: {
        tasklists: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.tasklists.findAll())
        ),

        tasklists_by_pk: buildResolver(async (parent, args, { api }) =>
            api.tasklists.getById({ id: args.id })
        ),
    },

    tasklists: {
        stage: buildResolver(async (parent, args, { api }) =>
            api.stages.getById({ id: parent.stage_id })
        ),

        tasks: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.tasks.findByTicketId({ ticketId: parent.ticket_id })).filter(
                    task => task.tasklist_id === parent.id
                )
            )
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),
    },
};
