import React from "react";

import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { Icon } from "components/ui/core/Icon";
import { draftsPerTicketState } from "lib/Drafts";

import styles from "./DraftReminder.module.scss";

export type DraftReminderProps = {
    className?: string;
    ticket: { id: string };
};

export function DraftReminder({ className, ticket }: DraftReminderProps) {
    const draftsPerTicket = useRecoilValue(draftsPerTicketState);
    const { [ticket.id]: ticketDrafts } = draftsPerTicket;

    return (
        !!ticketDrafts && (
            <span className={classNames(className, styles.draftReminder)}>
                {ticketDrafts.description && (
                    <span className={styles.draft}>
                        <Icon icon="file-edit" iconSet="lucide" iconSize={14} />
                        <span>You’re editing the description</span>
                    </span>
                )}
                {ticketDrafts.threads && (
                    <span className={styles.draft}>
                        <Icon icon="chatBubble2" iconSet="c9r" iconSize={14} />
                        <span>You’re writing a comment</span>
                    </span>
                )}
            </span>
        )
    );
}
