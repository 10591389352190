import React, { useMemo } from "react";

import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { TicketDueDates } from "c9r-common";
import classNames from "classnames";

import { Avatar } from "components/ui/common/Avatar";
import { UserSelect, UserSelectProps } from "components/ui/common/UserSelect";
import { BorderButton } from "components/ui/core/BorderButton";
import { DatePopover, DatePopoverProps } from "components/ui/core/DatePopover";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { Select, SelectProps } from "components/ui/core/Select";
import { Tooltip } from "components/ui/core/Tooltip";
import { useCurrentUser } from "contexts/UserContext";
import { useSemanticBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { divideAndFlattenGroups } from "lib/Helpers";
import { useMediaQuery } from "lib/Hooks";
import { isKeyboardDelete } from "lib/Keyboard";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { MembersListDisplay_userFragment } from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import styles from "./MetadataPickers.module.scss";

const fragments = {
    MembersListDisplay: {
        user: gql(/* GraphQL */ `
            fragment MembersListDisplay_user on users {
                id
                name

                ...Avatar_user
                ...UserSelect_user
            }
        `),
    },

    OwnerButtonDisplay: {
        user: gql(/* GraphQL */ `
            fragment OwnerButtonDisplay_user on users {
                id
                name

                ...Avatar_user
                ...UserSelect_user
            }
        `),
    },
};

type MetadataDropdownButtonProps = {
    className?: string;
    small?: boolean;
    statusAlert?: boolean;
    statusWarn?: boolean;
    strong?: boolean;
    text: React.ReactNode;
};

export function MetadataDropdownButton({
    className,
    small,
    statusAlert,
    statusWarn,
    strong,
    text,
}: MetadataDropdownButtonProps) {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();

    return (
        <DropdownButton
            className={classNames(
                className,
                styles.metadataButton,
                (isTopicMetadataPickersCondensed || small) && styles.metadataInfoSmall,
                strong && styles.metadataInfoStrong,
                statusWarn && styles.metadataInfoStatusWarn,
                statusAlert && styles.metadataInfoStatusAlert
            )}
            fill
            minimal
            text={text}
            iconSize={12}
            instrumentation={null}
        />
    );
}

export type OwnerButtonDisplayProps = {
    buttonClassName?: string;
    className?: string;
    ownerId?: number;
    selectableUsers: FragmentType<typeof fragments.OwnerButtonDisplay.user>[];
} & Pick<UserSelectProps, "getInstrumentation" | "onSelect" | "targetClassName"> &
    Pick<MetadataDropdownButtonProps, "small">;

export function OwnerButtonDisplay({
    buttonClassName,
    className,
    getInstrumentation,
    onSelect = () => undefined,
    ownerId,
    selectableUsers: _selectableUserFragments,
    small,
    targetClassName,
}: OwnerButtonDisplayProps) {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();
    const selectableUsers = useMemo(
        () =>
            _selectableUserFragments.map(userFragment =>
                getFragmentData(fragments.OwnerButtonDisplay.user, userFragment)
            ),
        [_selectableUserFragments]
    );

    return (
        <UserSelect
            className={className}
            initiallySelectedItemsIDs={[ownerId ?? null].filter(isDefined)}
            getInstrumentation={getInstrumentation}
            onSelect={onSelect}
            targetClassName={targetClassName}
            users={selectableUsers}
            makeFirstOptionCurrentUser
            hideMenuItemIconMode={Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED}
        >
            {({ selectedItems: [selectedItem] }) => (
                <MetadataDropdownButton
                    className={buttonClassName}
                    small={small}
                    strong={!!selectedItem?.id}
                    text={
                        selectedItem?.id ? (
                            <div className={styles.ownerButtonContent}>
                                <Avatar
                                    className={styles.ownerButtonAvatar}
                                    user={selectedItem}
                                    size={isTopicMetadataPickersCondensed || small ? 20 : 26}
                                />
                                <span>{selectedItem.name}</span>
                            </div>
                        ) : isTopicMetadataPickersCondensed ? (
                            <Icon icon="user" iconSet="lucide" iconSize={18} />
                        ) : (
                            "No owner"
                        )
                    }
                />
            )}
        </UserSelect>
    );
}

export type TSizeButtonSizeScheme = { value?: number | string | null; text: string };

export type SizeButtonDisplayProps = {
    buttonClassName?: string;
    className?: string;
    sizeScheme: TSizeButtonSizeScheme[];
    sizeSpec: Pick<TSizeButtonSizeScheme, "value"> | null;
} & Pick<
    SelectProps<TSizeButtonSizeScheme>,
    "getInstrumentation" | "onSelect" | "targetClassName"
> &
    Pick<MetadataDropdownButtonProps, "small">;

export function SizeButtonDisplay({
    buttonClassName,
    getInstrumentation,
    onSelect = () => undefined,
    sizeScheme,
    sizeSpec,
    small,
    targetClassName,
}: SizeButtonDisplayProps) {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();

    return (
        <Select
            placement="bottom-start"
            className={styles.metadataPicker}
            targetClassName={targetClassName}
            initiallySelectedItemsIDs={[sizeSpec?.value ?? null].filter(isDefined)}
            items={sizeScheme}
            idField="value"
            menuItemTextRenderer={item => item.text}
            itemPredicate={(q, item) => item.text.toLowerCase().includes(q.toLowerCase())}
            placeholder="Filter sizes"
            itemListRenderer={({ renderItem, filteredItems, itemsParentRef }) => {
                const items = filteredItems.filter(itm => itm.value);
                const nullValueItem = filteredItems.find(itm => !itm.value);

                return filteredItems.length ? (
                    <Menu ulRef={itemsParentRef}>
                        {divideAndFlattenGroups({
                            itemGroups: [
                                items.map(renderItem).filter(isDefined),
                                [nullValueItem].filter(isDefined).map(renderItem).filter(isDefined),
                            ],
                            divider: () => <MenuDivider />,
                        })}
                    </Menu>
                ) : null;
            }}
            getInstrumentation={getInstrumentation}
            onSelect={onSelect}
            hideMenuItemIconMode={Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED}
        >
            {({ selectedItems: [selectedItem] }) => {
                return (
                    <MetadataDropdownButton
                        className={buttonClassName}
                        small={small}
                        strong={!!selectedItem?.value}
                        text={
                            selectedItem?.value ? (
                                selectedItem.text
                            ) : isTopicMetadataPickersCondensed ? (
                                <Icon icon="bar-chart" iconSet="lucide" iconSize={18} />
                            ) : (
                                "Unsized"
                            )
                        }
                    />
                );
            }}
        </Select>
    );
}

export type DueDateButtonDisplayProps = {
    className?: string;
    buttonClassName?: string;
    dueDate: DatePopoverProps["selectedDate"];
    isUpcomingSoon?: boolean;
    isOverdue?: boolean;
} & Pick<DatePopoverProps, "getInstrumentation" | "onSelect" | "placement"> &
    Pick<MetadataDropdownButtonProps, "small">;

export function DueDateButtonDisplay({
    className,
    buttonClassName,
    dueDate,
    isUpcomingSoon,
    isOverdue,
    getInstrumentation,
    onSelect,
    placement,
    small,
}: DueDateButtonDisplayProps) {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();
    const formattedDueDate = dueDate ? (
        TicketDueDates.format({ dueDate })
    ) : isTopicMetadataPickersCondensed ? (
        <Icon icon="calendar" iconSet="lucide" iconSize={18} />
    ) : (
        "No due date"
    );

    return (
        <DatePopover
            className={className}
            getInstrumentation={getInstrumentation}
            onSelect={onSelect}
            placement={placement}
            selectedDate={dueDate}
        >
            <MetadataDropdownButton
                className={buttonClassName}
                small={small}
                statusWarn={isUpcomingSoon}
                statusAlert={isOverdue}
                strong={!!dueDate}
                text={formattedDueDate}
            />
        </DatePopover>
    );
}

type TStageButtonStage = { id: string; display_name: string; role: string };

export type StageButtonDisplayProps = {
    buttonClassName?: string;
    currentStageId?: string;
    replacementText?: React.ReactNode;
    stages: TStageButtonStage[];
} & Pick<SelectProps<TStageButtonStage>, "getInstrumentation" | "onSelect" | "targetClassName"> &
    Pick<MetadataDropdownButtonProps, "small">;

export function StageButtonDisplay({
    buttonClassName,
    currentStageId,
    getInstrumentation,
    onSelect = () => undefined,
    replacementText,
    small,
    stages,
    targetClassName,
}: StageButtonDisplayProps) {
    if (replacementText) {
        return <span className={styles.stageButtonReplacement}>{replacementText}</span>;
    }

    const currentStage = stages.find(s => s.id === currentStageId);

    return (
        <Select
            placement="bottom-start"
            className={styles.metadataPicker}
            targetClassName={targetClassName}
            initiallySelectedItemsIDs={[currentStageId].filter(isDefined)}
            items={stages}
            menuItemTextRenderer={item => item.display_name}
            itemPredicate={(q, item) => item.display_name.toLowerCase().includes(q.toLowerCase())}
            placeholder="Filter stages"
            getInstrumentation={getInstrumentation}
            onSelect={onSelect}
        >
            {({ selectedItems: [selectedItem] }) => {
                return (
                    <MetadataDropdownButton
                        className={buttonClassName}
                        small={small}
                        // A ticket is always associated with a stage, but it's possible that stage
                        // is deleted and so not available here.
                        strong={!!currentStage}
                        text={selectedItem?.display_name ?? "None"}
                    />
                );
            }}
        </Select>
    );
}

export type MembersListDisplayProps = {
    elementName?: string;
    onAddMember: UserSelectProps["onSelect"];
    onRemoveMember: (user: MembersListDisplay_userFragment) => void;
    selectedMembersIds: number[];
    ticketId?: number;
    ticketOwnerId?: number;
    users: FragmentType<typeof fragments.MembersListDisplay.user>[];
};

export function MembersListDisplay({
    elementName,
    onAddMember,
    onRemoveMember,
    selectedMembersIds = [],
    ticketId,
    ticketOwnerId,
    users: _userFragments,
}: MembersListDisplayProps) {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();
    const supportsHover = useMediaQuery("(hover: hover)");
    const users = useMemo(
        () =>
            _userFragments.map(userFragment =>
                getFragmentData(fragments.MembersListDisplay.user, userFragment)
            ),
        [_userFragments]
    );

    const alphabeticalSort = (
        a: MembersListDisplay_userFragment,
        b: MembersListDisplay_userFragment
    ) => a.name.localeCompare(b.name);
    const currentUser = useCurrentUser();
    const selectableUsers = users
        .filter(u => ![ticketOwnerId, ...selectedMembersIds].includes(u.id))
        .sort(alphabeticalSort);
    const hasSelectedMembers = !!selectedMembersIds.length;
    const selectedMembers = selectedMembersIds
        .map(id => users.find(su => su.id === id))
        .filter(isDefined)
        .sort(alphabeticalSort);

    return (
        <div className={styles.membersList}>
            {hasSelectedMembers ? (
                <div className={styles.membersListAvatars}>
                    {selectedMembers.map(user => (
                        <Popover2
                            content={
                                <div className={styles.memberPopupContent}>
                                    <header>
                                        {user.name}
                                        {currentUser.id === user.id ? " (me)" : null}
                                    </header>
                                    <BorderButton
                                        content="Remove"
                                        fill
                                        small
                                        instrumentation={{
                                            elementName: `${elementName}.members_list.popup.remove`,
                                            eventData: {
                                                ...(ticketId && { ticketId }),
                                                userId: user.id,
                                            },
                                        }}
                                        onClick={() => onRemoveMember(user)}
                                    />
                                </div>
                            }
                            enforceFocus={false}
                            hoverCloseDelay={0}
                            hoverOpenDelay={0}
                            interactionKind={
                                supportsHover
                                    ? Popover2InteractionKind.HOVER
                                    : Popover2InteractionKind.CLICK
                            }
                            key={user.id}
                            modifiers={{
                                arrow: { enabled: true },
                                offset: {
                                    enabled: true,
                                    options: {
                                        offset: [0, 10],
                                    },
                                },
                            }}
                            placement="bottom"
                            popoverClassName={styles.memberPopup}
                        >
                            <div
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                tabIndex={0}
                                onKeyDown={e => {
                                    if (isKeyboardDelete(e)) {
                                        onRemoveMember(user);
                                    }
                                }}
                            >
                                <Avatar
                                    user={user}
                                    size={isTopicMetadataPickersCondensed ? 20 : 26}
                                />
                            </div>
                        </Popover2>
                    ))}
                </div>
            ) : null}
            {
                // Edge case: If every single available user has already been added, don't show
                // the plus button.
                selectableUsers.length ? (
                    <UserSelect
                        disabledPredicate={item => item.id === ticketOwnerId}
                        allowMultipleSelected
                        hideMenuItemIconMode={Enums.HideMenuItemIconMode.ALWAYS}
                        initiallySelectedItemsIDs={selectedMembersIds}
                        getInstrumentation={user => ({
                            elementName: `${elementName}.members_list.menu.add`,
                            eventData: {
                                ...(ticketId && { ticketId }),
                                userId: user.id,
                            },
                        })}
                        onSelect={onAddMember}
                        targetClassName={styles.pickerTarget}
                        users={selectableUsers}
                        makeFirstOptionCurrentUser
                    >
                        <Tooltip
                            className={classNames(
                                hasSelectedMembers && styles.addMemberTooltipMargin
                            )}
                            content="Add additional collaborators"
                            disabled={hasSelectedMembers}
                            placement="bottom"
                            small
                            modifiers={{
                                offset: {
                                    enabled: true,
                                    options: {
                                        offset: [0, 5],
                                    },
                                },
                            }}
                            openOnTargetFocus={false}
                        >
                            <BorderButton
                                className={
                                    hasSelectedMembers
                                        ? styles.addMemberPlusButton
                                        : styles.addMemberButton
                                }
                                content={
                                    hasSelectedMembers ? (
                                        <Icon
                                            icon="plus"
                                            iconSet="lucide"
                                            iconSize={24}
                                            strokeWidth={1}
                                        />
                                    ) : (
                                        <Icon
                                            icon="user-plus"
                                            iconSet="lucide"
                                            iconSize={24}
                                            strokeWidth={1}
                                        />
                                    )
                                }
                                minimal
                                small
                                instrumentation={null}
                            />
                        </Tooltip>
                    </UserSelect>
                ) : null
            }
        </div>
    );
}
