import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { ValueOf } from "c9r-common";
import classNames from "classnames";

import { useSetIsViewReady } from "components/loading/Loading";
import { BorderButton } from "components/ui/core/BorderButton";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Icon } from "components/ui/core/Icon";
import { Select } from "components/ui/core/Select";
import { AbstractButton } from "components/ui/core/abstract/AbstractButton";
import { AbstractTextInput } from "components/ui/core/abstract/AbstractTextInput";
import { useNomenclature } from "lib/Nomenclature";
import { useRedirectToFullPathname, useUrlBuilders } from "lib/Urls";

import { SearchResult } from "./SearchResult";
import styles from "./SearchView.module.scss";
import {
    SearchViewProvider,
    TSearchInOption,
    TSortByOption,
    useSearchView,
} from "./SearchViewContext";

const ChevronDirection = {
    LEFT: "left",
    RIGHT: "right",
};

function Pagination() {
    const {
        maybeSlicePages,
        currentPageNum,
        maxPageNum,
        goToPageNum,
        goForward,
        goBackward,
    } = useSearchView();

    const ChevronButton = ({ side }: { side: ValueOf<typeof ChevronDirection> }) => (
        <AbstractButton
            className={styles.chevronButton}
            disabled={
                (side === ChevronDirection.LEFT && currentPageNum === 1) ||
                (side === ChevronDirection.RIGHT && currentPageNum === maxPageNum)
            }
            onClick={side === ChevronDirection.LEFT ? () => goBackward() : () => goForward()}
            instrumentation={{
                elementName: "search.pagination.chevron_btn",
                eventData: {
                    side,
                },
            }}
        >
            <Icon
                icon={side === ChevronDirection.LEFT ? "chevron-left" : "chevron-right"}
                iconSet="lucide"
                iconSize={16}
            />
        </AbstractButton>
    );

    return (
        <div className={styles.pagination}>
            <ChevronButton side={ChevronDirection.LEFT} />
            {Array.from(
                {
                    length: maxPageNum,
                },
                (_, i) => i + 1
            )
                .slice(...maybeSlicePages())
                .map(pageNum => (
                    <AbstractButton
                        key={pageNum}
                        className={classNames(
                            styles.pageNumButton,
                            currentPageNum === pageNum && styles.active
                        )}
                        onClick={() => goToPageNum({ pageNum })}
                        instrumentation={{
                            elementName: "search.pagination.page_no_btn",
                            eventData: {
                                pageNum,
                            },
                        }}
                    >
                        {pageNum}
                    </AbstractButton>
                ))}
            <ChevronButton side={ChevronDirection.RIGHT} />
        </div>
    );
}

function SearchResultsSearchIn() {
    const { selectedSearchInOptions, handleSearchInChange, searchInOptions } = useSearchView();

    const getText = (selectedItems: TSearchInOption[]) => {
        if (selectedItems.length === searchInOptions.length) {
            return "Everything";
        }

        if (!selectedItems.length) {
            return "Nothing";
        }

        return selectedItems.map(({ name }) => name).join(", ");
    };

    const handleSelect = useCallback(
        (option: TSearchInOption | null, remove?: boolean) => {
            handleSearchInChange(option!, remove);
        },
        [handleSearchInChange]
    );

    return (
        <Select
            allowMultipleSelected
            filterable={false}
            placement="bottom-end"
            initiallySelectedItemsIDs={selectedSearchInOptions.map(({ name }) => name)}
            items={searchInOptions}
            idField="name"
            menuItemTextRenderer={item => item.name}
            onSelect={handleSelect}
            getInstrumentation={option => ({
                elementName: "search.search_results_search_in.menu_item",
                eventData: {
                    name: option.name,
                },
            })}
        >
            {({ selectedItems }) => (
                <div className={styles.searchResultsOptionsDropdown}>
                    <div className={styles.text}>Search in:</div>&nbsp;
                    <DropdownButton
                        className={styles.dropdownBtn}
                        contentClassName={styles.dropdownBtnContent}
                        fill
                        minimal
                        text={getText(selectedItems)}
                        iconSize={12}
                        instrumentation={{
                            elementName: "search.search_results_search_in.dropdown_btn",
                        }}
                    />
                </div>
            )}
        </Select>
    );
}

function SearchResultsSortBy() {
    const { sortByOption, setSortByOption, sortByOptions } = useSearchView();

    const handleSelect = useCallback(
        (option: TSortByOption | null) => {
            setSortByOption(option!);
        },
        [setSortByOption]
    );

    return (
        <Select
            filterable={false}
            placement="bottom-end"
            initiallySelectedItemsIDs={[sortByOption.name]}
            items={sortByOptions}
            idField="name"
            menuItemTextRenderer={item => item.name}
            onSelect={handleSelect}
            getInstrumentation={option => ({
                elementName: "search.search_results_sort_by.menu_item",
                eventData: {
                    name: option.name,
                },
            })}
        >
            {({ selectedItems: [selectedItem] }) => (
                <div className={styles.searchResultsOptionsDropdown}>
                    <div className={styles.text}>Sort by:</div>&nbsp;
                    <DropdownButton
                        className={styles.dropdownBtn}
                        contentClassName={styles.dropdownBtnContent}
                        fill
                        minimal
                        text={selectedItem.name}
                        iconSize={12}
                        instrumentation={{
                            elementName: "search.search_results_sort_by.dropdown_btn",
                        }}
                    />
                </div>
            )}
        </Select>
    );
}

function SearchInputGroup() {
    const { nomenclature } = useNomenclature();
    const { query, handleQueryChange } = useSearchView();
    // Here we initialize the input value with query, but allow the component to then manage its own state
    // to avoid input lag.
    const [value, setValue] = useState(query);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOnChange = (newValue: string) => {
        setValue(newValue);
        handleQueryChange(newValue);
    };

    return (
        <div className={styles.searchInputGroup}>
            <AbstractTextInput
                autoFocus
                className={styles.searchInput}
                inputRef={inputRef}
                placeholder={`Search all ${nomenclature.space.plural.toLowerCase()} and their archives`}
                value={value}
                onChange={e => handleOnChange(e.target.value)}
                onKeyDown={e => {
                    if (e.key === "Escape") {
                        if (value) {
                            handleOnChange("");
                        } else {
                            inputRef?.current?.blur();
                        }
                    }
                }}
            />
            {value && (
                <BorderButton
                    className={styles.clearSearchButton}
                    content={<Icon icon="x" iconSet="lucide" iconSize={18} />}
                    onClick={() => {
                        handleOnChange("");
                        inputRef.current?.focus();
                    }}
                    instrumentation={{
                        elementName: "search.search_input_group.clear_search_btn",
                    }}
                    minimal
                />
            )}
        </div>
    );
}

function SearchViewContent() {
    const searchResultsRef = useRef<HTMLDivElement>(null);
    const setIsViewReady = useSetIsViewReady();
    const {
        areIndicesLoading,
        query,
        searchResultItems,
        sortByOption,
        matchingCommentsByTicketId,
        matchingTasksByTicketId,
        pageSize,
        currentPageNum,
        firstIndexToDisplay,
    } = useSearchView();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    useLayoutEffect(() => {
        searchResultsRef.current?.scroll({
            behavior: "auto",
            top: 0,
        });
    }, [currentPageNum]);

    return (
        <div className={styles.searchView}>
            <div className={styles.searchBar}>
                <SearchInputGroup />
            </div>
            <div className={styles.searchContext}>
                <div className={styles.searchResultsCount}>
                    {!!searchResultItems.length &&
                        query &&
                        !areIndicesLoading &&
                        `${searchResultItems.length} results found`}
                </div>
                <div>
                    <SearchResultsSearchIn />
                    <SearchResultsSortBy />
                </div>
            </div>
            <div className={styles.searchResults} ref={searchResultsRef}>
                {searchResultItems.length && query && !areIndicesLoading ? (
                    searchResultItems
                        .sort((a, b) => sortByOption.fn(a.ticket, b.ticket))
                        .slice(firstIndexToDisplay, currentPageNum * pageSize)
                        .map(({ ticket, matches }) => (
                            <SearchResult
                                key={ticket.id}
                                ticket={ticket}
                                matches={matches}
                                matchingComments={matchingCommentsByTicketId[ticket.id]}
                                matchingTasks={matchingTasksByTicketId[ticket.id]}
                            />
                        ))
                ) : areIndicesLoading ? (
                    <div>Loading...</div>
                ) : (
                    <div>{query && "No results."}</div>
                )}
            </div>
            {searchResultItems.length > pageSize && query && !areIndicesLoading && (
                <div className={styles.footer}>
                    <Pagination />
                </div>
            )}
        </div>
    );
}

export function SearchView() {
    const { buildSearchUrl } = useUrlBuilders();

    useRedirectToFullPathname({ fullPathname: buildSearchUrl().pathname });

    return (
        <SearchViewProvider>
            <SearchViewContent />
        </SearchViewProvider>
    );
}
