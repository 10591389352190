export const defaultSearchOptions = {
    // If the term is a number, it's probably a ticket reference or something the user
    // knows and is searching for exactly. So, we don't do fuzzy matching. Otherwise, if the
    // user types, say 123, the search would also match 133, 143, 153, etc.
    // which aren't useful.
    fuzzy: (term: string) => (Number.isInteger(Number(term)) ? 0 : 0.2),
    prefix: true,
};

export function buildIndexOptions({
    fields,
    storeFields,
}: {
    fields: string[];
    storeFields: string[];
}) {
    return {
        // https://lucaong.github.io/minisearch/classes/_minisearch_.minisearch.html#options-and-defaults
        fields,
        storeFields,
        searchOptions: defaultSearchOptions,
    };
}
