import React from "react";

import classNames from "classnames";
import { format as formatDate } from "date-fns";

import {
    TicketRowContentIcon,
    TicketRowContentMainInfo,
    TicketRowContentReference,
} from "components/shared/ticketList/TicketRowContent";
import { DynamicWidthTableCell } from "components/ui/common/DynamicWidthTable";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Link } from "lib/Routing";
import { useTicketOwnershipInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./TicketTable.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketTable_ticket on tickets {
            id
            ref
            slug
            title

            board {
                id
                display_name
            }

            ...TicketOwnershipInfo_ticket
            ...TicketRowContentMainInfo_ticket
            ...TicketRowContentReference_ticket
        }
    `),
};

export type TicketDateProps = {
    className?: string;
    date?: string | number | Date | null;
};

export function TicketDate({ className, date: _dateLike }: TicketDateProps) {
    if (!_dateLike) {
        return null;
    }

    const date = new Date(_dateLike);
    const formatString =
        new Date(Date.now()).getFullYear() !== date.getFullYear() ? "MMM d, yyyy" : "MMM d";

    return (
        <span className={classNames(className, styles.ticketDate)}>
            {formatDate(date, formatString)}
        </span>
    );
}

export type TicketOwnershipSummaryProps = {
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TicketOwnershipSummary({ ticket: _ticketFragment }: TicketOwnershipSummaryProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { ownershipSummaryText } = useTicketOwnershipInfo({ ticket });

    return (
        <span className={styles.ownershipSummaryText}>
            {ownershipSummaryText && [ownershipSummaryText].filter(Boolean)}
        </span>
    );
}

export type TicketTableHeaderProps = {
    children: React.ReactNode;
    className?: string;
};

export function TicketTableHeader({ children, className }: TicketTableHeaderProps) {
    return <div className={classNames(className, styles.ticketTableHeader)}>{children}</div>;
}

export type TicketTableColumnNameProps = {
    children?: React.ReactNode;
    className?: string;
};

export function TicketTableColumnName({ children, className }: TicketTableColumnNameProps) {
    return (
        <DynamicWidthTableCell className={classNames(className, styles.ticketTableColumnName)}>
            {children}
        </DynamicWidthTableCell>
    );
}

export type TicketRowsProps = {
    children: React.ReactNode;
    className?: string;
};

export function TicketRows({ children, className }: TicketRowsProps) {
    return <ul className={classNames(className, styles.ticketRows)}>{children}</ul>;
}

export type TicketRowProps = {
    children: React.ReactNode;
    className?: string;
    elementName: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TicketRow({
    children,
    className,
    elementName,
    ticket: _ticketFragment,
}: TicketRowProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { recordCallback } = useInstrumentation();
    const { buildTicketUrl } = useUrlBuilders();

    return (
        <li className={classNames(className, styles.ticketRow)} key={ticket.id}>
            <Link
                className={styles.ticketRowLink}
                to={
                    buildTicketUrl({
                        ticketSlug: ticket.slug,
                        vanity: {
                            boardDisplayName: ticket.board.display_name,
                            ticketRef: ticket.ref,
                            ticketTitle: ticket.title,
                        },
                    }).pathname
                }
                onClick={recordCallback({
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName,
                    eventData: { ticketId: ticket.id },
                })}
                role="link"
            >
                {children}
            </Link>
        </li>
    );
}

export type TicketRowContentProps = {
    ticket: FragmentType<typeof fragments.ticket>;
    titleOnly?: boolean;
};

export function TicketRowContent({ ticket: _ticketFragment, titleOnly }: TicketRowContentProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    return (
        <>
            {shouldShowTicketRefs ? (
                <TicketRowContentReference className={styles.ticketRowReference} ticket={ticket} />
            ) : (
                <TicketRowContentIcon className={styles.ticketRowIcon} />
            )}
            <TicketRowContentMainInfo titleOnly={titleOnly} ticket={ticket} />
        </>
    );
}
