import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CommonEnums } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { EnumValue, Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Storage } from "lib/Storage";
import { gql } from "lib/graphql/__generated__";
import { createCtx } from "lib/react/Context";
import {
    useReplicacheGraphQLClient,
    useReplicacheGraphQLLiveQuery,
} from "lib/replicache/graphql/LocalServer";
import { isDefined, isHTMLElement } from "lib/types/guards";

type TSelection = {
    mode: EnumValue<"MultiselectMode">;
    ticketIds: Set<string>;
};

export type TicketSelectionContextValue = {
    // State
    selection: TSelection;
    selectionStateForCurrentUser: {
        isSomeTicketOwned: boolean;
        isSomeTicketUnowned: boolean;
        isSomeTicketWatched: boolean;
        isSomeTicketNotWatched: boolean;
    };

    // State getters
    getSelectedTicketInfo: (params: { ticketId: string }) => { stagePos: number };

    // Mutations
    cancelSelection: () => void;
    clearTickets: () => void;
    deselectTickets: (params: { ticketIds: string[] }) => void;
    selectHovered: () => void;
    selectTickets: (params: { ticketIds: string[] }) => void;
    setSelectionMode: (params: {
        mode: EnumValue<"MultiselectMode">;
        ifMode?: EnumValue<"MultiselectMode">;
    }) => void;
    toggleTickets: (params: { ticketIds: string[] }) => void;

    // Other
    getSelectableElementProps: (
        ticketId: string
    ) => React.ComponentPropsWithoutRef<React.ElementType<any>>;
    maybeHandleTicketSelectionClick: (params: { e: React.MouseEvent; ticketId: string }) => void;
};

const [useTicketSelectionContext, ContextProvider] = createCtx<TicketSelectionContextValue>();

export { useTicketSelectionContext };

const SELECTION_ZERO_STATE = { mode: Enums.MultiselectMode.OFF, ticketIds: new Set<string>() };

function usePersistedSelection({ boardId }: { boardId: string }) {
    const storageKey = `board.${boardId}.selectionState`;

    const [selection, setSelection] = useState<TSelection>(() => {
        const persistedSelection = Storage.Session.getItem(storageKey);

        return persistedSelection
            ? { mode: persistedSelection.mode, ticketIds: new Set(persistedSelection.ticketIds) }
            : SELECTION_ZERO_STATE;
    });

    useEffect(() => {
        Storage.Session.setItem(storageKey, {
            mode: selection.mode,
            ticketIds: Array.from(selection.ticketIds),
        });
    }, [selection, storageKey]);

    return [selection, setSelection] as const;
}

function useRecordSelectionModeChanges({ mode }: { mode: EnumValue<"MultiselectMode"> }) {
    const { recordEvent } = useInstrumentation();

    useEffect(() => {
        if (mode === Enums.MultiselectMode.STARTING) {
            void recordEvent({ eventType: Enums.InstrumentationEvent.MULTISELECT_STARTING });
        }

        if (mode === Enums.MultiselectMode.ACTIVE) {
            void recordEvent({ eventType: Enums.InstrumentationEvent.MULTISELECT_ACTIVE });
        }
    }, [mode, recordEvent]);
}

export type TicketSelectionContextProviderProps = {
    boardId: string;
    children: React.ReactNode;
};

export function TicketSelectionContextProvider({
    boardId,
    children,
}: TicketSelectionContextProviderProps) {
    const currentUser = useCurrentUser();
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const [selection, setSelection] = usePersistedSelection({ boardId });
    const selectedTicketIds = useMemo(() => Array.from(selection.ticketIds), [selection.ticketIds]);

    useRecordSelectionModeChanges({ mode: selection.mode });

    const setSelectionMode = useCallback(
        ({
            mode,
            ifMode,
        }: {
            mode: EnumValue<"MultiselectMode">;
            ifMode?: EnumValue<"MultiselectMode">;
        }) => {
            setSelection(prev => (!ifMode || prev.mode === ifMode ? { ...prev, mode } : prev));
        },
        [setSelection]
    );

    const cancelSelection = useCallback(() => {
        setSelection(SELECTION_ZERO_STATE);
    }, [setSelection]);

    const clearTickets = useCallback(() => {
        setSelection(prev => ({
            ...prev,
            ticketIds: new Set<string>(),
        }));
    }, [setSelection]);

    const selectTickets = useCallback(
        ({ ticketIds }: { ticketIds: string[] }) => {
            setSelection(prev => {
                const nextTicketIds = new Set([...Array.from(prev.ticketIds).concat(ticketIds)]);

                return {
                    mode: nextTicketIds.size ? Enums.MultiselectMode.ACTIVE : prev.mode,
                    ticketIds: nextTicketIds,
                };
            });
        },
        [setSelection]
    );

    const getSelectableElementProps = useCallback(
        (ticketId: string) => ({ "data-selectable-ticket-id": ticketId }),
        []
    );

    const selectHovered = useCallback(() => {
        selectTickets({
            ticketIds: Array.from(document.querySelectorAll("[data-selectable-ticket-id]:hover"))
                .filter(isHTMLElement)
                .map(e => e.dataset.selectableTicketId)
                .filter(isDefined),
        });
    }, [selectTickets]);

    const deselectTickets = useCallback(
        ({ ticketIds }: { ticketIds: string[] }) => {
            setSelection(prev => {
                const removeSet = new Set(ticketIds);
                const nextTicketIds = new Set([
                    ...Array.from(prev.ticketIds).filter(ticketId => !removeSet.has(ticketId)),
                ]);

                return {
                    ...prev,
                    mode: !nextTicketIds.size ? Enums.MultiselectMode.STARTING : prev.mode,
                    ticketIds: nextTicketIds,
                };
            });
        },
        [setSelection]
    );

    const toggleTickets = useCallback(
        ({ ticketIds }: { ticketIds: string[] }) => {
            setSelection(prev => {
                const nextTicketIds = new Set(prev.ticketIds);

                for (const ticketId of ticketIds) {
                    if (prev.ticketIds.has(ticketId)) {
                        nextTicketIds.delete(ticketId);
                    } else {
                        nextTicketIds.add(ticketId);
                    }
                }

                return {
                    mode: nextTicketIds.size
                        ? Enums.MultiselectMode.ACTIVE
                        : Enums.MultiselectMode.STARTING,
                    ticketIds: nextTicketIds,
                };
            });
        },
        [setSelection]
    );

    const selectedTicketsQueryResult = useReplicacheGraphQLLiveQuery({
        query: TicketSelectionContextProvider.queries.component,
        variables: { boardId, selectedTicketIds },
    });

    const selectedTickets = useMemo(() => selectedTicketsQueryResult.data?.tickets || [], [
        selectedTicketsQueryResult.data?.tickets,
    ]);

    const selectedTicketsById = useMemo(
        () => Object.fromEntries(selectedTickets.map(t => [t.id, t])),
        [selectedTickets]
    );

    // It's possible that the tickets that were selected are no longer on the board, such as
    // if the user archived them or moved them to another board. In that case, cancel the
    // selection.
    useEffect(() => {
        (async () => {
            // No need to do anything if no tickets are selected. (It's OK for the selection
            // mode to be active with an empty selection.)
            if (!selectedTicketIds.length) {
                return;
            }

            // No need to do anything if the selected tickets are still visible on the board.
            if (selectedTickets.length) {
                return;
            }

            // Per the above checks, we're now in situation where the user has selected some
            // tickets, but none of them are visible on the board. Let's double check that
            // in fact none of the selected ticket IDs are on the board. We can't simply use
            // selectedTickets here, because it may not be current (i.e., when a ticket
            // selection is toggled, the live query that drives selectedTickets will update
            // eventually but not in the same render pass).
            const result = await replicacheGraphQLClient.readQuery({
                query: TicketSelectionContextProvider.queries.component,
                variables: { boardId, selectedTicketIds },
            });

            if (result?.tickets && !result.tickets.length) {
                cancelSelection();
            }
        })();
    }, [boardId, cancelSelection, replicacheGraphQLClient, selectedTicketIds, selectedTickets]);

    const getSelectedTicketInfo = useCallback(
        ({ ticketId }: { ticketId: string }) => ({
            stagePos: selectedTicketsById[ticketId].stage_pos!,
        }),
        [selectedTicketsById]
    );

    const maybeHandleTicketSelectionClick = useCallback(
        ({ e, ticketId }: { e: React.MouseEvent; ticketId: string }) => {
            if (selection.mode !== Enums.MultiselectMode.OFF) {
                e.preventDefault();

                toggleTickets({ ticketIds: [ticketId] });
            }
        },
        [selection.mode, toggleTickets]
    );

    const selectionStateForCurrentUser = useMemo(
        () => ({
            isSomeTicketOwned: !!selectedTickets.find(ticket => {
                const owner = ticket.owners.find(
                    ({ type }) => type === CommonEnums.TicketOwnerType.OWNER
                );

                return owner && owner.user_id === currentUser.id;
            }),
            isSomeTicketUnowned: !!selectedTickets.find(ticket => {
                const owner = ticket.owners.find(
                    ({ type }) => type === CommonEnums.TicketOwnerType.OWNER
                );

                return !owner || owner.user_id !== currentUser.id;
            }),
            isSomeTicketWatched: !!selectedTickets.find(ticket =>
                ticket.watchers.find(w => w.watcher.id === currentUser.id)
            ),
            isSomeTicketNotWatched: !!selectedTickets.find(
                ticket => !ticket.watchers.find(w => w.watcher.id === currentUser.id)
            ),
        }),
        [currentUser.id, selectedTickets]
    );

    const value = useMemo(
        () => ({
            cancelSelection,
            clearTickets,
            deselectTickets,
            getSelectableElementProps,
            getSelectedTicketInfo,
            maybeHandleTicketSelectionClick,
            selection,
            selectHovered,
            selectTickets,
            selectionStateForCurrentUser,
            setSelectionMode,
            toggleTickets,
        }),
        [
            cancelSelection,
            clearTickets,
            deselectTickets,
            getSelectableElementProps,
            getSelectedTicketInfo,
            maybeHandleTicketSelectionClick,
            selection,
            selectHovered,
            selectTickets,
            selectionStateForCurrentUser,
            setSelectionMode,
            toggleTickets,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

TicketSelectionContextProvider.queries = {
    component: gql(/* GraphQL */ `
        query TicketSelectionContextTickets($boardId: uuid!, $selectedTicketIds: [uuid!]!) {
            tickets(
                where: {
                    id: { _in: $selectedTicketIds }
                    board_id: { _eq: $boardId }
                    archived_at: { _is_null: true }
                    trashed_at: { _is_null: true }
                }
            ) {
                id
                stage_pos

                stage {
                    id
                    role
                }

                owners {
                    type
                    user_id
                }

                watchers {
                    watcher {
                        id
                    }
                }
            }
        }
    `),
};
