import { useEffect } from "react";

import { Config } from "Config";
import { useAuth0 } from "lib/auth/Auth0";

export function LogoutRedirect() {
    const { logout } = useAuth0();

    useEffect(() => {
        switch (Config.auth.provider) {
            case "AUTH0":
                logout();
                break;

            case "CONSTRUCTOR_DEMO":
                window.location.href = Config.urls.website;
                break;

            default:
                throw new Error("Unknown auth provider");
        }
    }, [logout]);

    return null;
}
