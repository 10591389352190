import { useCallback } from "react";

import { TSizeSpec } from "c9r-common";
import { format as formatDate } from "date-fns";

import { useReplicache } from "lib/replicache/Context";
import { TRichTextContentSerializers } from "lib/types/common/richText";

export function useUpdateTicketDueDate() {
    const { replicache } = useReplicache();

    const updateTicketDueDate = useCallback(
        async ({ ticketId, dueDate }: { ticketId: string; dueDate: Date | null }) => {
            const formattedDueDate = dueDate ? formatDate(dueDate, "yyyy-MM-dd") : null;

            await replicache.mutate.updateTicket({ ticketId, dueDate: formattedDueDate });
        },
        [replicache]
    );

    return { updateTicketDueDate };
}

export function useUpdateTicketDescription() {
    const { replicache } = useReplicache();

    const updateTicketDescription = useCallback(
        async ({
            ticketId,
            serializers,
            previousDescriptionDigest,
        }: {
            ticketId: string;
            serializers: TRichTextContentSerializers;
            previousDescriptionDigest: string;
        }) => {
            // Descriptions should be null if it's empty.
            // This means we don't have descriptions that are just lots of empty paragraphs.
            const isPopulated = serializers.isPopulated();
            const descriptionJSON = isPopulated ? serializers.getJSON() : null;

            await replicache.mutate.updateTicket({
                ticketId,
                description: descriptionJSON,
                previousDescriptionDigest,
            });
        },
        [replicache]
    );

    return { updateTicketDescription };
}

export function useUpdateTicketSize() {
    const { replicache } = useReplicache();

    const updateTicketSize = useCallback(
        async ({ ticketId, sizeSpec }: { ticketId: string; sizeSpec: TSizeSpec | null }) => {
            await replicache.mutate.updateTicket({ ticketId, sizeSpec });
        },
        [replicache]
    );

    return { updateTicketSize };
}

export function useUpdateTicketTitle() {
    const { replicache } = useReplicache();

    const updateTicketTitle = useCallback(
        async ({ ticketId, title }: { ticketId: string; title: string }) => {
            await replicache.mutate.updateTicket({ ticketId, title });
        },
        [replicache]
    );

    return { updateTicketTitle };
}
