import BuildEnv from "./buildEnv";

type EnvName = "development" | "test" | "staging" | "production" | "demo";

export const Env = {
    isDeployPreview: BuildEnv.CONTEXT === "deploy-preview",
    commitSha: BuildEnv.COMMIT_REF,
    name: (null as unknown) as EnvName,
};

if (process.env.NODE_ENV === "test") {
    Env.name = "test";
} else if (BuildEnv.NETLIFY_DEV || process.env.NODE_ENV === "development") {
    Env.name = "development";
} else if (BuildEnv.C9R_SITE === "demo") {
    Env.name = "demo";
} else if (BuildEnv.C9R_SITE === "staging") {
    Env.name = "staging";
} else if (BuildEnv.C9R_SITE === "production") {
    Env.name = "production";
} else {
    // eslint-disable-next-line no-console
    console.error("Unknown environment", {
        NETLIFY_DEV: BuildEnv.NETLIFY_DEV,
        NODE_ENV: process.env.NODE_ENV,
        CONTEXT: BuildEnv.CONTEXT,
        C9R_SITE: BuildEnv.C9R_SITE,
    });
    throw new Error("Unknown environment");
}
