import { useEffect, useState } from "react";

import { useGitHubIntegration } from "lib/Integrations";

export function GitHubOAuth2Redirect() {
    const [didAttempt, setDidAttempt] = useState(false);
    const { connectGitHub } = useGitHubIntegration();

    useEffect(() => {
        if (!didAttempt) {
            setDidAttempt(true);
            void connectGitHub();
        }
    }, [didAttempt, connectGitHub]);

    return null;
}
