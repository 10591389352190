import { useEffect, useMemo, useState } from "react";

import * as Comlink from "comlink";
import MiniSearch from "minisearch";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { CommentsSearchIndex_commentFragment } from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import CommentSearchIndexWorker, { SearchIndexWorkerApi } from "./SearchIndex.worker";
import { buildIndexOptions } from "./SearchIndexHelpers";

const fragments = {
    comment: gql(/* GraphQL */ `
        fragment CommentsSearchIndex_comment on comments {
            id
            comment_text
            posted_at
            ticket_id

            author {
                id
                name

                ...Avatar_user
            }
        }
    `),
};

const commentSearchIndexWorker = Comlink.wrap<typeof SearchIndexWorkerApi>(
    new CommentSearchIndexWorker()
);

export const useCommentSearchIndex = ({
    comments: _commentFragments,
    query,
    shouldReturnComments,
}: {
    comments: FragmentType<typeof fragments.comment>[] | null;
    query: string;
    shouldReturnComments?: boolean;
}) => {
    const [searchIndex, setSearchIndex] = useState<MiniSearch | null>(null);
    const [isCommentSearchIndexLoading, setIsCommentSearchIndexLoading] = useState(true);

    const comments = useMemo(
        () =>
            _commentFragments
                ? _commentFragments.map(c => getFragmentData(fragments.comment, c))
                : null,
        [_commentFragments]
    );

    const commentsById = useMemo(
        () =>
            comments ? Object.fromEntries(comments.map(comment => [comment.id, comment])) : null,
        [comments]
    );

    useEffect(() => {
        (async () => {
            setIsCommentSearchIndexLoading(true);

            if (!comments) {
                return;
            }

            const indexOptions = {
                fields: ["comment_text"],
                storeFields: ["id", "ticket_id"],
            };

            const data = comments.map(comment => ({
                id: comment.id,
                comment_text: comment.comment_text,
                ticket_id: comment.ticket_id,
            }));

            const serializedIndex = await commentSearchIndexWorker.buildIndex({
                indexOptions,
                data,
            });

            setSearchIndex(MiniSearch.loadJSON(serializedIndex, buildIndexOptions(indexOptions)));

            setIsCommentSearchIndexLoading(false);
        })();
    }, [comments]);

    const matchingComments = useMemo(() => {
        if (
            !shouldReturnComments ||
            !comments ||
            !commentsById ||
            !searchIndex ||
            isCommentSearchIndexLoading
        ) {
            return [];
        }

        const searchQuery = query.replace(/#(\d*)/gi, "$1");

        if (!searchQuery) {
            return comments;
        }

        return searchIndex
            .search(searchQuery)
            .map(({ id }) => commentsById[id])
            .filter(isDefined)
            .sort((a, b) => new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime());
    }, [
        comments,
        commentsById,
        isCommentSearchIndexLoading,
        query,
        searchIndex,
        shouldReturnComments,
    ]);

    const matchingCommentsByTicketId = useMemo(() => {
        return matchingComments.reduce((acc, c) => {
            acc[c.ticket_id] = acc[c.ticket_id] ?? [];
            acc[c.ticket_id].push(c);
            return acc;
        }, {} as Record<string, CommentsSearchIndex_commentFragment[]>);
    }, [matchingComments]);

    const result = useMemo(
        () => ({
            matchingComments,
            matchingCommentsByTicketId,
            isCommentSearchIndexLoading,
        }),
        [isCommentSearchIndexLoading, matchingComments, matchingCommentsByTicketId]
    );

    return result;
};
