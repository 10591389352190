import React, { useCallback, useMemo, useState } from "react";

import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";

import { AppData } from "AppData";
import { QueryLoader, TQueryResult } from "components/loading/QueryLoader";
import {
    TicketRowContentColumnType,
    TicketRowContentDynamicWidthTableColumnDefinitions,
    TicketRowTableCell,
} from "components/shared/ticketList/TicketRowContent";
import {
    DynamicWidthTable,
    DynamicWidthTableColumnDefinition,
} from "components/ui/common/DynamicWidthTable";
import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { isTicketMatchingFilterTerms, parseFilterExpression } from "lib/Filter";
import { Queries } from "lib/Queries";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    TicketFilter_ticketFragmentDoc,
    TrashQuery,
    Trash_boardFragment,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

import { SideDrawerTicketTableHeader } from "./SideDrawerTicketTableHeader";
import {
    TicketDate,
    TicketOwnershipSummary,
    TicketRow,
    TicketRowContent,
    TicketRows,
    TicketTableColumnName,
    TicketTableHeader,
} from "./TicketTable";
import styles from "./Trash.module.scss";
import { useBoardView } from "../BoardViewContext";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment Trash_board on boards {
            id
            display_name

            tickets(where: { trashed_at: { _is_null: false } }, order_by: { trashed_at: desc }) {
                id
                ref
                title
                trashed_at

                stage {
                    id
                    max_ticket_stage_pos
                }

                ...TicketFilter_ticket
                ...TicketTable_ticket
            }
        }
    `),
};

const DynamicWidthTableColumnDefinitions = [
    {
        columnClassName: styles.trashedDateColumn,
    },
    {
        ...TicketRowContentDynamicWidthTableColumnDefinitions.find(
            d => d.columnType === TicketRowContentColumnType.MAIN_INFO
        ),
        minWidth: undefined,
    } as DynamicWidthTableColumnDefinition,
    {
        columnClassName: styles.ownerColumn,
        maxWidth: 120,
    },
];

type TrashContextValue = {
    board: Trash_boardFragment;
    condensedFormat: boolean;
    filterExpression: string;
    setFilterExpression: React.Dispatch<React.SetStateAction<string>>;
    tickets: Trash_boardFragment["tickets"];
};

const [useTrash, ContextProvider] = createCtx<TrashContextValue>();

type TrashProviderProps = {
    children: React.ReactNode;
    board: FragmentType<typeof fragments.board>;
};

function TrashProvider({ children, board: _boardFragment }: TrashProviderProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const currentUser = useCurrentUser();
    const [filterExpression, setFilterExpression] = useState(
        AppData.trashFilterExpressionByBoardId[board.id] || ""
    );

    const breakpoints = useBreakpoints();
    const condensedFormat = breakpoints.smMax;

    const isTicketMatchingFilterExpression = useCallback(
        ({ ticket }: { ticket: FragmentType<typeof TicketFilter_ticketFragmentDoc> }) =>
            isTicketMatchingFilterTerms({
                ticket,
                userId: currentUser.id,
                filterTerms: parseFilterExpression({ filterExpression }),
            }),
        [currentUser.id, filterExpression]
    );

    const tickets = board.tickets
        .filter(ticket => !!ticket.trashed_at)
        .filter(ticket => isTicketMatchingFilterExpression({ ticket }));

    const value = useMemo(
        () => ({
            board,
            condensedFormat,
            filterExpression,
            setFilterExpression,
            tickets,
        }),
        [board, condensedFormat, filterExpression, tickets]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

function HeaderPlaceholder() {
    const { toggleIsTrashVisible } = useBoardView();

    return (
        <SideDrawerTicketTableHeader
            elementName="board_trash"
            onClose={toggleIsTrashVisible}
            title="Trash"
        />
    );
}

function Header() {
    const { board, toggleIsTrashVisible } = useBoardView();
    const { filterExpression, setFilterExpression, tickets } = useTrash();

    const onFilterExpressionChange = useCallback(
        (newFilterExpression?: string | null) => {
            setFilterExpression(newFilterExpression || "");
            AppData.trashFilterExpressionByBoardId[board.id] = newFilterExpression;
        },
        [board.id, setFilterExpression]
    );

    return (
        <SideDrawerTicketTableHeader
            board={board}
            space="TRASH"
            elementName="board_trash"
            filterExpression={filterExpression}
            onClose={toggleIsTrashVisible}
            onFilterExpressionChange={onFilterExpressionChange}
            ticketCount={tickets.length}
            title="Trash"
        />
    );
}

function MainTableZeroState() {
    return (
        <div className={styles.mainTableZeroState}>
            <div>
                <p>
                    Trash topics you don't expect to refer to again, like duplicates or topics
                    created by mistake. They won't appear in search, but they'll still be accessible
                    here if you ever need them.
                </p>
                <p>You can untrash topics at any time.</p>
            </div>
        </div>
    );
}

function MainTableTicketRows() {
    const { condensedFormat, tickets } = useTrash();

    return (
        <TicketRows>
            {tickets
                .sort(
                    (a, b) => new Date(b.trashed_at!).getTime() - new Date(a.trashed_at!).getTime()
                )
                .map(ticket => (
                    <TicketRow
                        className={styles.ticketRow}
                        key={ticket.id}
                        elementName="board_trash.ticket_row"
                        ticket={ticket}
                    >
                        <TicketRowTableCell columnClassName={styles.trashedDateColumn}>
                            <TicketDate date={ticket.trashed_at} />
                        </TicketRowTableCell>
                        <TicketRowContent ticket={ticket} titleOnly={condensedFormat} />
                        {!condensedFormat ? (
                            <TicketRowTableCell columnClassName={styles.ownerColumn}>
                                <TicketOwnershipSummary ticket={ticket} />
                            </TicketRowTableCell>
                        ) : null}
                    </TicketRow>
                ))}
        </TicketRows>
    );
}

function MainTable() {
    const { board, condensedFormat, filterExpression } = useTrash();
    const isZeroState = !board.tickets.some(ticket => !!ticket.trashed_at);

    return (
        <DynamicWidthTable
            columnDefinitions={DynamicWidthTableColumnDefinitions}
            dependencies={[board, filterExpression]}
        >
            <div className={styles.mainTable}>
                <TicketTableHeader>
                    <TicketTableColumnName className={styles.trashedDateColumn}>
                        Trashed
                    </TicketTableColumnName>
                    <TicketTableColumnName className={styles.titleColumn}>
                        Title
                    </TicketTableColumnName>
                    {!condensedFormat ? (
                        <TicketTableColumnName className={styles.ownerColumn}>
                            Owner
                        </TicketTableColumnName>
                    ) : null}
                </TicketTableHeader>
                {isZeroState ? <MainTableZeroState /> : <MainTableTicketRows />}
            </div>
        </DynamicWidthTable>
    );
}

function Content() {
    return (
        <div className={classNames(styles.content)}>
            <Header />
            <MainTable />
        </div>
    );
}

export function TrashImpl({ queryResult }: { queryResult: TQueryResult<TrashQuery> }) {
    if (queryResult.loading && !queryResult.data) {
        return (
            <div className={styles.loadingWrapper}>
                <HeaderPlaceholder />
                <Spinner className={styles.spinner} size={36} />
            </div>
        );
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    return (
        <TrashProvider board={queryResult.data!.board!}>
            <Content />
        </TrashProvider>
    );
}

export function Trash() {
    const { board } = useBoardView();

    return (
        <QueryLoader query={Trash.queries.component} variables={{ boardId: board.id }}>
            {({ queryResult }) => <TrashImpl queryResult={queryResult} />}
        </QueryLoader>
    );
}

Trash.queries = {
    component: gql(/* GraphQL */ `
        query Trash($boardId: uuid!) {
            board: boards_by_pk(id: $boardId) {
                ...Trash_board
            }
        }
    `),
};

Queries.register({ component: "Trash", gqlMapByName: Trash.queries });
