import React from "react";

import { Link, Redirect } from "react-router-dom";

import { useLiveViewQuery } from "components/loading/Loading";
import { ScrollDivider } from "components/shared/ScrollDivider";
import { Avatar } from "components/ui/common/Avatar";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentIdentity } from "contexts/IdentityContext";
import { useBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { isUserOnboarding } from "lib/Helpers";
import { useDocumentTitle } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { useInternalLogout } from "lib/auth/Logout";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { OrganizationsView_identityFragment } from "lib/graphql/__generated__/graphql";
import { LogoPageLayout } from "views/layouts/logoPage/LogoPageLayout";

import styles from "./OrganizationsView.module.scss";

const fragments = {
    identity: gql(/* GraphQL */ `
        fragment OrganizationsView_identity on identities {
            id

            email_address
            onboarding_completed_at

            users(where: { disabled_at: { _is_null: true } }) {
                id
                name
                full_name
                onboarding_completed_at
                onboarding_type
                org_id

                org {
                    id
                    disabled_at
                    display_name
                    slug

                    users(where: { disabled_at: { _is_null: true } }) {
                        id
                        disabled_at
                        name

                        ...Avatar_user
                    }
                }
            }
        }
    `),
};

type OrganizationBoxProps = {
    org: NonNullable<OrganizationsView_identityFragment["users"][number]["org"]>;
};

function OrganizationBox({ org }: OrganizationBoxProps) {
    const breakpoints = useBreakpoints();
    const userCount = org.users.filter(u => !u.disabled_at).length;

    return (
        <Link
            data-cy="organization-link"
            className={styles.organizationBox}
            to={`/o/${org.slug}`}
            role="link"
        >
            <div className={styles.organizationBoxContent}>
                <span className={styles.organizationName}>{org.display_name}</span>
                <div className={styles.organizationUsers}>
                    <div className={styles.avatars}>
                        {org.users
                            .concat()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .slice(0, 5)
                            .map(user => (
                                <span key={user.id} className={styles.avatarWrapper}>
                                    <Avatar size={breakpoints.mdMin ? 28 : 24} user={user} />
                                </span>
                            ))}
                    </div>
                    <span className={styles.organizationUserCount}>
                        {userCount.toLocaleString()}&nbsp;
                        {userCount === 1 ? "member" : "members"}
                    </span>
                </div>
            </div>
            <Icon
                className={styles.organizationArrow}
                icon="arrow-right"
                iconSet="lucide"
                iconSize={32}
                strokeWidth={1.5}
            />
        </Link>
    );
}

export function OrganizationsView() {
    const { id: identityId } = useCurrentIdentity();
    const { history } = useHistory();
    const { internalLogout } = useInternalLogout();

    useDocumentTitle("Organizations");

    const componentQuery = useLiveViewQuery({
        query: OrganizationsView.queries.component,
        variables: {
            identityId,
        },
        apiRoleType: Enums.ApiRoleType.IDENTITY,
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const identity = getFragmentData(fragments.identity, componentQuery.data?.identity);

    // Special case: A new identity invited to join an existing org will have a single org user
    // that has not completed onboarding. In that case, redirect to setup that specific org.
    if (identity && identity.users.length === 1 && isUserOnboarding(identity.users[0])) {
        return <Redirect to={`/organizations/setup?o=${identity.users[0].org.slug}`} />;
    }

    // Special case: A new identity that just signed up will have no org users. The only
    // thing they can do is create one. So, redirect to create an org.
    if (!identity || !identity.users.length) {
        return <Redirect to="/organizations/setup" />;
    }

    return (
        <LogoPageLayout
            className={styles.organizationsView}
            title="Your Organizations"
            subtitle="Pick an organization to launch"
        >
            <div className={styles.contentWrapper}>
                <ScrollDivider className={styles.scrollDivider} />
                <div className={styles.content}>
                    <div className={styles.organizationBoxes}>
                        {identity.users
                            .concat()
                            .filter(user => !!user.org)
                            .filter(user => !user.org.disabled_at)
                            .sort((a, b) =>
                                (a.org!.display_name ?? "").localeCompare(b.org!.display_name ?? "")
                            )
                            .map(user => (
                                <OrganizationBox key={user.org!.id} org={user.org!} />
                            ))}
                    </div>
                    <div className={styles.createOrganizationBox}>
                        <span>Looking to use Flat for a new team?</span>
                        <BorderButton
                            brandCta
                            className={styles.createOrganizationBoxBtn}
                            content="Create a new organization"
                            large
                            sharperer
                            onClick={() => {
                                history.push("/organizations/new");
                            }}
                            instrumentation={null}
                        />
                    </div>
                    <div className={styles.joinExistingTeam}>
                        <header>Not seeing your organization?</header>
                        <p>
                            Ask your admin to invite you at {identity.email_address},<br />
                            or{" "}
                            <TextButton
                                link
                                instrumentation={null}
                                text="sign in with a different email address"
                                onClick={internalLogout}
                            />
                            .
                        </p>
                    </div>
                </div>
            </div>
        </LogoPageLayout>
    );
}

OrganizationsView.queries = {
    component: gql(/* GraphQL */ `
        query OrganizationsView($identityId: uuid!) {
            identity: identities_by_pk(id: $identityId) {
                ...OrganizationsView_identity
            }
        }
    `),
};

Queries.register({ component: "OrganizationsView", gqlMapByName: OrganizationsView.queries });
