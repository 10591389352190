import React from "react";

import classNames from "classnames";

import styles from "./CardInfoBox.module.scss";

export type CardInfoBoxProps = {
    className?: string;
    children: React.ReactNode;
};

export function CardInfoBox({ className, children }: CardInfoBoxProps) {
    return <div className={classNames(className, styles.cardInfoBox)}>{children}</div>;
}
