import React, { useEffect } from "react";

import { Switch, useRouteMatch } from "react-router-dom";

import { DebugRefreshAccessToken } from "components/debugging/DebugAccessTokens";
import { DebugEdgeFunction } from "components/debugging/DebugEdgeFunctions";
import {
    DebugConsoleError,
    DebugFetchError,
    DebugLogError,
    DebugReactComponentError,
    DebugUncaughtError,
} from "components/debugging/DebugErrors";
import { useSetIsViewReady } from "components/loading/Loading";

import { AppRoute } from "./AppRoute";

export function DebugRoutes() {
    const setIsViewReady = useSetIsViewReady();
    const { path } = useRouteMatch();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    return (
        <Switch>
            {/* Access token debugging */}
            <AppRoute path={`${path}/refresh_access_token`}>
                <DebugRefreshAccessToken />
            </AppRoute>

            {/* Edge function debugging */}
            <AppRoute path={`${path}/edge_function`}>
                <DebugEdgeFunction />
            </AppRoute>

            {/* Error debugging */}
            <AppRoute path={`${path}/console_error`}>
                <DebugConsoleError />
            </AppRoute>
            <AppRoute path={`${path}/fetch_error`}>
                <DebugFetchError />
            </AppRoute>
            <AppRoute path={`${path}/log_error`}>
                <DebugLogError />
            </AppRoute>
            <AppRoute path={`${path}/react_component_error`}>
                <DebugReactComponentError />
            </AppRoute>
            <AppRoute path={`${path}/uncaught_error`}>
                <DebugUncaughtError />
            </AppRoute>
        </Switch>
    );
}
