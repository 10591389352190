import { useCallback } from "react";

import { Log } from "lib/Log";

export function getReplicacheDbName({ userId }: { userId: number }) {
    return `userId:${userId}`;
}

export function getExpectedReplicacheIDbNamePrefix({ userId }: { userId: number }) {
    return `rep:${getReplicacheDbName({ userId })}`;
}

export function useDeleteReplicacheDbs() {
    const deleteReplicacheDbs = useCallback(async ({ userIds }: { userIds: number[] }) => {
        // As of October 2023, indexedDB.databases is not implemented on Firefox.
        const databases = (await window.indexedDB.databases?.()) ?? [];

        for (const userId of userIds) {
            databases
                .filter(db => db.name?.startsWith(getExpectedReplicacheIDbNamePrefix({ userId })))
                .forEach(database => {
                    if (database?.name) {
                        Log.info("Deleting Replicache DB", { idbName: database.name, userId });
                        window.indexedDB.deleteDatabase(database.name);
                    }
                });
        }
    }, []);

    return { deleteReplicacheDbs };
}
