import React, { useState } from "react";

import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { AbstractAnchorButton } from "components/ui/core/abstract/AbstractButton";
import { useFilePicker } from "lib/Hooks";
import { pendingUploadsState } from "lib/Uploads";

import styles from "./Attachments.module.scss";

type ProgressBarProps = {
    className?: string;
    progressFraction: number;
};

function ProgressBar({ className, progressFraction }: ProgressBarProps) {
    return (
        <div
            className={classNames(className, styles.progressBar)}
            style={
                {
                    "--progress-percent": `${progressFraction * 100}%`,
                } as React.CSSProperties
            }
        />
    );
}

type AttachmentProps = {
    href?: string;
    isPending?: boolean;
    onDelete?: () => void;
    progressFraction?: number;
    title: string;
};

function Attachment({ isPending, onDelete, progressFraction, title, href }: AttachmentProps) {
    const [deleteIconHover, setDeleteIconHover] = useState(false);

    return (
        <AbstractAnchorButton
            className={classNames(
                styles.attachment,
                deleteIconHover && styles.danger,
                isPending && styles.pending,
                !href && styles.noHref
            )}
            href={href}
            target="_blank"
            instrumentation={{ elementName: "attachment" }}
            disabled={isPending}
        >
            <div className={styles.attachmentButtonContent}>
                <span>{title}</span>
                {typeof progressFraction === "number" && (
                    <ProgressBar progressFraction={progressFraction} />
                )}
                <Icon
                    className={styles.attachmentDeleteButton}
                    onMouseOver={() => setDeleteIconHover(true)}
                    onMouseOut={() => setDeleteIconHover(false)}
                    icon="x"
                    iconSet="lucide"
                    iconSize={15}
                    onClick={e => {
                        e.preventDefault();
                        onDelete?.();
                    }}
                />
            </div>
        </AbstractAnchorButton>
    );
}

export type TAttachment = { title: string; href: string | null };

export type AttachmentsProps<T extends TAttachment> = {
    attachments: T[];
    className?: string;
    elementName?: string;
    entityId?: number | string | null;
    entityType?: string | null;
    handleAttachmentDelete: (attachment: T) => void;
    handleAttachmentUpload?: (files: File[]) => void;
    showButton?: boolean;
};

export function Attachments<T extends TAttachment>({
    attachments,
    className,
    elementName,
    entityId,
    entityType,
    handleAttachmentDelete,
    handleAttachmentUpload,
    showButton,
}: AttachmentsProps<T>) {
    const pendingUploads = useRecoilValue(pendingUploadsState).filter(
        u => u.entityId === entityId && u.entityType === entityType
    );
    const { openFilePicker } = useFilePicker();
    const elementNamePrefix = elementName ? `${elementName}.` : "";

    return attachments?.length || pendingUploads.length || showButton ? (
        <div className={classNames(className, styles.attachments)}>
            {handleAttachmentUpload && (
                <BorderButton
                    className={styles.uploadMoreButton}
                    content={<Icon iconSet="lucide" icon="paperclip" iconSize={14} />}
                    minimal
                    onClick={() => {
                        openFilePicker({ multiple: true }, handleAttachmentUpload);
                    }}
                    small
                    instrumentation={{
                        elementName: `${elementNamePrefix}attachments.upload_button`,
                    }}
                />
            )}
            <div>
                {attachments.map(attachment => (
                    <Attachment
                        key={attachment.href}
                        href={attachment.href ?? undefined}
                        title={attachment.title}
                        onDelete={() => handleAttachmentDelete(attachment)}
                    />
                ))}
                {pendingUploads.map(upload => (
                    <Attachment
                        key={upload.id}
                        href={undefined}
                        title={upload.title}
                        onDelete={() => upload.cancel()}
                        progressFraction={upload.progressFraction}
                        isPending
                    />
                ))}
            </div>
        </div>
    ) : null;
}
