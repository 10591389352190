import { TLabel } from "c9r-common";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketFilter_ticket on tickets {
            id
            ref
            title

            label_attachments {
                color
                text
            }

            owners {
                ticket_id
                user_id

                owner {
                    id
                    name
                }
            }

            assigned_threads: threads(
                where: { resolved_at: { _is_null: true }, assigned_to_user_id: { _is_null: false } }
            ) {
                id
                assigned_at
                blocker_added_at
                resolved_at

                assignee {
                    id
                    name
                }
            }

            blocked_threads: threads(
                where: { resolved_at: { _is_null: true }, blocker_type: { _is_null: false } }
            ) {
                id
                assigned_at
                blocker_text
                blocker_added_at
                resolved_at

                assignee {
                    id
                    name
                }

                blocker_ticket {
                    id
                    ref
                    title
                }
            }

            child_of_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    ticket: { trashed_at: { _is_null: true } }
                }
            ) {
                id

                ticket {
                    id
                    title
                    ref
                }
            }
        }
    `),

    user: gql(/* GraphQL */ `
        fragment UserFilter_user on users {
            id
            full_name
            name
        }
    `),
};

type FilterTerm = { type: string; value?: string; quoted?: boolean; negate: boolean };

const regex = /(?<modifier>[+\-!]?)(?<cmd>[a-z]*):(?<val>[^"\s]\S*|"[^"]*")|((?<termModifier>[+\-!]?)(?<term>[^"\s]\S*|"[^"]*"))/gi;

function _normalize(value: string) {
    return value.startsWith('"') && value.endsWith('"')
        ? { value: value.slice(1, value.length - 1).toLowerCase(), quoted: true }
        : { value: value.toLowerCase(), quoted: false };
}

export function parseFilterExpression({
    filterExpression,
}: {
    filterExpression: string;
}): FilterTerm[] {
    const matches = Array.from(filterExpression.matchAll(regex));
    const result = [];

    for (const match of matches) {
        if (!match.groups) {
            continue;
        }

        if (match.groups.term) {
            if (!["+", "-", "!"].includes(match.groups.term)) {
                const { value, quoted } = _normalize(match.groups.term);

                result.push({
                    type: "TERM",
                    value,
                    quoted,
                    negate: ["!", "-"].includes(match.groups.termModifier),
                });
            }
        } else {
            const type = match.groups.cmd ? match.groups.cmd.toUpperCase() : "TERM";
            const { value, quoted } = _normalize(match.groups.val);
            const negate = ["!", "-"].includes(match.groups.modifier);

            if (type === "OWNER" && value.toUpperCase() === "ME") {
                result.push({ type: "OWNER_ME", negate });
            } else if (type === "OWNER" && value.toUpperCase() === "NOBODY") {
                result.push({ type: "UNOWNED", negate });
            } else if (type === "USER" && value.toUpperCase() === "ME") {
                result.push({ type: "USER_ME", negate });
            } else {
                result.push({ type, value, quoted, negate });
            }
        }
    }

    return result;
}

export function isTicketMatchingFilterTerms({
    ticket: _ticketFragment,
    userId,
    filterTerms,
}: {
    ticket: FragmentType<typeof fragments.ticket>;
    userId: number;
    filterTerms: FilterTerm[];
}) {
    if (!filterTerms.length) {
        return true;
    }

    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const normalize = (v: string) => _normalize(v).value;

    const { ref } = ticket;
    const title = normalize(ticket.title);
    const titleWords = title.split(" ").filter(isDefined).map(normalize);

    const ownerNames = ticket.owners.map(o => o.owner.name).map(normalize);

    const labels = ticket.label_attachments.map(l => l.text).map(normalize);
    const labelWords = labels
        .flatMap(l => l.split(" "))
        .filter(isDefined)
        .map(normalize);

    const blockerTicketRefs = ticket.blocked_threads
        .map(thread => thread.blocker_ticket?.ref)
        .filter(isDefined);

    const blockerTextsAndTicketTitles = ticket.blocked_threads
        .map(thread => thread.blocker_text ?? thread.blocker_ticket?.title)
        .filter(isDefined)
        .map(normalize);
    const blockerTextAndTicketTitleWords = blockerTextsAndTicketTitles
        .flatMap(t => t.split(" "))
        .filter(isDefined)
        // Include "blocked" because that appears on the card, and "blocker" because it's a
        // reasonable variation.
        .concat(blockerTextsAndTicketTitles.length ? ["blocked", "blocker"] : [])
        .map(normalize);

    const threadAssigneeNames = ticket.assigned_threads
        .filter(th => !th.resolved_at)
        .map(th => th.assignee?.name)
        .filter(isDefined)
        .map(normalize);

    const parentTicketRefs = ticket.child_of_tasks.map(t => t.ticket).map(p => p.ref);

    const parentTicketTitles = ticket.child_of_tasks.map(t => t.ticket.title).map(normalize);
    const parentTicketWords = parentTicketTitles
        .flatMap(t => t.split(" "))
        .filter(isDefined)
        .map(normalize);

    const associatedTicketRefs = blockerTicketRefs.concat(parentTicketRefs);

    for (const { type, value = "", quoted = false, negate = false } of filterTerms) {
        let isFound = false;

        switch (type) {
            case "TERM":
                isFound =
                    (value.match(/^#(\d+)$/) && `#${ref}`.startsWith(value)) ||
                    ref.startsWith(value) ||
                    title.startsWith(value) ||
                    titleWords.some(v => v.startsWith(value)) ||
                    ownerNames.some(v => v.startsWith(value)) ||
                    labelWords.some(v => v.startsWith(value)) ||
                    blockerTextAndTicketTitleWords.some(v => v.startsWith(value)) ||
                    threadAssigneeNames.some(v => v.startsWith(value)) ||
                    parentTicketWords.some(v => v.startsWith(value)) ||
                    associatedTicketRefs.some(
                        v => value.match(/^#(\d+)$/) && `#${v}`.startsWith(value)
                    ) ||
                    associatedTicketRefs.some(v => v.startsWith(value)) ||
                    (quoted &&
                        (labels.some(l => l.includes(value)) ||
                            title.includes(value) ||
                            parentTicketTitles.some(t => t.includes(value)) ||
                            blockerTextsAndTicketTitles.some(t => t.includes(value))));
                break;

            case "LABEL":
                isFound =
                    labelWords.some(v => v.startsWith(value)) ||
                    (quoted && labels.some(l => l.includes(value)));
                break;

            case "OWNER":
                isFound = ownerNames.some(o => o === value);
                break;

            case "OWNER_ME":
                isFound = ticket.owners.some(o => o.owner.id === userId);
                break;

            case "USER":
                isFound =
                    ownerNames.some(n => n === value) || threadAssigneeNames.some(n => n === value);
                break;

            case "USER_ME":
                isFound =
                    ticket.owners.some(o => o.owner.id === userId) ||
                    ticket.assigned_threads.some(th => th.assignee?.id === userId);
                break;

            case "UNOWNED":
                isFound = !ticket.owners.length;
                break;

            default:
                break;
        }

        if (isFound === negate) {
            return false;
        }
    }

    return true;
}

export function isLabelMatchingFilterTerms({
    label,
    filterTerms,
}: {
    label: TLabel;
    filterTerms: FilterTerm[];
}) {
    if (!filterTerms.length) {
        return true;
    }

    const normalize = (v: string) => _normalize(v).value;

    const text = normalize(label.text);
    const textWords = text.split(" ").filter(isDefined).map(normalize);

    for (const { type, value = "", quoted = false, negate = false } of filterTerms) {
        let isFound = false;

        switch (type) {
            case "TERM":
                isFound =
                    textWords.some(v => v.startsWith(value)) || (quoted && text.includes(value));
                break;

            default:
                break;
        }

        if (isFound === negate) {
            return false;
        }
    }

    return true;
}

export function isUserMatchingFilterTerms({
    user: _userFragment,
    filterTerms,
}: {
    user: FragmentType<typeof fragments.user>;
    filterTerms: FilterTerm[];
}) {
    if (!filterTerms.length) {
        return true;
    }

    const normalize = (v: string) => _normalize(v).value;

    const name = normalize(getFragmentData(fragments.user, _userFragment).name);

    for (const { type, value = "", negate = false } of filterTerms) {
        let isFound = false;

        switch (type) {
            case "TERM":
                isFound = name.startsWith(value);
                break;

            default:
                break;
        }

        if (isFound === negate) {
            return false;
        }
    }

    return true;
}
