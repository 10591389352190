import { useCallback } from "react";

import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useShareFeedback() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation ShareFeedback($text: String!) {
                insert_user_submitted_product_suggestions_one(object: { text: $text }) {
                    id
                }
            }
        `)
    );

    const shareFeedback = useCallback(
        async ({ text }: { text: string }, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    text,
                },
            });
        },
        [mutation]
    );

    return { shareFeedback };
}
