import React from "react";

import * as Sentry from "@sentry/react";

import { Log } from "lib/Log";
import { ErrorView } from "views/error/ErrorView";
import { OfflineView } from "views/error/OfflineView";

export type ErrorBoundaryProps = {
    children: React.ReactNode;
};

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
    return (
        <Sentry.ErrorBoundary
            onError={(error, componentStack) => {
                Log.error("React component error", { error, componentStack });
            }}
            fallback={({ error, resetError }) => {
                // If there's an error running an Apollo Client query, the view throws
                // the error. According to the docs, Apollo Client adds a networkError prop
                // to errors caused by a network issue.
                // @ts-expect-error
                if (error.networkError && !navigator.onLine) {
                    return <OfflineView resetError={resetError} />;
                }

                return <ErrorView />;
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}
