import React, { useCallback, useMemo } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog } from "components/ui/core/Dialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog } from "lib/Hooks";

import styles from "./JiraImport.module.scss";

type JiraImportDialogProps = { isOpen: boolean; onClose?: () => void };

function JiraImportDialog({ isOpen, onClose }: JiraImportDialogProps) {
    return (
        <Dialog
            className={styles.dialog}
            title="Import from Jira"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <p>
                    If you're interested in importing from Jira, <SupportMailto text="contact us" />
                    . We'd be happy to work with you – for free – to get your data into Flat.
                </p>
            </Dialog.Body>
            <Dialog.Footer />
        </Dialog>
    );
}

const useJiraImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();

    const jiraImportDialog = useMemo(
        () => <JiraImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateJiraImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        dialog.open();
    }, [dialog, gateFeature]);

    return { initiateJiraImport, jiraImportDialog };
};

export { useJiraImport };
