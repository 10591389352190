import React, { useState } from "react";

import jsonwebtoken from "jsonwebtoken";

import { useInterval } from "lib/Hooks";
import { getCurrentAccessToken } from "lib/auth/AccessToken";
import { useRefreshAuth0AccessToken } from "lib/auth/Auth0AccessTokenManager";

export function DebugRefreshAccessToken() {
    const [lastRefreshedAt, setLastRefreshedAt] = useState<string>(
        new Date(Date.now()).toISOString()
    );
    const { refreshAccessToken } = useRefreshAuth0AccessToken();

    useInterval(async () => {
        await refreshAccessToken({ ignoreCache: true });
        setLastRefreshedAt(new Date(Date.now()).toISOString());
    }, 5000);

    const accessToken = getCurrentAccessToken();

    return (
        <div>
            <p>Last refreshed at: {lastRefreshedAt}</p>
            <p>
                Access token:{" "}
                {accessToken ? (
                    <pre>{JSON.stringify(jsonwebtoken.decode(accessToken), null, 2)}</pre>
                ) : (
                    "none"
                )}
            </p>
        </div>
    );
}
