import React, { useCallback, useState } from "react";

import { CommonEnums, isTicketBlocker } from "c9r-common";
import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { generateFakeStringId, isFakeId } from "lib/GraphQL";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { useEditBlocker } from "lib/mutations";

import styles from "./BlockerEditor.module.scss";
import { BlockerInput, TDisplayableBlocker, TDisplayableTicketBlocker } from "./BlockerInput";

const fragments = {
    thread: gql(/* GraphQL */ `
        fragment BlockerEditor_thread on threads {
            id
            blocker_text
            blocker_type

            blocker_ticket {
                id
                ref
                title
            }

            ticket {
                id

                board {
                    id

                    ...BlockerInput_board
                }
            }
        }
    `),
};

export type BlockerEditorProps = {
    className?: string;
    excludedTicketIds: string[];
    onCancel?: () => void;
    onSubmit?: () => void;
    thread: FragmentType<typeof fragments.thread>;
};

export function BlockerEditor({
    className,
    excludedTicketIds,
    onCancel,
    onSubmit,
    thread: _threadFragment,
}: BlockerEditorProps) {
    const thread = getFragmentData(fragments.thread, _threadFragment);

    const { editBlocker } = useEditBlocker();
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    const [blocker, setBlocker] = useState<TDisplayableBlocker | null>(
        thread.blocker_type
            ? thread.blocker_type === CommonEnums.BlockerType.TEXT
                ? { type: CommonEnums.BlockerType.TEXT, value: { text: thread.blocker_text! } }
                : {
                      type: CommonEnums.BlockerType.TICKET,
                      value: {
                          ticket: thread.blocker_ticket ?? {
                              id: generateFakeStringId(),
                              title: buildUnauthorizedDisplayName({ abstractName: "workItem" }),
                              ref: null,
                          },
                      },
                  }
            : null
    );
    const isSubmitDisabled =
        !blocker || (isTicketBlocker(blocker) && isFakeId(blocker.value.ticket.id));
    const [inputValue, setInputValue] = useState(thread.blocker_text ?? "");

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const isUserOpeningTicketSelector = !inputValue && e.target.value === "#";

            if (isUserOpeningTicketSelector) {
                return;
            }

            setInputValue(e.target.value);

            setBlocker(
                e.target.value
                    ? { type: CommonEnums.BlockerType.TEXT, value: { text: e.target.value } }
                    : null
            );
        },
        [inputValue]
    );

    const handleSelectTicket = useCallback(
        ({ ticket }: { ticket: TDisplayableTicketBlocker["value"]["ticket"] }) => {
            setBlocker({
                type: CommonEnums.BlockerType.TICKET,
                value: { ticket },
            });
        },
        []
    );

    const handleClearTicket = useCallback(() => setBlocker(null), []);

    const handleSubmit = useCallback(async () => {
        if (isSubmitDisabled) {
            return;
        }

        await editBlocker({ threadId: thread.id, blocker });
        onSubmit?.();
    }, [blocker, editBlocker, isSubmitDisabled, onSubmit, thread.id]);

    const handleCancel = useCallback(() => {
        onCancel?.();
    }, [onCancel]);

    const handleKeyboardCancel = useCallback(() => {
        if (inputValue) {
            setInputValue("");

            return;
        }

        handleCancel();
    }, [handleCancel, inputValue]);

    if (!thread.blocker_type) {
        return null;
    }

    return (
        <div className={classNames(className, styles.blockerEditor)}>
            <BlockerInput
                blocker={blocker}
                board={thread.ticket.board}
                excludedTicketIds={excludedTicketIds}
                onClearTicket={handleClearTicket}
                onSelectTicket={handleSelectTicket}
                textInputBoxProps={{
                    "data-cy": "new-blocker-input",
                    onChange: handleChange,
                    onKeyboardCancel: handleKeyboardCancel,
                    onKeyboardSubmit: handleSubmit,
                }}
                value={inputValue}
            />

            <div className={styles.footerActions}>
                <BorderButton
                    onClick={() => onCancel?.()}
                    content="Cancel"
                    instrumentation={null}
                />
                <BorderButton
                    content="Update blocker"
                    debounceIntervalMs={1000}
                    disabled={isSubmitDisabled}
                    onClick={handleSubmit}
                    instrumentation={null}
                    primary
                />
            </div>
        </div>
    );
}
