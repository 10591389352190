import React from "react";

import classNames from "classnames";

import { AbstractButton, AbstractButtonProps } from "components/ui/core/abstract/AbstractButton";

import styles from "./TextButton.module.scss";

export type TextButtonProps = {
    link?: boolean;
    text: React.ReactNode;
} & Omit<AbstractButtonProps, "children">;

export function TextButton({ link, text, ...abstractButtonProps }: TextButtonProps) {
    return (
        <AbstractButton
            {...abstractButtonProps}
            className={classNames(
                abstractButtonProps.className,
                styles.button,
                abstractButtonProps.disabled && styles.disabled,
                link && styles.link
            )}
        >
            {text}
        </AbstractButton>
    );
}
