import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import { Draggable, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";

import { BorderButton, BorderButtonProps } from "components/ui/core/BorderButton";
import { useCurrentUser } from "contexts/UserContext";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { Link, LocationState } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";

import styles from "./TicketList.module.scss";

const fragments = {
    TicketListRow: {
        ticket: gql(/* GraphQL */ `
            fragment TicketListRow_ticket on tickets {
                id
                ref
                slug
                title

                board {
                    id
                    display_name
                }
            }
        `),

        task: gql(/* GraphQL */ `
            fragment TicketListRow_task on tasks {
                id
            }
        `),
    },
};

export type TicketListHeaderProps = {
    className?: string;
    instrumentation: BorderButtonProps["instrumentation"];
    isCollapsed?: boolean;
    leftElements?: React.ReactNode;
    rightElements?: React.ReactNode;
    title: React.ReactNode;
    toggleIsCollapsed?: () => void;
};

export function TicketListHeader({
    className,
    instrumentation,
    isCollapsed,
    leftElements,
    rightElements,
    title,
    toggleIsCollapsed,
}: TicketListHeaderProps) {
    return (
        <header
            className={classNames(
                className,
                styles.ticketListHeader,
                isCollapsed && styles.isCollapsed
            )}
        >
            <BorderButton
                className={styles.ticketListHeaderCollapseBtn}
                contentClassName={styles.ticketListHeaderCollapseBtnContentWrapper}
                leftIconProps={{
                    icon: isCollapsed ? "chevron-right" : "chevron-down",
                    iconSet: "lucide",
                }}
                content={
                    <div className={styles.ticketListHeaderCollapseBtnContent}>
                        <div className={styles.ticketListHeaderTitleAndLeftElements}>
                            <div className={styles.ticketListHeaderTitle}>{title}</div>
                            {leftElements}
                        </div>
                        <div>{rightElements}</div>
                    </div>
                }
                onClick={toggleIsCollapsed}
                instrumentation={instrumentation}
                minimal
                small
                useHoverEffect={false}
            />
        </header>
    );
}

export type TicketListRowProps = {
    className?: string;
    index: number;
    isDraggable?: boolean;
    isHidden?: boolean;
    isMuted?: boolean;
    locationState?: LocationState;
    onClick?: (e: React.MouseEvent) => void;
    task?: FragmentType<typeof fragments.TicketListRow.task>;
    ticket: FragmentType<typeof fragments.TicketListRow.ticket>;

    /** An instance of the TicketRowLayout component. */
    ticketRowLayout:
        | React.ReactNode
        | (({ snapshot }: { snapshot: DraggableStateSnapshot }) => React.ReactNode);
} & React.ComponentPropsWithoutRef<"li">;

export function TicketListRow({
    className,
    index,
    isDraggable = true,
    isHidden,
    isMuted,
    locationState,
    onClick,
    task: _taskFragment,
    ticket: _ticketFragment,
    ticketRowLayout,
    ...htmlLiProps
}: TicketListRowProps) {
    const ticket = getFragmentData(fragments.TicketListRow.ticket, _ticketFragment);
    const task = getFragmentData(fragments.TicketListRow.task, _taskFragment);
    const currentUser = useCurrentUser();
    const draggableId = task
        ? dragAndDropEntity.getDndId(Enums.DndEntityTypes.TASK, task.id)
        : dragAndDropEntity.getDndId(Enums.DndEntityTypes.TICKET, ticket.id);
    const { recordCallback } = useInstrumentation();
    const prefetchQuery = usePrefetchQuery();
    const { buildTicketUrl } = useUrlBuilders();

    const prefetchTicketDetails = () => {
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref: ticket.ref,
            },
        });
    };

    return (
        <Draggable draggableId={draggableId} index={index} isDragDisabled={!isDraggable}>
            {(provided, snapshot) => (
                <li
                    {...htmlLiProps}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(
                        className,
                        styles.ticketListRow,
                        snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging,
                        snapshot.isDropAnimating && styles.isDropping,
                        isHidden && styles.isHidden
                    )}
                    ref={provided.innerRef}
                    // provided.dragHandleProps includes setting tabIndex, but we don't need
                    // that, because we know that the entire row component is a link, which
                    // itself is tabbable. Setting tabIndex to -1 here does not interfere with
                    // being able to drag/drop the card via keyboard.
                    tabIndex={-1}
                >
                    <Link
                        className={classNames(styles.ticketListRowLink, isMuted && styles.isMuted)}
                        onFocus={() => prefetchTicketDetails()}
                        onMouseOver={() => prefetchTicketDetails()}
                        to={{
                            pathname: buildTicketUrl({
                                ticketSlug: ticket.slug,
                                vanity: {
                                    boardDisplayName: ticket.board.display_name,
                                    ticketRef: ticket.ref,
                                    ticketTitle: ticket.title,
                                },
                            }).pathname,
                            state: locationState,
                        }}
                        onClick={e => {
                            onClick?.(e);

                            recordCallback({
                                eventType: Enums.InstrumentationEvent.CLICK,
                                elementName: "ticket_list_row",
                                eventData: { ticketId: ticket.id },
                            })(e);
                        }}
                        role="link"
                    >
                        {typeof ticketRowLayout === "function"
                            ? ticketRowLayout({ snapshot })
                            : ticketRowLayout}
                    </Link>
                </li>
            )}
        </Draggable>
    );
}

export type TicketListProps = {
    bottomElement?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    isCollapsed?: boolean;
    isHorizontallyScrollable?: boolean;
    listId: string | number;
    sectionHeader?: React.ReactNode;
    shouldScroll?: (litsId: string | number) => boolean;
};

export function TicketList({
    bottomElement,
    children,
    className,
    isCollapsed,
    isHorizontallyScrollable,
    listId,
    sectionHeader,
    shouldScroll,
}: TicketListProps) {
    const ticketRowsRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        if (shouldScroll?.(listId)) {
            setImmediate(() => {
                ticketRowsRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            });
        }
    }, [shouldScroll, listId]);

    return (
        <Droppable
            droppableId={dragAndDropEntity.getDndId(
                Enums.DndEntityTypes.TICKET_LIST_SECTION,
                listId
            )}
            type={Enums.DndEntityTypes.TICKET_LIST_SECTION}
            ignoreContainerClipping
        >
            {(provided, snapshot) => (
                <div
                    className={classNames(
                        className,
                        styles.ticketList,
                        isCollapsed && styles.isCollapsed,
                        snapshot.isDraggingOver && styles.isDraggingOver
                    )}
                >
                    {sectionHeader}
                    <ul
                        className={classNames(
                            styles.ticketListRows,
                            isCollapsed && styles.isCollapsed,
                            isHorizontallyScrollable && styles.isHorizontallyScrollable
                        )}
                        ref={el => {
                            ticketRowsRef.current = el;
                            provided.innerRef(el);
                        }}
                        {...provided.droppableProps}
                    >
                        {isCollapsed ? null : children}
                        {provided.placeholder}
                    </ul>
                    {bottomElement}
                </div>
            )}
        </Droppable>
    );
}
