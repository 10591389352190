import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const MergeRequestResolvers: TResolvers = {
    query_root: {
        merge_requests: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.mergeRequests.findAll()
            )
        ),

        merge_requests_by_pk: buildResolver(async (parent, args, { api }) =>
            api.mergeRequests.getById({ id: args.id })
        ),
    },

    merge_requests: {
        tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => {
                const tickets = (await api.tickets.findAll()).filter(ticket =>
                    ticket.merge_requests.some(tmr => tmr.merge_request_id === parent.id)
                );

                return tickets.map(
                    ticket =>
                        ({
                            __typename: "tickets_merge_requests",
                            merge_request_id: parent.id,
                            ticket_id: ticket.id,
                            merge_request: parent,
                            ticket,
                        } as const)
                );
            })
        ),
    },
};
