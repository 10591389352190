import React from "react";

import classNames from "classnames";

import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./Ref.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment Ref_ticket on tickets {
            id
            ref
        }
    `),
};

export type RefProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function Ref({ className, ticket: _ticketFragment }: RefProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <span key="id" className={classNames(className, styles.ref)}>
            {ticket.ref}
        </span>
    );
}
