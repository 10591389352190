import React from "react";

import classNames from "classnames";

import { ExternalCssClasses } from "lib/Constants";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./Title.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment Title_ticket on tickets {
            id
            title
        }
    `),
};

export type TitleProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function Title({ className, ticket: _ticketFragment }: TitleProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <div className={classNames(className, styles.titleWrapper)}>
            <span className={ExternalCssClasses.CARD_TITLE}>{ticket.title}</span>
        </div>
    );
}
