import React, { useEffect, useRef } from "react";

import classNames from "classnames";

import { useResettingState } from "lib/Hooks";

import styles from "./EditableText.module.scss";

export type EditableTextProps = {
    autoFocus?: boolean;
    cancelIfEmpty?: boolean;
    className?: string;
    contentEditable?: boolean;
    disabled?: boolean;
    elementRef?: React.RefObject<HTMLDivElement>;
    onChange?: (value: string | null) => void;
    onConfirm?: ({ value, didBlur }: { value: string | null; didBlur: boolean }) => void;
    onKeyDown?: (
        e: React.KeyboardEvent<HTMLDivElement>,
        { value }: { value: string | null }
    ) => void;
    placeholder?: string;
    value?: string;
} & Omit<React.ComponentPropsWithoutRef<"div">, "onKeyDown">;

export function EditableText({
    autoFocus,
    cancelIfEmpty,
    className,
    contentEditable = true,
    disabled = false,
    elementRef,
    onChange,
    onConfirm,
    onKeyDown,
    placeholder,
    value,
    ...htmlDivProps
}: EditableTextProps) {
    const internalRef = useRef<HTMLDivElement>(null);
    const lastConfirmedValue = useRef<string | null>(null);
    const ref = elementRef || internalRef;
    const [moveCaretToEnd, setMoveCaretToEnd] = useResettingState(true, 350);

    const handleCancel = () => {
        if (!ref.current) {
            return;
        }

        ref.current.textContent = value ?? null;
        ref.current.blur();
    };

    const handleConfirm = ({ didBlur }: { didBlur: boolean }) => {
        if (!ref.current) {
            return;
        }

        const { textContent } = ref.current;

        if (textContent === lastConfirmedValue.current) {
            return;
        }

        if (cancelIfEmpty && !textContent?.trim()) {
            handleCancel();
            return;
        }

        // It's important to save the last confirmed value before onConfirm is called.
        // Otherwise, the onConfirm handler could do something, like trigger a blur,
        // which would (synchronously) cause handleConfirm to fire again, causing
        // onConfirm to be called again with the same value.
        lastConfirmedValue.current = ref.current.textContent;
        onConfirm?.({ value: ref.current.textContent, didBlur });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        if (e.key === "Escape") {
            handleCancel();
            return;
        }

        if (e.key === "Enter") {
            e.preventDefault();
            handleConfirm({ didBlur: false });
            ref.current.blur();
        }

        onKeyDown?.(e, { value: ref.current.textContent });
    };

    useEffect(() => {
        if (autoFocus) {
            ref.current?.focus();
        }
    }, [autoFocus, ref]);

    return (
        <div
            {...htmlDivProps}
            ref={ref}
            onInput={e => onChange?.(e.currentTarget.textContent)}
            className={classNames(className, styles.editableText, disabled && styles.disabled)}
            onKeyDown={handleKeyDown}
            contentEditable={contentEditable && !disabled}
            tabIndex={contentEditable && !disabled ? 0 : -1}
            onMouseDown={() => {
                setMoveCaretToEnd(false);
            }}
            // Move caret to the end if we've tabbed into the input.
            onFocus={() => {
                lastConfirmedValue.current = null;

                if (!ref.current) {
                    return;
                }

                if (
                    moveCaretToEnd &&
                    ref.current.childNodes[0] &&
                    ref.current.childNodes[0].textContent
                ) {
                    const range = document.createRange();
                    const selection = window.getSelection();

                    range.setStart(
                        ref.current.childNodes[0],
                        ref.current.childNodes[0].textContent.length
                    );
                    range.collapse(true);

                    if (selection) {
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }
            }}
            // Ensure that any rich text pasted is transformed to plaintext.
            // An alternative would be to set the content-editable attribute to plaintext-only
            // (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/contenteditable).
            // However, as of October 2023, this wasn't pursued because:
            //   - It's not supported by Firefox
            //   - It caused a surprising regression in Chrome (and possibly other browsers) where
            //     typing a key that we have a hotkey shortcut assigned to triggered the shortcut
            //     instead of inserting the character.
            onPasteCapture={event => {
                // document.execCommand has been marked deprecated for a while, but major browsers
                // still support it and probably will for a long time, particularly for
                // "insertText".
                if (document.execCommand) {
                    document.execCommand(
                        "insertText",
                        false,
                        event.clipboardData.getData("text/plain")
                    );
                    event.preventDefault();
                }
            }}
            onBlur={() => {
                handleConfirm({ didBlur: true });
            }}
            data-text={placeholder ?? "Type text..."}
            // https://stackoverflow.com/questions/49639144/why-does-react-warn-against-an-contenteditable-component-having-children-managed
            suppressContentEditableWarning
        >
            {value}
        </div>
    );
}
