import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import {
    AbstractCheckbox,
    AbstractCheckboxProps,
} from "components/ui/core/abstract/AbstractCheckbox";

import styles from "./Checkbox.module.scss";

export type CheckboxProps = {
    inline?: boolean;
    label?: React.ReactNode;
    labelClassName?: string;
} & Omit<AbstractCheckboxProps, "children">;

export function Checkbox({
    inline,
    label,
    labelClassName,

    ...abstractCheckboxProps
}: CheckboxProps) {
    return (
        <AbstractCheckbox
            {...abstractCheckboxProps}
            className={classNames(
                abstractCheckboxProps.className,
                styles.checkbox,
                abstractCheckboxProps.disabled && styles.disabled,
                inline && styles.inline,
                !label && styles.noLabel
            )}
        >
            <Icon className={styles.icon} icon="check" iconSet="lucide" iconSize={14} />
            {label ? (
                <span className={classNames(labelClassName, styles.labelText)}>{label}</span>
            ) : null}
        </AbstractCheckbox>
    );
}
