import { useCallback } from "react";

import { Enums } from "lib/Enums";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

/**
 * Trigger an email verification sync, which pulls the latest email verification status
 * from Auth0 (our primary source) and updates our DB if needed.
 */
export function useSyncEmailVerification() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation SyncEmailVerification {
                sync_email_verification {
                    ok

                    identity {
                        id
                        is_email_address_verified
                    }
                }
            }
        `)
    );

    const syncEmailVerification = useCallback(
        async (mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.IDENTITY,
                },
            });
        },
        [mutation]
    );

    return { syncEmailVerification };
}
