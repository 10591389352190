import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

/**
 * Adds/removes members to a ticket's list of memebers. If a user to be added as a member is
 * already the ticket owner, they become a member instead.
 */
export function useChangeTicketMembers() {
    const { replicache } = useReplicache();

    const changeTicketMembers = useCallback(
        async ({
            ticketId,
            userIdsToAdd = [],
            userIdsToRemove = [],
        }: {
            ticketId: string;
            userIdsToAdd?: number[];
            userIdsToRemove?: number[];
        }) => {
            await replicache.mutate.updateTicketMembers({
                ticketId,
                userIdsToAdd,
                userIdsToRemove,
            });
        },
        [replicache]
    );

    return { changeTicketMembers };
}

/**
 * Remove the owner of a ticket, leaving the ticket without an owner.
 */
export function useRemoveTicketOwner() {
    const { replicache } = useReplicache();

    const removeTicketOwner = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            await replicache.mutate.updateTicketOwner({ ticketId, userId: null });
        },
        [replicache]
    );

    return { removeTicketOwner };
}

/**
 * Set the owner of a ticket to a user. If that user was previously a member, they are no longer
 * a member, only the owner.
 */
export function useSetTicketOwner() {
    const { replicache } = useReplicache();

    const setTicketOwner = useCallback(
        async ({ ticketId, userId }: { ticketId: string; userId: number }) => {
            await replicache.mutate.updateTicketOwner({ ticketId, userId });
        },
        [replicache]
    );

    return { setTicketOwner };
}
