import React, { useEffect } from "react";

import { Route, RouteProps } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useSetIsAppReady } from "components/loading/Loading";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { OfflineView } from "views/error/OfflineView";

export type AppRouteProps = {
    appIsReady?: boolean;
    children?: React.ReactNode;
    requireOnline?: boolean;
} & Omit<RouteProps, "children" | "render">;

/**
 * Wrapper component around react-router Route that handles various business logic.
 */
export function AppRoute({ appIsReady, children, requireOnline, ...rest }: AppRouteProps) {
    const setIsAppReady = useSetIsAppReady();
    const isOnline = useRecoilValue(networkStatusState);

    useEffect(() => {
        if (appIsReady) {
            setIsAppReady(true);
        }
    }, [appIsReady, setIsAppReady]);

    return (
        <Route
            {...rest}
            render={() => {
                if (requireOnline && !isOnline) {
                    return <OfflineView />;
                }

                return children;
            }}
        />
    );
}
