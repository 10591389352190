import React, { useEffect, useMemo } from "react";

import { useMaybeCurrentIdentity, useSetCurrentIdentity } from "AppState";
import { Enums } from "lib/Enums";
import { Queries } from "lib/Queries";
import { useLiveQuery } from "lib/apollo/useLiveQuery";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { createCtx } from "lib/react/Context";
import { CurrentIdentity } from "lib/types/common/currentIdentity";

export type { CurrentIdentity };

const [useCurrentIdentity, ContextProvider] = createCtx<CurrentIdentity>();

export { useCurrentIdentity };

// Because IdentityContext is used throughout the app, this fragment should include only
// fields that change infrequently. If a field that changed frequently was included
// it would trigger lots of spurious re-renders throughout the app.
const fragments = {
    identity: gql(/* GraphQL */ `
        fragment IdentityContext_identity on identities {
            id

            app_state
            auth0_id
            email_address
            full_name
            is_email_address_verified
            names_confirmed_at
            onboarding_completed_at
            username

            users(where: { disabled_at: { _is_null: true } }) {
                id
                name
                full_name
                onboarding_completed_at
                org_id

                org {
                    id
                    demographic_info
                    display_name
                    disabled_at
                    provisioned_by_identity_id
                    slug
                }
            }
        }
    `),
};

const queries = {
    component: gql(/* GraphQL */ `
        query IdentityContext($identityId: uuid!) {
            identities_by_pk(id: $identityId) {
                ...IdentityContext_identity
            }
        }
    `),
};

export type IdentityProviderProps = {
    children?: React.ReactNode;
    identityId: string;
    isReadOnly?: boolean;
};

export function IdentityProvider({
    identityId,
    isReadOnly = false,
    children,
}: IdentityProviderProps) {
    const currentIdentity = useMaybeCurrentIdentity();
    const setCurrentIdentity = useSetCurrentIdentity();
    const componentQuery = useLiveQuery({
        query: queries.component,
        variables: { identityId },
        apiRoleType: Enums.ApiRoleType.IDENTITY,
    });

    const _identity = getFragmentData(fragments.identity, componentQuery.data?.identities_by_pk);
    const identity = useMemo(
        () =>
            _identity
                ? {
                      ..._identity,
                      isReadOnly,
                  }
                : _identity,
        [_identity, isReadOnly]
    );

    useEffect(() => {
        setCurrentIdentity(identity ?? null);
    }, [identity, setCurrentIdentity]);

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error) {
        // eslint-disable-next-line no-console
        console.log(componentQuery.error);
        return null;
    }

    if (!currentIdentity) {
        return null;
    }

    return <ContextProvider value={currentIdentity}>{children}</ContextProvider>;
}

Queries.register({ component: "IdentityContext", gqlMapByName: queries });
