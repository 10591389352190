import React, { useCallback, useEffect, useRef, useState } from "react";

import { CommonEnums, TBlocker } from "c9r-common";
import classNames from "classnames";

import { UserSelect } from "components/ui/common/UserSelect";
import { BorderButton } from "components/ui/core/BorderButton";
import { Checkbox } from "components/ui/core/Checkbox";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { useCurrentUser } from "contexts/UserContext";
import { useDraft } from "lib/Drafts";
import { Enums } from "lib/Enums";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserSelect_userFragment } from "lib/graphql/__generated__/graphql";
import { CurrentUser } from "lib/types/common/currentUser";
import { isDefined } from "lib/types/guards";

import { BlockerBadge } from "./BlockerBadge";
import { BlockerInput, TDisplayableBlocker, TDisplayableTicketBlocker } from "./BlockerInput";
import styles from "./NewBlockerEditor.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment NewBlockerEditor_board on boards {
            id

            authorized_users {
                user {
                    id
                    name

                    ...UserSelect_user
                }
            }

            ...BlockerInput_board
        }
    `),
};

export type NewBlockerEditorProps = {
    board: FragmentType<typeof fragments.board>;
    className?: string;
    draftStorageKey?: string;
    excludedTicketIds: string[];
    onCancel?: () => void;
    onSubmit: ({
        blocker,
        shouldAssign,
        newAssignee,
    }: {
        blocker: TBlocker;
        shouldAssign: boolean;
        newAssignee: { id: number } | null;
    }) => void;
};

export function NewBlockerEditor({
    board: _boardFragment,
    className,
    draftStorageKey = "",
    excludedTicketIds,
    onCancel,
    onSubmit,
}: NewBlockerEditorProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const { loadDraft, discardDraft, throttledSaveDraft } = useDraft({
        localStorageKey: draftStorageKey,
    });
    const currentUser = useCurrentUser();
    const [inputValue, setInputValue] = useState(loadDraft()?.content ?? "");
    const [blocker, setBlocker] = useState<TDisplayableBlocker | null>(null);
    const [shouldAssign, setShouldAssign] = useState(false);
    const [newAssignee, setNewAssignee] = useState<CurrentUser | UserSelect_userFragment>(
        currentUser
    );
    const footerRef = useRef(null);

    const isAssigningToCurrentUser = newAssignee?.id === currentUser.id;
    const possibleAssignmentUsers = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const isUserOpeningTicketSelector = !inputValue && e.target.value === "#";

            if (isUserOpeningTicketSelector) {
                return;
            }

            setInputValue(e.target.value);

            setBlocker(
                e.target.value
                    ? { type: CommonEnums.BlockerType.TEXT, value: { text: e.target.value } }
                    : null
            );

            throttledSaveDraft.callback({
                draft: {
                    content: e.target.value,
                    timestamp: loadDraft()?.timestamp ?? new Date().getTime(),
                },
            });
        },
        [inputValue, loadDraft, throttledSaveDraft]
    );

    const handleCancel = useCallback(() => {
        discardDraft();
        onCancel?.();
    }, [discardDraft, onCancel]);

    const handleSubmit = useCallback(() => {
        if (!blocker) {
            return;
        }

        discardDraft();

        onSubmit({
            blocker,
            shouldAssign,
            newAssignee: shouldAssign ? newAssignee : null,
        });
    }, [blocker, discardDraft, newAssignee, onSubmit, shouldAssign]);

    const handleKeyboardCancel = useCallback(() => {
        if (inputValue) {
            setInputValue("");

            return;
        }

        handleCancel();
    }, [handleCancel, inputValue]);

    const handleSelectTicket = useCallback(
        ({ ticket }: { ticket: TDisplayableTicketBlocker["value"]["ticket"] }) => {
            setBlocker({
                type: CommonEnums.BlockerType.TICKET,
                value: { ticket },
            });
        },
        []
    );

    const handleClearTicket = useCallback(() => setBlocker(null), []);

    // Delete draft on unmount if text is empty.
    useEffect(() => {
        return () => {
            if (!inputValue?.trim()) {
                discardDraft();
            }
        };
    }, [discardDraft, inputValue]);

    return (
        <div className={classNames(className, styles.newBlockerEditor)}>
            <BlockerBadge className={styles.blockerHeaderLabel} />

            <BlockerInput
                blocker={blocker}
                board={board}
                excludedTicketIds={excludedTicketIds}
                onClearTicket={handleClearTicket}
                onSelectTicket={handleSelectTicket}
                textInputBoxProps={{
                    "data-cy": "new-blocker-input",
                    onChange: handleChange,
                    onKeyboardCancel: handleKeyboardCancel,
                    onKeyboardSubmit: handleSubmit,
                }}
                value={inputValue}
            />

            {possibleAssignmentUsers.length ? (
                <div className={styles.assignmentControls}>
                    <Checkbox
                        checked={shouldAssign}
                        inline
                        instrumentation={{
                            elementName: "new_blocker_editor.assign_user_checkbox",
                            eventData: {
                                assigneeUserId: newAssignee.id,
                                checked: !shouldAssign,
                            },
                        }}
                        onChange={() => {
                            setShouldAssign(!shouldAssign);
                        }}
                        label="Assign to"
                    />
                    <UserSelect
                        hideMenuItemIconMode={Enums.HideMenuItemIconMode.ALWAYS}
                        users={possibleAssignmentUsers}
                        onSelect={userToAssign => {
                            if (userToAssign) {
                                setNewAssignee(userToAssign);
                                setShouldAssign(true);
                            }
                        }}
                    >
                        {() => (
                            <div className={styles.assignThreadBtnWrapper}>
                                <DropdownButton
                                    contentClassName={styles.assignThreadBtnContent}
                                    minimal
                                    small
                                    text={
                                        newAssignee.id === currentUser.id ? "me" : newAssignee.name
                                    }
                                    instrumentation={null}
                                />
                            </div>
                        )}
                    </UserSelect>
                </div>
            ) : null}
            {!isAssigningToCurrentUser && shouldAssign ? (
                <div className={styles.explanatoryText}>
                    {newAssignee.name} will be notified that they are responsible for following up
                    on and resolving this blocker.
                </div>
            ) : null}

            <div className={styles.footerActions} ref={footerRef}>
                <BorderButton onClick={handleCancel} content="Cancel" instrumentation={null} />
                <BorderButton
                    data-cy="new-blocker-submit-btn"
                    content="Add blocker"
                    debounceIntervalMs={1000}
                    disabled={!blocker}
                    onClick={handleSubmit}
                    instrumentation={null}
                    primary
                />
            </div>
        </div>
    );
}
