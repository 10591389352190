import { useCallback } from "react";

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { AppData } from "AppData";
import { CurrentIdentity } from "lib/types/common/currentIdentity";
import { CurrentOrg, CurrentUser } from "lib/types/common/currentUser";

// This file contains global reactive state, i.e., values for which updates
// should trigger a rerender of components that use the value.

export const currentBoardIdState = atom<string | null>({
    key: "currentBoardIdState",
    default: null,
});

export const integrationsSetupStatusState = atom<{
    isReady: boolean;
    slack: boolean | null;
    github: boolean | null;
    discord: boolean | null;
    zapier: boolean | null;
}>({
    key: "integrationsSetupStatus",
    default: {
        isReady: false,
        slack: null,
        github: null,
        discord: null,
        zapier: null,
    },
});

export const isDemoIntroModalDisplayedState = atom<boolean>({
    key: "isDemoIntroModalDisplayed",
    default: false,
});

export const isHotspotSettingEnabledState = atom<boolean>({
    key: "isHotspotSettingEnabled",
    default: false,
});

export const isNotFoundViewDisplayedState = atom<boolean>({
    key: "isNotFoundViewDisplayed",
    default: false,
});

const currentIdentityState = atom<CurrentIdentity | null>({
    key: "currentIdentity",
    default: null,
});

const currentOrgState = atom<CurrentOrg | null>({
    key: "currentOrg",
    default: null,
});

const currentUserState = atom<CurrentUser | null>({
    key: "currentUser",
    default: null,
});

const currentUserIdState = atom<number | null>({
    key: "currentUserId",
    default: null,
});

export function useSetCurrentIdentity() {
    const setCurrentIdentityState = useSetRecoilState(currentIdentityState);

    return useCallback(
        (identity: CurrentIdentity | null) => {
            AppData.currentIdentity = identity;
            setCurrentIdentityState(identity);
        },
        [setCurrentIdentityState]
    );
}

export function useMaybeCurrentIdentity() {
    return useRecoilValue(currentIdentityState);
}

export function useSetCurrentOrg() {
    const setCurrentOrgState = useSetRecoilState(currentOrgState);

    return useCallback(
        (org: CurrentOrg | null) => {
            AppData.currentOrg = org;
            setCurrentOrgState(org);
        },
        [setCurrentOrgState]
    );
}

export function useMaybeCurrentOrg() {
    return useRecoilValue(currentOrgState);
}

export function useSetCurrentUser() {
    const setCurrentUserState = useSetRecoilState(currentUserState);

    return useCallback(
        (user: CurrentUser | null) => {
            AppData.currentUser = user;
            setCurrentUserState(user);
        },
        [setCurrentUserState]
    );
}

export function useMaybeCurrentUser() {
    return useRecoilValue(currentUserState);
}

export function useSetCurrentUserId() {
    const [currentUserId, setCurrentUserId] = useRecoilState(currentUserIdState);
    const setCurrentOrgState = useSetRecoilState(currentOrgState);
    const setCurrentUserState = useSetRecoilState(currentUserState);

    return useCallback(
        (userId: number | null) => {
            if (userId !== currentUserId) {
                AppData.currentOrg = null;
                AppData.currentUser = null;
                setCurrentOrgState(null);
                setCurrentUserState(null);
                setCurrentUserId(userId);
            }
        },
        [currentUserId, setCurrentOrgState, setCurrentUserState, setCurrentUserId]
    );
}

export function useMaybeCurrentUserId() {
    return useRecoilValue(currentUserIdState);
}
