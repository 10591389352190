import React, { useMemo } from "react";

import classNames from "classnames";

import { UserSelect } from "components/ui/common/UserSelect";
import { Checkbox } from "components/ui/core/Checkbox";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Enums } from "lib/Enums";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserSelect_userFragment } from "lib/graphql/__generated__/graphql";

import styles from "./CommentAssignmentControls.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment CommentAssignmentControls_user on users {
            ...UserSelect_user
        }
    `),
};

export type CommentAssignmentControlsProps = {
    assignToName?: string;
    className?: string;
    checked?: boolean;
    onSelectAssignee: (user: UserSelect_userFragment | null) => void;
    onToggleShouldAssign: () => void;
    selectableUsers: FragmentType<typeof fragments.user>[];
};

export function CommentAssignmentControls({
    assignToName,
    className,
    checked,
    onSelectAssignee,
    onToggleShouldAssign,
    selectableUsers: _selectableUserFragments,
}: CommentAssignmentControlsProps) {
    const selectableUsers = useMemo(
        () => _selectableUserFragments.map(fragment => getFragmentData(fragments.user, fragment)),
        [_selectableUserFragments]
    );

    if (!assignToName || !selectableUsers.length) {
        return null;
    }

    const showUserPicker = selectableUsers.length > 1;

    return (
        <div className={classNames(className, styles.commentAssignmentControls)}>
            <Checkbox
                checked={checked}
                inline
                instrumentation={{
                    elementName: "comment_editor.assign_user_checkbox",
                    eventData: {
                        checked: !checked,
                    },
                }}
                onChange={onToggleShouldAssign}
                label={`Assign to ${showUserPicker ? "" : assignToName}`}
            />
            {showUserPicker ? (
                <UserSelect
                    hideMenuItemIconMode={Enums.HideMenuItemIconMode.ALWAYS}
                    users={selectableUsers}
                    onSelect={onSelectAssignee}
                >
                    {() => (
                        <div className={styles.assignThreadBtnWrapper}>
                            <DropdownButton
                                contentClassName={styles.assignThreadBtnContent}
                                minimal
                                small
                                text={assignToName}
                                instrumentation={null}
                            />
                        </div>
                    )}
                </UserSelect>
            ) : null}
        </div>
    );
}
