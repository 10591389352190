import { CommonEnumValue } from "c9r-common";

import { TicketEntry } from "lib/replicache/entries/EntryTypes";

import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";
import { TUser } from "../UserContextApi";

export const UserResolvers: TResolvers = {
    query_root: {
        users_by_pk: buildResolver(async (parent, args, { userContextApi }) =>
            userContextApi.getUserById({ userId: args.id })
        ),
    },

    users: {
        assigned_threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.threads.findAll()).filter(
                    thread => thread.assigned_to_user_id === parent.id
                )
            )
        ),

        comments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.comments.findAll()).filter(
                    comment => comment.author_user_id === parent.id
                )
            )
        ),

        created_threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.threads.findAll()).filter(
                    thread => thread.created_by_user_id === parent.id
                )
            )
        ),

        created_tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.tickets.findAll()).filter(
                    thread => thread.created_by_user_id === parent.id
                )
            )
        ),

        org: buildResolver(async (parent, args, { userContextApi }) => userContextApi.getOrg()),

        owned_tasks: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.tasks.findAll()).filter(task => task.assigned_to_user_id === parent.id)
            )
        ),

        owned_tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => {
                const tickets = await api.tickets.findAll();
                const result: {
                    readonly __typename: "tickets_owners";
                    readonly user_id: number;
                    readonly ticket_id: string;
                    readonly added_at: string;
                    readonly type: string;
                    readonly owner: TUser;
                    readonly ticket: TicketEntry;
                }[] = [];

                for (const ticket of tickets) {
                    for (const to of ticket.owners) {
                        if (to.user_id === parent.id) {
                            result.push({
                                __typename: "tickets_owners",
                                user_id: parent.id,
                                ticket_id: ticket.id,
                                added_at: to.added_at,
                                type: to.type,
                                owner: parent,
                                ticket,
                            } as const);
                        }
                    }
                }

                return result;
            })
        ),

        resolved_threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.threads.findAll()).filter(
                    thread => thread.resolved_by_user_id === parent.id
                )
            )
        ),

        ticket_plans: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => {
                const tickets = await api.tickets.findAll();
                const result: {
                    readonly __typename: "users_tickets_plans";
                    readonly user_id: number;
                    readonly ticket_id: string;
                    readonly plan_pos: number;
                    readonly plan_type: CommonEnumValue<"UserTicketPlanType">;
                    readonly ticket: TicketEntry;
                    readonly user: TUser;
                }[] = [];

                for (const ticket of tickets) {
                    for (const up of ticket.user_plans) {
                        if (up.user_id === parent.id) {
                            result.push({
                                __typename: "users_tickets_plans",
                                user_id: parent.id,
                                ticket_id: ticket.id,
                                plan_pos: up.plan_pos,
                                plan_type: up.plan_type,
                                ticket,
                                user: parent,
                            } as const);
                        }
                    }
                }

                return result;
            })
        ),

        watched_tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => {
                const tickets = await api.tickets.findAll();
                const result: {
                    readonly __typename: "tickets_watchers";
                    readonly user_id: number;
                    readonly ticket_id: string;
                    readonly watcher: TUser;
                    readonly ticket: TicketEntry;
                }[] = [];

                for (const ticket of tickets) {
                    for (const tw of ticket.watchers) {
                        if (tw.user_id === parent.id) {
                            result.push({
                                __typename: "tickets_watchers",
                                user_id: parent.id,
                                ticket_id: ticket.id,
                                watcher: parent,
                                ticket,
                            } as const);
                        }
                    }
                }

                return result;
            })
        ),
    },
};
