import { useCallback, useEffect, useRef, useState } from "react";

import { useCurrentUser } from "contexts/UserContext";
import { Regexes } from "lib/Regexes";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

const fragments = {
    CommentAssignmentState: {
        board: gql(/* GraphQL */ `
            fragment CommentAssignmentState_board on boards {
                id
                authorized_users {
                    user {
                        id
                        name
                        ...CommentAssignmentControls_user
                    }
                }
                ...CommentMentions_board
            }
        `),
    },
    CommentMentions: {
        board: gql(/* GraphQL */ `
            fragment CommentMentions_board on boards {
                id
                authorized_users {
                    user {
                        id
                        name
                        ...CommentAssignmentControls_user
                    }
                }
            }
        `),
    },
};

export const useCommentMentions = ({
    board: _boardFragment,
    commentText,
}: {
    board: FragmentType<typeof fragments.CommentMentions.board>;
    commentText: string;
}) => {
    const board = getFragmentData(fragments.CommentMentions.board, _boardFragment);
    const mentionedUserIds = [];
    const mentions = Array.from((commentText ?? "").matchAll(Regexes.MENTIONS_IN_TEXT));
    const userIdsByName = Object.fromEntries(
        board.authorized_users
            .map(au => au.user)
            .filter(isDefined)
            .map(user => [user.name.toUpperCase(), user.id])
    );

    for (const [, mentionedName] of mentions) {
        const mentionedUserId = userIdsByName[mentionedName.toUpperCase()];

        if (mentionedUserId) {
            mentionedUserIds.push(mentionedUserId);
        }
    }

    return { mentionedUserIds };
};

export const useCommentAssignmentState = ({
    board: _boardFragment,
    commentText,
    recentUserIds,
    threadAssignedToUserId,
}: {
    board: FragmentType<typeof fragments.CommentAssignmentState.board>;
    commentText: string;
    recentUserIds: number[];
    threadAssignedToUserId?: number | null;
}) => {
    const board = getFragmentData(fragments.CommentAssignmentState.board, _boardFragment);
    const currentUser = useCurrentUser();
    const [shouldAssign, setShouldAssign] = useState(false);
    const didSetNewAssigneeUserId = useRef(false);
    const [newAssigneeUserId, setNewAssigneeUserIdInternal] = useState<number | null>(null);
    const { mentionedUserIds } = useCommentMentions({ board, commentText });
    const usersById = Object.fromEntries(
        board.authorized_users
            .map(au => au.user)
            .filter(isDefined)
            .map(user => [user.id, user])
    );

    const setNewAssigneeUserId = useCallback((userId: number | null) => {
        didSetNewAssigneeUserId.current = true;
        setNewAssigneeUserIdInternal(userId);
    }, []);

    const newAssignee = newAssigneeUserId ? usersById[newAssigneeUserId] : null;
    const isAssigningToCurrentUser = newAssignee?.id === currentUser.id;
    const assignToName = isAssigningToCurrentUser ? "me" : newAssignee?.name ?? "";

    const possibleAssignmentUserIds = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map(user => user.id)
        .filter(userId => userId !== threadAssignedToUserId);

    useEffect(() => {
        if (!possibleAssignmentUserIds.length) {
            setNewAssigneeUserIdInternal(null);
            setShouldAssign(false);
        }
    }, [possibleAssignmentUserIds]);

    useEffect(() => {
        if (newAssignee && !possibleAssignmentUserIds.includes(newAssignee.id)) {
            setNewAssigneeUserIdInternal(null);
            setShouldAssign(false);
        }
    }, [possibleAssignmentUserIds, newAssignee]);

    // If the user hasn't explicitly indicated an assignee yet, try to be helpful and set one
    // in anticipation.
    useEffect(() => {
        if (didSetNewAssigneeUserId.current || shouldAssign) {
            return;
        }

        const firstMentionedEligibleUserId = mentionedUserIds.find(
            userId =>
                userId !== threadAssignedToUserId && possibleAssignmentUserIds.includes(userId)
        );
        const mostRecentEligibleUserId = recentUserIds.find(
            userId =>
                userId !== threadAssignedToUserId && possibleAssignmentUserIds.includes(userId)
        );

        if (firstMentionedEligibleUserId) {
            setNewAssigneeUserIdInternal(firstMentionedEligibleUserId);
        } else if (mostRecentEligibleUserId) {
            setNewAssigneeUserIdInternal(mostRecentEligibleUserId);
        } else if (possibleAssignmentUserIds.length) {
            setNewAssigneeUserIdInternal(possibleAssignmentUserIds[0]);
        }
    }, [
        shouldAssign,
        newAssigneeUserId,
        mentionedUserIds,
        recentUserIds,
        possibleAssignmentUserIds,
        setNewAssigneeUserIdInternal,
        threadAssignedToUserId,
        usersById,
    ]);

    return {
        assignToName,
        isAssigningToCurrentUser,
        mentionedUserIds,
        newAssignee,
        possibleAssignmentUsers: possibleAssignmentUserIds
            .map(userId => usersById[userId])
            .filter(Boolean),
        setNewAssigneeUserId,
        setShouldAssign,
        shouldAssign,
    };
};
