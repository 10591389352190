import React, { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import { BillingSchema } from "c9r-common";
import { useRecoilState } from "recoil";

import { useSetIsViewReady } from "components/loading/Loading";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { useCurrentUser } from "contexts/UserContext";
import { useDeleteAccountDialog } from "dialogs/DeleteAccountDialog";
import { Enums } from "lib/Enums";
import { Queries } from "lib/Queries";
import { gql } from "lib/graphql/__generated__";

import styles from "./AccountSettings.module.scss";
import { billingInfoState } from "./billing/BillingState";
import { BillingSubsection } from "./billing/BillingSubsection";
import { ContactInfoContent } from "./billing/ContactInfoContent";
import { PaymentMethodContent } from "./billing/PaymentMethodContent";
import { SubscriptionPlanContent } from "./billing/SubscriptionPlanContent";

type DangerZoneProps = {
    onChange: () => void;
};

function DangerZone({ onChange }: DangerZoneProps) {
    const currentUser = useCurrentUser();
    const deleteAccountDialog = useDeleteAccountDialog();

    return (
        <BorderButton
            content={<>Delete {currentUser.org.display_name} organization</>}
            danger
            instrumentation={{ elementName: "settings.delete_org_btn" }}
            onClick={() => deleteAccountDialog.openWithProps({ onChange })}
        />
    );
}

export function AccountSettings() {
    const currentUser = useCurrentUser();
    const setIsViewReady = useSetIsViewReady();
    const [billingInfo, setBillingInfo] = useRecoilState(billingInfoState);
    const [runQuery, { called, data, error, loading, refetch }] = useLazyQuery(
        AccountSettings.queries.billingInfo,
        {
            context: {
                apiRoleType: !currentUser.isReadOnly ? Enums.ApiRoleType.USER_ORG_ADMIN : undefined,
            },
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        if (!billingInfo) {
            void runQuery();
        }
    }, [billingInfo, runQuery]);

    useEffect(() => {
        if (!called || loading) {
            return;
        }

        if (data?.get_billing_info?.billing_info) {
            setBillingInfo({
                ...(data.get_billing_info.billing_info as BillingSchema.BillingInfo),
                as_of: new Date(Date.now()),
            });
        }

        if (!data?.get_billing_info?.ok) {
            AppToaster.error({
                message: "Sorry, something went wrong loading your account info.",
                timeout: 0,
            });
        }
    }, [called, currentUser.org_id, data, loading, setBillingInfo]);

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    if (error) {
        throw error;
    }

    return (
        <div className={styles.container}>
            <h1>Account</h1>

            <h3>Subscription and payment</h3>

            <BillingSubsection title="Plan">
                <SubscriptionPlanContent onChange={refetch} />
            </BillingSubsection>
            <BillingSubsection title="Payment method">
                <PaymentMethodContent onChange={refetch} />
            </BillingSubsection>
            <BillingSubsection title="Billing contact">
                <ContactInfoContent onChange={refetch} />
            </BillingSubsection>

            <h3 className={styles.dangerZone}>Danger zone</h3>
            <DangerZone onChange={refetch} />
        </div>
    );
}

AccountSettings.queries = {
    billingInfo: gql(/* GraphQL */ `
        query AccountSettingsGetBillingInfo {
            get_billing_info {
                ok
                error
                billing_info
            }
        }
    `),
};

Queries.register({
    component: "AccountSettings",
    gqlMapByName: AccountSettings.queries,
});
