import React from "react";

import { Editor } from "@tiptap/core";
import { ReactRenderer, Range as TipTapRange } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import tippy, { Instance as TippyInstance } from "tippy.js";

import { CssClasses } from "lib/Constants";
import { Focus } from "lib/Focus";

import styles from "./Suggestions.module.scss";

type SuggestionsMenuWrapperProps<TItem> = {
    items: TItem[];
    selectedIndex: number;
    onSelect: (selectedIndex: number) => void;
    query: string;
} & SuggestionProps;

type SuggestionsMenuWrapperState = {
    selectedIndex: number;
};

function createSuggestionsMenuWrapper<TItem>({
    SuggestionsMenuComponent,
    itemToCommandProps,
}: Pick<BuildSuggestionParamsOptions<TItem>, "SuggestionsMenuComponent" | "itemToCommandProps">) {
    return class SuggestionsMenuWrapper extends React.Component<
        SuggestionsMenuWrapperProps<TItem>,
        SuggestionsMenuWrapperState
    > {
        constructor(props: SuggestionsMenuWrapperProps<TItem>) {
            super(props);

            const state: SuggestionsMenuWrapperState = {
                selectedIndex: 0,
            };
            this.state = state;
        }

        componentDidUpdate(oldProps: SuggestionsMenuWrapperProps<TItem>) {
            if (this.props.items !== oldProps.items) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    selectedIndex: 0,
                });
            }
        }

        onKeyDown({ event }: SuggestionKeyDownProps) {
            // We should only apply the arrow navigation if the popup is open.
            // There seems to be no direct way to verify this,
            // so here we are using the suggested items' length as a proxy
            // for indicating that the popup is displayed.
            if (this.props.items.length && ["ArrowUp", "ArrowDown"].includes(event.key)) {
                this.setState(prev => ({
                    selectedIndex:
                        (prev.selectedIndex +
                            this.props.items.length +
                            (event.key === "ArrowUp" ? -1 : 1)) %
                        this.props.items.length,
                }));

                return true;
            }

            if (["Enter", "Tab"].includes(event.key)) {
                Focus.temporarilyDisableTabFocusStyling();
                return this.selectItem(this.state.selectedIndex);
            }

            return false;
        }

        selectItem(selectedIndex: number) {
            const item = this.props.items[selectedIndex];

            if (item) {
                this.props.command(itemToCommandProps(item));
                return true;
            }

            return false;
        }

        render() {
            if (!this.props.items.length) {
                return null;
            }

            return (
                <div className={styles.suggestionsMenuWrapper}>
                    <SuggestionsMenuComponent
                        items={this.props.items}
                        selectedIndex={this.state.selectedIndex}
                        onSelect={selectedIndex => this.selectItem(selectedIndex)}
                        query={this.props.query}
                    />
                </div>
            );
        }
    };
}

type BuildSuggestionParamsOptions<TItem> = {
    /** The character that will trigger the suggestion, e.g., "@". */
    char: string;

    /** The node type that will be inserted when a suggestion is selected. */
    type: string;

    /** A function which takes a string search query and returns an array of matches. */
    search: (query: string) => { item: TItem; isExactMatch: boolean }[];

    SuggestionsMenuComponent: React.FunctionComponent<{
        items: TItem[];
        selectedIndex: number;
        onSelect: (selectedIndex: number) => void;
        query?: string;
    }>;

    /**
     * A function which takes an item and returns an object like { text } (for text nodes) or { attrs }
     * (for non-text nodes).
     */
    itemToCommandProps: (item: TItem) => { text: string } | { attrs: Record<any, any> };

    /**
     * If true, then after the suggestion menu closes, if the current query is an exact match,
     * go ahead and insert the item.
     */
    autoReplaceExactMatch?: boolean | ((query: string) => boolean);

    /**
     * If autoReplaceExact match is specified, this regex indicates which characters are used
     * by the items. Any *other* characters are treated as "break" characters. When a break
     * character is entered, the suggestion is over, at which point we attempt to replace the
     * query with an exact match.
     */
    itemCharsRegex?: RegExp;

    /**
     * Characters that the trigger character must appear after for the suggestion to open.
     * By default this is only the space character. (Suggestions are always allowed to open at
     * the beginning of a line.)
     */
    previousCharAllowList: string[];

    /**
     * Should be set to true if the item ends with the same character that triggers the
     * suggestions menu, e.g. :+1:. This will ensure that the end character is included in the
     * range of characters that are replaced.
     */
    endsWithTriggerChar?: boolean;
};

/**
 * Helper to build the params for tiptap's Suggestion module.
 *
 * Returns props to spread into tiptap's Suggestion module.
 */
export function buildSuggestionParams<TItem>({
    char,
    type,
    search,
    SuggestionsMenuComponent,
    itemToCommandProps,
    autoReplaceExactMatch,
    itemCharsRegex,
    previousCharAllowList,
    endsWithTriggerChar,
}: BuildSuggestionParamsOptions<TItem>) {
    // Protection to ensure the command to insert the suggestion cannot possibly be executed twice.
    // tiptap's Suggestion module doesn't require this, but because we're sometimes automatically
    // replacing the query with the suggestion, which tiptap wouldn't know about, we need the
    // protection.
    let didExecuteCommand = false;

    const SuggestionsMenuWrapper = createSuggestionsMenuWrapper<TItem>({
        SuggestionsMenuComponent,
        itemToCommandProps,
    });

    const maybeReplaceExactMatch = ({
        props,
        mustEndWithNonItemCharacter,
    }: {
        props: SuggestionProps;
        mustEndWithNonItemCharacter?: boolean;
    }) => {
        if (
            props.query && typeof autoReplaceExactMatch === "function"
                ? autoReplaceExactMatch(props.query)
                : autoReplaceExactMatch
        ) {
            const lastCharacter = props.query[props.query.length - 1];
            const endsWithNonItemCharacter = !!(
                itemCharsRegex &&
                lastCharacter &&
                !lastCharacter.match(itemCharsRegex)
            );

            if (mustEndWithNonItemCharacter && !endsWithNonItemCharacter) {
                return;
            }

            const query =
                mustEndWithNonItemCharacter && endsWithNonItemCharacter
                    ? props.query.slice(0, -1)
                    : props.query;
            const matches = search(query);
            const exactMatchItem = matches.find(result => result.isExactMatch)?.item;

            if (exactMatchItem) {
                // setImmediate is necessary here. Let's say the user types "@foo"
                // followed by space, and there is a user named "foo". We want to
                // replace the text with the mention.
                //
                // When we replace text with a mention, the number of positions in
                // the document decreases, because each character in the text
                // occupies a position, whereas the mention node, being an atomic
                // node, occupies just one position in its entirety.
                //
                // Apparently, the Suggestion hooks below (e.g., onUpdate, onExit) are
                // called *before* the space gets inserted by tiptap. Therefore, we can't
                // replace the text with the mention immediately. If we do, tiptap
                // attempts to insert the space at the position it computed beforehand,
                // and that position might now be incorrect or out of bounds.
                //
                // To account for that, we use setImmediate to wait until after tiptap
                // is done with the transaction that triggered the hook.
                setImmediate(() => {
                    props.command({
                        ...itemToCommandProps(exactMatchItem),

                        // We don't want to replace the non-item character with the
                        // suggestion.
                        toRangeOffset: endsWithNonItemCharacter ? -1 : endsWithTriggerChar ? 1 : 0,
                    });
                });
            }
        }
    };

    return {
        char,
        items: ({ query }: { query: string }) => {
            return search(query).map(result => result.item);
        },
        prefixSpace: false,
        render: () => {
            let reactRenderer: ReactRenderer<typeof SuggestionsMenuWrapper>;
            let popup: TippyInstance;

            return {
                onStart: (props: SuggestionProps) => {
                    if (!props.editor.isFocused) {
                        return;
                    }

                    // As of February 2023, SuggestionsMenuWrapper is a dynamically created
                    // class component returned by createSuggestionsMenuWrapper. Wasn't able
                    // to provide a type properly for ReactRenderer.
                    // @ts-ignore
                    reactRenderer = new ReactRenderer(SuggestionsMenuWrapper, {
                        props,
                        editor: props.editor,
                    });

                    [popup] = tippy("body", {
                        getReferenceClientRect: props.clientRect,
                        appendTo:
                            props.decorationNode?.closest(`.${CssClasses.SCROLLABLE}`) ||
                            document.body,
                        content: reactRenderer.element,
                        maxWidth: "calc(min(600px, 100vw - 20px))",
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                        onHidden() {
                            // Necessary to destroy the renderer. If not, then after pressing Escape, while
                            // the popup is hidden, the renderer still exists and will, among other things,
                            // capture key events like ArrowUp/ArrowDown.
                            reactRenderer.destroy();
                        },
                        zIndex: 20,
                    });

                    didExecuteCommand = false;
                },
                onUpdate(props: SuggestionProps) {
                    reactRenderer?.updateProps(props);

                    // We want to be able to auto-replace the query with the suggestion whenever
                    // *we* think the query is done. Unfortunately, tiptap only "exits" the
                    // suggestion after a space character, therefore we can't really only
                    // on onExit below. So, we also check for replacing exact matches during
                    // updates, explicitly looking for a character that's not possibly in an
                    // item (and therefore indicating the query is done).
                    maybeReplaceExactMatch({ props, mustEndWithNonItemCharacter: true });

                    popup?.setProps({ getReferenceClientRect: props.clientRect });
                },
                onKeyDown(props: SuggestionKeyDownProps) {
                    if (props.event.key === "Escape") {
                        popup?.hide();
                        return true;
                    }

                    // Calling show() if the popup is already shown is a no-op. But we call
                    // it here in case the user hit Escape. If they then move the cursor
                    // through the query, the popup should reappear.
                    popup?.show();

                    // As of February 2023, because we didn't provide a type properly to
                    // ReactRenderer, it doesn't know the type of ref.
                    // @ts-ignore
                    return reactRenderer?.ref?.onKeyDown(props);
                },
                onExit(props: SuggestionProps) {
                    maybeReplaceExactMatch({ props, mustEndWithNonItemCharacter: false });
                    popup?.destroy();
                    reactRenderer?.destroy();
                },
            };
        },
        command: ({
            editor,
            range: givenRange,
            props: { attrs, text, toRangeOffset = 0 },
        }: {
            editor: Editor;
            range: TipTapRange;
            props: { attrs: Record<string, any>; text: string; toRangeOffset: number };
        }) => {
            if (didExecuteCommand) {
                return null;
            }

            didExecuteCommand = true;

            const range = {
                from: givenRange.from,
                to: givenRange.to + toRangeOffset,
            };

            // The given range contains the suggest character and the text typed afterward that
            // was used during the suggestion autocomplete. To see if we're at the end of the
            // line, we compare that text to the text we get by including one additional document
            // position. If they differ, that extra position has text, so clearly we're not at the
            // end of the line.
            const isEndOfLine =
                editor.state.doc.textBetween(range.from, range.to) ===
                editor.state.doc.textBetween(range.from, range.to + 1);

            return (
                editor
                    .chain()
                    .focus()
                    // As noted above, range.from === n and range.to === n + 1. Thus, by passing range
                    // to insertContentAt, we effectively  _replace_ the suggest character with the new
                    // node.
                    .insertContentAt(
                        range,
                        [
                            type === "text" ? { type, text } : { type, attrs },
                            isEndOfLine && { type: "text", text: " " },
                        ].filter(Boolean),

                        // Using updateSelection false is important. When typing a mention and then
                        // pressing Enter/Tab to select, it's not necessary. But in the flow where
                        // we automatically convert exact-match text into a mention, the cursor may
                        // already have moved, and we don't want our conversion to move the cursor
                        // (which would be unexpected for the user).
                        { updateSelection: false }
                    )
                    .run()
            );
        },
        allow: ({ editor, range }: { editor: Editor; range: TipTapRange }) => {
            try {
                const previousChar = editor.state.doc.textBetween(range.from - 1, range.from);

                if (
                    previousChar &&
                    !(previousCharAllowList || [" "]).some(c => previousChar === c)
                ) {
                    return false;
                }

                return editor.can().insertContentAt(range, { type });
            } catch (error) {
                // As of April 2022, if a user *pastes* text containing a suggestion char, the
                // range above, provided by tiptap, appears to be inconsistent with the doc state
                // resulting in the call to textBetween potentiall being out-of-bounds. So, do our
                // best. If it fails, just assume the suggestion isn't allowed.
                return false;
            }
        },
    };
}
