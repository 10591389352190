import { useEffect, useRef } from "react";

import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";

import { EnumValue, Enums } from "lib/Enums";

export function useHotkey(
    keys: Parameters<typeof useHotkeys>[0],
    callback: Parameters<typeof useHotkeys>[1],
    options: Parameters<typeof useHotkeys>[2] = {},
    deps: Parameters<typeof useHotkeys>[3] = []
) {
    return useHotkeys(
        keys,
        callback,
        { preventDefault: true, scopes: [Enums.HotkeyScope.DEFAULT], ...options },
        deps
    );
}

/**
 * react-hotkeys-hook supports a flexible concept for hotkey scopes. Here, we simplify things
 * by implementing it like a stack. The app starts in the DEFAULT scope, where all the "normal"
 * hotkeys are enabled. But we can push a new scope onto the stack, which gets popped off
 * (restoring the prior scopes) afterward.
 */
export function useHotkeyScope({
    scope,
    isEnabled,
}: {
    scope: EnumValue<"HotkeyScope">;
    isEnabled: boolean;
}) {
    const { disableScope, enableScope, enabledScopes: lastEnabledScopes } = useHotkeysContext();
    const ref = useRef({ didEnableScope: false, lastEnabledScopes });

    useEffect(() => {
        const { current } = ref;

        if (isEnabled && !current.didEnableScope) {
            current.didEnableScope = true;
            current.lastEnabledScopes.forEach(disableScope);
            enableScope(scope);
        }

        return () => {
            if (isEnabled && current.didEnableScope) {
                current.didEnableScope = false;
                disableScope(scope);
                current.lastEnabledScopes.forEach(enableScope);
            }
        };
    }, [isEnabled, scope, enableScope, disableScope]);
}
