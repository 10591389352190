import React, { useEffect, useRef } from "react";

import { AppData } from "AppData";
import { useSetIsViewReady } from "components/loading/Loading";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { createDeepLink } from "lib/DeepLinks";
import { useDocumentTitle } from "lib/Hooks";
import { useHistory } from "lib/Routing";

import styles from "./DesktopAppRedirectView.module.scss";

export type DesktopAppRedirectViewProps = {
    deepLinkUrl?: string;
};

export function DesktopAppRedirectView({ deepLinkUrl: _deepLinkUrl }: DesktopAppRedirectViewProps) {
    const didAttempt = useRef(false);
    const setIsViewReady = useSetIsViewReady();
    const { history } = useHistory();
    const deepLinkUrl = _deepLinkUrl ?? AppData.redirectToDeepLinkUrl ?? createDeepLink();

    useDocumentTitle();

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    useEffect(() => {
        if (window.electron) {
            history.replace("/");
        }
    }, [history]);

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (!didAttempt.current) {
                didAttempt.current = true;
                window.open(deepLinkUrl);
            }
        }, 1500);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [deepLinkUrl]);

    if (window.electron) {
        return null;
    }

    return (
        <div className={styles.desktopAppRedirectView}>
            <BrandLogomark className={styles.logomark} height={30} />
            <p>You'll be automatically redirected to the desktop app</p>
            <p className={styles.redirectManually}>
                Flat desktop app not opening?{" "}
                <a target="_blank" rel="noopener noreferrer" href={deepLinkUrl}>
                    Click here
                </a>
                .
            </p>
        </div>
    );
}
