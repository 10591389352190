import { useCallback } from "react";

import { MutationSpec } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { useReplicache } from "lib/replicache/Context";

export function useBulkMutate() {
    const currentUser = useCurrentUser();
    const { replicache } = useReplicache();

    const bulkMutate = useCallback(
        async ({ mutations }: { mutations: MutationSpec[] }) => {
            await replicache.mutate.bulkMutation({
                orgId: currentUser.org_id,
                mutations,
            });
        },
        [currentUser.org_id, replicache]
    );

    return { bulkMutate };
}
