import React from "react";

import { Tooltip, TooltipProps } from "components/ui/core/Tooltip";

export type TruncatedTextProps = {
    as?: "div" | "span";
    children?: React.ReactNode;
    className?: string;
    maxLength: number;
    text: string;
    tooltipProps?: Omit<TooltipProps, "children" | "content">;
};
/**
 * Helper to display text and possibly truncate it. If truncated, the full text is
 * visible via a tooltip.
 */
export function TruncatedText({
    as = "span",
    children,
    className,
    maxLength,
    text,
    tooltipProps,
}: TruncatedTextProps) {
    if (text.length <= maxLength) {
        return React.createElement(as, { className }, text);
    }

    return (
        <Tooltip
            {...tooltipProps}
            content={text}
            small
            renderTarget={props =>
                React.createElement(
                    as,
                    { ...props, className, tabIndex: -1 },
                    `${text.slice(0, maxLength)}...`
                )
            }
        >
            {children}
        </Tooltip>
    );
}
