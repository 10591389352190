/**
 * Check whether a keyboard event should be treated as a click of the target element.
 */
export function isKeyboardClick(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Enter" || event.key === " ";
}

/**
 * Check whether a keyboard event should be treated as deleting the target element.
 */
export function isKeyboardDelete(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Backspace" || event.key === "Delete";
}

/**
 * Check whether a keyboard event should be treated as a cancel of a text input type input.
 */
export function isKeyboardTextInputCancel(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Escape";
}

/**
 * Check whether a keyboard event should be treated as a "submit" (save) of a text input type
 * input.
 */
export function isKeyboardTextInputSubmit(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Enter";
}

/**
 * Check whether a keyboard event should be treated as a cancel of a text area type input.
 */
export function isKeyboardTextAreaCancel(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Escape";
}

/**
 * Check whether a keyboard event should be treated as a "submit" (save) of a text area type
 * input.
 */
export function isKeyboardTextAreaSubmit(event: React.KeyboardEvent | KeyboardEvent) {
    return event.key === "Enter" && (event.ctrlKey || event.metaKey);
}

/**
 * If a keyboard shortcut combo is detected within an element, should the shortcut fire?
 * For example, if a keyboard shortcut exists for the letter "X", and the user types "X"
 * into an input field, we don't want the keyboard shortcut to fire.
 *
 * The useKeyboardShortcuts hook has its own internal handler for this (within BlueprintJS).
 * However, this helper is useful for any keyboard shortcuts we handle separately from that
 * hook.
 *
 * Adapted from BlueprintJS.
 *
 * @see https://github.com/palantir/blueprint/blob/%40blueprintjs/core%403.54.0/packages/core/src/hooks/hotkeys/useHotkeys.ts#L156
 */
export function isKeyboardShortcutEligible(e: KeyboardEvent) {
    const elem = e.target as HTMLElement;
    const closestEditableElement = elem.closest("input, textarea, [contenteditable=true]");

    if (!closestEditableElement) {
        return true;
    }

    if (closestEditableElement instanceof HTMLInputElement) {
        if (closestEditableElement.type === "checkbox" || closestEditableElement.type === "radio") {
            return true;
        }

        if (closestEditableElement.readOnly) {
            return true;
        }
    }

    return false;
}
