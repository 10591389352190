import React from "react";

import classNames from "classnames";

import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { Select, SelectProps } from "components/ui/core/Select";
import { useCurrentUser } from "contexts/UserContext";
import { isFakeId } from "lib/GraphQL";
import { divideAndFlattenGroups, isUserQueryMatch } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserSelect_userFragment } from "lib/graphql/__generated__/graphql";
import { isDefined } from "lib/types/guards";

import { UserMenuItemText } from "./UserMenuItem";
import styles from "./UserSelect.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserSelect_user on users {
            id
            full_name
            name

            ...Avatar_user
            ...UserMenuItem_user
        }
    `),
};

const isNullUserItem = (id: number) => {
    return !id || isFakeId(id);
};

export type UserSelectProps = {
    children:
        | React.ReactNode
        | (({ selectedItems }: { selectedItems: UserSelect_userFragment[] }) => React.ReactNode);
    makeFirstOptionCurrentUser?: boolean;
    users: FragmentType<typeof fragments.user>[];
} & Omit<
    SelectProps<UserSelect_userFragment>,
    "items" | "itemListRenderer" | "menuItemTextRenderer"
>;

export function UserSelect({
    children,
    makeFirstOptionCurrentUser,
    users: _userFragments,

    ...selectProps
}: UserSelectProps) {
    const users = _userFragments.map(_userFragment =>
        getFragmentData(fragments.user, _userFragment)
    );
    const currentUser = useCurrentUser();

    return (
        <Select
            itemPredicate={(query, user) =>
                isUserQueryMatch({
                    query,
                    name: user.name,
                    fullName: user.full_name,
                })
            }
            placeholder="Filter people"
            placement="bottom-start"
            {...selectProps}
            items={
                makeFirstOptionCurrentUser
                    ? [users.find(user => user.id === currentUser.id)]
                          .concat(users.filter(user => user.id !== currentUser.id))
                          .filter(isDefined)
                    : users
            }
            targetClassName={classNames(selectProps?.targetClassName, styles.pickerTarget)}
            menuItemTextRenderer={user => {
                if (isNullUserItem(user.id)) {
                    return <span>{user.name}</span>;
                }

                return <UserMenuItemText showAvatar user={user} />;
            }}
            itemListRenderer={({ filteredItems, renderItem, itemsParentRef }) => {
                const items = filteredItems.filter(itm => !isNullUserItem(itm.id));
                const nullValueItem = filteredItems.find(itm => isNullUserItem(itm.id));

                return filteredItems.length ? (
                    <Menu ulRef={itemsParentRef}>
                        {divideAndFlattenGroups({
                            itemGroups: [
                                items.map(renderItem).filter(isDefined),
                                [nullValueItem].filter(isDefined).map(renderItem).filter(isDefined),
                            ],
                            divider: () => <MenuDivider />,
                        })}
                    </Menu>
                ) : null;
            }}
        >
            {children}
        </Select>
    );
}
