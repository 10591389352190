import React, { useEffect } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Log } from "lib/Log";
import { useHistory } from "lib/Routing";

export function LoginRedirect() {
    const { history } = useHistory();

    const hasError = new URLSearchParams(window.location.search).get("error");

    useEffect(() => {
        if (!hasError) {
            setImmediate(() => {
                history.push("/");
            });
        }
    }, [hasError, history]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("error")) {
            Log.error(
                "Login error",
                Object.fromEntries(
                    ["error", "error_description"]
                        .filter(key => searchParams.get(key))
                        .map(key => [key, searchParams.get(key)])
                )
            );
        }
    }, []);

    if (!hasError) {
        return null;
    }

    return (
        <p>
            Sorry, something went wrong. Please email us at <SupportMailto />.
        </p>
    );
}
