import { AppData } from "AppData";
import { Config } from "Config";
import { getCurrentAccessToken } from "lib/auth/AccessToken";

export async function setIdentityCookie() {
    const response = await fetch(Config.api.urlHttpProxy, {
        method: "POST",
        body: JSON.stringify({
            query: `
                    mutation {
                        set_identity_cookie {
                            ok
                        }
                    }
                `,
        }),
        headers: {
            authorization: `Bearer ${getCurrentAccessToken()}`,
            "x-hasura-role": AppData.identityRole,
        },
        credentials: "include",
    });

    const body = await response.json();

    if (!body?.data?.set_identity_cookie?.ok) {
        throw new Error("Failed to set identity cookie");
    }
}
