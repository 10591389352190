import React from "react";

import classNames from "classnames";

import {
    AbstractTextArea,
    AbstractTextAreaProps,
} from "components/ui/core/abstract/AbstractTextArea";

import styles from "./TextArea.module.scss";

export type TextAreaProps = {
    fill?: boolean;
} & AbstractTextAreaProps;

export function TextArea({ fill, ...abstractTextInputProps }: TextAreaProps) {
    return (
        <AbstractTextArea
            {...abstractTextInputProps}
            className={classNames(
                abstractTextInputProps.className,
                styles.textArea,
                abstractTextInputProps.disabled && styles.disabled,
                fill && styles.fill
            )}
        />
    );
}
