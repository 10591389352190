import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const OrgResolvers: TResolvers = {
    query_root: {
        orgs_by_pk: buildResolver(async (parent, args, { userContextApi }) =>
            userContextApi.getOrg()
        ),
    },

    orgs: {
        boards: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.boards.findAll())
        ),

        tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.tickets.findAll())
        ),
    },
};
