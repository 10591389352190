import React from "react";

import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";

import styles from "./DemoOutro.module.scss";

const dialogState = dialogStateFamily<null>("DemoOutroDialog");

export function useDemoOutroDialog() {
    return useDialogSingleton(dialogState);
}

export function DemoOutroDialog() {
    const { isOpen } = useRecoilValue(dialogState);
    const dialog = useDemoOutroDialog();

    return (
        <Dialog
            isOpen={isOpen}
            onClose={dialog.close}
            className={classNames(styles.dialog)}
            isCloseButtonShown={false}
        >
            <Dialog.Content>
                <Dialog.Header className={styles.header}>
                    <h1 className={styles.title}>Ready to give Flat a try?</h1>
                </Dialog.Header>
                <Dialog.Body className={styles.body}>
                    <p>
                        Flat is <strong>free</strong> for all teams during our early access period.
                        Sign up to get started, or visit our website for more information.
                    </p>
                </Dialog.Body>
                <Dialog.Footer className={styles.footer}>
                    <BorderAnchorButton
                        brandFlatCta
                        className={styles.ctaButton}
                        content="Sign up"
                        href={Config.urls.signup}
                        large
                        instrumentation={{
                            elementName: "demo_outro_dialog.signup_btn",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                    <p>
                        Learn more at{" "}
                        <a href={Config.urls.website} target="_blank" rel="noopener noreferrer">
                            {Config.urls.website}
                        </a>
                        .
                    </p>
                </Dialog.Footer>
            </Dialog.Content>
        </Dialog>
    );
}
