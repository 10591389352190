import React, { useEffect } from "react";

import { useSetRecoilState } from "recoil";

import { isNotFoundViewDisplayedState } from "AppState";
import { useSetIsViewReady } from "components/loading/Loading";
import { SupportMailto } from "components/shared/SupportMailto";
import { useDocumentTitle } from "lib/Hooks";
import { Link } from "lib/Routing";

import styles from "./NotFoundView.module.scss";

export type NotFoundViewProps = {
    header?: React.ReactNode;
    message?: React.ReactNode;
};

export function NotFoundView({ header, message }: NotFoundViewProps) {
    const setIsNotFoundViewDisplayed = useSetRecoilState(isNotFoundViewDisplayedState);
    const setIsViewReady = useSetIsViewReady();

    useDocumentTitle("Not Found");

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    useEffect(() => {
        setIsNotFoundViewDisplayed(true);

        return () => {
            setIsNotFoundViewDisplayed(false);
        };
    }, [setIsNotFoundViewDisplayed]);

    return (
        <div className={styles.notFoundView}>
            <div className={styles.header}>{header || "That page doesn't exist"}</div>
            {message ? <div className={styles.message}>{message}</div> : null}
            <div className={styles.link}>
                <Link to="/">Back</Link>
            </div>
        </div>
    );
}

function DefaultMessage() {
    return (
        <>
            If you think you're seeing this page in error, <SupportMailto text="let us know" />.
        </>
    );
}

export function BoardNotFoundView() {
    return <NotFoundView header="That workspace doesn't exist." message={<DefaultMessage />} />;
}

export function TicketNotFoundView() {
    return <NotFoundView header="That topic doesn't exist." message={<DefaultMessage />} />;
}

export function UserNotFoundView() {
    return <NotFoundView header="That person doesn't exist." message={<DefaultMessage />} />;
}
