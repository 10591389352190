import { useCallback } from "react";

import { gql } from "lib/graphql/__generated__";
import { useReplicache } from "lib/replicache/Context";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

import { waitFor } from "../MutationHelpers";

/**
 * As of January 2024, we use a Replicate mutator (updateBoardMembers) to persist changes
 * to board membership. However because board membership is not maintained in the local
 * Replicache cache, there is no client-side implementation of the mutator, so we don't
 * support an optimistic UI. So, we wait until the backend mutator runs and the client
 * syncs the new membership info.
 */
function useWaitForBoardMembers() {
    const replicacheGraphQLClient = useReplicacheGraphQLClient();

    const waitForBoardMembers = useCallback(
        async ({
            boardId,
            userIdsAdded,
            userIdsRemoved,
        }: {
            boardId: string;
            userIdsAdded: number[];
            userIdsRemoved: number[];
        }) => {
            await waitFor(async () => {
                // Read through the Replicache layer to find whether the users have been
                // added or removed successfully. As of January 2024, the Replicache
                // resolver will ultimately retrive this from the UserContext currentUser
                // object -- see BoardResolvers.ts.
                const queryResult = await replicacheGraphQLClient.readQuery({
                    query: gql(/* GraphQL */ `
                        query ManageBoardAccessWaitForBoardMembersSync($boardId: uuid!) {
                            board: boards_by_pk(id: $boardId) {
                                id

                                attached_users {
                                    user {
                                        id
                                    }
                                }
                            }
                        }
                    `),
                    variables: {
                        boardId,
                    },
                });

                const attachedUsers = queryResult?.board?.attached_users;

                if (!attachedUsers) {
                    return false;
                }

                const attachedUserIds = new Set(attachedUsers.map(au => au.user.id));

                return (
                    attachedUsers &&
                    userIdsAdded.every(userId => attachedUserIds.has(userId)) &&
                    userIdsRemoved.every(userId => !attachedUserIds.has(userId))
                );
            });
        },
        [replicacheGraphQLClient]
    );

    return { waitForBoardMembers };
}

export function useAttachGuestToBoard() {
    const { replicache } = useReplicache();
    const { waitForBoardMembers } = useWaitForBoardMembers();

    const attachGuestToBoard = useCallback(
        async ({ boardId, userId }: { boardId: string; userId: number }) => {
            await replicache.mutate.updateBoardMembers({ boardId, userIdsToAdd: [userId] });
            await waitForBoardMembers({ boardId, userIdsAdded: [userId], userIdsRemoved: [] });
        },
        [replicache, waitForBoardMembers]
    );

    return { attachGuestToBoard };
}

export function useDetachGuestFromBoard() {
    const { replicache } = useReplicache();
    const { waitForBoardMembers } = useWaitForBoardMembers();

    const detachGuestFromBoard = useCallback(
        async ({ boardId, userId }: { boardId: string; userId: number }) => {
            await replicache.mutate.updateBoardMembers({ boardId, userIdsToRemove: [userId] });
            await waitForBoardMembers({ boardId, userIdsAdded: [], userIdsRemoved: [userId] });
        },
        [replicache, waitForBoardMembers]
    );

    return { detachGuestFromBoard };
}

export function useUpdateBoardMembers() {
    const { replicache } = useReplicache();
    const { waitForBoardMembers } = useWaitForBoardMembers();

    const updateBoardMembers = useCallback(
        async ({
            boardId,
            userIdsToAdd = [],
            userIdsToRemove = [],
        }: {
            boardId: string;
            userIdsToAdd?: number[];
            userIdsToRemove?: number[];
        }) => {
            await replicache.mutate.updateBoardMembers({ boardId, userIdsToAdd, userIdsToRemove });
            await waitForBoardMembers({
                boardId,
                userIdsAdded: userIdsToAdd,
                userIdsRemoved: userIdsToRemove,
            });
        },
        [replicache, waitForBoardMembers]
    );

    return { updateBoardMembers };
}
