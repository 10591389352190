import React, { useCallback } from "react";

import { Classes } from "@blueprintjs/popover2";
import { CommonEnums } from "c9r-common";
import classNames from "classnames";
import { parseISO } from "date-fns";

import { Avatar } from "components/ui/common/Avatar";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { Icon } from "components/ui/core/Icon";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { SelectorMenu } from "components/ui/core/SelectorMenu";
import { Editor } from "components/ui/editor/Editor";
import { BotUser } from "lib/BotUser";
import { EnumValue, Enums } from "lib/Enums";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { makeFragmentData } from "lib/graphql/__generated__";
import { Avatar_userFragmentDoc } from "lib/graphql/__generated__/graphql";

import styles from "./MyWorkContent.module.scss";
import { TodoItem, TodoItemThread, useMyWork } from "./MyWorkContext";

type ListItemProps = {
    children: React.ReactNode;
    className?: string;
    url?: string;
};

function ListItem({ children, className, url }: ListItemProps) {
    return (
        <div className={classNames(className, styles.listItem)}>
            {url ? (
                <a
                    className={styles.listItemContent}
                    target="_blank"
                    rel="noopener noreferrer"
                    role="menuitem"
                    href={url}
                >
                    {children}
                </a>
            ) : (
                <div className={classNames(styles.listItemContent, Classes.POPOVER2_DISMISS)}>
                    {children}
                </div>
            )}
        </div>
    );
}

function isDelayed({ startDate }: { startDate: Date }) {
    return Date.now() - startDate.getTime() > 1 * 24 * 60 * 60 * 1000;
}

type TopicTitleWithIconProps = {
    title: string;
};

function TopicTitleWithIcon({ title }: TopicTitleWithIconProps) {
    return (
        <div className={styles.topicTitleWithIcon}>
            <Icon
                className={styles.topicIcon}
                icon="file-text"
                iconSet="lucide"
                iconSize={13.5}
                strokeWidth={1.75}
            />
            <div className={styles.topicTitle}>{title}</div>
        </div>
    );
}

type ThreadTodoItemProps = {
    isUnread?: boolean;
    ticket: TodoItem["ticket"];
    todoData: TodoItemThread["todoData"];
};

function ThreadTodoItem({ isUnread, ticket, todoData }: ThreadTodoItemProps) {
    const { thread, comment } = todoData;
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    return (
        <div
            className={classNames(
                styles.threadTodoItem,
                isUnread && styles.unread,
                comment && isDelayed({ startDate: parseISO(comment.posted_at) }) && styles.delayed
            )}
        >
            {thread.blocker_type ? (
                <div className={styles.blockerHeader}>
                    <Icon
                        className={styles.blockerIcon}
                        icon="x-octagon"
                        iconSet="lucide"
                        iconSize={16}
                        strokeWeight={1.5}
                    />
                    <div className={styles.blockerReference}>
                        {thread.blocker_type === CommonEnums.BlockerType.TEXT ? (
                            <div className={styles.blockerText}>{thread.blocker_text}</div>
                        ) : (
                            <div className={styles.blockerTicket}>
                                <Icon
                                    icon="file-text"
                                    iconSet="lucide"
                                    iconSize={16}
                                    strokeWidthAbsolute={1}
                                />
                                <div className={styles.blockerTicketTitle}>
                                    {thread.blocker_ticket?.title ??
                                        buildUnauthorizedDisplayName({
                                            abstractName: "workItem",
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            {comment ? (
                <div className={styles.comment}>
                    <Avatar
                        user={comment.author ?? makeFragmentData(BotUser, Avatar_userFragmentDoc)}
                        size={26}
                    />
                    <div className={styles.commentBylineAndTextAndTopic}>
                        <div className={styles.commentBylineAndText}>
                            <div className={styles.commentByline}>
                                <div className={styles.commentAuthor}>
                                    {(comment.author ?? BotUser).name}
                                </div>
                                <TimeAgo className={styles.commentDate} date={comment.posted_at} />
                            </div>
                            <Editor
                                board={ticket.board}
                                content={comment.comment_json as any}
                                editorContentClassName={styles.commentText}
                                emoji
                                images
                                minHeight={24}
                                mentions
                                readOnly
                                ticketReferences
                            />
                        </div>
                        <TopicTitleWithIcon title={ticket.title} />
                    </div>
                </div>
            ) : (
                <TopicTitleWithIcon title={ticket.title} />
            )}
        </div>
    );
}

type TodoListItemProps = {
    className?: string;
    isUnread?: boolean;
    ticket: TodoItem["ticket"];
    todoType: EnumValue<"TodoType">;
    todoData: unknown;
};

function TodoListItem({ className, isUnread, ticket, todoType, todoData }: TodoListItemProps) {
    return (
        <ListItem className={className}>
            {(() => {
                switch (todoType) {
                    case Enums.TodoType.THREAD:
                        return (
                            <ThreadTodoItem
                                isUnread={isUnread}
                                ticket={ticket}
                                todoData={todoData as TodoItemThread["todoData"]}
                            />
                        );
                    default:
                        return null;
                }
            })()}
        </ListItem>
    );
}

export type MyWorkContentProps = {
    className?: string;
    defaultKeyboardEnabled?: boolean;
    onItemSelected: () => void;
};

export function MyWorkContent({
    className,
    defaultKeyboardEnabled,
    onItemSelected,
}: MyWorkContentProps) {
    const myWork = useMyWork();
    const { history } = useHistory();
    const { buildTicketUrl } = useUrlBuilders();

    const myTodosItems = myWork.myTodosItems.concat();

    const handleSelect = useCallback(
        (item: TodoItem) => {
            const { ticket, todoType, todoData, url } = item;

            if (url) {
                window.open(url);
            } else {
                history.push({
                    pathname: buildTicketUrl({
                        ticketSlug: ticket.slug,
                        vanity: {
                            boardDisplayName: ticket.board.display_name,
                            ticketRef: ticket.ref,
                            ticketTitle: ticket.title,
                        },
                    }).pathname,
                    ...(todoType === Enums.TodoType.THREAD && {
                        hash: `T${(todoData as TodoItemThread["todoData"]).thread.id}`,
                    }),
                });
            }

            onItemSelected();
        },
        [buildTicketUrl, history, onItemSelected]
    );

    if (!myTodosItems) {
        return <div className={classNames(className, styles.myWork)}>Loading...</div>;
    }

    return (
        <div className={classNames(className, styles.myWork)}>
            <SelectorMenu
                allowNoActiveItem
                filterable={false}
                items={myTodosItems}
                itemListRenderer={({ items, renderItem }) => {
                    if (!items.length) {
                        return (
                            <div className={styles.zeroState}>
                                <Icon
                                    icon="chatBubbleWithoutLines"
                                    iconSet="c9r"
                                    iconSize={24}
                                    strokeWidth={1}
                                />
                                <div>You have no open threads or blockers</div>
                            </div>
                        );
                    }

                    return items.map((item, i, arr) => (
                        <>
                            {renderItem({
                                item,
                                menuItemProps: {
                                    instrumentation: {
                                        elementName: "my_work.ticket",
                                        eventData: {
                                            ticketId: item.ticket.id,
                                            isUnread: item.isUnread,
                                        },
                                    },
                                },
                            })}
                            {i === arr.length - 1 ? null : (
                                <MenuDivider className={styles.menuDivider} />
                            )}
                        </>
                    ));
                }}
                menuProps={{ className: styles.menu }}
                menuItemClassName={styles.menuItem}
                menuItemTextRenderer={item => (
                    <TodoListItem
                        key={item.key}
                        ticket={item.ticket}
                        isUnread={item.isUnread}
                        todoType={item.todoType}
                        todoData={item.todoData}
                    />
                )}
                onSelect={handleSelect}
            />
        </div>
    );
}
