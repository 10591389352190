import React, { useCallback, useMemo } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { BoardAndStagesMenuItem } from "components/shared/BoardAndStagesMenuItem";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Tooltip } from "components/ui/core/Tooltip";
import { useSelectParentTicketDialog } from "dialogs/SelectParentTicketDialog";
import { useClipboard } from "lib/Hooks";
import { useMoveTicketsUX } from "lib/MutationUX";
import { getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./TicketMenuButton.module.scss";
import { useDetailView } from "../context/DetailViewContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketMenuButton_ticket on tickets {
            id
            ref
            slug
            stage_id
            title

            board {
                id
                access_type
                display_name
            }

            child_of_not_deleted_tasks: child_of_tasks(where: { deleted_at: { _is_null: true } }) {
                id

                ticket {
                    id
                }
            }
        }
    `),
};

export type TicketMenuButtonProps = {
    className?: string;
};

export function TicketMenuButton({ className }: TicketMenuButtonProps) {
    const {
        archiveTicket,
        isClosed,
        isArchivable,
        isTrashable,
        isTrashed,
        ticket: _ticketFragment,
        ticketId,
        ticketUrl,
        trashTicket,
    } = useDetailView();

    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const { moveTicketsUX } = useMoveTicketsUX();

    const isAttachableToParent = !isTrashed;
    const selectParentTicketDialog = useSelectParentTicketDialog();
    const parentTicketIds = useMemo(
        () => ticket.child_of_not_deleted_tasks.map(task => task.ticket.id),
        [ticket]
    );

    const isBoardPrivate = ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE;

    const { copyTextToClipboard } = useClipboard();
    const handleCopyTicketLink = useCallback(async () => {
        await copyTextToClipboard({
            text: ticketUrl,
            successToast: "Link copied to clipboard.",
        });
    }, [copyTextToClipboard, ticketUrl]);

    const menuItems = [
        !isClosed ? (
            <Tooltip
                key="board-stages-menu"
                className={styles.menuItemTooltip}
                content={
                    <span className={styles.menuItemTooltipContent}>
                        Topics cannot be moved out of private workspaces.
                    </span>
                }
                disabled={!isBoardPrivate}
                placement="left"
                small
                wide
            >
                <BoardAndStagesMenuItem
                    icon={<Icon icon="truck" iconSet="lucide" iconSize={18} />}
                    text="Move to"
                    disabled={isBoardPrivate}
                    excludedStageIds={[ticket.stage_id]}
                    instrumentation={{
                        elementName: "ticket_detail.menu.move_to",
                        eventData: {
                            ticketId: ticket.id,
                        },
                    }}
                    onSelect={({ stageId }: { stageId: string }) =>
                        moveTicketsUX({ ticketIds: [ticket.id], toStageId: stageId })
                    }
                />
            </Tooltip>
        ) : null,
        isAttachableToParent ? (
            <MenuItem
                key="attach-to-parent"
                text="Attach to parent"
                icon={<Icon icon="attachParent" iconSet="c9r" iconSize={18} />}
                instrumentation={{
                    elementName: "ticket_detail.menu.attach_to_parent",
                    eventData: { ticketId },
                }}
                onClick={() =>
                    selectParentTicketDialog.openWithProps({
                        childTicketId: ticketId,
                        initialIneligibleTicketIds: [ticketId, ...parentTicketIds],
                    })
                }
            />
        ) : null,
        <MenuItem
            key="copy-link"
            text="Copy link"
            icon={<Icon icon="link" iconSet="lucide" iconSize={18} />}
            instrumentation={{
                elementName: "ticket_detail.menu.copy_link",
                eventData: { ticketId },
            }}
            onClick={handleCopyTicketLink}
        />,
        isArchivable ? (
            <MenuItem
                key="archive"
                text="Archive"
                icon={<Icon icon="archive" iconSet="lucide" iconSize={18} />}
                instrumentation={{
                    elementName: "ticket_detail.menu.archive",
                    eventData: { ticketId },
                }}
                onClick={archiveTicket}
            />
        ) : null,
        isTrashable ? (
            <MenuItem
                key="trash"
                text="Move to trash"
                icon={<Icon icon="trash" iconSet="lucide" iconSize={18} />}
                instrumentation={{
                    elementName: "ticket_detail.menu.trash",
                    eventData: { ticketId },
                }}
                onClick={trashTicket}
            />
        ) : null,
    ].filter(Boolean);

    return (
        <div className={classNames(className, styles.ticketMenuButton)}>
            <MenuPopover
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [4, -4],
                        },
                    },
                    preventOverflow: { enabled: false },
                }}
                content={<Menu>{menuItems}</Menu>}
                placement="bottom-end"
            >
                <EllipsisButton
                    className={styles.button}
                    vertical
                    iconSize={20}
                    instrumentation={null}
                />
            </MenuPopover>
        </div>
    );
}
