import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CommonEnums, Errors, TicketDueDates, ValueOf } from "c9r-common";
import classNames from "classnames";
import { addDays, nextMonday, parse as parseDate, startOfToday } from "date-fns";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { TQueryResult, ViewQueryLoader } from "components/loading/QueryLoader";
import { CardMenu } from "components/shared/card/CardMenu";
import {
    TicketList,
    TicketListHeader,
    TicketListRow,
} from "components/shared/ticketList/TicketList";
import {
    TaskRowContentActivity,
    TaskRowContentAncestry,
    TaskRowContentCheckbox,
    TaskRowContentDueDate,
    TaskRowContentMainInfo,
    TaskRowContentOwnership,
    TicketRowContentActivity,
    TicketRowContentAncestry,
    TicketRowContentBoard,
    TicketRowContentDueDate,
    TicketRowContentDynamicWidthTableColumnDefinitions,
    TicketRowContentIcon,
    TicketRowContentMainInfo,
    TicketRowContentOwnership,
    TicketRowContentPlaceholder,
    TicketRowContentProgress,
    TicketRowContentStage,
} from "components/shared/ticketList/TicketRowContent";
import { TicketRowLayout } from "components/shared/ticketList/TicketRowLayout";
import { DynamicWidthTable, DynamicWidthTableCell } from "components/ui/common/DynamicWidthTable";
import { Banner } from "components/ui/core/Banner";
import { useCurrentUser } from "contexts/UserContext";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { interpolateMissingPositions, moveToPositionByIndex } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useDocumentTitle, useResettingValueMap, useSaveScrollPosition } from "lib/Hooks";
import { Queries } from "lib/Queries";
import { Link, useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    DueDatesViewQuery,
    DueDatesView_taskFragment,
    DueDatesView_ticketFragment,
    DueDatesView_userFragment,
} from "lib/graphql/__generated__/graphql";
import { useUpdateTaskDueDate, useUpdateTicketDueDate } from "lib/mutations";
import { createCtx } from "lib/react/Context";

import styles from "./DueDatesView.module.scss";

const fragments = {
    task: gql(/* GraphQL */ `
        fragment DueDatesView_task on tasks {
            id
            assigned_to_user_id
            deleted_at
            due_date
            is_complete
            tasklist_pos
            task_type

            tasklist {
                id
                added_at

                stage {
                    id
                    board_pos
                }
            }

            ...TaskRowContentAncestry_task
            ...TaskRowContentCheckbox_task
            ...TaskRowContentDueDate_task
            ...TaskRowContentMainInfo_task
            ...TaskRowContentOwnership_task
            ...TicketListRow_task
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment DueDatesView_ticket on tickets {
            id
            archived_at
            due_date
            stage_pos
            trashed_at

            board {
                id
                display_name
            }
            stage {
                id
                board_pos
            }

            ...CardMenu_ticket
            ...TicketListRow_ticket
            ...TicketRowContentActivity_ticket
            ...TicketRowContentAncestry_ticket
            ...TicketRowContentBoard_ticket
            ...TicketRowContentDueDate_ticket
            ...TicketRowContentMainInfo_ticket
            ...TicketRowContentOwnership_ticket
            ...TicketRowContentProgress_ticket
            ...TicketRowContentReference_ticket
            ...TicketRowContentStage_ticket
            ...TicketWatcherInfo_ticket
        }
    `),

    user: gql(/* GraphQL */ `
        fragment DueDatesView_user on users {
            id
            name

            owned_tasks(
                where: {
                    deleted_at: { _is_null: true }
                    due_date: { _is_null: false }
                    task_type: { _eq: "TASK" }
                    ticket: {
                        archived_at: { _is_null: true }
                        trashed_at: { _is_null: true }
                        board: { archived_at: { _is_null: true } }
                        stage: { deleted_at: { _is_null: true }, role: { _nin: ["COMPLETE"] } }
                    }
                }
            ) {
                id
                ...DueDatesView_task

                ticket {
                    id
                    ...DueDatesView_ticket
                }
            }

            owned_tickets(
                where: {
                    ticket: {
                        archived_at: { _is_null: true }
                        trashed_at: { _is_null: true }
                        board: { archived_at: { _is_null: true } }
                        stage: { deleted_at: { _is_null: true }, role: { _nin: ["COMPLETE"] } }
                    }
                }
            ) {
                ticket_id
                user_id
                type
                added_at

                ticket {
                    id
                    ...DueDatesView_ticket

                    maybe_implicitly_owned_tasks: tasks(
                        where: {
                            assigned_to_user_id: { _is_null: true }
                            deleted_at: { _is_null: true }
                            task_type: { _eq: "TASK" }
                        }
                    ) {
                        id
                        ...DueDatesView_task
                    }
                }
            }
        }
    `),
};

const DynamicWidthTableColumnDefinitions = [
    ...TicketRowContentDynamicWidthTableColumnDefinitions,
    {
        columnClassName: styles.menu,
        displayIfEmpty: true,
    },
];

export const SectionType = {
    LATER: "LATER",
    PAST_DUE: "PAST_DUE",
    NEXT_WEEK: "NEXT_WEEK",
    THIS_WEEK: "THIS_WEEK",
    TODAY: "TODAY",
    TOMORROW: "TOMORROW",
} as const;

export type DueDatesViewContextValue = {
    handleTaskCompletionChange: ({
        taskId,
        isComplete,
    }: {
        taskId: string;
        isComplete: boolean;
    }) => void;
    isSectionCollapsed: ({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) => boolean;
    positionsBySectionTypeAndId: Record<ValueOf<typeof SectionType>, Record<string, number>>;
    setPositionsBySectionTypeAndId: React.Dispatch<
        React.SetStateAction<Record<ValueOf<typeof SectionType>, Record<string, number>>>
    >;
    tasks: (DueDatesView_taskFragment & { ticket: DueDatesView_ticketFragment })[];
    tickets: DueDatesView_ticketFragment[];
    toggleSectionCollapsed: ({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) => void;
    user: DueDatesView_userFragment;
};

const [useDueDatesView, ContextProvider] = createCtx<DueDatesViewContextValue>();

type DueDatesViewProviderProps = {
    children: React.ReactNode;
    user: FragmentType<typeof fragments.user>;
};

type ViewState = Partial<{
    sections: Partial<
        Record<
            ValueOf<typeof SectionType>,
            {
                isCollapsed?: boolean;
            }
        >
    >;
}>;

// If updating, ensure it matches the CSS transition duration.
const COMPLETED_TASK_UNMOUNT_DELAY_MS = 300;

function DueDatesViewProvider({ children, user: _userFragment }: DueDatesViewProviderProps) {
    const user = getFragmentData(fragments.user, _userFragment);

    // Track when a task is marked completed in this view, so that we can still display it briefly
    // before it's unmounted.
    const resettingValueMap = useResettingValueMap({ timeoutMs: COMPLETED_TASK_UNMOUNT_DELAY_MS });

    const isRecentlyCompletedTask = useCallback(
        ({ taskId }: { taskId: string }) => {
            return !!resettingValueMap.getValueById({ id: taskId });
        },
        [resettingValueMap]
    );

    const handleTaskCompletionChange = useCallback(
        ({ taskId, isComplete }: { taskId: string; isComplete: boolean }) => {
            resettingValueMap.setValueById({ id: taskId, value: isComplete });
        },
        [resettingValueMap]
    );

    const ownedTasks = useMemo(
        () =>
            user.owned_tasks
                .map(task => ({
                    task: getFragmentData(fragments.task, task),
                    ticket: getFragmentData(fragments.ticket, task.ticket),
                }))
                .filter(
                    ({ task }) =>
                        task.task_type === CommonEnums.TaskType.TASK &&
                        (!task.is_complete || isRecentlyCompletedTask({ taskId: task.id })) &&
                        !task.deleted_at &&
                        task.due_date
                )
                .map(({ task, ticket }) => ({ ...task, ticket })),
        [isRecentlyCompletedTask, user.owned_tasks]
    );

    const implicitlyOwnedTasks = useMemo(
        () =>
            user.owned_tickets
                .flatMap(ot => {
                    const tasks = ot.ticket.maybe_implicitly_owned_tasks
                        .map(task => getFragmentData(fragments.task, task))
                        .filter(
                            task =>
                                // Implicitly owned tasks: unassigned tasks go to the ticket owner
                                ot.type === CommonEnums.TicketOwnerType.OWNER &&
                                !task.assigned_to_user_id
                        );

                    return tasks.map(task => ({
                        ...task,
                        ticket: getFragmentData(fragments.ticket, ot.ticket),
                    }));
                })
                .filter(
                    task =>
                        task.task_type === CommonEnums.TaskType.TASK &&
                        (!task.is_complete || isRecentlyCompletedTask({ taskId: task.id })) &&
                        !task.deleted_at &&
                        task.due_date
                ),
        [isRecentlyCompletedTask, user.owned_tickets]
    );

    const tasks = useMemo(() => {
        return ownedTasks.concat(implicitlyOwnedTasks);
    }, [ownedTasks, implicitlyOwnedTasks]);

    const tickets = useMemo(
        () =>
            user.owned_tickets
                .map(ot => getFragmentData(fragments.ticket, ot.ticket))
                .filter(ticket => !ticket.archived_at && !ticket.trashed_at && ticket.due_date),
        [user.owned_tickets]
    );

    const [positionsBySectionTypeAndId, setPositionsBySectionTypeAndId] = useState<
        Record<ValueOf<typeof SectionType>, Record<string, number>>
    >({
        [SectionType.LATER]: {},
        [SectionType.NEXT_WEEK]: {},
        [SectionType.PAST_DUE]: {},
        [SectionType.THIS_WEEK]: {},
        [SectionType.TODAY]: {},
        [SectionType.TOMORROW]: {},
    });

    const storageKey = `user.${user.id}.due_dates`;
    const [viewState, setViewState] = useState(
        (Storage.All.getItem(storageKey) ?? {}) as ViewState
    );

    useEffect(() => {
        Storage.All.setItem(storageKey, viewState);
    }, [viewState, storageKey]);

    const isSectionCollapsed = useCallback(
        ({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) => {
            return !!viewState?.sections?.[sectionType]?.isCollapsed;
        },
        [viewState]
    );

    const toggleSectionCollapsed = useCallback(
        ({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) => {
            setViewState(prev => ({
                ...prev,
                sections: {
                    ...prev.sections,
                    [sectionType]: {
                        ...prev.sections?.[sectionType],
                        isCollapsed: !prev?.sections?.[sectionType]?.isCollapsed,
                    },
                },
            }));
        },
        []
    );

    const value = useMemo(
        () => ({
            handleTaskCompletionChange,
            isSectionCollapsed,
            positionsBySectionTypeAndId,
            setPositionsBySectionTypeAndId,
            tasks,
            tickets,
            toggleSectionCollapsed,
            user,
        }),
        [
            handleTaskCompletionChange,
            isSectionCollapsed,
            positionsBySectionTypeAndId,
            tasks,
            tickets,
            toggleSectionCollapsed,
            user,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

type SortableSectionRecord = {
    id: string;
    dueDate: string;
    posPersisted?: number;
    task?: DueDatesView_taskFragment;
    ticket: DueDatesView_ticketFragment;
};

export type SectionRecord = SortableSectionRecord & { posDisplayed: number };

export function pickSectionType({ dueDate }: { dueDate: string }) {
    const {
        isPast,
        isToday,
        isTomorrow,
        isUpcomingThisWeek,
        isUpcomingNextWeek,
    } = TicketDueDates.getInfo({ dueDate });

    if (isPast) {
        return SectionType.PAST_DUE;
    }

    if (isToday) {
        return SectionType.TODAY;
    }

    if (isTomorrow) {
        return SectionType.TOMORROW;
    }

    if (isUpcomingThisWeek) {
        return SectionType.THIS_WEEK;
    }

    if (isUpcomingNextWeek) {
        return SectionType.NEXT_WEEK;
    }

    return SectionType.LATER;
}

export function sortSection(a: SortableSectionRecord, b: SortableSectionRecord) {
    if (a.dueDate === b.dueDate) {
        if (a.posPersisted && b.posPersisted) {
            return a.posPersisted - b.posPersisted;
        }

        if (a.task && b.task) {
            return a.task.tasklist.id === b.task.tasklist.id
                ? (a.task.tasklist_pos ?? 0) - (b.task.tasklist_pos ?? 0)
                : a.task.tasklist.stage.id === b.task.tasklist.stage.id
                ? new Date(a.task.tasklist.added_at).getTime() -
                  new Date(b.task.tasklist.added_at).getTime()
                : (a.task.tasklist.stage.board_pos ?? 0) - (b.task.tasklist.stage.board_pos ?? 0);
        }

        if (!a.task && b.task) {
            return -1;
        }

        if (a.task && !b.task) {
            return 1;
        }

        return a.ticket.stage.id === b.ticket.stage.id
            ? (a.ticket.stage_pos ?? 0) - (b.ticket.stage_pos ?? 0)
            : a.ticket.board.id === b.ticket.board.id
            ? (a.ticket.stage.board_pos ?? 0) - (b.ticket.stage.board_pos ?? 0)
            : a.ticket.board.display_name < b.ticket.board.display_name
            ? -1
            : 1;
    }

    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
}

function useSections() {
    const { positionsBySectionTypeAndId, tasks, tickets } = useDueDatesView();

    const tasksBySectionType = tasks.reduce<
        Record<ValueOf<typeof SectionType>, SortableSectionRecord[]>
    >(
        (acc, task) => {
            const sectionType = pickSectionType({ dueDate: task.due_date! });

            acc[sectionType].push({
                id: task.id,
                dueDate: task.due_date!,
                posPersisted: positionsBySectionTypeAndId[sectionType][task.id],
                task,
                ticket: task.ticket,
            });

            return acc;
        },
        {
            [SectionType.LATER]: [],
            [SectionType.NEXT_WEEK]: [],
            [SectionType.PAST_DUE]: [],
            [SectionType.THIS_WEEK]: [],
            [SectionType.TODAY]: [],
            [SectionType.TOMORROW]: [],
        }
    );

    const ticketsBySectionType = tickets.reduce<
        Record<ValueOf<typeof SectionType>, SortableSectionRecord[]>
    >(
        (acc, ticket) => {
            const sectionType = pickSectionType({ dueDate: ticket.due_date! });

            acc[sectionType].push({
                id: ticket.id,
                dueDate: ticket.due_date!,
                posPersisted: positionsBySectionTypeAndId[sectionType][ticket.id],
                ticket,
            });

            return acc;
        },
        {
            [SectionType.LATER]: [],
            [SectionType.NEXT_WEEK]: [],
            [SectionType.PAST_DUE]: [],
            [SectionType.THIS_WEEK]: [],
            [SectionType.TODAY]: [],
            [SectionType.TOMORROW]: [],
        }
    );

    const getRecordsForSection = useCallback(
        ({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) => {
            const sortedSectionRecords = ([] as SortableSectionRecord[])
                .concat(tasksBySectionType[sectionType])
                .concat(ticketsBySectionType[sectionType])
                .sort(sortSection);

            const positions = interpolateMissingPositions({
                sortedPositions: sortedSectionRecords.map(ssr => ssr.posPersisted),
            });

            const sectionRecords: SectionRecord[] = sortedSectionRecords.map((ssr, i) => ({
                ...ssr,
                posDisplayed: positions[i],
            }));

            return sectionRecords;
        },
        [tasksBySectionType, ticketsBySectionType]
    );

    return useMemo(() => ({ getRecordsForSection }), [getRecordsForSection]);
}

function usePersistPlanPositions({ sectionType }: { sectionType: ValueOf<typeof SectionType> }) {
    const { getRecordsForSection } = useSections();
    const { setPositionsBySectionTypeAndId } = useDueDatesView();

    useEffect(() => {
        const sectionRecords = getRecordsForSection({ sectionType });

        if (sectionRecords.every(sr => sr.posPersisted)) {
            return;
        }

        setPositionsBySectionTypeAndId(prev => ({
            ...prev,
            [sectionType]: sectionRecords.reduce<Record<string, number>>((acc, sr) => {
                acc[sr.id] = sr.posDisplayed;

                return acc;
            }, {}),
        }));
    }, [getRecordsForSection, sectionType, setPositionsBySectionTypeAndId]);
}

type SectionTitleProps = {
    isEmpty?: boolean;
    sectionType: ValueOf<typeof SectionType>;
};

function SectionTitle({ isEmpty, sectionType }: SectionTitleProps) {
    return (
        <span
            className={classNames(
                styles.sectionTitle,
                sectionType === SectionType.PAST_DUE && styles.sectionDanger,
                isEmpty && styles.sectionIsEmpty
            )}
        >
            {
                {
                    [SectionType.LATER]: "Farther out",
                    [SectionType.NEXT_WEEK]: "Due next week",
                    [SectionType.PAST_DUE]: "Past due",
                    [SectionType.THIS_WEEK]: "Due this week",
                    [SectionType.TODAY]: "Due today",
                    [SectionType.TOMORROW]: "Due tomorrow",
                }[sectionType]
            }
        </span>
    );
}

type SectionProps = {
    sectionType: ValueOf<typeof SectionType>;
    showIfEmpty?: boolean;
};

function Section({ sectionType, showIfEmpty }: SectionProps) {
    usePersistPlanPositions({ sectionType });

    const location = useLocation();
    const { handleTaskCompletionChange, user } = useDueDatesView();
    const { getRecordsForSection } = useSections();
    const { isSectionCollapsed, toggleSectionCollapsed } = useDueDatesView();
    const records = getRecordsForSection({ sectionType });
    const isCollapsed = isSectionCollapsed({ sectionType });
    const isEmpty = !records.length;

    const toggleIsCollapsed = useCallback(() => {
        toggleSectionCollapsed({ sectionType });
    }, [sectionType, toggleSectionCollapsed]);

    if (isEmpty && !showIfEmpty) {
        return null;
    }

    return (
        <TicketList
            isCollapsed={isCollapsed}
            listId={sectionType}
            sectionHeader={
                <TicketListHeader
                    instrumentation={{
                        elementName: "user_view.due_dates.collapse_btn",
                        eventData: { sectionType, isCollapsed },
                    }}
                    isCollapsed={isCollapsed}
                    title={<SectionTitle sectionType={sectionType} isEmpty={isEmpty} />}
                    toggleIsCollapsed={toggleIsCollapsed}
                />
            }
        >
            {records.map(({ task, ticket }, index) => (
                <TicketListRow
                    className={classNames(
                        styles.ticketListRow,
                        task && styles.taskRow,
                        task?.is_complete && styles.isCompleteTask
                    )}
                    key={task ? task.id : ticket.id}
                    index={index}
                    locationState={{
                        from: {
                            location,
                            pageDetails: {
                                appPage: Enums.AppPage.USER,
                                userId: user.id,
                            },
                        },
                    }}
                    ticketRowLayout={
                        task ? (
                            <TicketRowLayout
                                ticketContent={
                                    <>
                                        <TaskRowContentCheckbox
                                            className={styles.ticketRowIcon}
                                            onChangeTaskCompletion={handleTaskCompletionChange}
                                            task={task}
                                        />
                                        <TaskRowContentMainInfo task={task} />
                                        <TaskRowContentAncestry task={task} />
                                        <TaskRowContentActivity />
                                        <TicketRowContentBoard ticket={ticket} />
                                        <TicketRowContentStage ticket={ticket} />
                                        <TicketRowContentPlaceholder classNameKey="progress" />
                                        <TaskRowContentOwnership task={task} />
                                        <TaskRowContentDueDate task={task} />
                                        <DynamicWidthTableCell className={styles.menu} />
                                    </>
                                }
                            />
                        ) : (
                            <TicketRowLayout
                                ticketContent={
                                    <>
                                        <TicketRowContentIcon className={styles.ticketRowIcon} />
                                        <TicketRowContentMainInfo ticket={ticket} />
                                        <TicketRowContentAncestry ticket={ticket} />
                                        <TicketRowContentActivity ticket={ticket} />
                                        <TicketRowContentBoard ticket={ticket} />
                                        <TicketRowContentStage ticket={ticket} />
                                        <TicketRowContentProgress ticket={ticket} />
                                        <TicketRowContentOwnership ticket={ticket} />
                                        <TicketRowContentDueDate ticket={ticket} />
                                        <DynamicWidthTableCell className={styles.menu}>
                                            <CardMenu
                                                className={styles.menuButton}
                                                ticket={ticket}
                                                handleMoveToStartOfStage={null}
                                                handleMoveToEndOfStage={null}
                                            />
                                        </DynamicWidthTableCell>
                                    </>
                                }
                            />
                        )
                    }
                    task={task}
                    ticket={ticket}
                />
            ))}
        </TicketList>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function StatusBanner() {
    const { user } = useDueDatesView();
    const currentUser = useCurrentUser();
    const location = useLocation();
    const { buildSettingsUrl } = useUrlBuilders();

    return (
        <Banner
            className={styles.banner}
            content={
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                    <>
                        Visit{" "}
                        <Link
                            to={{
                                pathname: `${buildSettingsUrl().pathname}/general`,
                                state: {
                                    from: {
                                        location,
                                        pageDetails: {
                                            appPage: Enums.AppPage.USER,
                                            userId: user.id,
                                        },
                                    },
                                },
                            }}
                        >
                            Admin Settings | General
                        </Link>{" "}
                        to enable due dates for your organization.
                    </>
                ) : (
                    "Ask a team admin to enable due dates for your organization."
                )
            }
            warning
        />
    );
}

export function determineNewDueDate({
    draggableRootId,
    toIndex,
    toSectionRecords,
    toSectionType,
}: {
    draggableRootId: string;
    toIndex: number;
    toSectionRecords: Pick<SectionRecord, "id" | "dueDate">[];
    toSectionType: ValueOf<typeof SectionType>;
}) {
    const referenceRecord = toSectionRecords.filter(r => r.id !== draggableRootId)[
        toSectionType === SectionType.PAST_DUE ? toIndex : toIndex - 1
    ];

    if (referenceRecord) {
        return parseDate(referenceRecord.dueDate, "yyyy-MM-dd", new Date());
    }

    switch (toSectionType) {
        case SectionType.PAST_DUE:
            return addDays(startOfToday(), -1);

        case SectionType.TODAY:
            return startOfToday();

        case SectionType.TOMORROW:
            return addDays(startOfToday(), 1);

        case SectionType.THIS_WEEK:
            return addDays(startOfToday(), 2);

        case SectionType.NEXT_WEEK:
            return nextMonday(startOfToday());

        case SectionType.LATER:
            return nextMonday(nextMonday(startOfToday()));

        default:
            throw new Errors.UnexpectedCaseError({ toSectionType });
    }
}

function useGetNewDueDate() {
    const { getRecordsForSection } = useSections();

    const getNewDueDate = useCallback(
        ({
            draggableRootId,
            toIndex,
            toSectionType,
        }: {
            draggableRootId: string;
            toIndex: number;
            toSectionType: ValueOf<typeof SectionType>;
        }) => {
            const toSectionRecords = getRecordsForSection({ sectionType: toSectionType });

            return determineNewDueDate({
                draggableRootId,
                toIndex,
                toSectionRecords,
                toSectionType,
            });
        },
        [getRecordsForSection]
    );

    return { getNewDueDate };
}

function useDragEndHandler() {
    const { setPositionsBySectionTypeAndId } = useDueDatesView();
    const { getRecordsForSection } = useSections();
    const { getNewDueDate } = useGetNewDueDate();
    const { updateTaskDueDate } = useUpdateTaskDueDate();
    const { updateTicketDueDate } = useUpdateTicketDueDate();

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            const { draggableId, destination, source } = result;

            if (
                !destination ||
                (destination.droppableId === source.droppableId &&
                    destination.index === source.index)
            ) {
                return;
            }

            const draggableRootId = dragAndDropEntity.getRootId(draggableId);
            const toSectionType = dragAndDropEntity.getRootId(destination.droppableId) as ValueOf<
                typeof SectionType
            >;
            const toIndex = destination.index;

            const sectionRecords = getRecordsForSection({
                sectionType: toSectionType,
            });

            const toSectionPos = moveToPositionByIndex({
                sortedEntities: sectionRecords,
                posFieldName: "posDisplayed",
                toIndex,
                entityId: draggableRootId,
            });

            setPositionsBySectionTypeAndId(prev => ({
                ...prev,
                [toSectionType]: {
                    ...prev[toSectionType],
                    [draggableRootId]: toSectionPos,
                },
            }));

            const newDueDate = getNewDueDate({
                draggableRootId,
                toSectionType,
                toIndex,
            });

            const draggableEntityType = dragAndDropEntity.getEntityType(draggableId);

            await (draggableEntityType === Enums.DndEntityTypes.TASK
                ? updateTaskDueDate({ taskId: draggableRootId, dueDate: newDueDate })
                : updateTicketDueDate({ ticketId: draggableRootId, dueDate: newDueDate }));
        },
        [
            getNewDueDate,
            getRecordsForSection,
            setPositionsBySectionTypeAndId,
            updateTaskDueDate,
            updateTicketDueDate,
        ]
    );

    return { handleDragEnd };
}

function Content() {
    const { isSectionCollapsed, tickets, user } = useDueDatesView();
    const { handleDragEnd } = useDragEndHandler();

    return (
        <div className={styles.content}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <DynamicWidthTable
                    columnDefinitions={DynamicWidthTableColumnDefinitions}
                    dependencies={[
                        user,
                        tickets,
                        Object.values(SectionType)
                            .map(sectionType => isSectionCollapsed({ sectionType }))
                            .join("|"),
                    ]}
                >
                    <Section sectionType={SectionType.PAST_DUE} />
                    <Section sectionType={SectionType.TODAY} showIfEmpty />
                    <Section sectionType={SectionType.TOMORROW} showIfEmpty />
                    <Section sectionType={SectionType.THIS_WEEK} />
                    <Section sectionType={SectionType.NEXT_WEEK} />
                    <Section sectionType={SectionType.LATER} showIfEmpty />
                </DynamicWidthTable>
            </DragDropContext>
        </div>
    );
}

export type DueDatesViewProps = {
    className?: string;
    userId: number;
};

export function DueDatesViewImpl({
    className,
    userId,
    queryResult,
}: DueDatesViewProps & { queryResult: TQueryResult<DueDatesViewQuery> }) {
    const containerRef = useRef<HTMLDivElement>(null);
    const { setDocumentTitle } = useDocumentTitle();

    useSaveScrollPosition({
        scrollElementRef: containerRef,
        component: "user_view_dates",
        id: userId,
    });

    const userFragment = queryResult.data?.user;
    const user = getFragmentData(fragments.user, userFragment);

    useEffect(() => {
        if (user) {
            setDocumentTitle(user.name);
        }
    }, [user, setDocumentTitle]);

    if (queryResult.loading && !queryResult.data) {
        return null;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    if (!userFragment || !user) {
        return null;
    }

    return (
        <DueDatesViewProvider user={userFragment}>
            <div className={classNames(className, styles.main)} key={userId} ref={containerRef}>
                <Content />
            </div>
        </DueDatesViewProvider>
    );
}

export function DueDatesView(props: DueDatesViewProps) {
    return (
        <ViewQueryLoader
            query={DueDatesView.queries.component}
            variables={{ userId: props.userId }}
        >
            {({ queryResult }) => <DueDatesViewImpl {...props} queryResult={queryResult} />}
        </ViewQueryLoader>
    );
}

DueDatesView.queries = {
    component: gql(/* GraphQL */ `
        query DueDatesView($userId: Int!) {
            user: users_by_pk(id: $userId) {
                id
                ...DueDatesView_user
            }
        }
    `),
};

Queries.register({ component: "DueDatesView", gqlMapByName: DueDatesView.queries });
