import { useCallback } from "react";

import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useRecordLogin() {
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation RecordLogin($currentUserId: Int!, $timeZone: String, $uids: jsonb) {
                update_users_by_pk(
                    pk_columns: { id: $currentUserId }
                    _set: {
                        pending_invite_sent_at: null
                        last_logged_in_at: "now()"
                        timezone: $timeZone
                    }
                    _append: { uids: $uids }
                ) {
                    id
                }
            }
        `)
    );

    const recordLogin = useCallback(
        async (
            {
                currentUserId,
                timeZone,
                uids,
            }: { currentUserId: number; timeZone: string | null; uids: Record<string, boolean> },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    currentUserId,
                    timeZone,
                    uids,
                },
            });
        },
        [mutation]
    );

    return { recordLogin };
}
