import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { CommonEnumValue, CommonEnums } from "c9r-common";

import { AppToaster } from "components/ui/core/AppToaster";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { Log } from "lib/Log";
import { OAuth, useOAuth } from "lib/OAuth";
import { useHistory } from "lib/Routing";
import { useDefaultRedirectUrl } from "lib/Urls";
import { gql } from "lib/graphql/__generated__";

type DiscordStateData = {
    authType: CommonEnumValue<"DiscordAuthType">;
    redirectUri: string;
};

type SlackStateData = {
    redirectUri: string;
};

export const useDiscordIntegration = () => {
    const client = useApolloClient();
    const { history } = useHistory();
    const { gateFeature } = useFeatureFlags();
    const defaultRedirectUrl = useDefaultRedirectUrl();
    const { buildAuthorizeUrl } = useOAuth();

    const authorizeDiscord = useCallback(
        ({ authType }: { authType: CommonEnumValue<"DiscordAuthType"> }) => {
            if (!gateFeature({ feature: Enums.Feature.DISCORD_INTEGRATION })) {
                return;
            }

            window.location.href = buildAuthorizeUrl({
                key: Enums.OAuthKey.DISCORD,
                authType,
            });
        },
        [buildAuthorizeUrl, gateFeature]
    );

    const connectDiscord = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.DISCORD_INTEGRATION })) {
            return;
        }

        const urlSearchParams = new URLSearchParams(history.location.search);
        const code = urlSearchParams.get("code");
        const state = urlSearchParams.get("state");

        const toastError = () => {
            AppToaster.error({
                message: "Something went wrong setting up Discord. Please try again.",
            });
        };

        if (!code) {
            Log.error("Discord setup failed", { reason: "Missing code" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const decodedState = OAuth.validateAndDecodeStateWithKey<DiscordStateData>({
            key: Enums.OAuthKey.DISCORD,
            state,
        });

        if (!decodedState) {
            Log.error("Discord setup failed", { reason: "State mismatch" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const {
            path,
            data: { authType, redirectUri },
        } = decodedState;

        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation ConnectDiscord($code: String!, $redirectUri: String!, $authType: String!) {
                    connect_discord(code: $code, redirect_uri: $redirectUri, auth_type: $authType) {
                        ok
                    }
                }
            `),
            variables: {
                code,
                redirectUri,
                authType,
            },
        });

        if (result.data?.connect_discord.ok) {
            AppToaster.success({
                message:
                    authType === CommonEnums.DiscordAuthType.INTEGRATION
                        ? "The Flat bot has now been added to your server."
                        : "You will start receiving notifications on Discord.",
            });
        } else {
            Log.error("Discord setup failed", { reason: "Backend error" });
            toastError();
        }

        history.replace(path);
    }, [client, defaultRedirectUrl.pathname, gateFeature, history]);

    const disconnectDiscord = useCallback(
        async ({ authType }: { authType: CommonEnumValue<"DiscordAuthType"> }) => {
            if (!gateFeature({ feature: Enums.Feature.DISCORD_INTEGRATION })) {
                return;
            }

            const result = await client.mutate({
                mutation: gql(/* GraphQL */ `
                    mutation DisconnectDiscord($authType: String!) {
                        disconnect_discord(auth_type: $authType) {
                            ok
                        }
                    }
                `),
                variables: { authType },
            });

            if (result.data?.disconnect_discord.ok) {
                AppToaster.success({
                    message: "Discord has been disconnected.",
                });
            } else {
                Log.error("Failed to disconnect Discord");
                AppToaster.error({
                    message: "Something went wrong disconnecting Discord. Please try again.",
                });
            }
        },
        [client, gateFeature]
    );

    return { authorizeDiscord, connectDiscord, disconnectDiscord };
};

export const useGitHubIntegration = () => {
    const client = useApolloClient();
    const { history } = useHistory();
    const { gateFeature } = useFeatureFlags();
    const defaultRedirectUrl = useDefaultRedirectUrl();
    const { buildAuthorizeUrl } = useOAuth();

    const authorizeGitHub = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.GITHUB_INTEGRATION })) {
            return;
        }

        window.location.href = buildAuthorizeUrl({ key: Enums.OAuthKey.GITHUB });
    }, [buildAuthorizeUrl, gateFeature]);

    const connectGitHub = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.GITHUB_INTEGRATION })) {
            return;
        }

        const urlSearchParams = new URLSearchParams(history.location.search);
        const code = urlSearchParams.get("code");
        const state = urlSearchParams.get("state");

        const toastError = (msg?: string) => {
            AppToaster.error({
                message: `Something went wrong setting up GitHub${
                    msg ? ` (${msg})` : ""
                }. Please try again.`,
            });
        };

        if (!code) {
            Log.error("GitHub setup failed", { reason: "Missing code" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const decodedState = OAuth.validateAndDecodeStateWithKey({
            key: Enums.OAuthKey.GITHUB,
            state,
        });

        if (!decodedState) {
            Log.error("GitHub setup failed", { reason: "State mismatch" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const { path } = decodedState;

        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation ConnectGitHubUser($code: String!) {
                    connect_github_user(code: $code) {
                        ok
                    }
                }
            `),
            variables: { code },
        });

        if (result.data?.connect_github_user.ok) {
            AppToaster.success({
                message: "Your profile has been updated with your GitHub username.",
            });
        } else {
            Log.error("GitHub setup failed", { reason: "Backend error" });
            toastError();
        }

        history.replace(path);
    }, [client, defaultRedirectUrl.pathname, gateFeature, history]);

    const disconnectGitHub = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.GITHUB_INTEGRATION })) {
            return;
        }

        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation DisconnectGitHubUser {
                    disconnect_github_user {
                        ok
                    }
                }
            `),
        });

        if (!result.data?.disconnect_github_user.ok) {
            Log.error("Failed to disconnect GitHub");
            AppToaster.error({
                message: "Something went wrong removing your GitHub username. Please try again.",
            });
        }
    }, [client, gateFeature]);

    return { authorizeGitHub, connectGitHub, disconnectGitHub };
};

export const useSlackIntegration = () => {
    const client = useApolloClient();
    const { history } = useHistory();
    const { gateFeature } = useFeatureFlags();
    const defaultRedirectUrl = useDefaultRedirectUrl();
    const { buildAuthorizeUrl } = useOAuth();

    const authorizeSlack = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.SLACK_INTEGRATION })) {
            return;
        }

        window.location.href = buildAuthorizeUrl({ key: Enums.OAuthKey.SLACK });
    }, [buildAuthorizeUrl, gateFeature]);

    const connectSlack = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.SLACK_INTEGRATION })) {
            return;
        }

        const urlSearchParams = new URLSearchParams(history.location.search);
        const code = urlSearchParams.get("code");
        const state = urlSearchParams.get("state");
        const error = urlSearchParams.get("error");

        const toastError = (msg?: string) => {
            AppToaster.error({
                message: `Something went wrong setting up Slack${
                    msg ? ` (${msg})` : ""
                }. Please try again.`,
            });
        };

        if (error) {
            Log.error("Slack setup failed", { reason: error });
            toastError(error);
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        if (!code) {
            Log.error("Slack setup failed", { reason: "Missing code" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const decodedState = OAuth.validateAndDecodeStateWithKey<SlackStateData>({
            key: Enums.OAuthKey.SLACK,
            state,
        });

        if (!decodedState) {
            Log.error("Slack setup failed", { reason: "State mismatch" });
            toastError();
            history.replace(defaultRedirectUrl.pathname);
            return;
        }

        const {
            path,
            data: { redirectUri },
        } = decodedState;

        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation ConnectSlack($code: String!, $redirectUri: String!) {
                    connect_slack(code: $code, redirect_uri: $redirectUri) {
                        ok
                    }
                }
            `),
            variables: { code, redirectUri },
        });

        if (result.data?.connect_slack.ok) {
            AppToaster.success({
                message: "You will start receiving notifications on Slack.",
            });
        } else {
            Log.error("Slack setup failed", { reason: "Backend error" });
            toastError();
        }

        history.replace(path);
    }, [client, defaultRedirectUrl.pathname, gateFeature, history]);

    const disconnectSlack = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.SLACK_INTEGRATION })) {
            return;
        }

        const result = await client.mutate({
            mutation: gql(/* GraphQL */ `
                mutation DisconnectSlack {
                    disconnect_slack {
                        ok
                    }
                }
            `),
        });

        if (result.data?.disconnect_slack.ok) {
            AppToaster.success({
                message: "Slack has been disconnected.",
            });
        } else {
            Log.error("Failed to disconnect Slack");
            AppToaster.error({
                message: "Something went wrong disconnecting Slack. Please try again.",
            });
        }
    }, [client, gateFeature]);

    return { authorizeSlack, connectSlack, disconnectSlack };
};
