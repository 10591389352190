import React from "react";

import { CommonEnums, sortStages } from "c9r-common";

import { Icon } from "components/ui/core/Icon";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { InstrumentationEvent } from "lib/Instrumentation";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { BoardAndStagesMenuItem_orgFragment } from "lib/graphql/__generated__/graphql";

import { useBoardAndStagesMenuContext } from "./BoardAndStagesMenuContext";
import styles from "./BoardAndStagesMenuItem.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment BoardAndStagesMenuItem_org on orgs {
            id
            is_multi_board

            boards(where: { archived_at: { _is_null: true } }) {
                id
                access_type
                display_name

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    display_name
                    board_pos
                    role
                }
            }
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment BoardAndStagesMenuItem_ticket on tickets {
            id
            stage_id
        }
    `),
};

type BoardMenuItemProps = {
    board: BoardAndStagesMenuItem_orgFragment["boards"][number];
    children: React.ReactNode;
    isMultiBoard?: boolean;
};

function BoardMenuItem({ board, children, isMultiBoard }: BoardMenuItemProps) {
    const text = (
        <div className={styles.boardMenuItemText}>
            <span className={styles.boardDisplayName}>{board.display_name}</span>
            {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                <Icon icon="lock" iconSet="c9r" iconSize={10.5} strokeWidthAbsolute={1.5} />
            ) : null}
        </div>
    );

    return (
        <>
            {isMultiBoard ? (
                <MenuItem text={text} instrumentation={null}>
                    {children}
                </MenuItem>
            ) : (
                children
            )}
        </>
    );
}

type StageMenuItemProps = {
    stage: { display_name: string };
} & Pick<MenuItemProps, "instrumentation" | "onClick">;

function StageMenuItem({ stage, instrumentation, onClick }: StageMenuItemProps) {
    return (
        <MenuItem text={stage.display_name} instrumentation={instrumentation} onClick={onClick} />
    );
}

export type BoardAndStagesSubMenusProps = {
    org: FragmentType<typeof fragments.org>;
    excludedStageIds?: string[];
    instrumentation: Omit<InstrumentationEvent, "eventType"> | null;
    onSelect: ({ stageId, boardId }: { stageId: string; boardId: string }) => void;
};

function BoardAndStagesSubMenus({
    org: _orgFragment,
    excludedStageIds = [],
    instrumentation,
    onSelect,
}: BoardAndStagesSubMenusProps) {
    const org = getFragmentData(fragments.org, _orgFragment);

    return (
        <>
            {org.boards
                .concat()
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map(board => (
                    <BoardMenuItem key={board.id} isMultiBoard={!!org.is_multi_board} board={board}>
                        {board.stages
                            .filter(s => !excludedStageIds.includes(s.id))
                            .sort(sortStages())
                            .map(stage => (
                                <StageMenuItem
                                    key={stage.id}
                                    stage={stage}
                                    instrumentation={
                                        instrumentation
                                            ? {
                                                  ...instrumentation,
                                                  eventData: {
                                                      ...instrumentation.eventData,
                                                      stageId: stage.id,
                                                  },
                                              }
                                            : null
                                    }
                                    onClick={() =>
                                        onSelect({ stageId: stage.id, boardId: board.id })
                                    }
                                />
                            ))}
                    </BoardMenuItem>
                ))}
        </>
    );
}

export type BoardAndStagesMenuItemProps = {
    icon: React.ReactNode;
    text: React.ReactNode;
    disabled?: boolean;
    excludedStageIds?: string[];
    instrumentation: Omit<InstrumentationEvent, "eventType"> | null;
    onSelect: ({ stageId, boardId }: { stageId: string; boardId: string }) => void;
};

export function BoardAndStagesMenuItem({
    icon,
    text,
    disabled,
    excludedStageIds = [],
    instrumentation,
    onSelect,
}: BoardAndStagesMenuItemProps) {
    const { org } = useBoardAndStagesMenuContext();

    return (
        <MenuItem icon={icon} text={text} disabled={disabled} instrumentation={instrumentation}>
            <BoardAndStagesSubMenus
                org={org}
                excludedStageIds={excludedStageIds}
                instrumentation={instrumentation}
                onSelect={onSelect}
            />
        </MenuItem>
    );
}
