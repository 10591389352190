import React, { useCallback, useEffect, useRef, useState } from "react";

import { TLabel } from "c9r-common";
import classNames from "classnames";
import stringify from "fast-json-stable-stringify";
import { useDebouncedCallback } from "use-debounce";

import { Label } from "components/shared/Label";
import { UserMenuItemText } from "components/ui/common/UserMenuItem";
import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { Suggest } from "components/ui/core/Suggest";
import { TextInputGroup } from "components/ui/core/TextInputGroup";
import { Tooltip } from "components/ui/core/Tooltip";
import { Enums } from "lib/Enums";
import {
    isLabelMatchingFilterTerms,
    isUserMatchingFilterTerms,
    parseFilterExpression,
} from "lib/Filter";
import { useToggle } from "lib/Hooks";
import { useHotkey } from "lib/Hotkeys";
import { useInstrumentation } from "lib/Instrumentation";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

import styles from "./BoardFilter.module.scss";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment BoardFilter_board on boards {
            id

            authorized_users {
                user {
                    id
                    name

                    ...Avatar_user
                    ...UserFilter_user
                    ...UserMenuItem_user
                }
            }

            all_tickets: tickets {
                id
                archived_at
                trashed_at

                label_attachments {
                    color
                    text
                }
            }
        }
    `),
};

const recordEventDebounceIntervalMs = 600;

export type BoardFilterProps = {
    className?: string;
    board?: FragmentType<typeof fragments.board>;
    space?: "BOARD" | "ARCHIVE" | "TRASH";
    filterExpression?: string | null;
    onChange: (filterExpression: string) => void;
    matchCount?: number;
    debounceIntervalMs?: number;
    maxWait?: number;
};

export function BoardFilter({
    className,
    board: _boardFragment,
    space,
    filterExpression,
    onChange,
    matchCount,
    debounceIntervalMs = 30,
    maxWait = 75,
}: BoardFilterProps) {
    const [filter, setFilter] = useState(filterExpression || "");
    const [isExpanded, setIsExpanded] = useState(!!filterExpression);
    const [isFocused, setIsFocused] = useState(false);
    const filterRef = useRef(filter);
    const inputRef = useRef<HTMLInputElement>(null);
    const { recordEvent } = useInstrumentation();

    const onChangeDebounced = useDebouncedCallback(v => onChange(v), debounceIntervalMs, {
        maxWait,
    }).callback;

    const expandAndFocusFilter = useCallback(() => {
        setIsExpanded(true);

        // Use setImmediate since the input isn't mounted until after the isExpanded flag is set.
        // Don't use autoFocus on the input, because then it would focus when a user navigates
        // to a board with a filter.
        setImmediate(() => {
            inputRef.current?.focus();
        });
    }, []);

    useHotkey("F", () => expandAndFocusFilter(), [expandAndFocusFilter]);

    const recordFilterEvent = useCallback(
        (f: string) => {
            if (!f) {
                return;
            }

            void recordEvent({
                eventType: Enums.InstrumentationEvent.BOARD_FILTER,
                eventData: { filter: f },
                dedupeKey: f,
            });
        },
        [recordEvent]
    );

    const recordFilterEventDebounced = useDebouncedCallback(
        recordFilterEvent,
        recordEventDebounceIntervalMs
    ).callback;

    // Keep internal state up-to-date.
    useEffect(() => {
        setFilter(filterExpression || "");
    }, [filterExpression]);

    // Ensure filter box is visible only if (a) there's a filter, or (b) it's focused.
    useEffect(() => {
        setIsExpanded(!!(filter || isFocused));
    }, [filter, isFocused]);

    // Record filter once it's quiet.
    useEffect(() => {
        recordFilterEventDebounced(filter);
    }, [filter, recordFilterEventDebounced]);

    // Track current filter so we can easily record it on unmount.
    useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    // Record final filter on unmount.
    useEffect(() => {
        return () => {
            recordFilterEvent(filterRef.current);
        };
    }, [recordFilterEvent]);

    const clearFilter = useCallback(() => {
        recordFilterEvent(filter);
        setFilter("");
        onChangeDebounced("");
    }, [filter, onChangeDebounced, recordFilterEvent]);

    const closeFilter = useCallback(() => {
        clearFilter();
        inputRef.current?.blur();
    }, [clearFilter]);

    const board = getFragmentData(fragments.board, _boardFragment);
    const users = board?.authorized_users.map(au => au.user).filter(isDefined) || [];

    const labelAlphabeticalCompare = (a: TLabel, b: TLabel) =>
        a.text.localeCompare(b.text) || a.color.localeCompare(b.color);

    const labelAttachments =
        board?.all_tickets
            .filter(ticket => {
                switch (space) {
                    case "BOARD": {
                        return !ticket.archived_at && !ticket.trashed_at;
                    }

                    case "ARCHIVE": {
                        return ticket.archived_at && !ticket.trashed_at;
                    }

                    case "TRASH": {
                        return !ticket.archived_at && ticket.trashed_at;
                    }

                    default: {
                        return false;
                    }
                }
            })
            .map(ticket => ticket.label_attachments)
            .flat() || [];

    const labelsByPopularity = Object.entries(
        labelAttachments.reduce((attachmentCount, label) => {
            const key = stringify(label);

            return {
                ...attachmentCount,
                [key]: (attachmentCount[key] ?? 0) + 1,
            };
        }, {} as { [index: string]: number })
    )
        .sort(
            ([keyA, attachmentCountA], [keyB, attachmentCountB]) =>
                attachmentCountB - attachmentCountA ||
                labelAlphabeticalCompare(JSON.parse(keyA), JSON.parse(keyB))
        )
        .map(([key, _attachmentCount]) => JSON.parse(key));

    const SuggestItemType = {
        LABEL: "LABEL",
        USER: "USER",
    } as const;

    type SuggestItem =
        | { type: typeof SuggestItemType["LABEL"]; data: typeof labelsByPopularity[number] }
        | {
              type: typeof SuggestItemType["USER"];
              data: typeof users[number];
          };

    const MAX_SUGGEST_LABELS = 5;
    const MAX_SUGGEST_USERS = 5;

    const filterTerms = filterExpression ? parseFilterExpression({ filterExpression }) : [];

    const suggestLabels = labelsByPopularity
        .filter(label => isLabelMatchingFilterTerms({ label, filterTerms }))
        .slice(0, MAX_SUGGEST_LABELS)
        .sort(labelAlphabeticalCompare)
        .map(label => ({ type: SuggestItemType.LABEL, data: label }));

    const suggestUsers = users
        .filter(user => isUserMatchingFilterTerms({ user, filterTerms }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(user => ({ type: SuggestItemType.USER, data: user }))
        .slice(0, MAX_SUGGEST_USERS);

    const suggestItems = ([] as SuggestItem[]).concat(suggestLabels).concat(suggestUsers);

    const dropdown = useToggle();

    useEffect(() => {
        if (isExpanded && isFocused && suggestItems.length) {
            !dropdown.isOpen && dropdown.open();
        } else {
            dropdown.isOpen && dropdown.close();
        }
    }, [dropdown, isExpanded, isFocused, suggestItems.length]);

    if (!isExpanded) {
        return (
            <Tooltip
                className={styles.boardStyleControlTooltipWrapper}
                content={
                    <span>
                        Filter <Hotkey text="F" />
                    </span>
                }
                openOnTargetFocus={false}
                placement="bottom"
                small
            >
                <BorderButton
                    data-cy="board-filter-open-btn"
                    minimal
                    tighterer
                    square
                    flushVertical
                    instrumentation={{ elementName: "board.board_header.filter_btn" }}
                    content={
                        <Icon
                            className={styles.filterIcon}
                            icon="filter"
                            iconSet="lucide"
                            iconSize={20}
                            strokeWidth={1}
                        />
                    }
                    onClick={expandAndFocusFilter}
                />
            </Tooltip>
        );
    }

    return (
        <Suggest
            popoverProps={{
                autoFocus: false,
                enforceFocus: false,
                canEscapeKeyClose: false, // Custom escape handling below.
                className: styles.suggestTarget,
                isOpen: dropdown.isOpen,
            }}
            itemListRenderer={({ renderItem }) => (
                <>
                    {suggestLabels.map(item => renderItem({ item }))}
                    {suggestLabels.length && suggestUsers.length ? <MenuDivider /> : null}
                    {suggestUsers.map(item => renderItem({ item }))}
                </>
            )}
            items={suggestItems}
            menuItemClassName={styles.suggestMenuItem}
            menuItemTextRenderer={item => {
                switch (item.type) {
                    case SuggestItemType.LABEL: {
                        const label = item.data;

                        return <Label color={label.color} text={label.text} />;
                    }

                    case SuggestItemType.USER: {
                        const user = item.data;

                        return <UserMenuItemText showAvatar user={user} />;
                    }

                    default:
                        return null;
                }
            }}
            menuProps={{
                // If the filter input is focused then prevent (default) blur on mouse down.
                onMouseDown: e => {
                    if (isFocused) {
                        e.preventDefault();
                    }
                },
            }}
            onSelect={item => {
                let newFilter: string;

                switch (item.type) {
                    case SuggestItemType.LABEL: {
                        const label = item.data;
                        newFilter = `label:${label.text} `;

                        break;
                    }

                    case SuggestItemType.USER: {
                        const user = item.data;
                        newFilter = `owner:${user.name} `;

                        break;
                    }
                }

                setFilter(newFilter);
                onChangeDebounced(newFilter);

                dropdown.close();

                inputRef.current?.focus();
            }}
        >
            <span
                className={classNames(
                    className,
                    styles.boardFilter,
                    isFocused && styles.focused,
                    filter && !isFocused && styles.populatedAndNotFocused
                )}
            >
                <TextInputGroup
                    className={styles.inputGroup}
                    inputRef={inputRef}
                    placeholder="Filter by title, labels, owner..."
                    value={filter}
                    onChange={e => {
                        // In case the user cleared the filter by hand with something like Ctrl+A Delete,
                        // record that too.
                        if (filter.length > 1 && !e.target.value) {
                            recordFilterEvent(filter);
                        }

                        setFilter(e.target.value);
                        onChangeDebounced(e.target.value);
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    onKeyDown={e => {
                        if (e.key === "Escape") {
                            e.stopPropagation();

                            if (filter) {
                                clearFilter();
                            } else {
                                closeFilter();
                            }
                        }
                    }}
                    leftElement={
                        <Icon
                            className={styles.filterIcon}
                            icon="filter"
                            iconSet="lucide"
                            iconSize={14}
                            strokeWidth={1}
                        />
                    }
                    rightElement={
                        <div className={styles.rightElement}>
                            {filter ? (
                                <>
                                    {isDefined(matchCount) ? (
                                        <div className={styles.matchCount}>{`${matchCount} ${
                                            matchCount === 1 ? "match" : "matches"
                                        }`}</div>
                                    ) : null}
                                    <div className={styles.closeButtonWrapper}>
                                        <BorderButton
                                            data-cy="board-filter-close-btn"
                                            className={styles.closeButton}
                                            content={
                                                <Icon icon="x" iconSet="lucide" iconSize={18} />
                                            }
                                            minimal
                                            // If the filter input is focused then prevent (default) blur on mouse down.
                                            onMouseDown={e => {
                                                if (isFocused) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onClick={() => {
                                                clearFilter();
                                            }}
                                            instrumentation={{
                                                elementName: "filter.close_btn",
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className={styles.blankSpacer}>
                                    <Icon icon="blank" iconSet="c9r" iconSize={18} />
                                </div>
                            )}
                        </div>
                    }
                />
            </span>
        </Suggest>
    );
}
