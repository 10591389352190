import React from "react";

import { CommonEnumValue, CommonEnums, LabelColors } from "c9r-common";
import classNames from "classnames";

import styles from "./Label.module.scss";

export type LabelProps = {
    className?: string;
    color: string;
    text: string;
    small?: boolean;
    inheritColor?: boolean;
    onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
};

export function Label({ className, color, text, small, inheritColor, onClick }: LabelProps) {
    return (
        <LabelDisplay
            className={classNames(className, inheritColor && styles.inheritColor)}
            color={color as CommonEnumValue<"LabelColor">}
            small={small}
            text={text}
            onClick={onClick}
        />
    );
}

export type LabelDisplayProps = {
    className?: string;
    color: CommonEnumValue<"LabelColor">;
    small?: boolean;
    text: string;
    onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
};

export function LabelDisplay({ className, color, small, text, onClick }: LabelDisplayProps) {
    const { bgColor } = LabelColors[color] ?? LabelColors[CommonEnums.LabelColor.GREY];

    return (
        <span
            className={classNames(className, styles.label, small && styles.small)}
            onClick={onClick}
        >
            <span
                className={styles.tag}
                style={{
                    background: bgColor,
                }}
            />
            {text}
        </span>
    );
}
