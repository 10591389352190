import React from "react";

import { useRecoilValue } from "recoil";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";

const dialogState = dialogStateFamily<null>("ConnectZapierDialog");

export function useConnectZapierDialog() {
    return useDialogSingleton(dialogState);
}

export function ConnectZapierDialog() {
    const { isOpen } = useRecoilValue(dialogState);
    const dialog = useConnectZapierDialog();

    return (
        <Dialog isOpen={isOpen} onClose={dialog.close}>
            <Dialog.Body>
                <p>
                    To connect Flat to your Zapier account, <SupportMailto text="contact us" /> and
                    we'll get you set up.
                </p>
            </Dialog.Body>
        </Dialog>
    );
}
