import { CustomError, P } from "c9r-common";
import { ReadTransaction, WriteTransaction } from "replicache";

import { EntryKeys, EntryKeysType } from "./entries/EntryKeys";
import {
    BoardEntry,
    CommentEntry,
    Entry,
    MergeRequestEntry,
    StageEntry,
    TaskEntry,
    TasklistEntry,
    ThreadEntry,
    TicketAttachmentEntry,
    TicketEntry,
    TicketHistoryEventEntry,
} from "./entries/EntryTypes";

class DuplicateEntryError extends CustomError<{ key: string }> {
    constructor({ key }: { key: string }) {
        super("Duplicate replicache entry");
        this.data = { key };
    }
}

class MissingEntryError extends CustomError<{ key?: string; id?: string }> {
    constructor({ key, id }: { key?: string; id?: string }) {
        super("Missing replicache entry");
        this.data = { key, id };
    }
}

export interface IReadApiEntry<T> {
    get(args: { key: string }): Promise<T | null>;
    getOrThrow(args: { key: string }): Promise<T>;
    getById(args: { id: string }): Promise<T | null>;
    getByIdOrThrow(args: { id: string }): Promise<T>;
    lookupKeyById(args: { id: string }): Promise<string | null>;
    findByIndex(args: { indexName: string; value: string }): Promise<T[]>;
    findByPrefix(args: { prefix: string }): Promise<T[]>;
    findAll(): Promise<T[]>;
}

class ReadApiEntry<T extends Entry> implements IReadApiEntry<T> {
    cacheScanResults: boolean;
    scanCache: Map<string, Promise<T[]>>;
    txn: ReadTransaction;

    constructor({
        cacheScanResults = true,
        txn,
    }: {
        cacheScanResults?: boolean;
        txn: ReadTransaction;
    }) {
        this.cacheScanResults = cacheScanResults;
        this.txn = txn;
        this.scanCache = new Map<string, Promise<T[]>>();
    }

    async get({ key }: { key: string }) {
        const entry = (await this.txn.get(key)) as T | undefined;

        return entry ?? null;
    }

    async getOrThrow({ key }: { key: string }) {
        const entry = await this.get({ key });

        if (!entry) {
            throw new MissingEntryError({ key });
        }

        return entry;
    }

    async getById({ id }: { id: string }) {
        const scanResult = await this.txn
            .scan({
                indexName: "entriesById",
                limit: 1,
                start: {
                    key: [id],
                },
            })
            .values();

        const { value: entry } = ((await scanResult.next()) ?? { value: undefined }) as {
            value?: T | undefined;
        };

        if (!entry || entry.id !== id) {
            return null;
        }

        return entry;
    }

    async getByIdOrThrow({ id }: { id: string }) {
        const entry = await this.getById({ id });

        if (!entry) {
            throw new MissingEntryError({ id });
        }

        return entry;
    }

    async lookupKeyById({ id }: { id: string }) {
        const scanResult = await this.txn.scan({
            indexName: "entriesById",
            limit: 1,
            start: {
                key: [id],
            },
        });

        const [entryId, key] = (await scanResult.keys().next())?.value ?? [];

        if (!key || typeof key !== "string" || entryId !== id) {
            return null;
        }

        return key as string;
    }

    async findByIndex({ indexName, value }: { indexName: string; value: string }) {
        const cacheKey = `${indexName}:${value}`;

        if (this.scanCache.has(cacheKey)) {
            return this.scanCache.get(cacheKey)!;
        }

        const deferred = P.defer<T[]>();

        if (this.cacheScanResults) {
            this.scanCache.set(cacheKey, deferred.promise);
        }

        const entries = (await this.txn.scan({ indexName, prefix: value }).toArray()) as T[];

        // @ts-ignore
        deferred.resolve(entries);

        return entries;
    }

    async findByPrefix({ prefix }: { prefix: string }) {
        const cacheKey = prefix;

        if (this.scanCache.has(cacheKey)) {
            return this.scanCache.get(cacheKey)!;
        }

        const deferred = P.defer<T[]>();

        if (this.cacheScanResults) {
            this.scanCache.set(cacheKey, deferred.promise);
        }

        const entries = (await this.txn.scan({ prefix }).toArray()) as T[];

        // @ts-ignore
        deferred.resolve(entries);

        return entries;
    }

    async findAll() {
        return this.findByPrefix({ prefix: "" });
    }
}

class ReadApiEntryWithType<T extends Entry, KT extends EntryKeysType> extends ReadApiEntry<T>
    implements IReadApiEntry<T> {
    type: KT;

    constructor({
        cacheScanResults,
        type,
        txn,
    }: {
        cacheScanResults?: boolean;
        type: KT;
        txn: ReadTransaction;
    }) {
        super({ cacheScanResults, txn });

        this.type = type;
    }

    async findAll() {
        return this.findByPrefix({ prefix: EntryKeys.buildPrefix({ type: this.type }) });
    }
}

class ReadApiBoards extends ReadApiEntryWithType<BoardEntry, typeof EntryKeys.Type.BOARD> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.BOARD });
    }
}

class ReadApiComments extends ReadApiEntryWithType<CommentEntry, typeof EntryKeys.Type.COMMENT> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.COMMENT });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

class ReadApiMergeRequests extends ReadApiEntryWithType<
    MergeRequestEntry,
    typeof EntryKeys.Type.MERGE_REQUEST
> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.MERGE_REQUEST });
    }
}

class ReadApiStages extends ReadApiEntryWithType<StageEntry, typeof EntryKeys.Type.STAGE> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.STAGE });
    }

    async findByBoardId({ boardId }: { boardId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { boardId } }),
        });
    }
}

class ReadApiTasklists extends ReadApiEntryWithType<TasklistEntry, typeof EntryKeys.Type.TASKLIST> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.TASKLIST });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

class ReadApiTasks extends ReadApiEntryWithType<TaskEntry, typeof EntryKeys.Type.TASK> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.TASK });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

class ReadApiThreads extends ReadApiEntryWithType<ThreadEntry, typeof EntryKeys.Type.THREAD> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.THREAD });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

class ReadApiTickets extends ReadApiEntryWithType<TicketEntry, typeof EntryKeys.Type.TICKET> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET });
    }

    async findByBoardId({ boardId }: { boardId: string }) {
        return (await this.findAll()).filter(ticket => ticket.board_id === boardId);
    }

    async findByStageId({ stageId }: { stageId: string }) {
        return (await this.findAll()).filter(ticket => ticket.stage_id === stageId);
    }
}

class ReadApiTicketAttachments extends ReadApiEntryWithType<
    TicketAttachmentEntry,
    typeof EntryKeys.Type.TICKET_ATTACHMENT
> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET_ATTACHMENT });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

class ReadApiTicketHistoryEvents extends ReadApiEntryWithType<
    TicketHistoryEventEntry,
    typeof EntryKeys.Type.TICKET_HISTORY_EVENT
> {
    constructor({ txn }: { txn: ReadTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET_HISTORY_EVENT });
    }

    async findByTicketId({ ticketId }: { ticketId: string }) {
        return this.findByPrefix({
            prefix: EntryKeys.buildPrefix({ type: this.type, ids: { ticketId } }),
        });
    }
}

export interface IReadApi {
    entries: IReadApiEntry<Entry>;
}

export class ReadApi implements IReadApi {
    readonly entries: ReadApiEntry<Entry>;
    readonly boards: ReadApiBoards;
    readonly comments: ReadApiComments;
    readonly mergeRequests: ReadApiMergeRequests;
    readonly stages: ReadApiStages;
    readonly tasklists: ReadApiTasklists;
    readonly tasks: ReadApiTasks;
    readonly threads: ReadApiThreads;
    readonly tickets: ReadApiTickets;
    readonly ticketAttachments: ReadApiTicketAttachments;
    readonly ticketHistoryEvents: ReadApiTicketHistoryEvents;

    constructor({ txn }: { txn: ReadTransaction }) {
        this.entries = new ReadApiEntry({ txn });
        this.boards = new ReadApiBoards({ txn });
        this.comments = new ReadApiComments({ txn });
        this.mergeRequests = new ReadApiMergeRequests({ txn });
        this.stages = new ReadApiStages({ txn });
        this.tasklists = new ReadApiTasklists({ txn });
        this.tasks = new ReadApiTasks({ txn });
        this.threads = new ReadApiThreads({ txn });
        this.tickets = new ReadApiTickets({ txn });
        this.ticketAttachments = new ReadApiTicketAttachments({ txn });
        this.ticketHistoryEvents = new ReadApiTicketHistoryEvents({ txn });
    }
}

export interface IWriteApiEntry<T> extends IReadApiEntry<T> {
    insert(args: { key: string; entry: T }): Promise<void>;
    insertOrThrow(args: { key: string; entry: T }): Promise<void>;
    shallowUpdate(args: {
        key: string;
        fields: Partial<Omit<T, "__typename" | "id">>;
    }): Promise<void>;
    shallowUpdateById(args: {
        id: string;
        fields: Partial<Omit<T, "__typename" | "id">>;
    }): Promise<void>;
    replace(args: { key: string; entry: T }): Promise<void>;
    replaceById(args: { id: string; entry: T }): Promise<void>;
}

class WriteApiEntry<T extends Entry> extends ReadApiEntry<T> implements IWriteApiEntry<T> {
    txn: WriteTransaction;

    constructor({
        cacheScanResults = true,
        txn,
    }: {
        /**
         * By default, invoking a "find" method causes a scan, the result of which is cached
         * so that subsequent calls don't do another scan. This is usually the desired behavior
         * for performance.
         *
         * However, it means that the scan results within a transaction do not update after
         * writes. If that is needed, set cacheScanResults to false.
         */
        cacheScanResults?: boolean;
        txn: WriteTransaction;
    }) {
        super({ cacheScanResults, txn });

        this.txn = txn;
    }

    async insert({ key, entry }: { key: string; entry: T }) {
        if (await this.txn.has(key)) {
            return;
        }

        await this.txn.put(key, entry);
    }

    async insertOrThrow({ key, entry }: { key: string; entry: T }) {
        if (await this.txn.has(key)) {
            throw new DuplicateEntryError({ key });
        }

        await this.txn.put(key, entry);
    }

    async shallowUpdate({
        key,
        fields,
    }: {
        key: string;
        fields: Partial<Omit<T, "__typename" | "id">>;
    }) {
        const entry = await this.getOrThrow({ key });
        const definedFields = {
            ...Object.fromEntries(
                Object.entries(fields).filter(([, value]) => value !== undefined)
            ),
        };

        await this.txn.put(key, { ...entry, ...definedFields });
    }

    async shallowUpdateById({
        id,
        fields,
    }: {
        id: string;
        fields: Partial<Omit<T, "__typename" | "id">>;
    }) {
        const key = await this.lookupKeyById({ id });

        if (!key) {
            return;
        }

        await this.shallowUpdate({ key, fields });
    }

    async replace({ key, entry }: { key: string; entry: T }) {
        if (!(await this.txn.has(key))) {
            return;
        }

        await this.txn.put(key, entry);
    }

    async replaceById({ id, entry }: { id: string; entry: T }) {
        const key = await this.lookupKeyById({ id });

        if (!key) {
            return;
        }

        await this.replace({ key, entry });
    }
}

class WriteApiEntryWithType<T extends Entry, KT extends EntryKeysType> extends WriteApiEntry<T>
    implements IWriteApiEntry<T> {
    type: KT;

    constructor({
        cacheScanResults,
        type,
        txn,
    }: {
        cacheScanResults?: boolean;
        type: KT;
        txn: WriteTransaction;
    }) {
        super({ cacheScanResults, txn });

        this.type = type;
    }

    async findAll() {
        return this.findByPrefix({ prefix: EntryKeys.buildPrefix({ type: this.type }) });
    }
}

class WriteApiBoards extends WriteApiEntryWithType<BoardEntry, typeof EntryKeys.Type.BOARD> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.BOARD });
    }
}

class WriteApiComments extends WriteApiEntryWithType<CommentEntry, typeof EntryKeys.Type.COMMENT> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.COMMENT });
    }
}

class WriteApiMergeRequests extends WriteApiEntryWithType<
    MergeRequestEntry,
    typeof EntryKeys.Type.MERGE_REQUEST
> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.MERGE_REQUEST });
    }
}

class WriteApiStages extends WriteApiEntryWithType<StageEntry, typeof EntryKeys.Type.STAGE> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.STAGE });
    }
}

class WriteApiTasks extends WriteApiEntryWithType<TaskEntry, typeof EntryKeys.Type.TASK> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.TASK });
    }
}

class WriteApiTasklists extends WriteApiEntryWithType<
    TasklistEntry,
    typeof EntryKeys.Type.TASKLIST
> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.TASKLIST });
    }
}

class WriteApiThreads extends WriteApiEntryWithType<ThreadEntry, typeof EntryKeys.Type.THREAD> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.THREAD });
    }
}

class WriteApiTickets extends WriteApiEntryWithType<TicketEntry, typeof EntryKeys.Type.TICKET> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET });
    }
}

class WriteApiTicketAttachments extends WriteApiEntryWithType<
    TicketAttachmentEntry,
    typeof EntryKeys.Type.TICKET_ATTACHMENT
> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET_ATTACHMENT });
    }
}

class WriteApiTicketHistoryEvents extends WriteApiEntryWithType<
    TicketHistoryEventEntry,
    typeof EntryKeys.Type.TICKET_HISTORY_EVENT
> {
    constructor({ txn }: { txn: WriteTransaction }) {
        super({ txn, type: EntryKeys.Type.TICKET_HISTORY_EVENT });
    }
}

export interface IWriteApi {
    boards: IWriteApiEntry<BoardEntry>;
    comments: IWriteApiEntry<CommentEntry>;
    mergeRequests: IWriteApiEntry<MergeRequestEntry>;
    stages: IWriteApiEntry<StageEntry>;
    tasklists: IWriteApiEntry<TasklistEntry>;
    tasks: IWriteApiEntry<TaskEntry>;
    threads: IWriteApiEntry<ThreadEntry>;
    tickets: IWriteApiEntry<TicketEntry>;
    ticketAttachments: IWriteApiEntry<TicketAttachmentEntry>;
    ticketHistoryEvents: IWriteApiEntry<TicketHistoryEventEntry>;
}

export class WriteApi implements IWriteApi {
    readonly boards: WriteApiBoards;
    readonly comments: WriteApiComments;
    readonly mergeRequests: WriteApiMergeRequests;
    readonly stages: WriteApiStages;
    readonly tasklists: WriteApiTasklists;
    readonly tasks: WriteApiTasks;
    readonly threads: WriteApiThreads;
    readonly tickets: WriteApiTickets;
    readonly ticketAttachments: WriteApiTicketAttachments;
    readonly ticketHistoryEvents: WriteApiTicketHistoryEvents;

    constructor({ txn }: { txn: WriteTransaction }) {
        this.boards = new WriteApiBoards({ txn });
        this.comments = new WriteApiComments({ txn });
        this.mergeRequests = new WriteApiMergeRequests({ txn });
        this.stages = new WriteApiStages({ txn });
        this.tasklists = new WriteApiTasklists({ txn });
        this.tasks = new WriteApiTasks({ txn });
        this.threads = new WriteApiThreads({ txn });
        this.tickets = new WriteApiTickets({ txn });
        this.ticketAttachments = new WriteApiTicketAttachments({ txn });
        this.ticketHistoryEvents = new WriteApiTicketHistoryEvents({ txn });
    }
}
