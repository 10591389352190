import React, { useCallback, useRef } from "react";

import { useDebouncedCallback } from "use-debounce";

import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";
import { isKeyboardClick } from "lib/Keyboard";

type ButtonInstrumentationEvent = Omit<InstrumentationEvent, "eventType">;

function useButtonClickHandler<TElement extends HTMLAnchorElement | HTMLButtonElement>({
    debounceIntervalMs,
    instrumentation,
    onClick,
}: {
    debounceIntervalMs?: number;
    instrumentation: ButtonInstrumentationEvent | null;
    onClick?: React.MouseEventHandler<TElement>;
}) {
    const { recordCallback } = useInstrumentation();

    const safeOnClick = useCallback(
        (e: React.MouseEvent<TElement>) => {
            onClick?.(e);
        },
        [onClick]
    );

    return recordCallback(
        {
            eventType: instrumentation?.elementName ? Enums.InstrumentationEvent.CLICK : null,
            elementName: instrumentation?.elementName,
            eventData: instrumentation?.eventData,
            dedupeKey: Date.now(),
        },
        useDebouncedCallback(safeOnClick, debounceIntervalMs, {
            leading: true,
            trailing: false,
        }).callback
    );
}

export type AbstractButtonProps = {
    children?: React.ReactNode;
    className?: string;
    debounceIntervalMs?: number;
    elementRef?: React.RefObject<HTMLButtonElement>;
    instrumentation: ButtonInstrumentationEvent | null;
    preventDefault?: boolean;
    stopPropagation?: boolean;
} & React.ComponentPropsWithoutRef<"button">;

export function AbstractButton({
    children,
    className,
    debounceIntervalMs,
    elementRef,
    instrumentation,
    preventDefault = false,
    stopPropagation = false,
    ...htmlButtonProps
}: AbstractButtonProps) {
    const onClick = useButtonClickHandler({
        debounceIntervalMs,
        instrumentation,
        onClick: htmlButtonProps.onClick,
    });
    const localRef = useRef<HTMLButtonElement>(null);
    const ref = elementRef || localRef;

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (preventDefault) {
                e.preventDefault();
            }

            if (stopPropagation) {
                e.stopPropagation();
            }

            if (htmlButtonProps.disabled) {
                return;
            }

            onClick(e);
        },
        [htmlButtonProps.disabled, preventDefault, stopPropagation, onClick]
    );

    return (
        <button
            type="button"
            {...htmlButtonProps}
            className={className}
            disabled={htmlButtonProps.disabled}
            onClick={handleClick}
            // By default, HTML buttons treat Enter and Space as clicks on key down. We override
            // that to be onKeyUp due to a quirky/unfortunate interaction with Blueprintjs popover2.
            //
            // In https://github.com/palantir/blueprint/commit/d86e7b30f5a9f9d9fc5f8602dd0ae374c978b9f7,
            // Blueprintjs started keyboard clicks on a popover target trigger the popover. That's
            // a good, desired behavior. However, if we allowed the default HTML button behavior,
            // hitting Enter would toggle the popover on and then off, first on due to the above
            // commit, and then off due to the default behavior firing a normal click event.
            //
            // To solve that, we don't fire a click onKeyDown, instead we wait until onKeyUp. When
            // a user presses Enter/Space on a button that's a popover target, the above commit
            // will cause the popover to open, and onKeyUp never fires because the button no longer
            // has focus.
            //
            // Blueprintjs buttons do this exact same thing.
            onKeyDown={e => {
                if (isKeyboardClick(e)) {
                    e.preventDefault();
                }

                htmlButtonProps.onKeyDown?.(e);
            }}
            onKeyUp={e => {
                if (isKeyboardClick(e)) {
                    ref?.current?.click();
                }

                htmlButtonProps.onKeyUp?.(e);
            }}
            ref={ref}
            tabIndex={htmlButtonProps.disabled ? -1 : htmlButtonProps.tabIndex}
        >
            {children}
        </button>
    );
}

export type AbstractAnchorButtonProps = {
    children?: React.ReactNode;
    className?: string;
    debounceIntervalMs?: number;
    disabled?: boolean;
    elementRef?: React.RefObject<HTMLAnchorElement>;
    instrumentation: ButtonInstrumentationEvent | null;
    preventDefault?: boolean;
    stopPropagation?: boolean;
} & React.ComponentPropsWithoutRef<"a">;

export function AbstractAnchorButton({
    children,
    className,
    disabled,
    debounceIntervalMs,
    elementRef,
    instrumentation,
    preventDefault,
    stopPropagation,

    ...htmlAnchorProps
}: AbstractAnchorButtonProps) {
    const onClick = useButtonClickHandler({
        debounceIntervalMs,
        instrumentation,
        onClick: htmlAnchorProps.onClick,
    });
    const localRef = useRef<HTMLAnchorElement>(null);
    const ref = elementRef || localRef;

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (disabled || preventDefault) {
                e.preventDefault();
            }

            if (stopPropagation) {
                e.stopPropagation();
            }

            if (disabled) {
                return;
            }

            onClick(e);
        },
        [disabled, preventDefault, stopPropagation, onClick]
    );

    return (
        <a
            role="button"
            {...htmlAnchorProps}
            className={className}
            onClick={handleClick}
            // See note in AbstractButton why we trigger a click onKeyUp.
            onKeyDown={e => {
                if (isKeyboardClick(e)) {
                    e.preventDefault();
                }

                htmlAnchorProps.onKeyDown?.(e);
            }}
            onKeyUp={e => {
                if (isKeyboardClick(e)) {
                    ref?.current?.click();
                }

                htmlAnchorProps.onKeyUp?.(e);
            }}
            ref={elementRef}
            tabIndex={disabled ? -1 : htmlAnchorProps.tabIndex}
        >
            {children}
        </a>
    );
}
