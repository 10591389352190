import React, { useEffect } from "react";

import { CommonConstants } from "c9r-common";
import { Redirect } from "react-router-dom";

import { useSetIsViewReady } from "components/loading/Loading";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { SupportMailto } from "components/shared/SupportMailto";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentIdentity } from "contexts/IdentityContext";
import { useDocumentTitle } from "lib/Hooks";
import { useInternalLogout } from "lib/auth/Logout";

import styles from "./EmailVerificationView.module.scss";

export function EmailVerificationView() {
    const currentIdentity = useCurrentIdentity();
    const { internalLogout } = useInternalLogout();

    const setIsViewReady = useSetIsViewReady();

    useDocumentTitle("Verify your email");

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    if (currentIdentity.is_email_address_verified) {
        return <Redirect to="/email_verified" />;
    }

    return (
        <div className={styles.emailVerificationView}>
            <BrandLogomark className={styles.emailVerificationBrandLogo} height={30} />
            <h1>Check your email</h1>
            <p>
                Follow the link in the verification email we sent to
                <br />
                <span className={styles.emailAddressToVerify}>{currentIdentity.email_address}</span>
            </p>
            <p className={styles.havingTrouble}>
                Can't find it? <SupportMailto text="Email us" />, start a{" "}
                <a target="_blank" rel="noopener noreferrer" href={CommonConstants.ChatSupportUrl}>
                    live support chat
                </a>
                , or{" "}
                <TextButton
                    link
                    instrumentation={{
                        elementName: "onboarding.email_verification.logout_btn",
                    }}
                    text="start over"
                    onClick={internalLogout}
                />
                .
            </p>
        </div>
    );
}
