import React, { useRef, useState } from "react";

import { CommonConstants, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AlertBadge } from "components/ui/common/AlertBadge";
import { Avatar } from "components/ui/common/Avatar";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { useCurrentUser } from "contexts/UserContext";
import { useInvitePeopleDialog } from "dialogs/InvitePeopleDialog";
import { useBreakpoints, useSemanticBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { divideAndFlattenGroups } from "lib/Helpers";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { useLogout } from "lib/auth/Logout";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { CurrentUserLegacy } from "lib/types/common/currentUser";

import styles from "./UserIcon.module.scss";

type AvatarButtonProps = {
    className?: string;
    currentUser: CurrentUserLegacy;
    onClick: () => void;
};

function AvatarButton({ className, currentUser, onClick }: AvatarButtonProps) {
    return (
        <BorderButton
            className={classNames(className, styles.avatarButton)}
            content={<Avatar user={currentUser} size={24} />}
            onClick={onClick}
            minimal
            textHover
            instrumentation={{
                elementName: "user_icon_menu.avatar",
            }}
        />
    );
}

type UserMenuProps = {
    currentUser: CurrentUserLegacy;
};

function UserMenu({ currentUser }: UserMenuProps) {
    const breakpoints = useBreakpoints();
    const semanticBreakpoints = useSemanticBreakpoints();
    const isOnline = useRecoilValue(networkStatusState);
    const { isFeatureEnabled } = useFeatureFlags();
    const invitePeopleDialog = useInvitePeopleDialog();
    const { getCurrentLocation } = useHistory();
    const { logout } = useLogout();
    const prefetchQuery = usePrefetchQuery();
    const { buildSettingsUrl } = useUrlBuilders();

    const orgDisplayName = currentUser.org.display_name;

    const prefetchProfileSettingsData = () => {
        void prefetchQuery({
            query: Queries.get({ component: "ProfileSettings", name: "component" }),
            variables: {
                currentUserId: currentUser.id,
            },
            usesLegacyApi: true,
        });
    };

    const menuItems = divideAndFlattenGroups({
        itemGroups: [
            [
                breakpoints.lgMin ? (
                    <MenuItem
                        key="settings"
                        icon={<Icon icon="settings" iconSet="lucide" iconSize={18} />}
                        text={
                            <>
                                Settings
                                {!currentUser.identity.is_email_address_verified && (
                                    <AlertBadge className={styles.menuItemAlertBadge} />
                                )}
                            </>
                        }
                        to={{
                            pathname: buildSettingsUrl().pathname,
                            state: {
                                from: { location: getCurrentLocation() },
                            },
                        }}
                        onMouseOver={() => prefetchProfileSettingsData()}
                        instrumentation={{ elementName: "navbar.avatar_menu.settings_button" }}
                        data-cy="user-menu-settings-link"
                    />
                ) : null,

                <MenuItem
                    key="docs"
                    icon={<Icon icon="book" iconSet="lucide" iconSize={18} />}
                    text="User guide"
                    href={Config.urls.docs.main}
                    target="_blank"
                    instrumentation={{ elementName: "navbar.avatar_menu.docs_button" }}
                />,

                isFeatureEnabled({ feature: Enums.Feature.LIVE_CHAT_SUPPORT }) ? (
                    <MenuItem
                        key="support"
                        icon={<Icon icon="support" iconSet="c9r" iconSize={18} />}
                        text="Chat with support"
                        href={CommonConstants.ChatSupportUrl}
                        target="_blank"
                        instrumentation={{ elementName: "navbar.avatar_menu.chat_support_button" }}
                    />
                ) : null,
            ].filter(Boolean),
            [
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN &&
                !semanticBreakpoints.isSideNavEnabled ? (
                    <MenuItem
                        key="invite-people"
                        text={`Invite people to ${orgDisplayName}`}
                        icon={<Icon icon="user-plus" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "side_nav.org_menu.invite_people",
                        }}
                        onClick={() => {
                            if (!isOnline) {
                                AppToaster.danger({
                                    message: "Sorry, inviting people is not available offline.",
                                });

                                return;
                            }

                            invitePeopleDialog.open();
                        }}
                    />
                ) : null,

                Config.isMultiOrgEnabled && !semanticBreakpoints.isSideNavEnabled ? (
                    <MenuItem
                        key="switch-org"
                        text="Switch organization"
                        icon={<Icon icon="arrow-left-right" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "navbar.avatar_menu.switch_org_button",
                        }}
                        onClick={event => {
                            if (!isOnline) {
                                AppToaster.danger({
                                    message:
                                        "Sorry, switching organizations is not available offline.",
                                });
                                event.preventDefault();
                            }
                        }}
                        to="/organizations"
                    />
                ) : null,
            ].filter(Boolean),
            [
                <MenuItem
                    key="logout"
                    icon={<Icon icon="log-out" iconSet="lucide" iconSize={18} />}
                    instrumentation={{
                        elementName: "navbar.avatar_menu.logout_button",
                    }}
                    text="Log out"
                    onClick={() => logout({ logoutType: Enums.LogoutType.USER_INITIATED })}
                />,
            ].filter(Boolean),
        ],
        divider: i => <MenuDivider key={i} />,
    });

    return <Menu>{menuItems}</Menu>;
}

type UserIconDisplayProps = {
    className?: string;
    currentUser: CurrentUserLegacy;
};

function UserIconDisplay({ className, currentUser }: UserIconDisplayProps) {
    const [isOpen, setIsOpen] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const handleOnClick = () => setIsOpen(!isOpen);

    return (
        <div
            ref={targetRef}
            className={classNames(styles.popoverTarget, isOpen && styles.popoverOpen)}
            data-cy="user-menu-icon"
        >
            <MenuPopover
                content={<UserMenu currentUser={currentUser} />}
                placement="bottom-end"
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [4, 4],
                        },
                    },
                    preventOverflow: {
                        enabled: false,
                    },
                }}
                isOpen={isOpen}
                onInteraction={(next, e) => {
                    if (e && e.target instanceof Element) {
                        // If this check is not in place, onInteraction runs when the popover is closed
                        // via the avatar icon button and sets the state back to open.
                        if (!targetRef.current?.contains(e.target)) {
                            setIsOpen(next);
                        }
                    }
                }}
            >
                <AvatarButton
                    className={className}
                    onClick={handleOnClick}
                    currentUser={currentUser}
                />
            </MenuPopover>
            {!currentUser.identity.is_email_address_verified && <AlertBadge />}
        </div>
    );
}

export type UserIconProps = {
    className?: string;
};

export function UserIcon({ className }: UserIconProps) {
    const currentUser = useCurrentUser();

    return <UserIconDisplay className={className} currentUser={currentUser} />;
}

UserIcon.Display = UserIconDisplay;
