import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const CommentResolvers: TResolvers = {
    query_root: {
        comments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.comments.findAll())
        ),

        comments_by_pk: buildResolver(async (parent, args, { api }) =>
            api.comments.getById({ id: args.id })
        ),
    },

    comments: {
        author: buildResolver(async (parent, args, { userContextApi }) =>
            parent.author_user_id
                ? userContextApi.getUserByIdOrThrow({ userId: parent.author_user_id })
                : null
        ),

        thread: buildResolver(async (parent, args, { api }) =>
            api.threads.getByIdOrThrow({ id: parent.thread_id })
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),
    },
};
