import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { isDemoIntroModalDisplayedState, isNotFoundViewDisplayedState } from "AppState";
import { Config } from "Config";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { useBreakpoints } from "lib/Breakpoints";
import { CssClasses } from "lib/Constants";
import { useHistory } from "lib/Routing";
import { Storage } from "lib/Storage";

import { DemoCtaForm } from "./DemoCtaForm";
import styles from "./DemoIntro.module.scss";

export type DemoIntroProps = {
    children: React.ReactNode;
};

/**
 * Modal shown to users when starting a demo.
 */
export function DemoIntro({ children }: DemoIntroProps) {
    const breakpoints = useBreakpoints();

    return breakpoints.smMax ? (
        <DemoIntroMobile />
    ) : (
        <DemoIntroDesktop>{children}</DemoIntroDesktop>
    );
}

/**
 * Modal shown to users when starting a demo on mobile.
 *
 * IMPORTANT NOTE BEFORE CHANGING THIS COMPONENT
 * ---------------------------------------------
 * In the demo environment, we "prerender" this component in the statically served
 * HTML so that the demo appears to load faster. Then once this component loads, it removes
 * the prerender from the DOM. Obviously it's important that the prerender matches the final
 * render exactly, or the user will see the change. We do have some automated tests to check
 * for obvious issues (changes in important strings, changes in CSS-module-generated class
 * names), but they may not catch everything, so take care when making changes here.
 *
 * Of course, the proper way to do this would be with SSR, but as of September 2021,
 * we haven't made that investment.
 */
export function DemoIntroMobile() {
    const prerenderedDialog = document.getElementById("demoIntroPrerender");
    const [didOpen, setDidOpen] = useState(false);

    useEffect(() => {
        if (didOpen && prerenderedDialog) {
            prerenderedDialog.remove();
        }
    }, [didOpen, prerenderedDialog]);

    // Intentionally don't accept or render children, since as of December 2023, we don't allow demo
    // visitors on mobile to experience the rest of the app.
    return (
        <Dialog
            fillViewport
            isOpen
            backdropClassName={styles.backdrop}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            className={classNames(styles.dialog, styles.dialogMobile)}
            isCloseButtonShown={false}
            onOpened={() => setDidOpen(true)}
            transitionDuration={0}
        >
            <Dialog.Content>
                <Dialog.Header className={styles.header}>
                    <BrandLogomark height={24} />
                </Dialog.Header>

                <Dialog.Body className={styles.body}>
                    <div className={styles.warning}>
                        <Icon
                            icon="alert-triangle"
                            iconSet="lucide"
                            iconSize={24}
                            strokeWidth={2}
                        />
                        <div>
                            <p>Sorry, the Flat live demo isn't optimized for mobile yet.</p>
                            <p>Check out our short demo video below instead.</p>
                        </div>
                    </div>
                    <div className={styles.videoWrapper}>
                        <iframe
                            title="Flat demo video"
                            src={Config.urls.video.embed}
                            allowFullScreen
                        />
                    </div>
                </Dialog.Body>

                <Dialog.Footer className={styles.footer}>
                    <DemoCtaForm className={styles.ctaForm} />
                </Dialog.Footer>
            </Dialog.Content>
        </Dialog>
    );
}

export type DemoIntroDesktopProps = {
    children: React.ReactNode;
};
/**
 * Modal shown to users when starting a demo in desktop.
 *
 * IMPORTANT NOTE BEFORE CHANGING THIS COMPONENT
 * ---------------------------------------------
 * In the demo environment, we "prerender" this component in the statically served
 * HTML so that the demo appears to load faster. Then once this component loads, it removes
 * the prerender from the DOM. Obviously it's important that the prerender matches the final
 * render exactly, or the user will see the change. We do have some automated tests to check
 * for obvious issues (changes in important strings, changes in CSS-module-generated class
 * names), but they may not catch everything, so take care when making changes here.
 *
 * Of course, the proper way to do this would be with SSR, but as of September 2021,
 * we haven't made that investment.
 */
export function DemoIntroDesktop({ children }: DemoIntroDesktopProps) {
    const setIsDemoIntroDisplayed = useSetRecoilState(isDemoIntroModalDisplayedState);
    const { history } = useHistory();
    const demoCredentials = Storage.Local.getItem("demo.credentials");
    const identityId =
        demoCredentials &&
        demoCredentials.expiresAt > Date.now() + Config.auth.preExpirationPromptMs
            ? demoCredentials.identityId
            : undefined;
    const storageKey = identityId ? `demo.ux.identity.${identityId}.didDismissIntroModal` : null;
    const didDismissModal = storageKey ? Storage.Local.getBoolean(storageKey) : false;
    const shouldRenderDialog = Config.demoUI.enabled && !didDismissModal;
    const prerenderedDialog = document.getElementById("demoIntroPrerender");
    const isNotFoundViewDisplayed = useRecoilValue(isNotFoundViewDisplayedState);

    const [isOpen, setIsOpen] = useState(shouldRenderDialog);
    const [didOpen, setDidOpen] = useState(false);
    const invisible = !didOpen;

    useEffect(() => {
        if (didOpen && prerenderedDialog) {
            prerenderedDialog.remove();
        }
    }, [didOpen, prerenderedDialog]);

    useEffect(() => {
        if (!shouldRenderDialog) {
            return;
        }

        setIsDemoIntroDisplayed(isOpen);

        // Suppose a user has the demo open in a browser tab, and the demo expires. When the page
        // refreshes, the URL will still refer to the old org, which will not be found. To mitigate
        // that, if the not found view is being displayed and we're showing the dialog (fresh demo),
        // redirect to the home route, which will then redirect to the new demo org.
        if (isNotFoundViewDisplayed) {
            history.push("/");
        }
    }, [history, isNotFoundViewDisplayed, isOpen, setIsDemoIntroDisplayed, shouldRenderDialog]);

    if (!shouldRenderDialog) {
        return <>{children}</>;
    }

    const isDisabled = !identityId;

    // IMPORTANT: See note above if making changes to this render.
    return (
        <>
            <Dialog
                isOpen={isOpen}
                backdropClassName={classNames(styles.backdrop, invisible && CssClasses.INVISIBLE)}
                canOutsideClickClose={!isDisabled}
                className={classNames(
                    styles.dialog,
                    styles.dialogDesktop,
                    invisible && CssClasses.INVISIBLE
                )}
                isCloseButtonShown={false}
                onClosed={() => {
                    if (storageKey) {
                        Storage.Local.setBoolean(storageKey, true);
                    }
                }}
                onOpened={() => setDidOpen(true)}
                portalClassName={classNames(invisible && CssClasses.INVISIBLE)}
            >
                <Dialog.Content>
                    <Dialog.Header className={styles.header}>
                        <BrandLogomark height={24} />
                    </Dialog.Header>

                    <Dialog.Body className={styles.body}>
                        <p className={styles.bodyIntro}>
                            👋 <strong>Hi!</strong> And welcome to your private Flat demo.
                        </p>
                        <p>
                            For busy individuals and fast-moving teams, Flat is the effortless,
                            uncluttered, and organized home base you've been looking for.
                        </p>
                        <p>
                            Feel free to explore and change things! This demo account is a private
                            playground just for you.
                        </p>
                    </Dialog.Body>

                    <Dialog.Footer className={styles.footer}>
                        <BorderButton
                            brandFlatCta
                            className={classNames(styles.footerButton, styles.footerButtonCta)}
                            content="Let's go"
                            large
                            disabled={isDisabled}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            instrumentation={{
                                elementName: "demo_intro_dialog.start",
                            }}
                        />
                    </Dialog.Footer>
                </Dialog.Content>
            </Dialog>
            {didOpen ? children : null}
        </>
    );
}
