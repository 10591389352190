import React from "react";

import { Redirect } from "react-router-dom";

import { useRouteMatch } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";

export function CurrentOrgRedirect() {
    const { buildCurrentOrgUrl } = useUrlBuilders();

    return <Redirect to={buildCurrentOrgUrl().pathname} />;
}

/**
 * Ensures that if a the URL is a top-level URL like "/settings/general", we redirect to the
 * equivalent settings page for a specific org.
 */
export function CurrentOrgSettingsRedirect() {
    const { buildSettingsUrl } = useUrlBuilders();
    const { path } = useRouteMatch();
    const route = useRouteMatch<{ slug: string }>({
        path: `${path}/:slug`,
        strict: true,
        sensitive: true,
    });

    return <Redirect to={`${buildSettingsUrl().pathname}/${route?.params.slug ?? ""}`} />;
}
