import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./UnreadBadge.module.scss";

export type UnreadBadgeProps = {
    className?: string;
    large?: boolean;
};

export function UnreadBadge({ className, large }: UnreadBadgeProps) {
    return (
        <Icon
            className={classNames(className, styles.unreadBadge)}
            icon="filledCircle"
            iconSet="c9r"
            iconSize={large ? 14 : 12}
        />
    );
}
