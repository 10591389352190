import { Extension } from "@tiptap/core";
import { RichText } from "c9r-rich-text";

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        trimExtension: {
            trimContent: () => ReturnType;
        };
    }
}

export default Extension.create({
    name: "trim",

    addCommands() {
        return {
            trimContent: () => ({ chain, editor }) => {
                return chain().setContent(RichText.trimContentJson(editor.getJSON())).run();
            },
        };
    },
});
