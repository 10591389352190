import { customAlphabet } from "nanoid";

// If updating the alphabet, consider also updating the alphabet in the DB generate_slug function
// to match.
const safeAlphabet = "6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz";
const nanoid = customAlphabet(safeAlphabet);

export function generateTicketSlug() {
    return nanoid(8);
}
