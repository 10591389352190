import React from "react";

import { CommonEnums, isDefined } from "c9r-common";
import isEmail from "validator/lib/isEmail";

import {
    FilterableSelector,
    FilterableSelectorProps,
} from "components/ui/common/FilterableSelector";
import { UserMenuItemText } from "components/ui/common/UserMenuItem";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { divideAndFlattenGroups, isUserQueryMatch } from "lib/Helpers";
import { useMaybeControlledValue } from "lib/Hooks";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import {
    UserSelector_userFragment,
    UserSelector_userFragmentDoc,
} from "lib/graphql/__generated__/graphql";

import styles from "./UserSelector.module.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserSelector_user on users {
            id
            role

            ...EditBoardDialog_user
            ...UserMenuItem_user
        }
    `),
};

export const UserSelectorItemType = {
    USER: "USER",
    INVITE_GUEST: "INVITE_GUEST",
} as const;

export type UserSelectorItem =
    | { type: typeof UserSelectorItemType["USER"]; user: UserSelector_userFragment }
    | { type: typeof UserSelectorItemType["INVITE_GUEST"]; emailAddress: string };

function areUserSelectorItemsEqual(a: UserSelectorItem, b: UserSelectorItem) {
    return (
        (a.type === UserSelectorItemType.INVITE_GUEST &&
            b.type === UserSelectorItemType.INVITE_GUEST) ||
        (a.type === UserSelectorItemType.USER &&
            b.type === UserSelectorItemType.USER &&
            a.user.id === b.user.id)
    );
}

export type UserSelectorProps = {
    disableInviteGuest?: boolean;
    disableSelectUser?: boolean;
    excludedEmailAddresses?: string[];
    excludedUserIds?: number[];
    showGuestIndicator?: boolean;
} & Omit<
    FilterableSelectorProps<UserSelectorItem>,
    | "items"
    | "itemsEqual"
    | "itemListRenderer"
    | "menuItemClassName"
    | "menuItemsClassName"
    | "menuItemTextRenderer"
    | "menuProps"
>;

export function UserSelector({
    disableInviteGuest: _disableInviteGuest,
    disableSelectUser,
    excludedEmailAddresses = [],
    excludedUserIds = [],
    showGuestIndicator,
    ...filterableSelectorProps
}: UserSelectorProps) {
    const { isFeatureEnabled } = useFeatureFlags();
    const currentUser = useCurrentUser();

    const disableInviteGuest =
        _disableInviteGuest ||
        !isFeatureEnabled({ feature: Enums.Feature.MANAGE_USERS }) ||
        !(currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN);

    const [query, onQueryChange] = useMaybeControlledValue({
        value: filterableSelectorProps.query,
        onValueChange: filterableSelectorProps.onQueryChange,
        defaultValue: "",
    });

    const excludedUserIdsSet = new Set(excludedUserIds ?? []);
    const matchingUsers = currentUser.org.users
        .filter(user => !excludedUserIdsSet.has(user.id))
        .filter(user =>
            isUserQueryMatch({
                query,
                name: user.name,
                fullName: user.full_name,
                email: user.identity?.email_address,
            })
        )
        .map(user => getFragmentData(UserSelector_userFragmentDoc, user));

    const shouldSuggestUserItems = !disableSelectUser;
    const shouldSuggestInviteGuestItem =
        !disableInviteGuest && isEmail(query) && !(excludedEmailAddresses ?? []).includes(query);

    const suggestedItems = query
        ? ([] as UserSelectorItem[])
              .concat(
                  shouldSuggestUserItems
                      ? matchingUsers.map(user => ({ type: UserSelectorItemType.USER, user }))
                      : []
              )
              .concat(
                  shouldSuggestInviteGuestItem
                      ? [
                            {
                                type: UserSelectorItemType.INVITE_GUEST,
                                emailAddress: query,
                            },
                        ]
                      : []
              )
        : [];

    const shouldShowResults = !!query;

    return (
        <FilterableSelector
            filterWeakIcon
            itemsPerPage={8}
            resetActiveItemOnEmptyQuery
            scrollable
            filterPlaceholder={
                disableInviteGuest ? "Enter a name" : "Enter a name or email address"
            }
            {...filterableSelectorProps}
            filterIconProps={{
                icon: "search",
                iconSet: "c9r",
                iconSize: 16,
                ...filterableSelectorProps.filterIconProps,
            }}
            items={suggestedItems}
            itemsEqual={areUserSelectorItemsEqual}
            itemListRenderer={({ items, renderItem }) => {
                if (!shouldShowResults) {
                    return null;
                }

                const userItems = items.filter(item => item.type === UserSelectorItemType.USER);
                const inviteGuestItem = items.find(
                    item => item.type === UserSelectorItemType.INVITE_GUEST
                );

                return (
                    <>
                        {divideAndFlattenGroups({
                            itemGroups: [
                                userItems.map(action => renderItem({ item: action })),
                                [
                                    inviteGuestItem ? (
                                        renderItem({ item: inviteGuestItem })
                                    ) : !disableInviteGuest && !isEmail(query) ? (
                                        <div className={styles.inviteGuestInstructions}>
                                            Enter an email address to invite a new guest
                                        </div>
                                    ) : null,
                                ].filter(isDefined),
                            ],
                            divider: () => <MenuDivider />,
                        })}
                    </>
                );
            }}
            menuItemsClassName={styles.menuItems}
            menuItemTextRenderer={item => {
                switch (item.type) {
                    case UserSelectorItemType.USER:
                        return (
                            <UserMenuItemText
                                showAvatar
                                showGuestIndicator={showGuestIndicator}
                                user={item.user}
                            />
                        );

                    case UserSelectorItemType.INVITE_GUEST:
                        return (
                            <div>
                                Invite <b>{query}</b> as a workspace guest
                            </div>
                        );

                    default:
                        return null;
                }
            }}
            menuProps={{ className: styles.userSelector }}
            onQueryChange={onQueryChange}
            query={query}
        />
    );
}
