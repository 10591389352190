import React from "react";

import { useRecoilValue } from "recoil";

import { ConfirmationModal } from "components/ui/common/ConfirmationModal";
import { dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";

import styles from "./MakeBoardPrivateDialog.module.scss";

export type MakeBoardPrivateDialogProps = {
    onSubmit: () => void;
};

const makeBoardPrivateDialogState = dialogStateFamily<MakeBoardPrivateDialogProps>(
    "MakeBoardPrivateDialogState"
);

export function useMakeBoardPrivateDialogState() {
    return useDialogSingleton(makeBoardPrivateDialogState);
}

export function MakeBoardPrivateDialog({ onSubmit }: MakeBoardPrivateDialogProps) {
    const { isOpen } = useRecoilValue(makeBoardPrivateDialogState);
    const makeBoardPrivateDialog = useMakeBoardPrivateDialogState();

    return (
        <ConfirmationModal
            className={styles.dialog}
            isOpen={isOpen}
            body={
                <>
                    <p>
                        By making this workspace private, only people added as members will have
                        access.
                    </p>
                    <p>
                        <strong>This change can't be undone.</strong> Once you make this workspace
                        private, it can't be changed back to public.
                    </p>
                </>
            }
            title="Are you sure?"
            submitButtonText="OK"
            onClose={makeBoardPrivateDialog.close}
            onSubmit={onSubmit}
        />
    );
}
