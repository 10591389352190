import React, { useCallback, useRef, useState } from "react";

import { CommonEnums, TTextBlocker, TTicketBlocker, isTicketBlocker } from "c9r-common";

import {
    TicketSelector,
    TicketSelectorItem,
    TicketSelectorItemType,
} from "components/shared/TicketSelector";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextInputBox, TextInputBoxProps } from "components/ui/core/TextInputBox";
import { AbstractButton } from "components/ui/core/abstract/AbstractButton";
import { useToggle } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./BlockerInput.module.scss";

type TDisplayableTextBlocker = TTextBlocker;
export type TDisplayableTicketBlocker = TTicketBlocker & {
    value: { ticket: { ref: string | null; title: string } };
};
export type TDisplayableBlocker = TDisplayableTextBlocker | TDisplayableTicketBlocker;

const fragments = {
    board: gql(/* GraphQL */ `
        fragment BlockerInput_board on boards {
            id
            access_type
        }
    `),
};

type BlockerInputProps = {
    blocker: TDisplayableBlocker | null;
    board: FragmentType<typeof fragments.board>;
    excludedTicketIds: string[];
    onClearTicket: () => void;
    onSelectTicket: ({
        ticket,
    }: {
        ticket: Extract<
            TicketSelectorItem,
            { type: typeof TicketSelectorItemType["TICKET"] }
        >["ticket"];
    }) => void;
    textInputBoxProps: TextInputBoxProps & { "data-cy": string };
    value: string;
};

export function BlockerInput({
    blocker,
    board: _boardFragment,
    excludedTicketIds: _excludedTicketIds,
    onClearTicket,
    onSelectTicket,
    textInputBoxProps,
    value,
}: BlockerInputProps) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const inputRef = useRef<HTMLInputElement>(null);
    const ticketSelectorPopover = useToggle();
    const [ticketSelectorQuery, setTicketSelectorQuery] = useState("");

    const excludedTicketIds =
        blocker?.type === CommonEnums.BlockerType.TICKET
            ? [blocker.value.ticket.id, ..._excludedTicketIds]
            : _excludedTicketIds;

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const isUserOpeningTicketSelector = !value && e.key === "#";

            if (isUserOpeningTicketSelector) {
                e.preventDefault();

                ticketSelectorPopover.open();
            }
        },
        [ticketSelectorPopover, value]
    );

    const handleTicketSelectorClose = useCallback(() => {
        setTicketSelectorQuery("");
        ticketSelectorPopover.close();
        setImmediate(() => inputRef.current?.focus());
    }, [ticketSelectorPopover]);

    const handleTicketSelectorSelect = useCallback(
        (selectedItem: TicketSelectorItem) => {
            if (selectedItem.type === TicketSelectorItemType.TICKET) {
                onSelectTicket({ ticket: selectedItem.ticket });
                handleTicketSelectorClose();
            }
        },
        [handleTicketSelectorClose, onSelectTicket]
    );

    return (
        <MenuPopover
            canEscapeKeyClose={ticketSelectorQuery === ""}
            className={styles.ticketSelectorPopover}
            content={
                <TicketSelector
                    autoFocus
                    excludedTicketIds={excludedTicketIds}
                    onQueryChange={setTicketSelectorQuery}
                    onSelect={handleTicketSelectorSelect}
                    query={ticketSelectorQuery}
                    showRecentTicketsOnEmptyQuery
                    ticketReferenceContextAndOriginBoard={{
                        context: CommonEnums.TicketReferenceContext.BLOCKER,
                        originBoard: board,
                    }}
                />
            }
            fill
            isOpen={ticketSelectorPopover.isOpen}
            modifiers={{
                offset: {
                    enabled: true,
                    options: {
                        offset: [0, -44],
                    },
                },
            }}
            onClose={handleTicketSelectorClose}
            placement="bottom-start"
        >
            {isTicketBlocker(blocker) ? (
                <AbstractButton
                    className={styles.ticketWrapper}
                    instrumentation={null}
                    onClick={() => {
                        ticketSelectorPopover.open();
                    }}
                >
                    <div className={styles.ticket}>
                        <Icon
                            className={styles.ticketIcon}
                            icon="file-text"
                            iconSet="lucide"
                            iconSize={16}
                            strokeWidthAbsolute={1}
                        />
                        <div className={styles.ticketTitle}>{blocker.value.ticket.title}</div>
                        {blocker.value.ticket.ref ? (
                            <div
                                className={styles.ticketRef}
                            >{`(#${blocker.value.ticket.ref})`}</div>
                        ) : null}
                    </div>
                    <BorderButton
                        className={styles.clearButton}
                        content={<Icon icon="x" iconSet="lucide" iconSize={16} />}
                        flush
                        instrumentation={null}
                        minimal
                        onClick={e => {
                            e.stopPropagation();

                            onClearTicket();
                        }}
                        square
                    />
                </AbstractButton>
            ) : (
                <TextInputBox
                    autoFocus
                    fill
                    inputRef={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder="One-line summary or type # to link a topic"
                    treatAsTextAreaForKeyboard
                    value={value}
                    {...textInputBoxProps}
                />
            )}
        </MenuPopover>
    );
}
