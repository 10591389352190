import { DocumentNode } from "@apollo/client";

type GqlMap = Record<string, DocumentNode>;

type ComponentName =
    | "AccountSettings"
    | "AppLayout"
    | "AppearanceSettings"
    | "Archive"
    | "BoardPage"
    | "BoardSettings"
    | "DetailView"
    | "DueDatesView"
    | "GeneralSettings"
    | "IdentityContext"
    | "ImportSettings"
    | "IntegrationsSettings"
    | "NotificationsSettings"
    | "OnboardingView"
    | "OrganizationsView"
    | "PlanView"
    | "ProfileSettings"
    | "TeamSettings"
    | "TicketHistoryEvents"
    | "TicketSearchIndex"
    | "Trash"
    | "UserContext";

export class Queries {
    private static _REGISTRY: Record<string, GqlMap> = {};

    static register({
        component,
        gqlMapByName,
    }: {
        component: ComponentName;
        gqlMapByName: GqlMap;
    }) {
        Queries._REGISTRY[component] = gqlMapByName;
    }

    static get({ component, name }: { component: ComponentName; name: string }) {
        return Queries._REGISTRY[component][name];
    }
}
