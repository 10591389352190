import React, { useRef, useState } from "react";

import { CommonEnums, sortLabels } from "c9r-common";
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";

import { ChildTicketProgressBar } from "components/shared/ChildTicketProgressBar";
import { Label } from "components/shared/Label";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { isKeyboardDelete } from "lib/Keyboard";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { Queries } from "lib/Queries";
import { Link } from "lib/Routing";
import { useTicketOwnershipInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";

import styles from "./TasklistChildTicket.module.scss";
import { TasklistChildTicketMenuPopover } from "./TasklistChildTicketMenuPopover";

const fragments = {
    ChildTicketDetails: {
        ticket: gql(/* GraphQL */ `
            fragment ChildTicketDetails_ticket on tickets {
                id
                ref
                slug
                title
                archived_at
                size_spec

                board {
                    id
                    display_name
                }

                label_attachments {
                    color
                    text
                }

                stage {
                    id
                    display_name
                    role
                }

                ...TicketOwnershipInfo_ticket
                ...ChildTicketProgressBar_ticket
            }
        `),
    },
    TasklistChildTicket: {
        task: gql(/* GraphQL */ `
            fragment TasklistChildTicket_task on tasks {
                id

                child_ticket {
                    id
                    archived_at

                    stage {
                        id
                        role
                    }

                    ...ChildTicketDetails_ticket
                }

                ...TasklistChildTicketMenuPopover_task
            }
        `),
    },
};

type ChildTicketDetailsProps = {
    childTicket: FragmentType<typeof fragments.ChildTicketDetails.ticket> | null;
    onDelete: () => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    prefetchTicketDetails: ({ ref }: { ref: string }) => void;
};

function ChildTicketDetails({
    childTicket: _childTicketFragment,
    onDelete,
    onKeyDown,
    prefetchTicketDetails,
}: ChildTicketDetailsProps) {
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const childTicket = _childTicketFragment
        ? getFragmentData(fragments.ChildTicketDetails.ticket, _childTicketFragment)
        : null;
    const childTicketRef = useRef<HTMLDivElement>(null);
    const { buildTicketUrl } = useUrlBuilders();
    const { ownershipSummaryText } = useTicketOwnershipInfo({ ticket: childTicket });
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();

    return (
        <div
            ref={childTicketRef}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            data-tasklists-arrow-nav
            className={styles.childTicketDetailsWrapper}
            onKeyDown={e => {
                if (isKeyboardDelete(e)) {
                    onDelete();
                }

                if (e.key === "Escape") {
                    childTicketRef.current?.blur();
                }

                onKeyDown?.(e);
            }}
        >
            {childTicket ? (
                <>
                    <Link
                        className={styles.titleAndRef}
                        to={
                            buildTicketUrl({
                                ticketSlug: childTicket.slug,
                                vanity: {
                                    boardDisplayName: childTicket.board.display_name,
                                    ticketRef: childTicket.ref,
                                    ticketTitle: childTicket.title,
                                },
                            }).pathname
                        }
                        onFocus={() => prefetchTicketDetails({ ref: childTicket.ref })}
                        onMouseOver={() => prefetchTicketDetails({ ref: childTicket.ref })}
                    >
                        <span className={styles.title}>{childTicket.title}</span>
                        {shouldShowTicketRefs ? (
                            <span className={styles.ref}>&nbsp;(#{childTicket.ref})</span>
                        ) : null}
                    </Link>
                    {!!childTicket.label_attachments.length && (
                        <span className={styles.labels}>
                            {childTicket.label_attachments
                                .concat()
                                .sort(sortLabels())
                                .map(label => (
                                    <React.Fragment key={`${label.color}|${label.text}`}>
                                        <Label color={label.color} text={label.text} small />
                                    </React.Fragment>
                                ))}
                        </span>
                    )}
                </>
            ) : (
                <span className={styles.title}>
                    {buildUnauthorizedDisplayName({ abstractName: "workItem" })}
                </span>
            )}
            <span className={styles.ownershipTextAndProgressBar}>
                {ownershipSummaryText && (
                    <span className={styles.ownership}>{ownershipSummaryText}</span>
                )}
                <ChildTicketProgressBar className={styles.progressBar} ticket={childTicket} />
            </span>
        </div>
    );
}

export type TasklistChildTicketProps = {
    index: number;
    onDelete: () => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    task: FragmentType<typeof fragments.TasklistChildTicket.task>;
};

export function TasklistChildTicket({
    index,
    onDelete,
    onKeyDown,
    task: _taskFragment,
}: TasklistChildTicketProps) {
    const task = getFragmentData(fragments.TasklistChildTicket.task, _taskFragment);
    const currentUser = useCurrentUser();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const childTicket = task.child_ticket;
    const prefetchQuery = usePrefetchQuery();

    const prefetchTicketDetails = ({ ref }: { ref: string }) => {
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref,
            },
        });
    };

    return (
        <Draggable key={task.id} draggableId={`${task.id.toString()}`} index={index}>
            {(provided, snapshot) => (
                <li
                    className={classNames(
                        styles.childTicket,
                        isMenuOpen && styles.menuOpen,
                        snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging,
                        snapshot.isDropAnimating && styles.isDropAnimating,
                        (childTicket?.stage.role === CommonEnums.StageRole.COMPLETE ||
                            childTicket?.archived_at) &&
                            styles.complete
                    )}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <Icon
                        className={styles.dragHandle}
                        icon="dragHandleLarge"
                        iconSet="c9r"
                        {...provided.dragHandleProps}
                    />
                    <Icon className={styles.icon} icon="file-text" iconSet="lucide" iconSize={18} />
                    <ChildTicketDetails
                        childTicket={childTicket}
                        prefetchTicketDetails={prefetchTicketDetails}
                        onKeyDown={onKeyDown}
                        onDelete={onDelete}
                    />
                    <TasklistChildTicketMenuPopover
                        onDelete={onDelete}
                        task={task}
                        popoverProps={{
                            modifiers: {
                                offset: {
                                    enabled: true,
                                    options: {
                                        offset: [8, -4],
                                    },
                                },
                            },
                            placement: "bottom-end",
                            onOpening: () => setIsMenuOpen(true),
                            onClosing: () => setIsMenuOpen(false),
                        }}
                    >
                        <EllipsisButton
                            className={styles.ellipsisButton}
                            vertical
                            instrumentation={null}
                        />
                    </TasklistChildTicketMenuPopover>
                </li>
            )}
        </Draggable>
    );
}
