import React from "react";

import classNames from "classnames";

import { useCurrentUser } from "contexts/UserContext";
import { ProductTourElementClasses } from "lib/Constants";
import { canCurrentUserViewUserPage } from "lib/Helpers";
import { useRouteParams } from "lib/Routing";
import { useRedirectToFullPathname, useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { UserNotFoundView } from "views/error/NotFoundView";

import styles from "./UserView.module.scss";
import { UserViewTabType, useUserViewRouting } from "./UserViewRouting";
import { DueDatesView } from "./dates/DueDatesView";
import { UserViewHeader } from "./header/UserViewHeader";
import { PlanView } from "./plan/PlanView";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment UserView_user on users {
            id
            name
            slug

            ...UserViewHeader_user
        }
    `),
};

type UserViewLayoutProps = {
    user: FragmentType<typeof fragments.user>;
};

function UserViewLayout({ user: _userFragment }: UserViewLayoutProps) {
    const user = getFragmentData(fragments.user, _userFragment);
    const { activeTab } = useUserViewRouting({ userId: user.id });
    const { buildUserUrl } = useUrlBuilders();

    useRedirectToFullPathname({
        fullPathname: buildUserUrl({
            userSlug: user.slug,
            vanity: {
                username: user.name,
            },
        }).pathname,
    });

    return (
        <div className={styles.layout}>
            <div
                className={classNames(styles.highlightTarget, ProductTourElementClasses.USER_VIEW)}
            />
            <UserViewHeader className={styles.header} user={user} />
            {activeTab === UserViewTabType.PLAN ? (
                <PlanView className={styles.main} userId={user.id} />
            ) : null}
            {activeTab === UserViewTabType.DUE_DATES ? (
                <DueDatesView className={styles.main} userId={user.id} />
            ) : null}
        </div>
    );
}

export function UserView() {
    const currentUser = useCurrentUser();
    const { userSlug } = useRouteParams<{ userSlug?: string }>();

    const user = currentUser.org.users.find(u => u.slug === userSlug);

    if (
        !user ||
        !canCurrentUserViewUserPage({
            currentUser,
            userIdToView: user.id,
        })
    ) {
        return <UserNotFoundView />;
    }

    return <UserViewLayout user={user} key={user.id} />;
}
