import React from "react";

import { Redirect } from "react-router-dom";

import { useCurrentUser } from "contexts/UserContext";
import { pickDefaultBoard } from "lib/Helpers";
import { useUrlBuilders } from "lib/Urls";

export function DefaultBoardRedirect() {
    const currentUser = useCurrentUser();
    const { buildBoardUrl, buildCurrentOrgUrl } = useUrlBuilders();

    const board = pickDefaultBoard({ org: currentUser.org });

    if (!board) {
        return <Redirect to={`${buildCurrentOrgUrl().pathname}/setup`} />;
    }

    return (
        <Redirect
            to={
                buildBoardUrl({
                    boardSlug: board.slug,
                    vanity: { boardDisplayName: board.display_name },
                }).pathname
            }
        />
    );
}
