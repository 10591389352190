import { textblockTypeInputRule } from "@tiptap/core";
import ExtensionHeading from "@tiptap/extension-heading";

// As of November 2021, the only change in this extension vs. tiptap's built-in extension
// is the input regex. tiptap's is ``^(#{1,${level}})\\s$` while ours is `^(#{1,${level}}) $`.
// tiptap's allows any whitespace after the #, whereas we only allow a space. That has two
// benefits:
//   (1) Better aligns with user behavior. If you're going to type header text, surely you'll
//       hit Space, not Enter or Tab.
//   (2) Avoids interference with ticket references, where you press Enter to select an item
//       from the suggestion menu.
export default ExtensionHeading.extend({
    addInputRules() {
        return this.options.levels.map(level => {
            return textblockTypeInputRule({
                find: new RegExp(`^(#{1,${level}}) $`),
                type: this.type,
                getAttributes: {
                    level,
                },
            });
        });
    },
});
