import { useCallback } from "react";

import { CommonEnums } from "c9r-common";
import { useRecoilValue } from "recoil";

import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AppToaster } from "components/ui/core/AppToaster";
import { Enums } from "lib/Enums";
import { useUploadAsset } from "lib/Uploads";
import { useCreateTicketAttachment, useDeleteTicketAttachment } from "lib/mutations";

export const useTicketDetailAttachments = ({ ticketId }: { ticketId: string }) => {
    const isOnline = useRecoilValue(networkStatusState);
    const { createTicketAttachment } = useCreateTicketAttachment();
    const { deleteTicketAttachment } = useDeleteTicketAttachment();
    const { uploadUserContent } = useUploadAsset();

    const handleAttachmentUpload = useCallback(
        async (files: File[]) => {
            if (!isOnline) {
                AppToaster.danger({
                    message: "Sorry, uploading attachments is not available offline.",
                });

                return;
            }

            await Promise.all(
                files.map(async file => {
                    try {
                        const commonArgs = {
                            assetType: CommonEnums.UserUploadAssetType.ATTACHMENT,
                            mimeType: file.type,
                            blob: file,
                            filename: file.name,
                            entityId: ticketId,
                            entityType: Enums.AttachmentEntityType.TICKET_DETAIL,
                        };

                        await uploadUserContent({
                            ...commonArgs,
                            onSuccess: async ({ key }) => {
                                await createTicketAttachment({
                                    ticketId,
                                    title: file.name,
                                    key,
                                });
                            },
                        });
                    } catch (error) {
                        AppToaster.error({
                            message: "Sorry, something went wrong saving your changes.",
                        });
                    }
                })
            );
        },
        [createTicketAttachment, isOnline, ticketId, uploadUserContent]
    );

    const handleAttachmentDelete = useCallback(
        (attachment: { id: string }) => {
            void deleteTicketAttachment({ attachmentId: attachment.id });
        },
        [deleteTicketAttachment]
    );

    return { handleAttachmentUpload, handleAttachmentDelete };
};
