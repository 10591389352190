import React, { useLayoutEffect } from "react";

export type DynamicWidthTableColumnDefinition = {
    columnClassName: string;
    displayIfEmpty?: boolean;
    minWidth?: number;
    maxWidth?: number;
    shrinkable?: boolean;
};

export type DynamicWidthTableProps = {
    children: React.ReactNode;
    columnDefinitions: DynamicWidthTableColumnDefinition[];

    /** List of dependencies that indicate when any content in the table may have changed, so that the column widths can be recomputed. */
    dependencies: any[];
};

/**
 * Creates a table-like layout built with flex rather than an HTML table.
 *
 * A row in the table can be created as any flex container with nowrap, and with a series of
 * DynamicWidthTableCell components as descendants. All rows should have the same
 * DynamicWidthTableCell components in the same order, thus ensuring each DynamicWidthTableCell
 * is arranged in a column with its counterparts. If a particular row doesn't have a value for
 * that column, a DynamicWidthTableCell should still be rendered, but with empty content.
 *
 * A column is effectively created becaues DynamicWidthTable sets each cell's individual
 * width based on the maximum width of all DynamicWidthTableCells in that column (sharing
 * the same className), causing them all to line up.
 */
export function DynamicWidthTable({
    children,
    columnDefinitions,
    dependencies,
}: DynamicWidthTableProps) {
    useLayoutEffect(() => {
        for (const { columnClassName, shrinkable } of columnDefinitions) {
            const elements = Array.from(document.getElementsByClassName(columnClassName)).filter(
                el => el instanceof HTMLElement
            ) as HTMLElement[];

            for (const el of elements) {
                el.style.display = "";
                el.style.minWidth = "max-content";
                el.style.width = "max-content";
                el.style.maxWidth = "max-content";

                if (shrinkable) {
                    el.style.flexBasis = "auto";
                    el.style.flexShrink = "0";
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnDefinitions, ...dependencies]);

    useLayoutEffect(() => {
        for (const {
            columnClassName,
            displayIfEmpty,
            minWidth,
            maxWidth,
            shrinkable,
        } of columnDefinitions) {
            const elements = Array.from(document.getElementsByClassName(columnClassName)).filter(
                el => el instanceof HTMLElement
            ) as HTMLElement[];
            const maxElementWidth = Math.max(...elements.map(el => el.offsetWidth));
            const isEveryElementEmpty = elements.every(
                el => el.childElementCount === 0 && !el.textContent
            );
            const width =
                typeof maxWidth === "number"
                    ? Math.min(maxElementWidth, maxWidth)
                    : maxElementWidth;

            for (const el of elements) {
                if (shrinkable) {
                    el.style.minWidth = minWidth ? `${Math.min(width, minWidth)}px` : "0px";
                    el.style.width = "";
                    el.style.maxWidth = "";
                    el.style.flexBasis = `${width}px`;
                    el.style.flexShrink = "1";
                } else {
                    el.style.minWidth = `${width}px`;
                    el.style.width = `${width}px`;
                    el.style.maxWidth = `${width}px`;
                }

                if (isEveryElementEmpty && !displayIfEmpty) {
                    el.style.display = "none";
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnDefinitions, ...dependencies]);

    return <>{children}</>;
}

export type DynamicWidthTableCellProps = {
    /** All cells in the same column must include a common class name that defines that column. */
    className: string;
    children?: React.ReactNode;
};

/**
 * Represents a single cell in the table-like layout.
 */
export function DynamicWidthTableCell({ className, children }: DynamicWidthTableCellProps) {
    return <span className={className}>{children}</span>;
}
