import { useCallback } from "react";

import { DbColumnTypes } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import { gql } from "lib/graphql/__generated__";

import { MutationCallbackOptions, useMutation } from "../MutationHelpers";

export function useSaveAppearancePreferences() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation SetAppearancePreferences($userId: Int!, $appearancePreferences: jsonb!) {
                update_users_by_pk(
                    pk_columns: { id: $userId }
                    _set: { appearance_preferences: $appearancePreferences }
                ) {
                    id
                    appearance_preferences
                }
            }
        `)
    );

    const saveAppearancePreferences = useCallback(
        async (
            {
                appearancePreferences,
            }: { appearancePreferences: DbColumnTypes.UsersAppearancePreferences },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    appearancePreferences,
                    userId: currentUser.id,
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { saveAppearancePreferences };
}

export function useSaveSettings() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation SetSettings($userId: Int!, $settings: jsonb!) {
                update_users_by_pk(pk_columns: { id: $userId }, _set: { settings: $settings }) {
                    id
                    settings
                }
            }
        `)
    );

    const saveSettings = useCallback(
        async (
            { settings }: { settings: DbColumnTypes.UsersSettings },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    settings,
                    userId: currentUser.id,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        settings,
                    },
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { saveSettings };
}

export function useSaveNotificationPreferences() {
    const currentUser = useCurrentUser();
    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation NotificationsPreferences($userId: Int!, $notificationPreferences: jsonb!) {
                update_users_by_pk(
                    pk_columns: { id: $userId }
                    _set: { notification_preferences: $notificationPreferences }
                ) {
                    id
                    notification_preferences
                }
            }
        `)
    );

    const saveNotificationPreferences = useCallback(
        async (
            {
                notificationPreferences,
            }: { notificationPreferences: DbColumnTypes.UsersNotificationPreferences },
            mutationOptions?: MutationCallbackOptions
        ) => {
            return mutation({
                ...mutationOptions,
                variables: {
                    notificationPreferences,
                    userId: currentUser.id,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        notification_preferences: notificationPreferences,
                    },
                },
            });
        },
        [currentUser.id, mutation]
    );

    return { saveNotificationPreferences };
}
