export default /* GraphQL */ `
    schema {
        query: query_root
    }

    directive @cached(ttl: Int! = 60, refresh: Boolean! = false) on QUERY

    input Boolean_comparison_exp {
        _eq: Boolean
        _gt: Boolean
        _gte: Boolean
        _in: [Boolean!]
        _is_null: Boolean
        _lt: Boolean
        _lte: Boolean
        _neq: Boolean
        _nin: [Boolean!]
    }

    input Int_comparison_exp {
        _eq: Int
        _gt: Int
        _gte: Int
        _in: [Int!]
        _is_null: Boolean
        _lt: Int
        _lte: Int
        _neq: Int
        _nin: [Int!]
    }

    input String_comparison_exp {
        _eq: String
        _gt: String
        _gte: String
        _ilike: String
        _in: [String!]
        _iregex: String
        _is_null: Boolean
        _like: String
        _lt: String
        _lte: String
        _neq: String
        _nilike: String
        _nin: [String!]
        _niregex: String
        _nlike: String
        _nregex: String
        _nsimilar: String
        _regex: String
        _similar: String
    }

    type auth_boards_access {
        board: boards
        board_id: uuid
        user: users
        user_id: Int
    }

    input auth_boards_access_bool_exp {
        _and: [auth_boards_access_bool_exp!]
        _not: auth_boards_access_bool_exp
        _or: [auth_boards_access_bool_exp!]
        board: boards_bool_exp
        board_id: uuid_comparison_exp
        user: users_bool_exp
        user_id: Int_comparison_exp
    }

    input auth_boards_access_order_by {
        board: boards_order_by
        board_id: order_by
        user: users_order_by
        user_id: order_by
    }

    type auth_orgs_access {
        org: orgs
        org_id: Int
        user: users
        user_id: Int
    }

    input auth_orgs_access_bool_exp {
        _and: [auth_orgs_access_bool_exp!]
        _not: auth_orgs_access_bool_exp
        _or: [auth_orgs_access_bool_exp!]
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        user: users_bool_exp
        user_id: Int_comparison_exp
    }

    input auth_orgs_access_order_by {
        org: orgs_order_by
        org_id: order_by
        user: users_order_by
        user_id: order_by
    }

    scalar bigint

    input bigint_comparison_exp {
        _eq: bigint
        _gt: bigint
        _gte: bigint
        _in: [bigint!]
        _is_null: Boolean
        _lt: bigint
        _lte: bigint
        _neq: bigint
        _nin: [bigint!]
    }

    type boards {
        access_type: String!
        archived_at: timestamptz
        attached_users(
            limit: Int
            order_by: [boards_users_order_by!]
            where: boards_users_bool_exp
        ): [boards_users!]!
        authorized_users(
            limit: Int
            order_by: [auth_boards_access_order_by!]
            where: auth_boards_access_bool_exp
        ): [auth_boards_access!]!
        creator: users
        display_name: String!
        id: uuid!
        import: imports
        import_id: bigint
        labels_config(path: String): jsonb!
        org: orgs!
        org_id: Int!
        settings(path: String): jsonb!
        slug: String!
        stages(limit: Int, order_by: [stages_order_by!], where: stages_bool_exp): [stages!]!
        tickets(limit: Int, order_by: [tickets_order_by!], where: tickets_bool_exp): [tickets!]!
        users(
            limit: Int
            order_by: [boards_users_order_by!]
            where: boards_users_bool_exp
        ): [boards_users!]!
    }

    input boards_bool_exp {
        _and: [boards_bool_exp!]
        _not: boards_bool_exp
        _or: [boards_bool_exp!]
        access_type: String_comparison_exp
        archived_at: timestamptz_comparison_exp
        attached_users: boards_users_bool_exp
        authorized_users: auth_boards_access_bool_exp
        creator: users_bool_exp
        display_name: String_comparison_exp
        id: uuid_comparison_exp
        import: imports_bool_exp
        import_id: bigint_comparison_exp
        labels_config: jsonb_comparison_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        settings: jsonb_comparison_exp
        slug: String_comparison_exp
        stages: stages_bool_exp
        tickets: tickets_bool_exp
        users: boards_users_bool_exp
    }

    input boards_order_by {
        access_type: order_by
        archived_at: order_by
        creator: users_order_by
        display_name: order_by
        id: order_by
        import: imports_order_by
        import_id: order_by
        labels_config: order_by
        org: orgs_order_by
        org_id: order_by
        settings: order_by
        slug: order_by
    }

    type boards_users {
        board: boards!
        user: users!
    }

    input boards_users_bool_exp {
        _and: [boards_users_bool_exp!]
        _not: boards_users_bool_exp
        _or: [boards_users_bool_exp!]
        board: boards_bool_exp
        user: users_bool_exp
    }

    input boards_users_order_by {
        board: boards_order_by
        user: users_order_by
    }

    scalar citext

    input citext_comparison_exp {
        _eq: citext
        _gt: citext
        _gte: citext
        _ilike: citext
        _in: [citext!]
        _iregex: citext
        _is_null: Boolean
        _like: citext
        _lt: citext
        _lte: citext
        _neq: citext
        _nilike: citext
        _nin: [citext!]
        _niregex: citext
        _nlike: citext
        _nregex: citext
        _nsimilar: citext
        _regex: citext
        _similar: citext
    }

    type comments {
        author: users
        author_user_id: Int
        comment_html: String!
        comment_json(path: String): jsonb!
        comment_text: String!
        id: uuid!
        last_edited_at: timestamptz
        posted_at: timestamptz!
        thread: threads!
        thread_assignee: users
        thread_id: uuid!
        ticket: tickets!
        ticket_id: uuid!
    }

    input comments_bool_exp {
        _and: [comments_bool_exp!]
        _not: comments_bool_exp
        _or: [comments_bool_exp!]
        author: users_bool_exp
        author_user_id: Int_comparison_exp
        comment_html: String_comparison_exp
        comment_json: jsonb_comparison_exp
        comment_text: String_comparison_exp
        id: uuid_comparison_exp
        last_edited_at: timestamptz_comparison_exp
        posted_at: timestamptz_comparison_exp
        thread: threads_bool_exp
        thread_assignee: users_bool_exp
        thread_id: uuid_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
    }

    input comments_order_by {
        author: users_order_by
        author_user_id: order_by
        comment_html: order_by
        comment_json: order_by
        comment_text: order_by
        id: order_by
        last_edited_at: order_by
        posted_at: order_by
        thread: threads_order_by
        thread_assignee: users_order_by
        thread_id: order_by
        ticket: tickets_order_by
        ticket_id: order_by
    }

    scalar date

    input date_comparison_exp {
        _eq: date
        _gt: date
        _gte: date
        _in: [date!]
        _is_null: Boolean
        _lt: date
        _lte: date
        _neq: date
        _nin: [date!]
    }

    scalar float8

    input float8_comparison_exp {
        _eq: float8
        _gt: float8
        _gte: float8
        _in: [float8!]
        _is_null: Boolean
        _lt: float8
        _lte: float8
        _neq: float8
        _nin: [float8!]
    }

    type identities {
        auth0_id: String
        email_address: String!
        full_name: String
        id: uuid!
        is_email_address_verified: Boolean!
        names_confirmed_at: timestamptz
        onboarding_completed_at: timestamptz
        username: String
        users(limit: Int, order_by: [users_order_by!], where: users_bool_exp): [users!]!
    }

    input identities_bool_exp {
        _and: [identities_bool_exp!]
        _not: identities_bool_exp
        _or: [identities_bool_exp!]
        auth0_id: String_comparison_exp
        email_address: String_comparison_exp
        full_name: String_comparison_exp
        id: uuid_comparison_exp
        is_email_address_verified: Boolean_comparison_exp
        names_confirmed_at: timestamptz_comparison_exp
        onboarding_completed_at: timestamptz_comparison_exp
        username: String_comparison_exp
        users: users_bool_exp
    }

    input identities_order_by {
        auth0_id: order_by
        email_address: order_by
        full_name: order_by
        id: order_by
        is_email_address_verified: order_by
        names_confirmed_at: order_by
        onboarding_completed_at: order_by
        username: order_by
    }

    type imports {
        boards(limit: Int, order_by: [boards_order_by!], where: boards_bool_exp): [boards!]!
        enqueued_by_user: users
        est_pct_complete: float8
        id: bigint!
        job_status: String
        notes(path: String): jsonb
        org: orgs!
        processed_at: timestamptz
        source: String!
    }

    input imports_bool_exp {
        _and: [imports_bool_exp!]
        _not: imports_bool_exp
        _or: [imports_bool_exp!]
        boards: boards_bool_exp
        enqueued_by_user: users_bool_exp
        est_pct_complete: float8_comparison_exp
        id: bigint_comparison_exp
        job_status: String_comparison_exp
        notes: jsonb_comparison_exp
        org: orgs_bool_exp
        processed_at: timestamptz_comparison_exp
        source: String_comparison_exp
    }

    input imports_order_by {
        enqueued_by_user: users_order_by
        est_pct_complete: order_by
        id: order_by
        job_status: order_by
        notes: order_by
        org: orgs_order_by
        processed_at: order_by
        source: order_by
    }

    scalar jsonb

    input jsonb_cast_exp {
        String: String_comparison_exp
    }

    input jsonb_comparison_exp {
        _cast: jsonb_cast_exp
        _contained_in: jsonb
        _contains: jsonb
        _eq: jsonb
        _gt: jsonb
        _gte: jsonb
        _has_key: String
        _has_keys_all: [String!]
        _has_keys_any: [String!]
        _in: [jsonb!]
        _is_null: Boolean
        _lt: jsonb
        _lte: jsonb
        _neq: jsonb
        _nin: [jsonb!]
    }

    type labels_deprecated {
        archived_at: timestamptz
        color: String!
        id: uuid!
        org: orgs
        org_id: Int
        text: String!
        tickets_labels(
            limit: Int
            order_by: [tickets_labels_deprecated_order_by!]
            where: tickets_labels_deprecated_bool_exp
        ): [tickets_labels_deprecated!]!
    }

    input labels_deprecated_bool_exp {
        _and: [labels_deprecated_bool_exp!]
        _not: labels_deprecated_bool_exp
        _or: [labels_deprecated_bool_exp!]
        archived_at: timestamptz_comparison_exp
        color: String_comparison_exp
        id: uuid_comparison_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        text: String_comparison_exp
        tickets_labels: tickets_labels_deprecated_bool_exp
    }

    input labels_deprecated_order_by {
        archived_at: order_by
        color: order_by
        id: order_by
        org: orgs_order_by
        org_id: order_by
        text: order_by
    }

    type merge_requests {
        build_status: String
        build_status_info(path: String): jsonb
        closed_at: timestamptz
        closed_by_user: users
        has_changes_requested: Boolean!
        has_merge_conflict: Boolean!
        head_sha: String!
        id: uuid!
        last_activity_at: timestamptz
        lines_added: Int
        lines_removed: Int
        merged_at: timestamptz
        merged_by_user: users
        opened_at: timestamptz!
        opened_by_user: users
        org: orgs!
        org_id: Int!
        pending_reviews(path: String): jsonb
        provider: String!
        tickets(
            limit: Int
            order_by: [tickets_merge_requests_order_by!]
            where: tickets_merge_requests_bool_exp
        ): [tickets_merge_requests!]!
        title: String!
        url: String!
    }

    input merge_requests_bool_exp {
        _and: [merge_requests_bool_exp!]
        _not: merge_requests_bool_exp
        _or: [merge_requests_bool_exp!]
        build_status: String_comparison_exp
        build_status_info: jsonb_comparison_exp
        closed_at: timestamptz_comparison_exp
        closed_by_user: users_bool_exp
        has_changes_requested: Boolean_comparison_exp
        has_merge_conflict: Boolean_comparison_exp
        head_sha: String_comparison_exp
        id: uuid_comparison_exp
        last_activity_at: timestamptz_comparison_exp
        lines_added: Int_comparison_exp
        lines_removed: Int_comparison_exp
        merged_at: timestamptz_comparison_exp
        merged_by_user: users_bool_exp
        opened_at: timestamptz_comparison_exp
        opened_by_user: users_bool_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        pending_reviews: jsonb_comparison_exp
        provider: String_comparison_exp
        tickets: tickets_merge_requests_bool_exp
        title: String_comparison_exp
        url: String_comparison_exp
    }

    input merge_requests_order_by {
        build_status: order_by
        build_status_info: order_by
        closed_at: order_by
        closed_by_user: users_order_by
        has_changes_requested: order_by
        has_merge_conflict: order_by
        head_sha: order_by
        id: order_by
        last_activity_at: order_by
        lines_added: order_by
        lines_removed: order_by
        merged_at: order_by
        merged_by_user: users_order_by
        opened_at: order_by
        opened_by_user: users_order_by
        org: orgs_order_by
        org_id: order_by
        pending_reviews: order_by
        provider: order_by
        title: order_by
        url: order_by
    }

    enum order_by {
        asc
        asc_nulls_first
        asc_nulls_last
        desc
        desc_nulls_first
        desc_nulls_last
    }

    type org_domains {
        domain: String!
        id: Int!
        org: orgs!
        org_id: Int!
    }

    input org_domains_bool_exp {
        _and: [org_domains_bool_exp!]
        _not: org_domains_bool_exp
        _or: [org_domains_bool_exp!]
        domain: String_comparison_exp
        id: Int_comparison_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
    }

    input org_domains_order_by {
        domain: order_by
        id: order_by
        org: orgs_order_by
        org_id: order_by
    }

    type orgs {
        app_config(path: String): jsonb!
        authorized_users(
            limit: Int
            order_by: [auth_orgs_access_order_by!]
            where: auth_orgs_access_bool_exp
        ): [auth_orgs_access!]!
        boards(limit: Int, order_by: [boards_order_by!], where: boards_bool_exp): [boards!]!
        demographic_info(path: String): jsonb!
        disabled_at: timestamptz
        discord_guild_id: String
        display_name: String
        domains(
            limit: Int
            order_by: [org_domains_order_by!]
            where: org_domains_bool_exp
        ): [org_domains!]!
        id: Int!
        is_domain_signup_enabled: Boolean!
        is_internal: Boolean!
        is_multi_board: Boolean
        is_multi_user: Boolean
        is_zapier_enabled: Boolean
        labels(
            limit: Int
            order_by: [labels_deprecated_order_by!]
            where: labels_deprecated_bool_exp
        ): [labels_deprecated!]!
        next_ticket_ref: String!
        provisioned_by_identity_id: uuid
        settings(path: String): jsonb!
        slug: String!
        stages(limit: Int, order_by: [stages_order_by!], where: stages_bool_exp): [stages!]!
        ticket_size_scheme_deprecated(path: String): jsonb
        tickets(limit: Int, order_by: [tickets_order_by!], where: tickets_bool_exp): [tickets!]!
        trial_end_date: date
        users(limit: Int, order_by: [users_order_by!], where: users_bool_exp): [users!]!
    }

    input orgs_bool_exp {
        _and: [orgs_bool_exp!]
        _not: orgs_bool_exp
        _or: [orgs_bool_exp!]
        app_config: jsonb_comparison_exp
        authorized_users: auth_orgs_access_bool_exp
        boards: boards_bool_exp
        demographic_info: jsonb_comparison_exp
        disabled_at: timestamptz_comparison_exp
        discord_guild_id: String_comparison_exp
        display_name: String_comparison_exp
        domains: org_domains_bool_exp
        id: Int_comparison_exp
        is_domain_signup_enabled: Boolean_comparison_exp
        is_internal: Boolean_comparison_exp
        is_multi_board: Boolean_comparison_exp
        is_multi_user: Boolean_comparison_exp
        is_zapier_enabled: Boolean_comparison_exp
        labels: labels_deprecated_bool_exp
        next_ticket_ref: String_comparison_exp
        provisioned_by_identity_id: uuid_comparison_exp
        settings: jsonb_comparison_exp
        slug: String_comparison_exp
        stages: stages_bool_exp
        ticket_size_scheme_deprecated: jsonb_comparison_exp
        tickets: tickets_bool_exp
        trial_end_date: date_comparison_exp
        users: users_bool_exp
    }

    input orgs_order_by {
        app_config: order_by
        demographic_info: order_by
        disabled_at: order_by
        discord_guild_id: order_by
        display_name: order_by
        id: order_by
        is_domain_signup_enabled: order_by
        is_internal: order_by
        is_multi_board: order_by
        is_multi_user: order_by
        is_zapier_enabled: order_by
        next_ticket_ref: order_by
        provisioned_by_identity_id: order_by
        settings: order_by
        slug: order_by
        ticket_size_scheme_deprecated: order_by
        trial_end_date: order_by
    }

    type query_root {
        auth_boards_access(
            limit: Int
            order_by: [auth_boards_access_order_by!]
            where: auth_boards_access_bool_exp
        ): [auth_boards_access!]!
        auth_orgs_access(
            limit: Int
            order_by: [auth_orgs_access_order_by!]
            where: auth_orgs_access_bool_exp
        ): [auth_orgs_access!]!
        boards(limit: Int, order_by: [boards_order_by!], where: boards_bool_exp): [boards!]!
        boards_by_pk(id: uuid!): boards
        boards_users(
            limit: Int
            order_by: [boards_users_order_by!]
            where: boards_users_bool_exp
        ): [boards_users!]!
        comments(limit: Int, order_by: [comments_order_by!], where: comments_bool_exp): [comments!]!
        comments_by_pk(id: uuid!): comments
        identities(
            limit: Int
            order_by: [identities_order_by!]
            where: identities_bool_exp
        ): [identities!]!
        identities_by_pk(id: uuid!): identities
        imports(limit: Int, order_by: [imports_order_by!], where: imports_bool_exp): [imports!]!
        imports_by_pk(id: bigint!): imports
        labels_deprecated(
            limit: Int
            order_by: [labels_deprecated_order_by!]
            where: labels_deprecated_bool_exp
        ): [labels_deprecated!]!
        labels_deprecated_by_pk(id: uuid!): labels_deprecated
        merge_requests(
            limit: Int
            order_by: [merge_requests_order_by!]
            where: merge_requests_bool_exp
        ): [merge_requests!]!
        merge_requests_by_pk(id: uuid!): merge_requests
        org_domains(
            limit: Int
            order_by: [org_domains_order_by!]
            where: org_domains_bool_exp
        ): [org_domains!]!
        org_domains_by_pk(id: Int!): org_domains
        orgs(limit: Int, order_by: [orgs_order_by!], where: orgs_bool_exp): [orgs!]!
        orgs_by_pk(id: Int!): orgs
        stages(limit: Int, order_by: [stages_order_by!], where: stages_bool_exp): [stages!]!
        stages_by_pk(id: uuid!): stages
        tasklists(
            limit: Int
            order_by: [tasklists_order_by!]
            where: tasklists_bool_exp
        ): [tasklists!]!
        tasklists_by_pk(id: uuid!): tasklists
        tasks(limit: Int, order_by: [tasks_order_by!], where: tasks_bool_exp): [tasks!]!
        tasks_by_pk(id: uuid!): tasks
        threads(limit: Int, order_by: [threads_order_by!], where: threads_bool_exp): [threads!]!
        threads_by_pk(id: uuid!): threads
        ticket_attachments(
            limit: Int
            order_by: [ticket_attachments_order_by!]
            where: ticket_attachments_bool_exp
        ): [ticket_attachments!]!
        ticket_attachments_by_pk(id: uuid!): ticket_attachments
        ticket_detail_views(
            limit: Int
            order_by: [ticket_detail_views_order_by!]
            where: ticket_detail_views_bool_exp
        ): [ticket_detail_views!]!
        ticket_detail_views_by_pk(ticket_id: uuid!, user_id: Int!): ticket_detail_views
        ticket_history_events(
            limit: Int
            order_by: [ticket_history_events_order_by!]
            where: ticket_history_events_bool_exp
        ): [ticket_history_events!]!
        ticket_history_events_by_pk(id: uuid!): ticket_history_events
        ticket_label_attachments(
            limit: Int
            order_by: [ticket_label_attachments_order_by!]
            where: ticket_label_attachments_bool_exp
        ): [ticket_label_attachments!]!
        ticket_label_attachments_by_pk(id: uuid!): ticket_label_attachments
        ticket_resources(
            limit: Int
            order_by: [ticket_resources_order_by!]
            where: ticket_resources_bool_exp
        ): [ticket_resources!]!
        ticket_resources_by_pk(id: Int!): ticket_resources
        tickets(limit: Int, order_by: [tickets_order_by!], where: tickets_bool_exp): [tickets!]!
        tickets_by_pk(id: uuid!): tickets
        tickets_labels_deprecated(
            limit: Int
            order_by: [tickets_labels_deprecated_order_by!]
            where: tickets_labels_deprecated_bool_exp
        ): [tickets_labels_deprecated!]!
        tickets_labels_deprecated_by_pk(
            label_id: uuid!
            ticket_id: uuid!
        ): tickets_labels_deprecated
        tickets_merge_requests(
            limit: Int
            order_by: [tickets_merge_requests_order_by!]
            where: tickets_merge_requests_bool_exp
        ): [tickets_merge_requests!]!
        tickets_merge_requests_by_pk(
            merge_request_id: uuid!
            ticket_id: uuid!
        ): tickets_merge_requests
        tickets_owners(
            limit: Int
            order_by: [tickets_owners_order_by!]
            where: tickets_owners_bool_exp
        ): [tickets_owners!]!
        tickets_owners_by_pk(ticket_id: uuid!, user_id: Int!): tickets_owners
        tickets_watchers(
            limit: Int
            order_by: [tickets_watchers_order_by!]
            where: tickets_watchers_bool_exp
        ): [tickets_watchers!]!
        tickets_watchers_by_pk(ticket_id: uuid!, user_id: Int!): tickets_watchers
        user_app_events(
            limit: Int
            order_by: [user_app_events_order_by!]
            where: user_app_events_bool_exp
        ): [user_app_events!]!
        user_app_events_by_pk(id: bigint!): user_app_events
        users(limit: Int, order_by: [users_order_by!], where: users_bool_exp): [users!]!
        users_by_pk(id: Int!): users
        users_tickets_plans(
            limit: Int
            order_by: [users_tickets_plans_order_by!]
            where: users_tickets_plans_bool_exp
        ): [users_tickets_plans!]!
        users_tickets_plans_by_pk(ticket_id: uuid!, user_id: Int!): users_tickets_plans
    }

    type stages {
        board: boards!
        board_id: uuid!
        board_pos: float8
        deleted_at: timestamptz
        display_name: String!
        id: uuid!
        is_empty: Boolean
        max_ticket_stage_pos: float8
        min_ticket_stage_pos: float8
        org: orgs!
        org_id: Int!
        role: String!
        tickets(limit: Int, order_by: [tickets_order_by!], where: tickets_bool_exp): [tickets!]!
    }

    input stages_bool_exp {
        _and: [stages_bool_exp!]
        _not: stages_bool_exp
        _or: [stages_bool_exp!]
        board: boards_bool_exp
        board_id: uuid_comparison_exp
        board_pos: float8_comparison_exp
        deleted_at: timestamptz_comparison_exp
        display_name: String_comparison_exp
        id: uuid_comparison_exp
        is_empty: Boolean_comparison_exp
        max_ticket_stage_pos: float8_comparison_exp
        min_ticket_stage_pos: float8_comparison_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        role: String_comparison_exp
        tickets: tickets_bool_exp
    }

    input stages_order_by {
        board: boards_order_by
        board_id: order_by
        board_pos: order_by
        deleted_at: order_by
        display_name: order_by
        id: order_by
        is_empty: order_by
        max_ticket_stage_pos: order_by
        min_ticket_stage_pos: order_by
        org: orgs_order_by
        org_id: order_by
        role: order_by
    }

    type tasklists {
        added_at: timestamptz!
        deleted_at: timestamptz
        id: uuid!
        stage: stages
        stage_id: uuid!
        tasks(limit: Int, order_by: [tasks_order_by!], where: tasks_bool_exp): [tasks!]!
        ticket: tickets!
        ticket_id: uuid!
        title: String!
        uuid: uuid!
    }

    input tasklists_bool_exp {
        _and: [tasklists_bool_exp!]
        _not: tasklists_bool_exp
        _or: [tasklists_bool_exp!]
        added_at: timestamptz_comparison_exp
        deleted_at: timestamptz_comparison_exp
        id: uuid_comparison_exp
        stage: stages_bool_exp
        stage_id: uuid_comparison_exp
        tasks: tasks_bool_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        title: String_comparison_exp
        uuid: uuid_comparison_exp
    }

    input tasklists_order_by {
        added_at: order_by
        deleted_at: order_by
        id: order_by
        stage: stages_order_by
        stage_id: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        title: order_by
        uuid: order_by
    }

    type tasks {
        assigned_to_user_id: Int
        assignee: users
        child_ticket: tickets
        child_ticket_id: uuid
        deleted_at: timestamptz
        due_date: date
        id: uuid!
        is_complete: Boolean!
        task_type: String
        tasklist: tasklists!
        tasklist_id: uuid!
        tasklist_pos: float8
        ticket: tickets!
        ticket_id: uuid!
        title: String
    }

    input tasks_bool_exp {
        _and: [tasks_bool_exp!]
        _not: tasks_bool_exp
        _or: [tasks_bool_exp!]
        assigned_to_user_id: Int_comparison_exp
        assignee: users_bool_exp
        child_ticket: tickets_bool_exp
        child_ticket_id: uuid_comparison_exp
        deleted_at: timestamptz_comparison_exp
        due_date: date_comparison_exp
        id: uuid_comparison_exp
        is_complete: Boolean_comparison_exp
        task_type: String_comparison_exp
        tasklist: tasklists_bool_exp
        tasklist_id: uuid_comparison_exp
        tasklist_pos: float8_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        title: String_comparison_exp
    }

    input tasks_order_by {
        assigned_to_user_id: order_by
        assignee: users_order_by
        child_ticket: tickets_order_by
        child_ticket_id: order_by
        deleted_at: order_by
        due_date: order_by
        id: order_by
        is_complete: order_by
        task_type: order_by
        tasklist: tasklists_order_by
        tasklist_id: order_by
        tasklist_pos: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        title: order_by
    }

    type threads {
        assigned_at: timestamptz
        assigned_by_user_id: Int
        assigned_to_user_id: Int
        assignee: users
        assigner: users
        blocker_added_at: timestamptz
        blocker_author: users
        blocker_author_user_id: Int
        blocker_text: String
        blocker_ticket: tickets
        blocker_ticket_id: uuid
        blocker_type: String
        comments(limit: Int, order_by: [comments_order_by!], where: comments_bool_exp): [comments!]!
        created_by_user_id: Int
        creator: users
        id: uuid!
        opened_at: timestamptz!
        resolved_at: timestamptz
        resolved_by_user_id: Int
        resolver: users
        ticket: tickets!
        ticket_history_events(
            limit: Int
            order_by: [ticket_history_events_order_by!]
            where: ticket_history_events_bool_exp
        ): [ticket_history_events!]!
        ticket_id: uuid!
    }

    input threads_bool_exp {
        _and: [threads_bool_exp!]
        _not: threads_bool_exp
        _or: [threads_bool_exp!]
        assigned_at: timestamptz_comparison_exp
        assigned_by_user_id: Int_comparison_exp
        assigned_to_user_id: Int_comparison_exp
        assignee: users_bool_exp
        assigner: users_bool_exp
        blocker_added_at: timestamptz_comparison_exp
        blocker_author: users_bool_exp
        blocker_author_user_id: Int_comparison_exp
        blocker_text: String_comparison_exp
        blocker_ticket: tickets_bool_exp
        blocker_ticket_id: uuid_comparison_exp
        blocker_type: String_comparison_exp
        comments: comments_bool_exp
        created_by_user_id: Int_comparison_exp
        creator: users_bool_exp
        id: uuid_comparison_exp
        opened_at: timestamptz_comparison_exp
        resolved_at: timestamptz_comparison_exp
        resolved_by_user_id: Int_comparison_exp
        resolver: users_bool_exp
        ticket: tickets_bool_exp
        ticket_history_events: ticket_history_events_bool_exp
        ticket_id: uuid_comparison_exp
    }

    input threads_order_by {
        assigned_at: order_by
        assigned_by_user_id: order_by
        assigned_to_user_id: order_by
        assignee: users_order_by
        assigner: users_order_by
        blocker_added_at: order_by
        blocker_author: users_order_by
        blocker_author_user_id: order_by
        blocker_text: order_by
        blocker_ticket: tickets_order_by
        blocker_ticket_id: order_by
        blocker_type: order_by
        created_by_user_id: order_by
        creator: users_order_by
        id: order_by
        opened_at: order_by
        resolved_at: order_by
        resolved_by_user_id: order_by
        resolver: users_order_by
        ticket: tickets_order_by
        ticket_id: order_by
    }

    type ticket_attachments {
        attached_at: timestamptz!
        attached_by_user: users
        attached_by_user_id: Int
        deleted_at: timestamptz
        id: uuid!
        key: String
        ticket: tickets!
        ticket_id: uuid!
        title: String!
        url: String
    }

    input ticket_attachments_bool_exp {
        _and: [ticket_attachments_bool_exp!]
        _not: ticket_attachments_bool_exp
        _or: [ticket_attachments_bool_exp!]
        attached_at: timestamptz_comparison_exp
        attached_by_user: users_bool_exp
        attached_by_user_id: Int_comparison_exp
        deleted_at: timestamptz_comparison_exp
        id: uuid_comparison_exp
        key: String_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        title: String_comparison_exp
        url: String_comparison_exp
    }

    input ticket_attachments_order_by {
        attached_at: order_by
        attached_by_user: users_order_by
        attached_by_user_id: order_by
        deleted_at: order_by
        id: order_by
        key: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        title: order_by
        url: order_by
    }

    type ticket_detail_views {
        last_viewed_at: timestamptz!
        ticket: tickets
        ticket_id: uuid!
        user: users!
        user_id: Int!
    }

    input ticket_detail_views_bool_exp {
        _and: [ticket_detail_views_bool_exp!]
        _not: ticket_detail_views_bool_exp
        _or: [ticket_detail_views_bool_exp!]
        last_viewed_at: timestamptz_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        user: users_bool_exp
        user_id: Int_comparison_exp
    }

    input ticket_detail_views_order_by {
        last_viewed_at: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        user: users_order_by
        user_id: order_by
    }

    type ticket_history_events {
        event_at: timestamptz!
        event_details(path: String): jsonb!
        event_on_label: labels_deprecated
        event_on_label_id_deprecated: uuid
        event_on_merge_request: merge_requests
        event_on_merge_request_id: uuid
        event_on_stage: stages
        event_on_stage_id: uuid
        event_on_task: tasks
        event_on_task_id: uuid
        event_on_tasklist: tasklists
        event_on_tasklist_id: uuid
        event_on_thread: threads
        event_on_thread_id: uuid
        event_on_ticket_attachment: ticket_attachments
        event_on_ticket_attachment_id: uuid
        event_on_ticket_label_attachment: ticket_label_attachments
        event_on_ticket_label_attachment_id: uuid
        event_on_ticket_resource: ticket_resources
        event_on_ticket_resource_id: Int
        event_on_user: users
        event_on_user_id: Int
        event_type: String!
        id: uuid!
        import: imports
        ticket: tickets!
        ticket_id: uuid!
        user: users
        user_id: Int
    }

    input ticket_history_events_bool_exp {
        _and: [ticket_history_events_bool_exp!]
        _not: ticket_history_events_bool_exp
        _or: [ticket_history_events_bool_exp!]
        event_at: timestamptz_comparison_exp
        event_details: jsonb_comparison_exp
        event_on_label: labels_deprecated_bool_exp
        event_on_label_id_deprecated: uuid_comparison_exp
        event_on_merge_request: merge_requests_bool_exp
        event_on_merge_request_id: uuid_comparison_exp
        event_on_stage: stages_bool_exp
        event_on_stage_id: uuid_comparison_exp
        event_on_task: tasks_bool_exp
        event_on_task_id: uuid_comparison_exp
        event_on_tasklist: tasklists_bool_exp
        event_on_tasklist_id: uuid_comparison_exp
        event_on_thread: threads_bool_exp
        event_on_thread_id: uuid_comparison_exp
        event_on_ticket_attachment: ticket_attachments_bool_exp
        event_on_ticket_attachment_id: uuid_comparison_exp
        event_on_ticket_label_attachment: ticket_label_attachments_bool_exp
        event_on_ticket_label_attachment_id: uuid_comparison_exp
        event_on_ticket_resource: ticket_resources_bool_exp
        event_on_ticket_resource_id: Int_comparison_exp
        event_on_user: users_bool_exp
        event_on_user_id: Int_comparison_exp
        event_type: String_comparison_exp
        id: uuid_comparison_exp
        import: imports_bool_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        user: users_bool_exp
        user_id: Int_comparison_exp
    }

    input ticket_history_events_order_by {
        event_at: order_by
        event_details: order_by
        event_on_label: labels_deprecated_order_by
        event_on_label_id_deprecated: order_by
        event_on_merge_request: merge_requests_order_by
        event_on_merge_request_id: order_by
        event_on_stage: stages_order_by
        event_on_stage_id: order_by
        event_on_task: tasks_order_by
        event_on_task_id: order_by
        event_on_tasklist: tasklists_order_by
        event_on_tasklist_id: order_by
        event_on_thread: threads_order_by
        event_on_thread_id: order_by
        event_on_ticket_attachment: ticket_attachments_order_by
        event_on_ticket_attachment_id: order_by
        event_on_ticket_label_attachment: ticket_label_attachments_order_by
        event_on_ticket_label_attachment_id: order_by
        event_on_ticket_resource: ticket_resources_order_by
        event_on_ticket_resource_id: order_by
        event_on_user: users_order_by
        event_on_user_id: order_by
        event_type: order_by
        id: order_by
        import: imports_order_by
        ticket: tickets_order_by
        ticket_id: order_by
        user: users_order_by
        user_id: order_by
    }

    type ticket_label_attachments {
        color: String!
        detached_at: timestamptz
        id: uuid!
        text: String!
        ticket: tickets!
        ticket_id: uuid!
    }

    input ticket_label_attachments_bool_exp {
        _and: [ticket_label_attachments_bool_exp!]
        _not: ticket_label_attachments_bool_exp
        _or: [ticket_label_attachments_bool_exp!]
        color: String_comparison_exp
        detached_at: timestamptz_comparison_exp
        id: uuid_comparison_exp
        text: String_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
    }

    input ticket_label_attachments_order_by {
        color: order_by
        detached_at: order_by
        id: order_by
        text: order_by
        ticket: tickets_order_by
        ticket_id: order_by
    }

    type ticket_resources {
        added_at: timestamptz!
        id: Int!
        ticket: tickets!
        ticket_id: uuid!
        title: String!
        url: String!
    }

    input ticket_resources_bool_exp {
        _and: [ticket_resources_bool_exp!]
        _not: ticket_resources_bool_exp
        _or: [ticket_resources_bool_exp!]
        added_at: timestamptz_comparison_exp
        id: Int_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        title: String_comparison_exp
        url: String_comparison_exp
    }

    input ticket_resources_order_by {
        added_at: order_by
        id: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        title: order_by
        url: order_by
    }

    type tickets {
        archived_at: timestamptz
        attachments(
            limit: Int
            order_by: [ticket_attachments_order_by!]
            where: ticket_attachments_bool_exp
        ): [ticket_attachments!]!
        blocker_of_threads(
            limit: Int
            order_by: [threads_order_by!]
            where: threads_bool_exp
        ): [threads!]!
        board: boards!
        board_id: uuid!
        child_of_tasks(limit: Int, order_by: [tasks_order_by!], where: tasks_bool_exp): [tasks!]!
        comments(limit: Int, order_by: [comments_order_by!], where: comments_bool_exp): [comments!]!
        created_by_user_id: Int
        creator: users
        description_html: String
        description_json(path: String): jsonb
        description_text: String
        due_date: date
        external_source_display_id: String
        id: uuid!
        label_attachments(
            limit: Int
            order_by: [ticket_label_attachments_order_by!]
            where: ticket_label_attachments_bool_exp
        ): [ticket_label_attachments!]!
        labels(
            limit: Int
            order_by: [tickets_labels_deprecated_order_by!]
            where: tickets_labels_deprecated_bool_exp
        ): [tickets_labels_deprecated!]!
        merge_requests(
            limit: Int
            order_by: [tickets_merge_requests_order_by!]
            where: tickets_merge_requests_bool_exp
        ): [tickets_merge_requests!]!
        org: orgs!
        org_id: Int!
        owners(
            limit: Int
            order_by: [tickets_owners_order_by!]
            where: tickets_owners_bool_exp
        ): [tickets_owners!]!
        ref: String!
        resources(
            limit: Int
            order_by: [ticket_resources_order_by!]
            where: ticket_resources_bool_exp
        ): [ticket_resources!]!
        size_spec(path: String): jsonb
        slug: String!
        stage: stages!
        stage_id: uuid!
        stage_pos: float8
        tasklists(
            limit: Int
            order_by: [tasklists_order_by!]
            where: tasklists_bool_exp
        ): [tasklists!]!
        tasks(limit: Int, order_by: [tasks_order_by!], where: tasks_bool_exp): [tasks!]!
        threads(limit: Int, order_by: [threads_order_by!], where: threads_bool_exp): [threads!]!
        ticket_detail_views(
            limit: Int
            order_by: [ticket_detail_views_order_by!]
            where: ticket_detail_views_bool_exp
        ): [ticket_detail_views!]!
        ticket_history_events(
            limit: Int
            order_by: [ticket_history_events_order_by!]
            where: ticket_history_events_bool_exp
        ): [ticket_history_events!]!
        title: String!
        trashed_at: timestamptz
        user_plans(
            limit: Int
            order_by: [users_tickets_plans_order_by!]
            where: users_tickets_plans_bool_exp
        ): [users_tickets_plans!]!
        watchers(
            limit: Int
            order_by: [tickets_watchers_order_by!]
            where: tickets_watchers_bool_exp
        ): [tickets_watchers!]!
    }

    input tickets_bool_exp {
        _and: [tickets_bool_exp!]
        _not: tickets_bool_exp
        _or: [tickets_bool_exp!]
        archived_at: timestamptz_comparison_exp
        attachments: ticket_attachments_bool_exp
        blocker_of_threads: threads_bool_exp
        board: boards_bool_exp
        board_id: uuid_comparison_exp
        child_of_tasks: tasks_bool_exp
        comments: comments_bool_exp
        created_by_user_id: Int_comparison_exp
        creator: users_bool_exp
        description_html: String_comparison_exp
        description_json: jsonb_comparison_exp
        description_text: String_comparison_exp
        due_date: date_comparison_exp
        external_source_display_id: String_comparison_exp
        id: uuid_comparison_exp
        label_attachments: ticket_label_attachments_bool_exp
        labels: tickets_labels_deprecated_bool_exp
        merge_requests: tickets_merge_requests_bool_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        owners: tickets_owners_bool_exp
        ref: String_comparison_exp
        resources: ticket_resources_bool_exp
        size_spec: jsonb_comparison_exp
        slug: String_comparison_exp
        stage: stages_bool_exp
        stage_id: uuid_comparison_exp
        stage_pos: float8_comparison_exp
        tasklists: tasklists_bool_exp
        tasks: tasks_bool_exp
        threads: threads_bool_exp
        ticket_detail_views: ticket_detail_views_bool_exp
        ticket_history_events: ticket_history_events_bool_exp
        title: String_comparison_exp
        trashed_at: timestamptz_comparison_exp
        user_plans: users_tickets_plans_bool_exp
        watchers: tickets_watchers_bool_exp
    }

    type tickets_labels_deprecated {
        label: labels_deprecated!
        label_id: uuid!
        ticket: tickets!
        ticket_id: uuid!
    }

    input tickets_labels_deprecated_bool_exp {
        _and: [tickets_labels_deprecated_bool_exp!]
        _not: tickets_labels_deprecated_bool_exp
        _or: [tickets_labels_deprecated_bool_exp!]
        label: labels_deprecated_bool_exp
        label_id: uuid_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
    }

    input tickets_labels_deprecated_order_by {
        label: labels_deprecated_order_by
        label_id: order_by
        ticket: tickets_order_by
        ticket_id: order_by
    }

    type tickets_merge_requests {
        merge_request: merge_requests!
        merge_request_id: uuid!
        ticket: tickets!
        ticket_id: uuid!
    }

    input tickets_merge_requests_bool_exp {
        _and: [tickets_merge_requests_bool_exp!]
        _not: tickets_merge_requests_bool_exp
        _or: [tickets_merge_requests_bool_exp!]
        merge_request: merge_requests_bool_exp
        merge_request_id: uuid_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
    }

    input tickets_merge_requests_order_by {
        merge_request: merge_requests_order_by
        merge_request_id: order_by
        ticket: tickets_order_by
        ticket_id: order_by
    }

    input tickets_order_by {
        archived_at: order_by
        board: boards_order_by
        board_id: order_by
        created_by_user_id: order_by
        creator: users_order_by
        description_html: order_by
        description_json: order_by
        description_text: order_by
        due_date: order_by
        external_source_display_id: order_by
        id: order_by
        org: orgs_order_by
        org_id: order_by
        ref: order_by
        size_spec: order_by
        slug: order_by
        stage: stages_order_by
        stage_id: order_by
        stage_pos: order_by
        title: order_by
        trashed_at: order_by
    }

    type tickets_owners {
        added_at: timestamptz!
        added_by_user_id: Int
        owner: users!
        ticket: tickets!
        ticket_id: uuid!
        type: String!
        user_id: Int!
    }

    input tickets_owners_bool_exp {
        _and: [tickets_owners_bool_exp!]
        _not: tickets_owners_bool_exp
        _or: [tickets_owners_bool_exp!]
        added_at: timestamptz_comparison_exp
        added_by_user_id: Int_comparison_exp
        owner: users_bool_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        type: String_comparison_exp
        user_id: Int_comparison_exp
    }

    input tickets_owners_order_by {
        added_at: order_by
        added_by_user_id: order_by
        owner: users_order_by
        ticket: tickets_order_by
        ticket_id: order_by
        type: order_by
        user_id: order_by
    }

    type tickets_watchers {
        ticket: tickets!
        ticket_id: uuid!
        user_id: Int!
        watcher: users!
    }

    input tickets_watchers_bool_exp {
        _and: [tickets_watchers_bool_exp!]
        _not: tickets_watchers_bool_exp
        _or: [tickets_watchers_bool_exp!]
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        user_id: Int_comparison_exp
        watcher: users_bool_exp
    }

    input tickets_watchers_order_by {
        ticket: tickets_order_by
        ticket_id: order_by
        user_id: order_by
        watcher: users_order_by
    }

    scalar timestamptz

    input timestamptz_comparison_exp {
        _eq: timestamptz
        _gt: timestamptz
        _gte: timestamptz
        _in: [timestamptz!]
        _is_null: Boolean
        _lt: timestamptz
        _lte: timestamptz
        _neq: timestamptz
        _nin: [timestamptz!]
    }

    type user_app_events {
        id: bigint!
    }

    input user_app_events_bool_exp {
        _and: [user_app_events_bool_exp!]
        _not: user_app_events_bool_exp
        _or: [user_app_events_bool_exp!]
        id: bigint_comparison_exp
    }

    input user_app_events_order_by {
        id: order_by
    }

    type users {
        app_config(path: String): jsonb!
        app_state(path: String): jsonb!
        appearance_preferences(path: String): jsonb!
        assigned_threads(
            limit: Int
            order_by: [threads_order_by!]
            where: threads_bool_exp
        ): [threads!]!
        avatar_url: String
        comments(limit: Int, order_by: [comments_order_by!], where: comments_bool_exp): [comments!]!
        created_threads(
            limit: Int
            order_by: [threads_order_by!]
            where: threads_bool_exp
        ): [threads!]!
        created_tickets(
            limit: Int
            order_by: [tickets_order_by!]
            where: tickets_bool_exp
        ): [tickets!]!
        demographic_preferences(path: String): jsonb!
        disabled_at: timestamptz
        discord_dm_channel_id: String
        discord_user_id: String
        full_name: String
        github_login: String
        id: Int!
        identity: identities
        identity_id: uuid
        is_pending_disposition: Boolean!
        last_logged_in_at: timestamptz
        last_viewed_work_at: timestamptz
        name: citext!
        notification_preferences(path: String): jsonb!
        onboarding_completed_at: timestamptz
        onboarding_type: String!
        org: orgs!
        org_id: Int!
        owned_tasks(limit: Int, order_by: [tasks_order_by!], where: tasks_bool_exp): [tasks!]!
        owned_tickets(
            limit: Int
            order_by: [tickets_owners_order_by!]
            where: tickets_owners_bool_exp
        ): [tickets_owners!]!
        pending_invite_sent_at: timestamptz
        resolved_threads(
            limit: Int
            order_by: [threads_order_by!]
            where: threads_bool_exp
        ): [threads!]!
        role: String!
        settings(path: String): jsonb!
        slack_dm_channel_id: String
        slack_team_id: String
        slack_user_id: String
        slug: String!
        ticket_plans(
            limit: Int
            order_by: [users_tickets_plans_order_by!]
            where: users_tickets_plans_bool_exp
        ): [users_tickets_plans!]!
        watched_tickets(
            limit: Int
            order_by: [tickets_watchers_order_by!]
            where: tickets_watchers_bool_exp
        ): [tickets_watchers!]!
    }

    input users_bool_exp {
        _and: [users_bool_exp!]
        _not: users_bool_exp
        _or: [users_bool_exp!]
        app_config: jsonb_comparison_exp
        app_state: jsonb_comparison_exp
        appearance_preferences: jsonb_comparison_exp
        assigned_threads: threads_bool_exp
        avatar_url: String_comparison_exp
        comments: comments_bool_exp
        created_threads: threads_bool_exp
        created_tickets: tickets_bool_exp
        demographic_preferences: jsonb_comparison_exp
        disabled_at: timestamptz_comparison_exp
        discord_dm_channel_id: String_comparison_exp
        discord_user_id: String_comparison_exp
        full_name: String_comparison_exp
        github_login: String_comparison_exp
        id: Int_comparison_exp
        identity: identities_bool_exp
        identity_id: uuid_comparison_exp
        is_pending_disposition: Boolean_comparison_exp
        last_logged_in_at: timestamptz_comparison_exp
        last_viewed_work_at: timestamptz_comparison_exp
        name: citext_comparison_exp
        notification_preferences: jsonb_comparison_exp
        onboarding_completed_at: timestamptz_comparison_exp
        onboarding_type: String_comparison_exp
        org: orgs_bool_exp
        org_id: Int_comparison_exp
        owned_tasks: tasks_bool_exp
        owned_tickets: tickets_owners_bool_exp
        pending_invite_sent_at: timestamptz_comparison_exp
        resolved_threads: threads_bool_exp
        role: String_comparison_exp
        settings: jsonb_comparison_exp
        slack_dm_channel_id: String_comparison_exp
        slack_team_id: String_comparison_exp
        slack_user_id: String_comparison_exp
        slug: String_comparison_exp
        ticket_plans: users_tickets_plans_bool_exp
        watched_tickets: tickets_watchers_bool_exp
    }

    input users_order_by {
        app_config: order_by
        app_state: order_by
        appearance_preferences: order_by
        avatar_url: order_by
        demographic_preferences: order_by
        disabled_at: order_by
        discord_dm_channel_id: order_by
        discord_user_id: order_by
        full_name: order_by
        github_login: order_by
        id: order_by
        identity: identities_order_by
        identity_id: order_by
        is_pending_disposition: order_by
        last_logged_in_at: order_by
        last_viewed_work_at: order_by
        name: order_by
        notification_preferences: order_by
        onboarding_completed_at: order_by
        onboarding_type: order_by
        org: orgs_order_by
        org_id: order_by
        pending_invite_sent_at: order_by
        role: order_by
        settings: order_by
        slack_dm_channel_id: order_by
        slack_team_id: order_by
        slack_user_id: order_by
        slug: order_by
    }

    type users_tickets_plans {
        plan_pos: float8!
        plan_type: String!
        ticket: tickets!
        ticket_id: uuid!
        user: users!
        user_id: Int!
    }

    input users_tickets_plans_bool_exp {
        _and: [users_tickets_plans_bool_exp!]
        _not: users_tickets_plans_bool_exp
        _or: [users_tickets_plans_bool_exp!]
        plan_pos: float8_comparison_exp
        plan_type: String_comparison_exp
        ticket: tickets_bool_exp
        ticket_id: uuid_comparison_exp
        user: users_bool_exp
        user_id: Int_comparison_exp
    }

    input users_tickets_plans_order_by {
        plan_pos: order_by
        plan_type: order_by
        ticket: tickets_order_by
        ticket_id: order_by
        user: users_order_by
        user_id: order_by
    }

    scalar uuid

    input uuid_comparison_exp {
        _eq: uuid
        _gt: uuid
        _gte: uuid
        _in: [uuid!]
        _is_null: Boolean
        _lt: uuid
        _lte: uuid
        _neq: uuid
        _nin: [uuid!]
    }
`;
