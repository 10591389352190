import React, { useEffect } from "react";

import { useSetRecoilState } from "recoil";

import { integrationsSetupStatusState, useMaybeCurrentOrg, useMaybeCurrentUser } from "AppState";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useLiveQuery } from "lib/apollo/useLiveQuery";
import { gql } from "lib/graphql/__generated__";
import { CurrentOrg, CurrentUser } from "lib/types/common/currentUser";

function IntegrationsStatusMonitorInternal({
    currentOrg,
    currentUser,
}: {
    currentOrg: CurrentOrg;
    currentUser: CurrentUser;
}) {
    const { isFeatureEnabled } = useFeatureFlags();
    const setIntegrationsSetupStatus = useSetRecoilState(integrationsSetupStatusState);

    const componentQuery = useLiveQuery({
        query: IntegrationsStatusMonitor.queries.component,
        variables: {
            orgId: currentOrg.id,
        },
    });

    useEffect(() => {
        if (componentQuery.loading || !componentQuery.data) {
            return;
        }

        const isSlackActive = !!currentUser.slack_user_id;
        const isGitHubActive = componentQuery.data
            ? !!componentQuery.data.org?.github_app_installations[0] ||
              isFeatureEnabled({ feature: Enums.Feature.GITHUB_INTEGRATION_FAKE_INSTALLATION })
            : null;
        const isDiscordActive = !!componentQuery.data.org?.discord_guild_id || null;
        const isZapierActive = !!componentQuery.data.org?.is_zapier_enabled || null;

        setIntegrationsSetupStatus({
            isReady: true,
            slack: isSlackActive,
            github: isGitHubActive,
            discord: isDiscordActive,
            zapier: isZapierActive,
        });
    }, [
        currentUser.role,
        currentUser.slack_user_id,
        componentQuery.loading,
        componentQuery.data,
        isFeatureEnabled,
        setIntegrationsSetupStatus,
    ]);

    return null;
}

export function IntegrationsStatusMonitor() {
    const currentOrg = useMaybeCurrentOrg();
    const currentUser = useMaybeCurrentUser();

    if (!currentOrg || !currentUser) {
        return null;
    }

    return <IntegrationsStatusMonitorInternal currentOrg={currentOrg} currentUser={currentUser} />;
}

IntegrationsStatusMonitor.queries = {
    component: gql(/* GraphQL */ `
        query IntegrationsStatusMonitor($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                id
                discord_guild_id
                is_zapier_enabled

                github_app_installations(where: { uninstalled_at: { _is_null: true } }) {
                    id
                }
            }
        }
    `),
};
