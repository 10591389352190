import { MutationFunctionOptions, useMutation } from "@apollo/client";
import { P } from "c9r-common";

export { useMutation };
export type MutationCallbackOptions = Omit<MutationFunctionOptions, "variables">;

export function waitFor<TResult>(
    fn: () => TResult,
    {
        intervalMs = 100,
        timeoutMs = 10000,
    }: {
        intervalMs?: number;
        timeoutMs?: number;
    } = {}
) {
    const startTime = Date.now();
    const deferred = P.defer<TResult | undefined>();

    const finish = (result?: TResult) => {
        deferred.resolve(result);
        clearInterval(interval);
    };

    const interval = setInterval(async () => {
        if (timeoutMs && Date.now() - startTime > timeoutMs) {
            finish();
        }

        const result = await fn();

        if (result) {
            finish(result);
        }
    }, intervalMs);

    return deferred.promise;
}
