import React from "react";

import { useRecoilValue } from "recoil";

import { ConfirmationModal } from "components/ui/common/ConfirmationModal";
import { dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { useNomenclature } from "lib/Nomenclature";

export type ArchiveBoardDialogProps = {
    boardDisplayName: string;
    onSubmit: () => void;
};

const archiveBoardDialogState = dialogStateFamily<ArchiveBoardDialogProps>(
    "ArchiveBoardDialogState"
);

export function useArchiveBoardDialogState() {
    return useDialogSingleton(archiveBoardDialogState);
}

export function ArchiveBoardDialog({ boardDisplayName, onSubmit }: ArchiveBoardDialogProps) {
    const { isOpen } = useRecoilValue(archiveBoardDialogState);
    const archiveBoardDialog = useArchiveBoardDialogState();
    const { nomenclature } = useNomenclature();

    return (
        <ConfirmationModal
            isOpen={isOpen}
            body={
                <>
                    Archiving this {nomenclature.space.singular.toLowerCase()} will immediately hide
                    it from all users. It can be restored at any time in{" "}
                    <em>Settings | {nomenclature.space.plural}</em>
                </>
            }
            title="Just so you know..."
            submitButtonText={`Archive${
                boardDisplayName ? ` ${boardDisplayName}` : ""
            } ${nomenclature.space.singular.toLowerCase()}`}
            onClose={archiveBoardDialog.close}
            onSubmit={onSubmit}
        />
    );
}
