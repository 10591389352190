import React from "react";

import { CommonConstants } from "c9r-common";
import classNames from "classnames";

export type SupportMailtoProps = {
    className?: string;
    text?: string;
};

export function SupportMailto({ className, text }: SupportMailtoProps) {
    return (
        <a className={classNames(className)} href={`mailto:${CommonConstants.SupportEmailAddress}`}>
            {text || CommonConstants.SupportEmailAddress}
        </a>
    );
}
