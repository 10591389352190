import React, { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { CommonEnumValue, Errors, P, ValueOf } from "c9r-common";
import { atom, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { ProgressText } from "components/ui/common/ProgressText";
import { AppToaster } from "components/ui/core/AppToaster";
import { Icon } from "components/ui/core/Icon";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { gql } from "lib/graphql/__generated__";
import { CompressionWorker } from "workers/CompressionWorker";

type PendingUpload = {
    uuid: string;
    id: number;
    progressFraction: number;
    cancel: () => void;
    title: string;
    entityType?: string | null;
    entityId?: number | string | null;
};

export const pendingUploadsState = atom<PendingUpload[]>({
    key: "PendingUploadsState",
    default: [],
});

class ManagedUpload {
    static State = {
        NOT_STARTED: "NOT_STARTED",
        IN_PROGRESS: "IN_PROGRESS",
        SUCCESS: "SUCCESS",
        FAILED: "FAILED",
        CANCELED: "CANCELED",
    } as const;

    // If multiple files are uploaded at once, we could potentially have the same start time
    // for more than one upload, hence we are using an explicit incremental counter for the upload ID
    // to allow for sorting in the order that the uploads were added.
    private static _nextSeq: number = 1;
    private _onChangeCallback?: (m: ManagedUpload) => void;
    private _progressFraction: number;
    private _xhr?: XMLHttpRequest;

    uuid: string;
    seq: number;
    state: ValueOf<typeof ManagedUpload.State>;

    constructor({ onChange }: { onChange?: (m: ManagedUpload) => void }) {
        this._onChangeCallback = onChange;

        this.uuid = uuidv4();

        this.seq = ManagedUpload._nextSeq;
        ManagedUpload._nextSeq += 1;

        this.state = ManagedUpload.State.NOT_STARTED;
        this._progressFraction = 0;
    }

    async send({
        url,
        method,
        body,
        finalizationDelayMs,
        onSuccess,
    }: {
        url: string;
        method: string;
        body: XMLHttpRequestBodyInit;
        finalizationDelayMs?: number;
        onSuccess?: () => Promise<void> | undefined;
    }) {
        if (this.state !== ManagedUpload.State.NOT_STARTED) {
            throw new Error(`Unexpected state ${this.state} when calling start()`);
        }

        const xhr = new XMLHttpRequest();

        this._xhr = xhr;
        this.state = ManagedUpload.State.IN_PROGRESS;

        const deferred = P.defer();
        const fail = () => {
            this._transitionToTerminalState(ManagedUpload.State.FAILED);
            deferred.reject(new Error("Failed to send upload"));
        };

        xhr.onload = () => {
            if (![200, 201].includes(xhr.status)) {
                fail();
            } else {
                deferred.resolve(undefined);
            }
        };

        xhr.upload.onprogress = event => {
            this._updateProgress(event.loaded / event.total);
        };

        xhr.onerror = fail;
        xhr.upload.onerror = fail;

        xhr.open(method, url);
        xhr.send(body);

        this._onChange();

        await deferred.promise;

        if (finalizationDelayMs) {
            await new Promise<void>(resolve => {
                setTimeout(() => resolve(), finalizationDelayMs);
            });
        }

        if (this.state === ManagedUpload.State.IN_PROGRESS) {
            await onSuccess?.();
            this._transitionToTerminalState(ManagedUpload.State.SUCCESS);
        }
    }

    _cancel() {
        this._xhr?.abort();
        this._transitionToTerminalState(ManagedUpload.State.CANCELED);
    }

    _transitionToTerminalState(finalState: ValueOf<typeof ManagedUpload.State>) {
        // We could have more state machine logic to validate the transition, but as of November
        // 2022 it's not worth the effort given this class is used only once, here in this module.
        this.state = finalState;
        this._onChange();
    }

    _updateProgress(progressFraction: number) {
        this._progressFraction = progressFraction;
        this._onChange();
    }

    _onChange() {
        this._onChangeCallback?.(this);
    }

    toObject() {
        return {
            uuid: this.uuid,
            id: this.seq,
            progressFraction: this._progressFraction,
            cancel: this._cancel.bind(this),
        };
    }

    static compare(a: { id: number }, b: { id: number }) {
        return a.id - b.id;
    }
}

export const useUploadAsset = () => {
    const client = useApolloClient();
    const { gateFeature, getFeatureValue } = useFeatureFlags();
    const { maxSizeInBytes, maxSizeDisplayText } = getFeatureValue({
        feature: Enums.Feature.UPLOADS,
    }) as { maxSizeInBytes: number; maxSizeDisplayText: string };
    const setPendingUploads = useSetRecoilState(pendingUploadsState);

    const toastFileTooLarge = useCallback(() => {
        AppToaster.danger({
            message: `Uploads are limited to ${maxSizeDisplayText} per file. Contact support to request a higher limit.`,
        });
    }, [maxSizeDisplayText]);

    const upload = useCallback(
        /**
         * Upload a user-provided file to S3. This method must be used for any file provided
         * directly be the user that my be later consumed/retrieved by that user or others on
         * their team.
         *
         * In the returned object, assetUrl will be populated if the upload succeeded and null
         * otherwise.
         */
        async ({
            assetType,
            blob,
            filename,
            mimeType,
            showToast,
            entityType,
            entityId,
            optimistic,
            onSuccess,
        }: {
            /** The type of user asset being uploaded. */
            assetType: CommonEnumValue<"UserUploadAssetType">;

            /** The Blob or File object to upload. */
            blob: Blob | File;

            /** The name of the file. */
            filename: string;

            /** The MIME type of the file. */
            mimeType: string;

            /** Whether the uploading toast should be shown. */
            showToast?: boolean;

            /** The type of entity that the upload is being attached to. */
            entityType?: string | null;

            /** The ID of the entity that the upload is being attached to. */
            entityId?: number | string | null;

            /** Whether the asset URL should be returned before the upload is complete. */
            optimistic?: boolean;

            /** Callback when the upload is completed successfully. */
            onSuccess?: ({ assetUrl }: { assetUrl: string }) => Promise<void>;
        }) => {
            if (!gateFeature({ feature: Enums.Feature.UPLOADS })) {
                return { assetUrl: null, uploadId: null };
            }

            if (blob.size > maxSizeInBytes) {
                toastFileTooLarge();
                return { assetUrl: null, uploadId: null };
            }

            const onChange = (managedUpload: ManagedUpload) => {
                switch (managedUpload.state) {
                    case ManagedUpload.State.NOT_STARTED:
                    case ManagedUpload.State.IN_PROGRESS:
                        setPendingUploads(prev =>
                            [
                                ...prev.filter(p => p.uuid !== managedUpload.uuid),
                                {
                                    ...managedUpload.toObject(),
                                    title: filename,
                                    entityType,
                                    entityId,
                                },
                            ].sort(ManagedUpload.compare)
                        );
                        break;

                    case ManagedUpload.State.SUCCESS:
                    case ManagedUpload.State.FAILED:
                    case ManagedUpload.State.CANCELED:
                        setPendingUploads(prev => prev.filter(p => p.uuid !== managedUpload.uuid));
                        break;

                    default:
                        throw new Errors.UnexpectedCaseError({
                            state: managedUpload.state,
                        });
                }
            };

            const toastKey = showToast
                ? AppToaster.info({
                      message: (
                          <ProgressText
                              text={`Uploading${filename ? ` ${filename}` : ""}`}
                              intervalMs={500}
                          />
                      ),
                      icon: <Icon icon="upload" iconSet="c9r" iconSize={24} />,
                      timeout: 0,
                  })
                : null;

            const managedUpload = new ManagedUpload({
                onChange: showToast ? undefined : onChange,
            });

            try {
                const result = await client.mutate({
                    mutation: gql(/* GraphQL */ `
                        mutation UploadAsset(
                            $assetType: String!
                            $mimeType: String!
                            $filename: String
                        ) {
                            upload_asset(
                                asset_type: $assetType
                                mime_type: $mimeType
                                filename: $filename
                            ) {
                                ok
                                asset_url
                                post_url
                                post_fields
                            }
                        }
                    `),
                    variables: { assetType, mimeType, filename },
                });

                if (
                    !result.data?.upload_asset.ok ||
                    !result.data.upload_asset.asset_url ||
                    !result.data.upload_asset.post_url
                ) {
                    throw new Error("Failed to get signed upload URL");
                }

                const assetUrl = result.data.upload_asset.asset_url;

                const formData = new FormData();
                Object.entries(result.data.upload_asset.post_fields as any).forEach(([k, v]) => {
                    formData.append(k, v as string);
                });

                formData.append("file", blob); // Note: S3 requires this be the last field.

                const sendArgs = {
                    url: result.data.upload_asset.post_url,
                    method: "POST",
                    body: formData,

                    // Ensure the upload has a chance to show 100% briefly, so it actually "feels"
                    // like something happened after completion.
                    finalizationDelayMs: 500,

                    onSuccess: () => onSuccess?.({ assetUrl }),
                };

                const performUpload = async () => {
                    await managedUpload.send(sendArgs);
                    toastKey && AppToaster.dismiss(toastKey);
                };

                if (optimistic) {
                    void performUpload();
                } else {
                    await performUpload();

                    if (managedUpload.state !== ManagedUpload.State.SUCCESS) {
                        return { assetUrl: null, uploadId: managedUpload.uuid };
                    }
                }

                return {
                    assetUrl,
                    uploadId: managedUpload.uuid,
                };
            } catch {
                toastKey && AppToaster.dismiss(toastKey);

                return { assetUrl: null, uploadId: managedUpload.uuid };
            }
        },
        [client, gateFeature, maxSizeInBytes, setPendingUploads, toastFileTooLarge]
    );

    const uploadUserContent = useCallback(
        /**
         * Upload a user-provided file to S3. This method must be used for any file provided directly
         * be the user that my be later consumed/retrieved by that user or others on their team.
         *
         * In the returned object, key will be populated if the upload succeeded and null otherwise.
         */
        async ({
            assetType,
            blob,
            filename,
            mimeType,
            showToast,
            entityType,
            entityId,
            optimistic,
            onSuccess,
        }: {
            /** The type of user asset being uploaded. */
            assetType: CommonEnumValue<"UserUploadAssetType">;

            /** The Blob or File object to upload. */
            blob: Blob | File;

            /** The name of the file. */
            filename: string;

            /** The MIME type of the file. */
            mimeType: string;

            /** Whether the uploading toast should be shown. */
            showToast?: boolean;

            /** The type of entity that the upload is being attached to. */
            entityType?: string | null;

            /** The ID of the entity that the upload is being attached to. */
            entityId?: number | string | null;

            /** Whether the asset URL should be returned before the upload is complete. */
            optimistic?: boolean;

            /** Callback when the upload is completed successfully. */
            onSuccess?: ({ key }: { key: string }) => Promise<void>;
        }) => {
            if (!gateFeature({ feature: Enums.Feature.UPLOADS })) {
                return { key: null, uploadId: null };
            }

            if (blob.size > maxSizeInBytes) {
                toastFileTooLarge();
                return { key: null, uploadId: null };
            }

            const onChange = (managedUpload: ManagedUpload) => {
                switch (managedUpload.state) {
                    case ManagedUpload.State.NOT_STARTED:
                    case ManagedUpload.State.IN_PROGRESS:
                        setPendingUploads(prev =>
                            [
                                ...prev.filter(p => p.uuid !== managedUpload.uuid),
                                {
                                    ...managedUpload.toObject(),
                                    title: filename,
                                    entityType,
                                    entityId,
                                },
                            ].sort(ManagedUpload.compare)
                        );
                        break;

                    case ManagedUpload.State.SUCCESS:
                    case ManagedUpload.State.FAILED:
                    case ManagedUpload.State.CANCELED:
                        setPendingUploads(prev => prev.filter(p => p.uuid !== managedUpload.uuid));
                        break;

                    default:
                        throw new Errors.UnexpectedCaseError({
                            state: managedUpload.state,
                        });
                }
            };

            const toastKey = showToast
                ? AppToaster.info({
                      message: (
                          <ProgressText
                              text={`Uploading${filename ? ` ${filename}` : ""}`}
                              intervalMs={500}
                          />
                      ),
                      icon: <Icon icon="upload" iconSet="c9r" iconSize={24} />,
                      timeout: 0,
                  })
                : null;

            const managedUpload = new ManagedUpload({
                onChange: showToast ? undefined : onChange,
            });

            try {
                const result = await client.mutate({
                    mutation: gql(/* GraphQL */ `
                        mutation UploadUserContent(
                            $assetType: String!
                            $mimeType: String!
                            $filename: String
                        ) {
                            upload: upload_user_content(
                                asset_type: $assetType
                                mime_type: $mimeType
                                filename: $filename
                            ) {
                                ok
                                key
                                post_url
                                post_fields
                            }
                        }
                    `),
                    variables: { assetType, mimeType, filename },
                });

                if (
                    !result.data?.upload.ok ||
                    !result.data.upload.key ||
                    !result.data.upload.post_url
                ) {
                    throw new Error("Failed to get signed upload URL");
                }

                const { key } = result.data.upload;

                const formData = new FormData();
                Object.entries(result.data.upload.post_fields as any).forEach(([k, v]) => {
                    formData.append(k, v as string);
                });

                formData.append("file", blob); // Note: S3 requires this be the last field.

                const sendArgs = {
                    url: result.data.upload.post_url,
                    method: "POST",
                    body: formData,

                    // Ensure the upload has a chance to show 100% briefly, so it actually "feels"
                    // like something happened after completion.
                    finalizationDelayMs: 500,

                    onSuccess: () => onSuccess?.({ key }),
                };

                const performUpload = async () => {
                    await managedUpload.send(sendArgs);
                    toastKey && AppToaster.dismiss(toastKey);
                };

                if (optimistic) {
                    void performUpload();
                } else {
                    await performUpload();

                    if (managedUpload.state !== ManagedUpload.State.SUCCESS) {
                        return { key: null, uploadId: managedUpload.uuid };
                    }
                }

                return {
                    key,
                    uploadId: managedUpload.uuid,
                };
            } catch {
                toastKey && AppToaster.dismiss(toastKey);

                return { key: null, uploadId: managedUpload.uuid };
            }
        },
        [client, gateFeature, maxSizeInBytes, setPendingUploads, toastFileTooLarge]
    );

    const uploadInternal = useCallback(
        /**
         * Upload a user-related file to S3 that will be consumed/retrieved only by us
         * (e.g., the backend), not by the user themselves.
         *
         * For these internal uploads, we don't show a toast.
         *
         * Also, we compress before upload. We can do that beacuse we can guarantee we will
         * decompress correctly on retrieval. We can't do that for user-provided files because
         * we can't guarantee all user agents will accept the compressed encoding.
         *
         * (S3 unfortunately doesn't compress at ingestion time on the fly, nor does it
         * compress on retrieval based on Accept-Encoding headers. It might be possible to
         * address that with CloudFront, which would allow us to compress all uploads.)
         *
         * For more information, see:
         * - https://nelsonslog.wordpress.com/2015/01/21/s3-vs-gzip-encoding/
         * - https://www.jacobelder.com/aws/s3/cloudfront/2012/05/08/3-problems-aws-needs-to-address.html
         *
         * In the returned object, assetUrl will be populated if the upload succeeded and null
         * otherwise.
         */
        async ({
            assetType,
            blob,
            filename,
            mimeType,
        }: {
            /** The type of user asset being uploaded. */
            assetType: CommonEnumValue<"UserUploadAssetType">;

            /** The Blob or File object to upload. */
            blob: Blob | File;

            /** The name of the file. */
            filename?: string;

            /** The MIME type of the file. */
            mimeType: string;
        }) => {
            if (!gateFeature({ feature: Enums.Feature.UPLOADS })) {
                return { assetUrl: null };
            }

            if (blob.size > maxSizeInBytes) {
                toastFileTooLarge();
                return { assetUrl: null };
            }

            const result = await client.mutate({
                mutation: gql(/* GraphQL */ `
                    mutation UploadInternal(
                        $assetType: String!
                        $mimeType: String!
                        $filename: String
                    ) {
                        upload_asset(
                            asset_type: $assetType
                            mime_type: $mimeType
                            content_encoding: "gzip"
                            filename: $filename
                        ) {
                            ok
                            asset_url
                            post_url
                            post_fields
                        }
                    }
                `),
                variables: { assetType, mimeType, filename },
            });

            if (!result.data?.upload_asset.ok || !result.data.upload_asset.post_url) {
                throw new Error("Failed to get signed upload URL");
            }

            const formData = new FormData();
            Object.entries(result.data.upload_asset.post_fields as any).forEach(([k, v]) => {
                formData.append(k, v as string);
            });

            formData.append(
                "file",
                new Blob([await CompressionWorker.gzip(await blob.arrayBuffer())], {
                    type: mimeType,
                })
            ); // Note: S3 requires this be the last field.

            const res = await fetch(result.data.upload_asset.post_url, {
                method: "POST",
                body: formData,
            });

            if (!res.ok) {
                throw new Error("Failed to upload asset");
            }

            return { assetUrl: result.data.upload_asset.asset_url };
        },
        [client, gateFeature, maxSizeInBytes, toastFileTooLarge]
    );

    return { upload, uploadUserContent, uploadInternal };
};
