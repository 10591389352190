import React, { useState } from "react";

import { Spinner } from "@blueprintjs/core";
import { BillingSchema, CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { format as formatDate, parseISO } from "date-fns";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { SupportMailto } from "components/shared/SupportMailto";
import { AppToaster } from "components/ui/core/AppToaster";
import { Banner } from "components/ui/core/Banner";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { TextButton } from "components/ui/core/TextButton";
import { useAsyncWatcher, useDialog } from "lib/Hooks";
import { Log } from "lib/Log";
import { useHistory } from "lib/Routing";

import { useBillingFormatters } from "./BillingFormatters";
import { useViewBillingPortal } from "./BillingMutations";
import {
    BillingSection,
    billingInfoSectionLastModifiedState,
    billingInfoState,
} from "./BillingState";
import { BillingSubsectionSpinner } from "./BillingSubsectionSpinner";
import styles from "./SubscriptionPlanContent.module.scss";
import { SubscriptionPlanDialog } from "./SubscriptionPlanDialog";

type CurrentPlanTextProps = {
    cancelAt?: string;
    planKey?: CommonEnumValue<"SubscriptionPlanKey">;
    planName?: string;
    price?: BillingSchema.Price;
};

function CurrentPlanText({ cancelAt, planKey, planName, price }: CurrentPlanTextProps) {
    const { formatPrice } = useBillingFormatters();
    let extraText;

    switch (planKey) {
        case CommonEnums.SubscriptionPlanKey.FREE_IN_BETA_PLAN:
            extraText = "(free while Flat is in beta)";
            break;

        case CommonEnums.SubscriptionPlanKey.FREE_PLAN:
            extraText = `(up to ${Config.billing.freePlanMaxUsers} users)`;
            break;

        case CommonEnums.SubscriptionPlanKey.STANDARD_PLAN:
            extraText = `(${formatPrice({ price: price! })})`;
            break;

        default:
            extraText = "";
    }

    if (cancelAt) {
        if (new Date(cancelAt) > new Date(Date.now())) {
            extraText = `(will cancel on ${formatDate(parseISO(cancelAt), "MMM d, yyyy")})`;
        } else {
            extraText = "cancelled";
        }
    }

    return (
        <span>
            Current plan:{" "}
            {planKey ? (
                <>
                    <strong>{planName}</strong> {extraText}
                </>
            ) : (
                "No active subscription"
            )}
        </span>
    );
}

function CurrentPlanNextBillingDate() {
    const billingInfo = useRecoilValue(billingInfoState);
    const planKey = billingInfo?.current_subscription_plan?.plan_key;
    const nextPaymentAttemptAt = billingInfo?.next_invoice?.next_payment_attempt_at;
    const trialEndAt = billingInfo?.current_subscription_plan?.trial_end_at;

    if (
        !nextPaymentAttemptAt ||
        !planKey ||
        planKey === CommonEnums.SubscriptionPlanKey.FREE_PLAN ||
        trialEndAt
    ) {
        return null;
    }

    return (
        <span>Next billing date: {formatDate(parseISO(nextPaymentAttemptAt), "MMM d, yyyy")}</span>
    );
}

function FreeTrialBanner() {
    const billingInfo = useRecoilValue(billingInfoState);
    const planKey = billingInfo?.current_subscription_plan?.plan_key;
    const planName = billingInfo?.current_subscription_plan?.display_name;
    const trialEndAt = billingInfo?.current_subscription_plan?.trial_end_at;

    if (planKey === CommonEnums.SubscriptionPlanKey.FREE_PLAN || !trialEndAt) {
        return null;
    }

    return (
        <div className={styles.freeTrialBanner}>
            <Banner
                content={`Your ${planName} free trial ends on ${formatDate(
                    parseISO(trialEndAt),
                    "MMM d, yyyy"
                )}`}
            />
        </div>
    );
}

function ViewBillingPortalLink() {
    const [isRedirecting, setIsRedirecting] = useState(false);
    const submission = useAsyncWatcher();
    const { history } = useHistory();
    const { viewBillingPortal } = useViewBillingPortal();

    const handleViewBillingPortal = async () => {
        const result = await viewBillingPortal({
            returnUrl: `${Config.urls.public}${history.location.pathname}`,
        });

        if (!result.data?.view_billing_portal.ok || !result.data.view_billing_portal.url) {
            Log.error("Failed to generate billing portal URL");
            AppToaster.error({
                message: "Sorry, something went wrong.",
            });

            return;
        }

        setIsRedirecting(true);
        window.location.href = result.data.view_billing_portal.url;
    };

    const showSpinner = submission.isInFlight || isRedirecting;

    return (
        <TextButton
            className={styles.viewInvoicesButton}
            disabled={showSpinner}
            link
            instrumentation={{ elementName: "settings.view_invoices_btn" }}
            text={
                <>
                    View billing history
                    {showSpinner ? (
                        <Spinner size={16} />
                    ) : (
                        <Icon icon="external-link" iconSet="lucide" iconSize={16} />
                    )}
                </>
            }
            onClick={submission.watch(handleViewBillingPortal)}
        />
    );
}

export type SubscriptionPlanContentProps = {
    className?: string;
    onChange: () => void;
};

export function SubscriptionPlanContent({ className, onChange }: SubscriptionPlanContentProps) {
    const billingInfo = useRecoilValue(billingInfoState);
    const billingInfoSectionLastModified = useRecoilValue(billingInfoSectionLastModifiedState);
    const dialog = useDialog();

    const planKey = billingInfo?.current_subscription_plan?.plan_key;
    const planName = billingInfo?.current_subscription_plan?.display_name;
    const price = billingInfo?.current_subscription_plan?.price;
    const cancelAt = billingInfo?.current_subscription_plan?.cancel_at;
    const subscriptionPlanLastModifiedAt =
        billingInfoSectionLastModified[BillingSection.SUBSCRIPTION_PLAN];
    const isReady =
        billingInfo &&
        (!subscriptionPlanLastModifiedAt ||
            (billingInfo.as_of && billingInfo.as_of > subscriptionPlanLastModifiedAt));

    return (
        <>
            <section className={classNames(className, styles.subscriptionPlanContent)}>
                {isReady ? (
                    <>
                        <div>
                            <div className={styles.currentPlan}>
                                <CurrentPlanText
                                    cancelAt={cancelAt ?? undefined}
                                    planKey={planKey}
                                    planName={planName}
                                    price={price}
                                />
                                <BorderButton
                                    flushVertical
                                    content={cancelAt ? "Renew or change" : "Change"}
                                    instrumentation={{ elementName: "settings.change_plan_btn" }}
                                    onClick={dialog.open}
                                    small
                                />
                            </div>
                            <CurrentPlanNextBillingDate />
                        </div>

                        <FreeTrialBanner />

                        <div>
                            <ViewBillingPortalLink />
                        </div>

                        <div className={styles.questionsCaption}>
                            Questions? Email us at <SupportMailto />.
                        </div>
                    </>
                ) : (
                    <BillingSubsectionSpinner />
                )}
            </section>
            {billingInfo ? (
                <SubscriptionPlanDialog
                    isOpen={dialog.isOpen}
                    onChange={onChange}
                    onClose={dialog.close}
                />
            ) : null}
        </>
    );
}
