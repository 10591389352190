import React, { useCallback, useMemo } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog } from "components/ui/core/Dialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog } from "lib/Hooks";

import styles from "./MondayImport.module.scss";

type MondayImportDialogProps = { isOpen: boolean; onClose?: () => void };

function MondayImportDialog({ isOpen, onClose }: MondayImportDialogProps) {
    return (
        <Dialog
            className={styles.dialog}
            title="Import from Monday"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <p>
                    To get started importing from Monday, first{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href="https://support.monday.com/hc/en-us/articles/360002543719-How-to-export-your-entire-account-s-data"
                    >
                        export your account's data
                    </a>
                    .
                </p>
                <p>
                    Then, contact us at <SupportMailto />. We'll be happy to work with you – for
                    free – to get your data into Flat.
                </p>
            </Dialog.Body>
            <Dialog.Footer />
        </Dialog>
    );
}

const useMondayImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();

    const mondayImportDialog = useMemo(
        () => <MondayImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateMondayImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        dialog.open();
    }, [dialog, gateFeature]);

    return { initiateMondayImport, mondayImportDialog };
};

export { useMondayImport };
