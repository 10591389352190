import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./MultiselectBadge.module.scss";

type MultiselectBadgeProps = { className: string; count?: number };

export function MultiselectBadge({ className, count }: MultiselectBadgeProps) {
    return (
        <div data-cy="board-multiselect-badge" className={classNames(className, styles.badge)}>
            {count ? (
                <span className={styles.count}>{count}</span>
            ) : (
                <Icon icon="check" iconSet="lucide" iconSize={10} strokeWidthAbsolute={2} />
            )}
        </div>
    );
}
