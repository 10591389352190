import React, { useEffect, useRef, useState } from "react";

import { CommonEnums, TicketSizes } from "c9r-common";
import classNames from "classnames";

import {
    TasksProgress,
    TasksProgressBar,
    TasksProgressReport,
} from "components/shared/TasksProgress";
import { Hotspot } from "components/ui/core/Hotspot";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { Enums } from "lib/Enums";
import { maybeQuote } from "lib/Helpers";
import { useTicketOwnershipInfo, useTicketWatcherInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { CardInfoSummary_ticketFragment } from "lib/graphql/__generated__/graphql";

import styles from "./CardInfoSummary.module.scss";
import { useBoardView } from "../../BoardViewContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment CardInfoSummary_ticket on tickets {
            id
            size_spec
            stage_id
            title

            board {
                id
                settings
            }

            merge_requests {
                merge_request {
                    id
                    merged_at
                    ...merge_request_full
                }
            }

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id
                stage_id

                tasks(where: { deleted_at: { _is_null: true } }) {
                    id
                    task_type

                    ...TasksProgress_task
                }
            }

            ...TicketOwnershipInfo_ticket
            ...TicketWatcherInfo_ticket
        }
    `),
};

const tasksProgressTaskTypes = [CommonEnums.TaskType.TASK, CommonEnums.TaskType.CHILD_TICKET];

export type CardInfoSummaryDisplayProps = {
    className?: string;
    ticket: CardInfoSummary_ticketFragment;
    ownerUser: { name: string } | null;
    ownershipSummaryText?: string;
    isCurrentUserOwner?: boolean;
    isCurrentUserMember?: boolean;
    isCurrentUserWatcher?: boolean;
    mergedMrCount?: number;
    tasks: CardInfoSummary_ticketFragment["tasklists"][number]["tasks"];
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
};

function CardInfoSummaryDisplay({
    className,
    ticket,
    ownerUser,
    ownershipSummaryText,
    isCurrentUserOwner,
    isCurrentUserMember,
    isCurrentUserWatcher,
    mergedMrCount,
    tasks,
    onClickFilterTrigger,
}: CardInfoSummaryDisplayProps) {
    const sizeAndTeamRef = useRef<HTMLSpanElement>(null);
    const [isMultiline, setIsMultiline] = useState(false);
    const { highlightMyTickets } = useBoardView();

    useEffect(() => {
        // Divide height by line-height to see if content is wrapped.
        setIsMultiline((sizeAndTeamRef.current?.clientHeight ?? 0) / 16 >= 2);
    }, []);

    const ticketSizeSpec = ticket.size_spec;
    const boardTicketSizes = ticket.board.settings[CommonEnums.BoardSettingType.SIZES];

    const isTeamDefined = !!ownershipSummaryText;
    const isSizeDefined = !!(ticketSizeSpec && boardTicketSizes?.enabled);
    const showSizeAndTeam = isTeamDefined || isSizeDefined;
    const isMine = isCurrentUserOwner || isCurrentUserMember;
    const showWatchingIndicator = !isMine && isCurrentUserWatcher;
    const showMergedPrCount = mergedMrCount && mergedMrCount > 0;
    const showNonTasksinfo = showSizeAndTeam || showWatchingIndicator || showMergedPrCount;
    const showTasksInfo = tasks.filter(task => tasksProgressTaskTypes.includes(task.task_type))
        .length;

    if (!showNonTasksinfo && !showTasksInfo) {
        return null;
    }

    return (
        <div className={classNames(className, styles.cardInfo)}>
            {showNonTasksinfo ? (
                <div className={classNames(styles.nonTasksProgressWrapper)}>
                    {showSizeAndTeam ? (
                        <span
                            className={classNames(
                                styles.sizeAndTeam,
                                isMultiline && styles.multiline
                            )}
                            ref={sizeAndTeamRef}
                        >
                            {isTeamDefined ? (
                                <Tooltip
                                    content={<span>Filter workspace by owner</span>}
                                    openOnTargetFocus={false}
                                    placement="bottom"
                                    small
                                    disabled={!(onClickFilterTrigger && ownerUser)}
                                >
                                    <span
                                        className={classNames(
                                            styles.ownershipSummaryText,
                                            highlightMyTickets &&
                                                isMine &&
                                                styles.ownershipSummaryHighlighted
                                        )}
                                        onClick={
                                            onClickFilterTrigger && ownerUser
                                                ? e =>
                                                      onClickFilterTrigger(
                                                          e,
                                                          `owner:${maybeQuote(ownerUser.name)}`
                                                      )
                                                : undefined
                                        }
                                    >
                                        {ownershipSummaryText}
                                    </span>
                                </Tooltip>
                            ) : null}
                            {isTeamDefined && isSizeDefined ? (
                                <span className={styles.divider}>•</span>
                            ) : null}
                            {isSizeDefined
                                ? TicketSizes.format({
                                      value: ticketSizeSpec.value,
                                      unit: ticketSizeSpec.unit,
                                      abbr: true,
                                  })
                                : null}
                        </span>
                    ) : null}
                    {showWatchingIndicator ? (
                        <span className={styles.watching}>
                            <Icon icon="eye" iconSet="lucide" iconSize={12} />
                        </span>
                    ) : null}

                    {showMergedPrCount ? (
                        <span className={styles.mergedMrCount}>
                            <Icon
                                className={styles.mergedMrIcon}
                                icon="codeMerged"
                                iconSet="c9r"
                                iconSize={14}
                            />
                            <span>{mergedMrCount}</span>
                        </span>
                    ) : null}
                </div>
            ) : null}

            {showTasksInfo ? (
                <div className={styles.tasksProgressWrapper}>
                    <Hotspot
                        allowListValue={ticket.title}
                        hotspotKey={Enums.HotspotKey.BOARD_CARD_PROGRESS_BAR}
                        offset={[0, 12]}
                        placement="top"
                        usePortal={false}
                    >
                        <TasksProgress className={styles.tasksProgress}>
                            <TasksProgressBar
                                tasks={tasks}
                                taskTypes={tasksProgressTaskTypes}
                                small
                            />
                            <TasksProgressReport
                                className={styles.progressReport}
                                tasks={tasks}
                                taskTypes={tasksProgressTaskTypes}
                            />
                        </TasksProgress>
                    </Hotspot>
                </div>
            ) : null}
        </div>
    );
}

export type CardInfoSummaryProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
    onClickFilterTrigger?: (e: React.MouseEvent<HTMLSpanElement>, term: string) => void;
};

export function CardInfoSummary({
    className,
    ticket: _ticketFragment,
    onClickFilterTrigger,
}: CardInfoSummaryProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const {
        ownerUser,
        isCurrentUserOwner,
        isCurrentUserMember,
        ownershipSummaryText,
    } = useTicketOwnershipInfo({ ticket });
    const { isCurrentUserWatcher } = useTicketWatcherInfo({ ticket });
    const mergedMrCount = ticket.merge_requests.filter(mr => mr.merge_request.merged_at).length;
    const tasks = ticket.tasklists
        .filter(tl => tl.stage_id === ticket.stage_id)
        .flatMap(tl => tl.tasks);

    return (
        <CardInfoSummaryDisplay
            className={className}
            ticket={ticket}
            ownerUser={ownerUser}
            ownershipSummaryText={ownershipSummaryText}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserMember={isCurrentUserMember}
            isCurrentUserWatcher={isCurrentUserWatcher}
            mergedMrCount={mergedMrCount}
            tasks={tasks}
            onClickFilterTrigger={onClickFilterTrigger}
        />
    );
}

CardInfoSummary.Display = CardInfoSummaryDisplay;
