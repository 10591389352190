import React from "react";

import classNames from "classnames";

import styles from "./BrandLogomark.module.scss";

const baseWidth = 581;
const baseHeight = 174;

export type BrandLogomarkProps = {
    className?: string;
    width?: number;
    height?: number;
};

export function BrandLogomark({ className, width: _width, height: _height }: BrandLogomarkProps) {
    let width = baseWidth;
    let height = baseHeight;

    if (_width && _height) {
        width = _width;
        height = _height;
    } else if (_width) {
        width = _width;
        height = baseHeight * (_width / baseWidth);
    } else if (_height) {
        height = _height;
        width = baseWidth * (_height / baseHeight);
    }

    return (
        <svg
            className={classNames(className, styles.logomark)}
            width={width}
            height={height}
            viewBox="0 0 581 174"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g className={styles.logo}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48 24C48 10.7452 58.7452 0 72 0H144C157.255 0 168 10.7452 168 24C168 37.2548 157.255 48 144 48H72C58.7452 48 48 37.2548 48 24ZM36 24C36 33.9411 27.9411 42 18 42C8.05887 42 0 33.9411 0 24C0 14.0589 8.05887 6 18 6C27.9411 6 36 14.0589 36 24ZM36 90C36 99.9411 27.9411 108 18 108C8.05887 108 0 99.9411 0 90C0 80.0589 8.05887 72 18 72C27.9411 72 36 80.0589 36 90ZM18 174C27.9411 174 36 165.941 36 156C36 146.059 27.9411 138 18 138C8.05887 138 0 146.059 0 156C0 165.941 8.05887 174 18 174ZM72 66C58.7452 66 48 76.7452 48 90C48 103.255 58.7452 114 72 114H114C127.255 114 138 103.255 138 90C138 76.7452 127.255 66 114 66H72Z"
                    fill="url(#paint0_linear_132_594)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_132_594"
                        x1="0"
                        y1="174"
                        x2="226.024"
                        y2="147.069"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4986E7" />
                        <stop offset="1" stopColor="#31B2C9" />
                    </linearGradient>
                </defs>
            </g>
            <g className={styles.mark}>
                <path d="M324.615 24V49.7607H271.661V77.5898H311.284V102.598H271.661V156H240V24H324.615Z" />
                <path d="M372 24V156H341.539V24.0001L372 24Z" />
                <path d="M390 101.965C390 91.147 391.963 81.6496 395.884 73.4729C399.929 65.2966 405.385 59.007 412.252 54.6041C419.116 50.2013 426.777 48 435.234 48C442.467 48 448.783 49.5095 454.176 52.5286C459.691 55.5477 463.922 59.5099 466.864 64.4161V49.5095H498.308V156.308H466.864V139.892C463.799 144.797 459.509 148.76 453.99 151.779C448.597 154.798 442.284 156.308 435.052 156.308C426.717 156.308 419.116 154.106 412.252 149.704C405.385 145.175 399.929 138.822 395.884 130.646C391.963 122.344 390 112.783 390 101.965ZM466.864 102.154C466.864 94.1031 464.656 87.7504 460.243 83.0961C455.952 78.4418 450.682 76.1148 444.429 76.1148C438.177 76.1148 432.844 78.4418 428.43 83.0961C424.14 87.6247 421.995 93.9143 421.995 101.965C421.995 110.016 424.14 116.431 428.43 121.211C432.844 125.866 438.177 128.193 444.429 128.193C450.682 128.193 455.952 125.866 460.243 121.211C464.656 116.557 466.864 110.205 466.864 102.154Z" />
                <path d="M580.608 128.71V155.582H564.712C553.386 155.582 544.555 152.803 538.223 147.243C531.889 141.56 528.722 132.355 528.722 119.63V75.5204H516.3V49.2041H528.722V24H559.962V49.2041H580.423V75.5204H559.962V120C559.962 123.089 560.694 125.313 562.156 126.672C563.617 128.031 566.052 128.71 569.463 128.71H580.608Z" />
            </g>
        </svg>
    );
}
