import React, { useCallback, useMemo } from "react";

import { SupportMailto } from "components/shared/SupportMailto";
import { Dialog } from "components/ui/core/Dialog";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDialog } from "lib/Hooks";

import styles from "./AsanaImport.module.scss";

type AsanaImportDialogProps = { isOpen: boolean; onClose?: () => void };

function AsanaImportDialog({ isOpen, onClose }: AsanaImportDialogProps) {
    return (
        <Dialog
            className={styles.dialog}
            title="Import from Asana"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Dialog.Body>
                <p>
                    If you're interested in importing from Asana,{" "}
                    <SupportMailto text="contact us" />. We'd be happy to work with you – for free –
                    to get your data into Flat.
                </p>
            </Dialog.Body>
            <Dialog.Footer />
        </Dialog>
    );
}

const useAsanaImport = () => {
    const { gateFeature } = useFeatureFlags();
    const dialog = useDialog();

    const asanaImportDialog = useMemo(
        () => <AsanaImportDialog isOpen={dialog.isOpen} onClose={dialog.close} />,
        [dialog.isOpen, dialog.close]
    );

    const initiateAsanaImport = useCallback(() => {
        if (!gateFeature({ feature: Enums.Feature.IMPORT_DATA })) {
            return;
        }

        dialog.open();
    }, [dialog, gateFeature]);

    return { initiateAsanaImport, asanaImportDialog };
};

export { useAsanaImport };
