import React from "react";

import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";

import styles from "./DropdownButton.module.scss";

export type DropdownButtonProps = {
    className?: string;
    id?: string;
    text: string;
};

export function DropdownButton({ className, id, text }: DropdownButtonProps) {
    return (
        <button className={classNames(className, styles.dropdownButton)} id={id} type="button">
            <span>{text}</span>
            <Icon
                className={styles.icon}
                icon="chevron-down"
                iconSet="lucide"
                iconSize={14}
                strokeWidth={2}
            />
        </button>
    );
}
