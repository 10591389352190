import { useCallback } from "react";

import { TBlocker } from "c9r-common";
import { v4 as uuidv4 } from "uuid";

import { useReplicache } from "lib/replicache/Context";
import { TRichTextContentSerializers } from "lib/types/common/richText";

export function useCreateThreadWithBlocker() {
    const { replicache } = useReplicache();

    const createThreadWithBlocker = useCallback(
        async ({
            ticketId,
            blocker,
            assignedToUserId,
        }: {
            ticketId: string;
            blocker: TBlocker;
            assignedToUserId: number | null;
        }) => {
            const threadId = uuidv4();

            const mutatorArgs = {
                threadId,
                ticketId,
                blocker,
                assignedToUserId,
            };

            await replicache.mutate.createThread(mutatorArgs);
        },
        [replicache]
    );

    return { createThreadWithBlocker };
}

export function useCreateThreadWithComment() {
    const { replicache } = useReplicache();

    const createThreadWithComment = useCallback(
        async ({
            ticketId,
            serializers,
            assignedToUserId,
        }: {
            ticketId: string;
            serializers: TRichTextContentSerializers;
            assignedToUserId: number | null;
        }) => {
            const commentJSON = serializers.getJSON();

            const threadId = uuidv4();
            const commentId = uuidv4();

            const mutatorArgs = {
                threadId,
                ticketId,
                comment: commentJSON,
                commentId,
                assignedToUserId,
            };

            await replicache.mutate.createThread(mutatorArgs);
        },
        [replicache]
    );

    return { createThreadWithComment };
}

export function useReplyToThread() {
    const { replicache } = useReplicache();

    const replyToThread = useCallback(
        async ({
            threadId,
            ticketId,
            serializers,
        }: {
            threadId: string;
            ticketId: string;
            serializers: TRichTextContentSerializers;
        }) => {
            const mutatorArgs = {
                commentId: uuidv4(),
                threadId,
                comment: serializers.getJSON(),
            };

            await replicache.mutate.replyToThread(mutatorArgs);
        },
        [replicache]
    );

    return { replyToThread };
}

export function useReplyToThreadAndAssign() {
    const { replicache } = useReplicache();

    const replyToThreadAndAssign = useCallback(
        async ({
            threadId,
            ticketId,
            serializers,
            assignedToUserId,
        }: {
            threadId: string;
            ticketId: string;
            serializers: TRichTextContentSerializers;
            assignedToUserId: number;
        }) => {
            const mutatorArgs = {
                commentId: uuidv4(),
                threadId,
                comment: serializers.getJSON(),
                assignedToUserId,
            };

            await replicache.mutate.replyToThread(mutatorArgs);
        },
        [replicache]
    );

    return { replyToThreadAndAssign };
}

export function useReplyToThreadAndResolve() {
    const { replicache } = useReplicache();

    const replyToThreadAndResolve = useCallback(
        async ({
            threadId,
            ticketId,
            serializers,
        }: {
            threadId: string;
            ticketId: string;
            serializers: TRichTextContentSerializers;
        }) => {
            const mutatorArgs = {
                commentId: uuidv4(),
                threadId,
                comment: serializers.getJSON(),
                isThreadResolved: true,
            };

            await replicache.mutate.replyToThread(mutatorArgs);
        },
        [replicache]
    );

    return { replyToThreadAndResolve };
}

export function useAssignThread() {
    const { replicache } = useReplicache();

    const assignThread = useCallback(
        async ({ threadId, assignedToUserId }: { threadId: string; assignedToUserId: number }) => {
            await replicache.mutate.updateThread({ threadId, assignedToUserId });
        },
        [replicache]
    );

    return { assignThread };
}

export function useResolveThread() {
    const { replicache } = useReplicache();

    const resolveThread = useCallback(
        async ({ threadId }: { threadId: string }) => {
            await replicache.mutate.resolveThread({ threadId });
        },
        [replicache]
    );

    return { resolveThread };
}

export function useReopenThread() {
    const { replicache } = useReplicache();

    const reopenThread = useCallback(
        async ({ threadId }: { threadId: string }) => {
            await replicache.mutate.reopenThread({ threadId });
        },
        [replicache]
    );

    return { reopenThread };
}

export function useEditBlocker() {
    const { replicache } = useReplicache();

    const editBlocker = useCallback(
        async ({ threadId, blocker }: { threadId: string; blocker: TBlocker }) => {
            await replicache.mutate.updateThread({ threadId, blocker });
        },
        [replicache]
    );

    return { editBlocker };
}

export function useEditComment() {
    const { replicache } = useReplicache();

    const editComment = useCallback(
        async ({
            commentId,
            serializers,
        }: {
            commentId: string;
            serializers: TRichTextContentSerializers;
        }) => {
            const commentJSON = serializers.getJSON();

            await replicache.mutate.updateComment({ commentId, comment: commentJSON });
        },
        [replicache]
    );

    return { editComment };
}

export function useEditCommentAndAssignThread() {
    const { replicache } = useReplicache();

    const editCommentAndAssignThread = useCallback(
        async ({
            threadId,
            commentId,
            assignedToUserId,
            serializers,
        }: {
            threadId: string;
            commentId: string;
            assignedToUserId: number;
            serializers: TRichTextContentSerializers;
        }) => {
            const commentJSON = serializers.getJSON();

            await replicache.mutate.updateComment({
                commentId,
                comment: commentJSON,
                threadAssignedToUserId: assignedToUserId,
            });
        },
        [replicache]
    );

    return { editCommentAndAssignThread };
}
