import React, { useCallback, useEffect, useRef } from "react";

import { Classes } from "@blueprintjs/core";
import { CommonEnums, TicketDueDates, sortStages } from "c9r-common";
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";

import { LabelsPicker } from "components/shared/LabelsPicker";
import { MultiselectBadge } from "components/shared/MultiselectBadge";
import { ScrollDivider } from "components/shared/ScrollDivider";
import { CardMenu } from "components/shared/card/CardMenu";
import {
    TicketList,
    TicketListHeader,
    TicketListRow,
} from "components/shared/ticketList/TicketList";
import {
    TicketRowContentActivity,
    TicketRowContentAncestry,
    TicketRowContentDueDate,
    TicketRowContentDynamicWidthTableColumnDefinitions,
    TicketRowContentIcon,
    TicketRowContentMainInfo,
    TicketRowContentOwnership,
    TicketRowContentProgress,
} from "components/shared/ticketList/TicketRowContent";
import { TicketRowLayout } from "components/shared/ticketList/TicketRowLayout";
import { Avatar } from "components/ui/common/Avatar";
import { DynamicWidthTable, DynamicWidthTableCell } from "components/ui/common/DynamicWidthTable";
import { UserSelect } from "components/ui/common/UserSelect";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { DatePopover } from "components/ui/core/DatePopover";
import { Icon } from "components/ui/core/Icon";
import { AbstractTextInput } from "components/ui/core/abstract/AbstractTextInput";
import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { CssClasses, NewTicketEntryId } from "lib/Constants";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { moveToLastPosition } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { generateFakeNumberId, generateFakeStringId, isFakeId } from "lib/GraphQL";
import { useAsyncWatcher, useOutsideClick, useSaveScrollPosition } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import { useTicketOwnershipInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

import styles from "./ListView.module.scss";
import { useBoardViewFilter } from "../BoardFilterContext";
import { useBoardView } from "../BoardViewContext";
import {
    useNewTicketEntry,
    useNewTicketEntryContent,
    useNewTicketEntryMutations,
    useNewTicketEntryPlacement,
} from "../NewTicketEntry";
import { StageMenu } from "../StageMenu";
import { TicketCount } from "../TicketCount";
import { BulkActionCardMenu } from "../card/boardCard/BulkActionCardMenu";

const fragments = {
    List: {
        stage: gql(/* GraphQL */ `
            fragment List_stage on stages {
                id
                board_id
                display_name
                is_empty

                ...NewTicketClickTarget_stage
                ...NewTicketRow_stage
                ...StageMenu_stage
            }
        `),

        ticket: gql(/* GraphQL */ `
            fragment List_ticket on tickets {
                id

                ...StageMenu_ticket
                ...TicketRow_ticket
            }
        `),
    },

    ListView: {
        stage: gql(/* GraphQL */ `
            fragment ListView_stage on stages {
                id
                display_name
                is_empty

                ...List_stage
            }
        `),

        ticket: gql(/* GraphQL */ `
            fragment ListView_ticket on tickets {
                id
                stage_id

                ...List_ticket
            }
        `),
    },

    NewTicketClickTarget: {
        stage: gql(/* GraphQL */ `
            fragment NewTicketClickTarget_stage on stages {
                id
                board_id
                max_ticket_stage_pos
            }
        `),
    },

    NewTicketRow: {
        stage: gql(/* GraphQL */ `
            fragment NewTicketRow_stage on stages {
                id
                board_id
                max_ticket_stage_pos
            }
        `),
    },

    TicketRow: {
        ticket: gql(/* GraphQL */ `
            fragment TicketRow_ticket on tickets {
                id
                ref
                title

                owners {
                    ticket_id
                    user_id
                    type
                }

                ...BulkActionCardMenu_ticket
                ...CardMenu_ticket
                ...TicketListRow_ticket
                ...TicketRowContentActivity_ticket
                ...TicketRowContentAncestry_ticket
                ...TicketRowContentDueDate_ticket
                ...TicketRowContentMainInfo_ticket
                ...TicketRowContentOwnership_ticket
                ...TicketRowContentProgress_ticket
                ...TicketRowContentReference_ticket
                ...TicketOwnershipInfo_ticket
                ...TicketWatcherInfo_ticket
            }
        `),
    },
};

type TableCellProps = {
    columnClassName?: string;
    children: React.ReactNode;
};

function TableCell({ columnClassName, children }: TableCellProps) {
    return (
        <DynamicWidthTableCell className={classNames(styles.tableCell, columnClassName)}>
            {children}
        </DynamicWidthTableCell>
    );
}

type TicketRowContentProps = {
    ticket: FragmentType<typeof fragments.TicketRow.ticket>;
};

const TicketRowContent = React.memo(({ ticket: _ticketFragment }: TicketRowContentProps) => {
    const ticket = getFragmentData(fragments.TicketRow.ticket, _ticketFragment);
    const { highlightMyTickets } = useBoardView();
    const { isCurrentUserOwner, isCurrentUserMember } = useTicketOwnershipInfo({ ticket });
    const isMine = isCurrentUserOwner || isCurrentUserMember;

    const { appendTermToFilterExpression } = useBoardViewFilter();

    const handleClickFilterTrigger = useCallback(
        (e: React.MouseEvent<HTMLSpanElement>, term: string) => {
            e.preventDefault();
            e.stopPropagation();

            appendTermToFilterExpression(term);
        },
        [appendTermToFilterExpression]
    );

    return (
        <>
            <TicketRowContentIcon className={styles.ticketRowIcon} />
            <TicketRowContentMainInfo
                ticket={ticket}
                onClickFilterTrigger={handleClickFilterTrigger}
            />
            <TicketRowContentAncestry ticket={ticket} />
            <TicketRowContentActivity ticket={ticket} />
            <TicketRowContentProgress ticket={ticket} />
            <TicketRowContentOwnership
                ticket={ticket}
                isHighlighted={highlightMyTickets && isMine}
                onClickFilterTrigger={handleClickFilterTrigger}
            />
            <TicketRowContentDueDate ticket={ticket} />
        </>
    );
});

TicketRowContent.displayName = "TicketRowContent";

type TicketMenuProps = {
    ticket: FragmentType<typeof fragments.TicketRow.ticket>;
    isFirstTicketInStage: boolean;
    isLastTicketInStage: boolean;
};

function TicketMenu({
    ticket: _ticketFragment,
    isFirstTicketInStage,
    isLastTicketInStage,
}: TicketMenuProps) {
    const ticket = getFragmentData(fragments.TicketRow.ticket, _ticketFragment);
    const { isFeatureEnabled } = useFeatureFlags();
    const { selection, selectTickets } = useTicketSelectionContext();

    const { onMoveCard, onArchiveCard, onTrashCard } = useBoardView();

    const handleEdit = isFeatureEnabled({ feature: Enums.Feature.QUICK_EDITS })
        ? () => {
              selectTickets({ ticketIds: [ticket.id] });
          }
        : null;

    const handleMoveToStartOfStage = () => {
        onMoveCard({
            ticketId: ticket.id,
            withinStageDirection: CommonEnums.Direction.BACK,
        });
    };

    const handleMoveToEndOfStage = () => {
        onMoveCard({
            ticketId: ticket.id,
            withinStageDirection: CommonEnums.Direction.FORWARD,
        });
    };

    const handleArchive = () => {
        onArchiveCard({
            ticketId: ticket.id,
        });
    };

    const handleMoveToTrash = () => {
        onTrashCard({
            ticketId: ticket.id,
        });
    };

    return (
        <TableCell columnClassName={styles.menu}>
            {selection.ticketIds.has(ticket.id) ? (
                <BulkActionCardMenu className={styles.menuButton} ticket={ticket} />
            ) : (
                <CardMenu
                    className={styles.menuButton}
                    ticket={ticket}
                    isFirstTicketInStage={isFirstTicketInStage}
                    isLastTicketInStage={isLastTicketInStage}
                    handleEdit={handleEdit}
                    handleMoveToStartOfStage={handleMoveToStartOfStage}
                    handleMoveToEndOfStage={handleMoveToEndOfStage}
                    handleMoveToPreviousStage={null}
                    handleMoveToNextStage={null}
                    handleArchive={handleArchive}
                    handleMoveToTrash={handleMoveToTrash}
                />
            )}
        </TableCell>
    );
}

type TicketRowProps = {
    ticket: FragmentType<typeof fragments.TicketRow.ticket>;
    index: number;
    isFirstTicketInStage: boolean;
    isLastTicketInStage: boolean;
};

function TicketRow({
    ticket: _ticketFragment,
    index,
    isFirstTicketInStage,
    isLastTicketInStage,
}: TicketRowProps) {
    const ticket = getFragmentData(fragments.TicketRow.ticket, _ticketFragment);
    const breakpoints = useBreakpoints();
    const { isDragInProgress, highlightMyTickets } = useBoardView();
    const { isCurrentUserOwner, isCurrentUserMember } = useTicketOwnershipInfo({ ticket });

    const {
        maybeHandleTicketSelectionClick,
        getSelectableElementProps,
        selection,
    } = useTicketSelectionContext();

    const isSelected = selection.ticketIds.has(ticket.id);

    const multiselectClassNames = [
        selection.mode === Enums.MultiselectMode.STARTING && styles.multiselectStarting,
        selection.mode === Enums.MultiselectMode.ACTIVE && styles.multiselectActive,
        isSelected ? styles.selected : styles.notSelected,
    ];

    return (
        <TicketListRow
            {...getSelectableElementProps(ticket.id)}
            className={classNames(styles.ticketRow, ...multiselectClassNames)}
            index={index}
            isDraggable={breakpoints.lgMin}
            isMuted={
                (highlightMyTickets &&
                    !(isCurrentUserOwner || isCurrentUserMember) &&
                    !(selection.mode === Enums.MultiselectMode.ACTIVE && isSelected)) ||
                (isDragInProgress && selection.mode === Enums.MultiselectMode.ACTIVE && !isSelected)
            }
            ticketRowLayout={({ snapshot }) => (
                <TicketRowLayout
                    ticketContent={
                        <>
                            {!isDragInProgress || (snapshot.isDragging && isSelected) ? (
                                <MultiselectBadge
                                    className={styles.multiselectIcon}
                                    count={
                                        snapshot.isDragging && isSelected
                                            ? selection.ticketIds.size
                                            : undefined
                                    }
                                />
                            ) : null}
                            <TicketRowContent ticket={_ticketFragment} />
                            <TicketMenu
                                ticket={_ticketFragment}
                                isFirstTicketInStage={isFirstTicketInStage}
                                isLastTicketInStage={isLastTicketInStage}
                            />
                        </>
                    }
                />
            )}
            ticket={ticket}
            onClick={e => maybeHandleTicketSelectionClick({ e, ticketId: ticket.id })}
        />
    );
}

type NewTicketClickTargetProps = {
    stage: FragmentType<typeof fragments.NewTicketClickTarget.stage>;
};

function NewTicketClickTarget({ stage: _stageFragment }: NewTicketClickTargetProps) {
    const stage = getFragmentData(fragments.NewTicketClickTarget.stage, _stageFragment);
    const newTicketEntry = useNewTicketEntry();
    const newTicketEntryPlacement = useNewTicketEntryPlacement();

    if (newTicketEntryPlacement.isPlaced) {
        return null;
    }

    return (
        <div
            className={styles.newTicketClickTarget}
            onClick={() =>
                newTicketEntry.openAt({
                    boardId: stage.board_id,
                    stageId: stage.id,
                    stagePos: moveToLastPosition({ maxPos: stage.max_ticket_stage_pos }),
                })
            }
        />
    );
}

type NewTicketRowProps = {
    stage: FragmentType<typeof fragments.NewTicketRow.stage>;
    index: number;
    onCancel: () => void;
    onCreate: ({ another }: { another: boolean }) => Promise<void>;
};

function NewTicketRow({ stage: _stageFragment, index, onCancel, onCreate }: NewTicketRowProps) {
    const stage = getFragmentData(fragments.NewTicketRow.stage, _stageFragment);
    const currentUser = useCurrentUser();
    const newTicketRowRef = useRef<HTMLLIElement | null>(null);
    const { recordEvent } = useInstrumentation();
    const newTicketEntry = useNewTicketEntry();
    const { title, labels, ownerUserId, dueDate } = useNewTicketEntryContent();
    const { createTicket } = useNewTicketEntryMutations();
    const submission = useAsyncWatcher();
    const newTicketInputRef = useRef(null);
    const boundariesElement = document.querySelector("#appRoot");

    const board = currentUser.org.all_boards.find(b => b.id === stage.board_id)!;
    const authorizedUsers = board.authorized_users
        .map(au => au.user?.id)
        .map(userId => currentUser.org.users.find(user => user.id === userId))
        .filter(isDefined);
    const selectedOwner = authorizedUsers.filter(isDefined).find(user => user.id === ownerUserId);
    const formattedDueDate = dueDate ? TicketDueDates.format({ dueDate }) : "No due date";

    useEffect(() => {
        newTicketRowRef.current?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "nearest",
        });
    }, []);

    const handleCancel = useCallback(() => {
        void recordEvent({
            eventType: Enums.InstrumentationEvent.ELEMENT_CANCEL,
            elementName: "list_view.new_ticket_row",
            dedupeKey: Date.now(),
        });

        onCancel?.();
    }, [onCancel, recordEvent]);

    const handleSubmit = useCallback(
        async ({ another }: { another?: boolean }) => {
            if (!title) {
                return;
            }

            void recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                elementName: "list_view.new_ticket_row",
                dedupeKey: Date.now(),
            });

            const newTicket = await createTicket();

            if (!newTicket) {
                AppToaster.error({
                    message: "Something went wrong creating that topic. Please try again.",
                });

                return;
            }

            await onCreate?.({ another: !!another });
        },
        [createTicket, onCreate, recordEvent, title]
    );

    useOutsideClick(
        newTicketInputRef,
        useCallback(() => {
            if (!title) {
                onCancel?.();
            }
        }, [onCancel, title]),
        {
            eventType: "mousedown",
        }
    );

    useOutsideClick(
        newTicketRowRef,
        useCallback(
            event => {
                if (!title) {
                    onCancel?.();
                } else if (
                    event.target instanceof Element &&
                    !event.target.closest(`.${Classes.OVERLAY}`)
                ) {
                    void handleSubmit({ another: false });
                }
            },
            [handleSubmit, onCancel, title]
        ),
        {
            eventType: "mousedown",
        }
    );

    return (
        <Draggable
            draggableId={dragAndDropEntity.getDndId(
                Enums.DndEntityTypes.NEW_TICKET_ROW,
                NewTicketEntryId,
                stage.id
            )}
            index={index}
            // This component is a Draggable so that dragging the regular ticket rows causes
            // this component to be repositioned correctly. But it's not actually itself draggable.
            isDragDisabled
        >
            {provided => (
                <li
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(
                        styles.newTicketRow,
                        submission.isInFlight && styles.newTicketRowIsSubmitting
                    )}
                    ref={e => {
                        newTicketRowRef.current = e;
                        provided.innerRef(e);
                    }}
                    tabIndex={-1}
                >
                    <div className={styles.newTicketRowContent}>
                        <AbstractTextInput
                            autoFocus
                            className={classNames(
                                styles.newTicketRowInput,
                                CssClasses.ALWAYS_SHOW_FOCUS_INDICATOR
                            )}
                            inputRef={newTicketInputRef}
                            placeholder="Enter title..."
                            onChange={e => newTicketEntry.setContent({ title: e.target.value })}
                            onKeyboardCancel={() => {
                                if (title) {
                                    newTicketEntry.setContent({ title: "" });
                                } else {
                                    handleCancel();
                                }
                            }}
                            onKeyboardSubmit={submission.watch(() =>
                                handleSubmit({ another: true })
                            )}
                            value={title}
                        />
                        {!!title && (
                            <>
                                <LabelsPicker
                                    className={styles.newTicketRowLabelsPicker}
                                    boardId={stage.board_id}
                                    initiallySelectedLabels={labels}
                                    onAdd={(_, selectedLabels) =>
                                        newTicketEntry.setContent({ labels: selectedLabels })
                                    }
                                    onRemove={(_, selectedLabels) =>
                                        newTicketEntry.setContent({ labels: selectedLabels })
                                    }
                                    {...(boundariesElement && {
                                        popoverModifiers: {
                                            flip: {
                                                boundariesElement,
                                            },
                                            preventOverflow: {
                                                boundariesElement,
                                            },
                                        },
                                    })}
                                />
                                <div className={styles.newTicketRowMetadataControls}>
                                    <UserSelect
                                        initiallySelectedItemsIDs={[ownerUserId].filter(isDefined)}
                                        onSelect={owner => {
                                            newTicketEntry.setContent({
                                                ownerUserId:
                                                    owner?.id && isFakeId(owner?.id)
                                                        ? null
                                                        : owner?.id,
                                            });
                                        }}
                                        users={authorizedUsers
                                            .filter(isDefined)
                                            .sort((a, b) =>
                                                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                                            )
                                            .concat(
                                                ownerUserId
                                                    ? [
                                                          {
                                                              id: generateFakeNumberId(),
                                                              avatar_url: null,
                                                              disabled_at: null,
                                                              github_login: null,
                                                              is_pending_disposition: false,
                                                              name: "Nobody",
                                                              full_name: "Nobody",
                                                              identity: {
                                                                  id: generateFakeStringId(),
                                                                  email_address: "",
                                                              },
                                                              slug: "",
                                                          },
                                                      ]
                                                    : []
                                            )}
                                        getInstrumentation={user => ({
                                            elementName: "list_view.new_ticket_row.owner_menu",
                                            eventData: {
                                                userId: user?.id ?? null,
                                            },
                                        })}
                                        makeFirstOptionCurrentUser
                                        hideMenuItemIconMode={
                                            Enums.HideMenuItemIconMode.WHEN_NOTHING_SELECTED
                                        }
                                    >
                                        <BorderButton
                                            content={
                                                selectedOwner ? (
                                                    <span className={styles.newTicketRowOwner}>
                                                        <Avatar
                                                            className={
                                                                styles.newTicketRowOwnerAvatar
                                                            }
                                                            user={selectedOwner}
                                                            size={20}
                                                        />
                                                        <span>{selectedOwner.name}</span>
                                                    </span>
                                                ) : (
                                                    <Icon
                                                        icon="user-plus"
                                                        iconSet="lucide"
                                                        iconSize={18}
                                                        strokeWidth={1}
                                                    />
                                                )
                                            }
                                            instrumentation={{
                                                elementName: "list_view.new_ticket_row.owner_btn",
                                            }}
                                            flushSide
                                            minimal
                                            square
                                            tighter
                                        />
                                    </UserSelect>
                                    <DatePopover
                                        getInstrumentation={() => ({
                                            elementName: "list_view.new_ticket_row.due_date_picker",
                                        })}
                                        onSelect={date =>
                                            newTicketEntry.setContent({ dueDate: date ?? null })
                                        }
                                        selectedDate={dueDate ?? undefined}
                                    >
                                        <BorderButton
                                            content={
                                                dueDate ? (
                                                    formattedDueDate
                                                ) : (
                                                    <Icon
                                                        icon="calendar-plus"
                                                        iconSet="lucide"
                                                        iconSize={18}
                                                        strokeWidth={1}
                                                    />
                                                )
                                            }
                                            instrumentation={{
                                                elementName: "list_view.new_ticket_row.owner_btn",
                                            }}
                                            flushSide
                                            minimal
                                            square
                                            tighter
                                        />
                                    </DatePopover>
                                </div>
                                <div className={styles.newTicketRowFormControls}>
                                    <BorderButton
                                        onClick={handleCancel}
                                        content="Cancel"
                                        instrumentation={{
                                            elementName: "list_view.new_ticket_row.cancel_btn",
                                        }}
                                        flush
                                        minimal
                                        small
                                    />
                                    <BorderButton
                                        disabled={!title}
                                        loading={submission.isInFlight}
                                        onClick={submission.watch(() =>
                                            handleSubmit({ another: false })
                                        )}
                                        content="Create"
                                        instrumentation={{
                                            elementName: "list_view.new_ticket_row.submit_btn",
                                        }}
                                        brandCta
                                        small
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </li>
            )}
        </Draggable>
    );
}

type ListProps = {
    stage: FragmentType<typeof fragments.List.stage>;
    tickets: FragmentType<typeof fragments.List.ticket>[];
};

function List({ stage: _stageFragment, tickets: _ticketFragments }: ListProps) {
    const stage = getFragmentData(fragments.List.stage, _stageFragment);
    const tickets = _ticketFragments.map(t => getFragmentData(fragments.List.ticket, t));
    const {
        collapsedStageIds,
        isDragInProgress,
        showTicketCounts,
        toggleStageCollapse,
    } = useBoardView();
    const newTicketEntry = useNewTicketEntry();
    const newTicketEntryPlacement = useNewTicketEntryPlacement();
    const isCollapsed = collapsedStageIds.includes(stage.id);

    const onCreateNewTicket = async ({ another = false }) => {
        if (another) {
            newTicketEntry.openAt({
                boardId: stage.board_id,
                stageId: stage.id,
                stagePos: moveToLastPosition({ maxPos: newTicketEntryPlacement.stagePos }),
            });
        }
    };

    return (
        <TicketList
            bottomElement={!isCollapsed ? <NewTicketClickTarget stage={stage} /> : null}
            className={classNames(
                styles.list,
                newTicketEntryPlacement.stageId === stage.id && styles.isEnteringNewTicket
            )}
            isCollapsed={isCollapsed}
            // As of October 2023, it's importing for the ticket list *not* to be horizontally
            // scrollable *during* a drag, so that react-beautiful-dnd detects the *vertically*
            // scrollable list view and scrolls the entire list vertically as the user drags.
            isHorizontallyScrollable={!isDragInProgress}
            listId={stage.id}
            sectionHeader={
                <TicketListHeader
                    className={styles.listHeader}
                    instrumentation={{
                        elementName: "list_view.stage_header.collapse_btn",
                        eventData: { stageId: stage.id, isCollapsed },
                    }}
                    isCollapsed={isCollapsed}
                    leftElements={
                        <>
                            {showTicketCounts && (
                                <TicketCount
                                    className={styles.ticketCount}
                                    ticketCount={tickets.length}
                                />
                            )}
                        </>
                    }
                    rightElements={
                        <>
                            <StageMenu
                                buttonClassName={styles.stageMenuButton}
                                className={styles.stageMenuWrapper}
                                popoverClassName={styles.stageMenuPopover}
                                stage={stage}
                                tickets={tickets}
                            />
                        </>
                    }
                    title={stage.display_name}
                    toggleIsCollapsed={() => {
                        toggleStageCollapse(stage.id);

                        if (newTicketEntryPlacement.stageId === stage.id) {
                            newTicketEntry.clear();
                        }
                    }}
                />
            }
            shouldScroll={() => false}
        >
            {tickets.map((ticket, i) => (
                <TicketRow
                    key={ticket.id}
                    ticket={ticket}
                    index={i}
                    isFirstTicketInStage={i === 0}
                    isLastTicketInStage={i === tickets.length - 1}
                />
            ))}
            {newTicketEntryPlacement.isPlaced && newTicketEntryPlacement.stageId === stage.id && (
                <NewTicketRow
                    stage={stage}
                    index={tickets.length}
                    onCreate={onCreateNewTicket}
                    onCancel={newTicketEntry.clear}
                />
            )}
        </TicketList>
    );
}

export function ListView() {
    const {
        board,
        boardStages,
        collapsedStageIds,
        flipListViewStageOrder,
        tickets,
    } = useBoardView();
    const listViewRef = useRef(null);

    useSaveScrollPosition({ scrollElementRef: listViewRef, component: "list_view", id: board.id });

    return (
        <DynamicWidthTable
            columnDefinitions={ListView.DynamicWidthTableColumnDefinitions}
            dependencies={[board.id, tickets, collapsedStageIds.join("|")]}
        >
            <div className={classNames(styles.listView)} ref={listViewRef}>
                <ScrollDivider className={styles.listViewDivider} />
                <div className={styles.lists}>
                    {boardStages
                        .sort(flipListViewStageOrder ? sortStages({ order: "desc" }) : sortStages())
                        .map(stage => (
                            <List
                                key={stage.id}
                                stage={stage}
                                tickets={tickets.filter(t => t.stage_id === stage.id)}
                            />
                        ))}
                </div>
            </div>
        </DynamicWidthTable>
    );
}

ListView.DynamicWidthTableColumnDefinitions = [
    ...TicketRowContentDynamicWidthTableColumnDefinitions,
    {
        columnClassName: styles.menu,
        displayIfEmpty: true,
    },
];
