import React from "react";

import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { integrationsSetupStatusState } from "AppState";
import { MergeRequest } from "components/shared/MergeRequest";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { InfoCircle } from "components/ui/common/InfoCircle";
import { BorderButton } from "components/ui/core/BorderButton";
import { Hotspot } from "components/ui/core/Hotspot";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Enums } from "lib/Enums";
import { useClipboard, useResettingState } from "lib/Hooks";
import { useSetTicketOwnerToCurrentUserUX } from "lib/MutationUX";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { SuggestedBranch_ticketFragment } from "lib/graphql/__generated__/graphql";

import styles from "./CodeSection.module.scss";
import { ContentBox } from "./ContentBox";
import { useDetailView } from "../context/DetailViewContext";

const fragments = {
    CodeSectionDetail: {
        ticket: gql(/* GraphQL */ `
            fragment CodeSectionDetail_ticket on tickets {
                id

                merge_requests {
                    merge_request {
                        id
                        closed_at
                        merged_at
                        opened_at

                        ...MergeRequest_merge_request
                    }
                }

                ...SuggestedBranch_ticket
            }
        `),
    },
    CodeSectionHeader: {
        ticket: gql(/* GraphQL */ `
            fragment CodeSectionHeader_ticket on tickets {
                id
                ref
                title

                merge_requests {
                    merge_request {
                        id
                    }
                }

                owners {
                    ticket_id
                    user_id
                }
            }
        `),
    },
    CodeSection: {
        ticket: gql(/* GraphQL */ `
            fragment CodeSection_ticket on tickets {
                id

                board {
                    id
                    settings
                }

                merge_requests {
                    merge_request {
                        id
                    }
                }

                ...CodeSectionHeader_ticket
                ...CodeSectionDetail_ticket
            }
        `),
    },
    SuggestedBranch: {
        ticket: gql(/* GraphQL */ `
            fragment SuggestedBranch_ticket on tickets {
                id
                ref

                owners {
                    ticket_id
                    user_id
                }
            }
        `),
    },
};

export type SuggestBranchProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.SuggestedBranch.ticket>;
};

export function SuggestedBranch({ className, ticket: _ticketFragment }: SuggestBranchProps) {
    const ticket = getFragmentData(fragments.SuggestedBranch.ticket, _ticketFragment);
    const { suggestedBranchName } = useDetailView();

    return (
        <SuggestedBranch.Display
            className={className}
            suggestedBranchName={suggestedBranchName}
            ticket={ticket}
        />
    );
}

type SuggestedBranchDisplayProps = {
    className?: string;
    suggestedBranchName: string;
    ticket: SuggestedBranch_ticketFragment;
};

function SuggestedBranchDisplay({
    className,
    suggestedBranchName,
    ticket,
}: SuggestedBranchDisplayProps) {
    const { id: ticketId, ref: ticketRef } = ticket;
    const { copyTextToClipboard } = useClipboard();
    const [isCopiedTipOpen, setIsCopiedTipOpen] = useResettingState(false, 1500);
    const { setTicketOwnerToCurrentUserUX } = useSetTicketOwnerToCurrentUserUX({
        ticketId,
        ticketRef,
    });

    const handleClick = async () => {
        setIsCopiedTipOpen(true);
        await Promise.all([
            copyTextToClipboard({ text: suggestedBranchName }),
            !ticket.owners.length && setTicketOwnerToCurrentUserUX(),
        ]);
    };

    return (
        <Hotspot
            fill
            hotspotKey={Enums.HotspotKey.SUGGESTED_BRANCH_NAME}
            offset={[0, 14]}
            placement="left"
            usePortal={false}
        >
            <div className={classNames(className, styles.suggestedBranch)}>
                <Icon
                    className={styles.suggestedBranchIcon}
                    icon="codeBranch"
                    iconSet="c9r"
                    iconSize={18}
                />
                <span className={styles.suggestedBranchName}>{suggestedBranchName}</span>{" "}
                <Popover2
                    content={<div className={styles.copiedTip}>Copied to clipboard</div>}
                    interactionKind={Popover2InteractionKind.CLICK}
                    isOpen={isCopiedTipOpen}
                    modifiers={{
                        arrow: { enabled: false },
                        hide: { enabled: false },
                        flip: { enabled: false },
                    }}
                    placement="top"
                    className={styles.copyBranchName}
                >
                    <BorderButton
                        className={styles.suggestedBranchNameCopyBtn}
                        content={<Icon icon="copy" iconSet="lucide" />}
                        instrumentation={{
                            elementName: "ticket_detail.code_section.copy_branch_btn",
                            eventData: { ticketId },
                        }}
                        minimal
                        onClick={handleClick}
                        small
                    />
                </Popover2>
                <div style={{ flex: "1 1 auto" }} />
                <InfoCircle
                    className={styles.suggestedBranchNameHelpIcon}
                    content={
                        <div>
                            <p className={styles.branchExplanation}>
                                Use this branch name to have pull requests automatically linked to
                                this topic. Or use any branch name containing the topic ID similar
                                to these:
                                <ul>
                                    <li>{ticketRef}</li>
                                    <li>{ticketRef}-foo-bar</li>
                                    <li>{ticketRef}_foo_bar</li>
                                    <li>baz/{ticketRef}-foo-bar</li>
                                </ul>
                            </p>
                        </div>
                    }
                    iconProps={{ icon: "help-circle", iconSet: "lucide" }}
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [10, 6],
                            },
                        },
                    }}
                    placement="bottom-end"
                />
            </div>
        </Hotspot>
    );
}

SuggestedBranch.Display = SuggestedBranchDisplay;

type CodeSectionHeaderProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.CodeSectionHeader.ticket>;
};

function CodeSectionHeader({ className, ticket: _ticketFragment }: CodeSectionHeaderProps) {
    const ticket = getFragmentData(fragments.CodeSectionHeader.ticket, _ticketFragment);
    const { copyTextToClipboard } = useClipboard();
    const { suggestedBranchName } = useDetailView();
    const { setTicketOwnerToCurrentUserUX } = useSetTicketOwnerToCurrentUserUX({
        ticketId: ticket.id,
        ticketRef: ticket.ref,
    });

    return (
        <div className={classNames(styles.sectionHeader, className)}>
            <Hotspot
                disabled={!ticket.merge_requests.length}
                hotspotKey={Enums.HotspotKey.SUGGESTED_BRANCH_NAME}
                offset={[0, 24]}
                placement="left"
                usePortal={false}
            >
                <h3>Code</h3>
            </Hotspot>
            {ticket.merge_requests.length ? (
                <MenuPopover
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [8, -4],
                            },
                        },
                    }}
                    content={
                        <Menu>
                            <MenuItem
                                text="Copy branch name"
                                icon={<Icon icon="codeBranch" iconSet="c9r" iconSize={18} />}
                                instrumentation={{
                                    elementName: "ticket_detail.code_section.menu.copy_branch_name",
                                    eventData: {
                                        ticketId: ticket.id,
                                    },
                                }}
                                onClick={() => {
                                    void Promise.all([
                                        copyTextToClipboard({
                                            text: suggestedBranchName,
                                            successToast: "Branch name copied to clipboard.",
                                        }),
                                        !ticket.owners.length && setTicketOwnerToCurrentUserUX(),
                                    ]);
                                }}
                            />
                        </Menu>
                    }
                    placement="bottom-end"
                >
                    <EllipsisButton
                        className={styles.ellipsisBtn}
                        instrumentation={null}
                        vertical
                    />
                </MenuPopover>
            ) : null}
        </div>
    );
}

export type CodeSectionDetailProps = {
    ticket: FragmentType<typeof fragments.CodeSectionDetail.ticket>;
};

function CodeSectionDetail({ ticket: _ticketFragment }: CodeSectionDetailProps) {
    const ticket = getFragmentData(fragments.CodeSectionDetail.ticket, _ticketFragment);

    if (!ticket.merge_requests.length) {
        return <SuggestedBranch ticket={ticket} />;
    }

    const openMergeRequests = ticket.merge_requests
        .map(tmr => tmr.merge_request)
        .filter(mr => !mr.closed_at && !mr.merged_at)
        .sort((mrA, mrB) => new Date(mrB.opened_at).getTime() - new Date(mrA.opened_at).getTime());
    const mergedMergeRequests = ticket.merge_requests
        .map(tmr => tmr.merge_request)
        .filter(mr => !!mr.merged_at)
        .sort(
            (mrA, mrB) => new Date(mrB.merged_at!).getTime() - new Date(mrA.merged_at!).getTime()
        );
    const closedMergeRequests = ticket.merge_requests
        .map(tmr => tmr.merge_request)
        .filter(mr => !!mr.closed_at && !mr.merged_at)
        .sort(
            (mrA, mrB) => new Date(mrB.closed_at!).getTime() - new Date(mrA.closed_at!).getTime()
        );

    return (
        <div className={styles.prs}>
            {[openMergeRequests, mergedMergeRequests, closedMergeRequests].map(mergeRequests =>
                mergeRequests.length > 0 ? (
                    <section>
                        <ul>
                            {mergeRequests.map(mr => (
                                <li key={mr.id} className={styles.pr}>
                                    <MergeRequest elementName="ticket_detail.pr_link" mr={mr} />
                                </li>
                            ))}
                        </ul>
                    </section>
                ) : null
            )}
        </div>
    );
}

export type CodeSectionProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.CodeSection.ticket>;
};

export function CodeSection({ className, ticket: _ticketFragment }: CodeSectionProps) {
    const ticket = getFragmentData(fragments.CodeSection.ticket, _ticketFragment);
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);

    const isGithubIntegrationActive = integrationsSetupStatus.github;
    const isCodeEnabled = ticket.board.settings[CommonEnums.BoardSettingType.CODE]?.enabled;

    if (!(isGithubIntegrationActive && isCodeEnabled) && !ticket.merge_requests.length) {
        return null;
    }

    return (
        <ContentBox className={className} tight>
            <div>
                <CodeSectionHeader ticket={ticket} />
                <CodeSectionDetail ticket={ticket} />
            </div>
        </ContentBox>
    );
}
