import { CurrentOrg, CurrentUserLegacy } from "lib/types/common/currentUser";

export type TOrg = CurrentOrg;

export type TUser = {
    id: number;
    avatar_url: string | null;
    full_name: string | null;
    github_login: string | null;
    name: string;
};

export class UserContextApi {
    readonly currentUser: CurrentUserLegacy;

    constructor({ currentUser }: { currentUser: CurrentUserLegacy }) {
        this.currentUser = currentUser;
    }

    getOrg(): TOrg {
        return this.currentUser.org;
    }

    getUserById({ userId }: { userId: number }): TUser | null {
        return this.currentUser.org.all_users.find(user => user.id === userId) ?? null;
    }

    getUserByIdOrThrow({ userId }: { userId: number }): TUser {
        const user = this.getUserById({ userId });

        if (!user) {
            throw new Error("User not found");
        }

        return user;
    }
}
