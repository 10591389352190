import React from "react";

import classNames from "classnames";

import { Icon, IconProps } from "components/ui/core/Icon";
import { Tooltip, TooltipProps } from "components/ui/core/Tooltip";

import styles from "./InfoCircle.module.scss";

export type InfoCircleProps = {
    className?: string;
    content: TooltipProps["content"];
    iconProps?: IconProps;
    modifiers?: TooltipProps["modifiers"];
    placement?: TooltipProps["placement"];
};

/**
 * Info circle to display more info on hover.
 */
export function InfoCircle({
    className,
    content,
    iconProps,
    modifiers,
    placement,
}: InfoCircleProps) {
    const iconElementProps = iconProps ?? { icon: "info", iconSet: "lucide" };

    return (
        <Tooltip
            className={className}
            content={content}
            modifiers={modifiers}
            placement={placement}
            small
            showFast
            showOnMobile
        >
            <Icon
                {...iconElementProps}
                className={classNames(iconProps?.className, styles.infoCircleIcon)}
            />
        </Tooltip>
    );
}
