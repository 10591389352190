import React from "react";

import classNames from "classnames";

import { Config } from "Config";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";

import styles from "./SideNavDisplayToggle.module.scss";
import { useAppLayout } from "../AppLayoutContext";

export type SideNavDisplayToggleProps = {
    className?: string;
    contentClassName?: string;
};

export function SideNavDisplayToggle({ className, contentClassName }: SideNavDisplayToggleProps) {
    const { appLayoutState, toggleIsSideNavCollapsed } = useAppLayout();

    if (Config.demoUI.enabled) {
        return null;
    }

    return (
        <BorderButton
            className={classNames(className, styles.button)}
            contentClassName={contentClassName}
            content={
                <Icon
                    icon={appLayoutState.isSideNavCollapsed ? "sidebar-open" : "sidebar-close"}
                    iconSet="lucide"
                    iconSize={20}
                    strokeWidth={1.75}
                />
            }
            flush
            instrumentation={{
                elementName: "navbar.side_nav_toggle",
                eventData: {
                    isCollapsed: !toggleIsSideNavCollapsed,
                },
            }}
            minimal
            onClick={toggleIsSideNavCollapsed}
            small
            textHover
            themeDark
            square
        />
    );
}
