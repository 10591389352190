import React, { useCallback, useEffect, useMemo, useState } from "react";

import { TLabel } from "c9r-common";

import { useCurrentUser } from "contexts/UserContext";
import {
    isLabelMatchingFilterTerms,
    isTicketMatchingFilterTerms,
    isUserMatchingFilterTerms,
    parseFilterExpression,
} from "lib/Filter";
import { useHistory } from "lib/Routing";
import { Storage } from "lib/Storage";
import { FragmentType } from "lib/graphql/__generated__";
import {
    TicketFilter_ticketFragmentDoc,
    UserFilter_userFragmentDoc,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

export type BoardViewFilterContextValue = {
    filterExpression: string;
    onFilterExpressionChange: (newFilterExpression: string) => void;
    appendTermToFilterExpression: (term: string) => void;
    isLabelMatchingFilterExpression: ({ label }: { label: TLabel }) => boolean;
    isTicketMatchingFilterExpression: ({
        ticket,
    }: {
        ticket: FragmentType<typeof TicketFilter_ticketFragmentDoc>;
    }) => boolean;
    isUserMatchingFilterExpression: ({
        user,
    }: {
        user: FragmentType<typeof UserFilter_userFragmentDoc>;
    }) => boolean;
};

const [useBoardViewFilter, ContextProvider] = createCtx<BoardViewFilterContextValue>();

export { useBoardViewFilter };

export type BoardViewFilterProviderProps = {
    children: React.ReactNode;
    boardId: string;
};

export function BoardViewFilterProvider({ children, boardId }: BoardViewFilterProviderProps) {
    const storageKey = `board.${boardId}.filterExpression`;
    const currentUser = useCurrentUser();
    const { setUrlSearchParam } = useHistory();

    const [filterExpression, setFilterExpression] = useState(
        (new URLSearchParams(window.location.search).get("filter") as string) ||
            (Storage.Session.getItem(storageKey) as string) ||
            ""
    );

    useEffect(() => {
        const currentQueryParam = new URLSearchParams(window.location.search).get("filter") || "";
        const newQueryParam = filterExpression || "";

        if (currentQueryParam !== newQueryParam) {
            setUrlSearchParam({ key: "filter", val: filterExpression || "" });
        }

        Storage.Session.setItem(storageKey, filterExpression);
    }, [filterExpression, setUrlSearchParam, storageKey]);

    const onFilterExpressionChange = useCallback((newFilterExpression: string) => {
        setFilterExpression(newFilterExpression || "");
    }, []);

    const appendTermToFilterExpression = useCallback(
        (term: string) => {
            onFilterExpressionChange(filterExpression ? `${filterExpression} ${term}` : term);
        },
        [filterExpression, onFilterExpressionChange]
    );

    const filterTerms = useMemo(() => parseFilterExpression({ filterExpression }), [
        filterExpression,
    ]);

    const isLabelMatchingFilterExpression = useCallback(
        ({ label }: { label: TLabel }) => isLabelMatchingFilterTerms({ label, filterTerms }),
        [filterTerms]
    );

    const isTicketMatchingFilterExpression = useCallback(
        ({ ticket }: { ticket: FragmentType<typeof TicketFilter_ticketFragmentDoc> }) =>
            isTicketMatchingFilterTerms({ ticket, userId: currentUser.id, filterTerms }),
        [currentUser.id, filterTerms]
    );

    const isUserMatchingFilterExpression = useCallback(
        ({ user }: { user: FragmentType<typeof UserFilter_userFragmentDoc> }) =>
            isUserMatchingFilterTerms({ user, filterTerms }),
        [filterTerms]
    );

    const value = useMemo(
        () => ({
            filterExpression,
            onFilterExpressionChange,
            appendTermToFilterExpression,
            isLabelMatchingFilterExpression,
            isTicketMatchingFilterExpression,
            isUserMatchingFilterExpression,
        }),
        [
            appendTermToFilterExpression,
            filterExpression,
            onFilterExpressionChange,
            isLabelMatchingFilterExpression,
            isTicketMatchingFilterExpression,
            isUserMatchingFilterExpression,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
