import React, { useEffect, useState } from "react";

export type ProgressTextProps = {
    /** Optional class name. */
    className?: string;

    /** Interval in milliseconds between changes. The final reset from "..." back to no dots is twice this. */
    intervalMs?: number;

    /** Text to display before animated "..." */
    text: string;
};

/**
 * Renders some text with an animated "..." to indicate progress, like "Loading" => "Loading...".
 * To ensure it's a similar width as the dots are added, renders non-breaking spaces in place of
 * dots.
 */
export function ProgressText({ className, intervalMs = 500, text }: ProgressTextProps) {
    const maxDots = 3;
    const [count, setCount] = useState(0);

    useEffect(() => {
        const updateCountTimeout = setTimeout(
            () => setCount(count < maxDots ? count + 1 : 0),
            count < maxDots ? intervalMs : intervalMs * 2
        );

        return () => {
            clearTimeout(updateCountTimeout);
        };
    }, [count, intervalMs]);

    return (
        <span className={className}>
            {text}
            {".".repeat(count)}
            {"\xa0".repeat(maxDots - count)}
        </span>
    );
}
