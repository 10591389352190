import React from "react";

import classNames from "classnames";

import styles from "./TextInput.module.scss";

export type TextInputProps = {
    className?: string;
    fill?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    hasError?: boolean;
} & React.ComponentPropsWithoutRef<"input">;

export function TextInput({
    className,
    fill,
    inputRef,
    hasError,

    ...htmlInputProps
}: TextInputProps) {
    return (
        <input
            type="text"
            {...htmlInputProps}
            className={classNames(
                className,
                styles.textInput,
                htmlInputProps?.disabled && styles.disabled,
                fill && styles.fill,
                hasError && styles.hasError
            )}
            ref={inputRef}
        />
    );
}
