import React from "react";

import { CommonEnums, sortLabels } from "c9r-common";

import { Label } from "components/shared/Label";
import { Avatar } from "components/ui/common/Avatar";
import { PunctuatedList } from "components/ui/common/PunctuatedList";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { BotUser } from "lib/BotUser";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { Link, useLocation } from "lib/Routing";
import { useTicketActivityDatesInfo, useTicketParentsInfo } from "lib/TicketInfo";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql, makeFragmentData } from "lib/graphql/__generated__";
import {
    Avatar_userFragmentDoc,
    CommentsSearchIndex_commentFragment,
    SearchResult_ticketFragment,
    TasksSearchIndex_taskFragment,
} from "lib/graphql/__generated__/graphql";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";

import styles from "./SearchResult.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment SearchResult_ticket on tickets {
            id
            ref
            slug
            title

            board {
                id
                access_type
                display_name
            }

            label_attachments {
                color
                text
            }

            comments {
                id
                posted_at

                author {
                    id
                    name

                    ...Avatar_user
                }
            }

            ...TicketActivityDatesInfo_ticket
            ...TicketParentsInfo_ticket
        }
    `),
};

type TicketLinkProps = {
    ticket: SearchResult_ticketFragment;
};

function TicketLink({ ticket }: TicketLinkProps) {
    const currentUser = useCurrentUser();
    const location = useLocation();
    const prefetchQuery = usePrefetchQuery();
    const { recordCallback } = useInstrumentation();
    const { buildTicketUrl } = useUrlBuilders();

    const prefetchTicketDetails = () => {
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref: ticket.ref,
            },
        });
    };

    return (
        <Link
            className={styles.title}
            onFocus={() => prefetchTicketDetails()}
            onMouseOver={() => prefetchTicketDetails()}
            to={{
                pathname: buildTicketUrl({
                    ticketSlug: ticket.slug,
                    vanity: {
                        boardDisplayName: ticket.board.display_name,
                        ticketRef: ticket.ref,
                        ticketTitle: ticket.title,
                    },
                }).pathname,
                state: {
                    from: { location, pageDetails: { appPage: Enums.AppPage.SEARCH_RESULTS } },
                },
            }}
            onClick={recordCallback({
                eventType: Enums.InstrumentationEvent.CLICK,
                elementName: "search_result.ticket_title",
                eventData: { ticketId: ticket.id },
            })}
            role="link"
        >
            {ticket.title}
        </Link>
    );
}

type CommentLinkProps = {
    comment: CommentsSearchIndex_commentFragment;
    ticket: SearchResult_ticketFragment;
};

function CommentLink({ ticket, comment }: CommentLinkProps) {
    const location = useLocation();
    const { buildTicketUrl } = useUrlBuilders();

    return (
        <Link
            className={styles.link}
            to={{
                pathname: buildTicketUrl({
                    ticketSlug: ticket.slug,
                    vanity: {
                        boardDisplayName: ticket.board.display_name,
                        ticketRef: ticket.ref,
                        ticketTitle: ticket.title,
                    },
                }).pathname,
                hash: `#C${comment.id}`,
                state: {
                    from: { location, pageDetails: { appPage: Enums.AppPage.SEARCH_RESULTS } },
                },
            }}
        >
            View comment
        </Link>
    );
}

type MatchContextProps = {
    matches: string[];
    matchingComments?: CommentsSearchIndex_commentFragment[];
    matchingTasks?: TasksSearchIndex_taskFragment[];
    ticket: SearchResult_ticketFragment;
};

function MatchContext({
    matches,
    matchingComments = [],
    matchingTasks = [],
    ticket,
}: MatchContextProps) {
    const tasksCount = matchingTasks.length;
    const commentsCount = matchingComments.length;

    const maybeGetFieldText = (fieldName: string, count: number, endChar: string = "") =>
        !!count && `${count} ${fieldName}${count > 1 ? "s" : ""}${endChar}`;

    return matches.some(m => ["description", "tasks", "comments"].includes(m)) ? (
        <div className={styles.matchContext}>
            <div className={styles.matchText}>
                Matched&nbsp;
                <PunctuatedList joinText="and">
                    {[
                        matches.includes("description") && "the description",
                        matches.includes("tasks") && maybeGetFieldText("task", tasksCount),
                        matches.includes("comments") &&
                            maybeGetFieldText("comment", commentsCount, ":"),
                    ].filter(Boolean)}
                </PunctuatedList>
            </div>
            {matches.includes("comments") && !!matchingComments.length && (
                <div className={styles.matchingComments}>
                    {matchingComments.map(comment => {
                        return (
                            <div key={comment.id} className={styles.comment}>
                                <Avatar
                                    className={styles.avatar}
                                    user={
                                        comment.author ??
                                        makeFragmentData(BotUser, Avatar_userFragmentDoc)
                                    }
                                    size={20}
                                />
                                <span className={styles.author}>
                                    {(comment.author ?? BotUser).name}
                                </span>
                                <TimeAgo className={styles.postedAt} date={comment.posted_at} />
                                <CommentLink ticket={ticket} comment={comment} />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    ) : null;
}

export type SearchResultProps = {
    matches: string[];
    matchingComments: CommentsSearchIndex_commentFragment[];
    matchingTasks: TasksSearchIndex_taskFragment[];
    ticket: FragmentType<typeof fragments.ticket>;
};

export function SearchResult({
    matches,
    matchingComments,
    matchingTasks,
    ticket: _ticketFragment,
}: SearchResultProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { parentTicketsText } = useTicketParentsInfo({ ticket });
    const { createdAt, importedAt, updatedAt, archivedAt } = useTicketActivityDatesInfo({ ticket });
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    return (
        <div className={styles.searchResult}>
            <div className={styles.ticketInfo}>
                <div className={styles.headerContent}>
                    <span className={styles.board}>
                        {ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                            <Icon
                                icon="lock"
                                iconSet="c9r"
                                iconSize={12}
                                strokeWidthAbsolute={1.5}
                            />
                        ) : (
                            <Icon icon="spaceFrame" iconSet="c9r" iconSize={12} strokeWeight={1} />
                        )}
                        <span>{ticket.board.display_name}</span>
                    </span>
                    {shouldShowTicketRefs ? <span className={styles.ref}>{ticket.ref}</span> : null}
                    {parentTicketsText && (
                        <span className={styles.parentTickets}>{parentTicketsText}</span>
                    )}
                </div>
                <TicketLink ticket={ticket} />
                {!!ticket.label_attachments.length && (
                    <div className={styles.labels}>
                        {ticket.label_attachments
                            .concat()
                            .sort(sortLabels())
                            .map(label => (
                                <Label
                                    key={`${label.color}|${label.text}`}
                                    className={styles.label}
                                    color={label.color}
                                    text={label.text}
                                    small
                                />
                            ))}
                    </div>
                )}
                <MatchContext
                    matches={matches}
                    matchingComments={matchingComments}
                    matchingTasks={matchingTasks}
                    ticket={ticket}
                />
            </div>
            <div className={styles.ticketActivityDates}>
                {createdAt && (
                    <div>
                        Created&nbsp;
                        <TimeAgo date={createdAt} />
                    </div>
                )}
                {importedAt && (
                    <div>
                        Imported&nbsp;
                        <TimeAgo date={importedAt} />
                    </div>
                )}
                {updatedAt && (
                    <div>
                        Last&nbsp;updated&nbsp;
                        <TimeAgo date={updatedAt} />
                    </div>
                )}
                {archivedAt && (
                    <div>
                        Archived&nbsp;
                        <TimeAgo date={archivedAt} />
                    </div>
                )}
            </div>
        </div>
    );
}
