import { HandlerType, StatusType } from "@datadog/browser-logs";
import DeepMerge from "deepmerge";
import { TEST_LICENSE_KEY } from "replicache";

import { Env } from "Env";
import { EnumValue, Enums } from "lib/Enums";

export type FeatureConfig = {
    enabled: boolean;
    message?: string;
    value?: number | string | object;
};

export type HotspotKeyConfig = {
    allowList?: string[];
    appPage: EnumValue<"AppPage">;
    enabled: boolean;
    prerequisites: EnumValue<"HotspotKey">[];
};

/**
 * Sometimes we may want to use the demo environment without the extra demo UI chrome (footer,
 * intro dialog, etc.). To make that easy, we look for a special URL param. Set to 0 to disable
 * the demo UI, set to 1 to enable (default).
 */
function maybeShowDemoUI() {
    if (new URLSearchParams(window.location.search).get("ui") === "0") {
        window.localStorage.setItem("demo.ui.disabled", "true");
    }

    if (new URLSearchParams(window.location.search).get("ui") === "1") {
        window.localStorage.removeItem("demo.ui.disabled");
    }

    return !(window.localStorage.getItem("demo.ui.disabled") === "true");
}

const envConfigs = {
    default: {
        accessTokenCheckIntervalMs: 5000,
        accessTokenPrefetchPollIntervalMs: 30,
        api: {
            urlHttp: "http://localhost:8080/v1/graphql",
            urlHttpProxy: "http://localhost:8888/api/development",
            urlWs: "ws://localhost:8080/v1/graphql",
        },
        apollo: {
            // As of December 2023, Hasura is configured to send keepalive pings every 5s. This
            // delay is configured to wait a few missing pings before killing the socket completely
            // and attempting to reconnect.
            socketKeepAliveTimeoutMs: 12000,
            socketRestartDelayMs: 5000,
            subscriptionRestartDelayMs: 4000,
        },
        appType: window.electron ? Enums.AppType.DESKTOP : Enums.AppType.WEB,
        auth: {
            provider: "AUTH0",
            preExpirationPromptMs: 1800000,
            preExpirationRefreshMs: 600000,
        },
        auth0: {
            clientId: "FAKE_AUTH0_CLIENT_ID",
            domain: "FAKE_AUTH0_DOMAIN",
        },
        billing: {
            freePlanMaxUsers: 10,
        },
        customProtocol: "flat",
        datadog: {
            clientToken: "pub628a1438199fd6cbfe3151ed70176d2c",
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        },
        demoUI: {
            enabled: false,
            tour: {
                exampleTicketRef: "1",
                exampleTicketSlug: "1a2b3c4d",
                exampleBoardDisplayName: "General",
                exampleBoardSlug: "1a2b3c4d",
                exampleStageDisplayName: "In progress",
                exampleUsername: "foo",
                exampleUserSlug: "1a2b3c4d",
            },
        },
        discordClientId: "FAKE_DISCORD_CLIENT_ID",
        entityPositioning: {
            boundaryFactor: Math.pow(1.97, 0.49),
            default: Math.pow(1.97, 3.35),
            jitter: true,
            maxIncrement: 10.427811,
        },
        features: {
            config: {
                defaultMessage: "Sorry, that feature is not available.",
            },
            byFeature: {
                [Enums.Feature.ACCOUNT_SETTINGS]: {
                    enabled: true,
                },
                [Enums.Feature.DEBUG_ROUTES]: {
                    enabled: false,
                },
                [Enums.Feature.DISCORD_INTEGRATION]: {
                    enabled: true,
                },
                [Enums.Feature.GITHUB_INTEGRATION]: {
                    enabled: true,
                },
                [Enums.Feature.GITHUB_INTEGRATION_FAKE_INSTALLATION]: {
                    enabled: false,
                },
                [Enums.Feature.IDENTITY_COOKIE]: {
                    enabled: true,
                },
                [Enums.Feature.IMPORT_DATA]: {
                    enabled: true,
                },
                [Enums.Feature.LIVE_CHAT_SUPPORT]: {
                    enabled: true,
                },
                [Enums.Feature.MANAGE_USERS]: {
                    enabled: true,
                },
                [Enums.Feature.ONBOARDING]: {
                    enabled: true,
                },
                [Enums.Feature.REPLICACHE_USE_TEST_LICENSE]: {
                    enabled: false,
                },
                [Enums.Feature.SLACK_INTEGRATION]: {
                    enabled: true,
                },
                [Enums.Feature.UPLOADS]: {
                    enabled: true,
                    value: {
                        // If updating, be sure both values match and are consistent with the
                        // backend Config.js.
                        maxSizeInBytes: 1024 * 1024 * 25,
                        maxSizeDisplayText: "25mb",
                    },
                },
                [Enums.Feature.USER_SUBMITTED_FEEDBACK]: {
                    enabled: true,
                },
                [Enums.Feature.QUICK_EDITS]: {
                    enabled: true,
                },
            } as Record<EnumValue<"Feature">, FeatureConfig>,
        },
        forceReloadJitterMaxMs: 300000,
        githubAppUrl: "FAKE_GITHUB_APP_URL",
        githubOAuthAppClientId: "FAKE_GITHUB_OAUTH_APP_CLIENT_ID",
        graphql: {
            checkExpectedFields: false,
            logLiveViewQueryDiff: false,
        },
        heartbeatIntervalMs: 600000,
        hotspots: {
            config: {
                maxRenderDelayMs: 500,
                usePrerequisites: false,
            },
            byHotspotKey: {
                [Enums.HotspotKey.BLOCKER_THREADS]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [Enums.HotspotKey.THREADED_DISCUSSIONS],
                },
                [Enums.HotspotKey.BOARD_CARD_CHILD_TICKETS]: {
                    enabled: true,
                    allowList: ["New company-wide branding"],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_FILTER],
                },
                [Enums.HotspotKey.BOARD_CARD_OPEN_BLOCKER]: {
                    enabled: true,
                    allowList: [
                        "waiting for additional sample files from CS",
                        "pending decision on scope increase",
                    ],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_CARD_PROGRESS_BAR],
                },
                [Enums.HotspotKey.BOARD_CARD_OPEN_PR]: {
                    enabled: true,
                    allowList: [
                        "Fix unsupported states showing in the signup selector",
                        "Implement redesigned appointment screen",
                    ],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_CARD_OPEN_THREAD],
                },
                [Enums.HotspotKey.BOARD_CARD_OPEN_THREAD]: {
                    enabled: true,
                    allowList: [
                        "Patient appointment history visualization",
                        "Upload photos from camera roll",
                    ],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_CARD_OPEN_BLOCKER],
                },
                [Enums.HotspotKey.BOARD_CARD_PROGRESS_BAR]: {
                    enabled: true,
                    allowList: ["Use new registration API for signup flow", "Share PDF"],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_FILTER],
                },
                [Enums.HotspotKey.BOARD_CARD_STALE_PR]: {
                    enabled: false,
                    allowList: [
                        "Implement new patient onboarding",
                        "Implement redesigned appointment screen",
                    ],
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [Enums.HotspotKey.BOARD_CARD_OPEN_PR],
                },
                [Enums.HotspotKey.BOARD_FILTER]: {
                    enabled: false,
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [],
                },
                [Enums.HotspotKey.CHECKLIST]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [Enums.HotspotKey.SUGGESTED_BRANCH_NAME],
                },
                [Enums.HotspotKey.MY_WORK]: {
                    enabled: true,
                    appPage: Enums.AppPage.BOARD,
                    prerequisites: [],
                },
                [Enums.HotspotKey.OWNER_AND_TEAM]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [],
                },
                [Enums.HotspotKey.SUGGESTED_BRANCH_NAME]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [Enums.HotspotKey.OWNER_AND_TEAM],
                },
                [Enums.HotspotKey.THREADED_DISCUSSIONS]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [Enums.HotspotKey.CHECKLIST],
                },
                [Enums.HotspotKey.WORK_STRUCTURES]: {
                    enabled: true,
                    appPage: Enums.AppPage.TICKET_DETAIL,
                    prerequisites: [Enums.HotspotKey.SUGGESTED_BRANCH_NAME],
                },
            } as Record<EnumValue<"HotspotKey">, HotspotKeyConfig>,
        },
        identityCookie: {
            resetIntervalMs: 24 * 60 * 60 * 1000,
            retryConfig: {
                maxAttempts: 2,
                initialDelayMs: 250,
                backoffFactor: 1.5,
            },
        },
        isMultiOrgEnabled: true,
        logging: {
            level: "info" as StatusType,
            transports: ["http"] as HandlerType[],
        },
        logrocket: {
            enabled: false,
            excludedIdentityIds: [] as string[],
            excludedOrgIds: [] as number[],
            projectId: "<PROJECT_ID>",
        },
        maxInvitesPerBatch: 10,
        minStagesPerBoard: 2, // Minimum number of stages per board
        onboarding: {
            signupPreferencesTtlMs: 259200000,
        },
        preferredDefaultBoardDisplayNames: [] as string[],
        reformatDemoFakeEmailAddresses: false,
        replicache: {
            licenseKey: "l0e9774ab7c324e71ae00849fb2755687",
            pullIntervalMs: 5 * 60 * 1000,
        },
        sentry: {
            dsn: "https://661dc5863524455f9ccfd280045395c2@o469772.ingest.sentry.io/5499738",
            environment: Env.name,
        },
        showLoadingOverlay: true,
        slackClientId: "FAKE_SLACK_CLIENT_ID",
        simultaneousThresholdMs: 5000,
        stripePublishableKey: "FAKE_STRIPE_PUBLISHABLE_KEY",
        ticketSearchIndex: {
            initialLoadDelayMs: 5000,
            reindexBatchSize: 200,
            reindexCheckInterval: 500,
            reindexThrottleMs: 200,
        },
        tracking: {
            cookie: {
                domain: "constructor.dev",
                expirationDays: 365,
                key: "uid",
            },
            enabled: false,
        },
        trello: {
            appKey: "FAKE_TRELLO_APP_KEY",
            tokenPreExpirationInvalidationMs: 1800000,
            tokenExpirationMs: 86400000,
            tokenExpirationString: "1day",
        },
        urls: {
            app: {
                direct: {
                    mac:
                        "https://downloads.flat.app/desktop-app/releases/mac/universal/latest/Flat.dmg",
                    windows:
                        "https://downloads.flat.app/desktop-app/releases/windows/x64/latest/Flat-Setup.exe",
                },
                fallback: "https://flat.app/downloads",
            },
            docs: {
                gettingStartedGuide: "https://docs.flat.app/welcome/getting-started",
                dueDates: "https://docs.flat.app/topics/due-dates",
                github: "https://docs.flat.app/integrations/github",
                main: "https://docs.flat.app",
                sizing: "https://docs.flat.app/topics/sizing",
                workspaceVisibility: "https://docs.flat.app/workspaces/workspace-visibility",
            },
            pricing: "https://flat.app/pricing",
            public: "https://go.flat.app",
            publicForms: "https://go.flat.app/_public_forms",
            signup: "https://go.flat.app/signup",
            status: "https://status.flat.app",
            terms: "https://flat.app/terms",
            video: {
                direct: "https://youtu.be/NW2c9cZVaD0",
                embed: "https://www.youtube.com/embed/NW2c9cZVaD0",
            },
            website: "https://flat.app",
        },
        versionPollIntervalMs: 30000,
    },
    development: {
        auth0: {
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
        },
        discordClientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
        githubAppUrl: process.env.REACT_APP_GITHUB_APP_URL,
        githubOAuthAppClientId: process.env.REACT_APP_GITHUB_OAUTH_APP_CLIENT_ID,
        graphql: {
            checkExpectedFields: true,
            logLiveViewQueryDiff: true,
        },
        logging: {
            level: "debug",
            transports: ["console", "http"],
        },
        features: {
            byFeature: {
                [Enums.Feature.DEBUG_ROUTES]: {
                    enabled: true,
                },
            },
        },
        reformatDemoFakeEmailAddresses: true,
        sentry: {
            dsn: null,
        },
        slackClientId: process.env.REACT_APP_SLACK_CLIENT_ID,
        stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        tracking: {
            cookie: {
                domain: "localhost",
            },
            enabled: true,
        },
        trello: {
            appKey: process.env.REACT_APP_TRELLO_APP_KEY as string,
        },
        urls: {
            public: "http://localhost:8888",
        },
    },
    test: {
        datadog: {
            clientToken: "FAKE_DATADOG_CLIENT_TOKEN",
            forwardErrorsToLogs: false,
        },
        entityPositioning: {
            boundaryFactor: 2,
            default: 5,
            jitter: false,
            maxIncrement: 99999999,
        },
        logging: {
            transports: ["console"],
        },
        replicache: {
            licenseKey: TEST_LICENSE_KEY,
        },
        sentry: {
            dsn: null,
        },
    },
    demo: {
        api: {
            urlHttp: "https://qs-try.flat.app/v1/graphql",
            urlHttpProxy: "",
            urlWs: "wss://qs-try.flat.app/v1/graphql",
        },
        auth: {
            provider: "CONSTRUCTOR_DEMO",
        },
        datadog: {
            forwardErrorsToLogs: true,
        },
        demoUI: {
            enabled: maybeShowDemoUI(),
            tour: {
                exampleTicketRef: "129",
                exampleTicketSlug: "8YN2cb7S",
                exampleBoardDisplayName: "CS",
                exampleBoardSlug: "7yW5Wg",
                exampleStageDisplayName: "In progress",
                exampleUsername: "Alex",
                exampleUserSlug: "5HF4sG",
            },
        },
        features: {
            config: {
                defaultMessage: "Sorry, that feature is not available in this demo.",
            },
            byFeature: {
                [Enums.Feature.ACCOUNT_SETTINGS]: {
                    enabled: false,
                },
                [Enums.Feature.DISCORD_INTEGRATION]: {
                    enabled: false,
                },
                [Enums.Feature.GITHUB_INTEGRATION_FAKE_INSTALLATION]: {
                    enabled: false,
                },
                [Enums.Feature.IDENTITY_COOKIE]: {
                    enabled: false,
                },
                [Enums.Feature.IMPORT_DATA]: {
                    enabled: false,
                    message: "Sorry, importing data is not available in this demo.",
                },
                [Enums.Feature.LIVE_CHAT_SUPPORT]: {
                    enabled: false,
                },
                [Enums.Feature.MANAGE_USERS]: {
                    enabled: false,
                },
                [Enums.Feature.ONBOARDING]: {
                    enabled: false,
                },
                [Enums.Feature.SLACK_INTEGRATION]: {
                    enabled: false,
                },
                [Enums.Feature.UPLOADS]: {
                    enabled: false,
                    message: "Sorry, uploads are not available in this demo.",
                },
                [Enums.Feature.USER_SUBMITTED_FEEDBACK]: {
                    enabled: false,
                },
            },
        },
        githubAppUrl: "https://github.com/apps/flat-app-demo",
        isMultiOrgEnabled: false,
        logging: {
            level: Env.isDeployPreview ? "debug" : "info",
        },
        logrocket: {
            enabled: !Env.isDeployPreview,
            projectId: "m1s1e9/constructor-demo",
        },
        preferredDefaultBoardDisplayNames: ["CS"],
        reformatDemoFakeEmailAddresses: true,
        // Don't display app loading indicator in demo, because we have a special modal that
        // displays immediately on load.
        showLoadingOverlay: false,
        tracking: {
            enabled: true,
        },
        urls: {
            public: "https://try.flat.app",
        },
    },
    staging: {
        api: {
            urlHttp: "https://qs-staging.flat.app/v1/graphql",
            urlHttpProxy: "https://staging.flat.app/api/staging",
            urlWs: "wss://qs-staging.flat.app/v1/graphql",
        },
        auth0: {
            clientId: "DgJcLvXlj36vbAIe0TWHu04oPmgtPHh9",
            domain: "login.flat.app",
        },
        datadog: {
            forwardErrorsToLogs: true,
        },
        discordClientId: "916344642935611442",
        features: {
            byFeature: {
                [Enums.Feature.DEBUG_ROUTES]: {
                    enabled: true,
                },
            },
        },
        graphql: {
            logLiveViewQueryDiff: !!Env.isDeployPreview,
        },
        logging: {
            level: Env.isDeployPreview ? "debug" : "info",
            transports: Env.isDeployPreview ? ["console", "http"] : ["http"],
        },
        sentry: {
            environment: Env.isDeployPreview ? "deployPreview" : Env.name,
        },
        stripePublishableKey:
            "pk_live_51I3OZqK3EnQI2sOJG85F3uF1raHPJ2jpSCnYR0EIkoB4qFmAzZ7To4VIX9BsSToI1F7DEGH0qyJmDx10ItmmbfZ300s21vhk2Q",
        tracking: {
            enabled: true,
        },
        urls: {
            public: "https://staging.flat.app",
        },
    },
    production: {
        api: {
            urlHttp: "https://qs.flat.app/v1/graphql",
            urlHttpProxy: "https://go.flat.app/api/production",
            urlWs: "wss://qs.flat.app/v1/graphql",
        },
        auth0: {
            clientId: "DgJcLvXlj36vbAIe0TWHu04oPmgtPHh9",
            domain: "login.flat.app",
        },
        datadog: {
            forwardErrorsToLogs: true,
        },
        discordClientId: "916344642935611442",
        githubAppUrl: "https://github.com/apps/flat-app-bot",
        githubOAuthAppClientId: "eb746c1ed95a9db1b71a",
        graphql: {
            logLiveViewQueryDiff: !!Env.isDeployPreview,
        },
        logging: {
            level: Env.isDeployPreview ? "debug" : "info",
            transports: Env.isDeployPreview ? ["console", "http"] : ["http"],
        },
        logrocket: {
            enabled: !Env.isDeployPreview,
            excludedIdentityIds: [
                "598fdc24-51a7-4fc0-bc19-00f3621f1476", // Uptime monitoring
                "9fddc652-c83b-4607-bfef-efa6e5bcf5c3", // E2E testing
            ],
            excludedOrgIds: [470, 474, 585],
            projectId: "m1s1e9/constructor-prod",
        },
        sentry: {
            environment: Env.isDeployPreview ? "deployPreview" : Env.name,
        },
        slackClientId: "1175924918756.1481517326513",
        stripePublishableKey:
            "pk_live_51I3OZqK3EnQI2sOJG85F3uF1raHPJ2jpSCnYR0EIkoB4qFmAzZ7To4VIX9BsSToI1F7DEGH0qyJmDx10ItmmbfZ300s21vhk2Q",
        tracking: {
            enabled: true,
        },
        trello: {
            appKey: "e1707e4796eac368994a9fdf679f8f4a",
        },
    },
};

type ConfigType = typeof envConfigs.default;

export const Config = DeepMerge.all([envConfigs.default, envConfigs[Env.name]], {
    arrayMerge: (_, sourceArray) => sourceArray,
}) as ConfigType;
