import React, { useCallback, useEffect, useRef, useState } from "react";

import { Editor as TCoreEditor } from "@tiptap/core";
import ExtensionPlaceholder from "@tiptap/extension-placeholder";
import {
    EditorContent,
    EditorEvents,
    Content as TContent,
    Editor as TEditor,
    useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import AttrAccept from "attr-accept";
import { CommonEnums, ValueOf } from "c9r-common";
import { RichText } from "c9r-rich-text";
import classNames from "classnames";
import * as FileSelector from "file-selector";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Entries } from "type-fest";
import { useThrottledCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

import { Config } from "Config";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AppToaster } from "components/ui/core/AppToaster";
import ExtensionCode from "components/ui/editor/extensions/Code";
import ExtensionCodeBlock from "components/ui/editor/extensions/CodeBlock";
import ExtensionEmoji from "components/ui/editor/extensions/Emoji";
import ExtensionFormatting from "components/ui/editor/extensions/Formatting";
import ExtensionHeading from "components/ui/editor/extensions/Heading";
import ExtensionImage, { pendingImageIdsState } from "components/ui/editor/extensions/Image";
import ExtensionLink, { LinkPopup } from "components/ui/editor/extensions/Link";
import ExtensionListItem from "components/ui/editor/extensions/ListItem";
import ExtensionMention from "components/ui/editor/extensions/Mention";
import ExtensionStrike from "components/ui/editor/extensions/Strike";
import ExtensionTicketReference from "components/ui/editor/extensions/TicketReference";
import ExtensionTrim from "components/ui/editor/extensions/Trim";
import { serializeText } from "components/ui/editor/extensions/helpers/Serializations";
import { useInputPromptDialog } from "dialogs/InputPromptDialog";
import { useBreakpoints } from "lib/Breakpoints";
import { GlobalCssClasses } from "lib/Constants";
import { EnumValue, Enums } from "lib/Enums";
import { isKeyboardTextAreaCancel, isKeyboardTextAreaSubmit } from "lib/Keyboard";
import { Regexes } from "lib/Regexes";
import { useHistory } from "lib/Routing";
import { useUploadAsset } from "lib/Uploads";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TRichTextContentSerializers } from "lib/types/common/richText";
import { isDefined } from "lib/types/guards";

import styles from "./Editor.module.scss";
import { EditorToolbar } from "./EditorToolbar";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment Editor_board on boards {
            id

            authorized_users {
                user {
                    id

                    ...ExtensionMention_user
                }
            }

            ...TicketReference_board
        }
    `),
};

const ACCEPTED_TYPES = "image/*";

const LinkUnfurlTypeInfo: Record<
    EnumValue<"LinkUnfurlType">,
    { displayName: string; getSrc: (href: string) => string | null; regex: RegExp }
> = {
    [Enums.LinkUnfurlType.FIGMA]: {
        displayName: "Figma",
        getSrc: (href: string) => `https://www.figma.com/embed?embed_host=flat&url=${href}`,
        regex: Regexes.LINK_UNFURL_FIGMA,
    },
    [Enums.LinkUnfurlType.LOOM]: {
        displayName: "Loom",
        getSrc: (href: string) => {
            const key = href.match(Regexes.LINK_UNFURL_LOOM)?.groups?.key;

            return key ? `https://www.loom.com/embed/${key}` : null;
        },
        regex: Regexes.LINK_UNFURL_LOOM,
    },
    [Enums.LinkUnfurlType.GOOGLE_DRIVE]: {
        displayName: "Google Drive",
        getSrc: (href: string) => {
            const key = href.match(Regexes.LINK_UNFURL_GOOGLE_DRIVE)?.groups?.key;

            return key ? `https://drive.google.com/file/d/${key}/preview` : null;
        },
        regex: Regexes.LINK_UNFURL_GOOGLE_DRIVE,
    },
} as const;

type LinkUnfurlProps = {
    linkUnfurlType: EnumValue<"LinkUnfurlType">;
    href: string;
};

const LinkUnfurl = React.memo(({ linkUnfurlType, href }: LinkUnfurlProps) => {
    const linkUnfurlTypeInfo = LinkUnfurlTypeInfo[linkUnfurlType];
    const src = linkUnfurlTypeInfo.getSrc(href);

    if (!src) {
        return null;
    }

    // See https://jameshfisher.com/2017/08/30/how-do-i-make-a-full-width-iframe/ for more on
    // this technique to make a full width iframe with fixed aspect ratio.
    return (
        <div className={classNames(styles.linkUnfurl, styles[linkUnfurlType])}>
            <iframe
                title={`Embedded ${linkUnfurlTypeInfo.displayName}`}
                src={src}
                allowFullScreen
            />
        </div>
    );
});

type LinkUnfurlsProps = {
    linkUnfurlTypes: EnumValue<"LinkUnfurlType">[];
    linkedHrefs: string[];
};

function LinkUnfurls({ linkUnfurlTypes, linkedHrefs }: LinkUnfurlsProps) {
    const breakpoints = useBreakpoints();

    // As of January 2024, embedded Figma or Loom iframes sometimes crash Safari on iOS.
    // They're not likely to be useful on mobile, anway, so for simplicity, we just suppress
    // them completely on narrow screens.
    if (breakpoints.mdMax) {
        return null;
    }

    const linkUnfurls: { linkUnfurlType: EnumValue<"LinkUnfurlType">; href: string }[] = [];
    const linkUnfurlHrefs = new Set();

    for (const href of linkedHrefs) {
        for (const [linkUnfurlType, info] of Object.entries(LinkUnfurlTypeInfo) as Entries<
            typeof LinkUnfurlTypeInfo
        >) {
            if (
                linkUnfurlTypes.includes(linkUnfurlType) &&
                href.match(info.regex) &&
                !linkUnfurlHrefs.has(href)
            ) {
                linkUnfurls.push({ linkUnfurlType, href });
                linkUnfurlHrefs.add(href);
                break;
            }
        }
    }

    if (!linkUnfurls.length) {
        return null;
    }

    return (
        <>
            <hr className={styles.linkUnfurlsDivider} />
            {linkUnfurls.map(({ linkUnfurlType, href }) => (
                <LinkUnfurl key={href} linkUnfurlType={linkUnfurlType} href={href} />
            ))}
        </>
    );
}

export const EditorToolbarType = {
    FIXED_PALETTE: "FIXED_PALETTE",
    STICKY_PALETTE: "STICKY_PALETTE",
};

export type EditorProps = {
    autoFocus?: boolean;
    board: FragmentType<typeof fragments.board>;
    className?: string;
    clickBelowProps?: {
        targetClassName?: string;
        targetOnClick: ({ editor }: { editor: TEditor }) => void;
    };
    content: TContent;
    editorContentClassName?: string;
    emoji?: boolean;
    linkUnfurlTypes?: EnumValue<"LinkUnfurlType">[];
    handleAttachmentUpload?: (files: File[]) => void;
    images?: boolean;
    isDirty?: boolean;
    minHeight?: number;
    maxHeight?: number;
    mentions?: boolean;
    onBlur?: () => void;
    onCreate?: (serializers: TRichTextContentSerializers, editor: TCoreEditor) => void;
    onFocus?: () => void;
    onSelectionUpdate?: () => void;
    onUpdate?: () => void;
    onKeyboardCancel?: () => void;
    onKeyboardSubmit?: () => void;
    placeholderText?: string | (() => string);
    readOnly?: boolean;
    ticketReferences?: boolean;
    toolbarClassName?: string;
    toolbarType?: ValueOf<typeof EditorToolbarType>;
    toolbarRightElement?: React.ReactNode;
};

export function Editor({
    autoFocus,
    board: _boardFragment,
    className,
    clickBelowProps,
    content,
    editorContentClassName,
    emoji,
    linkUnfurlTypes = [],
    handleAttachmentUpload,
    images,
    isDirty,
    minHeight,
    maxHeight,
    mentions,
    onBlur = () => undefined,
    onCreate = () => undefined,
    onFocus = () => undefined,
    onSelectionUpdate = () => undefined,
    onUpdate = () => undefined,
    onKeyboardCancel,
    onKeyboardSubmit,
    placeholderText,
    readOnly,
    ticketReferences,
    toolbarClassName,
    toolbarType,
    toolbarRightElement,
}: EditorProps) {
    const isOnline = useRecoilValue(networkStatusState);
    const board = getFragmentData(fragments.board, _boardFragment);
    const inputPromptDialog = useInputPromptDialog();
    const { history } = useHistory();
    const lastDropPosition = useRef<number | null>(null);
    const eventHandlers = useRef<{
        onBlur: () => void;
        onFocus: () => void;
        onKeyboardCancel?: () => void;
        onKeyboardSubmit?: () => void;
        onSelectionUpdate: () => void;
        onUpdate: (params: EditorEvents["update"]) => void;
    } | null>(null);
    const [pendingUpload, setPendingUpload] = useState<{ files: File[]; source: string } | null>(
        null
    );
    const [linkedHrefs, setLinkedHrefs] = useState<string[]>([]);
    const { uploadUserContent } = useUploadAsset();
    const { buildImageUrl } = useUrlBuilders();
    const contentWrapperRef = useRef<HTMLDivElement>(null);

    const parseLinkedHrefs = useCallback(
        ({ editor }: { editor: EditorEvents["update"]["editor"] }) => {
            const contentJson = editor.getJSON();
            const hrefs: string[] = [];

            RichText.visitContentJson({
                contentJson,
                visitor: ({
                    node,
                }: {
                    node: { marks: { type: string; attrs: Record<string, any> }[] };
                }) => {
                    if (!node.marks) {
                        return;
                    }

                    hrefs.push(
                        ...node.marks
                            .filter(mark => mark.type === "link")
                            .map(mark => mark.attrs?.href)
                            .filter(isDefined)
                    );
                },
            });

            setLinkedHrefs(hrefs);
        },
        []
    );

    const throttledParseLinkedHrefs = useThrottledCallback(parseLinkedHrefs, 1000);

    const handleUpdate = useCallback(
        ({ editor }: EditorEvents["update"]) => {
            throttledParseLinkedHrefs.callback({ editor });

            onUpdate?.();
        },
        [throttledParseLinkedHrefs, onUpdate]
    );

    const handleImageUpload = useCallback((files: File[]) => {
        if (files.length) {
            setPendingUpload({ files, source: "UPLOAD" });
        }
    }, []);

    const handleDrop = useCallback(
        (event: DragEvent, { pos }: { pos: number }) => {
            if (!images) {
                return false;
            }

            (async () => {
                lastDropPosition.current = pos;

                const files = (await FileSelector.fromEvent(event)) as File[]; // TODO: This cast may be unsafe;
                const imageFiles = files.filter(file => AttrAccept(file, ACCEPTED_TYPES));

                if (imageFiles.length) {
                    setPendingUpload({ files: imageFiles, source: "DROP" });
                }
            })();

            // If the event had *any* files, consider it handled. If not (such as if the user was
            // dragging a node within the document), consider it *not* handled, thereby allowing
            // drag-and-drop of nodes within the document to work.
            return !!event.dataTransfer?.files.length;
        },
        [images]
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent) => {
            if (!images) {
                return false;
            }

            if (!event.clipboardData?.items) {
                return false;
            }

            const files = Array.from(event.clipboardData.items)
                .filter(i => i.kind === "file")
                .map(i => i.getAsFile())
                .filter(isDefined);

            if (!files.length) {
                return false;
            }

            const imageFiles = files.filter(f => AttrAccept(f, ACCEPTED_TYPES));

            if (imageFiles.length) {
                setPendingUpload({ files: imageFiles, source: "PASTE" });
            }

            return true;
        },
        [images]
    );

    const showPromptForLinkUrl = useCallback(
        ({ editor, selectedText }: { editor: TCoreEditor; selectedText?: string }) => {
            inputPromptDialog.openWithProps({
                inputType: "url",
                promptText: `Enter or paste a URL${
                    selectedText
                        ? ` to link ${
                              selectedText.length < 30
                                  ? `"${selectedText}"`
                                  : `"${selectedText.slice(0, 30)}..."`
                          } to`
                        : ""
                }`,
                onCancel: () => {
                    editor?.chain().focus().run();
                },
                onSubmit: ({ text }) => {
                    editor?.chain().focus().toggleOrCreateLink({ url: text }).run();
                },
                // We return focus to the editor ourselves, above.
                shouldReturnFocusOnClose: false,
            });
        },
        [inputPromptDialog]
    );

    const editor = useEditor(
        {
            content,
            extensions: [
                StarterKit.configure({
                    // Disable starter kit extensions for which we have our own versions.
                    code: false,
                    codeBlock: false,
                    heading: false,
                    listItem: false,
                    strike: false,
                }),

                // The starter kit extensions for which we have our own versions.
                ExtensionCode,
                ExtensionCodeBlock,
                ExtensionHeading,
                ExtensionListItem,
                ExtensionStrike,

                // Other custom extensions.
                ExtensionFormatting,
                // ExtensionTicketReference must be before ExtensionLink because it has a more
                // specific paste rule for Flat ticket URLs.
                ticketReferences ? ExtensionTicketReference.configure({ board }) : null,
                ExtensionLink.configure({ history, showPrompt: showPromptForLinkUrl }),
                ExtensionTrim,
                placeholderText
                    ? ExtensionPlaceholder.configure({
                          placeholder: placeholderText,
                          emptyEditorClass: styles.editorIsEmpty,
                      })
                    : null,
                emoji ? ExtensionEmoji : null,
                images ? ExtensionImage.configure({ inline: true }) : null,
                mentions
                    ? ExtensionMention.configure({
                          users: board.authorized_users.map(au => au.user).filter(isDefined),
                      })
                    : null,
            ].filter(isDefined),
            onBlur,
            onFocus,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onCreate({ editor }) {
                if (autoFocus) {
                    // Without this setImmediate, the editor is focused but the cursor is not
                    // placed correctly. (The same happens when using the autofocus option to
                    // the useEditor hook.)
                    setImmediate(() => {
                        editor.commands.focus("end");
                    });
                }

                const serializers = {
                    getJSON: () => editor.getJSON(),
                    getHTML: () => editor.getHTML(),
                    getText: () => serializeText({ editor }),
                    isPopulated: () => !editor.isEmpty,
                };

                onCreate(serializers, editor);
            },
            editorProps: {
                attributes: {
                    class: classNames(styles.editorContent, GlobalCssClasses.RICH_EDITOR),
                    "data-cy": "editor",
                },
                handleDOMEvents: {
                    keydown(view, event) {
                        if (
                            eventHandlers.current?.onKeyboardCancel &&
                            isKeyboardTextAreaCancel(event)
                        ) {
                            event.preventDefault();
                            eventHandlers.current?.onKeyboardCancel();
                            return true;
                        }

                        if (
                            eventHandlers.current?.onKeyboardSubmit &&
                            isKeyboardTextAreaSubmit(event)
                        ) {
                            event.preventDefault();
                            eventHandlers.current?.onKeyboardSubmit();
                            return true;
                        }

                        return false;
                    },
                },
                handleDrop: (view, _event) => {
                    const event: DragEvent = _event as DragEvent;
                    const coordinates = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                    });

                    return handleDrop(event, { pos: coordinates?.pos ?? 0 });
                },
                handlePaste: (view, event) => {
                    return handlePaste(event);
                },
                // Prevent ctrl-click/ cmd-click node highlight.
                // https://discuss.prosemirror.net/t/prevent-nodeview-selection-on-click/3193
                handleClickOn: (view, pos, node, nodePos, event) =>
                    // @ts-expect-error navigator.userAgentData is experimental and may exist
                    /mac/i.test(navigator.userAgentData?.platform ?? navigator.platform)
                        ? event.metaKey
                        : event.ctrlKey,
            },
        },
        []
    );

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(content);

            // Remove focus after content is set if we're not in edit mode.
            if (!toolbarType) {
                editor.commands.blur();
                // As of April 2023, we discovered that bolding some text and then discarding
                // changes resulted in the text's paragraph being selected. Similarly, bolding
                // some text and then publishing changes resulted in everything in the text's
                // paragragh _before_ the text being selected. (In both cases, the desired
                // operation (i.e. discarding / publishing changes, respectively) was successful.)
                // We were not able to determine the cause of this behaviour, but were able to
                // negate its effect with `document.getSelection()?.removeAllRanges()`.

                // As of November 2023, we discovered that having `document.getSelection()?.removeAllRanges()`
                // here unconditionally was resulting in (among others) the following undesirable outcome:
                //     - User 1 goes to Topic A's detail view, creates a new thread and starts typing in the new
                //       comment editor.
                //     - User 2 edits Topic A's description.
                //     - This effect runs for the description editor on Topic A's detail view.
                //     - For User 1, `document.getSelection()` is the cursor in the new comment editor. As such,
                //       the cursor in the new comment editor disappears, and the new comment editor stops
                //       accepting input.
                // As such, we now execute `document.getSelection()?.removeAllRanges()` iff the editor for which
                // this effect is running contains `document.getSelection()`. In other words, when an editor's
                // content changes, we clear the current selection iff the current selection is in the editor.

                const selection = document.getSelection();

                if (
                    contentWrapperRef.current?.contains(selection?.anchorNode ?? null) &&
                    contentWrapperRef.current?.contains(selection?.focusNode ?? null)
                ) {
                    document.getSelection()?.removeAllRanges();
                }
            }

            throttledParseLinkedHrefs.callback({ editor });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, content, throttledParseLinkedHrefs]);

    useEffect(() => {
        if (editor) {
            if (eventHandlers.current) {
                editor.off("blur", eventHandlers.current.onBlur);
                editor.off("focus", eventHandlers.current.onFocus);
                editor.off("update", eventHandlers.current.onUpdate);
                editor.off("selectionUpdate", eventHandlers.current.onSelectionUpdate);
            }

            eventHandlers.current = {
                onBlur,
                onFocus,
                onKeyboardCancel,
                onKeyboardSubmit,
                onSelectionUpdate,
                onUpdate: handleUpdate,
            };

            editor.on("blur", eventHandlers.current!.onBlur);
            editor.on("focus", eventHandlers.current!.onFocus);
            editor.on("update", eventHandlers.current!.onUpdate);
            editor.on("selectionUpdate", eventHandlers.current!.onSelectionUpdate);
            // onKeyboardCancel omitted because it's handled by handleDOMEvents, above
            // onKeyboardSubmit omitted because it's handled by handleDOMEvents, above
        }
    }, [
        editor,
        onBlur,
        onFocus,
        onKeyboardCancel,
        onKeyboardSubmit,
        onSelectionUpdate,
        handleUpdate,
    ]);

    useEffect(() => {
        if (editor && readOnly) {
            editor.setOptions({ editable: false });
        }
    }, [editor, readOnly]);

    const setPendingImageIds = useSetRecoilState(pendingImageIdsState);

    useEffect(() => {
        (async () => {
            if (!editor || !pendingUpload) {
                return;
            }

            setPendingUpload(null);

            if (!isOnline) {
                AppToaster.danger({
                    message: "Sorry, inserting images is not available offline.",
                });

                return;
            }

            // give each image an id
            const imagesToUpload = pendingUpload.files.map(file => ({ id: uuidv4(), file }));

            let insertPosition = lastDropPosition.current || 0;

            // add image ids to pending image ids and insert nodes
            for (const image of imagesToUpload) {
                setPendingImageIds(prev => [...prev, image.id]);

                const contentNode = {
                    type: "image",
                    attrs: {
                        pendingImageId: image.id,
                    },
                };

                const command = editor.chain().focus();

                if (pendingUpload.source === "DROP") {
                    command.insertContentAt(insertPosition, contentNode);
                    insertPosition += 1;
                } else {
                    command.insertContent(contentNode);
                }

                command.run();
            }

            // initiate uploads
            const uploadDatas = await Promise.all(
                imagesToUpload.map(async image => {
                    const filename = image.file.name;

                    const data = await uploadUserContent({
                        assetType: CommonEnums.UserUploadAssetType.IMAGE,
                        mimeType: image.file.type,
                        blob: image.file,
                        filename,
                        optimistic: true,
                    });

                    return { imageId: image.id, filename, ...data };
                })
            );

            // update nodes and remove image ids from pending image ids
            for (const uploadData of uploadDatas) {
                const { filename, imageId, key, uploadId } = uploadData;

                const updatedNode = {
                    type: "image",
                    attrs: {
                        uploadId,
                        src: `${Config.urls.public}${
                            buildImageUrl({ key: key ?? "[key]", filename }).pathname
                        }`,
                    },
                };

                let position: number | undefined;

                editor.state.doc.descendants((node, pos) => {
                    if (node.attrs.pendingImageId === imageId) {
                        position = pos;
                    }
                });

                if (position) {
                    editor
                        .chain()
                        .deleteRange({ from: position, to: position + 1 })
                        .insertContentAt(position, updatedNode)
                        .run();
                }

                setPendingImageIds(prev => prev.filter(id => id !== imageId));
            }
        })();
    }, [buildImageUrl, editor, isOnline, pendingUpload, setPendingImageIds, uploadUserContent]);

    if (!editor) {
        return (
            <div
                className={classNames(className, styles.editor)}
                style={{
                    ...(minHeight && { minHeight: `${minHeight}px` }),
                    ...(maxHeight && { maxHeight: `${maxHeight}px` }),
                }}
            />
        );
    }

    return (
        <div
            className={classNames(
                className,
                styles.editor,
                readOnly && styles.readOnly,
                minHeight && styles.minHeight,
                maxHeight && styles.maxHeight,
                toolbarType === EditorToolbarType.STICKY_PALETTE &&
                    styles.editorIsShowingStickyToolbar
            )}
            style={
                {
                    ...(minHeight && { "--editor-min-height": `${minHeight}px` }),
                    ...(maxHeight && { "--editor-max-height": `${maxHeight}px` }),
                } as React.CSSProperties
            }
            data-drop-accept={images ? ACCEPTED_TYPES : null}
        >
            <div ref={contentWrapperRef} className={styles.contentWrapper}>
                {toolbarType ? (
                    <EditorToolbar
                        className={classNames(toolbarClassName, styles.toolbar)}
                        editor={editor}
                        handleImageUpload={images ? handleImageUpload : undefined}
                        rightElement={toolbarRightElement}
                        useStickyScrolling={toolbarType === EditorToolbarType.STICKY_PALETTE}
                        visible={!!toolbarType}
                    />
                ) : null}
                <EditorContent className={editorContentClassName} editor={editor} />
            </div>
            {clickBelowProps ? (
                <div
                    className={clickBelowProps.targetClassName}
                    onClick={() => clickBelowProps.targetOnClick({ editor })}
                />
            ) : null}
            <LinkPopup editor={editor} />
            <LinkUnfurls linkUnfurlTypes={linkUnfurlTypes} linkedHrefs={linkedHrefs} />
        </div>
    );
}
