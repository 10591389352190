import React, { useEffect, useState } from "react";

import { Config } from "Config";
import VersioningInfo from "VersioningInfo";
import { AppToaster } from "components/ui/core/AppToaster";
import { Icon } from "components/ui/core/Icon";
import { useInterval } from "lib/Hooks";

type TVersioningInfo = {
    current: number;
    prompt: number;
    force: number;
};

function forceRefresh() {
    window.location.reload();
}

function promptUserToRefresh() {
    const message = "A new version of Flat is available!";

    if (AppToaster.getToasts().some(toast => toast.message === message)) {
        return;
    }

    AppToaster.info({
        icon: <Icon icon="refresh-cw" iconSet="lucide" iconSize={24} />,
        message,
        action: {
            onClick: forceRefresh,
            text: "Refresh",
        },
        timeout: 0,
    });
}

export function useVersioning() {
    const [serverVersioningInfo, setServerVersioningInfo] = useState<TVersioningInfo | null>(null);

    useInterval(() => {
        fetch(`${Config.urls.public}/versioning.json`)
            .then(res => res.json())
            .then((latestServerVersioningInfo: TVersioningInfo) => {
                if (
                    serverVersioningInfo?.current !== latestServerVersioningInfo.current ||
                    serverVersioningInfo?.prompt !== latestServerVersioningInfo.prompt ||
                    serverVersioningInfo?.force !== latestServerVersioningInfo.force
                ) {
                    setServerVersioningInfo(latestServerVersioningInfo);
                }
            })
            .catch(() => {});
    }, Config.versionPollIntervalMs);

    useEffect(() => {
        if (!serverVersioningInfo || serverVersioningInfo.current === VersioningInfo.current) {
            return;
        }

        // The server says a new client version is available. Depending how out-of-date the client
        // is, we should either:
        //   (a) force a refresh
        //   (b) prompt the user to optionally refresh
        //   (c) do nothing
        if (VersioningInfo.current < serverVersioningInfo.force) {
            forceRefresh();
        } else if (VersioningInfo.current < serverVersioningInfo.prompt) {
            promptUserToRefresh();
        } else {
            // The client is out-of-date, but not by so much that we care to do anything.
        }
    }, [serverVersioningInfo]);
}
