import { useCallback } from "react";

import { AppData } from "AppData";
import { AppToaster } from "components/ui/core/AppToaster";
import { useMutations } from "contexts/MutationsContext";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { generatePositionsBetween, generateRandomPos } from "lib/EntityPositioning";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { gql } from "lib/graphql/__generated__";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

export function useArchiveManyTicketsUX() {
    const { bulkMutate } = useMutations();

    type TTicketToArchive = {
        id: string;
        stage_pos: number | null;
    };

    const archiveManyTicketsUX = useCallback(
        async ({ tickets }: { tickets: TTicketToArchive[] }) => {
            await bulkMutate({
                mutations: tickets.map(t => ({ name: "archiveTicket", args: { ticketId: t.id } })),
            });

            const count = tickets.length;

            AppToaster.success({
                icon: null,
                message: `${count} ${count === 1 ? "topic" : "topics"} archived.`,
                action: {
                    onClick: () =>
                        bulkMutate({
                            mutations: tickets.map(t => ({
                                name: "unarchiveTicket",
                                args: {
                                    ticketId: t.id,
                                    stagePos: t.stage_pos ?? generateRandomPos(),
                                },
                            })),
                        }),
                    icon: "undo",
                    text: "Undo",
                },
            });
        },
        [bulkMutate]
    );

    return { archiveManyTicketsUX };
}

export function useMoveTicketsUX() {
    const replicacheGraphQLClient = useReplicacheGraphQLClient();
    const { moveTicketsToStagePositions } = useMutations();
    const { history } = useHistory();
    const { buildBoardUrl } = useUrlBuilders();

    const moveTicketsUX = useCallback(
        async ({
            ticketIds,
            toStageId,
            showToast = true,
        }: {
            ticketIds: string[];
            toStageId: string;
            showToast?: boolean;
        }) => {
            const toStage = await replicacheGraphQLClient.readFragment({
                id: toStageId,
                fragment: gql(/* GraphQL */ `
                    fragment MutationUIMoveTicketStage on stages {
                        id
                        board_id
                        display_name
                        max_ticket_stage_pos
                    }
                `),
            });

            if (!toStage) {
                return;
            }

            const toBoardId = toStage.board_id;

            const toBoard = await replicacheGraphQLClient.readFragment({
                id: toBoardId,
                fragment: gql(/* GraphQL */ `
                    fragment MutationUIMoveTicketBoard on boards {
                        id
                        display_name
                        slug
                    }
                `),
            });

            if (!toBoard) {
                return;
            }

            const toStagePoses = generatePositionsBetween({
                fromPos: toStage.max_ticket_stage_pos,
                count: ticketIds.length,
            });

            await moveTicketsToStagePositions({
                ticketIds,
                toStageId,
                toStagePoses,
                toBoardId,
            });

            if (showToast) {
                [AppData.scrollToTicketSetAt, AppData.scrollToTicketId] = [
                    Date.now(),
                    ticketIds[0],
                ];

                AppToaster.success({
                    icon: null,
                    message: `Moved to the bottom of [${toBoard.display_name}] ${toStage.display_name}.`,
                    action: {
                        onClick: () => {
                            history.push(
                                buildBoardUrl({
                                    boardSlug: toBoard.slug,
                                    vanity: {
                                        boardDisplayName: toBoard.display_name,
                                    },
                                }).pathname
                            );
                        },
                        text: "Go there",
                    },
                });
            }
        },
        [buildBoardUrl, history, moveTicketsToStagePositions, replicacheGraphQLClient]
    );

    return { moveTicketsUX };
}

export function useSetTicketOwnerToCurrentUserUX({
    ticketId,
    ticketRef,
}: {
    ticketId: string;
    ticketRef: string;
}) {
    const currentUser = useCurrentUser();
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const { setTicketOwner } = useMutations();

    const setTicketOwnerToCurrentUserUX = useCallback(async () => {
        await setTicketOwner({ ticketId, userId: currentUser.id });

        AppToaster.success({
            message: shouldShowTicketRefs
                ? `You're now the owner of topic #${ticketRef}`
                : "You're now the owner of this topic.",
        });
    }, [setTicketOwner, ticketId, currentUser.id, shouldShowTicketRefs, ticketRef]);

    return { setTicketOwnerToCurrentUserUX };
}

export function useTrashManyTicketsUX() {
    const { bulkMutate } = useMutations();

    type TTicketToTrash = {
        id: string;
        stage_pos: number | null;
    };

    const trashManyTicketsUX = useCallback(
        async ({ tickets }: { tickets: TTicketToTrash[] }) => {
            await bulkMutate({
                mutations: tickets.map(t => ({ name: "trashTicket", args: { ticketId: t.id } })),
            });

            const count = tickets.length;

            AppToaster.success({
                icon: null,
                message: `${count} ${count === 1 ? "topic" : "topics"} trashed.`,
                action: {
                    onClick: () =>
                        bulkMutate({
                            mutations: tickets.map(t => ({
                                name: "untrashTicket",
                                args: {
                                    ticketId: t.id,
                                    stagePos: t.stage_pos ?? generateRandomPos(),
                                },
                            })),
                        }),
                    icon: "undo",
                    text: "Undo",
                },
            });
        },
        [bulkMutate]
    );

    return { trashManyTicketsUX };
}
