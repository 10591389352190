import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { atom, useSetRecoilState } from "recoil";

import { useCurrentMinimalUser } from "contexts/UserContext";
import { Storage } from "lib/Storage";
import { createCtx } from "lib/react/Context";

type TicketViewsLocal = {
    [ticketId: string]: { lastViewedAt: number } | undefined;
};

export type TicketViewsLocalContextValue = {
    recordTicketViewLocally: (params: { ticketId: string }) => void;
    getWhenLastViewedLocally: (params: { ticketId: string }) => number;
    hasBeenViewedLocally: (params: { ticketId: string }) => boolean;
};

export const ticketViewsLocalState = atom<TicketViewsLocal>({
    key: "ticketViewsLocal",
    default: {},
});

const [useTicketViewsLocal, ContextProvider] = createCtx<TicketViewsLocalContextValue>();

export { useTicketViewsLocal };

export type TicketViewsLocalProviderProps = {
    children: React.ReactNode;
};

export function TicketViewsLocalProvider({ children }: TicketViewsLocalProviderProps) {
    const currentMinimalUser = useCurrentMinimalUser();
    const key = `user.${currentMinimalUser.id}.ticketViews`;
    const cachedTicketViewsLocal = useRef<TicketViewsLocal>(Storage.Local.getItem(key) || {});
    const setTicketViewsLocalState = useSetRecoilState(ticketViewsLocalState);

    const refreshCachedTicketViewsLocal = useCallback(() => {
        cachedTicketViewsLocal.current = (Storage.Local.getItem(key) || {}) as TicketViewsLocal;
        setTicketViewsLocalState({ ...cachedTicketViewsLocal.current });
    }, [key, setTicketViewsLocalState]);

    useEffect(() => {
        const listener = (event: StorageEvent) => {
            if (event.key === key) {
                refreshCachedTicketViewsLocal();
            }
        };

        window.addEventListener("storage", listener);

        return () => {
            window.removeEventListener("storage", listener);
        };
    }, [key, refreshCachedTicketViewsLocal]);

    const getTicketViewsLocal = useCallback(
        ({ bustCache }: { bustCache?: true } = {}) => {
            if (bustCache) {
                refreshCachedTicketViewsLocal();
            }

            return cachedTicketViewsLocal.current;
        },
        [refreshCachedTicketViewsLocal]
    );

    const setTicketViewsLocal = useCallback(
        (ticketViewsLocal: TicketViewsLocal) => {
            Storage.Local.setItem(key, ticketViewsLocal);
            cachedTicketViewsLocal.current = ticketViewsLocal;
            setTicketViewsLocalState({ ...cachedTicketViewsLocal.current });
        },
        [key, setTicketViewsLocalState]
    );

    const recordTicketViewLocally = useCallback(
        ({ ticketId }: { ticketId: string }) => {
            const ticketViewsLocal = getTicketViewsLocal({ bustCache: true });

            ticketViewsLocal[ticketId] = {
                ...ticketViewsLocal[ticketId],
                lastViewedAt: Date.now(),
            };

            setTicketViewsLocal(ticketViewsLocal);
        },
        [getTicketViewsLocal, setTicketViewsLocal]
    );

    const getWhenLastViewedLocally = useCallback(
        ({ ticketId }: { ticketId: string }) => {
            return getTicketViewsLocal()[ticketId]?.lastViewedAt || 0;
        },
        [getTicketViewsLocal]
    );

    const hasBeenViewedLocally = useCallback(
        ({ ticketId }: { ticketId: string }) => {
            return !!getWhenLastViewedLocally({ ticketId });
        },
        [getWhenLastViewedLocally]
    );

    const value = useMemo(
        () => ({
            recordTicketViewLocally,
            getWhenLastViewedLocally,
            hasBeenViewedLocally,
        }),
        [getWhenLastViewedLocally, hasBeenViewedLocally, recordTicketViewLocally]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
