import { isDefined } from "lib/types/guards";

import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const BoardResolvers: TResolvers = {
    query_root: {
        boards: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.boards.findAll())
        ),

        boards_by_pk: buildResolver(async (parent, args, { api }) =>
            api.boards.getById({ id: args.id })
        ),
    },

    boards: {
        attached_users: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { userContextApi }) => {
                const org = userContextApi.getOrg();
                const board = org.all_boards.find(b => b.id === parent.id);

                if (!board) {
                    return [];
                }

                return board.attached_users
                    .map(au => {
                        const user = org.users.find(u => u.id === au.user?.id);

                        return user
                            ? ({
                                  __typename: "boards_users",
                                  user,
                              } as const)
                            : null;
                    })
                    .filter(isDefined);
            })
        ),

        authorized_users: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { userContextApi }) => {
                const org = userContextApi.getOrg();
                const board = org.all_boards.find(b => b.id === parent.id);

                if (!board) {
                    return [];
                }

                return board.authorized_users
                    .map(au => {
                        const user = org.users.find(u => u.id === au.user?.id);

                        return user
                            ? ({
                                  __typename: "auth_boards_access",
                                  user,
                              } as const)
                            : null;
                    })
                    .filter(isDefined);
            })
        ),

        org: buildResolver(async (parent, args, { userContextApi }) => userContextApi.getOrg()),

        stages: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.stages.findByBoardId({ boardId: parent.id })
            )
        ),

        tickets: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.tickets.findByBoardId({ boardId: parent.id })
            )
        ),
    },
};
