import { useCallback } from "react";

import { CommonEnumValue, CommonEnums, DbColumnTypes } from "c9r-common";

import { gql } from "lib/graphql/__generated__";
import { useReplicache } from "lib/replicache/Context";
import { useReplicacheGraphQLClient } from "lib/replicache/graphql/LocalServer";

import { MutationCallbackOptions, useMutation, waitFor } from "../MutationHelpers";

export function useArchiveBoard() {
    const { replicache } = useReplicache();

    const archiveBoard = useCallback(
        async ({ boardId }: { boardId: string }) => {
            await replicache.mutate.archiveBoard({ boardId });
        },
        [replicache]
    );

    return { archiveBoard };
}

export function useCreateBoard() {
    const { replicache } = useReplicache();
    const replicacheGraphQLClient = useReplicacheGraphQLClient();

    const [mutation] = useMutation(
        gql(/* GraphQL */ `
            mutation CreateBoard($displayName: String!, $accessType: String!) {
                create_board(display_name: $displayName, access_type: $accessType) {
                    ok
                    error
                    board_id
                    board {
                        id
                        display_name
                        slug
                    }
                }
            }
        `)
    );

    const createBoard = useCallback(
        async (
            {
                displayName,
                accessType,
            }: { displayName: string; accessType: CommonEnumValue<"BoardAccessType"> },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const mutationResult = await mutation({
                ...mutationOptions,
                variables: {
                    displayName,
                    accessType,
                },
            });

            const boardId = mutationResult.data?.create_board.board_id;

            if (boardId) {
                // Since a new board is *not* created optimistically, we must ensure we've synced it
                // to Replicache before proceeding (so that the app can, say, redirect to the new
                // board). That's what the waitFor below does.
                //
                // The waitFor would be sufficient if we *knew* the app was going to pull. And
                // ordinarily, the app *does* pull after any successful mutation. However,
                // as of December 2023, the demo environment does not have "poke" enabled, which
                // means it would never pull the new board. So, we explicitly trigger a pull here.
                // (There's no harm in doing this in all environments.)
                replicache.pull();

                await waitFor(async () => {
                    const queryResult = await replicacheGraphQLClient.readQuery({
                        query: gql(/* GraphQL */ `
                            query CreateBoardWaitForSync($boardId: uuid!) {
                                board: boards_by_pk(id: $boardId) {
                                    id
                                }
                            }
                        `),
                        variables: {
                            boardId,
                        },
                    });

                    return !!queryResult?.board;
                });
            }

            return mutationResult;
        },
        [mutation, replicache, replicacheGraphQLClient]
    );

    return { createBoard };
}

export function useMakeBoardPrivate() {
    const { replicache } = useReplicache();

    const makeBoardPrivate = useCallback(
        async ({ boardId, userId }: { boardId: string; userId: number }) => {
            await replicache.mutate.updateBoardMembers({ boardId, userIdsToAdd: [userId] });
            await replicache.mutate.updateBoard({
                boardId,
                accessType: CommonEnums.BoardAccessType.PRIVATE,
            });
        },
        [replicache]
    );

    return { makeBoardPrivate };
}

export function useRenameBoard() {
    const { replicache } = useReplicache();

    const renameBoard = useCallback(
        async ({ boardId, displayName }: { boardId: string; displayName: string }) => {
            await replicache.mutate.updateBoard({ boardId, displayName });
        },
        [replicache]
    );

    return { renameBoard };
}

export function useUnarchiveBoard() {
    const { replicache } = useReplicache();

    const unarchiveBoard = useCallback(
        async ({ boardId }: { boardId: string }) => {
            await replicache.mutate.unarchiveBoard({ boardId });
        },
        [replicache]
    );

    return { unarchiveBoard };
}

export function useUpdateBoardSettings() {
    const { replicache } = useReplicache();

    const updateBoardSettings = useCallback(
        async ({
            boardId,
            code,
            dueDates,
            sizes,
        }: {
            boardId: string;
            code?: DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.CODE];
            dueDates?: DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.DUE_DATES];
            sizes?: DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.SIZES];
        }) => {
            await replicache.mutate.updateBoard({
                boardId,
                ...(code && { code }),
                ...(dueDates && { dueDates }),
                ...(sizes && { sizes }),
            });
        },
        [replicache]
    );

    return { updateBoardSettings };
}
