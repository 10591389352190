import { Storage } from "lib/Storage";

const storageKey = "auth.login.hint";

/**
 * Record details useful to hint the login screen which account the user should log in with.
 * Overwrites previously recorded hint.
 */
export const recordLoginHint = ({
    emailAddress,
}: {
    /**
     * The email address used for the hint. This is expected to be the last email
     * address the user logged in with.
     */
    emailAddress: string;
}) => {
    Storage.Local.setItem(storageKey, { emailAddress, asOf: Date.now() });
};

/**
 * Clear previously recorded login hint.
 */
export const clearLoginHint = () => {
    Storage.Local.removeItem(storageKey);
};
