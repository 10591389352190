import { UAParser } from "ua-parser-js";

function parseUserAgent() {
    const parsed = UAParser();
    const os = {
        isAndroid: parsed.os?.name?.toLowerCase().startsWith("android"),
        isIOS: parsed.os?.name?.toLowerCase().startsWith("ios"),
        isMac:
            parsed.os?.name?.toLowerCase().startsWith("mac os") ||
            parsed.os?.name?.toLowerCase().startsWith("macos"),
        isWindows: parsed.os?.name?.toLowerCase().startsWith("windows"),
    };
    const device = {
        // Adapted from https://github.com/faisalman/ua-parser-js/issues/182
        isDesktop:
            !parsed.device.type ||
            !["mobile", "tablet", "smarttv", "wearable", "embedded"].includes(parsed.device.type),
        isMobile: parsed.device.type === "mobile",
        isTablet: parsed.device.type === "tablet",
    };

    return { device, os };
}

export const parsedUserAgent = parseUserAgent();
