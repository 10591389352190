import { FieldReadFunction, TypePolicies } from "@apollo/client";

/**
 * Helper method for building a portion of our Apollo Client InMemoryCache typePolicies object.
 *
 * Some entities in our schema have a foreign key field to another object. Typically that drives
 * a corresponding *association* to the other object. For example, threads have an
 * "assigned_to_user_id" field that drives the "assignee" association.
 *
 * In many cases, it makes our optimistic cache updates easier if we only
 * have to update the foreign key field (assigned_to_user_id), and the corresponding
 * association is updated automatically. That's what the "read" function returned by this helper
 * does.
 *
 * See https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields.
 */
const createAssociationFieldReader = ({
    associationIdFieldName,
    associationTypename,
}: {
    /**  The name of field that contains an id for another entity. */
    associationIdFieldName: string;

    /** The typename of the entity in the association driven by associationIdFieldName. */
    associationTypename: string;
}): FieldReadFunction => {
    return (existing, { readField, toReference }) => {
        const referenceId = readField(associationIdFieldName);

        if (referenceId === null) {
            return null;
        }

        if (!referenceId || (existing && readField("id", existing) === referenceId)) {
            return existing;
        }

        return toReference(`${associationTypename}:${referenceId}`);
    };
};

// Most objects in the API have an id primary key, which Apollo automatically
// recognizes and uses to build the object's key in the normalized cache. But
// some are many-to-many array associations which have a composite primary key
// that we must define manually.
//
// For most of those associations, the association itself has no other data, e.g.,
// tickets_watchers. Therefore, if whenever we use that association we always go
// "through" it to the child (and not use the fields directly), there is no need
// to separately maintain the association object in the normalized cache. (To be
// clear, there would be no harm in doing so either, and we probably should, but
// at the time of this writing in April 2022, we didn't want to expand the scope
// or risk of introducing typePolicies for those cases).
//
// For associations that do have additional data on them, like tickets_owners,
// it's important to either (a) define the keyFields so that object can be
// included in the normalized cache, or (b) include *all* fields *every* time
// the association is used. We decided it was appropriate to do (a).
export const typePolicies: TypePolicies = {
    comments: {
        fields: {
            author: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "author_user_id",
                    associationTypename: "users",
                }),
            },
        },
    },
    tasklists: {
        fields: {
            stage: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "stage_id",
                    associationTypename: "stages",
                }),
            },
            ticket: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "ticket_id",
                    associationTypename: "tickets",
                }),
            },
        },
    },
    tasks: {
        fields: {
            assignee: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "assigned_to_user_id",
                    associationTypename: "users",
                }),
            },
            tasklist: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "tasklist_id",
                    associationTypename: "tasklists",
                }),
            },
        },
    },
    threads: {
        fields: {
            assignee: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "assigned_to_user_id",
                    associationTypename: "users",
                }),
            },
            assigner: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "assigned_by_user_id",
                    associationTypename: "users",
                }),
            },
            blocker_author: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "blocker_author_user_id",
                    associationTypename: "users",
                }),
            },
            resolver: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "resolved_by_user_id",
                    associationTypename: "users",
                }),
            },
            ticket: {
                read: createAssociationFieldReader({
                    associationIdFieldName: "ticket_id",
                    associationTypename: "tickets",
                }),
            },
        },
    },
    ticket_detail_views: {
        keyFields: ["ticket_id", "user_id"],
    },
    tickets_owners: {
        keyFields: ["ticket_id", "user_id"],
    },
    users_tickets_plans: {
        keyFields: ["user_id", "ticket_id"],
    },
};
