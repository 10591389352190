import React from "react";

import classNames from "classnames";

import {
    AbstractRadio,
    AbstractRadioGroup,
    AbstractRadioGroupProps,
    AbstractRadioProps,
} from "components/ui/core/abstract/AbstractRadio";

import styles from "./Radio.module.scss";

export type RadioGroupProps = {
    children: React.ReactNode;
} & Omit<AbstractRadioGroupProps, "children">;

export function RadioGroup({ children, ...abstractRadioGroupProps }: RadioGroupProps) {
    return <AbstractRadioGroup {...abstractRadioGroupProps}>{children}</AbstractRadioGroup>;
}

export type RadioProps = {
    inline?: boolean;
    label?: React.ReactNode;
    labelClassName?: string;
} & Omit<AbstractRadioProps, "children">;

export function Radio({
    inline,
    label,
    labelClassName,

    ...abstractRadioProps
}: RadioProps) {
    return (
        <AbstractRadio
            {...abstractRadioProps}
            className={classNames(
                abstractRadioProps.className,
                styles.radio,
                abstractRadioProps.disabled && styles.disabled,
                inline && styles.inline
            )}
        >
            <span className={styles.indicator}>
                {
                    // As of December 2023, this invisible non-breaking space ensures the height of the icon
                    // element is 1 line-height. Recently, Firefox started supporting the lh CSS unit. Once support
                    // is widespread enough, we could use that instead.
                    "\u200b"
                }
            </span>
            {label ? (
                <span className={classNames(labelClassName, styles.labelText)}>{label}</span>
            ) : null}
        </AbstractRadio>
    );
}
