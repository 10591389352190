import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useMoveTask() {
    const { replicache } = useReplicache();

    const moveTask = useCallback(
        async ({
            taskId,
            fromTasklistId,
            toTasklistId,
            tasklistPos,
        }: {
            taskId: string;
            fromTasklistId: string;
            toTasklistId: string;
            tasklistPos: number;
        }) => {
            await replicache.mutate.moveTask({ taskId, tasklistId: toTasklistId, tasklistPos });
        },
        [replicache]
    );

    return { moveTask };
}
