import React, { useCallback, useEffect, useRef, useState } from "react";

import AttrAccept from "attr-accept";
import {
    CommonEnumValue,
    CommonEnums,
    TAttachment as TAttachmentToPersist,
    TLabel,
    TSizeSpec,
    TicketSizes,
    sortStages,
} from "c9r-common";
import * as FileSelector from "file-selector";
import { useRecoilValue } from "recoil";

import { AppData } from "AppData";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { Attachments, TAttachment as TAttachmentToRender } from "components/shared/Attachments";
import { LabelsPicker } from "components/shared/LabelsPicker";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { TextArea } from "components/ui/core/TextArea";
import { Tooltip } from "components/ui/core/Tooltip";
import { Editor, EditorToolbarType } from "components/ui/editor/Editor";
import { useCurrentUser } from "contexts/UserContext";
import { useBreakpoints } from "lib/Breakpoints";
import { useDragDetection } from "lib/DragAndDrop";
import { moveToLastPosition } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { useAsyncWatcher } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { useRecordTicketView } from "lib/TicketViews";
import { useUploadAsset } from "lib/Uploads";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { NewTicketDialog_boardFragment } from "lib/graphql/__generated__/graphql";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import { useCreateTicket } from "lib/mutations";
import { TRichTextContent, TRichTextContentSerializers } from "lib/types/common/richText";
import { isDefined } from "lib/types/guards";

import styles from "./NewTicketDialog.module.scss";
import {
    DueDateButtonDisplay,
    MembersListDisplay,
    OwnerButtonDisplay,
    SizeButtonDisplay,
    StageButtonDisplay,
    TSizeButtonSizeScheme,
} from "../components/shared/MetadataPickers";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment NewTicketDialog_board on boards {
            id
            access_type
            display_name
            settings

            authorized_users {
                user {
                    id
                    name

                    ...Avatar_user
                    ...MembersListDisplay_user
                    ...OwnerButtonDisplay_user
                    ...UserSelect_user
                }
            }

            stages(where: { deleted_at: { _is_null: true } }) {
                id
                board_pos
                display_name
                min_ticket_stage_pos
                max_ticket_stage_pos
                role
            }

            ...Editor_board
        }
    `),
};

type NewTicketDialogContentProps = {
    handleAttachmentUpload: (files: File[]) => void;
    children: React.ReactNode;
};

function NewTicketDialogContent({ handleAttachmentUpload, children }: NewTicketDialogContentProps) {
    const isOnline = useRecoilValue(networkStatusState);
    const wrapperDivRef = useRef(null);
    const attachmentToastKey = useRef<string | null>(null);

    useDragDetection(wrapperDivRef, event => {
        if (event.type === "dragenter" && !attachmentToastKey.current) {
            (async () => {
                const files = await FileSelector.fromEvent(event);

                // Only show the toast if online and the drag contains files and not, say, a snippet of text.
                if (isOnline && files.length) {
                    attachmentToastKey.current = AppToaster.info({
                        message: "Drop files anywhere to add as an attachment.",
                        icon: <Icon icon="upload" iconSet="c9r" iconSize={24} />,
                        timeout: 0,
                    });
                }
            })();
        } else if (["dragleave", "drop"].includes(event.type) && attachmentToastKey.current) {
            AppToaster.dismiss(attachmentToastKey.current);
            attachmentToastKey.current = null;
        }
    });

    // Per the DOM API, you can't specify a drop event handler (below) without preventing the
    // default behavior of the dragOver event.
    const handleDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    }, []);

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLElement>) => {
            event.preventDefault();
            event.persist();

            (async () => {
                // The idea here is to only upload as attachments files on the event that weren't
                // accepted by descendent elements.
                const files = (await FileSelector.fromEvent(event.nativeEvent)) as File[]; // TODO: This cast may be unsafe;
                const alreadyAcceptedTypes = [] as string[];
                let acceptingElem = (event.target as Element).closest("[data-drop-accept]");

                do {
                    if (
                        acceptingElem &&
                        acceptingElem instanceof HTMLElement &&
                        acceptingElem.dataset?.dropAccept
                    ) {
                        alreadyAcceptedTypes.push(
                            ...acceptingElem.dataset.dropAccept.split(",").filter(Boolean)
                        );
                        acceptingElem = acceptingElem.parentElement
                            ? acceptingElem.parentElement.closest("[data-drop-accept]")
                            : null;
                    }
                } while (
                    acceptingElem &&
                    acceptingElem instanceof HTMLElement &&
                    acceptingElem.dataset?.dropAccept
                );

                const filesToUpload = files.filter(file => !AttrAccept(file, alreadyAcceptedTypes));

                if (filesToUpload.length) {
                    handleAttachmentUpload(filesToUpload);
                }
            })();
        },
        [handleAttachmentUpload]
    );

    return (
        <Dialog.Content ref={wrapperDivRef} onDragOver={handleDragOver} onDrop={handleDrop}>
            {children}
        </Dialog.Content>
    );
}

export type NewTicketDialogProps = {
    board: FragmentType<typeof fragments.board>;
};

const dialogState = dialogStateFamily<NewTicketDialogProps>("NewTicketDialogState");

export function useNewTicketDialog() {
    return useDialogSingleton(dialogState);
}

export function NewTicketDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const dialog = useNewTicketDialog();
    const isOnline = useRecoilValue(networkStatusState);
    const board = getFragmentData(fragments.board, props?.board);
    const currentUser = useCurrentUser();
    const breakpoints = useBreakpoints();
    const prefetchQuery = usePrefetchQuery();
    const { recordEvent } = useInstrumentation();
    const { recordTicketView } = useRecordTicketView();
    const debounceIntervalMs = 1000;

    const getFirstPosStageIdOnBoard = (stages: NewTicketDialog_boardFragment["stages"]) =>
        stages.reduce((currentMinPosStage, stage) =>
            currentMinPosStage && currentMinPosStage.board_pos! < stage.board_pos!
                ? currentMinPosStage
                : stage
        ).id;

    const defaultStageId = board ? getFirstPosStageIdOnBoard(board.stages) : null;

    const [title, setTitle] = useState("");
    const [labels, setLabels] = useState<TLabel[]>([]);
    const [descriptionContent, setDescriptionContent] = useState<TRichTextContent | null>(null);
    const [selectedOwnerId, setSelectedOwnerId] = useState(
        currentUser.org.is_multi_user ? null : currentUser.id
    );
    const [selectedMembersIds, setSelectedMembersIds] = useState<number[]>([]);
    const [selectedStageId, setSelectedStageId] = useState(defaultStageId);
    const [sizeSpec, setSizeSpec] = useState<TSizeButtonSizeScheme | null>(null);
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const [makeCurrentUserWatcher, setMakeCurrentUserWatcher] = useState(true);
    const [isSubmissionAllowed, setIsSubmissionAllowed] = useState(false);
    const [attachments, setAttachments] = useState<(TAttachmentToPersist & TAttachmentToRender)[]>(
        []
    );
    const descriptionSerializers = useRef<TRichTextContentSerializers | null>(null);
    const textAreaRef = useRef(null);
    const submission = useAsyncWatcher();
    const { uploadUserContent } = useUploadAsset();
    const elementName = "new_ticket_dialog";

    const selectedStage = board?.stages.find(s => s.id === selectedStageId);
    const boardTicketSizes = board?.settings[CommonEnums.BoardSettingType.SIZES];

    const { createTicket } = useCreateTicket();

    useEffect(() => {
        setIsSubmissionAllowed(!!(!submission.isInFlight && title.trim()));
    }, [submission, title]);

    const clear = useCallback(() => {
        setTitle("");
        setLabels([]);
        setDescriptionContent(null);
        setSelectedStageId(defaultStageId);
        setSelectedOwnerId(null);
        setSelectedMembersIds([]);
        setMakeCurrentUserWatcher(true);
        setAttachments([]);
        setSizeSpec(null);
        setDueDate(null);
    }, [defaultStageId]);

    useEffect(() => {
        setSelectedStageId(defaultStageId);
    }, [defaultStageId]);

    const setDescriptionSerializers = useCallback((newSerializers: TRichTextContentSerializers) => {
        descriptionSerializers.current = newSerializers;
    }, []);

    // Unlike the other inputs, the editor's "state" is stored directly in the DOM as
    // contenteditable nodes. Therefore, when the dialog closes, grab that state so that
    // we can reinstate it when the dialog opens.
    const onClosing = useCallback(() => {
        setDescriptionContent(descriptionSerializers.current?.getJSON() || null);
    }, []);

    const handleSubmit = async () => {
        if (!board || !selectedStage) {
            return;
        }

        if (title) {
            void recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                elementName,
                dedupeKey: Date.now(),
            });

            // Descriptions should be null if it's empty.
            // This means we don't have descriptions that are just lots of empty paragraphs.
            const isPopulated = descriptionSerializers.current?.isPopulated();
            const descriptionJSON = isPopulated ? descriptionSerializers.current?.getJSON() : null;

            const owners = ([] as ({
                user_id: number;
                type: CommonEnumValue<"TicketOwnerType">;
            } | null)[])
                .concat([
                    selectedOwnerId
                        ? { user_id: selectedOwnerId, type: CommonEnums.TicketOwnerType.OWNER }
                        : null,
                ])
                .concat(
                    selectedMembersIds.length
                        ? selectedMembersIds.map(userId => ({
                              user_id: userId,
                              type: CommonEnums.TicketOwnerType.MEMBER,
                          }))
                        : []
                )
                .filter(isDefined);

            const newMinimalTicket = await createTicket({
                boardId: board.id,
                stageId: selectedStage.id,
                title,
                descriptionJSON: descriptionJSON ?? undefined,
                stagePos: moveToLastPosition({ maxPos: selectedStage.max_ticket_stage_pos }),
                labels: labels.map(l => ({ color: l.color, text: l.text })),
                ownerUserId: owners.find(o => o.type === CommonEnums.TicketOwnerType.OWNER)
                    ?.user_id,
                memberUserIds: owners
                    .filter(o => o.type === CommonEnums.TicketOwnerType.MEMBER)
                    .map(o => o.user_id),
                makeCurrentUserWatcher,
                attachments,
                sizeSpec:
                    boardTicketSizes?.enabled && sizeSpec?.value
                        ? {
                              unit: boardTicketSizes.scheme.unit,
                              value: sizeSpec.value,
                          }
                        : undefined,
                dueDate: dueDate ?? undefined,
            });

            if (!newMinimalTicket) {
                AppToaster.error({
                    message: "Something went wrong creating that topic. Please try again.",
                });

                return;
            }

            const { ref, id: ticketId } = newMinimalTicket;

            AppData.scrollToTicketSetAt = Date.now();
            AppData.scrollToTicketId = ticketId;

            void recordTicketView({ ticketId });

            // Cache details page in case the user immediately navigates to it.
            void prefetchQuery({
                query: Queries.get({ component: "DetailView", name: "component" }),
                variables: {
                    orgId: currentUser.org_id,
                    ref,
                },
            });

            dialog.close();
            clear();
        }
    };

    const handleAttachmentUpload = useCallback(
        (files: File[]) => {
            if (!isOnline) {
                AppToaster.danger({
                    message: "Sorry, uploading attachments is not available offline.",
                });

                return;
            }

            files.forEach(async file => {
                try {
                    const args = {
                        assetType: CommonEnums.UserUploadAssetType.ATTACHMENT,
                        mimeType: file.type,
                        blob: file,
                        filename: file.name,
                        entityType: Enums.AttachmentEntityType.NTD,
                    };

                    const { key } = await uploadUserContent(args);

                    if (!key) {
                        return;
                    }

                    // Here we are spreading the previous state, rather than doing a direct update, to ensure that
                    // the state is updated correctly in the case that multiple files are uploaded at once.
                    setAttachments(prev => [
                        ...prev,
                        { title: file.name, key, url: null, href: null },
                    ]);
                } catch (error) {
                    AppToaster.error({
                        message:
                            "Sorry, something went wrong uploading your file. Please try again.",
                    });
                }
            });
        },
        [isOnline, uploadUserContent]
    );

    if (!board || !selectedStage) {
        return null;
    }

    const authorizedUsers = board.authorized_users.map(au => au.user).filter(isDefined);

    const sizeScheme = boardTicketSizes?.enabled
        ? boardTicketSizes.scheme.values.map((value: TSizeSpec["value"]) => ({
              value,
              text: TicketSizes.format({
                  value,
                  unit: boardTicketSizes.scheme.unit,
              }),
          }))
        : undefined;

    const isCurrentUserOwner = selectedOwnerId === currentUser.id;
    const isCurrentUserMember = selectedMembersIds.includes(currentUser.id);

    const watchToggleDisabled = isCurrentUserOwner || isCurrentUserMember;
    const watchToggleTooltipText = isCurrentUserOwner
        ? "You'll be subscribed to notifications because you're the owner."
        : isCurrentUserMember
        ? "You'll be subscribed to notifications because you're a collaborator."
        : makeCurrentUserWatcher
        ? "Unsubscribe from notifications"
        : "Subscribe to notifications";

    return (
        <Dialog
            isCloseButtonShown={false}
            isOpen={isOpen}
            className={styles.newTicketDialog}
            onClose={dialog.close}
            onClosing={onClosing}
            fillViewport={breakpoints.smMax}
        >
            <NewTicketDialogContent handleAttachmentUpload={handleAttachmentUpload}>
                <Dialog.Header className={styles.header}>
                    <Dialog.CloseButton className={styles.closeButton} onClose={dialog.close} />
                </Dialog.Header>
                <Dialog.Body className={styles.body}>
                    <div>
                        {currentUser.org.is_multi_board && (
                            <div className={styles.boardName}>
                                {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                                    <Icon
                                        icon="lock"
                                        iconSet="c9r"
                                        iconSize={14}
                                        strokeWidthAbsolute={1.5}
                                    />
                                ) : (
                                    <Icon
                                        icon="spaceFrame"
                                        iconSet="c9r"
                                        iconSize={14}
                                        strokeWeight={1}
                                    />
                                )}
                                <div>{board.display_name}</div>
                            </div>
                        )}
                        <TextArea
                            autoFocus
                            className={styles.title}
                            placeholder="Topic title"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            fill
                            autoSize
                            textAreaRef={textAreaRef}
                            onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                            onKeyboardSubmit={submission.watch(handleSubmit)}
                        />
                        <LabelsPicker
                            className={styles.labelsPicker}
                            boardId={board.id}
                            initiallySelectedLabels={labels}
                            onAdd={(_, selectedLabels) => setLabels(selectedLabels)}
                            onRemove={(_, selectedLabels) => setLabels(selectedLabels)}
                            elementName={elementName}
                        />
                        <div className={styles.metadataActions}>
                            <StageButtonDisplay
                                targetClassName={styles.stagePicker}
                                currentStageId={selectedStageId ?? undefined}
                                onSelect={stage => setSelectedStageId(stage!.id)}
                                stages={board.stages.concat().sort(sortStages())}
                                getInstrumentation={stage => ({
                                    elementName: `${elementName}.stage_menu`,
                                    eventData: {
                                        stageId: stage.id,
                                    },
                                })}
                            />
                            <OwnerButtonDisplay
                                ownerId={selectedOwnerId ?? undefined}
                                onSelect={owner => {
                                    setSelectedOwnerId(owner?.id ?? null);
                                    setSelectedMembersIds(prev =>
                                        prev.filter(userId => userId !== owner?.id)
                                    );
                                }}
                                selectableUsers={authorizedUsers
                                    .sort((a, b) =>
                                        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                                    )
                                    .concat(
                                        // @ts-ignore
                                        selectedOwnerId ? [{ id: null, name: "Nobody" }] : []
                                    )}
                                getInstrumentation={user => ({
                                    elementName: `${elementName}.owner_menu`,
                                    eventData: {
                                        userId: user?.id ?? null,
                                    },
                                })}
                            />
                            {(selectedOwnerId || !!selectedMembersIds.length) && (
                                <MembersListDisplay
                                    selectedMembersIds={selectedMembersIds}
                                    onAddMember={user => {
                                        if (user) {
                                            setSelectedMembersIds([...selectedMembersIds, user.id]);
                                        }
                                    }}
                                    onRemoveMember={user =>
                                        setSelectedMembersIds(prev =>
                                            prev.filter(userId => userId !== user.id)
                                        )
                                    }
                                    ticketOwnerId={selectedOwnerId ?? undefined}
                                    users={authorizedUsers}
                                    elementName={elementName}
                                />
                            )}
                            {sizeScheme && (
                                <SizeButtonDisplay
                                    onSelect={size => setSizeSpec(size)}
                                    sizeScheme={([] as {
                                        value: string | number | null;
                                        text: string;
                                    }[])
                                        .concat(sizeScheme)
                                        .concat(
                                            sizeSpec?.value
                                                ? [
                                                      {
                                                          value: null,
                                                          text: "¯\\_(ツ)_/¯",
                                                      },
                                                  ]
                                                : []
                                        )}
                                    sizeSpec={sizeSpec}
                                    getInstrumentation={size => ({
                                        elementName: `${elementName}.size_menu`,
                                        eventData: {
                                            sizeScheme: boardTicketSizes?.scheme?.unit,
                                            value: size?.value,
                                        },
                                    })}
                                />
                            )}
                            {board.settings[CommonEnums.BoardSettingType.DUE_DATES]?.enabled && (
                                <DueDateButtonDisplay
                                    dueDate={dueDate ?? undefined}
                                    getInstrumentation={() => ({
                                        elementName: `${elementName}.due_date_picker`,
                                    })}
                                    placement="top"
                                    onSelect={date => setDueDate(date ?? null)}
                                />
                            )}
                            <div style={{ flex: "1 1 auto" }} />
                            <Tooltip
                                content={watchToggleTooltipText}
                                openOnTargetFocus={false}
                                placement="bottom"
                                small
                                showFast
                                className={styles.tooltipTarget}
                                modifiers={{
                                    offset: {
                                        enabled: true,
                                        options: {
                                            offset: [0, 2],
                                        },
                                    },
                                }}
                            >
                                <BorderAnchorButton
                                    content={
                                        <Icon
                                            icon={
                                                isCurrentUserOwner ||
                                                isCurrentUserMember ||
                                                makeCurrentUserWatcher
                                                    ? "eye"
                                                    : "eye-off"
                                            }
                                            iconSet="lucide"
                                            iconSize={18}
                                        />
                                    }
                                    className={styles.watchToggle}
                                    contentClassName={styles.watchToggleContent}
                                    disabled={watchToggleDisabled}
                                    instrumentation={{
                                        elementName: `${elementName}.watch_toggle`,
                                        eventData: { checked: !makeCurrentUserWatcher },
                                    }}
                                    onClick={() => setMakeCurrentUserWatcher(prev => !prev)}
                                    minimal
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div>
                        <Editor
                            board={board}
                            className={styles.descEditor}
                            toolbarClassName={styles.editorToolbar}
                            content={descriptionContent}
                            editorContentClassName={styles.editorContent}
                            minHeight={104}
                            maxHeight={240}
                            onCreate={setDescriptionSerializers}
                            emoji
                            images
                            ticketReferences
                            placeholderText="Write a description..."
                            toolbarType={EditorToolbarType.FIXED_PALETTE}
                        />
                    </div>
                    <div>
                        <Attachments
                            showButton
                            className={styles.attachments}
                            attachments={attachments}
                            handleAttachmentUpload={handleAttachmentUpload}
                            handleAttachmentDelete={attachment =>
                                setAttachments(prev =>
                                    prev.filter(
                                        a => !(a.url === attachment.url && a.key === attachment.key)
                                    )
                                )
                            }
                            elementName={elementName}
                            entityType={Enums.AttachmentEntityType.NTD}
                        />
                    </div>
                </Dialog.Body>
                <Dialog.Footer className={styles.footer}>
                    <Dialog.FooterActions>
                        <div className={styles.submitBtnWrapper}>
                            <BorderButton
                                data-cy="new-ticket-dialog-submit-btn"
                                className={styles.submitBtn}
                                content="Create"
                                brandCta
                                disabled={!isSubmissionAllowed}
                                loading={submission.isInFlight}
                                onClick={submission.watch(handleSubmit)}
                                debounceIntervalMs={debounceIntervalMs}
                                instrumentation={{
                                    elementName: `${elementName}.submit_button`,
                                }}
                            />
                        </div>
                    </Dialog.FooterActions>
                </Dialog.Footer>
            </NewTicketDialogContent>
        </Dialog>
    );
}
