import React from "react";

import { Tooltip2 as BlueprintTooltip, Classes, Tooltip2Props } from "@blueprintjs/popover2";
import classNames from "classnames";

import { useBreakpoints } from "lib/Breakpoints";
import { CssClasses } from "lib/Constants";

import styles from "./Tooltip.module.scss";

export type TooltipProps = {
    children: React.ReactNode;
    showFast?: boolean;
    showOnMobile?: boolean;
    small?: boolean;
    useContentSizing?: boolean;
    wide?: boolean;
} & Tooltip2Props;

export function Tooltip({
    children,
    showFast,
    showOnMobile,
    small,
    useContentSizing,
    wide,
    ...blueprintTooltipProps
}: TooltipProps) {
    const breakpoints = useBreakpoints();
    const disabled = !!(blueprintTooltipProps.disabled || (!showOnMobile && breakpoints.mdMax));

    return (
        <BlueprintTooltip
            {...blueprintTooltipProps}
            disabled={disabled}
            hoverOpenDelay={showFast ? 100 : 500}
            popoverClassName={classNames(
                styles.tooltip,
                small && styles.small,
                useContentSizing && Classes.POPOVER2_CONTENT_SIZING,
                wide && styles.wide,

                // This is a bit of a hack. If the tooltip is disabled, it's not supposed to
                // display. However, as of October 2021, there's a small bug in blueprint
                // related to hoverOpenDelay. If disabled is false and hoverOpenDelay is set,
                // a timer is created to display the tooltip. When the timer fires, if the
                // tooltip is now disabled, blueprint incorrectly renders the tooltip, briefly,
                // before hiding it. To mitigate the effect of that, we just make the tooltip
                // invisible when it's disabled.
                disabled && CssClasses.INVISIBLE
            )}
        >
            {children}
        </BlueprintTooltip>
    );
}
