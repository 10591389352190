import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const ThreadResolvers: TResolvers = {
    query_root: {
        threads: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) => api.threads.findAll())
        ),

        threads_by_pk: buildResolver(async (parent, args, { api }) =>
            api.threads.getById({ id: args.id })
        ),
    },

    threads: {
        assignee: buildResolver(async (parent, args, { userContextApi }) =>
            parent.assigned_to_user_id
                ? userContextApi.getUserById({ userId: parent.assigned_to_user_id })
                : null
        ),

        assigner: buildResolver(async (parent, args, { userContextApi }) =>
            parent.assigned_by_user_id
                ? userContextApi.getUserById({ userId: parent.assigned_by_user_id })
                : null
        ),

        blocker_author: buildResolver(async (parent, args, { userContextApi }) =>
            parent.blocker_author_user_id
                ? userContextApi.getUserById({ userId: parent.blocker_author_user_id })
                : null
        ),

        blocker_ticket: buildResolver(async (parent, args, { api }) =>
            parent.blocker_ticket_id ? api.tickets.getById({ id: parent.blocker_ticket_id }) : null
        ),

        comments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (await api.comments.findByTicketId({ ticketId: parent.ticket_id })).filter(
                    comment => comment.thread_id === parent.id
                )
            )
        ),

        creator: buildResolver(async (parent, args, { userContextApi }) =>
            parent.created_by_user_id
                ? userContextApi.getUserByIdOrThrow({ userId: parent.created_by_user_id })
                : null
        ),

        resolver: buildResolver(async (parent, args, { userContextApi }) =>
            parent.resolved_by_user_id
                ? userContextApi.getUserById({ userId: parent.resolved_by_user_id })
                : null
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),

        ticket_history_events: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                (
                    await api.ticketHistoryEvents.findByTicketId({ ticketId: parent.ticket_id })
                ).filter(ticketHistoryEvent => ticketHistoryEvent.event_on_thread_id === parent.id)
            )
        ),
    },
};
