import React from "react";

import classNames from "classnames";

import {
    AbstractCheckbox,
    AbstractCheckboxProps,
} from "components/ui/core/abstract/AbstractCheckbox";

import styles from "./Toggle.module.scss";

export type ToggleProps = {
    alignment?: "left" | "right";
    fill?: boolean;
    inline?: boolean;
    label?: React.ReactNode;
    labelClassName?: string;
    large?: boolean;
    long?: boolean;
    loose?: boolean;
} & Omit<AbstractCheckboxProps, "children">;

export function Toggle({
    alignment,
    fill,
    inline,
    label,
    labelClassName,
    large,
    long,
    loose,

    ...abstractCheckboxProps
}: ToggleProps) {
    return (
        <AbstractCheckbox
            {...abstractCheckboxProps}
            className={classNames(
                abstractCheckboxProps.className,
                styles.toggle,
                abstractCheckboxProps.disabled && styles.disabled,
                inline && styles.inline,
                large && styles.large,
                long && styles.long,
                loose && styles.loose,
                alignment === "right" && styles.reverse,
                fill && styles.fill
            )}
        >
            <span className={styles.control} />
            {label ? (
                <span className={classNames(labelClassName, styles.labelText)}>{label}</span>
            ) : null}
        </AbstractCheckbox>
    );
}
