import { useCallback, useEffect, useMemo, useRef } from "react";

import { makeExecutableSchema } from "@graphql-tools/schema";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { CustomError } from "c9r-common";
import { ExecutionResult, graphql, print as printGraphQL } from "graphql";
import { ReadTransaction } from "replicache";

import { useCurrentUser } from "contexts/UserContext";
import { Log } from "lib/Log";
import { ReadApi } from "lib/replicache/Api";
import {
    REPLICACHE_QUERY_LOADING,
    useReplicache,
    useReplicacheSubscription,
} from "lib/replicache/Context";
import { CurrentUserLegacy } from "lib/types/common/currentUser";

import { TContextValue } from "./ContextValue";
import { UserContextApi } from "./UserContextApi";
import { WhereClause } from "./WhereClause";
import { BoardResolvers } from "./resolvers/BoardResolvers";
import { CommentResolvers } from "./resolvers/CommentResolvers";
import { MergeRequestResolvers } from "./resolvers/MergeRequestResolvers";
import { OrgResolvers } from "./resolvers/OrgResolvers";
import { StageResolvers } from "./resolvers/StageResolvers";
import { TaskResolvers } from "./resolvers/TaskResolvers";
import { TasklistResolvers } from "./resolvers/TasklistResolvers";
import { ThreadResolvers } from "./resolvers/ThreadResolvers";
import { TicketAttachmentResolvers } from "./resolvers/TicketAttachmentResolvers";
import { TicketHistoryEventResolvers } from "./resolvers/TicketHistoryEventResolvers";
import { TicketResolvers } from "./resolvers/TicketResolvers";
import { UserResolvers } from "./resolvers/UserResolvers";
import { ResolvedFieldValueCache } from "./resolvers/lib/ResolvedFieldValueCache";
import typeDefs from "./typeDefs";

export const schema = makeExecutableSchema({
    typeDefs,
    resolvers: [
        BoardResolvers,
        CommentResolvers,
        MergeRequestResolvers,
        OrgResolvers,
        StageResolvers,
        TasklistResolvers,
        TaskResolvers,
        ThreadResolvers,
        TicketAttachmentResolvers,
        TicketHistoryEventResolvers,
        TicketResolvers,
        UserResolvers,
    ],
});

async function executeGraphQLQuery<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
>({
    api,
    currentUser,
    rootValue,
    source,
    variables,
}: {
    api: ReadApi;
    currentUser: CurrentUserLegacy;
    rootValue?: any;
    source: string;
    variables?: TVariables;
}) {
    const userContextApi = new UserContextApi({ currentUser });
    const whereClause = new WhereClause({ api });
    const resolvedFieldValueCache = new ResolvedFieldValueCache();
    const contextValue: TContextValue = {
        api,
        resolvedFieldValueCache,
        userContextApi,
        whereClause,
    };

    const result = (await graphql({
        schema,
        source,
        contextValue,
        variableValues: variables,
        rootValue,
    })) as ExecutionResult<TData>;

    if (result.errors) {
        Log.error("Error executing GraphQL via Replicache", {
            source: source.slice(0, 30),
            variables,
            errors: result.errors,
        });
    }

    return result;
}

class ReplicacheGraphQLError extends CustomError<{ errors: any }> {
    constructor({ errors }: { errors: any }) {
        super("Failed to get GraphQL result");
        this.data = { errors };
    }
}

/**
 * Creates a client with methods similar to Apollo Client for reading data directly
 * out of Replicache via a GraphQL query or fragment. This should be used sparingly,
 * primarily as a bridge to facilitate getting off of Apollo and onto Replicache.
 */
export function useReplicacheGraphQLClient() {
    const { replicache } = useReplicache();
    const currentUser = useCurrentUser();
    const currentUserRef = useRef<CurrentUserLegacy>(currentUser);

    useEffect(() => {
        currentUserRef.current = currentUser;
    }, [currentUser]);

    const _readQuery = useCallback(
        async <TData = any, TVariables extends Record<string, any> = Record<string, any>>({
            id,
            source,
            variables,
        }: {
            id?: string;
            source: string;
            variables?: TVariables;
        }) => {
            return replicache.query(async (txn: ReadTransaction) => {
                const api = new ReadApi({ txn });
                const executionResult = await executeGraphQLQuery<TData, TVariables>({
                    api,
                    // By using a ref to the most recent snapshot of currentUser, it means
                    // the query is not reactive to data taken from the currentUser object,
                    // which is essentially just static info like the org name and user
                    // names, so it's acceptable if we don't update immediately when those
                    // change.
                    currentUser: currentUserRef.current,
                    rootValue: id ? api.entries.getById({ id }) ?? undefined : undefined,
                    source,
                    variables,
                });

                return executionResult.data ?? null;
            });
        },
        [replicache]
    );

    const readQuery = useCallback(
        async <TData = any, TVariables extends Record<string, any> = Record<string, any>>({
            query,
            variables,
        }: {
            query: TypedDocumentNode<TData, TVariables>;
            variables?: TVariables;
        }) => _readQuery<TData, TVariables>({ source: printGraphQL(query), variables }),
        [_readQuery]
    );

    // This implementation is hacky and is just meant to help facilitate moving off of
    // Apollo and onto Replicache. To "read" a fragment, we just wrap it in a corresponding
    // query by pk and return the result.
    const readFragment = useCallback(
        async <TData = any>({
            id,
            fragment,
        }: {
            id: string;
            fragment: TypedDocumentNode<TData>;
        }) => {
            const fragmentDefinition = fragment.definitions[0];

            if (fragmentDefinition.kind !== "FragmentDefinition") {
                throw new Error("readFragment can only be called with a single fragment");
            }

            const rootQueryByPk = `${fragmentDefinition.typeCondition.name.value}_by_pk`;
            const source = /* GraphQL */ `
                query($id: uuid!) {
                    ${fragmentDefinition.typeCondition.name.value}_by_pk(id: $id) {
                        ...${fragmentDefinition.name.value}
                    }
                }

                ${printGraphQL(fragment)}
            `;

            const result = await _readQuery<Record<string, TData>>({
                id,
                source,
                variables: { id },
            });

            return result?.[rootQueryByPk] ?? null;
        },
        [_readQuery]
    );

    return useMemo(() => ({ readFragment, readQuery }), [readFragment, readQuery]);
}

export type ReplicacheQueryResult<TData = any> = {
    data: TData | null;
    loading: boolean;
    error?: Error;
};

/**
 * Subscribe to Replicache data via a GraphQL query. Provides an interface similar to Apollo's
 * useSubscribe hook.
 */
export function useReplicacheGraphQLLiveQuery<
    TData = any,
    TVariables extends Record<string, any> = Record<string, any>
>({
    query,
    variables,
}: {
    query: TypedDocumentNode<TData, TVariables>;
    variables?: TVariables;
}): ReplicacheQueryResult<TData> {
    const currentUser = useCurrentUser();
    const source = useMemo(() => printGraphQL(query), [query]);

    const result = useReplicacheSubscription<
        { data: TData | null; errors?: string } | typeof REPLICACHE_QUERY_LOADING
    >(
        async (txn: ReadTransaction) => {
            try {
                const api = new ReadApi({ txn });
                const startTime = Date.now();
                const executionResult = await executeGraphQLQuery<TData, TVariables>({
                    api,
                    currentUser,
                    source,
                    variables,
                });

                Log.debug("Executed GraphQL via Replicache", {
                    source: source.slice(0, 30),
                    elapsedMs: Date.now() - startTime,
                });

                return {
                    data: executionResult.data ?? null,
                    errors: executionResult.errors
                        ? JSON.stringify(executionResult.errors)
                        : undefined,
                };
            } catch (error) {
                return {
                    data: null,
                    errors: JSON.stringify(error),
                };
            }
        },
        REPLICACHE_QUERY_LOADING,
        [source, JSON.stringify(variables), currentUser]
    );

    if (result === REPLICACHE_QUERY_LOADING) {
        return {
            loading: true,
            data: null,
        };
    }

    if (!result || result.errors) {
        return {
            error: new ReplicacheGraphQLError({ errors: result.errors }),
            loading: false,
            data: null,
        };
    }

    return {
        loading: false,
        data: result.data,
    };
}
