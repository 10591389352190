import { PullResponseOK, Replicache, TEST_LICENSE_KEY } from "replicache";

import { Config } from "Config";
import { schemaVersion } from "ReplicacheSchemaVersion";
import { Log } from "lib/Log";
import { getCurrentAccessToken } from "lib/auth/AccessToken";

import { getExpectedReplicacheIDbNamePrefix, getReplicacheDbName } from "./Helpers";
import { buildMutators } from "./Mutators";

/**
 * Cache of Replicache instances by name.
 *
 * As of August 2023, only one instance is active (unpaused) at a time, whichever one
 * corresponds to the org that the person is currently viewing. However, if the person has
 * over the course of their session accessed several orgs, we keep the other Replicache instances
 * around, but in a paused state. That way, if the user navigates back to that org, we don't have
 * to recreate a Replicache instance, which involves reloading everything for that instance from
 * IndexedDB, which can be slow.
 *
 * Replicache doesn't have a native "pause" method. To implement it, our custom puller just
 * replays the previous response's cookie and last mutation ID, and with an empty patch.
 */
const map: Record<
    string,
    {
        isPaused: boolean;
        lastPullResponse: PullResponseOK | null;
        pause: () => void;
        shouldUseFakePull: () => boolean;
        replicache: Replicache<ReturnType<typeof buildMutators>>;
    }
> = {};

/**
 * Create a Replicache instance.
 *
 * When running live in the app, there must be only one Replicache instance running for a given
 * name, or all of the live instances will call pull and potentially clobber each other.
 */
export function createReplicacheInstance({
    userId,
    userOrgId,
    shouldUseFakePull,
    shouldUseTestLicenseKey,
}: {
    userId: number;
    userOrgId: number;
    shouldUseFakePull: () => boolean;
    shouldUseTestLicenseKey?: boolean;
}) {
    const name = getReplicacheDbName({ userId });

    if (map[name]) {
        map[name].shouldUseFakePull = shouldUseFakePull;
        map[name].isPaused = false;

        return { pause: map[name].pause, replicache: map[name].replicache };
    }

    const replicache = new Replicache({
        name,
        licenseKey: shouldUseTestLicenseKey ? TEST_LICENSE_KEY : Config.replicache.licenseKey,
        schemaVersion,
        indexes: {
            entriesById: { jsonPointer: "/id", allowEmpty: true },
        },
        pullInterval: Config.replicache.pullIntervalMs,
        puller: async request => {
            const args = await request.json();

            if (map[name].shouldUseFakePull()) {
                return {
                    httpRequestInfo: {
                        errorMessage: "",
                        httpStatusCode: 200,
                    },
                    response: {
                        cookie: null,
                        lastMutationID: 0,
                        patch: [{ op: "clear" }],
                    },
                };
            }

            if (map[name].isPaused) {
                return {
                    httpRequestInfo: {
                        errorMessage: "",
                        httpStatusCode: 200,
                    },
                    response: {
                        cookie: map[name].lastPullResponse?.cookie ?? null,
                        lastMutationID: map[name].lastPullResponse?.lastMutationID ?? 0,
                        patch: [],
                    },
                };
            }

            const apiResponse = await fetch(Config.api.urlHttp, {
                method: "POST",
                body: JSON.stringify({
                    query: /* GraphQL */ `
                        query ReplicachePull($args: jsonb!) {
                            replicache_pull(args: $args) {
                                ok
                                pull_response
                                pull_response_url
                                error
                            }
                        }
                    `,
                    variables: { args },
                }),
                headers: {
                    authorization: `Bearer ${getCurrentAccessToken()}`,
                },
            });

            // See https://doc.replicache.dev/api/#puller
            if (apiResponse.status !== 200) {
                return {
                    httpRequestInfo: {
                        errorMessage: "Pull endpoint responded with error response code",
                        httpStatusCode: apiResponse.status,
                    },
                };
            }

            const result = (await apiResponse.json())?.data?.replicache_pull;

            if (!result.ok) {
                return {
                    httpRequestInfo: {
                        errorMessage: "Pull endpoint responded with failure flag",
                        httpStatusCode: 500,
                    },
                };
            }

            let pullResponse;

            if (result.pull_response_url) {
                const fetchResponse = await fetch(result.pull_response_url);

                if (fetchResponse.status !== 200) {
                    return {
                        httpRequestInfo: {
                            errorMessage: "S3 responded with error response code",
                            httpStatusCode: fetchResponse.status,
                        },
                    };
                }

                pullResponse = await fetchResponse.json();
            } else {
                pullResponse = result.pull_response;
            }

            map[name].lastPullResponse = pullResponse;

            return {
                httpRequestInfo: {
                    // The return type for puller requires errorMessage to be a string, even if there is no error!
                    errorMessage: "",
                    httpStatusCode: 200,
                },
                response: pullResponse,
            };
        },
        pusher: async req => {
            const args = await req.json();
            const response = await fetch(Config.api.urlHttp, {
                method: "POST",
                body: JSON.stringify({
                    query: /* GraphQL */ `
                        mutation ReplicachePush($args: jsonb!) {
                            replicache_push(args: $args) {
                                ok
                                error
                            }
                        }
                    `,
                    variables: { args },
                }),
                headers: {
                    authorization: `Bearer ${getCurrentAccessToken()}`,
                },
            });

            // See https://doc.replicache.dev/api/#pullerresult
            if (response.status !== 200) {
                return {
                    errorMessage: "Push endpoint responded with error response code",
                    httpStatusCode: response.status,
                };
            }

            const responseJson = await response.json();

            if (!responseJson.data.replicache_push.ok) {
                return {
                    errorMessage: "Push endpoint responded with failure flag",
                    httpStatusCode: 500,
                };
            }

            // https://doc.replicache.dev/api/#pusher
            return {
                // The return type for puller requires errorMessage to be a string, even if there is no error!
                errorMessage: "",
                httpStatusCode: response.status,
            };
        },
        mutators: buildMutators({ currentUserId: userId, currentUserOrgId: userOrgId }),
    });

    map[name] = {
        isPaused: false,
        lastPullResponse: null,
        pause: () => {
            map[name].isPaused = true;
        },
        replicache,
        shouldUseFakePull,
    };

    const actualIdbName = replicache.idbName;
    const expectedIdbName = getExpectedReplicacheIDbNamePrefix({ userId });

    if (!replicache.idbName.startsWith(getExpectedReplicacheIDbNamePrefix({ userId }))) {
        Log.warn("Replicache indexedDB name does not match expectation", {
            actualIdbName,
            expectedIdbName,
        });
    }

    return { pause: map[name].pause, replicache };
}

export type TReplicache = ReturnType<typeof createReplicacheInstance>["replicache"];
