import React from "react";

import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Tooltip } from "components/ui/core/Tooltip";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";

import styles from "./FeedbackButton.module.scss";

export type FeedbackButtonProps = {
    className?: string;
    onClick: () => void;
};

export function FeedbackButton({ className, onClick }: FeedbackButtonProps) {
    const { isFeatureEnabled } = useFeatureFlags();

    if (!isFeatureEnabled({ feature: Enums.Feature.USER_SUBMITTED_FEEDBACK })) {
        return null;
    }

    return (
        <div className={className}>
            <Tooltip
                content="Send feedback"
                openOnTargetFocus={false}
                placement="bottom"
                small
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [0, 8],
                        },
                    },
                }}
            >
                <BorderButton
                    className={styles.feedbackBtn}
                    content={
                        <Icon
                            className={styles.feedbackIcon}
                            icon="send"
                            iconSet="lucide"
                            iconSize={20}
                        />
                    }
                    minimal
                    instrumentation={{ elementName: "navbar.feedback_btn" }}
                    onClick={onClick}
                    textHover
                    themeDark
                />
            </Tooltip>
        </div>
    );
}
