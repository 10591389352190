import { useCallback } from "react";

import { CommonEnums, TrelloClient } from "c9r-common";

import { Config } from "Config";
import { Env } from "Env";
import VersioningInfo from "VersioningInfo";
import { useCurrentUser } from "contexts/UserContext";
import { Log } from "lib/Log";
import { useUploadAsset } from "lib/Uploads";
import { TrelloCredentials } from "lib/import/trello/TrelloConnection";
import { useEnqueueDataImport } from "lib/mutations/org/importData";

const fetchTrello = async ({ trelloBoardId }: { trelloBoardId: string }) => {
    const trelloClient = new TrelloClient({
        appKey: Config.trello.appKey,
        token: TrelloCredentials.getToken(),
    });

    Log.info("Fetching Trello data for import", { trelloBoardId });

    const data: any = { version: "1" };
    const meta = { principalUserSourceExternalId: null };

    data.me = await trelloClient.request({ path: "members/me" });
    meta.principalUserSourceExternalId = data.me.id;

    data.board = await trelloClient.request({ path: `boards/${trelloBoardId}` });
    data.board.labels = await trelloClient.request({
        path: `boards/${trelloBoardId}/labels`,
        query: { limit: 1000 },
    });
    data.board.members = await trelloClient.request({
        path: `boards/${trelloBoardId}/members`,
        query: { fields: ["all"] },
    });
    data.board.lists = await trelloClient.request({
        path: `boards/${trelloBoardId}/lists`,
        query: { filter: "all" },
    });
    data.board.cards = await trelloClient.request({
        path: `boards/${trelloBoardId}/cards/all`,
        query: { attachments: true },
    });
    data.board.checklists = await trelloClient.request({
        path: `boards/${trelloBoardId}/checklists`,
    });
    data.board.actions = [];

    let actionsBefore;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        Log.info("Fetching batch of Trello actions", { trelloBoardId, actionsBefore });

        // Actions are returned in reverse chronological order.
        const actionsBatch: any[] = await trelloClient.request({
            path: `boards/${trelloBoardId}/actions`,
            query: {
                filter: "createCard,commentCard",
                limit: 1000,
                before: actionsBefore,
            },
        });

        if (!actionsBatch.length) {
            break;
        }

        data.board.actions.push(...actionsBatch);
        actionsBefore = actionsBatch[actionsBatch.length - 1].date;
    }

    Log.info("Fetched Trello data for import", {
        trelloBoardId,
        labels: data.board.labels.length,
        lists: data.board.lists.length,
        members: data.board.members.length,
        cards: data.board.cards.length,
        actions: data.board.actions.length,
    });

    return { data, meta };
};

const useImportData = () => {
    const currentUser = useCurrentUser();
    const { uploadInternal } = useUploadAsset();
    const { enqueueDataImport } = useEnqueueDataImport();

    const importData = useCallback(
        async ({
            source,
            fetchParams,
        }: {
            // TODO: These types should be made to handle imports from other sources.
            source: "TRELLO";
            fetchParams: { trelloBoardId: string };
        }) => {
            try {
                const fetcher = { [CommonEnums.ImportSource.TRELLO]: fetchTrello }[source];
                const { data: fetchedData, meta } = await fetcher(fetchParams);
                const mimeType = "application/json";
                const { assetUrl } = await uploadInternal({
                    assetType: CommonEnums.UserUploadAssetType.IMPORT_DATA,
                    mimeType,
                    blob: new Blob([
                        JSON.stringify({
                            version: "1",
                            clientVersion: VersioningInfo.current,
                            clientSha: Env.commitSha,
                            userId: currentUser.id,
                            timestamp: Date.now(),
                            source,
                            meta,
                            data: fetchedData,
                        }),
                    ]),
                });

                if (!assetUrl) {
                    throw new Error("Failed to get assetUrl for import upload");
                }

                const { data } = await enqueueDataImport({
                    source,
                    sourceDataUrl: assetUrl,
                });

                return data?.insert_imports_one?.id;
            } catch (error) {
                Log.error("Failed to enqueue import", { error });

                throw error;
            }
        },
        [currentUser, enqueueDataImport, uploadInternal]
    );

    return { importData };
};

export { useImportData };
