import { useCallback } from "react";

import { useReplicache } from "lib/replicache/Context";

export function useTrashTicket() {
    const { replicache } = useReplicache();

    const trashTicket = useCallback(
        async ({ ticketId }: { ticketId: string }) => {
            await replicache.mutate.trashTicket({ ticketId });
        },
        [replicache]
    );

    return { trashTicket };
}

export function useUntrashTicket() {
    const { replicache } = useReplicache();

    const untrashTicket = useCallback(
        async ({ ticketId, stagePos }: { ticketId: string; stagePos: number }) => {
            await replicache.mutate.untrashTicket({ ticketId, stagePos });
        },
        [replicache]
    );

    return { untrashTicket };
}
