import React, { useEffect } from "react";

import { useRecoilValue } from "recoil";

import { useSetIsViewReady } from "components/loading/Loading";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { useDocumentTitle } from "lib/Hooks";

import styles from "./OfflineView.module.scss";

export type OfflineViewProps = {
    resetError?: () => void;
};

export function OfflineView({ resetError }: OfflineViewProps) {
    const isOnline = useRecoilValue(networkStatusState);
    const setIsViewReady = useSetIsViewReady();

    useDocumentTitle("Offline");

    useEffect(() => {
        setIsViewReady(true);
    }, [setIsViewReady]);

    // If the network connection is restored while this view is being displayed, reload the app so
    // that we can refetch the data and display the original page.
    useEffect(() => {
        if (isOnline) {
            window.location.reload();
        }
    }, [isOnline]);

    return (
        <div className={styles.offlineView}>
            <BrandLogomark className={styles.logomark} height={30} />
            <p className={styles.header}>It looks like you're offline.</p>
            <p className={styles.text}>While offline, some pages may be unavailable.</p>
        </div>
    );
}
