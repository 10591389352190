import { TResolvers } from "./__generated__";
import { buildArrayAssociationResolver, buildResolver } from "./lib/ResolverHelpers";

export const TicketAttachmentResolvers: TResolvers = {
    query_root: {
        ticket_attachments: buildResolver(
            buildArrayAssociationResolver(async (parent, args, { api }) =>
                api.ticketAttachments.findAll()
            )
        ),

        ticket_attachments_by_pk: buildResolver(async (parent, args, { api }) =>
            api.ticketAttachments.getById({ id: args.id })
        ),
    },

    ticket_attachments: {
        attached_by_user: buildResolver(async (parent, args, { userContextApi }) =>
            parent.attached_by_user_id
                ? userContextApi.getUserById({ userId: parent.attached_by_user_id })
                : null
        ),

        ticket: buildResolver(async (parent, args, { api }) =>
            api.tickets.getByIdOrThrow({ id: parent.ticket_id })
        ),
    },
};
