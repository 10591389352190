import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Classes } from "@blueprintjs/core";
import classNames from "classnames";

import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { useCurrentUser } from "contexts/UserContext";
import { DraftEnums, createDraftStorageKey, hasExistingDraft } from "lib/Drafts";
import { useOutsideClick } from "lib/Hooks";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    CommentThread_threadFragment,
    CommentThread_ticketFragment,
} from "lib/graphql/__generated__/graphql";
import {
    useAssignThread,
    useReopenThread,
    useReplyToThread,
    useReplyToThreadAndAssign,
    useReplyToThreadAndResolve,
    useResolveThread,
} from "lib/mutations";
import { TRichTextContentSerializers } from "lib/types/common/richText";
import { isDefined } from "lib/types/guards";

import { Blocker } from "./Blocker";
import { BlockerBadge } from "./BlockerBadge";
import { BlockerEditor } from "./BlockerEditor";
import { Comment } from "./Comment";
import styles from "./CommentThread.module.scss";
import { CommentThreadEvent } from "./CommentThreadEvent";
import { NewCommentEditor } from "./NewCommentEditor";
import { useDetailView } from "../context/DetailViewContext";
import { useDetailViewDiscussion } from "../context/DetailViewDiscussionContext";

const fragments = {
    thread: gql(/* GraphQL */ `
        fragment CommentThread_thread on threads {
            id
            ticket_id
            resolved_at
            assigned_at
            assigned_to_user_id
            blocker_added_at
            blocker_type

            assignee {
                id
                name
            }

            resolver {
                id
                name
            }

            comments {
                id
                posted_at

                author {
                    id
                }

                ...Comment_comment
            }

            ticket_history_events {
                id
                event_type
                event_at

                user {
                    id
                }

                event_on_user {
                    id
                }

                ...CommentThreadEvent_ticket_history_event
            }

            ...Blocker_thread
            ...BlockerEditor_thread
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment CommentThread_ticket on tickets {
            id

            board {
                id

                ...NewCommentEditor_board
            }

            ...Comment_ticket
        }
    `),
};

const useThreadInfo = (thread: CommentThread_threadFragment) => {
    const currentUser = useCurrentUser();

    const isResolved = !!thread.resolved_at;
    const isAssigned = !!thread.assigned_at;
    const isAssignedToCurrentUser = isAssigned && thread.assigned_to_user_id === currentUser.id;
    const hasBlocker = !!thread.blocker_type;
    const hasBlockerHeader = hasBlocker;
    const hasStatusHeader = !hasBlocker && !!(isResolved || isAssigned);
    const hasAnyHeader = hasBlockerHeader || hasStatusHeader;

    const threadInfo = useMemo(
        () => ({
            isResolved,
            isAssigned,
            isAssignedToCurrentUser,
            hasBlocker,
            hasAnyHeader,
            hasStatusHeader,
            hasBlockerHeader,
        }),
        [
            isResolved,
            isAssigned,
            isAssignedToCurrentUser,
            hasBlocker,
            hasAnyHeader,
            hasStatusHeader,
            hasBlockerHeader,
        ]
    );

    return threadInfo;
};

type CommentThreadStatusHeaderProps = {
    handleResolveOrReopenThread: ({ action }: { action: "RESOLVE" | "REOPEN" }) => void;
    thread: CommentThread_threadFragment;
};

function CommentThreadStatusHeader({
    handleResolveOrReopenThread,
    thread,
}: CommentThreadStatusHeaderProps) {
    const { isResolved, isAssignedToCurrentUser, hasStatusHeader } = useThreadInfo(thread);

    if (!hasStatusHeader) {
        return null;
    }

    const headerUser = isResolved ? thread.resolver : thread.assignee;
    const headerUsername = isAssignedToCurrentUser ? "me" : headerUser?.name;
    const headerText = isResolved ? "Resolved by" : "Assigned to";
    const headerDate = isResolved ? thread.resolved_at! : thread.assigned_at!;

    return (
        <>
            <div className={classNames(styles.header, styles.statusHeader)}>
                <span className={styles.headerLeftElement}>
                    <span className={styles.headerTextAndUsername}>
                        {headerText} <span className={styles.headerUsername}>{headerUsername}</span>
                    </span>{" "}
                    <TimeAgo
                        className={classNames(styles.headerTimeAgo, styles.headerWeak)}
                        date={headerDate}
                    />
                </span>
                <div style={{ flex: "1 1 auto" }} />
                {isResolved ? (
                    <TextButton
                        data-cy="thread-reopen-btn"
                        className={styles.threadActionButton}
                        text="Reopen"
                        debounceIntervalMs={1000}
                        instrumentation={{
                            elementName: "thread.reopen_btn",
                            eventData: { threadId: thread.id },
                        }}
                        onClick={() => {
                            handleResolveOrReopenThread({ action: "REOPEN" });
                        }}
                    />
                ) : (
                    <TextButton
                        data-cy="thread-resolve-btn"
                        className={styles.threadActionButton}
                        text="Resolve"
                        debounceIntervalMs={1000}
                        instrumentation={{
                            elementName: "thread.resolve_btn",
                            eventData: { threadId: thread.id },
                        }}
                        onClick={() => {
                            handleResolveOrReopenThread({ action: "RESOLVE" });
                        }}
                    />
                )}
            </div>
            <hr />
        </>
    );
}

type CommentThreadBlockerHeaderProps = {
    handleResolveOrReopenThread: ({ action }: { action: "RESOLVE" | "REOPEN" }) => void;
    onEditBlocker: () => void;
    thread: CommentThread_threadFragment;
};

function CommentThreadBlockerHeader({
    handleResolveOrReopenThread,
    onEditBlocker,
    thread,
}: CommentThreadBlockerHeaderProps) {
    const { isAssigned, isResolved, isAssignedToCurrentUser, hasBlockerHeader } = useThreadInfo(
        thread
    );

    if (!hasBlockerHeader) {
        return null;
    }

    const headerUser = isResolved ? thread.resolver : isAssigned ? thread.assignee : null;
    const headerUsername = isAssignedToCurrentUser ? "me" : headerUser?.name;
    const headerText = isResolved ? "resolved by" : isAssigned ? "assigned to" : null;

    return (
        <div className={classNames(styles.header, styles.blockerHeader)}>
            <div className={styles.blockerHeaderLeftElement}>
                <BlockerBadge className={styles.blockerBadge} />
                {headerText ? (
                    <span className={styles.headerTextAndUsername}>
                        <span className={styles.headerWeak}>{headerText}</span>{" "}
                        <span className={styles.headerUsername}>{headerUsername}</span>
                    </span>
                ) : null}
            </div>
            <div style={{ flex: "1 1 auto" }} />
            <div className={styles.blockerHeaderRightElement}>
                {isResolved ? (
                    <TextButton
                        data-cy="blocker-reopen-btn"
                        className={styles.threadActionButton}
                        text="Reopen"
                        debounceIntervalMs={1000}
                        instrumentation={{
                            elementName: "thread.reopen_btn",
                            eventData: { threadId: thread.id },
                        }}
                        onClick={() => {
                            handleResolveOrReopenThread({ action: "REOPEN" });
                        }}
                    />
                ) : (
                    <TextButton
                        data-cy="blocker-resolve-btn"
                        className={styles.threadActionButton}
                        text="Resolve"
                        debounceIntervalMs={1000}
                        instrumentation={{
                            elementName: "thread.resolve_btn",
                            eventData: { threadId: thread.id },
                        }}
                        onClick={() => {
                            handleResolveOrReopenThread({ action: "RESOLVE" });
                        }}
                    />
                )}
                <MenuPopover
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [8, -4],
                            },
                        },
                    }}
                    content={
                        <Menu>
                            <MenuItem
                                text="Edit blocker"
                                icon={<Icon icon="edit-2" iconSet="lucide" iconSize={18} />}
                                instrumentation={{
                                    elementName: "thread.menu.edit_blocker",
                                    eventData: { threadId: thread.id },
                                }}
                                onClick={onEditBlocker}
                            />
                        </Menu>
                    }
                    placement="bottom-end"
                    targetClassName={styles.menuTarget}
                >
                    <EllipsisButton vertical instrumentation={null} />
                </MenuPopover>
            </div>
        </div>
    );
}

type ThreadItem =
    | {
          type: "COMMENT";
          id: string;
          timestamp: string;
          comment: CommentThread_threadFragment["comments"][number];
          ticket: CommentThread_ticketFragment;
      }
    | {
          type: "EVENT";
          id: string;
          timestamp: string;
          ticketHistoryEvent: CommentThread_threadFragment["ticket_history_events"][number];
      };

export type CommentThreadProps = {
    thread: FragmentType<typeof fragments.thread>;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function CommentThread({
    thread: _threadFragment,
    ticket: _ticketFragment,
}: CommentThreadProps) {
    const thread = getFragmentData(fragments.thread, _threadFragment);
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { blockedByTicketIds } = useDetailView();
    const { urlHashId, lastReopenedThreadId, onThreadReopen } = useDetailViewDiscussion();
    const newCommentDraftStorageKey = createDraftStorageKey({
        entityType: DraftEnums.EntityTypes.TICKET,
        entityId: ticket.id,
        draftEntityType: DraftEnums.DraftEntityTypes.THREAD,
        draftEntityTypeId: thread.id,
    });
    const hasExistingCommentDraft = hasExistingDraft(newCommentDraftStorageKey);
    const [isSelected, setIsSelected] = useState(hasExistingCommentDraft);
    const [isAddingNewComment, setIsAddingNewComment] = useState(hasExistingCommentDraft);
    const [isEditingBlocker, setIsEditingBlocker] = useState(false);
    const [newCommentIsPopulated, setNewCommentIsPopulated] = useState(false);
    const [isEditingComment, setIsEditingComment] = useState(false);
    const threadRef = useRef<HTMLLIElement>(null);

    const { replyToThread } = useReplyToThread();
    const { replyToThreadAndAssign } = useReplyToThreadAndAssign();
    const { replyToThreadAndResolve } = useReplyToThreadAndResolve();

    const { assignThread } = useAssignThread();
    const { resolveThread } = useResolveThread();
    const { reopenThread } = useReopenThread();

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [lastCommentIndex, setLastCommentIndex] = useState(0);
    const [collapsedComments, setCollapsedComments] = useState(0);
    const {
        isResolved,
        isAssignedToCurrentUser,
        hasBlocker,
        hasAnyHeader,
        hasStatusHeader,
        hasBlockerHeader,
    } = useThreadInfo(thread);
    const minNumberOfCollapsibleEvents = 2;
    const minNumberOfCollapsibleComments = 4;
    const hasCommentsToCollapse = collapsedComments > 0;

    useOutsideClick(
        threadRef,
        useCallback(
            event => {
                // useOutsideClick fires if the click is outside of the DOM tree starting with the thread
                // node. We have menus within the thread, which  open via a popover and are therefore
                // not part of the DOM tree starting with the thread node. We try to detect that by
                // checking if the clicked element has an ancestor with a popover-related class.
                if (
                    isSelected &&
                    !isAddingNewComment &&
                    !isEditingBlocker &&
                    event.target instanceof Element &&
                    !event.target.closest(`.${Classes.POPOVER_DISMISS}`)
                ) {
                    setIsSelected(false);
                }
            },
            [isSelected, isAddingNewComment, isEditingBlocker]
        )
    );

    const isInUrlHash = urlHashId.value === thread.id;

    const scrollIntoView =
        isSelected || (lastReopenedThreadId === thread.id && !thread.resolved_at) || isInUrlHash;

    useEffect(() => {
        if (scrollIntoView) {
            // As of December 2021, there seems to be an unfortunate interaction between
            // scrollIntoView and the editor autofocus. Ideally, when a thread is selected,
            // we want the editor to appear and be focused and simultanously to smoothly scroll
            // it into view. But by autofocusing the editor, browser behavior is to *immediately*
            // scroll it into view.
            //
            // As a quick good enough solution, just scroll after a timeout. It doesn't look
            // awful and is better than what we had before.
            setTimeout(() => {
                threadRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
            }, 150);
        }
    }, [scrollIntoView]);

    const commitReply = async ({
        serializers,
        shouldAssign,
        newAssignee,
    }: {
        serializers: React.MutableRefObject<TRichTextContentSerializers | null>;
        shouldAssign: boolean;
        newAssignee?: { id: number } | null;
    }) => {
        const assignedToUserId = newAssignee?.id || null;
        const isPopulated = serializers.current?.isPopulated();

        if (isPopulated && serializers.current) {
            if (shouldAssign && assignedToUserId) {
                await replyToThreadAndAssign({
                    threadId: thread.id,
                    ticketId: thread.ticket_id,
                    serializers: serializers.current,
                    assignedToUserId,
                });
            } else {
                await replyToThread({
                    threadId: thread.id,
                    ticketId: thread.ticket_id,
                    serializers: serializers.current,
                });
            }

            if (thread.resolved_at) {
                onThreadReopen({ threadId: thread.id });
            }
        } else if (shouldAssign && assignedToUserId) {
            await assignThread({
                threadId: thread.id,
                assignedToUserId,
            });

            if (thread.resolved_at) {
                onThreadReopen({ threadId: thread.id });
            }
        }
    };

    const commitReplyAndResolve = async ({
        serializers,
    }: {
        serializers: React.MutableRefObject<TRichTextContentSerializers | null>;
    }) => {
        const isPopulated = serializers.current?.isPopulated();

        if (isPopulated && serializers.current) {
            await replyToThreadAndResolve({
                threadId: thread.id,
                ticketId: thread.ticket_id,
                serializers: serializers.current,
            });
        }
    };

    const handleResolveOrReopenThread = async ({ action }: { action: "RESOLVE" | "REOPEN" }) => {
        if (action === "RESOLVE") {
            await resolveThread({ threadId: thread.id });
        } else {
            await reopenThread({ threadId: thread.id });
        }

        if (action === "REOPEN") {
            onThreadReopen({ threadId: thread.id });
        }
    };

    const threadItems = ([] as ThreadItem[])
        .concat(
            thread.comments.map(comment => ({
                type: "COMMENT",
                id: comment.id,
                timestamp: comment.posted_at,
                comment,
                ticket,
            }))
        )
        .concat(
            thread.ticket_history_events
                .filter(event => ["RESOLVE_THREAD", "REOPEN_THREAD"].includes(event.event_type))
                .map(event => ({
                    type: "EVENT",
                    id: event.id,
                    timestamp: event.event_at,
                    ticketHistoryEvent: event,
                }))
        )
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    const findLastIndex = (
        array: ThreadItem[],
        predicate: (item: ThreadItem, index: number, array: ThreadItem[]) => boolean
    ) => {
        let index = array.length - 1;
        while (index >= 0) {
            if (predicate(array[index], index, array)) {
                return index;
            }
            index -= 1;
        }
        return -1;
    };

    useEffect(() => {
        setLastCommentIndex(findLastIndex(threadItems, item => item.type === "COMMENT"));

        const shouldCollapse = () => {
            if (
                threadItems.filter(item => item.type === "COMMENT").length >=
                minNumberOfCollapsibleComments
            ) {
                return true;
            }

            const firstCommentIndex = threadItems.findIndex(item => item.type === "COMMENT");

            if (firstCommentIndex > -1 && lastCommentIndex > firstCommentIndex) {
                const collapsibleItems = threadItems.slice(firstCommentIndex + 1, lastCommentIndex);
                if (
                    collapsibleItems.find(item => item.type === "COMMENT") &&
                    collapsibleItems.filter(item => item.type === "EVENT").length >=
                        minNumberOfCollapsibleEvents
                ) {
                    return true;
                }
            }
            return false;
        };

        if (shouldCollapse()) {
            setIsCollapsed(true);
            setCollapsedComments(threadItems.filter(item => item.type === "COMMENT").length - 3);
        }

        // We don't wish to add threadItems as a dependency as we don't want
        // the effect to run again if this changes, e.g. if a comment is added.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastCommentIndex]);

    useEffect(() => {
        if (urlHashId.type === "COMMENT") {
            const firstCommentIndex = threadItems.findIndex(item => item.type === "COMMENT");
            const commentIndex = threadItems.findIndex(item => item.id === urlHashId.value);

            setIsCollapsed(!(commentIndex > firstCommentIndex && commentIndex < lastCommentIndex));
        }

        return undefined;

        // We don't wish to add threadItems as a dependency as we don't want
        // the effect to run again if this changes, e.g. if a comment is added.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlHashId, lastCommentIndex]);

    const recentUserIds = thread.comments
        .concat()
        .sort((a, b) => new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime())
        .map(c => c.author?.id)
        .filter(isDefined);

    const threadAssignedToUserId = thread.resolved_at ? null : thread.assigned_to_user_id;

    // Only show comments/events if there is at least one comment. If the thread is just a blocker
    // with no comments, don't show the items no matter how many they are, if they're all just
    // events.
    const renderItems = threadItems.some(item => item.type === "COMMENT")
        ? threadItems
              .map((threadItem, i) => {
                  let renderedItem;

                  switch (threadItem.type) {
                      case "COMMENT": {
                          const { comment } = threadItem;

                          renderedItem = (
                              <Comment
                                  key={comment.id}
                                  className={styles.comment}
                                  comment={comment}
                                  expandFullText={
                                      !isCollapsed || isSelected || i >= lastCommentIndex
                                  }
                                  threadId={thread.id}
                                  ticket={ticket}
                                  onResolve={
                                      !isResolved && !hasAnyHeader && i === 0
                                          ? async () => {
                                                await handleResolveOrReopenThread({
                                                    action: "RESOLVE",
                                                });
                                            }
                                          : undefined
                                  }
                                  onReopen={
                                      isResolved && !hasAnyHeader && i === 0
                                          ? async () => {
                                                await handleResolveOrReopenThread({
                                                    action: "REOPEN",
                                                });
                                            }
                                          : undefined
                                  }
                                  onEditingComment={() => {
                                      if (!newCommentIsPopulated) {
                                          setIsSelected(false);
                                          setIsAddingNewComment(false);
                                      }
                                  }}
                                  isEditingComment={isEditingComment}
                                  isMostRecentComment={i === lastCommentIndex}
                                  recentUserIds={recentUserIds}
                                  setIsEditingComment={setIsEditingComment}
                                  threadAssignedToUserId={threadAssignedToUserId}
                              />
                          );

                          break;
                      }

                      case "EVENT": {
                          renderedItem = (
                              <CommentThreadEvent
                                  ticketHistoryEvent={threadItem.ticketHistoryEvent}
                              />
                          );
                          break;
                      }

                      default:
                  }

                  if (renderedItem) {
                      return {
                          renderItem: (
                              <React.Fragment key={`${threadItem.type}_${threadItem.id}`}>
                                  {i > 0 &&
                                  !(
                                      isCollapsed &&
                                      threadItem.type === "COMMENT" &&
                                      i === lastCommentIndex
                                  ) ? (
                                      <hr />
                                  ) : null}
                                  {renderedItem}
                              </React.Fragment>
                          ),
                          key: `parent_key_${threadItem.type}_${threadItem.id}`,
                      };
                  }
                  return null;
              })
              .filter(isDefined)
        : [];

    return (
        <>
            <li
                data-cy="comment-thread"
                ref={threadRef}
                className={classNames(
                    styles.thread,
                    isSelected || isEditingComment || isEditingBlocker
                        ? styles.selected
                        : styles.unselected,
                    isResolved ? styles.resolved : styles.unresolved,
                    isAssignedToCurrentUser && !isResolved && styles.openAssignedToMe,
                    isInUrlHash && styles.inUrlHash
                )}
                onClick={event => {
                    // If the click was on some other interactive element in the thread,
                    // don't use that as the basis to select the thread.
                    // There's probably a better way to do this.
                    if (
                        (event.target instanceof Element &&
                            (event.target.closest("a") ||
                                event.target.closest("textarea") ||
                                event.target.closest("button") ||
                                event.target.closest(`.${Classes.POPOVER_DISMISS}`))) ||
                        isEditingComment ||
                        isEditingBlocker
                    ) {
                        return;
                    }

                    // If the click resulted from the user selecting some text, then do not select the thread.
                    const selection = document.getSelection();

                    if (selection && !selection.isCollapsed) {
                        return;
                    }

                    setIsSelected(true);
                }}
            >
                {hasStatusHeader ? (
                    <CommentThreadStatusHeader
                        thread={thread}
                        handleResolveOrReopenThread={handleResolveOrReopenThread}
                    />
                ) : null}
                {hasBlockerHeader ? (
                    <CommentThreadBlockerHeader
                        thread={thread}
                        handleResolveOrReopenThread={handleResolveOrReopenThread}
                        onEditBlocker={() => setIsEditingBlocker(true)}
                    />
                ) : null}
                {hasBlocker ? (
                    <>
                        {isEditingBlocker ? (
                            <BlockerEditor
                                thread={thread}
                                excludedTicketIds={[ticket.id, ...blockedByTicketIds]}
                                onCancel={() => setIsEditingBlocker(false)}
                                onSubmit={() => setIsEditingBlocker(false)}
                            />
                        ) : (
                            <Blocker thread={thread} />
                        )}
                        {renderItems.length || isSelected ? (
                            <hr className={styles.sectionBreak} />
                        ) : null}
                    </>
                ) : null}
                <div className={styles.content}>
                    <ul className={styles.items}>
                        {renderItems.map(({ renderItem, key }, i) => {
                            if (i === 0) {
                                return (
                                    <React.Fragment key={key}>
                                        {renderItem}
                                        {isCollapsed && hasCommentsToCollapse ? (
                                            <div className={styles.expandBtnContainer}>
                                                <hr />
                                                <button
                                                    type="button"
                                                    className={styles.expandBtn}
                                                    onClick={() => {
                                                        // Wait a tick before updating isCollapsed so that
                                                        // useOutsideClick recognizes the click event target as
                                                        // a child of the thread before it's removed from the
                                                        // DOM.
                                                        setImmediate(() => setIsCollapsed(false));
                                                    }}
                                                >
                                                    {collapsedComments === 1
                                                        ? "Show 1 more comment"
                                                        : `Show ${collapsedComments} more comments`}
                                                </button>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                );
                            }

                            return !isCollapsed ||
                                // This is a quick way to find the *next-to-last* comment.
                                // threadItems.slice(0, lastCommentIndex) is the slice excluding the
                                // last comment, so findLastIndex on it will find the last comment of
                                // what remains, which is next-to-last overall.
                                //
                                // Why do we need the next-to-last comment? Because the design is to
                                // show the most recent two comments (to give more context to whoever
                                // next replies).
                                i >=
                                    findLastIndex(
                                        threadItems.slice(0, lastCommentIndex),
                                        item => item.type === "COMMENT"
                                    )
                                ? renderItem
                                : null;
                        })}
                    </ul>
                    {isSelected ? (
                        <NewCommentEditor
                            className={styles.newCommentEditor}
                            board={ticket.board}
                            draftStorageKey={newCommentDraftStorageKey}
                            // Put the most recent comment author first, since they're the most
                            // likely to be the user to assign back to.
                            recentUserIds={recentUserIds}
                            threadAssignedToUserId={threadAssignedToUserId}
                            isReply
                            isResolved={isResolved}
                            onStart={() => setIsAddingNewComment(true)}
                            onChange={isPopulated => setNewCommentIsPopulated(isPopulated)}
                            onCancel={() => {
                                setIsSelected(false);
                                setIsAddingNewComment(false);
                                setNewCommentIsPopulated(false);
                            }}
                            onSubmit={async ({
                                serializers,
                                shouldAssign,
                                newAssignee,
                                shouldResolve,
                            }) => {
                                if (shouldResolve) {
                                    await commitReplyAndResolve({
                                        serializers,
                                    });
                                } else {
                                    await commitReply({
                                        serializers,
                                        shouldAssign,
                                        newAssignee,
                                    });
                                }

                                setIsSelected(false);
                                setIsAddingNewComment(false);
                                setNewCommentIsPopulated(false);
                            }}
                        />
                    ) : null}
                </div>
            </li>
        </>
    );
}
