import { useCallback } from "react";

import { MutationFunctionOptions, useMutation } from "@apollo/client";
import { CommonEnumValue } from "c9r-common";
import { useSetRecoilState } from "recoil";

import { Enums } from "lib/Enums";
import { gql } from "lib/graphql/__generated__";

import { BillingSection, billingInfoSectionLastModifiedState } from "./BillingState";

type MutationCallbackOptions = Omit<MutationFunctionOptions, "variables">;

export const useCancelSubscriptionPlan = () => {
    const setBillingInfoLastModifiedAt = useSetRecoilState(billingInfoSectionLastModifiedState);
    const [mutation] = useMutation(useCancelSubscriptionPlan._gql);

    const cancelSubscriptionPlan = useCallback(
        async (
            { cancelAtPeriodEnd }: { cancelAtPeriodEnd: boolean },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const result = await mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    cancelAtPeriodEnd,
                },
            });

            if (result.data?.cancel_subscription_plan.ok) {
                setBillingInfoLastModifiedAt(prev => ({
                    ...prev,
                    [BillingSection.SUBSCRIPTION_PLAN]: new Date(Date.now()),
                }));
            }

            return result;
        },
        [mutation, setBillingInfoLastModifiedAt]
    );

    return { cancelSubscriptionPlan };
};

useCancelSubscriptionPlan._gql = gql(/* GraphQL */ `
    mutation CancelSubscriptionPlan($cancelAtPeriodEnd: Boolean!) {
        cancel_subscription_plan(cancel_at_period_end: $cancelAtPeriodEnd) {
            ok
            error
        }
    }
`);

export const useChangeBillingDetails = () => {
    const setBillingInfoLastModifiedAt = useSetRecoilState(billingInfoSectionLastModifiedState);
    const [mutation] = useMutation(useChangeBillingDetails._gql);

    const changeBillingDetails = useCallback(
        async (
            { billingEmailAddress }: { billingEmailAddress: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const result = await mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    billingEmailAddress,
                },
            });

            if (result.data?.change_billing_details.ok) {
                setBillingInfoLastModifiedAt(prev => ({
                    ...prev,
                    [BillingSection.CONTACT_INFO]: new Date(Date.now()),
                }));
            }

            return result;
        },
        [mutation, setBillingInfoLastModifiedAt]
    );

    return { changeBillingDetails };
};

useChangeBillingDetails._gql = gql(/* GraphQL */ `
    mutation ChangeBillingDetails($billingEmailAddress: String!) {
        change_billing_details(billing_email_address: $billingEmailAddress) {
            ok
            error
        }
    }
`);

export const useChangePaymentMethod = () => {
    const setBillingInfoLastModifiedAt = useSetRecoilState(billingInfoSectionLastModifiedState);
    const [mutation] = useMutation(useChangePaymentMethod._gql);

    const changePaymentMethod = useCallback(
        async (
            { paymentMethodId }: { paymentMethodId: string },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const result = await mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    paymentMethodId,
                },
            });

            if (result.data?.change_payment_method.ok) {
                setBillingInfoLastModifiedAt(prev => ({
                    ...prev,
                    [BillingSection.PAYMENT_METHOD]: new Date(Date.now()),
                }));
            }

            return result;
        },
        [mutation, setBillingInfoLastModifiedAt]
    );

    return { changePaymentMethod };
};

useChangePaymentMethod._gql = gql(/* GraphQL */ `
    mutation ChangePaymentMethod($paymentMethodId: String!) {
        change_payment_method(payment_method_id: $paymentMethodId) {
            ok
            error
        }
    }
`);

export const useChangeSubscriptionPlan = () => {
    const setBillingInfoLastModifiedAt = useSetRecoilState(billingInfoSectionLastModifiedState);
    const [mutation] = useMutation(useChangeSubscriptionPlan._gql);

    const changeSubscriptionPlan = useCallback(
        async (
            {
                planKey,
                billingInterval,
            }: {
                planKey: CommonEnumValue<"SubscriptionPlanKey">;
                billingInterval: CommonEnumValue<"SubscriptionBillingInterval">;
            },
            mutationOptions?: MutationCallbackOptions
        ) => {
            const result = await mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    planKey,
                    billingInterval,
                },
            });

            if (result.data?.change_subscription_plan.ok) {
                setBillingInfoLastModifiedAt(prev => ({
                    ...prev,
                    [BillingSection.SUBSCRIPTION_PLAN]: new Date(Date.now()),
                }));
            }

            return result;
        },
        [mutation, setBillingInfoLastModifiedAt]
    );

    return { changeSubscriptionPlan };
};

useChangeSubscriptionPlan._gql = gql(/* GraphQL */ `
    mutation ChangeSubscriptionPlan($planKey: String!, $billingInterval: String!) {
        change_subscription_plan(plan_key: $planKey, billing_interval: $billingInterval) {
            ok
            error
        }
    }
`);

export const useDeleteOrg = () => {
    const [mutation] = useMutation(useDeleteOrg._gql);

    const deleteOrg = useCallback(
        async (_?: unknown, mutationOptions?: MutationCallbackOptions) => {
            const result = await mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
            });

            return result;
        },
        [mutation]
    );

    return { deleteOrg };
};

useDeleteOrg._gql = gql(/* GraphQL */ `
    mutation DeleteOrg {
        delete_org {
            ok
            error
        }
    }
`);

export const usePreparePaymentMethod = () => {
    const [mutation] = useMutation(usePreparePaymentMethod._gql);

    const preparePaymentMethod = useCallback(
        async (_?: unknown, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
            });
        },
        [mutation]
    );

    return { preparePaymentMethod };
};

usePreparePaymentMethod._gql = gql(/* GraphQL */ `
    mutation PreparePaymentMethod {
        prepare_payment_method {
            ok
            error
            client_secret
        }
    }
`);

export const useViewBillingPortal = () => {
    const [mutation] = useMutation(useViewBillingPortal._gql);

    const viewBillingPortal = useCallback(
        async ({ returnUrl }: { returnUrl: string }, mutationOptions?: MutationCallbackOptions) => {
            return mutation({
                ...mutationOptions,
                context: {
                    ...mutationOptions?.context,
                    apiRoleType: Enums.ApiRoleType.USER_ORG_ADMIN,
                },
                variables: {
                    returnUrl,
                },
            });
        },
        [mutation]
    );

    return { viewBillingPortal };
};

useViewBillingPortal._gql = gql(/* GraphQL */ `
    mutation ViewBillingPortal($returnUrl: String!) {
        view_billing_portal(return_url: $returnUrl) {
            ok
            error
            url
        }
    }
`);
