import { useCallback, useEffect } from "react";

import { ValueOf } from "c9r-common";
import { atom, useSetRecoilState } from "recoil";
import { useThrottledCallback } from "use-debounce";

import { useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";

// Consts
export const DefaultDraftId = "new";
const DraftKeySuffix = "draft";
const DraftKeyDivider = ".";

// Enums
const EntityTypes = {
    TICKET: "ticket",
} as const;

const DraftEntityTypes = {
    DESCRIPTION: "description",
    BLOCKER: "blocker",
    THREAD: "thread",
} as const;

export const DraftEnums = {
    EntityTypes,
    DraftEntityTypes,
};

// Helpers
export const createDraftStorageKey = ({
    entityType,
    entityId,
    draftEntityType,
    draftEntityTypeId,
}: {
    entityType: ValueOf<typeof EntityTypes>;
    entityId: string | number;
    draftEntityType: ValueOf<typeof DraftEntityTypes>;
    draftEntityTypeId?: string | number;
}) =>
    [entityType, entityId, draftEntityType, draftEntityTypeId, DraftKeySuffix]
        .filter(Boolean)
        .join(DraftKeyDivider);

export const hasExistingDraft = (localStorageKey: string) =>
    !!Storage.Local.getItem(localStorageKey);

// Hook for managing a single draft item.
export const useDraft = ({ localStorageKey }: { localStorageKey: string }) => {
    const loadDraft = useCallback(() => Storage.Local.getItem(localStorageKey) ?? null, [
        localStorageKey,
    ]);

    const saveDraft = useCallback(
        ({ draft }: { draft: object }) => Storage.Local.setItem(localStorageKey, draft),
        [localStorageKey]
    );

    const throttledSaveDraft = useThrottledCallback(saveDraft, 1000);

    const discardDraft = useCallback(() => {
        throttledSaveDraft.cancel();
        Storage.Local.removeItem(localStorageKey);
    }, [throttledSaveDraft, localStorageKey]);

    return { loadDraft, saveDraft, discardDraft, throttledSaveDraft };
};

type TDraftsPerTicket = Record<
    string,
    {
        description?: boolean;
        blocker?: typeof DefaultDraftId;
        threads?: (string | number | typeof DefaultDraftId)[];
    }
>;

// Global recoil state with the current active drafts per ticket.
// The source of truth of local storage, and the hook below ensures that we listen for local storage
// changes and resync as required.
export const draftsPerTicketState = atom({
    key: "DraftsPerTicket",
    default: {} as TDraftsPerTicket,
});

export const useDraftsSynchronization = () => {
    const location = useLocation();
    const setDraftsPerTicket = useSetRecoilState(draftsPerTicketState);

    const syncStateFromStorage = useCallback(() => {
        setDraftsPerTicket(
            // Here we are finding the draft keys per ticket in local storage
            // and then creating a hash map of the draft types per ticket ID.
            // Threads are currently the only draft type which can have multiple drafts per ticket,
            // so this item will have a pluralized key i.e. "threads" with an array of IDs.
            Object.keys(localStorage)
                .filter(k => k.includes(DraftKeySuffix))
                .map(k => k.split(DraftKeyDivider).filter(el => el !== DraftKeySuffix))
                .filter(
                    k =>
                        k[0] === EntityTypes.TICKET &&
                        Object.values(DraftEntityTypes).includes(
                            k[2] as ValueOf<typeof DraftEntityTypes>
                        )
                )
                .map(k => k.slice(1, 4))
                .reduce((acc: TDraftsPerTicket, [ticketId, draftEntityType, draftEntityTypeId]) => {
                    if (draftEntityType === DraftEntityTypes.THREAD) {
                        const threadsKey = `${draftEntityType}s` as const;
                        const threadId = parseInt(draftEntityTypeId) || draftEntityTypeId;

                        acc[ticketId] = {
                            ...acc[ticketId],
                            [threadsKey]: [...(acc[ticketId]?.[threadsKey] ?? []), threadId],
                        };

                        return acc;
                    }

                    acc[ticketId] = {
                        ...acc[ticketId],
                        [draftEntityType]: draftEntityTypeId ?? true,
                    };

                    return acc;
                }, {})
        );
    }, [setDraftsPerTicket]);

    // Initial sync on mount
    useEffect(() => {
        syncStateFromStorage();
    }, [syncStateFromStorage]);

    // Sync if the page changes
    // As of February 2023, this was a quick fix for a bug where the state was not in sync.
    // If a user creates or clears a draft, the saveDraft function above makes the change in
    // local storage. However, that doesn't trigger a storage event or a sync. We could simply
    // invoke a sync within saveDraft, or after a delay, but that might be too frequent.
    // Rather than do that, the draft state only matters *outside* the details page, and drafts
    // can only be created *in* the details page, so changing the location pathname is a sufficient
    // trigger for our purposes.
    useEffect(() => {
        syncStateFromStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    // Sync if local storage changes by another tab.
    // (The "storage" event only fires if a change is made by *another* browser tab.)
    useEffect(() => {
        const maybeSyncStateFromStorage = (e: StorageEvent) => {
            if (
                e?.key?.includes(DraftKeySuffix) ||
                (e?.key === null && e?.storageArea?.length === 0)
            ) {
                syncStateFromStorage();
            }
        };

        window.addEventListener("storage", maybeSyncStateFromStorage);

        return () => {
            window.removeEventListener("storage", maybeSyncStateFromStorage);
        };
    }, [syncStateFromStorage]);
};
