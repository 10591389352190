import { useEffect } from "react";

import {
    DocumentNode,
    OperationVariables,
    SubscriptionHookOptions,
    TypedDocumentNode,
    useSubscription,
} from "@apollo/client";

import { Config } from "Config";
import { useResettingState } from "lib/Hooks";
import { Log } from "lib/Log";

/**
 * Thin wrapper around Apollo useSubscription that automatically restarts the subscription
 * after an error.
 *
 * As of July 2023, if any error is transmitted over the subscription, Apollo closes the
 * subscription and no new messages are received, even if the error was non-fatal (such as a
 * transient backend issue). See https://github.com/apollographql/apollo-client/issues/9109.
 */
export function useAutoRestartSubscription<TData = any, TVariables = OperationVariables>(
    subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: Omit<SubscriptionHookOptions<TData, TVariables>, "shouldResubscribe" | "skip">
) {
    const [skip, setSkip] = useResettingState(false, Config.apollo.subscriptionRestartDelayMs);

    // Setting shouldResubscribe causes Apollo to make a new subscription if any of the inputs
    // change. So, to resubscribe after an error, we toggle the skip input off and then back on.
    const result = useSubscription(subscription, { ...options, shouldResubscribe: true, skip });

    useEffect(() => {
        if (result.error && !skip) {
            setSkip(true);
            Log.info("Set subscription to restart after error", { error: result.error });
        }
    }, [skip, setSkip, result.error]);

    return result;
}
