import { useEffect } from "react";

import { atom, useSetRecoilState } from "recoil";

export const networkStatusState = atom<boolean | null>({
    key: "NetworkStatus",
    default: null,
});

export function useNetworkStatusMonitor() {
    const setNetworkStatusState = useSetRecoilState(networkStatusState);

    useEffect(() => {
        setNetworkStatusState(navigator.onLine);

        const listener = () => setNetworkStatusState(navigator.onLine);

        window.addEventListener("online", listener);
        window.addEventListener("offline", listener);

        return () => {
            window.removeEventListener("online", listener);
            window.removeEventListener("offline", listener);
        };
    }, [setNetworkStatusState]);
}
