import { useCallback } from "react";

import { CommonEnumValue, CommonEnums, Errors } from "c9r-common";

import { Config } from "Config";
import { EnumValue, Enums } from "lib/Enums";
import { Hex } from "lib/Hex";
import { useHistory } from "lib/Routing";
import { Storage } from "lib/Storage";

type OAuthStateDataDiscord = {
    authType: CommonEnumValue<"DiscordAuthType">;
    redirectUri: string;
};

type OAuthStateDataSlack = {
    redirectUri: string;
};

type AuthorizationParams =
    | {
          key: "DISCORD";
          path: string;
          authType: CommonEnumValue<"DiscordAuthType">;
      }
    | {
          key: "GITHUB";
          path: string;
          authType?: undefined;
      }
    | {
          key: "SLACK";
          path: string;
          authType?: undefined;
      }
    | {
          key: "TRELLO";
          path: string;
          authType?: undefined;
      };

/**
 * Helper methods for OAuth flows.
 */
export class OAuth {
    private static getStorageKey() {
        return "oauth.state";
    }

    static encodeState({
        key,
        path,
        data,
    }: {
        key: EnumValue<"OAuthKey">;
        path: string;
        data?: unknown;
    }) {
        return Hex.encode(
            JSON.stringify({
                key,
                appType: Config.appType,
                path,
                data,
                nonce: Math.random(),
            })
        );
    }

    static decodeState<TData>({ state }: { state: string }) {
        return JSON.parse(Hex.decode(state)) as {
            key: EnumValue<"OAuthKey">;
            appType: EnumValue<"AppType">;
            path: string;
            data: TData;
        };
    }

    static buildAuthorizeUrl({ key, authType, path }: AuthorizationParams) {
        switch (key) {
            case Enums.OAuthKey.DISCORD: {
                const authorizeUrl = new URL("https://discord.com/api/oauth2/authorize");
                const redirectUri = `${window.location.origin}/oauth/redirect`;
                const stateData: OAuthStateDataDiscord = { authType, redirectUri };
                const state = OAuth.buildAndSaveState({
                    key: Enums.OAuthKey.DISCORD,
                    data: stateData,
                    path,
                });

                if (authType === CommonEnums.DiscordAuthType.INTEGRATION) {
                    // URL to authorize that our bot be added to the end user's Discord server.
                    authorizeUrl.search = new URLSearchParams({
                        client_id: Config.discordClientId,
                        redirect_uri: redirectUri,
                        response_type: "code",
                        scope: "bot",
                        state,
                    }).toString();

                    return authorizeUrl.href;
                }

                if (authType === CommonEnums.DiscordAuthType.NOTIFICATION) {
                    // URL to request that permissions to send messages be granted to our bot.
                    // 2048 is the integer value indicating that we want access granted for the SEND_MESSAGES permission.
                    // See: https://discord.com/developers/docs/topics/permissions
                    authorizeUrl.search = new URLSearchParams({
                        client_id: Config.discordClientId,
                        permissions: "2048",
                        redirect_uri: redirectUri,
                        response_type: "code",
                        scope: "identify",
                        state,
                    }).toString();

                    return authorizeUrl.href;
                }

                throw new Errors.UnexpectedCaseError({ authType });
            }

            case Enums.OAuthKey.GITHUB: {
                const authorizeUrl = new URL("https://github.com/login/oauth/authorize");
                const redirectUri = `${window.location.origin}/oauth/redirect`;
                const state = OAuth.buildAndSaveState({
                    key: Enums.OAuthKey.GITHUB,
                    data: { redirectUri },
                    path,
                });

                authorizeUrl.search = new URLSearchParams({
                    client_id: Config.githubOAuthAppClientId,
                    state,
                }).toString();

                return authorizeUrl.href;
            }

            case Enums.OAuthKey.SLACK: {
                const authorizeUrl = new URL("https://slack.com/oauth/v2/authorize");
                const redirectUri = `${window.location.origin}/oauth/redirect`;
                const stateData: OAuthStateDataSlack = { redirectUri };
                const state = OAuth.buildAndSaveState({
                    key: Enums.OAuthKey.SLACK,
                    data: stateData,
                    path,
                });

                authorizeUrl.search = new URLSearchParams({
                    client_id: Config.slackClientId,
                    scope: "chat:write,im:history,im:write,links:read,links:write",
                    redirect_uri: redirectUri,
                    state,
                }).toString();

                return authorizeUrl.href;
            }

            case Enums.OAuthKey.TRELLO: {
                const authorizeUrl = new URL("https://trello.com/1/authorize");
                const state = OAuth.buildAndSaveState({ key: Enums.OAuthKey.TRELLO, path });
                const redirectUri = `${window.location.origin}/oauth/redirect?state=${state}`;

                // As of June 2023, if the name is just "Flat", the consent screen shows "Unknown application".
                // It's not clear why. Other names work fine, including "Flat.app", so we use that instead.
                authorizeUrl.search = new URLSearchParams({
                    callback_method: "fragment",
                    expiration: Config.trello.tokenExpirationString,
                    key: Config.trello.appKey,
                    name: "Flat.app",
                    redirect_uri: redirectUri,
                    response_type: "token",
                    scope: "read",
                }).toString();

                return authorizeUrl.href;
            }

            default:
                throw new Errors.UnexpectedCaseError({ key });
        }
    }

    /**
     * Build an encoded "state" key passed in OAuth URLs and save it for later validation.
     */
    static buildAndSaveState({
        key,
        data,
        path,
    }: {
        /** A key describing this particular OAuth flow. */
        key: EnumValue<"OAuthKey">;

        /**
         * Path within the app to send the user back to after the OAuth redirect.
         */
        path: string;

        /** Optional arbitrary data to include in the state. */
        data?: unknown;
    }) {
        const state = OAuth.encodeState({ key, path, data });

        Storage.Local.setItem(OAuth.getStorageKey(), state);

        return state;
    }

    /**
     * Validate and decode a "state" provided by an OAuth redirect and previously encoded and saved
     * with buildAndSaveState.
     */
    static validateAndDecodeState<TData>({ state }: { state: string | null }) {
        const savedState = Storage.Local.getItem(OAuth.getStorageKey());

        if (!state || savedState !== state) {
            return null;
        }

        const decodedState = OAuth.decodeState<TData>({ state });

        return {
            key: decodedState.key,
            path: decodedState.path,
            data: decodedState.data,
        };
    }

    static validateAndDecodeStateWithKey<TData>({
        key,
        state,
    }: {
        key: EnumValue<"OAuthKey">;
        state: string | null;
    }) {
        const decodedState = OAuth.validateAndDecodeState<TData>({ state });

        if (!decodedState || decodedState.key !== key) {
            return null;
        }

        return decodedState;
    }
}

export function useOAuth() {
    const { history } = useHistory();

    const buildAuthorizeUrl = useCallback(
        (params: Omit<AuthorizationParams, "path">) => {
            const path = `${history.location.pathname}${history.location.search}${history.location.hash}`;

            // The TypeScript compiler isn't smart enough to persist the discriminated union in
            // AuthorizationParams when it's spread into a new object.
            // @ts-expect-error
            return OAuth.buildAuthorizeUrl({ ...params, path });
        },
        [history]
    );

    return { buildAuthorizeUrl };
}
