import { Extension } from "@tiptap/core";

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        formattingExtension: {
            clearFormatting: () => ReturnType;
        };
    }
}

export default Extension.create({
    name: "formatting",

    addCommands() {
        return {
            clearFormatting: () => ({ chain }) => {
                return chain().clearNodes().unsetAllMarks().run();
            },
        };
    },
});
