import React, { useCallback, useState } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { SupportMailto } from "components/shared/SupportMailto";
import { PunctuatedList } from "components/ui/common/PunctuatedList";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { TextArea } from "components/ui/core/TextArea";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { parseEmailAddresses } from "lib/Helpers";
import { useAsyncWatcher } from "lib/Hooks";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { useInviteUser } from "lib/mutations";

import styles from "./InvitePeopleDialog.module.scss";

const dialogState = dialogStateFamily("InvitePeopleDialog");

export function useInvitePeopleDialog() {
    return useDialogSingleton(dialogState);
}

function DomainSignupInfo({ closeDialogAndClearInput }: { closeDialogAndClearInput: () => void }) {
    const currentUser = useCurrentUser();
    const { buildSettingsUrl } = useUrlBuilders();

    const domains = currentUser.org.domains.map(({ domain }) => domain) ?? [];
    const isDomainSignupEligible = !!domains.length;
    const isDomainSignupEnabled = currentUser.org.is_domain_signup_enabled;

    if (!isDomainSignupEligible) {
        return null;
    }

    return (
        <div className={styles.domainSignupInfo}>
            <Icon icon="info" iconSet="lucide" />
            <div className={styles.domainSignupInfoText}>
                {isDomainSignupEnabled ? (
                    <>
                        <p>
                            Anyone having an{" "}
                            <PunctuatedList joinText="or">
                                {domains.map(domain => (
                                    <span key="domain">@{domain}</span>
                                ))}
                            </PunctuatedList>{" "}
                            email address can join your organization without needing an invitation.
                        </p>
                        <p>
                            To change this, visit{" "}
                            <Link
                                to={`${buildSettingsUrl().pathname}/people`}
                                onClick={closeDialogAndClearInput}
                            >
                                Settings | People
                            </Link>
                            .
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            You can enable self-signup for anyone having an{" "}
                            <PunctuatedList joinText="or">
                                {domains.map(domain => (
                                    <span key="domain">@{domain}</span>
                                ))}
                            </PunctuatedList>{" "}
                            email address in{" "}
                            <Link
                                to={`${buildSettingsUrl().pathname}/people`}
                                onClick={closeDialogAndClearInput}
                            >
                                Settings | People
                            </Link>
                            .
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}

export function InvitePeopleDialog() {
    const { gateFeature } = useFeatureFlags();
    const dialog = useInvitePeopleDialog();
    const { isOpen } = useRecoilValue(dialogState);
    const [emailAddressesText, setEmailAddressesText] = useState("");
    const submission = useAsyncWatcher();
    const isOnline = useRecoilValue(networkStatusState);
    const currentUser = useCurrentUser();
    const { inviteUser } = useInviteUser();

    const closeDialogAndClearInput = useCallback(() => {
        dialog.close();
        setEmailAddressesText("");
    }, [dialog]);

    const handleSubmit = useCallback(async () => {
        if (!gateFeature({ feature: Enums.Feature.MANAGE_USERS })) {
            return;
        }

        if (!isOnline) {
            AppToaster.danger({
                message: "Sorry, inviting people is not available offline.",
            });

            return;
        }

        const emailAddresses = Array.from(new Set(parseEmailAddresses(emailAddressesText))).filter(
            emailAddress => emailAddress !== currentUser.identity.email_address
        );

        if (!emailAddresses.length) {
            AppToaster.danger({
                message:
                    "Sorry, please enter valid email addresses separated by commas or newlines.",
            });

            return;
        }

        if (emailAddresses.length > Config.maxInvitesPerBatch) {
            AppToaster.danger({
                message: `Sorry, please invite no more than ${Config.maxInvitesPerBatch} people at a time.`,
            });

            return;
        }

        const invitations = await Promise.all(
            emailAddresses.map(emailAddress =>
                inviteUser({ emailAddress }).then(result => ({ emailAddress, result }))
            )
        );

        if (
            invitations.some(invitation => {
                return (
                    (invitation.result.data?.invite_user.error as any)?.code ===
                    CommonEnums.ActionErrorCode.ACTION_DUPLICATE_EMAIL_ADDRESS
                );
            })
        ) {
            AppToaster.danger({
                message: (
                    <span>
                        Some of your teammates already have an account with Flat.{" "}
                        <SupportMailto text="Contact us" /> about adding them to your Flat team.
                    </span>
                ),
            });

            return;
        }

        if (invitations.some(invitation => !invitation.result.data?.invite_user.ok)) {
            AppToaster.error({
                message:
                    "Sorry, something went wrong sending your invitations. We're looking into it.",
            });

            return;
        }

        AppToaster.info({
            icon: <Icon icon="check" iconSet="lucide" iconSize={24} />,
            message: "Your invitations are on the way.",
        });

        closeDialogAndClearInput();
    }, [
        closeDialogAndClearInput,
        currentUser.identity.email_address,
        emailAddressesText,
        gateFeature,
        inviteUser,
        isOnline,
    ]);

    const didEnterValidEmailAddress = !!parseEmailAddresses(emailAddressesText).length;

    return (
        <Dialog className={styles.dialog} isOpen={isOpen} onClose={closeDialogAndClearInput}>
            <Dialog.Header className={styles.header}>
                <Dialog.CloseButton
                    className={styles.closeButton}
                    onClose={closeDialogAndClearInput}
                />
            </Dialog.Header>
            <Dialog.Body>
                <h6>Invite teammates</h6>
                <p className={styles.inviteExplanation}>
                    Separate multiple email addresses by commas or newlines.
                </p>
                <TextArea
                    autoFocus
                    autoSize
                    className={styles.emailAddressesTextArea}
                    fill
                    placeholder="Email addresses"
                    value={emailAddressesText}
                    onChange={e => setEmailAddressesText(e.target.value)}
                    onKeyboardSubmit={submission.watch(handleSubmit)}
                />
                <DomainSignupInfo closeDialogAndClearInput={closeDialogAndClearInput} />
            </Dialog.Body>

            <Dialog.Footer className={styles.footer}>
                <Dialog.FooterActions>
                    <BorderButton
                        className={classNames(styles.ctaBtn)}
                        cta
                        fill
                        large
                        content="Send invitations"
                        disabled={!didEnterValidEmailAddress}
                        loading={submission.isInFlight}
                        onClick={submission.watch(handleSubmit)}
                        instrumentation={{
                            elementName: "invite_people_dialog.submit_btn",
                        }}
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
